<template>
  <div class="cbd-total-table">
    <div class="cbd-part-title">汇总</div>
    <div class="cbd-table-box">
      <div
        class="cbd-table-column"
        v-for="(val, key) in totalTable.cost"
        :key="key"
      >
        <div
          class="cbd-table-key-box"
          :class="[val.percent ? 'percent-box' : '']"
          @click="showPercent(key)"
        >
          {{ val.label }}
          <span v-if="val.percent" class="percent"> {{ val.percent }}%</span>
          <el-card
            class="discount-input-box"
            v-if="isShowDiscount && val.percent && nowKey == key"
          >
            <el-input
              ref="discount"
              v-model="val.percent"
              size="mini"
              @keyup.native="val.percent = val.percent.replace(/[^0-9_]/g, '')"
              @blur="hideDiscount"
            ></el-input>
          </el-card>
        </div>
        <div class="cbd-table-key-box">
          {{ val.value }}
        </div>
      </div>
    </div>
    <div class="cbd-total-box">
      <div class="total-box">
        <div class="total-box-text">不含税成本小计：</div>
        <div class="total-box-cost">￥{{ totalTable.total.profit_total }}</div>
      </div>
      <div class="total-box">
        <div class="total-box-text">含税成本小计：</div>
        <div class="total-box-cost">
          ￥{{ totalTable.total.tax_profit_total }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cbdTotalTable",
  props: {
    allTotalTable: {
      type: Object,
    },
  },
  data() {
    return {
      isShowDiscount: false,
      nowKey: "",
      totalTable: this.allTotalTable,
    };
  },
  watch: {
    allTotalTable(val) {
      this.totalTable = val;
    },
  },
  methods: {
    showPercent(key) {
      this.nowKey = key;
      this.isShowDiscount = true;
      setTimeout(() => {
        this.$refs.discount.focus();
      }, 100);
    },
    hideDiscount() {
      this.$emit("setAllCost");
      this.isShowDiscount = false;
    },
  },
};
</script>

<style lang="scss">
$cbd-border: #ebeef5;
.cbd-total-table {
  .cbd-part-title {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
  }
  .el-textarea__inner {
    font-size: $base-font-size;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    resize: none;
    padding: 5px 0px !important;
  }
  .cbd-table-box {
    width: 100%;
    border-left: 1px solid $cbd-border;
    border-top: 1px solid $cbd-border;
    display: flex;
    flex-wrap: wrap;
    .cbd-table-column {
      width: 20%;
      min-height: 22px;
      flex-shrink: 0;
      display: flex;
      position: relative;
      .cbd-table-key-box {
        display: flex;
        align-items: center;
        width: 50%;
        min-height: 22px;
        padding: 8px 4px;
        border-right: 1px solid $cbd-border;
        border-bottom: 1px solid $cbd-border;
        position: relative;

        .discount-input-box {
          position: absolute;
          bottom: -30px;
          left: 0;
          width: 120px;
          height: 30px;
          background-color: #fff;
          z-index: 1000;
        }
        .el-card__body {
          padding: 0 !important;
        }
        .percent {
          color: red;
        }
      }
      .percent-box {
        cursor: pointer;
      }
    }
  }
  .cbd-total-box {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;

    .total-box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .total-box-text {
        width: 200px;
        text-align: right;
        font-size: 24px;
        font-weight: 600;
      }
      .total-box-cost {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
}
</style>

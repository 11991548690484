<template>
  <div class="form-menu-item">
    <div class="form-menu-title-box" @click="handleChange">
      <div class="item-left">
        <i
          class="iconfont icon-sanjiaoright menu-animate"
          :class="[isShowMenu ? 'arrow-rotate' : '']"
        ></i>
        <span class="memu-title">{{ title }}</span>
      </div>
      <i class="iconfont icon-ic_add" @click.stop="toAddForm"></i>
    </div>
    <div
      class="form-left-list menu-animate"
      :class="[isShowMenu ? '' : 'hide-menu']"
      :style="{
        height:
          menuList.length == 0
            ? 62 + 'px'
            : menuList.length > 3
            ? 200 + 'px'
            : menuList.length * 62 + 'px',
      }"
    >
      <div class="form-left-item" v-if="menuList.length == 0">
        <span class="item-empty">No Data</span>
      </div>
      <div
        @click="getDetail(val, key)"
        class="form-left-item"
        :class="[join + key == checkJoin ? 'form-left-item-active' : '']"
        v-for="(val, key) in menuList"
        :key="key"
        v-else
      >
        <div class="item-msg">
          <div class="item-msg-text-box">
            <div class="item-msg-title">{{ val.sn }}</div>
            <div class="item-msg-date">{{ val.sn }}</div>
          </div>
        </div>
        <div class="item-price-box">
          <div class="item-price">Name</div>
          <div class="item-price-tag">
            <span class="text">Quatation</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "formMenuItem",
  props: {
    title: {
      type: String,
    },
    menuList: {
      type: Array,
    },
    join: {
      type: String,
    },
    checkJoin: {
      type: String,
    },
  },
  data() {
    return {
      isShowMenu: true,
      checkChoose: this.checkJoin,
    };
  },
  watch: {
    checkJoin(val) {
      this.checkChoose = val;
    },
  },
  methods: {
    handleChange() {
      this.isShowMenu = !this.isShowMenu;
    },
    getDetail(val, key) {
      this.$emit("getDetail", {
        join: this.join + key,
        id: val.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-menu-item {
  width: 100%;
  max-height: 33%;
  overflow: hidden;
  padding-bottom: 20px;
  /*min-height: 200px;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .form-menu-title-box {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    .item-left {
      display: flex;
      align-items: center;
      .memu-title {
        font-size: 18px;
        font-weight: 600;
        margin-left: 8px;
      }
    }
    .icon-ic_add {
      color: #bebebe;
    }
  }
  .menu-animate {
    transition: all 0.3s ease-out;
  }
  .hide-menu {
    height: 0 !important;
    opacity: 0 !important;
    min-height: 0 !important;
  }
  .arrow-rotate {
    transform: rotate(90deg);
  }
  .form-left-list {
    opacity: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    height: 200px;
    .form-left-item {
      width: 100%;
      height: 50px;
      margin-top: 12px;
      border: 1px solid #e2dede;
      padding: 6px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .item-empty {
        color: #d3d6db;
        font-size: 18px;
        font-weight: 600;
      }
      .item-msg {
        display: flex;
        align-items: center;
        .item-msg-img {
          width: 72px;
          height: 100%;
          margin-right: 18px;
          .el-icon-picture-outline {
            font-size: 16px;
            color: #999;
          }
        }
        .item-msg-text-box {
          .item-msg-title {
            max-width: 200px;
            font-size: 16px;
            font-weight: 600;
            color: #2f2e2e;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .item-msg-date {
            margin-top: 8px;
            font-size: 10px;
            color: #4d4d4d;
          }
        }
      }
      .item-price-box {
        text-align: right;
        .item-price {
          font-size: 12px;
          font-weight: 600;
          color: #2f2e2e;
        }
        .item-price-tag {
          width: 68px;
          height: 14px;
          text-align: center;
          line-height: 14px;
          color: #fff;
          background-color: $base-config-color;
          margin-top: 6px;
        }
      }
    }
    .form-left-item-active {
      border-color: #000;
    }
  }

  .text {
    font-size: 12px;
    -webkit-transform: scale(0.9);
  }
}
</style>

export default ({ service, request }) => ({
  getServiceList(page) {
    return request({
      url: "/v1/customer/getServiceList",
      method: "post",
    });
  },
});

const usePage = (pageInfo) => {
  const { pageSize, current } = pageInfo;
  return {
    page: current,
    page_size: pageSize,
  };
};

<template>
  <div class="n-form-item mb-1" :style="{ justifyContent }">
    <div class="form-item-label">
      <label class="label mr-2" :class="{ required }" :for="label" v-if="label">{{ label }}</label>
    </div>
    <div class="form-item-field"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "n-form-item",
  props: {
    label: {
      type: String,
      default: "",
    },

    justifyContent: {
      type: String,
      default: "space-between",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.n-form-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .form-item-label {
    width: 200px;
    text-align: right;
  }

  .form-item-field {
    width: 100%;
    flex: 1;
  }
}
</style>

<template>
  <div
    class="resource"
    :class="{ selected }"
    @click="handleClick"
    @mouseover="handleOver"
    @mouseout="handleOut"
  >
    <div class="resource-preview">
      <div class="resource-header">
        <i class="iconfont icon-tishi1" @click.stop="handleEdit"></i>
        <i class="iconfont icon-check" @click.stop="handleChange"></i>
      </div>
      <img
        :src="src"
        :alt="title"
        type="material"
        :data="JSON.stringify(data)"
      />
    </div>
    <div class="resource-footer">
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  methods: {
    handleChange() {
      this.selected = !this.selected;
      this.$emit("select", this.selected);
    },
    handleClick() {
      this.$emit("click");
    },
    handleEdit() {
      //this.selected = !this.selected;
      this.$emit("edit", this.selected);
    },
    handleOver() {
      document.getElementById("preBox").style.zIndex = 1;
      document.getElementById("preview").src = this.data.coverpic;
      document.getElementById("preSn").innerHTML = this.data.sn;
    },
    handleOut() {
      document.getElementById("preBox").style.zIndex = -1;
      document.getElementById("preview").src = "";
    },
  },
};
</script>

<style lang="scss">
.resource {
  width: 25%;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  .resource-header {
    width: 100%;
    padding: 0px 4px;
    opacity: 0;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    .iconfont {
      color: #999;
    }
  }
  .resource-preview {
    height: 94px;
    border-radius: 8px;
    transition: scale ease 0.3s;
    border: 1px solid transparent;
    transition: all ease-in-out 0.2s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 8px;
    img {
      border-radius: 50%;
      height: 60%;
      width: 60%;
      object-fit: contain;
      transition: all ease 0.5s;
    }
  }
  .resource-footer {
    padding: 0px 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    // font-weight: 600;
  }
  &:hover {
    .resource-preview {
      border: 1px solid #999;
    }
    .resource-header {
      opacity: 1;
    }
    img {
      transform: scale(1.2);
    }
  }
}
.resource.selected {
  i:nth-child(2) {
    color: var(--primaryColor);
  }
  .resource-footer {
    color: var(--primaryColor);
  }
  .resource-preview {
    border: 1px solid var(--primaryColor);
  }
  .resource-header {
    opacity: 1;
  }
}
</style>

<template>
  <div class="main">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.main {
  width: 100%;
  // height: calc(100vh - 100px);
  height: calc(100vh - 52px);
  // margin-top: 8px;
}
</style>

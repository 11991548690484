<template>
  <div class="current-conv bg-gray-3 flex-1" v-if="visible">
    <div class="current-conv-header conversation-drag" ref="target">
      <span></span>
      <span class="flex flex-col">
        <span class="mb-16">
          <!-- <i
            class="iconfont icon-yk_fangkuai mr-28 pointer"
            @click="handleClickIcon"
          ></i> -->
          <i class="iconfont icon-close pointer" @click="handleClickIcon"></i>
        </span>
        <i
          v-if="current.meeting_id"
          class="iconfont icon-shenglvehao pointer"
          @click.stop="handleToggleGroupDetail"
        ></i>
      </span>
    </div>
    <ul class="message-list border-bottom p-4" id="svgTop">
      <li v-for="m in msglist" :key="m.id">
        <!-- v-if="m.timestamp - msglist[idx - 1 < 0 ? 0 : idx].timestamp > 60" -->
        <time-line :current="m.timestamp" v-if="m.displayTimeLine"></time-line>
        <message
          :avatar="m.meeting_user_img"
          :nick="m.meeting_user_name"
          :revoke="m.revoke"
          :message="m"
          :isSelf="m.type"
          @revoke="handleRevoke(m)"
        >
        </message>
        <!-- <message
          v-if="m.meeting_chat_type == 0"
          :avatar="m.meeting_user_img"
          :nick="m.meeting_user_name"
          :msg="m.meeting_chat_conent"
          :type="m.type"
        >
        </message> -->
        <!-- <message
          v-else-if="m.meeting_chat_type == 4"
          :avatar="m.meeting_user_img"
          :nick="m.meeting_user_name"
          :type="m.type"

        >
        </message> -->
        <!-- <message
          v-else-if="m.meeting_chat_type == 5"
          v-for="(n, idx2) in m.arr"
          :key="idx2"
          :avatar="m.meeting_user_img"
          :nick="m.meeting_user_name"
          :msgimg="n"
          :type="m.type"

        >
        </message> -->
        <!-- <message
          v-else-if="m.meeting_chat_type == 5"
          :avatar="m.meeting_user_img"
          :nick="m.meeting_user_name"
          :msggoods="m.arr"
          :type="m.type"

        >
        </message> -->
        <!-- <message
          v-else-if="m.meeting_chat_type == 6"
          :avatar="m.meeting_user_img"
          :nick="m.meeting_user_name"

        >
        </message> -->
      </li>
    </ul>
    <message-send
      :disabled="disabled"
      @sendmsg="sendmsg"
      @uploadfile="uploadfile"
      @sendinside="sendinside"
      @upload="handleUpload"
    ></message-send>
    <el-upload
      ref="uploadfile"
      v-show="false"
      action=""
      :auto-upload="false"
      :multiple="true"
      :show-file-list="false"
      :on-change="douploadfile"
      accept="image/*"
    >
    </el-upload>
    <file-send-comfirm
      :x="302"
      :y="309"
      :visible.sync="showSendConfirm"
      :files="files"
      :userInfo="userInfo"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations } = createNamespacedHelpers("jiyang/im");
import TimeLine from "../message/time-line.vue";
import FileSendComfirm from "../components/modal/file-uploader-modal.vue";
import Message from "../message/message.vue";
import MessageSend from "../message/message-send.vue";
import uploadCos from "@/utils/upload_to_cos";

import { isJSON, uid } from "../utils";

/**
 *  warning im模块有大量辣鸡代码，从一个叫小温那里接手来的屎山
 */
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => ({
        meeting_id: 0,
      }),
    },
  },
  data() {
    return {
      msglist: [],
      chatpage: 0,
      currentuserlist: null,
      chatpagesize: 50,
      uploadfiletime: null,
      showSendConfirm: false,
      files: [],
      filemsg: [],
      pictureList: [],
      messageList: [],
    };
  },
  computed: {
    ...mapState(["showGroupDetail", "showConversation", "sdkReady"]),
    disabled() {
      return !new Boolean(this.current.meeting_id).valueOf() && this.sdkReady;
    },
    userInfo() {
      const { meeting_img, meeting_name } = this.current;
      return {
        avatar: meeting_img,
        nickname: meeting_name,
      };
    },
  },
  watch: {
    current() {
      this.chatpage = 0;
      this.msglist = [];
      // this.seeMeeting();
      this.init();
    },
    visible(val) {
      if (val) this.scrollbar();
    },
  },
  mounted() {
    this.onRevoke();
  },
  methods: {
    ...mapMutations([
      "setShowGroupDetail",
      "setPosition",
      "setShowConversation",
    ]),
    async init() {
      await this.getMessageList();
    },
    onRevoke() {
      this.$im.on(this.$TIM.EVENT.MESSAGE_REVOKED, (event) => {
        const { data } = event;
        this.msglist.forEach((message, idx) => {
          const { msg = { id: "" } } = message;
          const { id = "" } = msg;
          data.forEach((d) => {
            const { ID } = d;
            if (id == ID) {
              this.msglist.splice(idx, 1, {
                ...message,
                revoke: true,
              });
            }
          });
        });
      });
    },
    async handleRevoke(messageItem) {
      const {
        msg: { id },
        message,
        timestamp,
      } = messageItem;

      const now = parseInt(Date.now() / 1000);
      if (now - timestamp >= 2 * 60) {
        this.$message({
          message: "只能撤回2分钟内的消息",
          type: "warning",
        });
        return;
      }
      if (message) {
        this.$im.revokeMessage(message);
      }
      const idx = this.msglist.findIndex((m) => m.msg.id == id);
      if (idx == -1) {
        return;
      }
      this.msglist.splice(idx, 1, {
        ...messageItem,
        revoke: true,
      });

      const { messageList = [] } = await this.getMsgList();
      const findMsg = messageList.find((m) => m.msg.id == id);
      if (findMsg) {
        this.$api.backMsg({ id: findMsg.id });
      }
    },
    async getMsgList() {
      const param = {
        meeting_id: this.current.meeting_id,
        page: this.chatpage,
        pagesize: this.chatpagesize,
      };
      const { user = [], msg = [] } = await this.$api.seeMeeting(param);
      // let user_info = JSON.parse(this.$cookie.get("user_info"));
      const msgList = msg.map((m) => {
        let { meeting_chat_conent } = m;
        let msg = null;
        if (isJSON(meeting_chat_conent)) {
          msg = JSON.parse(meeting_chat_conent);
          meeting_chat_conent = msg.content;
        } else {
          msg = {
            id: m.id,
            content: m.meeting_chat_conent,
          };
        }
        return {
          ...m,
          meeting_chat_conent,
          msg,
        };
      });
      return { messageList: msgList, userList: user };
    },
    async getMessageList() {
      const param = {
        meeting_id: this.current.meeting_id,
        page: this.chatpage,
        pagesize: this.chatpagesize,
      };
      //
      const {
        user = [],
        msg = [],
        meeting_id,
        page,
      } = await this.$api.seeMeeting(param);
      let user_info = JSON.parse(this.$cookie.get("user_info"));
      // this.$api.seeMeeting(data).then((res) => {
      //   console.log(res);
      //   if (this.current.meeting_id == res.meeting_id) {
      //     if (res.page == 0) {
      //       this.currentuserlist = res.user;
      //       this.currentuserlist.forEach((user) => {
      //         user.head_img =
      //           "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
      //           user.head_img;
      //       });
      //       this.msglist = [];
      //       this.upuserlist();
      //     }
      //     res.msg.forEach((msg) => {
      //       this.currentuserlist.forEach((user) => {
      //         if (user.id == msg.meeting_user_id) {
      //           msg.meeting_user_name = user.last_name + user.first_name;
      //           msg.meeting_user_img = user.head_img;
      //           msg.finish = 1;
      //           msg.type = 0;
      //           if (msg.meeting_user_id == user_info.id) msg.type = 1;
      //           _this.pushmsg(msg);
      //           return;
      //         }
      //       });
      //     });
      //   }
      // });
      const msgList = msg.map((m) => {
        let { meeting_chat_conent, meeting_chat_type } = m;
        let msg = null;
        if (isJSON(meeting_chat_conent)) {
          msg = JSON.parse(meeting_chat_conent);
          meeting_chat_conent = msg.content;
        } else {
          msg = {
            id: m.id,
            content: m.meeting_chat_conent,
          };
        }
        return {
          ...m,
          revoke: meeting_chat_type == 4,
          meeting_chat_conent,
          msg,
        };
      });

      if (this.current.meeting_id == meeting_id) {
        //
        if (page == 0) {
          this.currentuserlist = user.map((u) => ({
            ...u,
            head_img: `https://shoe-1303249748.cos.ap-shanghai.myqcloud.com${u.head_img}`,
          }));
          // this.currentuserlist.forEach((user) => {
          //   user.head_img =
          //     "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
          //     user.head_img;
          // });
          // this.msglist = [];
          this.upuserlist();
        }
        msgList.forEach((m, idx) => {
          m.timestamp = parseInt(m.create_time);
          let displayTimeLine = false;
          if (idx >= 1) {
            displayTimeLine = m.timestamp - msgList[idx - 1].timestamp >= 60;
          }
          m.displayTimeLine = displayTimeLine;
          this.currentuserlist.forEach((user) => {
            if (user.id == m.meeting_user_id) {
              m.meeting_user_name = user.last_name + user.first_name;
              m.meeting_user_img = user.head_img;
              m.finish = 1;
              m.type = 0;
              if (m.meeting_user_id == user_info.id) m.type = 1;
              this.pushmsg(m);
              return;
            }
          });
        });
      }
      return { message: msgList, user };
    },
    handleOk(files) {
      this.delayedloadfile(this.filemsg, this.pictureList);
    },
    handleUpload(files) {
      const fileList = files.map((f) => f.file);
      let list = files.map((f) => ({
        id: uid(),
        name: f.name,
        type: f.type,
        url: "",
        finish: 0,
      }));
      this.sendMediaMessage(list, fileList);
    },
    handleClickIcon() {
      this.setShowConversation(false);
    },
    handleToggleGroupDetail() {
      this.setShowGroupDetail(!this.showGroupDetail);
    },

    handlejump(goodsmsg) {
      this.$emit("jumpgoods", goodsmsg.arr);
    },
    onIMMsg(newVal) {
      //
      let receivenews = [];
      let grouplist = this.currentuserlist;
      newVal.payload.data = parseInt(newVal.payload.data);
      //有新的用户加入
      if (newVal.payload.data == 127) {
        this.getmeetinguser();
      } else if (newVal.payload.data == 2) {
        receivenews.push({
          id: newVal.from.split("uid")[0],
          text: newVal.payload.description,
          sub: newVal.payload.extension,
          time: newVal.time,
          type: 2,
          msg: {
            id: newVal.ID,
            content: newVal.payload.description,
          },
        });
      } else if (newVal.payload.data >= 3) {
        receivenews.push({
          id: newVal.from.split("uid")[0],
          text: newVal.payload.description,
          sub: newVal.payload.extension,
          type: newVal.payload.data,
          msg: {
            id: newVal.ID,
            content: newVal.payload.description,
          },
        });
      } else if (newVal.payload.data == 1) {
        receivenews.push({
          id: newVal.from.split("uid")[0],
          text: newVal.payload.description,
          sub: newVal.payload.extension,
          type: 1,
          msg: {
            id: newVal.ID,
            content: newVal.payload.description,
          },
        });
      } else {
        receivenews.push({
          id: newVal.from.split("uid")[0],
          text: newVal.payload.description,
          sub: newVal.payload.extension,
          time: newVal.time,
          type: 0,
          msg: {
            id: newVal.ID,
            content: newVal.payload.description,
          },
        });
      }

      if (receivenews.length > 0) {
        let _this = this;
        this.updateMeetingUMlast();
        receivenews.forEach(function (val) {
          grouplist.forEach(function (val2) {
            if ((val.id == val2.id) == true) {
              //
              _this.pushmsg({
                msg: val.msg,
                meeting_user_id: val.id,
                meeting_user_name: val2.last_name + val2.first_name,
                meeting_user_img: val2.head_img,
                meeting_chat_conent: val.text,
                meeting_chat_sub: val.sub,
                meeting_chat_type: val.type,
                type: 0,
                finish: 1,
              });
            }
          });
        });
      }
    },
    seeMeeting() {
      let data = {
        meeting_id: this.current.meeting_id,
        page: this.chatpage,
        pagesize: this.chatpagesize,
      };
      let _this = this;
      let user_info = JSON.parse(this.$cookie.get("user_info"));
      this.$api.seeMeeting(data).then((res) => {
        if (this.current.meeting_id == res.meeting_id) {
          if (res.page == 0) {
            this.currentuserlist = res.user;
            this.currentuserlist.forEach((user) => {
              user.head_img =
                "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
                user.head_img;
            });
            this.msglist = [];
            this.upuserlist();
          }
          res.msg.forEach((msg) => {
            this.currentuserlist.forEach((user) => {
              if (user.id == msg.meeting_user_id) {
                msg.meeting_user_name = user.last_name + user.first_name;
                msg.meeting_user_img = user.head_img;
                msg.finish = 1;
                msg.type = 0;
                if (msg.meeting_user_id == user_info.id) msg.type = 1;
                _this.pushmsg(msg);
                return;
              }
            });
          });
        }
      });
    },
    getmeetinguser() {
      let data = { meeting_id: this.current.meeting_id };
      this.$api.getmeetinguser(data).then((res) => {
        if (this.current.meeting_id == res.meeting_id) {
          this.currentuserlist = res.user;
          this.currentuserlist.forEach((user) => {
            user.head_img =
              "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
              user.head_img;
          });
          this.upuserlist();
        }
      });
    },
    sendmsg(type, msg, sub = "", now = 1, room, meeting_id) {
      let user = JSON.parse(this.$cookie.get("user_info"));
      let user_id = user.id;
      let user_nickname = user.last_name + user.first_name;
      let head_img = user.head_img;
      var c = [];
      let to = this.current.room;
      if (!to) {
        this.$message({
          message: "请先打开一个聊天窗口",
          type: "warning",
        });
        return;
      }
      let msgInfo = {
        meeting_chat_conent: msg,
        meeting_chat_sub: sub,
        meeting_chat_type: type,
      };
      let message = this.createMessage(msgInfo, to);
      c.push({
        ...msgInfo,
        id: message.ID,
        meeting_user_id: user_id,
        meeting_user_name: user_nickname,
        meeting_user_img: head_img,
        finish: 0,
        timestamp: Date.now(),
        msg: {
          id: message.ID,
          content: msg,
        },
        message,
      });

      this.pushmsg(c[0]);
      if (now == 1) this.sendmsgsever(c[0], room, meeting_id);
      return c[0];
    },
    createMessage(msg, to) {
      const { meeting_chat_type, meeting_chat_conent, meeting_chat_sub } = msg;
      let message = this.$im.createCustomMessage({
        to: to,
        conversationType: this.$TIM.TYPES.CONV_GROUP,
        payload: {
          data: `${meeting_chat_type}`, // 消息类型
          description: meeting_chat_conent, // 信息主体
          extension: meeting_chat_sub,
        },
      });
      return message;
    },
    //发送信息
    sendmsgToIM(msg, room) {
      // let _this = this;
      // let Txroom = room;
      ///需要等待后台分配客服才发送
      // let message = this.$im.createCustomMessage({
      //   to: Txroom,
      //   conversationType: this.$TIM.TYPES.CONV_GROUP,
      //   payload: {
      //     data: msg.meeting_chat_type + "", // 消息类型
      //     description: msg.meeting_chat_conent, // 信息主体
      //     extension: msg.meeting_chat_sub,
      //   },
      // });
      // this.messageList.push(msg);
      // 2. 发送消息
      let promise = this.$im.sendMessage(msg);
      promise
        .then((msg) => {
          this.$emit("upMeetingStatue", msg.data.message, 1);
        })
        .catch((err) => {
          // console.error("sendMessage error:", imError);
          this.$notify.error({
            title: "错误",
            message: err,
          });
        });
    },
    //发送新的会议信息
    sendSysmsgToIM(msg, sub, room, type) {
      ///需要等待后台分配客服才发送
      let message = this.$im.createCustomMessage({
        to: room,
        conversationType: this.$TIM.TYPES.CONV_GROUP,
        payload: {
          data: type + "", // 消息类型
          description: msg, // 信息主体
          extension: sub,
        },
      });
      // 2. 发送消息
      this.$im.sendMessage(message);
    },
    //发送信息
    sendmsgsever(msg, room, meeting_id) {
      const { message } = msg;

      let txroom = room ? room : this.current.room;
      let m_id = meeting_id ? meeting_id : this.current.meeting_id;

      if (msg.meeting_chat_type >= 127) {
        this.sendmsgToIM(message, txroom);
        return;
      } else {
        let data = {
          meeting_id: m_id,
          msg_type: msg.meeting_chat_type,
          msg: JSON.stringify({
            content: msg.meeting_chat_conent,
            id: message.ID,
          }),
          sub: msg.meeting_chat_sub,
        };
		 console.log(typeof data.sub);
        this.$api.sendMeetingmsg(data).then((res) => {
          msg.finish = 1;
          this.sendmsgToIM(message, txroom);
        });
      }
    },
    //更新用户最后一个信息id
    updateMeetingUMlast() {
      let data = { meeting_id: this.current.meeting_id };
      this.$api.updateMeetingUMlast(data).then((res) => {});
    },
    pushmsg(msg) {
      if (msg.create_time) {
        msg.timestamp = parseInt(msg.create_time);
      }
      if (msg.meeting_chat_type >= 127) return;
      else if (msg.meeting_chat_type == 2) {
        msg.arr = JSON.parse(msg.meeting_chat_sub);
        msg.gid = msg.arr.id;
        msg.img = msg.arr.img;
        msg.tid = msg.arr.tid;
        msg.text = msg.arr.text;
      } else if (msg.meeting_chat_type >= 3) {
       msg.arr = JSON.parse(msg.meeting_chat_sub);
      }
      // if (msg.create_time == undefined) {
      //   msg.create_time = new Date().toLocaleString().replace(/:\d{1,2}$/, " ");
      // } else {
      //   msg.create_time = new Date(msg.create_time * 1000)
      //     .toLocaleString()
      //     .replace(/:\d{1,2}$/, " ");
      // }

      this.msglist.push(msg);
      this.scrollbar();
    },
    uploadfile() {
      this.$refs.uploadfile.$children[0].$refs.input.click();
    },
    //上传文件
    douploadfile(file, fileList) {
      this.files = [];
      this.filemsg = [];
      this.pictureList = [];
      fileList.forEach((file) => {
        // name: "2e85553593b16879d4ffc4adae201eaa.jpg";
        // percentage: 0;
        // raw: File;
        // size: 101342;
        // status: "ready";
        // uid: 1654669383332;
        this.pictureList.push(file.raw);

        const f = {
          name: file.raw.name,
          type: file.raw.type,
          size: file.size,
          id: file.uid,
          file: file.raw,
        };
        this.filemsg.push(f);
        this.files.push(f);
      });
      this.showSendConfirm = true;
      // if (this.uploadfiletime) {
      //   clearTimeout(this.uploadfiletime);
      // }
      // let _this = this;
      // this.uploadfiletime = setTimeout(() => {
      //   _this.delayedloadfile(filemsg, pictureList);
      // }, 500);
    },
    sendMediaMessage(filemsg, pictureList) {
      this.$refs.uploadfile.clearFiles();
      let msgList = filemsg.map((f, idx) => {
        let msg = this.sendmsg(6, "", JSON.stringify(f), 0);
        return msg;
      });
      uploadCos(
        pictureList,
        "meeting/" + this.current.meeting_id,
        (picture) => {
          picture.forEach((url, idx) => {
            let m = { ...msgList[idx], url };
            let msg = msgList[idx];
            msg.meeting_chat_sub = JSON.stringify(m);
            msg.arr = [];
            this.sendmsgsever(msg);
          });
        }
      );
    },
    delayedloadfile(filemsg, pictureList) {
      this.sendMediaMessage(filemsg, pictureList);
      // this.$refs.uploadfile.clearFiles();
      // // let _this = this;
      // let msgList = filemsg.map((f, idx) => {
      //   let msg = this.sendmsg(6, "", JSON.stringify(f), 0);
      //   return msg;
      // });
      // uploadCos(
      //   pictureList,
      //   "meeting/" + this.current.meeting_id,
      //   (picture) => {
      //     picture.forEach((url, idx) => {
      //       let m = { ...msgList[idx], url };
      //       let msg = msgList[idx];
      //       msg.meeting_chat_sub = JSON.stringify(m);
      //       msg.arr = [];
      //       this.sendmsgsever(msg);
      //     });
      //   }
      // );
      // let listmsg = this.sendmsg(6, "", JSON.stringify(filemsg), 0);
      // return new Promise((resolve) => {
      //   uploadCos(
      //     pictureList,
      //     "meeting/" + this.current.meeting_id,
      //     (picture) => {
      //       for (let i = 0; i < filemsg.length; i++) {
      //         let m = { ...filemsg[i], url: picture[i] };
      //         listmsg.meeting_chat_sub = JSON.stringify(m);
      //         listmsg.arr = [];
      //         _this.sendmsgsever(listmsg);
      //       }
      //       // listmsg.meeting_chat_sub = JSON.stringify(filemsg);
      //       // listmsg.arr = filemsg;
      //       // _this.sendmsgsever(listmsg);
      //       resolve(picture);
      //     }
      //   );
      // });
    },
    sendinside(type) {
      console.log("需要一个内部文件的列表");
    },
    //发布内部文件
    dosendinside(id, name, url, type) {
      let data = { id: id, name: name, url: url, type: type };
      this.sendmsg(5, "", JSON.stringify(data));
    },
    upuserlist() {
      this.$emit("changeUserList", this.currentuserlist);
    },
    //滚动条自动沉底
    scrollbar() {
      this.$nextTick(() => {
        let msg = document.getElementById("svgTop"); // 获取对象
        if (msg) msg.scrollTop = msg.scrollHeight; // 滚动高度550 1160
      });
    },
  },

  components: {
    MessageSend,
    Message,
    FileSendComfirm,
    TimeLine,
  },
};
</script>

<style lang="less" scoped>
.resizeable-wrap {
  height: 100%;
  position: relative;
  .resizeable {
    position: absolute;
  }

  .resizeable-right {
    height: 100%;
    width: 2px;
    right: -1px;
    background-color: blue;
    cursor: col-resize;
  }
}
.current-conv {
  overflow: auto;
  > .current-conv-header {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 68px;
    background: #f3f3f3;
    box-shadow: 0px 0px 10px rgba(46, 46, 46, 0.3);
    color: #b2b2b2;
    .icon-shenglvehao {
      text-align: right;
    }
  }
  ul,
  li {
    padding: 0;
    margin: 0;
  }
  .message-list {
    width: 100%;
    height: 55%;
    overflow: auto;
  }
}
</style>

<template>
  <form class="n-form" action="">
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: "n-form",
  created() {},
};
</script>

<style lang="scss">
.n-form {
  display: flex;
  flex-direction: column;
}
</style>

<template>
	<div id="materials-list">
		<card v-if="type == 1" v-for="item in filterMaterial" :src="item.coverpic"></card>
		<photo v-if="type == 2" v-for="item in filterMaterial" :src="item.coverpic"></photo>
		<el-pagination
		  background
		  big
		  :total="materialPage.total"
		  :current-page="materialPage.current_page"
		  :page-size="materialPage.per_page"
		  layout="prev, pager, next"
		  @current-change="currentChange"
		/>
	</div>
</template>

<script>
	import { createNamespacedHelpers } from "vuex";
	const { mapState, mapMutations, mapGetters, mapActions } =
	  createNamespacedHelpers("jiyang/material");
	import card from "@/components/assets/common/card";
	import photo from "@/components/assets/common/photo";
	
	export default {
		components:{
			card,
			photo
		},
		props:{
			type:{
				type:Number,
				default:1
			}
		},
		data(){
			return{

			}
		},
		computed:{
			...mapGetters(["filterMaterial"]),
			...mapState(["materialPage"]),
		},
		created() {
		  this.getMaterialList();
		},
		methods:{
			...mapActions(["getMaterialList", "delMaterial"]),
			currentChange(page, pageSize) {
			  this.getMaterialList({ page: page, pageSize: pageSize });
			},
		}
	}
</script>

<style scoped lang="scss">
	#materials-list{
		.el-pagination{
			text-align: center;
		}
	}
</style>

export default {
  namespaced: true,
  state: {
    publicEnquiry: [],
  },
  mutations: {
    setPublicEnquiry(state, enquiry) {
      state.publicEnquiry = [...enquiry];
    },
  },
  getters: {
    publicEnquiryTotal(state) {
      return state.publicEnquiry.length;
    },
  },
};

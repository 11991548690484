<template>
  <div class="resource" :class="{ selected }" @click="handleClick">
    <div class="resource-preview">
      <div class="resource-header">
        <i class="iconfont icon-tishi1" @click="handleEdit"></i>
        <i class="iconfont icon-check" @click="handleSelect"></i>
      </div>
      <img :src="src" :alt="title" class="radius-0" />
    </div>
    <div class="resource-footer">
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  methods: {
    handleSelect() {
      this.selected = !this.selected;
      this.$emit("select", this.selected);
    },
    handleClick() {
      this.$emit("click");
    },
    handleEdit() {
      this.$emit("edit");
    },
  },
};
</script>

<style lang="scss">
.resource {
  width: 97px;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  .resource-header {
    width: 100%;
    padding: 0px 4px;
    opacity: 0;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    .iconfont {
      color: #999;
    }
  }
  .resource-preview {
    height: 94px;
    border-radius: 8px;
    transition: scale ease 0.3s;
    border: 1px solid transparent;
    transition: all ease-in-out 0.2s;
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 8px;
    text-align: center !important;
    overflow: hidden;
    img {
      border-radius: 50%;
      height: 100% !important;
      width: 100% !important;
      object-fit: contain;
      transition: all ease 0.5s;
    }
  }
  .resource-footer {
    padding: 0px 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    // font-weight: 600;
  }
  &:hover {
    .resource-preview {
      // border: 1px solid var(--primaryColor);
    }
    .resource-header {
      opacity: 1;
      position: absolute;
      z-index: 10;
    }
    img {
      transform: scale(1.14);
    }
  }
}
.resource.selected {
  i:nth-child(2) {
    color: var(--primaryColor);
    color: red;
  }
  .resource-footer {
    color: var(--primaryColor);
  }
  .resource-preview {
    // border: 1px solid var(--primaryColor);
  }
  .resource-header {
    opacity: 1;
  }
}
</style>

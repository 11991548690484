<template>
  <a-modal
    :visible="visible"
    :title="$t('selectOneTexture')"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('cancel')"
    :okText="$t('ok')"
    width="640px"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <div class="picker-body">
        <a-empty v-if="texturePage.total == 0" />
        <ul class="image-list" v-else>
          <li
            class="image-item"
            :class="curSelected == idx ? 'selected' : null"
            v-for="(image, idx) in textures"
            :key="idx"
            @click="handleClick(idx)"
          >
            <img class="thumbnail" :src="image.url" alt="" />
          </li>
        </ul>
        <a-pagination
          v-if="texturePage.total > 0"
          v-model="current"
          :total="texturePage.total"
          show-less-items
          @change="handlePageChange"
          :defaultPageSize="12"
        />
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");
export default {
  name: "image-picker",
  data() {
    return { current: 1, curSelected: 0 };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    pageChange: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(["textures", "textureFolders", "texturePage", "loading"]),
  },
  created() {
    this.getTextureList({ page: 1 });
  },
  methods: {
    ...mapActions(["getTextureList"]),
    handleClick(idx) {
      this.curSelected = idx;
    },
    handleCancel() {
      this.$emit("update:visible", false);
    },
    handlePageChange(page) {
      this.getTextureList({ page });
    },
    handleOk() {
      this.$emit("update:visible", false);
      this.$emit("ok", this.textures[this.curSelected]);
    },
  },
};
</script>

<style lang="scss">
.picker-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
  .selected {
    .thumbnail {
      border: 1px solid var(--primaryColor);
    }
  }
}

.ant-modal-body {
  padding: 16px 16px;
}
.image-list {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  word-wrap: break-word;
  word-break: normal;
  .image-item {
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    &:hover {
      .thumbnail {
        border: 1px solid var(--primaryColor);
      }
    }
  }
  .thumbnail {
    width: 60px;
    height: 60px;
    border: 1px solid #dbdbdb;
    padding: 4px 4px;
  }
}
</style>
<template>
<!--  <el-drawer-->
<!--      title="我是标题"-->
<!--      :modal="false"-->
<!--      :visible.sync="visible"-->
<!--      :before-close="closeDrawer">-->
<!--    <span>我来啦!</span>-->
<!--  </el-drawer>-->
  <a-drawer
      title=""
      placement="right"
      :closable="false"
      :visible="visible"
      :get-container="false"
      :style="{ position: 'absolute' }"
      @close="closeDrawer"
  >
    <div>
      <div class="history-header">
        <div class="history-header-item " :class="[current===2?'history-header-item-active':'']" @click="changeHistory(2)">
          报价记录
        </div>
        <div class="history-header-item" :class="[current===1?'history-header-item-active':'']"  @click="changeHistory(1)">
          询盘记录
        </div>
      </div>
      <div class="history-body">
        <div class="history-item" v-if="current == 2" v-for="(val,key) in lists" :key="key" @click="toDetail(val.id)">
          <div>第{{key+1}}次报价</div>
          <div class="history-item-time">
            {{val.create_time}}
          </div>
        </div>
		<div class="history-item" v-if="current == 1 && enquiryHistory" :key="key" @click="toDetail(enquiryHistory.id)">
		  <div>首次询盘时间</div>
		  <div class="history-item-time">
		    {{enquiryHistory.update_time}}
		  </div>
		</div>
      </div>
    </div>

  </a-drawer>
</template>

<script>
export default {
  name: "formHistory",
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    historyList:{
      type:Array
    },
    detailId:{
      type: Number
    },
	sn:{
		type:String
	}
  },
  data(){
    return{
      lists:this.historyList,
      current:2,
	  enquiryHistory:{}
    }
  },
  watch:{
    historyList(val){
      this.lists=val
    },
    visible(val){
      if(val){
        this.getHistoryList()
      }
    }
  },
  created() {

  },
  methods:{
    //切换历史记录
    changeHistory(type){
      this.current=type
      this.getHistoryList()
    },
    //获取历史记录
    getHistoryList(){
      let data={
        type:this.current,
        id:this.detailId,
		sn:this.sn,
        page:1,
        page_size:100
      }
      this.$api.getHistory(data).then(res=>{
        if(this.current==1){
          this.enquiryHistory=res.enquiryHistory?res.enquiryHistory:{};
        }else{
          this.historyList=res.quoteHistory.data
        }
      })
    },
    toDetail(id){
      this.$emit('toFormDetail',{id,type:this.current})
    },
    closeDrawer(){
      this.$emit('update:visible',false)
    }
  }
}
</script>

<style lang="scss">
  .history-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px solid #eee ;
    padding: 10px 40px;
    .history-header-item{
      position: relative;
      cursor: pointer;
    }
    .history-header-item-active{
      font-weight: 600;
    }
    .history-header-item-active:after{
      content: '';
      height: 2px;
      width: 40px;
      background-color: $base-config-color;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .history-body{
    .history-item{
      padding: 10px;
      border-bottom:1px solid #eee ;
      cursor: pointer;
      .history-item-time{
        margin-top: 6px;
        text-align: right;
        color:#bbb;
      }
    }
  }
</style>
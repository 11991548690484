export default ({ service, request }) => ({
  // 保存bom
  saveBom(data) {
    return request({
      url: `/v1/bomtable`,
      method: "post",
      data,
    });
  },
  // 获取指定Bom单信息
  getBom(params) {
    return request({
      url: `/v1/bomtable/${params.id}`,
      method: "get",
      params,
    });
  },
  // 更新指定Bom单
  updateBom(data) {
    return request({
      url: `/v1/bomtable/${data.id}`,
      method: "put",
      data,
    });
  },
  // 获取部件
  getPart(params) {
    return request({
      url: `/v1/part`,
      method: "get",
      params,
    });
  },
  //  获取素材筛选列表
  getTextureSelectList(data) {
    return request({
      url: `/v1/texture/getSelectList`,
      method: "post",
      data,
    });
  },
});

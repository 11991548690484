<template>
  <div>
    <div class="login-box">
      <div class="login-form">
        <img class="login-logo" src="@/assets/logo.png" />
        <el-form
          class="login-input-box"
          ref="loginForm"
          label-position="top"
          :rules="rules"
          :model="formLogin"
          size="default"
        >
          <el-form-item prop="user_email" class="login-input-item">
            <el-input
              type="text"
              class="login-input"
              v-model="formLogin.user_email"
              :placeholder="$t('loginEmail')"
              @keyup.enter.native="submit"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="user_pwd" class="login-input-item">
            <el-input
              type="password"
              class="login-input"
              v-model="formLogin.user_pwd"
              :placeholder="$t('loginPassword')"
              @keyup.enter.native="submit"
            >
            </el-input>
          </el-form-item>
        </el-form>
		<div class="forgetPassword">
			<div style="text-align: left;">
				 <el-checkbox v-model="checked">{{$t("rememberPassword")}}</el-checkbox>
			</div>
			<div style="text-align: right;">
				<router-link style="text-align: right;" to="forgetPassword">{{$t("forgetPassword")}}</router-link>
			</div>
		</div>
        <div class="login-btn" @click="submit">
          {{ $t("login") }}
        </div>
        <!--                <div class="forget-btn">-->

        <!--                </div>-->
      </div>
      <div class="register-box">
        <span>{{ $t("noneAccount") }}</span>
        <span class="register-text" @click="toRegister">{{
          $t("signUp")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
const Base64 = require('js-base64').Base64
export default {
  name: "login",
  data() {
    return {
      currentTab: 1,
      formLogin: {
        user_email: "",
        user_pwd: "",
      },
	  checked: false,
      // 校验
      rules: {
        user_email: [
          {
            required: true,
            message: this.$t("noneLoginEmail"),
            trigger: "blur",
          },
        ],
        user_pwd: [
          {
            required: true,
            message: this.$t("noneLoginPassword"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("jiyang/account", ["isUserLogin"]),
  },
  created() {
    this.toCheckLogin()
    if (this.isUserLogin) {
      this.$router.replace(this.$route.query.redirect || "/");
    }
  },
  mounted(){
	   let username = localStorage.getItem('username')
	   if (username) {
		 this.formLogin.user_email = localStorage.getItem('username')
		 this.formLogin.user_pwd = Base64.decode(localStorage.getItem('password'))// base64解密
		 this.checked = true
	   }
  },
  methods: {
    ...mapActions("jiyang/account", ["login","toCheckLogin"]),
    // 提交登录信息
    submit() {
      let that = this;
      that.$refs.loginForm.validate((valid) => {
        if (valid) {
          let loading = this.openLoading();
		  if (this.checked) {
				let password = Base64.encode(this.formLogin.user_pwd) // base64加密
				localStorage.setItem('username', this.formLogin.user_email)
				localStorage.setItem('password', password)
		  } else {
			    localStorage.removeItem('username')
			    localStorage.removeItem('password')
		  }
          // 登录
          that
            .login({
              user_mobile: that.formLogin.user_email,
              user_pwd: that.formLogin.user_pwd,
            })
            .then(() => {
              loading.close();
              // 重定向对象不存在则返回顶层路径
			  this.$router.push({
			    name: "Home",
			  });
              //that.$router.replace(that.$route.query.redirect || "/Home");
            })
            .catch(() => {
              loading.close();
            });
        } else {
          // 登录表单校验失败
          if (that.formLogin.user_email == "") {
            that.$message.error(that.$t("noneLoginMobile"));
            return false;
          }
          if (that.formLogin.user_pwd == "") {
            that.$message.error(that.$t("noneLoginPassword"));
            return false;
          }
        }
      });
    },
    //    跳转注册
    toRegister() {
      this.$router.push({
        name: "register",
      });
    },
    //    跳转忘记密码
    toForgetPassword() {
      this.$router.push({
        name: "forgetPassword",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "account";
</style>

<template>
  <div>
    <div class="navbar">
      <div class="nav-left">
        <img class="nav-logo" src="@/assets/logo.png" alt="" @click="toHome" />
        <div class="nav-center">
          <div v-for="(val, key) in menuList" :key="key">
            <el-dropdown @command="handleCommand" v-if="val.type == 2">
              <div
                class="nav-center-item"
                :class="[menu == val.type ? 'nav-center-item-active' : '']"
                @click="changeMenu(val)"
              >
                {{ $t("tab_"+val.type) }}
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="toDesign"
                  :disabled="filter_menu == 1"
                  >{{ $t("design") }}</el-dropdown-item
                >
                <el-dropdown-item
                  command="toThreeD"
                  :disabled="filter_menu == 2"
                  >{{ $t("threeD") }}</el-dropdown-item
                >
                <el-dropdown-item
                  command="toProduct"
                  :disabled="filter_menu == 3"
                  >{{ $t("goods") }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <div
              v-else
              class="nav-center-item"
              :class="[menu == val.type ? 'nav-center-item-active' : '']"
              @click="changeMenu(val)"
            >
              {{ $t("tab_"+val.type) }}
            </div>
          </div>
        </div>
      </div>
	  <div v-if="origin_web == 3" style="font-size: 20px;font-weight: bold;">福建第二轻工业学校线上交互设计平台</div>
	  <div class="nav-right" style="font-size: 16px;color:rgb(138,138,138);font-weight: bold;" v-show="!isLogin">
		  <router-link to="/login" class="navLoginRegister" :style="{color:(menu==-1?'#21c3a9':'')}">{{$t('login')}}</router-link>
		  /
		  <router-link to="/register" class="navLoginRegister" style="margin-right: 20px;" :style="{color:(menu==-2?'#21c3a9':'')}">{{$t('signUp')}}</router-link>
		  <img width="25px" @click="handleCommand('language')" src="../../assets/image/languageChange.png">
	  </div>
      <div class="nav-right" v-show="isLogin">
        <cart-indicator></cart-indicator>
        <el-dropdown @command="handleCommand">
          <div class="header-user">
            <div
              class="header-user-img-box"
              :style="{ backgroundImage: `url(${user_info.head_img})` }"
            >
              <!--                    <img class="header-user-img" :src="user_info.head_pic"/>-->
            </div>
            <span class="header-user-name" v-if="user_info.user_type == 1">{{
              user_info.enterprise
            }}</span>
            <span class="header-user-name" v-else
              >{{ user_info.last_name }}{{ user_info.first_name }}</span
            >
          </div>
          <el-dropdown-menu slot="dropdown">
           <!-- <el-dropdown-item command="language">
              {{ language === "zh" ? "切换英文" : "Lang Chinese" }}
            </el-dropdown-item> -->
            <el-dropdown-item command="logOff">{{
              $t("logOut")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
		<img width="25px" @click="handleCommand('language')" src="../../assets/image/languageChange.png">
      </div>
    </div>
    <div class="nav-bottom"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import CartIndicator from "@/components/cart-animation/CartIndicator.vue";

export default {
  name: "navBar",
  components: { CartIndicator },
  data() {
    return {
      user_info: {},
      isLogin: false,
      token: "",
      menu: 1,
      filter_menu: 0,
      menuList: [],
      checkList: [
        { name: "首页", type: 1, route: "profile" },
        { name: "产品", type: 2, route: "Home" },
        { name: "工作站", type: 3, route: "project-group" },
        // { name: "MARKET", type: 6, route: "market" },
      ],
	  origin_web:1,
    };
  },
  created() {
    if (this.info && location.origin != 'https://fjqgy.futuroartigiano.net') {
      this.user_info = this.info;
      if (this.user_info.user_type == 2 || this.user_info.user_type == 4) {
        this.menuList = [
          ...this.checkList,
          ...[{ name: "表单", type: 4, route: "lists", query: { type: 1 } }],
        ];
      } else {
        this.menuList = [
          ...this.checkList,
          ...[
            {
              name: "表单",
              type: 5,
              route: "lead",
              query: { menu: "publicInquiry" },
            },
          ],
        ];
      }
    }else{
		this.menuList = [
		  ...this.checkList,
		];
	}
	if(location.origin == 'https://bom.futuroartigiano.net' || location.origin == 'http://en.ranchfootwear.com' || location.origin == 'http://www.ranchfootwear.com' || location.origin == 'http://192.168.4.3:8080'){
		this.origin_web = 2;
	}else if(location.origin == 'http://localhost:8080' || location.origin == 'https://fjqgy.futuroartigiano.net'){
		this.origin_web = 3;
	}
    this.menu = this.menuType;
    this.checkLogin();
  },
  computed: {
    ...mapState("jiyang/user", ["info", "userRole"]),
    ...mapState("jiyang/user", ["language"]),
    ...mapState("jiyang/menu", ["menuType"]),
    ...mapGetters("jiyang/cart", ["cartTotal"]),
  },
  watch: {
    $route(to,from) {
		console.log(to);
      this.checkLogin();
	  if(to.name == 'login'){
		  this.menu = -1;
	  }else if(to.name == 'register'){
		  this.menu = -2;
	  }
    },
    info(val) {
      this.user_info = val;
	  if(location.origin == 'https://fjqgy.futuroartigiano.net'){
		  return;
	  }
      if (this.userRole == "custom") {
        this.menuList = [
          ...this.checkList,
          ...[{ name: "LISTS", type: 4, route: "lists", query: { type: 1 } }],
        ];
      } else {
        this.menuList = [
          ...this.checkList,
          ...[
            {
              name: "CRM",
              type: 5,
              route: "customer",
              query: { menu: "myCustomer" },
            },
          ],
        ];
      }
    },
    menuType(val) {
      this.menu = val;
    },
  },
  methods: {
    ...mapActions("jiyang/account", ["logout"]),
    ...mapMutations("jiyang/menu", ["setMenu"]),

    ...mapMutations("jiyang/user", ["setLanguage"]),
    ...mapMutations("jiyang/cart", ["setFormId"]),
    //下拉菜单点击
    handleCommand(command) {
		console.log(command);
      if (command == "logOff") {
        this.logOff();
      } else if (command == "toDesign") {
        this.toProduct(1);
        this.filter_menu = 1;
      } else if (command == "toThreeD") {
        this.toProduct(2);
        this.filter_menu = 2;
      } else if (command == "toProduct") {
        this.toProduct(3);
        this.filter_menu = 3;
      } else if (command === "language") {
        if (this.language == "zh") {
          this.$i18n.locale = "en";
          this.setLanguage("en");
        } else {
          this.$i18n.locale = "zh";
          this.setLanguage("zh");
        }
      }
    },
    //获取表单列表
    toGetFormList() {
      let data = {
        page: 1,
        page_size: 1,
        type: 1,
        search: {
          state: 1,
        },
      };
      this.$api.getFormList(data).then((res) => {
        if (res.total > 0) {
          this.setFormId(res.data[0].id);
        } else {
          this.setFormId(0);
        }
      });
    },
    //筛选列表
    toProduct(type) {
      this.$router.push({
        name: "Home",
        query: {
          type,
        },
      });
    },
    //验证是否登录
    checkLogin() {
      this.token = this.$cookie.get("token") ? this.$cookie.get("token") : "";
      // this.user_info=this.$cookie.get('user_info')?JSON.parse(this.$cookie.get('user_info')):{}
      if (this.token && this.user_info) {
        this.isLogin = true;
        this.toGetFormList();
      } else {
        this.isLogin = false;
      }
    },
    //跳转路由
    changeMenu(val) {
      this.menu = val.type;
      this.setMenu(val.type);
      if (val.type == 2) {
        this.toProduct(0);
        this.filter_menu = 0;
      } else {
        if (val.query) {
          this.$router.push({
            name: val.route,
            query: val.query,
          });
        } else {
          this.$router.push({
            name: val.route,
          });
        }
      }
    },
    //跳转首页
    toHome() {
      this.menu = 1;
      this.$router.push({
        name: "profile",
      });
    },
    /**
     * @description 登出
     */
    logOff() {
      this.logout({
        confirm: true,
      }).then((res) => {
        this.$message.error("Logging Out successfully");
        this.checkLogin();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  user-select: none;
  height: $head-line-height;
  width: 100%;
  min-width: 1300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);*/
  border-bottom: 1px solid #d6d3d3;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .language-color {
    color: $base-config-color;
  }
  .nav-left {
    display: flex;
    align-items: center;
    .nav-logo {
      height: 36px;
      cursor: pointer;
    }
    .nav-logo-text {
      color: #333;
      font-size: 26px;
      font-weight: 600;
      margin-left: 10px;
      margin-bottom: 4px;
    }
  }
  .nav-center {
    display: flex;
    align-items: center;
    margin-left: 60px;
    .nav-center-item {
      height: 40px;
      line-height: 40px;
      font-size: $head-font-size;
      font-weight: $head-font-weight;
      margin: 0 20px;
      cursor: pointer;
      color: #626262;
      border: none !important;
    }
    .nav-center-item-active {
      color: $base-config-color;
    }
  }
  .nav-right {
  }
}
.nav-bottom {
  height: $head-line-height;
  width: 100%;
}

.nav-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .header-user {
    display: flex;
    align-items: center;
    .header-user-img-box {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 1px solid #f2f2f2;
    }
    .header-user-img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    .header-user-name {
      font-size: 12px;
      margin: 0 12px;
    }
    .header-user-exit {
      font-size: 16px;
      color: #268eb9;
      text-decoration: underline;
      margin-right: 46px;
      cursor: pointer;
    }
    .header-user-login {
      font-size: 16px;
      margin-left: 12px;
      color: #999;
      cursor: pointer;
    }
  }
  .navLoginRegister{
	  margin: 0px 5px;
	  color:rgb(138,138,138);
	  font-weight: bold;
  }
}
</style>

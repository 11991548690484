<template>
  <div class="export-right-page" v-if="formData.formPage">
    <div id="pageHeader">
      <component
        v-if="formData.formHeader.data"
        is="formHeader"
        :checkEdit="checkEdit"
        :itemData="formData.formHeader.data"
      />
    </div>
    <div class="page-all-form">
      <div class="page-msg-box" id="msgTableBox" v-if="index === 0">
        <component
          v-if="formData.formPage.userMsg"
          is="normalTable"
          :customerId="customer_id"
          :customer="customer"
          ref="userMsg"
          :customArr="customArr"
          :formType="formType"
          :checkEdit="checkEdit"
          :table="formData.formPage.userMsg.tableData"
          index="userMsg"
          @addRow="addRow"
          @changeCustom="changeCustom"
        />
        <component
          v-if="formData.formPage.formSn"
          is="normalTable"
          :checkEdit="checkEdit"
          :table="formData.formPage.formSn.tableData"
          index="formSn"
          @addRow="addRow"
        />
      </div>
      <div
        class="page-product-box"
        id="formTables"
        v-if="formData.formPage.product"
      >
        <component
          is="formTable"
          :formType="formType"
          :table="productTable"
          :checkEdit="checkEdit"
          :isHistory="isHistory"
          @setAmount="setAmount"
          @removeProduct="removeProduct"
          @showProduct="showProduct"
          @updateImage="updateImage"
        />
        <component
          v-if="index === last"
          is="formTotalTable"
          :checkEdit="checkEdit"
          :totalData="formData.formPage.product.totalData"
          @addRow="addProductRow"
          @setTotal="setTotal"
        />
      </div>
      <div id="paymentBox" v-if="index === last">
        <div class="page-payment-box" v-if="formData.formPage.payment">
          <component
            is="normalTable"
            :checkEdit="checkEdit"
            :checkExport="true"
            :table="formData.formPage.payment.tableData"
            index="payment"
            @addRow="addRow"
          />
        </div>
        <div class="page-payment-box" v-if="formData.formPage.terms">
          <component
            is="normalTable"
            :checkEdit="checkEdit"
            :checkExport="true"
            :table="formData.formPage.terms.tableData"
            index="terms"
            @addRow="addRow"
          />
        </div>
      </div>
    </div>
    <component
      v-if="formData.formFooter.data"
      is="formFooter"
      :checkEdit="checkEdit"
      :itemData="formData.formFooter && formData.formFooter.data"
    />
    <addProductDialog
      v-if="isShowProduct"
      :top="productTop"
      @closeDialog="closeDialog"
      @submitProduct="submitProduct"
    ></addProductDialog>
  </div>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector";
import normalTable from "./normalTable";
import formTable from "./formTable";
import formHeader from "./formHeader";
import formFooter from "./formFooter";
import addProductDialog from "./addProductDialog";
import formTotalTable from "./formTotalTable";
let that;
export default {
  name: "formPage",
  components: {
    formHeader,
    formFooter,
    formTable,
    normalTable,
    addProductDialog,
    formTotalTable,
  },
  props: {
    checkEdit: {
      type: Boolean,
      default: false,
    },
    formType: {
      type: Number,
      default: 2,
    },
    table: {
      type: Array,
    },
    templateData: {
      type: Object,
    },
    productData: {
      type: Array,
    },
    index: {
      type: Number,
    },
    last: {
      type: Number,
    },
    customId: {
      type: Number,
      default: 0,
    },
    customer: {
      type: Object,
    },
    isHistory: {
      type: Boolean,
      default:false
    },
  },

  data() {
    return {
      tableData: this.table,
      productTable: this.productData,
      checkCreate: true,
      formIndex: 0,
      sublineLeft: 0,
      isShowSubline: false,
      isShowProduct: false,
      productTop: 0,
      productIndex: 0,
      customer_id: this.customId,
      customArr: [],
      formData: this.templateData,
      pageBottom: 0,
      checkWatch: false,
    };
  },
  watch: {
    customId(val) {
      this.customer_id = val;
      this.$nextTick(() => {
        this.$refs.userMsg.custom_id = val;
      });
    },
    customer(val) {
      this.$nextTick(() => {
        this.$refs.userMsg.customer = val;
      });
    },
    templateData(val) {
      this.formData = val;
      if (val.customer_id) {
        this.customer_id = val.customer_id;
      } else {
        this.customer_id = 0;
      }
      if (!this.checkWatch) {
        let erd = elementResizeDetectorMaker();
        this.$nextTick(async () => {
          //监听基础信息
          await erd.listenTo(
            document.getElementById("paymentBox"),
            that.resizeMsg
          );
          await erd.listenTo(
            document.getElementById("pageHeader"),
            that.getHeaderHieght
          );
          erd.listenTo(document.getElementById("formTables"), that.resizeTable);
        });
        this.checkWatch = true;
      }
    },
    productData(val) {
      this.productTable = val;
    },
  },
  created() {
    this.toGetCustomerList();
  },
  mounted() {
    that = this;
  },
  methods: {
    //显示选择商品
    showProduct(data) {
      if (this.checkEdit) return;
      let formRight = document.getElementById("formRight");
      let scrollTop = formRight.scrollTop;
      this.productIndex = data.index;
      this.productTop = data.top + scrollTop;
      this.isShowProduct = true;
    },
    toGetCustomerList() {
      this.$api.getOwnerCustomerList({ pageSize: 1000 }).then((res) => {
        // this.currentPage = res.current_page;
        this.customArr = res.data;
      });
    },
    //提交选中商品
    submitProduct(data) {
      let item = data.data;
      this.productTable.splice(this.productIndex, 1, ...item);
      console.log(this.productTable)
      this.formData.formPage.product.tableData.splice(this.productIndex, 1, ...item);
    },
    //设置图片
    updateImage(data){
      let {index,url} = data;
      this.productTable[index].img_url = url;
      this.$set(
          this.formData.formPage.product.tableData,
          index,
          this.productTable[index]
      );
    },
    //设置总数
    setAmount(data) {
      let index = data.index;
      this.productTable[index].amount = data.amount;

      this.$set(
        this.formData.formPage.product.tableData,
        index,
        this.formData.formPage.product.tableData[index]
      );
      this.$emit("setTotal");
    },
    //设置总价
    setTotal() {
      let total = this.formData.formPage.product.tableData.reduce(
        (total, item) => {
          return total + item.amount;
        },
        0
      );
      let totalData = this.formData.formPage.product.totalData;
      totalData.subTotal = total;
      totalData.grandTotal =
        Math.floor(total * (totalData.discount / 100) * 100) / 100;
    },
    //关闭弹窗
    closeDialog() {
      this.isShowProduct = false;
    },
    //监听信息
    resizeMsg(element) {
      this.pageBottom = element.offsetHeight;
      console.log(this.pageBottom);
    },
    getHeaderHieght(element) {
      this.pageHeader = element.offsetHeight;
    },
    //监听表格
    resizeTable(element) {
      let { offsetTop, offsetHeight } = element;
      let height = offsetTop + offsetHeight + 40 + this.pageBottom;
      // that.$emit("getTop", {
      //   offsetTop: offsetTop,
      //   pageBottom: this.pageBottom,
      //   pageHeader: this.pageHeader,
      // });

      if (height >= 1634 && this.checkCreate) {
        this.checkCreate = false;
        that.$emit("createPage");
      }
    },
    //选择客户
    changeCustom(data) {
      const customer = this.customArr.find((c) => c.user_id == data.id);
	  this.customer_id = customer.user_id;
	  this.formData["customer_id"] = customer.user_id;
      const values = this.formData.formPage.userMsg.tableData.data;
      values[2].detail.value = customer.address;
      values[3].detail.value = customer.user_mobile;
      values[4].detail.value = customer.user_email;
      this.$emit("getCustom", customer);
    },
    //添加数据
    addProductRow() {
      this.formData.formPage.product.tableData.push({
        style_number: "",
        img_url: "",
        intro: "",
        size: "",
        color: "",
        number: "",
        price: "",
        amount: "",
      });
      this.$emit("addProductRow");
    },
    //移除数据
    removeProduct(data) {
      let index = data.index;
      this.productData.splice(index,1)
      // this.formData.formPage.product.tableData.splice(index, 1);
    },
    //选中表单
    chooseTable(val, key) {
      this.formIndex = key;
    },
    addProduct() {
      this.tableData.push({
        style: "",
        picture: "",
        description: "",
        size: "",
        color: "",
        number: "",
        price: "",
        amount: "",
      });
    },
    addRow(data) {
      let index = data.index;
      let props = this.formData.formPage[index].tableData.props;
      let firstData = this.formData.formPage[index].tableData.data[1];
      let obj = {};
      props.forEach((val) => {
        let type = firstData[val.prop].type;
        obj[val.prop] = { value: "", type };
      });
      this.formData.formPage[index].tableData.data.push(obj);
    },
  },
};
</script>

<style lang="scss">
.export-right-page {
  margin: 10px auto;
  /*width: 856px;*/
  width: 1155px;
  height: 1633px;
  /*min-height: 1211px;*/
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
  position: relative;
  /*overflow: hidden;*/
  margin-bottom: 60px;
  .page-all-form {
    padding: 0 40px;
  }
  .el-card__body {
    padding: 0 !important;
  }
  .el-input__inner {
    border: none !important;
    /*border-bottom:1px solid #c0c4cc!important;*/
    border-radius: 0 !important;
    padding: 0 4px !important;
    background-color: transparent !important;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 30px !important;
  }
  .el-table {
    user-select: none !important;
  }
  .page-msg-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .page-product-box {
    margin-top: 40px;
    width: 100%;
  }
  .page-payment-box {
    width: 532px;
    margin-bottom: 18px;
  }
  .subline {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    border-left: 1px dashed $base-config-color;
  }
  .module-position {
    position: absolute;
    z-index: 1001;
    box-sizing: content-box;
    .move-box {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      background-color: $base-config-color;
    }
  }
  .module-position-active {
    border: 1px dashed $base-config-color;
    display: block;
    z-index: 9999;
  }

  .cellBg {
    background-color: #ebf8f9;
  }

  .form-text-color {
    color: $base-config-color;
  }
  .form-text-weight {
    font-weight: 600;
  }
  .form-footer {
    height: 14px;
    position: absolute;
    bottom: 28px;
  }
}
</style>

export default ({ request }) => ({
  quoted() {
    return request({
      url: `/v1/bomtable`,
      method: "get",
      params,
    });
  },
  required() {},
});

import api from "@/api";
export default {
  namespaced: true,
  state: {
    countList: {},
  },
  mutations: {
    setCount(state, list) {
      state.countList = list;
    },
  },
  actions: {
    getCount({ state, commit }) {
      api.unreadCount().then((res) => {
        commit("setCount", res);
      });
    },
  },
};

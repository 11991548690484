<template>
  <div class="form-card">
    <div class="form-card-header">
      <span>{{ item.sn }}</span>
      <span v-if="formType == 2 && userRole === 'custom'"
        >{{ item.salesman.last_name }}{{ item.salesman.first_name }}</span
      >
      <span v-else
        >{{ item.customer.last_name }}{{ item.customer.first_name }}</span
      >
    </div>
    <div class="form-card-bottom">
      <div class="form-card-detail">
        <div></div>
        <div>{{ item.create_time }}</div>
      </div>
      <div class="form-card-btn">{{ stateText }}</div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("jiyang/user");
export default {
  name: "formCard",
  props: {
    item: {
      type: Object,
    },
    formType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      stateArr: [
        "已关闭",
        "未询盘",
        "已发送询盘",
        "已询盘",
        "未报价",
        "已报价",
        "已确认报价",
        "已支付",
        "已完成",
      ],
    };
  },
  computed: {
    ...mapState(["info", "userRole"]),
    stateText() {
      let num = this.item.state;
      if (this.item.state < 0) {
        num = 0;
      }
      return this.stateArr[num];
    },
  },
};
</script>
<style lang="scss">
.form-card {
  width: 420px;
  height: 122px;
  border: 1px solid #b6b6b6;
  background-color: #fff;
  padding: 14px 22px 26px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
  cursor: pointer;
  .form-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #000;
  }
  .form-card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;

    .form-card-detail {
      color: #3e3e3e;
      font-weight: 300;
    }
    .form-card-btn {
      width: 85px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      background-color: #cce5ff;
    }
  }
}
</style>

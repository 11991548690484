import VueCookies from "vue-cookie";
export default {
  namespaced: true,
  state: {
    // 用户信息
    info: {},
    //用户角色
    userRole: "custom",
    //  语言
    language: "en",
  },
  mutations: {
    //设置语言
    setLanguage(state, language) {
      if (language) {
        state.language = language;
        VueCookies.set("language", language);
      }
    },
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} state vuex state
     * @param {*} info info
     */
    set({ state, dispatch }, info) {
      return new Promise((resolve) => {
        // store 赋值
        state.info = info;
        if (info.user_type == 4 || info.user_type == 2) {
          state.userRole = "custom";
        } else {
          state.userRole = "company";
        }
        // 持久化
        VueCookies.set("user_info", JSON.stringify(info), 30);
        // end
        resolve();
      });
    },
    /**
     * @description 从cookie取用户数据
     * @param {Object} state vuex state
     */
    load({ state }) {
      return new Promise((resolve) => {
        // store 赋值
        state.info = JSON.parse(VueCookies.get("user_info"));
        if (state.info.user_type == 4 || state.info.user_type == 2) {
          state.userRole = "custom";
        } else {
          state.userRole = "company";
        }
        if (VueCookies.get("language")) {
          state.language = VueCookies.get("language");
        }
        // end
        resolve();
      });
    },
  },
};

<template>
  <div id="app">
    <navBar v-if="!$route.meta.hideNav"></navBar>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <div class="footerBox">
      <a href="https://beian.miit.gov.cn/">闽ICP备2024041607号-1</a>
    </div>
    <!-- <chat v-if="isUserLogin"></chat> -->
  </div>
</template>
<script>
import navBar from "@/components/navBar/navBar";
import chat from "@/components/chat/index";
import { mapState } from "vuex";

export default {
  components: {
    navBar,
    chat,
  },
  computed: {
    ...mapState("jiyang/account", ["isUserLogin"]),
  },
};
</script>
<style lang="scss">
@import "./assets/style/config.scss";
@import "./assets/font/font.css";
#app {
  // font-family: Helvetica, "Microsoft YaHei", 微软雅黑;
  font-family: Avenir, Helvetica, "Microsoft YaHei", 微软雅黑 Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #fff;

  .footerBox {
    position: fixed;
    bottom: 0px;
    text-align: center;
    width: 100%;
    background: #fff;
  }
}

body {
  line-height: 1 !important;
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<template>
  <div class="select-goods-dialog" style="cursor:move"  id="boxProducts"  @mousedown="move">
    <div class="goods-filter">
      <el-select v-model="goodsType" @change="changeGoodsType">
        <el-option
            v-for="item in goodsTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-input
          style="width: 250px;margin:0 10px;"
        class="list-search-input"
        v-model="search_form.name"
        @keyup.enter.native="search"
        placeholder="请搜索名称"
          size="small"
      ></el-input>
      <el-button       size="small" @click="search">搜索</el-button>
    </div>
    <div class="goods-list" ref="goodsList">
      <designView
        v-for="val in tableData"
        :key="val.id"
        :itemData="val"
        :isToChooseType="2"
        :isChoose="val.check"
        @chooseProduct="chooseProduct"
      ></designView>
      <div v-if="tableData.length == 0" class="empty-box">
        <emptyPage></emptyPage>
      </div>
    </div>

    <div class="dialog-bottom" v-if="tableData.length > 0">
      <div class="submit-goods-btn" @click="submitProduct">Submit</div>
    </div>
  </div>
</template>

<script>
import designView from "../designView/designView";
import emptyPage from "@/components/emptyPage";
import elementResizeDetectorMaker from "_element-resize-detector@1.2.4@element-resize-detector";
export default {
  name: "selectGoodsDialog",
  components: {
    designView,emptyPage
  },
  props:{
    formTable: {
      type: Array,
    },
  },
  data() {
    return {
      tableData: [],
      chooseList: [],
      page: 1,
      page_size: 10,
      last_page: 1,
      search_form: {
        name: "",
      },
      goodsType:1,
      goodsTypeList:[
        {label:'商品库',value:1},
        {label:'我的收藏',value:2},
      ],
      formData:this.formTable
    };
  },
  watch:{
    formTable(val) {
      this.formData = val.filter(val=>val.id)
      console.log(this.formData)
    },
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.$nextTick(()=>{
      this.$refs.goodsList.addEventListener("scroll", this.lazyLoading); // 滚动到底部，再加载的处理事件

    })
  },
  methods: {
    //选中商品
    chooseProduct(data) {
      this.tableData.some((val, key) => {
        if (val.id === data.item.id) {
          val.check = !data.check;
          this.$set(this.tableData, key, val);
          return true;
        }
      });
      if (!data.check) {
        this.chooseList.push(data.item);
      } else {
        this.chooseList.some((val, key) => {
          if (val.id === data.item.id) {
            this.chooseList.splice(key, 1);
            return true;
          }
        });
      }
    },
    changeGoodsType(){
      this.page = 1;
      this.tableData = [];
      if(this.goodsType===1){
        this.getTableData();
      }else{
        this.getLikeGoods()
      }
    },
    search() {
      this.page = 1;
      this.tableData = [];
      this.getTableData();
    },
    //获取收藏商品
    getLikeGoods(){
      this.$api.queryWish().then(resData=>{

        let data = {
          page: this.page,
          page_size: this.page_size,
          type:0,
          search:this.search_form.name,
          foldId:resData.id
        };
        this.$api.queryProductList(data).then((res) => {
          if(res.data&&res.data.length>0){
            let datas = res.data;
            if (datas) {
              datas.forEach((val, key) => {
                if(this.formData.length>0){
                  let check=this.formData.some(item=>{
                    if(item.id===val.id){
                      datas[key] = { ...val, ...{ check: true } };
                      this.chooseList.push(datas[key])
                      return true
                    }
                  })
                  if(!check){
                    datas[key] = { ...val, ...{ check: false } };
                  }
                }else{
                  datas[key] = { ...val, ...{ check: false } };
                }
                datas[key]["file_info"]=JSON.parse(datas[key].file_info)
                datas[key]["img_url"] = datas[key].file_info
                    ? datas[key].file_info.main_file.img_url
                    : "";
                datas[key]["amount"] = 0;
                datas[key]["number"] = "";
              });
            }
            this.tableData = [...this.tableData, ...datas];

            this.last_page = res.last_page;
          }

        });
      })

    },
    // 滚动加载
    lazyLoading(e) {
      const { scrollTop, clientHeight: windowHeight, scrollHeight } = e.target; // 滚动条滚动时，距离顶部的距离
      // 滚动条到底部
      if (scrollTop + windowHeight === scrollHeight) {
        this.page++;
        if (this.page > this.last_page) return;
        this.getTableData();
      }
    },
    //清空状态
    clearGoods() {
      this.chooseList = [];
      this.tableData.forEach((val, key) => {
        val.check = false;
        this.tableData[key] = val;
      });
    },
    //提交商品
    submitProduct() {
      if (this.chooseList.length === 0) {
        this.$message.error("请选择商品");
        return false;
      }
      this.$emit("submitProduct", {
        data: this.chooseList,
      });
    },
    //切换页面
    changePage(type) {
      if (type === 1) {
        if (this.page === 1) return false;
        this.page--;
      } else {
        if (this.page === this.last_page) return false;
        this.page++;
      }
      this.getTableData();
    },
    //获取列表
    getTableData() {
      let data = {
        page: this.page,
        page_size: this.page_size,
      };
      let search = {};
      search = this.search_form;
      data = { ...data, ...{ search } };
      this.$api.getProductList(data).then((res) => {
        let datas = res.data;
        if (datas) {
          datas.forEach((val, key) => {
            if(this.formData.length>0){
              let check=this.formData.some(item=>{
                if(item.id===val.id){
                  datas[key] = { ...val, ...{ check: true } };
                  this.chooseList.push(datas[key])
                  return true
                }
              })
              if(!check){
                datas[key] = { ...val, ...{ check: false } };
              }
            }else{
              datas[key] = { ...val, ...{ check: false } };
            }

            datas[key]["img_url"] = datas[key].file_info
              ? datas[key].file_info.main_file.img_url
              : "";
            datas[key]["amount"] = 0;
            datas[key]["number"] = "";
          });
        }
        this.tableData = [...this.tableData, ...datas];

        this.last_page = res.last_page;
      });
    },
	move(e) {
	  var box = document.getElementById("boxProducts");
	  let odiv = e.target; //获取目标元素
	  //算出鼠标相对元素的位置
	  let disX = e.clientX - box.offsetLeft;
	
	  let disY = e.clientY - box.offsetTop;

	  //console.log(odiv.offsetLeft);
	
	  //odiv.style.position = 'absolute';
	  document.onmousemove = (e) => {
	    //鼠标按下并移动的事件
	    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
	    let left = e.clientX - disX;
	    let top = e.clientY - disY;
	
	    //移动当前元素
	    box.style.left = left + "px";
	    box.style.top = top + "px";
	    return false;
	  };
	  document.onmouseup = (e) => {
	    document.onmousemove = null;
	    document.onmouseup = null;
		
	  };
	},
  },
};
</script>

<style lang="scss">
.select-goods-dialog {
  position: fixed;
  bottom: 0;
  left: 10px;
  z-index: 2000;
  width: 534px;
  height: 698px;
  padding: 30px 0;
  background: #fbfbfb;
  box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.3);
  .goods-filter {
    width: 100%;
    display: flex;
    align-items: center;
    height: 34px;
    margin-bottom: 14px;
    padding: 0 24px;
    .el-input__inner {
      height: 34px;
    }
  }
  .empty-box{
    margin: 40px auto;

  }
  .goods-list {
    width: 100%;
    padding: 4px 24px;
    height: 570px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: auto;
  }
  .dialog-bottom {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    border-top: 1px solid #141414;
    .submit-goods-btn {
      width: 120px;
      height: 30px;
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #16c39a;
      cursor: pointer;
    }
  }
}
</style>

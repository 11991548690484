import Vue from "vue";
import Vuex from "vuex";
import { Message } from "element-ui";
Vue.use(Vuex);
import jiyang from "./getter.js";
export default new Vuex.Store({
  state: {
    current: Date.now(), // 当前时间
    intervalID: 0,
    message: undefined,
    sendIMmsg: {
      msgid: 0, //发送商品或bom单ID
      name: 0, //发送商品或bom单ID
      url: 0, //发送商品或bom单ID
      type: 0, //0商品  1bom单
    },
  },
  getters: {
    hidden(state) {
      // eslint-disable-next-line no-unused-vars
      const temp = state.current;
      if (typeof document.hasFocus !== "function") {
        return document.hidden;
      }
      return !document.hasFocus();
    },
    sendIMmsg(state) {
      return state.sendIMmsg;
    },
  },
  mutations: {
    startComputeCurrent(state) {
      state.intervalID = setInterval(() => {
        state.current = Date.now();
      }, 500);
    },
    stopComputeCurrent(state) {
      clearInterval(state.intervalID);
      state.intervalID = 0;
    },
    showMessage(state, options) {
      if (state.message) {
        state.message.close();
      }
      state.message = Message({
        message: options.message,
        type: options.type || "success",
        duration: options.duration || 2000,
        offset: 40,
      });
    },
    callIM(state, msg) {
      state.sendIMmsg.msgid = msg.id;
      state.sendIMmsg.name = msg.name;
      state.sendIMmsg.url = msg.url;
      state.sendIMmsg.type = msg.type;
    },
  },
  modules: {
    jiyang,
  },
});

<template>
  <a-form class="material-form">
    <a-form-item v-if="material.hasOwnProperty('name')">
      <span slot="label">
        {{ $t("materialName") }}
      </span>
      <a-input
        v-model="material.name"
        :placeholder="$t('placeHolderMaterialName')"
        @change="handleNameChange"
      />
    </a-form-item>
    <a-form-item v-if="material.hasOwnProperty('sn')">
      <span slot="label">
        {{ $t("materialNumber") }}
      </span>
      <a-input
        v-model="material.sn"
        :placeholder="$t('placeHolderMaterialNumber')"
        @change="handleNumberChange"
      />
    </a-form-item>
	<a-form-item>
		<span slot="label">
		  {{ $t("materialType") }}
		</span>
	  <a-select mode="multiple" :placeholder="$t('placeHolderMaterialStype')" v-model="materialType" @change="handleSelectChange">
	    <a-select-option v-for="item in partList" :value="item.id">
	      {{ item.name }}
	    </a-select-option>
	  </a-select>
	</a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("placement") }}
      </span>
	  <el-cascader
	      :options="placementOptions"
	      :props="{multiple: true}"
	      collapse-tags
	      clearable>
	  </el-cascader>
     <!-- <a-cascader
        :options="placementOptions"
        :placeholder="$t('pleaseSelect')"
		multiple
        @change="onChangePlacement"
      ></a-cascader> -->
    </a-form-item>
    <a-row>
      <a-col :span="8" v-if="material.param.hasOwnProperty('albedoTexture')">
        <a-form-item>
          <span slot="label">
            {{ $t("alphaSwitch") }}
          </span>
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model="material.param.transparencyMode"
            @change="switchChange"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item>
          <span slot="label">
            {{ $t("isPrivate") }}
          </span>
          <a-switch
            checked-children="公开"
            un-checked-children="私有"
            v-model="!material.is_private"
            @change="switchChange2"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item>
          <span slot="label">
            {{ $t("isSpecial") }}
          </span>
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="material.is_special"
            @change="switchChange3"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="material.param.hasOwnProperty('roughness')">
      <span slot="label">
        {{ $t("materialRoughness") }}
      </span>
      <a-slider
        :min="0.3"
        :max="1"
        :step="0.1"
        v-model="material.param.roughness"
        @change="handleRoughnessChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("environmentIntensity") }}
      </span>
      <a-slider
        :min="0.1"
        :max="5"
        :step="0.1"
        v-model="material.param.environmentIntensity"
        @change="handleEnvironmentIntensityChange"
      />
    </a-form-item>
    <a-form-item v-if="material.param.hasOwnProperty('albedoTexture')">
      <span slot="label">
        {{ $t("textureSize") }}
      </span>
      <a-slider
        :min="0.1"
        :max="2"
        :step="0.1"
        v-model="material.param.albedoTexture.uScale"
        @change="handlealbedoTexturevScaleChange"
      ></a-slider>
    </a-form-item>
    <a-form-item v-else-if="material.param.hasOwnProperty('bumpTexture')">
      <span slot="label">
        {{ $t("textureSize") }}
      </span>
      <a-slider
        :min="0.1"
        :max="2"
        :step="0.1"
        v-model="material.param.bumpTexture.uScale"
        @change="handlealbedoTexturevScaleChange"
      ></a-slider>
    </a-form-item>
    <!-- <a-form-item v-if="material.param.hasOwnProperty('bumpTexture')">
      <span slot="label">
        {{ $t("textureSize") }}
      </span>
      <a-slider
        :min="0.1"
        :max="2"
        :step="0.1"
        v-model="material.param.bumpTexture.uScale"
        @change="handleBumpUChange"
      ></a-slider>
    </a-form-item> -->
    <a-form-item v-if="material.param.hasOwnProperty('alpha')">
      <span slot="label">
        {{ $t("alphaIntensity") }}
      </span>
      <a-slider
        :min="0"
        :max="1"
        :step="0.1"
        v-model="material.param.alpha"
        @change="handleAlphaIntensityChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("textureColor") }}
      </span>
      <color-picker
        class="color-picker"
        v-model="material.param.albedoColor"
        @change="handleAlbeColorPicker"
        type="material"
      ></color-picker>
    </a-form-item>
  </a-form>
</template>

<script>
import ImagePicker from "@/components/ImagePicker";
import mixin from "@/components/material/mixin";
import ColorPicker from "@/components/v2/color-picker/ColorPicker.vue";
export default {
  name: "pbr",
  components: {
    ImagePicker,
    ColorPicker,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    materialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      material: this.materialData,
      showImagePick: false,
	  partList:[],
	  materialType:[],
	  placementOptions:[
		  {
			  value:'1',
			  label:'适用产品',
			  children:[
				  {
					  value:'2',
					  label:'鞋子',
					  children:[
						  {
							  value:'3',
							  label:'帮面'
						  },
						  {
							  value:'4',
							  label:'车线'
						  },
						  {
							  value:'5',
							  label:'内里'
						  },
						  {
							  value:"6",
							  label:"中底"
						  },
						  {
							  value:"7",
							  label:"大底"
						  },
						  {
							  value:"8",
							  label:"装饰"
						  },
						  {
							  value:'9',
							  label:'内在'
						  }
					  ]
				  },
				  {
					  value:'15',
					  label:'服装'
				  },
				  {
					  value:'16',
					  label:'箱包'
				  },
				  {
					  value:'17',
					  label:'家纺'
				  },
				  {
					  value:'18',
					  label:'其它'
				  }
			  ]
		  },
		  {
			  value:"10",
			  label:"材料类型",
			  children:[]
		  },
		  {
			  value:"11",
			  label:"色系",
			  children:[]
		  },
		  {
			  value:'12',
			  label:'纹理',
			  chidlren:[]
		  },
		  {
			  value:'13',
			  label:'功能',
			  chidlren:[]
		  },
		  {
			  value:'14',
			  label:'后处理',
			  children:[]
		  }
	  ],
    };
  },
  created() {
	this.$api.getPart({}).then((res) => {
	  this.partList = res;
	  this.materialType = this.material.part_type.map(Number);
	}); 
  },
  watch: {
    materialData() {
      this.material = this.materialData;
      if (this.material.param && this.material.param.albedoColor) {
        let rgb =
          "rgb(" +
          this.material.param.albedoColor.r * 255 +
          "," +
          this.material.param.albedoColor.g * 255 +
          "," +
          this.material.param.albedoColor.b * 255 +
          ")";
          this.global.PickrMaterialColor.setColor(rgb);
		  let _this = this;
		 setTimeout(function () {
			_this.global.PickrMaterialColor.setColor(rgb);
		 }, 100);
      }
	  
	  this.emit(this.material);
    },
  },
  methods: {
    handleNameChange(e) {
      this.material.name = e.target.value;
      this.emit(this.material);
    },
    handleNumberChange(e) {
      this.material.sn = e.target.value;
      this.emit(this.material);
    },
    handleChange() {
      this.emit(this.material);
    },
    handleRoughnessChange(val) {
      this.global.NewMaterialScene.pickedMesh.material.roughness = val;
      // this.material.param.roughness = val;
      // this.emit(this.material);
    },
    handlemetallicChange(val) {
      this.material.param.metallic = val;
      this.emit(this.material);
    },
    handleEnvironmentIntensityChange(val) {
      if (this.global.NewMaterialScene.pickedMesh.material)
        this.global.NewMaterialScene.pickedMesh.material.environmentIntensity =
          val;
      // this.material.param.environmentIntensity = val;
      // this.emit(this.material);
    },
    handlealbedoTexturevScaleChange(val) {
      if (this.global.NewMaterialScene.pickedMesh.material.albedoTexture)
        this.global.NewMaterialScene.pickedMesh.material.albedoTexture.vScale =
          val;
      if (this.global.NewMaterialScene.pickedMesh.material.albedoTexture)
        this.global.NewMaterialScene.pickedMesh.material.albedoTexture.uScale =
          val;
      if (this.global.NewMaterialScene.pickedMesh.material.bumpTexture)
        this.global.NewMaterialScene.pickedMesh.material.bumpTexture.vScale =
          val;
      if (this.global.NewMaterialScene.pickedMesh.material.bumpTexture)
        this.global.NewMaterialScene.pickedMesh.material.bumpTexture.uScale =
          val;
      // this.material.param.albedoTexture = {
      //   ...this.material.param.albedoTexture,
      //   vScale: val,
      //   uScale: val,
      // };
      // this.emit(this.material);
    },
    handlealbedoTextureUScaleChange(val) {
      this.material.param.albedoTexture = {
        ...this.material.param.albedoTexture,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleOkAlbedoTexture(file) {
      if (file) {
        this.material.param.albedoTexture = {
          ...this.material.param.albedoTexture,
          ...file,
        };
      } else {
        this.material.param.albedoTexture = {
          ...this.material.param.albedoTexture,
          url: null,
          id: null,
          type: null,
          material: null,
        };
      }
      this.emit(this.material);
    },
    handleOkBumpTexture(file) {
      if (file) {
        this.material.param.bumpTexture = {
          ...this.material.param.bumpTexture,
          ...file,
        };
      } else {
        this.material.param.bumpTexture = {
          ...this.material.param.bumpTexture,
          url: null,
          id: null,
          type: null,
          material: null,
        };
      }
      this.emit(this.material);
    },
    handleBumpVChange(val) {
      this.material.param.bumpTexture = {
        ...this.material.param.bumpTexture,
        vScale: val,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleBumpUChange(val) {
      this.material.param.bumpTexture = {
        ...this.material.param.bumpTexture,
        uScale: val,
        vScale: val,
      };
      this.emit(this.material);
    },
    handleOkReflection(file) {
      if (file) {
        this.material.param.reflectionTexture = {
          ...this.material.param.reflectionTexture,
          ...file,
        };
      } else {
        this.material.param.reflectionTexture = {
          ...this.material.param.reflectionTexture,
          url: null,
          id: null,
          type: null,
          material: null,
        };
      }
      this.emit(this.material);
    },
    handleOkambientTexture(file) {
      if (file) {
        this.material.param.ambientTexture = {
          ...this.material.param.ambientTexture,
          ...file,
        };
      } else {
        this.material.param.ambientTexture = {
          ...this.material.param.ambientTexture,
          url: null,
          id: null,
          type: null,
          material: null,
        };
      }
      this.emit(this.material);
    },
    handleambientTextureUChange(val) {
      this.material.param.ambientTexture = {
        ...this.material.param.ambientTexture,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleambientTextureVChange(val) {
      this.material.param.ambientTexture = {
        ...this.material.param.ambientTexture,
        vScale: val,
        vScale: val,
      };
      this.emit(this.material);
    },
    handleAlbeColorPicker(e) {
      this.global.NewMaterialScene.pickedMesh.material.albedoColor =
        new BABYLON.Color3(e.r, e.g, e.b);
      this.global.NewMaterialScene.pickedMesh.material.reflectionColor =
        new BABYLON.Color3(e.r, e.g, e.b);
      // this.material.param.albeColor = e;
      //    this.material.param.reflectionColor = e;
      //    this.emit(this.material);
    },
    switchChange(checked) {
      if (checked) {
        this.global.NewMaterialScene.pickedMesh.material.transparencyMode = 3;
        // this.material.param.transparencyMode = 3;
        // this.emit(this.material);
      } else {
        this.global.NewMaterialScene.pickedMesh.material.transparencyMode =
          null;
        // this.material.param.transparencyMode = null;
        // this.emit(this.material);
      }
    },
    switchChange2(checked) {
      if (checked) {
        this.material.is_private = 0;
      } else {
        this.material.is_private = 1;
      }
      console.log(this.material);
      this.emit(this.material);
    },
    switchChange3(checked) {
      if (checked) {
        this.material.is_special = 1;
      } else {
        this.material.is_special = 0;
      }
      this.emit(this.material);
    },
    handleAlphaIntensityChange(val) {
      // this.material.param.alpha = val;
      // this.emit(this.material);
      this.global.NewMaterialScene.pickedMesh.material.alpha = val;
    },
	handleSelectChange(){
		this.material.part_type = this.materialType;
		this.emit(this.material);
	}
  },
  mixins: [mixin],
};
</script>
<style lang="scss">
.ant-collapse {
  border-radius: 0;
}

.color-preview--wrap {
  position: relative;
}
.color-preview {
  width: 32px;
  height: 32px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.color-picker{
	width: 100%;
}
</style>

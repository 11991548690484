<template>
  <div>
    <div class="login-box">
      <div class="login-form">
        <img class="login-logo" src="@/assets/logo.png" />
        <div class="register-title">
          {{ $t("forgetPassword") }}
        </div>
        <el-form
          class="login-input-box"
          ref="loginForm"
          label-position="top"
          :rules="rules"
          :model="formLogin"
          size="default"
        >
          <el-form-item prop="user_email" class="login-input-item">
            <el-input
              type="text"
              class="login-input"
              v-model="formLogin.user_email"
              :placeholder="$t('email')"
            >
            </el-input>
			<div class="text-error" v-if="show_check_email">
			  {{ check_email }}
			</div>
          </el-form-item>
          <el-form-item prop="sms_code" class="login-input-item">
            <el-input
              type="text"
              class="login-input"
              v-model="formLogin.sms_code"
              :placeholder="$t('loginCodePlaceholder')"
            >
              <el-button
                slot="append"
                :disabled="showCount"
                @click="onGetCode"
                size="mini"
              >
                <span class="code-btn" v-if="!showCount">{{
                  isGetCode?$t("getCode"):$t("pushCode")
                }}</span>
                <span v-else>{{ sec }}</span>
              </el-button>
            </el-input>
          </el-form-item>
          <!-- <el-form-item prop="code" class="login-input-item">
            <el-select
              placeholder="请选择国家"
              filterable
              v-model="formLogin.country"
              style="width: 100%"
            >
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.value
                }}</span>
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item prop="user_pwd" class="login-input-item">
            <el-input
              type="password"
              class="login-input"
              auto-complete="new-password"
              v-model="formLogin.user_pwd"
              :placeholder="$t('noneLoginPassword')"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            prop="check_pwd"
            class="login-input-item"
            :class="[show_check_text ? 'border-error' : '']"
          >
            <el-input
              type="password"
              v-model="check_pwd"
              auto-complete="new-password"
              @blur="checkPwd"
              :placeholder="$t('confirmPassword')"
            ></el-input>
            <div class="text-error" v-if="show_check_text">
              {{ check_text }}
            </div>
          </el-form-item>
        </el-form>
        <div class="login-btn" @click="submit">
          {{ $t("forgetPasswordBtn") }}
        </div>
        <div class="back-btn" @click="toLogin">
          {{ $t("backLogin") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countryList from "@/utils/country.js";
export default {
  name: "register",
  data() {
    return {
      formLogin: {
        user_email: "",
        user_pwd: "",
        sms_code: "",
        country: "",
      },
      check_text: "",
      show_check_text: false,
      check_pwd: "",
      code_text: this.$t('getCode'),
      sec: 60,
      showCount: false,
      country: [],
      countryList: [],
      // 校验
      rules: {
        user_email: [{ required: true, message: this.$t("noneLoginEmail"), trigger: "blur" }],
        user_pwd: [{ required: true, message: this.$t("noneLoginPassword"), trigger: "blur" }],
        sms_code: [{ required: true, message: this.$t("loginCodePlaceholder"), trigger: "blur" }],
        country: [{ required: true, message: this.$t("selectCountry"), trigger: "change" }],
      },
	  check_email: "",
	  show_check_email: false,
	  isGetCode:true,
    };
  },
  created() {
    this.country = countryList;
    this.checkCountry = countryList;
  },
  methods: {
    //筛选国家
    dataFilter(val) {
      if (val) {
        //val存在
        this.country = this.checkCountry.filter((item) => {
          if (
            !!~item.label.indexOf(val) ||
            !!~item.label.toUpperCase().indexOf(val.toUpperCase()) ||
            !!~item.value.indexOf(val) ||
            !!~item.value.toUpperCase().indexOf(val.toUpperCase())
          ) {
            return true;
          }
        });
      } else {
        //val为空时，还原数组
        this.country = this.checkCountry;
      }
    },
    // 验证确认密码
    checkPwd() {
      if (this.formLogin.user_pwd == "") {
        return true;
      }
      if (this.check_pwd != this.formLogin.user_pwd) {
        this.check_text = this.$t("twoPwdError");
        this.show_check_text = true;
        return false;
      } else if (this.check_pwd == "") {
        this.check_text = this.$t("noneConfirmPwd");
        this.show_check_text = true;
        return false;
      } else {
        this.show_check_text = false;
        return true;
      }
    },
	//验证邮箱
	checkEmail() {
	  if (this.formLogin.user_email == "") {
	    this.check_email = this.$t("noneLoginEmail");
	    this.show_check_email = true;
	    return false;
	  }
	  let myreg =
	    /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;
	  if (myreg.test(this.formLogin.user_email)) {
	    this.show_check_email = false;
	    return true;
	  } else {
	    this.check_email = this.$t("emailError");
	    this.show_check_email = true;
	    return false;
	  }
	},
    //获取验证码
    onGetCode() {
		if(this.checkEmail()){
			  let data = {
				user_email: this.formLogin.user_email,
				type: 2,
			  };
			  this.isGetCode = false;
			  this.$api.getCode(data).then((res) => {
				this.showCount = true;
				this.isGetCode = true,
				this.countDown();
			  });
		}
		return true;
		
      if (this.formLogin.mobile.trim() == "") {
        this.$message.error(this.$t("noneLoginMobile"));
        return;
      }
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (myreg.test(this.formLogin.mobile)) {
        this.formLogin.code = "";
        let data = {
          mobile: this.formLogin.mobile,
          type: 2,
        };
        this.$api.getCode(data).then((res) => {
          this.showCount = true;
          this.countDown();
        });
      } else {
        this.$message.error(this.$t("phoneError"));
      }
    },
    //跳转登录
    toLogin() {
      this.$router.back();
    },

    // 倒计时
    countDown() {
      this.timer = setInterval(() => {
        if (this.sec == 1) {
          clearInterval(this.timer);
          this.showCount = false;
          this.sec = 60;
          return;
        }
        this.sec--;
      }, 1000);
    },
    // 提交重置信息
    submit() {
      let that = this;

      if (!this.checkPwd()) {
        return;
      }
      that.$refs.loginForm.validate((valid) => {
        if (valid) {
          let loading = this.openLoading();
          that.$api.resetPwd(that.formLogin).then((res) => {
            that.$message.success(that.$t("resetSuccess"));
            that.$router.replace({
              name: "login",
            });
            loading.close();
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./account.scss";
</style>

<template>
  <div class="tab-content">
    <div class="tab-header">
      <!-- <a-input-group compact>
        <a-select style="width: 36%" default-value="all">
          <a-select-option value="all">All</a-select-option>
          <a-select-option value="other">Other</a-select-option>
        </a-select>
        <a-input
          style="width: 64%"
          placeholder="请输入内容"
          @change="handleSearch"
        />
      </a-input-group> -->
      <a-button
        icon="folder"
        @click="showFolder = !showFolder"
        type="info"
      ></a-button>
      <a-button
        icon="plus"
        type="primary"
        @click="showEditor = true"
      ></a-button>
      <!-- <a-button
        icon="edit"
        :disabled="disableEdit"
        type="success"
        @click="showEditor = true"
      ></a-button> -->
      <a-popconfirm
        :title="$t('tipDel')"
        :ok-text="$t('yes')"
        :cancel-text="$t('no')"
        @confirm="confirmDel"
        :disabled="disableDel"
      >
        <a-button icon="delete" type="danger" :disabled="disableDel"></a-button>
      </a-popconfirm>
      <a-button icon="sync" @click="handleRefresh"></a-button>
    </div>
    <folder
      :visiable.sync="showFolder"
      :folders="logoFolders"
      @selected="handleFolderSelect"
    ></folder>
    <textures
      :data="logos"
      @page-change="handlePageChange"
      @selected="handleSelect"
      :total="logoPage.total"
      :pageSize="logoPage.per_page"
      @edit="(m) => editLogo(m)"
    ></textures>
    <logo-editor
      title="Logo"
      :visible.sync="showEditor"
      :current="selected[0]"
      :logoInfo="logoInfo"
    ></logo-editor>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");
import textures from "@/components/Textures.vue";
import folder from "@/components/Folder.vue";
import LogoEditor from "@/components/logo-list/LogoEditor.vue";

export default {
  components: {
    textures,
    folder,
    LogoEditor,
  },
  data() {
    return {
      showFolder: true,
      showEditor: false,
      selected: [],
      logoInfo: {},
    };
  },

  computed: {
    ...mapState(["logos", "logoFolders", "logoPage"]),
    // ...mapGetters(["filterLogo"]),
    disableEdit() {
      return this.selected.length != 1;
    },
    disableDel() {
      return this.selected.length <= 0;
    },
  },
  created() {
    this.getLogoFolderList();
    this.getLogoList();
  },
  methods: {
    ...mapMutations(["SET_LOGOS", "SET_SEARCH", "SET_CURRENT_LOGOFOLDER"]),
    ...mapActions(["getLogoFolderList", "getLogoList", "delLogo"]),
    handleFolderSelect(idx) {
      this.SET_CURRENT_LOGOFOLDER(idx);
      this.getLogoList();
    },
    handleSelect(val) {
      this.selected = [...val];
    },
    handlePageChange(page, pageSize) {
      this.getLogoList({ page, pageSize });
    },
    handleSearch(e) {
      this.SET_SEARCH(e.target.value);
    },
    handleRefresh() {
      this.getLogoList();
    },
    confirmDel() {
      this.handleDel();
    },
    handleDel() {
      const ids = this.selected.map((s) => s.id);
      this.delLogo(ids);
      this.init();
    },
    init() {
      this.selected = [];
    },
    editLogo(m) {
      this.showEditor = true;
      this.logoInfo = m;
    },
  },
};
</script>

<style lang="scss">
// .resource-list {
//   height: 80vh;
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   align-content: flex-start;
//   row-gap: 8px;
//   overflow: scroll;
//   padding-right: 4px;
//   .resource {
//     margin-right: 7px;
//   }
//   .resource:nth-child(4n) {
//     margin-right: 0;
//   }
// }
</style>

<template>
	<el-dialog
	:visible.sync="visible"
	width="800px"
	>
		<div class="success-text-box">
			<i class="el-icon-circle-check success-icon"></i>
			<span>添加成功!</span>
		</div>
		<div class="sucess-btn-box">
			<div class="item-btn" @click="goProductDetail"><i class="el-icon-document-copy"></i></div>
			<div class="item-btn" @click="continueAdd"><i class="el-icon-plus"></i></div>
			<!-- <div class="item-btn"><i>IM</i></div> -->
			<div class="item-btn" v-if="is_like == 0" @click="addProject"><i class="el-icon-star-off"></i></div>
			<div class="item-btn" v-else @click="delProject"><i class="el-icon-star-on" style="color: #f6ee76;"></i></div>
		</div>
		<div class="sucess-btn-text">
			<div class="item-text">查看详情</div>
			<div class="item-text">继续添加</div>
			<!-- <div class="item-text">发送至IM</div> -->
			<div class="item-text">收藏</div>
		</div>
	</el-dialog>
</template>

<script>
export default {
	props:{
		visible:{
			type:Boolean,
			default:false
		},
		itemData:{
			type:Object,
			default:{}
		}
		
	},
	data(){
		return {
			is_like:0
		}
	},
	methods:{
		goProductDetail(){
			this.$emit("goProductDetail");
		},
		continueAdd(){
			this.$emit("continueAdd");
		},
		//添加心愿单
		addProject() {
		  let loading = this.openLoading();
		  let data = {
		    product_id: this.itemData.id,
		    type: 1,
		  };
		  this.$api.addBaseProject(data).then((res) => {
		    this.is_like = 1;
		    loading.close();
		  });
		},
		//移除心愿单
		delProject() {
		  let loading = this.openLoading();
		  let data = {
		    product_id: this.itemData.id,
		    type: 1,
		  };
		  this.$api.delBaseProject(data).then((res) => {
		    this.is_like = 0;
		    loading.close();
		  });
		},
	}
}
</script>

<style lang="scss">
.success-text-box{
	text-align: center;
	font-size: 16px;
	margin-top: 30px;
	
	.success-icon{
		margin-right: 10px;
		color: #67C23A;
		font-size: 18px;
	}
}

.sucess-btn-box{
	text-align: center;
	margin-top: 30px;
	.item-btn{
		width: 60px;
		height: 60px;
		border: 2px dashed #e2dede;
		border-radius: 5px;
		display: inline-block;
		margin-left: 30px;
		cursor: pointer;
		
		i{
			line-height: 60px;
			font-size: 20px;
			color: #999;
		}
	}
}

.sucess-btn-text{
	text-align: center;
	margin-top: 10px;
	margin-bottom: 20px;
	
	.item-text{
		width: 60px;
		display: inline-block;
		margin-left: 30px;
		color: #999;
	}
}

</style>

export default {
    namespaced: true,
    state: {
        menuType:1,//当前选中导航
    },
    mutations:{
        setMenu(state,type){
            state.menuType=type
        },
    },
}

<template>
  <el-card
    @click.stop
    class="product-dialog"
    :style="{ top: top + 'px', left: left + 'px' }"
  >
    <div class="dialog-search-box">
      <input
        type="text"
        class="dialog-search"
        v-model="search_form.name"
        @keyup.enter="search"
      />
      <i class="iconfont icon-fangdajing search-icon"></i>
    </div>
    <div class="dialog-product-list-box">
      <i
        class="iconfont icon-arrowLeft-fill arrow left"
        :class="[page === 1 ? 'unclick' : '']"
        @click="changePage(1)"
      ></i>
      <div class="dialog-product-list">
        <designView
          v-for="val in tableData"
          :key="val.id"
          :itemData="val"
          :isToChooseType="2"
          :isChoose="val.check"
          @chooseProduct="chooseProduct"
        ></designView>
        <div class="data-item temp" v-for="i in 10" :key="i + 'key'"></div>
      </div>
      <i
        class="iconfont icon-arrowRight arrow right"
        :class="[page === last_page ? 'unclick' : '']"
        @click="changePage(2)"
      ></i>
    </div>
    <div class="dialog-product-bottom">
      <div class="dialog-product-submit" @click="submitProduct">Submit</div>
    </div>
    <div class="dialog-product-close" @click="closeDialog">
      <i class="iconfont icon-guanbi"></i>
    </div>
  </el-card>
</template>

<script>
import designView from "../designView/designView";
let that;
export default {
  name: "productDialog",
  components: {
    designView,
  },
  props: {
    top: {
      type: Number,
      default: 100,
    },
    left: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      tableData: [],
      search_form: {
        name: "",
        // type: 3,
      },
      page: 1,
      page_size: 3,
      last_page: 1,
      chooseList: [],
    };
  },
  created() {
    that = this;
    this.getTableData();
  },
  methods: {
    search() {
      this.page = 1;
      this.search_form = this.filterParams(this.search_form);
      this.getTableData();
    },
    //选中商品
    chooseProduct(data) {
      this.tableData.some((val, key) => {
        if (val.id === data.item.id) {
          val.check = !data.check;
          this.$set(this.tableData, key, val);
          return true;
        }
      });
      if (!data.check) {
        this.chooseList.push(data.item);
      } else {
        this.chooseList.some((val, key) => {
          if (val.id === data.item.id) {
            this.chooseList.splice(key, 1);
            return true;
          }
        });
      }
    },
    //提交商品
    submitProduct() {
      if (this.chooseList.length === 0) {
        this.$message.error("请选择商品");
        return false;
      }
      this.$emit("submitProduct", {
        data: this.chooseList,
      });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    //切换页面
    changePage(type) {
      if (type === 1) {
        if (this.page === 1) return false;
        this.page--;
      } else {
        if (this.page === this.last_page) return false;
        this.page++;
      }
      this.getTableData();
    },
    //获取列表
    getTableData() {
      let loading = this.openLoading();
      let data = {
        page: this.page,
        page_size: this.page_size,
      };
      let search = {};
      search = this.search_form;
      data = { ...data, ...{ search } };
      this.$api.getProductList(data).then((res) => {
        that.tableData = res.data;
        that.tableData.forEach((val, key) => {
          that.tableData[key] = { ...val, ...{ check: false } };

          that.tableData[key]["img_url"] = that.tableData[key].file_info
            ? that.tableData[key].file_info.main_file.img_url
            : "";
          that.tableData[key]["amount"] = 0;
          that.tableData[key]["number"] = "";
        });
        that.last_page = res.last_page;
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-dialog {
  position: absolute;
  width: 874px;
  height: 436px;
  padding: 20px 0;
  background-color: #fff;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .dialog-search-box {
    width: 540px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    .dialog-search {
      width: 540px;
      height: 34px;
      border: 1px solid #e2dede;
      background-color: #f7f7f5;
      padding: 0 32px 0 12px;
    }
    .search-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
    }
  }
  .dialog-product-list-box {
    padding: 16px 62px 26px;
    width: 100%;
    height: 322px;
    position: relative;
    .dialog-product-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .data-item {
        width: 250px;
      }
      &.temp {
        height: 0;
        margin-bottom: 0;
        border: none;
        padding: 0;
        box-sizing: content-box;
      }
    }
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 26px;
      color: #2d2d2d;
      cursor: pointer;
    }
    .unclick {
      color: #999 !important;
      cursor: not-allowed;
    }
    .left {
      left: 20px;
    }
    .right {
      right: 20px;
    }
  }
  .dialog-product-bottom {
    height: 60px;
    border-top: 1px solid #e2dede;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .dialog-product-submit {
      width: 120px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background-color: #21c3a9;
      font-size: 12px;
      font-weight: 600;
      margin-left: 20px;
      cursor: pointer;
      color: #fff;
    }
  }
  .dialog-product-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    .icon-guanbi {
      color: #999;
      font-size: 20px;
    }
  }
}
</style>

<template>
    <el-dialog title="备注信息" :visible.sync="dialogVisible" v-if="dialogVisible" :before-close="closeRemarkModal" :modal-append-to-body='false' width="500px">
        <el-form class="remark-dialog" label-width="90px"    :inline="true" >
            <div class="dialog-item-ul">
                <el-form-item label="业务备注" class="form-item" prop="style_number">
                    <el-input type="textarea" style="width: 300px" v-model="remark"  size="small"></el-input>
                </el-form-item>
            </div>
            <el-form-item class="dialog-btn enter-btn-box">
                <el-button  size="big" @click="closeRemarkModal">
                    取消
                </el-button>
                <el-button class="btn" size="big" type="primary" @click="toSubmit">
                    确认
                </el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        name: "remarkDialog",
        props:{
            dialogVisible:{
                type:Boolean,
                default:true
            },
            remarkText:{
                type:String
            },
        },
        watch:{
            remarkText(val){
                this.remark=val
            }
        },
        data(){
            return{
                remark:''
            }
        },
        created(){
            console.log(this.remarkText)
        },
        methods:{
            closeRemarkModal(){
                this.$emit('closeRemarkModal')
            },
            toSubmit(){
                this.$emit('submitRemark',this.remark)
            },
        },
    }
</script>

<style scoped lang="scss">
    .remark-dialog{
        box-sizing: border-box;
        .form-item{
            padding-left: 10px;box-sizing: border-box
        }
        .dialog-text{
            margin: 10px 0;
            font-size: 15px;
            display: flex;
            align-items: center;
            .text-list{
                margin: 10px 0;
                width: 40%;
                text-align: right;
            }
        }
        .enter-btn-box{
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            padding: 0 24px;
            box-sizing: border-box;
        }
        .dialog-item-ul{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
</style>

<template>
  <div>
    <maskLayer></maskLayer>
    <div class="form-table-detail">
      <span class="iconfont icon-guanbi close-btn" @click="closeModal"></span>
      <div class="goods-img-box">
        <el-image
          class="goods-img"
          fit="contain"
          :src="itemData.img_url + '?imageMogr2/thumbnail/!30p'"
        >
        </el-image>
      </div>
      <div class="goods-detail">
        <div class="goods-item" v-for="(val, key) in detail" :key="key">
          {{ val }}
        </div>
      </div>

      <i
        class="iconfont icon-shangjiantou1 jump-btn-icon"
        @click="toNextGoods(1)"
      ></i>
      <i
        class="iconfont icon-xiajiantou jump-btn-icon jump-btn-icon-bottom"
        @click="toNextGoods(2)"
      ></i>
    </div>
  </div>
</template>

<script>
import maskLayer from "@/components/maskLayer";
export default {
  name: "formTableDetail",
  components: {
    maskLayer,
  },
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  computed: {
    detail() {
      let obj = {
        style_number: "",
        description: "",
        color: "",
        number: "",
        price: "",
        amount: "",
      };
      for (const i in obj) {
        obj[i] = this.itemData[i];
      }
      return obj;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeDetailModal");
    },
    //  跳转下一个商品
    toNextGoods(type) {
      this.$emit("toNextGoods", type);
    },
  },
};
</script>

<style lang="scss">
.form-table-detail {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 70px;
  width: 450px;
  height: 580px;
  z-index: 9999;

  background-color: #fff;

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
  }
  .goods-img-box {
    width: 310px;
    height: 214px;
    margin: 0 auto;
    .goods-img {
      width: 100%;
      height: 100%;
    }
  }
  .design-image-box {
    width: 310px;
    height: 214px;
    border: 1px solid #bfbfbf;
    /*padding:60px 40px 30px 40px;*/
    padding: 10px 4px;
    opacity: 1;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .design-image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .design-type-text {
      position: absolute;
      left: 12px;
      top: 12px;
      font-size: 12px;
      color: #333;
      font-weight: 500;
    }
    .el-icon-picture-outline {
      font-size: 60px;
      color: #999;
    }
  }

  .design-image-none {
    text-align: center;
    position: relative;
    margin: 0 auto;
    .upload-text {
      font-size: 12px;
      color: #4d4d4d;
    }
  }
  .goods-detail {
    width: 100%;
    overflow-y: auto;
  }
  .goods-item {
    width: 100%;
    text-align: left;
    font-size: 18px;
    color: #7f7f7f;
    margin: 16px auto;
  }
  .goods-item-input {
    width: 100%;
    font-size: 18px;
    margin: 12px auto;
  }
  .jump-btn-icon {
    position: absolute;
    right: 20px;
    bottom: 60px;
    font-size: 20px;
    cursor: pointer;
    color: #7f7f7f;
  }
  .jump-btn-icon-bottom {
    bottom: 36px;
  }
}
</style>

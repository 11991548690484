<template>
  <div class="form-item mt-12 flex flex-col mb-4">
    <label class="mb-8 font-size-16">{{ label }}</label>
    <input
      class="focus input radius-4 text-color font-size-14 p-6"
      type="text"
      v-model="value"
      :placeholder="placeholder"
      @input="$emit('input', value)"
      ref="input"
      @click="$refs.input.focus()"
      @keypress.enter="$emit('enter', value)"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    default: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: this.default,
    };
  },
};
</script>

<style lang="less">
.form-item {
  label {
    // color: #b8b7b8;
    color: #050505;
  }
}
</style>

<template>
  <el-card class="edit-tool">
    <div class="tools-header">
      <div
        class="tools-header-item"
        :class="{ 'tools-header-item-active': toolTab == 1 }"
        @click="changeTab(1)"
      >
        {{ $t("basic") }}
      </div>
      <div
        v-if="isShowDesign"
        class="tools-header-item"
        :class="{ 'tools-header-item-active': toolTab == 2 }"
        @click="changeTab(2)"
      >
        {{ $t("design") }}
      </div>
      <div
        class="tools-header-item"
        :class="{ 'tools-header-item-active': toolTab == 3 }"
        @click="changeTab(3)"
      >
        {{ $t("form") }}
      </div>
    </div>
    <div>
      <div v-if="toolTab == 1" class="edit-tool-basic">
        <el-form
          class="enter-dialog"
          label-width="110px"
          ref="addForm"
          :model="addForm"
          :inline="true"
          :rules="rules"
        >
          <el-form-item :label="$t('bomNmae')" class="form-item" prop="name">
            <el-input
              class="tool-input"
              v-model="addForm.name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('bomDate')"
            class="form-item"
            prop="delivery_date"
          >
            <el-date-picker
              style="width: 200px"
              value-format="yyyy-MM-dd"
              v-model="addForm.delivery_date"
              size="small"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            :label="$t('bomStyle')"
            class="form-item"
            prop="style_number"
          >
            <el-input
              class="tool-input"
              v-model="addForm.style_number"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('bomColor')"
            class="form-item"
            prop="style_color"
          >
            <el-input
              class="tool-input"
              v-model="addForm.style_color"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('bomModel')"
            class="form-item"
            prop="model_name"
          >
            <el-input
              class="tool-input"
              v-model="addForm.model_name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('bomHeader')"
            class="form-item"
            prop="shoe_last_name"
          >
            <el-input
              class="tool-input"
              v-model="addForm.shoe_last_name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('bomClient')"
            class="form-item"
            prop="client"
          >
            <el-input
              class="tool-input"
              v-model="addForm.client"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('bomSize')" class="form-item" prop="size">
            <el-input
              class="tool-input"
              v-model="addForm.size"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('bomNum')" class="form-item" prop="count">
            <el-input
              class="tool-input"
              v-model="addForm.count"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('remark')" class="form-item">
            <el-input
              class="tool-input"
              v-model="remark"
              size="small"
              @change="changeRemark"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('imageSize')"
            class="form-item"
            v-if="tableData.length == 0"
          >
            <el-slider
              v-model="size"
              style="width: 200px"
              @input="changeImgSize"
            ></el-slider>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="toolTab == 2" class="edit-tool-list">
        <div class="tool-title">
          {{ $t("part") }}
        </div>
        <el-select
          size="mini"
          class="texture-select"
          ref="configPart"
          value-key="id"
          v-model="part"
          filterable
          @change="changePart"
          :placeholder="$t('partPlaceholder')"
        >
          <el-option
            v-for="item in partList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
        <div v-show="isShowImg">
          <div class="tool-title">
            {{ $t("textureName") }}
          </div>
          <div class="texture-image-box">
            <div
              class="texture-image"
              :style="{
                backgroundImage: `url('${
                  texture
                    ? texture.cover_pic + '?imageMogr2/thumbnail/!10p'
                    : ''
                }')`,
              }"
              @click="showModal(texture.cover_pic)"
            ></div>
            <el-select
              class="texture-select"
              size="mini"
              ref="configSelect"
              :remote-method="fileterTexture"
              remote
              :loading="loading"
              v-model="texture_id"
              clearable
              filterable
              @change="changeTexture"
              :placeholder="$t('pleaseSearchTexture')"
            >
              <el-option
                class="texture-item-box"
                v-for="item in textureList"
                :key="item.id"
                :label="item.sn"
                :value="item.id"
              >
                <div class="texture-item">
                  <el-image
                    class="texture-img"
                    fit="contain"
                    :src="item.cover_pic + '?imageMogr2/thumbnail/!10p'"
                    alt=""
                  ></el-image>
                  <span>{{ item.sn }}</span>
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="tool-title">
            {{ $t("textureRemark") }}
          </div>
          <div>
            <el-upload
              class="upload-texture"
              action=""
              accept=".jpg,.jpeg,.png,.JPG,.PNG"
              multiple
              :show-file-list="false"
              :http-request="picUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <div v-else>
                <div class="iconfont icon-ic_add icon-add"></div>
              </div>
              <div class="clear-img" @click.stop="clearImg" v-if="imageUrl">
                <span class="iconfont icon-guanbi"></span>
              </div>
            </el-upload>
            <div
              v-if="imageUrl"
              class="get-big-img"
              @click="showModal(imageUrl)"
            >
              查看大图
            </div>
          </div>
        </div>
        <div class="tool-title">
          {{ $t("materialColor") }}
        </div>
        <div class="texture-color-box">
          <el-color-picker
            ref="colorPicker"
            v-model="colors"
            class="texture-color"
            @change="updateValue"
          ></el-color-picker>
          <el-input
            size="mini"
            class="texture-pantone"
            :placeholder="$t('patonePlaceholder')"
            clearable
            v-model="pantoneColor"
          >
            <el-button slot="append" @click="setPantone">{{
              $t("setting")
            }}</el-button>
          </el-input>
        </div>
        <div v-show="isShowImg">
          <div class="tool-title">
            {{ $t("textureSn") }}
          </div>
          <el-input
            size="mini"
            :placeholder="$t('textureSnPlaceholder')"
            class="texture-technology"
            v-model="texture_sn"
            clearable
            @input="changeSn"
          ></el-input>
          <div class="tool-title">{{ $t("textureTechnology") }}</div>
          <el-input
            size="mini"
            :placeholder="$t('textureTechnologyPlaceholder')"
            class="texture-technology"
            v-model="technology"
            clearable
            @input="changeTech"
          ></el-input>
        </div>
        <div class="del-node-box">
          <el-button
            class="del-node"
            type="danger"
            size="small"
            @click="removeNode"
          >
            {{ $t("removeNode") }}
          </el-button>
        </div>
      </div>
      <div v-if="toolTab == 3" class="edit-tool-table">
        <materialTree
          :datas="tableData"
          @selected="(r) => $emit('selected', r)"
        ></materialTree>
      </div>
    </div>

    <!--        <div class="iconfont icon-guanbi close-btn" @click="closeMsgModal">-->
    <!--        </div>-->
    <el-image-viewer v-if="imgModal" :on-close="closeLock" :url-list="imgArr" />
  </el-card>
</template>

<script>
import pantone from "../../utils/pantone.js";
import hexcolor from "../../utils/hexcolor";
import materialTree from "../materialTree/materialTree";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import throttle from "lodash/throttle";
let that;
export default {
  name: "editTools",
  components: {
    ElImageViewer,
    // 'chrome-picker':Chrome,
    materialTree,
  },
  props: {
    svgData: {
      type: Object,
    },
    imgSize: {
      type: Number,
      default: 50,
    },
    partList: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
    },
    checkShowColor: {
      type: Boolean,
    },
    addForm: {
      type: Object,
    },
    remarkText: {
      type: String,
    },
  },

  data() {
    return {
      colors: "",
      pantoneColor: "",
      texture: {
        cover_pic: "",
      },
      texture_id: "",
      part: "",
      imgArr: [],
      imgModal: false,
      technology: "",
      texture_sn: "",
      itemData: {},
      size: 50,
      texture_color: "",
      imageUrl: "",
      loading: false,
      textureList: [],
      isShowImg: true,
      showType: 0,
      toolTab: 1,
      remark: this.remarkText,
      isShowDesign: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t("bomNmaePlaceholder"),
            trigger: "blur",
          },
        ],
        delivery_date: [
          {
            required: true,
            message: this.$t("bomDatePlaceholder"),
            trigger: "change",
          },
        ],
        style_number: [
          {
            required: true,
            message: this.$t("bomStylePlaceholder"),
            trigger: "blur",
          },
        ],
        style_color: [
          {
            required: true,
            message: this.$t("bomColorPlaceholder"),
            trigger: "blur",
          },
        ],
        model_name: [
          {
            required: true,
            message: this.$t("bomModelPlaceholder"),
            trigger: "blur",
          },
        ],
        shoe_last_name: [
          {
            required: true,
            message: this.$t("bomHeaderPlaceholder"),
            trigger: "blur",
          },
        ],
        count: [
          {
            required: true,
            message: this.$t("bomNumPlaceholder"),
            trigger: "blur",
          },
        ],
        client: [
          {
            required: true,
            message: this.$t("bomClientPlaceholder"),
            trigger: "blur",
          },
        ],
        size: [
          {
            required: true,
            message: this.$t("bomSizePlaceholder"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    svgData: {
      handler: function (val) {
        if (!val.data) return;
        let param = val.data.param;
        // this.technology = param.technology;
        // this.texture_sn = param.texture_sn;
        this.itemData = param.oneData;

        if (Object.values(this.itemData).length > 0) {
          that.isShowDesign = true;
          that.texture_sn = this.itemData.sn || "";
          that.technology = this.itemData.technology || "";
          this.colors = this.itemData.color || "";
          this.pantoneColor = this.itemData.pantone || "";
          this.imageUrl = this.itemData.texture_remark_img || "";
          this.texture_id = this.itemData.texture_id || "";

          this.texture = {
            cover_pic: this.itemData.cover_pic || "",
          };
          this.part = {
            id: this.itemData.part_id || "",
          };
        }
      },
      deep: true,
    },
    imgSize(val) {
      this.size = val;
    },
    // checkShowColor(val){
    //     setTimeout(()=>{
    //         if(this.itemData.color==''&&this.itemData.texture_id==''&&this.itemData.texture_remark_img==''){
    //             this.$refs.colorPicker.showPicker=true
    //         }else{
    //             this.$refs.colorPicker.hide()
    //         }
    //     },10)
    // }
  },
  created() {
    that = this;
    if (this.itemData.texture_id) {
      let data = {
        id: this.itemData.texture_id,
        name: this.itemData.texture_name,
        cover_pic: this.itemData.cover_pic,
      };
      this.textureList = [data];
    }
    // this.fileterTexture()
  },
  methods: {
    updateValue() {
      // if(!hexcolor[this.colors]){
      //     this.$message.error(this.$t("nonePantone"))
      //     setTimeout(()=>{
      //         this.colors=''
      //     },10)
      //
      //
      //     return
      // }
      // this.pantoneColor=hexcolor[this.colors]
      let data = {
        color: this.colors,
        pantone: this.pantoneColor,
      };
      this.$emit("getColor", data);
    },
    showRemarkModal(type) {
      this.$emit("showRemarkModal", type);
    },
    backPage() {
      this.$router.back();
    },
    showTable() {
      if (this.showType == 2) {
        this.showType = 1;
      } else {
        this.showType = 2;
      }
    },
    //修改备注
    changeRemark(e) {
      this.$emit("changeRemark", e);
    },
    //切换tab
    changeTab(type) {
      this.toolTab = type;
    },
    //筛选素材
    fileterTexture(value) {
      let that = this;
      let filter = function () {
        that.loading = true;
        // that.$api
        //   .getTextureSelectList({
        //     sn: value,
        //   })
        //   .then((res) => {
        //     that.loading = false;
        //     that.textureList = res;
        //   });
		that.$api
		  .getMaterialListData({
		    material_name: value,
			supplier_id:'',
			page:1,
			page_size:100
		  })
		  .then((res) => {
		    that.loading = false;
		    that.textureList = res.data;
			that.textureList.forEach(function(item){
				item.sn = item.material_name;
				item.cover_pic = item.material_url;
			})
		  });
      };
      var timeHandler = throttle(filter, 500, {
        leading: true,
        trailing: false,
      });
      timeHandler();
      // this.$api.getMaterialList({
      //     page:1,
      //     page_size:100
      // }).then(res=>{
      //     that.loading=false
      //     that.textureList=res
      // })
    },
    // 选择图片
    picUpload(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.imageUrl = e.target.result; // 将图片路径赋值给src
        let data = {
          img_file: file.file,
          cover_pic: e.target.result,
          num: that.itemData.nodeNum,
        };
        that.$emit("getTextureImg", data);
      };
      reader.readAsDataURL(file.file);
      this.img_file = file.file;
    },
    //删除图片
    clearImg() {
      let data = {
        img_file: "",
        cover_pic: "",
        num: this.itemData.nodeNum,
      };
      this.$emit("getTextureImg", data);
    },
    //关闭
    closeMsgModal() {
      this.$emit("closeMsgModal");
    },
    // 显示弹窗
    showModal(url) {
      if (!url) return;
      this.imgArr[0] = url;
      this.imgModal = true;
    },
    // 关闭弹窗
    closeLock() {
      this.imgModal = false;
    },
    //修改编号
    changeSn(e) {
      this.$emit("changeSn", e);
    },
    //修改工艺
    changeTech(e) {
      this.$emit("changeTech", e);
    },
    //修改图片大小
    changeImgSize(e) {
      // let imgSize=(e-50)/100
      this.$emit("changeImgSize", e);
    },
    //设置潘通号
    setPantone() {
      if (this.pantoneColor == "") {
        this.$message.error(this.$t("patonePlaceholder"));
        return;
      }
      if (pantone[this.pantoneColor]) {
        // this.$message.error(this.$t("noneSearchPantone"))
        this.colors = pantone[this.pantoneColor];
      }
      let data = {
        color: this.colors,
        pantone: this.pantoneColor,
      };
      this.$emit("getColor", data);
    },
    //修改素材
    changeTexture(val) {
      let list = this.textureList.filter((item) => item.id == val);
      this.texture = list[0];
      setTimeout(() => {
        this.$refs.configSelect.blur();
      }, 100);
      this.$emit("getImage", this.texture);
    },
    //删除节点
    removeNode() {
      this.$emit("removeNode");
    },
    //修改部件
    changePart() {
      // if(this.part.type==1){
      //     this.isShowImg=false
      // }else{
      //     this.isShowImg=true
      // }
      setTimeout(() => {
        this.$refs.configPart.blur();
      }, 100);
      this.$emit("getPart", this.part);
    },
  },
};
</script>

<style lang="scss">
.el-image-viewer__wrapper {
  z-index: 9999 !important;
}
.texture-item-box {
  width: 100% !important;
  height: 60px !important;
  line-height: 60px !important;
}
.texture-img {
  width: 50px;
  height: 50px;
}
.texture-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tool-input {
  width: 200px;
}
.edit-tool {
  width: 360px;
  height: 100%;
  /*max-height: calc(100% - 150px);*/
  background-color: #fff;
  border-left: 1px solid #dcdcdc;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  top: 0px;

  overflow-y: auto !important;
  /*opacity: 0.95;*/
  .edit-tool-list {
    padding: 20px;
  }
  .edit-tool-basic {
    padding: 20px 0;
  }
  .edit-tool-table {
    padding: 20px 10px;
  }
  .el-image-viewer__close {
    right: 80px !important;
  }
  .el-card__body {
    text-align: center;
    padding: 0;
  }
  .el-image-viewer__btn {
    .el-icon-circle-close {
      color: #fff;
      font-size: 60px;
    }
  }
  .el-form-item__label {
    font-size: $base-font-size;
  }
  .form-item label {
    color: #333;
  }
  .el-table th > .cell {
    font-size: 10px;
    color: #333;
    font-weight: 500;
  }
  .tools-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    height: 40px;
    border-bottom: 2px solid #d6d3d3;
    .tools-header-item {
      font-size: $base-font-size;
      font-weight: 500;
      color: #999;
      cursor: pointer;
    }
    .tools-header-item-active {
      font-size: $base-font-size;
      font-weight: 600;
      color: #333;
      position: relative;
    }
    .tools-header-item-active::after {
      content: "";
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 60px;
      background-color: $base-config-color;
    }
  }
  .save-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .save-item {
      .el-button--small,
      .el-button--small.is-round {
        padding: 9px 12px;
      }
    }
  }
  .close-btn {
    font-size: 16px;
    color: #999;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .vc-chrome-saturation-wrap {
    padding-bottom: 170px !important;
  }

  .edit-title-box {
    background-color: #d8d8d8;
    width: 100%;
    height: 50px;
    display: flex;

    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #b5b5b5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    .edit-item {
      width: 50%;
      height: 50px;
      background-color: #d8d8d8;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
    }
    .edit-item-active {
      font-weight: 600;
      background-color: #f2f2f2;
    }
  }
  .texture-color-box {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    .texture-color {
      height: 28px;
      width: 28px;
      margin-right: 4px;
      flex-shrink: 0;
      border-radius: 4px;
      font-size: 28px;

      background-color: #fff;
      cursor: pointer;
    }

    .texture-color-input {
      flex: 1;
    }
    .texture-pantone {
      width: 100%;
    }
  }
  .texture-image-box {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    align-items: center;
    .texture-image {
      height: 28px;
      width: 28px;
      margin-right: 4px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #f2f2f2;
      box-sizing: border-box;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .edit-title-bottom {
    width: 100%;
    height: 40px;
  }
  .tool-title {
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
  .texture-select {
    margin: 0 auto;
    width: 100%;
  }
  .texture-technology {
    margin: 0 auto;
    width: 100%;
  }
  .texture-img-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .del-node-box {
    width: 90%;
    margin: 20px auto 0 auto;
    text-align: right;
  }
  .upload-texture {
    min-width: 80px;
    min-height: 80px;
    border-radius: 10px;
    /*background-color: #f3f3f3;*/
    border: 1px solid #f3f3f3;
    padding: 8px 16px;
    //box-sizing: border-box;
    position: relative;
    text-align: center;
    .avatar {
      width: auto;
      height: 80px;
      border-radius: 10px;
    }
    .icon-add {
      color: #999;
      font-size: 34px;
      margin-top: 20px;
    }
    .upload-text {
      margin-top: 12px;
      text-align: center;
      font-size: 14px;
      color: #999;
    }
    .clear-img {
      position: absolute;
      right: 2px;
      top: 2px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .get-big-img {
    margin-top: 4px;
    font-size: 12px;
    color: #4598b9;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
  }
}
</style>

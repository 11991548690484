<template>
  <div class="workEdit">
    <texture-set
      :visiable.sync="showTools"
	  :type="textureSetType"
    ></texture-set>
  </div>
</template>

<script>
import MaterialList from "@/components/material/MaterialList.vue";
import LogoList from "@/components/logo-list/LogoList.vue";
import TextureList from "@/components/texture/TextureList.vue";
import TextureSet from "@/components/TextureSet.vue";
import ColorPicker from "@/components/v2/color-picker/ColorPicker.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations, mapGetters, mapActions } = createNamespacedHelpers("jiyang/material");
export default {
  name: "assets",
  components: { MaterialList, LogoList, TextureList,TextureSet,ColorPicker },
  data(){
	return{
		showTools:true,
	}  
  },
  created() {},
  computed: {
    ...mapState(["loading","textureSetType"]),
  },
  methods: {
	...mapActions(["textureSet"]),
    tabChange() {},
  },
};
</script>

<style lang="scss">
.workEdit {
  height: 100%;
  min-width: 340px;
  max-width: 340px;
  width: 20%;
  background-color: #fff;
  text-align: center;
  .tab-content {
    width: 100%;
    height: auto !important;
    padding: 0 12px;
  }
  .tab-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    .ant-input-group {
      margin-right: 16px;
    }
    .ant-btn-icon-only {
      width: 48px;
    }
    .ant-btn {
      margin-right: 8px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .pcr-app{
  	  box-shadow: none;
  	  width: 24em !important;
  }
}
</style>

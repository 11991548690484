import api from "@/api";

export default {
  namespaced: true,
  state: {
    navCollapse: false,
  },
  mutations: {
    setCollapse(state, collapsed) {
      state.navCollapse = collapsed;
    },
  },
  actions: {},
  getters: {
    cartTotal(state) {
      return state.cart.length;
    },
  },
};

<template>
  <div class="temp" :style="{ width: itemWidth + 'px' }"></div>
</template>
<script>
export default {
  name: "listSpace",
  props: {
    itemWidth: {
      type: Number,
    },
  },
};
</script>
<style lang="scss" scoped>
.temp {
  height: 0;
  margin-bottom: 0;
  border: none;
  padding: 0;
  box-sizing: content-box;
}
</style>

import axios from "axios";
// import Adapter from 'axios-mock-adapter'
import { errorLog, errorCreate } from "./tools";
import { Loading, Notification } from "element-ui";
import router from "@/router";
import VueCookies from "vue-cookie";
import store from "@/store";
/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create();
  // 请求拦截

  service.interceptors.request.use(
    (config) => config,
    (error) => {
      // 发送失败

      //  store.commit("SET_LOADING", true);

      return Promise.reject(error);
    }
  );
  // 响应拦截
  service.interceptors.response.use(
    (response) => {
      store.commit("jiyang/material/SET_LOADING", false);
      const dataAxios = response.data,
        { status_code } = dataAxios;
      // 根据 code 进行判断

      if (status_code === undefined) {
        return dataAxios;
      }
      switch (status_code) {
        case 1:
          // if (dataAxios.msg != '') {
          //     Message({
          //         message: dataAxios.msg,
          //         type: 'success',
          //         duration: 2 * 1000
          //     })
          // }
          return dataAxios.result;
          break;
        //    没有token：-1   token过期：-2
        case -1:
        case -2:
          var loadingInstance = Loading.service({ fullscreen: true });
          loadingInstance.close();
          VueCookies.set("token", "");
          VueCookies.set("user_info", "");
          router.push({
            name: "login",
          });
          errorCreate(`${dataAxios.error_msg}`);
          break;
        default:
          // Message({
          //   message: dataAxios.msg,
          //   type: 'error',
          //   duration: 2 * 1000
          // })

          var loadingInstance = Loading.service({ fullscreen: true });
          loadingInstance.close();

          errorCreate(`${dataAxios.error_msg}`);
          break;
      }
    },
    (error) => {
      if (error && error.response) {
        var loadingInstance = Loading.service({ fullscreen: true });
        Notification.error({
          title: "后端错误",
          message: JSON.stringify(error),
          duration: 0,
        });
        loadingInstance.close();
        switch (error.response.status) {
          case 422:
            error.message = error.response.data.error_msg;
            break;
          // case 400:
          //     error.message = '请求错误';
          //     break;
          // case 401:
          //     error.message = '未授权，请登录';
          //     break;
          // case 403:
          //     error.message = '拒绝访问';
          //     break;
          // case 404:
          //     error.message = `请求地址出错: ${error.response.config.url}`;
          //     break;
          // case 408:
          //     error.message = '请求超时';
          //     break;
          //
          // case 500:
          //     error.message = '服务器内部错误';
          //     break;
          // case 501:
          //     error.message = '服务未实现';
          //     break;
          // case 502:
          //     error.message = '网关错误';
          //     break;
          // case 503:
          //     error.message = '服务不可用';
          //     break;
          // case 504:
          //     error.message = '网关超时';
          //     break;
          // case 505:
          //     error.message = 'HTTP版本不受支持';
          //     break;
          default:
            break;
        }
      }
      errorLog(error);
      var loadingInstance = Loading.service({ fullscreen: true });
      loadingInstance.close();
      return Promise.reject(error);
    }
  );
  return service;
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function (config) {
    store.commit("jiyang/material/SET_LOADING", true);
    const token2 = localStorage.getItem("token");
    const token = VueCookies.get("token"),
      configDefault = {
        headers: {
          Authorization: token || token2 || "",
          "Content-Type": "application/json",
          // withCredentials: true,
          // credentials: "include",
        },
        timeout: 60000,
        baseURL: process.env.VUE_APP_API,
        data: {},
      };

    return service(Object.assign(configDefault, config));
  };
}

// 用于真实网络请求的实例和请求方法
export const service = createService();
export const request = createRequestFunction(service);

// 用于模拟网络请求的实例和请求方法
// export const serviceForMock = createService();
// export const requestForMock = createRequestFunction(serviceForMock);

// 网络请求数据模拟工具
// export const mock = new Adapter(serviceForMock)

<template>
	<div id="textures-list">
		<photo v-for="item in filterTexture" :src="item.url"></photo>
		<el-pagination
		  background
		  big
		  :total="texturePage.total"
		  :current-page="texturePage.current_page"
		  :page-size="texturePage.per_page"
		  layout="prev, pager, next"
		  @current-change="currentChange"
		/>
	</div>
</template>
<script>
	import { createNamespacedHelpers } from "vuex";
	const { mapState, mapMutations, mapGetters, mapActions } =
	  createNamespacedHelpers("jiyang/material");
	import photo from "@/components/assets/common/photo";
	export default {
		components:{
			photo
		},
		data(){
			return{
				
			}
		},
		computed:{
			...mapGetters(["filterTexture"]),
			...mapState(["texturePage"]),
		},
		created() {
		  this.getTextureList();
		},
		methods:{
			...mapActions(["getTextureList", "delTexture"]),
			currentChange(page, pageSize) {
			  this.getTextureList({ page: page, pageSize: pageSize });
			},
		}
	}
</script>

<style scoped lang="scss">
	#textures-list{
		.el-pagination{
			text-align: center;
		}
	}
</style>

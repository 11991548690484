<template>
  <div>
    <maskLayer></maskLayer>
    <div class="goods-tree-dialog">
      <span class="iconfont icon-guanbi close-btn" @click="closeModal"></span>
      <div class="canvasBox" ref="canvasBox">
        <canvas
          id="goodsTree"
          :width="canvasWidth"
          :height="canvasHeight"
          @click="showMoreStyle"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import maskLayer from "@/components/maskLayer";
export default {
  name: "goodsTreeDialog",
  components: {
    maskLayer,
  },
  props: {
    goodsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      canvasDom: null,
      ctx: null,
      canvasWidth: 950,
      canvasHeight: 530,
      canvasNowHeight: 530,
      rectWidth: 170,
      rectHeight: 120,
      xLineWidth: 84,
      yLineHeight: 180,
      thirdRectWidth: 130,
      thirdRectHeight: 92,
      thirdYLineHeight: 110,
      firstRectX: 0,
      firstRectY: 0,
      nowSecIndex: -1,
    };
  },
  watch: {
    goodsData(val) {
      console.log(val);
    },
  },
  mounted() {
    //设置初始高度
    let len = this.goodsData.relatedModel.length - 1;
    let height = this.yLineHeight * len + this.rectHeight + 40;
    let canvasHeight = height <= this.canvasHeight ? this.canvasHeight : height;
    document.getElementById("goodsTree").height = canvasHeight;
    this.canvasNowHeight = canvasHeight;
    this.$nextTick(() => {
      this.$refs.canvasBox.scrollTo(0, canvasHeight / 2 - 260);
    });
    this.canvasDom = document.getElementById("goodsTree");
    this.ctx = this.canvasDom.getContext("2d");
    this.initCanvas();
  },
  methods: {
    closeModal() {
      this.$emit("closeTreeModal");
    },
    initCanvas() {
      let rectWidth = this.rectWidth;
      let rectHeight = this.rectHeight;
      let xLineWidth = this.xLineWidth;
      let yLineHeight = this.yLineHeight;
      let len = this.goodsData.relatedModel.length - 1;
      let height = this.yLineHeight * len + rectHeight + 40;
      //绘制初始图块
      let beginY = this.canvasNowHeight / 2 - rectHeight / 2;
      this.drawImageRect(
        10,
        beginY,
        rectWidth,
        rectHeight,
        this.goodsData.file_info.main_file.img_url +
          "?imageMogr2/thumbnail/!20p"
      );
      //绘制初始连接线
      let firstLineX = 10 + rectWidth;
      let firstLineY = this.canvasNowHeight / 2;
      this.drawLine(
        firstLineX,
        firstLineY,
        firstLineX + xLineWidth,
        firstLineY
      );
      let firstColumnLineHeight = height - 40 - rectHeight;
      let firstColumnLineX = firstLineX + xLineWidth;
      let firstColumnLineY = firstLineY - firstColumnLineHeight / 2;
      this.drawLine(
        firstColumnLineX,
        firstColumnLineY,
        firstColumnLineX,
        firstColumnLineY + firstColumnLineHeight
      );
      //获取同底第一个矩形的位置
      this.firstRectX = firstColumnLineX + xLineWidth;
      this.firstRectY = firstColumnLineY - rectHeight / 2;
      //遍历添加二级
      this.goodsData.relatedModel.forEach((val, key) => {
        this.drawLine(
          firstColumnLineX,
          firstColumnLineY + key * yLineHeight,
          firstColumnLineX + xLineWidth,
          firstColumnLineY + key * yLineHeight
        );
        this.drawImageRect(
          firstColumnLineX + xLineWidth,
          firstColumnLineY + key * yLineHeight - rectHeight / 2,
          rectWidth,
          rectHeight,
          val.img_url + "?imageMogr2/thumbnail/!20p"
        );
      });
    },
    //  绘制图片块
    drawImageRect(x, y, w, h, img) {
      let that = this;
      this.ctx.beginPath();
      this.ctx.setLineDash([]);
      this.ctx.strokeStyle = "#bbbbbb";
      this.ctx.strokeRect(x, y, w, h); // strokeRect(x, y, width, height)
      this.ctx.fillStyle = "white";
      this.ctx.fillRect(x, y, w, h); // fillRect(x, y, width, height)
      const image = new Image();
      image.src = img;
      image.onload = function () {
        let iw = this.width;
        let ih = this.height;
        let local = that.calculate(w, h, iw, ih, x, y);
        const { px, py, pw, ph } = local;
        that.ctx.drawImage(image, px, py, pw, ph);
      };
    },
    //  绘制线条
    drawLine(x, y, sx, sy) {
      this.ctx.beginPath();
      this.ctx.setLineDash([5]);
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(sx, sy);
      this.ctx.stroke();
    },
    // 计算出图片画在canvas中的四个参数
    calculate(w, h, pw, ph, x, y) {
      let px = 0;
      let py = 0;
      if (pw <= w && ph <= h) {
      } else if (ph / pw > h / w) {
        let uu = ph;
        ph = h;
        pw = (pw * h) / uu;
      } else {
        let uu = pw;
        pw = w;
        ph = (ph * pw) / uu;
      }
      px = x + 0.5 * w - 0.5 * pw;
      py = y + 0.5 * h - 0.5 * ph;
      return { px, py, pw, ph };
    },
    //绘制三级树形结构
    async drawThirdTree(lists) {
      let rectHeight = this.thirdRectHeight;
      let yLineHeight = this.thirdYLineHeight;
      let rectWidth = this.thirdRectWidth;
      let len = lists.length - 1;
      let height = yLineHeight * len + rectHeight + 40;
      let x = this.firstRectX;
      let y = this.firstRectY + this.nowSecIndex * this.yLineHeight;
      let linex = x + this.rectWidth + this.xLineWidth;
      let liney = y + this.rectHeight / 2;
      let firstColumnLineHeight = height - 40 - rectHeight;
      let firstColumnLineY = liney - firstColumnLineHeight / 2;
      if (height > this.canvasNowHeight) {
        let canvasHeight =
          height <= this.canvasHeight ? this.canvasHeight : height;
        document.getElementById("goodsTree").height = canvasHeight;
        this.canvasNowHeight = canvasHeight;
        await this.initCanvas();
      } else if (
        firstColumnLineY + firstColumnLineHeight + rectHeight / 2 + 20 >
        this.canvasNowHeight
      ) {
        let canvasHeight =
          firstColumnLineY + firstColumnLineHeight + rectHeight / 2 + 20;
        document.getElementById("goodsTree").height = canvasHeight;
        this.canvasNowHeight = canvasHeight;
        await this.initCanvas();
        y = this.firstRectY + this.nowSecIndex * this.yLineHeight;
        liney = y + this.rectHeight / 2;
      }

      //绘制二级连接线
      this.drawLine(x + this.rectWidth, liney, linex, liney);

      if (liney - height / 2 <= 0) {
        firstColumnLineY = 10 + rectHeight / 2;
      }
      this.drawLine(
        linex + 2,
        firstColumnLineY,
        linex,
        firstColumnLineY + firstColumnLineHeight
      );
      lists.forEach((val, key) => {
        this.drawLine(
          linex,
          firstColumnLineY + key * yLineHeight,
          linex + this.xLineWidth,
          firstColumnLineY + key * yLineHeight
        );
        this.drawImageRect(
          linex + this.xLineWidth,
          firstColumnLineY + key * yLineHeight - rectHeight / 2,
          rectWidth,
          rectHeight,
          val.file_info.main_file.img_url + "?imageMogr2/thumbnail/!20p"
        );
      });
    },
    //  点击事件
    showMoreStyle(e) {
      const { offsetX, offsetY } = e;
      this.goodsData.relatedModel.some((val, key) => {
        let x = this.firstRectX;
        let y = this.firstRectY + key * this.yLineHeight;
        if (
          offsetX >= x &&
          offsetX <= x + this.rectWidth &&
          offsetY >= y &&
          offsetY <= y + this.rectHeight
        ) {
          this.ctx.clearRect(
            this.firstRectX + this.rectWidth + 2,
            0,
            500,
            this.canvasNowHeight
          );
          this.nowSecIndex = key;
          let data = {
            id: val.id,
            style_number: val.style_number,
          };
          this.$api.getRelatedList(data).then((res) => {
            if (res.length > 0) {
              this.drawThirdTree(res);
            }
          });

          return true;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.goods-tree-dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 40px;
  width: 1040px;
  height: 620px;
  z-index: 9999;
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
  }
  .canvasBox {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
</style>

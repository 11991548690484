<template>
  <div>
    <div class="form-table-box" ref="formTables">
      <el-table
        id="formTableData"
        :data="tableData"
        :border="true"
        header-cell-class-name="table-header"
        :row-class-name="formTableRow"
        :row-style="formRowStyle"
        style="width: 100%"
        @row-click="rowClick"
        @header-dragend="headerDragend"
      >
        <el-table-column
          v-for="val in propAllData"
          :prop="val.prop"
          :label="val.label"
          align="center"
          :key="val.prop"
          :width="val.width ? val.width : ''"
        >
          <template slot-scope="scope">
            <div>
              <div class="style-number-box" v-if="val.prop == 'style_number'">
                <span>{{ scope.row.style_number }}</span>
                <div
                  class="setting-box"
                  v-if="
                    nowIndex === scope.$index &&
                    !checkEdit &&
                    isShowSetting
                  "
                >
                  <i
                    class="iconfont icon-jian"
                    @click.stop="removeProduct(scope.$index)"
                  ></i>
                </div>
              </div>
              <div v-else-if="val.prop == 'img_url'">
                <div class="row-height" v-if="checkEdit">+</div>
                <div :style="{ height: scope.row.height + 'px' }" v-else>
                  <img
                    v-if="scope.row.img_url"
                    class="form-img"
                    :src="scope.row.img_url + '?imageMogr2/thumbnail/!30p'"
                    @click="showDetailDialog(scope.row, scope.$index)"
                  />
                </div>
              </div>

              <div
                v-else-if="
                  val.prop == 'intro' ||
                  val.prop == 'size' ||
                  val.prop == 'color' ||
                  val.prop == 'number' ||
                  val.prop == 'price' ||
                  val.prop == 'operation'
                "
              >
                <div>
                  <div
                    v-if="
                      (!checkEdit && !isUnEdit) ||
                      val.prop === 'operation' ||
                      (isUnEdit &&
                        val.prop === 'price' &&
                        userRole === 'company'&&!isHistory)
                    "
                  >
                    <div v-if="val.prop == 'intro'">
                      <!--                    <div-->
                      <!--                      class="textarea"-->
                      <!--                      :value="scope.row.intro"-->
                      <!--                      @input="changeInput(scope.row, scope.$index)"-->
                      <!--                      contenteditable="true"-->
                      <!--                    ></div>-->
                      <!--                    <textarea-->
                      <!--                      class="textarea-input"-->
                      <!--                      v-model="scope.row.intro"-->
                      <!--                      @input="changeInput(scope.row, scope.$index)"-->
                      <!--                    ></textarea>-->
                      <InputTextArea
                        v-model="scope.row.intro"
                        autoSize
                        class="textarea-input"
                      />

                      <!--                    <el-input-->
                      <!--                      v-model="scope.row.description"-->
                      <!--                      autosize-->
                      <!--                      type="textarea"-->
                      <!--                      size="mini"-->
                      <!--                    ></el-input>-->
                    </div>
                    <div v-if="val.prop == 'size'">
                      <InputTextArea
                        v-model="scope.row.size"
                        autoSize
                        class="textarea-input"
                      />
                    </div>
                    <div v-if="val.prop == 'color'">
                      <InputTextArea
                        v-model="scope.row.color"
                        autoSize
                        class="textarea-input"
                      />
                    </div>
                    <div v-if="val.prop == 'number'">
                      <!--                    <el-input-->
                      <!--                      v-model="scope.row.number"-->
                      <!--                      size="mini"-->
                      <!--                      @keyup.native="-->
                      <!--                        scope.row.number = scope.row.number.replace(-->
                      <!--                          /[^0-9_\.]/g,-->
                      <!--                          ''-->
                      <!--                        )-->
                      <!--                      "-->
                      <!--                      @blur="setAmount(scope.row, scope.$index)"-->
                      <!--                    ></el-input>-->
                      <input
                        class="table-input"
                        v-model="scope.row.number"
                        @input="changeInput(scope.row, scope.$index)"
                        @keyup="
                          scope.row.number = scope.row.number.replace(/\D/g, '')
                        "
                        @blur="setAmount(scope.row, scope.$index, val.prop)"
                      />
                    </div>
                    <div v-if="val.prop == 'operation'">
                      <div
                        v-if="userRole === 'custom'"
                        class="operation-btn-box"
                      >
                        <div
                          class="operation-btn operation"
                          v-if="scope.row.operation.type === 'operation'"
                        >
                          目标:{{ scope.row.operation.price }}
                        </div>
                        <div
                          class="operation-btn operation"
                          v-else-if="scope.row.operation.type === 'confirmed'"
                        >
                          已确认
                        </div>
                        <div class="operation-btn-box" v-else>
                          <div
                            class="operation-btn confirm"
                            @click="confirmOperation(scope.$index)"
                          >
                            确认
                          </div>
                          <div
                            class="operation-btn bargain"
                            @click="toOperation"
                          >
                            议价
                          </div>
                        </div>
                        <i
                          class="iconfont icon-undo"
                          v-if="
                            scope.row.operation.type === 'operation' ||
                            scope.row.operation.type === 'confirmed'
                          "
                          @click="backOperation"
                        ></i>
                      </div>
                      <div v-else>
                        <div class="operation-btn-box">
                          <div
                            class="operation-btn operation"
                            v-if="scope.row.operation.type === 'operation'"
                          >
                            目标:{{ scope.row.operation.price }}
                          </div>
                          <div
                            class="operation-btn operation"
                            v-else-if="scope.row.operation.type === 'confirmed'"
                          >
                            已确认
                          </div>
                          <div class="operation-btn unconfirmed" v-else>
                            未确认
                          </div>
                          <!--                        <i-->
                          <!--                          class="iconfont icon-undo back-icon-btn"-->
                          <!--                          v-if="-->
                          <!--                            scope.row.operation.type === 'operation' ||-->
                          <!--                            scope.row.operation.type === 'confirmed'-->
                          <!--                          "-->
                          <!--                          @click="backOperation"-->
                          <!--                        ></i>-->
                        </div>
                      </div>
                    </div>

                    <div v-if="val.prop == 'price'">
                      <input
                        class="table-input"
                        v-model="scope.row.price"
                        @input="changeInput(scope.row, scope.$index)"
                        @keyup="
                          scope.row.price = scope.row.price.replace(
                            /[^0-9_\.]/g,
                            ''
                          )
                        "
                        @focus="intoPrice(scope.row, scope.$index)"
                        @blur="setAmount(scope.row, scope.$index, val.prop)"
                      />
                      <!--                    <el-input-->
                      <!--                      size="mini"-->
                      <!--                      v-model="scope.row.price"-->
                      <!--                      @keyup.native="-->
                      <!--                        scope.row.price = scope.row.price.replace(-->
                      <!--                          /[^0-9_\.]/g,-->
                      <!--                          ''-->
                      <!--                        )-->
                      <!--                      "-->
                      <!--                      @blur="setAmount(scope.row, scope.$index)"-->
                      <!--                    ></el-input>-->
                    </div>
                  </div>
                  <div class="row-height" v-else>
                    {{ scope.row[val.prop] }}
                  </div>
                </div>
              </div>
              <div v-else>
                {{ scope.row[val.prop] }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <operationDialog
        v-if="isShowOperation"
        :top="operationTop"
        @setOperationPrice="setOperationPrice"
        @closeOperation="closeOperation"
      ></operationDialog>
    </div>
    <formTableDetail
      v-if="isShowDetailModal"
      :itemData="itemData"
      @toNextGoods="toNextGoods"
      @closeDetailModal="closeDetailModal"
    ></formTableDetail>
    <el-upload
      ref="uploadImg"
      v-show="false"
      action=""
      accept=".jpg,.jpeg,.png,.JPG,.PNG"
      multiple
      :before-upload="beforeUpload"
      :show-file-list="false"
      :http-request="picUpload"
    >
    </el-upload>
  </div>
</template>

<script>
import uploadCos from "@/utils/upload_to_cos";
import Sortable from "sortablejs";
import resizeTable from "@/utils/resizeTable";
import operationDialog from "@/components/lists/operationDialog";
import formTableDetail from "./formTableDetail";
import { Input } from "ant-design-vue";
import { mapState } from "vuex";
export default {
  name: "formTable",
  components: {
    operationDialog,
    formTableDetail,
    InputTextArea: Input.TextArea,
  },
  props: {
    table: {
      type: Array,
    },
    checkEdit: {
      type: Boolean,
      default: true,
    },
    formType: {
      type: Number,
    },
    type: {
      type: Number,
      default: 1,
    },
    isHistory: {
      type: Boolean,
      default:false
    },
    totalData: {
      type: Object,
      default: () => {
        return {
          discount: 100,
          subTotal: 0,
          grandTotal: 0,
        };
      },
    },
  },

  data() {
    return {
      totalList: [
        {
          title: "Sub Total",
          type: "normal",
          total: 0,
        },
        {
          title: "Discount",
          type: "input",
          total: 100,
        },
      ],
      propData: [
        { prop: "style_number", label: "Style#", width: 100 },
        { prop: "img_url", label: "Picture", width: 120 },
        { prop: "intro", label: "Description" },
        { prop: "color", label: "Color", width: 110 },
        { prop: "size", label: "Size", width: 110 },
        { prop: "number", label: "QTY", width: 100 },
        { prop: "price", label: "Price", width: 120 },
        { prop: "amount", label: "Amount", width: 120 },
      ],
      isShowDiscount: false,
      nowIndex: -1,
      isShowSetting: false,
      tableData: this.table,
      checkDrag: false,
      rowHeight: 40,
      checkIndex: 0,
      operationTop: 0,
      isShowOperation: false,
      nowPrice: -1,
      itemData: {},
      isShowDetailModal: false,
    };
  },
  computed: {
    ...mapState("jiyang/user", ["info", "userRole"]),
    ...mapState("jiyang/form", ["formState", "formNowType"]),
    propAllData() {
      let data = this.propData;
      if (this.formNowType === 5) {
        let check = data.find((val) => val.prop === "operation");
        if (!check) {
          let obj = { prop: "operation", label: "Operation", width: 190 };
          data.splice(this.propData.length - 1, 0, obj);
        }
      }
      return data;
    },
    isUnEdit() {
      if (
        ((this.info.user_type == 2 || this.info.user_type == 4) &&
          this.formState == 2) ||
        this.formState == 3 ||
        (this.info.user_type != 2 &&
          this.info.user_type != 4 &&
          this.formState == 1) ||
        this.formNowType >= 5||this.isHistory
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    table(val) {
      val.forEach((item, index) => {
        if (!item.height) {
          val[index] = { ...item, ...{ height: 40 } };
        }
        if (!item.operation) {
          this.table[index] = {
            ...item,
            ...{
              operation: {
                type: "create",
                price: 0,
              },
            },
          };
        }
      });
      this.tableData = val;
      this.itemData = val[0];
    },
    formType(val) {
      if (val === 5) {
        this.isUnEdit = true;
      }
    },
    rowHeight(val) {
      console.log(val);
    },
  },
  created() {
    let that = this;
    document.addEventListener("click", (e) => {
      if (!that.isShowSetting) return;
      if (!that.$el.contains(e.target)) {
        that.isShowSetting = false;
      }
    });
  },
  mounted() {
    let that = this;
    // 阻止表格默认行为，用于实现拖拽
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    this.$nextTick(() => {
      this.rowDrop();
      // this.columnDrop();
      resizeTable((data) => {
        let checkIndex = that.checkIndex;
        if (!that.table[checkIndex]) return false;
        if (!that.table[checkIndex].height) {
          that.table[checkIndex] = {
            ...that.table[checkIndex],
            ...{ height: data.height },
          };
        } else {
          that.table[checkIndex].height = data.height;
        }
      });
    });
  },

  methods: {
    changeInput(row, index) {
      console.log(row, index);
      this.$set(this.tableData, index, row);
    },
    closeDetailModal() {
      this.isShowDetailModal = false;
    },
    //显示详情弹窗
    showDetailDialog(row, index) {
      this.itemData = row;
      this.isShowDetailModal = true;
      this.checkIndex = index;
    },
    //跳转下一个商品
    toNextGoods(type) {
      let index;
      if (type === 2) {
        this.checkIndex++;
      } else {
        this.checkIndex--;
      }
      if (this.checkIndex < 0) {
        this.checkIndex = this.tableData.length - 1;
      } else if (this.checkIndex > this.tableData.length - 1) {
        this.checkIndex = 0;
      }

      this.itemData = this.tableData[this.checkIndex];
    },
    //表格拖拽
    rowDrop() {
      const tbodys = document.querySelectorAll("#formTableData tbody");
      tbodys.forEach((item) => {
        item.addEventListener("mousemove", this.showRowTip, false);
      });
      const tbody = document.querySelector("#formTableData tbody");
      const _this = this;
      this.sortable = Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".el-table__row",
        disabled: false,
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
        },
      });
    },
    //显示议价弹窗
    toOperation(e) {
      if(e.path)this.operationTop = e.path[8].offsetHeight + e.path[8].offsetTop;
      this.isShowOperation = true;
    },
    //设置目标价格
    setOperationPrice(data) {
      this.changeOperationType(this.checkIndex, {
        type: "operation",
        price: data.price,
      });
    },
    //确认报价
    confirmOperation(index) {
      //let operation = this.tableData[this.checkIndex].operation;
	  let operation = this.tableData[index].operation;

      operation.type = "confirmed";
      //this.changeOperationType(this.checkIndex, operation);
	  this.changeOperationType(index, operation);
    },
    closeOperation() {
      this.isShowOperation = false;
    },
    //返回初始状态
    backOperation() {
      this.changeOperationType(this.checkIndex, {
        type: "create",
        price: 0,
      });
    },
    changeOperationType(index, operation) {
      let item = this.tableData[index];
      item.operation = operation;
      this.$set(this.tableData, index, item);
      this.isShowOperation = false;
    },
    showRowTip(e) {
      const currentTagHeight = e.target.offsetHeight;
      const cursorPositionY = e.offsetY;
      const classname = e.target.parentNode.className;
      if (
        (classname === "el-table__row formTableRow" ||
          classname === "el-table__row el-table__row--striped") &&
        currentTagHeight - cursorPositionY < 8
      ) {
        this.sortable.options.disabled = true;
      } else {
        this.sortable.options.disabled = false;
      }
    },
    //列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(".el-table__header-wrapper tr");
      const _this = this;
      this.sortable = Sortable.create(wrapperTr, {
        draggable: ".th",
        animation: 180,
        delay: 0,
        onEnd: (evt) => {
          const oldItem = _this.propData[evt.oldIndex];
          _this.propData.splice(evt.oldIndex, 1);
          _this.propData.splice(evt.newIndex, 0, oldItem);
        },
      });
    },
    //改变列宽事件
    headerDragend(newWidth, oldWidth, col, event) {
      if (col.property === "price" || col.property === "amount") {
        col.width = 120;
        return;
      }
      if (newWidth < 80) {
        col.width = 80;
      }
    },
    //获取行索引
    formTableRow({ row, rowIndex }) {
      row.index = rowIndex;
      if (this.type === 1) {
        return "formTableRow";
      } else {
        return "getFormTableRowHeight";
      }
    },
    //设置行样式
    formRowStyle({ row, rowIndex }) {
      if (row.height) {
        return { height: row.height + "px" };
      } else {
        return { height: "40px" };
      }
    },
    //点击行获取
    rowClick(row, column, mouse) {
      this.checkIndex = row.index;
      console.log(row,this.isShowSetting)
      if (row.id || row.img_url) {
        this.nowIndex = row.index;
        this.isShowSetting = true;
        return false;
      }

      if (this.isUnEdit) return;
      this.nowIndex = -1;
      this.$refs.uploadImg.$children[0].$refs.input.click();
      // let top = mouse.clientY - 183;
      // this.$emit("showProduct", {
      //   top: top,
      //   index: row.index,
      // });
    },
    //移除行
    removeProduct(rowIndex) {
      this.$emit("removeProduct", {
        index: rowIndex,
      });
    },

    //上传图片限制
    beforeUpload(file) {
      let that = this;
      let limit_file = 4;
      const limit = file.size / 1024 / 1024 < limit_file;
      if (!limit) {
        this.$message({
          message: `${that.$t("uploadWarning")} ${limit_file}MB`,
          type: "warning",
        });
      }
      return limit;
    },
    // 选择图片
    async picUpload(file) {
      let loading = this.openLoading();
      let fileLists = [file.file];
      let url = await this.uploadImg(fileLists);

      this.tableData[this.checkIndex].img_url = url[0];
      this.$set(
        this.tableData,
        this.checkIndex,
        this.tableData[this.checkIndex]
      );
      this.$emit('updateImage',{url:url[0],index:this.checkIndex})
      loading.close();
    },
    //上传图片
    uploadImg(pictureList) {
      return new Promise((resolve) => {
        uploadCos(pictureList, "works", (picture) => {
          resolve(picture);
        });
      });
    },
    //获取当前价格
    intoPrice(row, index) {
      this.nowPrice = row.price;
    },
    //  设置总数
    setAmount(row, index, prop) {
      if (prop === "price") {
        if (row.price !== this.nowPrice) {
          let item = this.tableData[index];
          item.operation = {
            type: "create",
            price: 0,
          };
          this.$set(this.tableData, index, item);
        }
      }
      if (row.price && row.number) {
        row.amount = row.price * Number(row.number);
        this.$emit("setAmount", {
          amount: row.amount,
          index: index,
        });
      } else {
        row.amount = 0;
        this.$emit("setAmount", {
          amount: row.amount,
          index: index,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.form-table-box {
  table {
    width: 100% !important;
  }
  .el-input__inner {
    border: none !important;
    /*border-bottom:1px solid #c0c4cc!important;*/
    border-radius: 0 !important;
    padding: 0 4px !important;
    height: 24px;
  }
  .textarea-input {
    border: none;
    resize: none;
  }
  .table-input {
    width: 100%;
    border: none !important;
    /*border-bottom:1px solid #c0c4cc!important;*/
    border-radius: 0 !important;
    padding: 0 4px !important;
    height: 24px;
    background-color: transparent;
  }
  .textarea-input {
    width: 100%;
    border: none !important;
    /*border-bottom:1px solid #c0c4cc!important;*/
    border-radius: 0 !important;
    padding: 0 4px !important;
    background-color: transparent;
  }
  .el-table {
    overflow: visible;
    .cell {
      overflow: visible;
    }
  }
  .el-table__body-wrapper {
    overflow: visible!important;
  }
  .row-height {
    min-height: 24px;
    line-height: 24px;
  }
  .style-number-box {
    position: relative;
    .setting-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -30px;
      cursor: pointer;
    }
  }
  .form-img-box {
    height: 100%;
  }
  .form-img {
    height: 40px;
    //min-height: 40px;
    //height: 100%;
    width: auto;
    max-width: 100%;
  }
  .operation-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .operation-btn {
      width: 86px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
      margin: 0 5px;
    }
    .confirm {
      background-color: #64d9bb;
    }
    .bargain {
      background-color: #feea77;
    }
    .operation {
      background-color: #64d9bb;
      min-width: 86px;
      color: #fff;
    }
    .unconfirmed {
      background-color: #bbb;
      color: #fff;
    }
    .back-icon-btn {
      cursor: pointer;
    }
  }

  .table-header {
    .cell {
      color: $base-config-color !important;
      font-size: $base-font-size !important;
    }
  }
  .el-table .cell {
    font-size: $base-font-size;
    padding: 0 3px !important;
  }
  .el-table td,
  .el-table th {
    padding: 3px 0 !important;
    box-sizing: content-box;
  }
}
</style>

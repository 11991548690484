<template>
  <div class="tab-content">
    <div class="tab-header">
      <a-input-group compact>
        <a-select
          style="width: 30%"
          :value="searchType"
          @change="handleSearchType"
        >
          <a-select-option value="1">{{ $t("searchNumber") }}</a-select-option>
          <a-select-option value="2">{{ $t("searchName") }}</a-select-option>
          <a-select-option value="3">{{ $t("searchCompany") }}</a-select-option>
        </a-select>
        <!-- <a-input
          style="width: 70%"
          :placeholder="$t('handleMaterialsSearch')"
          @change="handleSearch"
        /> -->
        <a-input
          style="width: 70%"
          :placeholder="$t('handleMaterialsSearch')"
          @change="handleSearch"
        >
          <a-tooltip slot="suffix" title="">
            <el-upload
              action=""
              accept=".jpg,.jpeg,.png,.JPG,.PNG"
              :show-file-list="false"
              ref="upload"
              :http-request="toSearchImage"
            >
              <i class="iconfont icon-zhaoxiangji image-search-icon"></i>
            </el-upload>
          </a-tooltip>
        </a-input>
      </a-input-group>
      <!-- <a-input
	    :placeholder="$t('handleMaterialsSearch')"
		@change="handleChange"
	    @pressEnter="handleSearch"
	  /> -->
      <a-button
        icon="folder"
        @click="showFolder = !showFolder"
        type="info"
      ></a-button>
      <a-dropdown>
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <a-button icon="plus" type="primary"></a-button>
        </a>
        <a-menu slot="overlay" @click="handleAdd">
          <a-menu-item v-for="(menu, idx) in addType" :key="idx">
            <a href="javascript:;">{{ $t(menu.label) }}</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <!-- <a-button
        icon="edit"
        :disabled="disableEdit"
        type="success"
        @click="showEditor = true"
      ></a-button> -->
      <a-popconfirm
        :title="$t('tipDel')"
        :ok-text="$t('yes')"
        :cancel-text="$t('no')"
        @confirm="confirmDel"
        :disabled="disableDel"
      >
        <a-button icon="delete" type="danger" :disabled="disableDel"></a-button>
      </a-popconfirm>
      <a-button icon="sync" @click="handleRefresh"></a-button>
    </div>
    <folder
      :visiable.sync="showFolder"
      :folders="materialFolders"
      @selected="handleFolderSelect"
    ></folder>
    <div
      id="preBox"
      style="
        width: 100%;
        height: 250px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: #fff;
      "
    >
      <img id="preview" style="height: 100%" /><br />
      <span id="preSn" style="position: relative; bottom: 20px"></span>
    </div>
    <resource-list
      :data="filterMaterial"
      @page-change="handlePageChange"
      @selected="handleSelect"
      @edit="(m) => handleEdit(m)"
      :total="materialPage.total"
      :pageSize="materialPage.per_page"
    ></resource-list>
    <material-editor
      :visible.sync="showEditor"
      :title="$t(drawTitle)"
      @mat-change="handleMatChange"
      @ok="handleOk"
      @close="handleClose"
      :material="materialType"
      :materialData="materialData"
    >
    </material-editor>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");

import ResourceList from "@/components/ResourceList.vue";
import folder from "@/components/Folder.vue";
import MaterialEditor from "@/components/material/MaterialEditor.vue";
import {
  materials,
  materialsConfig,
  materialsData,
} from "@/utils/babylonjs/meshData";
import uploadCos from "@/utils/upload_to_cos";

export default {
  components: {
    ResourceList,
    folder,
    MaterialEditor,
  },
  data() {
    return {
      showFolder: true,
      showEditor: false,
      selected: [],
      materialType: "metal",
      materialParam: null,
      drawTitle: "createMaterial",
      defaultOption: {
        id: 1,
        name: "material name",
        shape: "ball",
        type: "pbr",
        material: "metal",
        param: {
          reflectionTexture: {
            url: "texture/environmentSpecular.env",
            id: 1,
            type: "env",
            material: null,
          },
          // microSurface: 0.96,
          // albedoColor: { r: 0.01, g: 0.01, b: 0.01 },
          // reflectivityColor: { r: 0.85, g: 0.85, b: 0.85 },
        },
      },
      addType: [
        { label: "createMetalMaterial", material: "metal" },
        { label: "createLeatherMaterial", material: "leather" },
        { label: "createClothMaterial", material: "cloth" },
        { label: "createFurMaterial", material: "fur" },
        { label: "createGlassMaterial", material: "glass" },
        { label: "createSoleMaterial", material: "sole" },
      ],
      materialData: {},
      page: 1,
      pageSize: 12,
      searchType: "1",
    };
  },
  watch: {},
  computed: {
    ...mapState(["materialFolders", "materialPage"]),
    ...mapGetters(["filterMaterial"]),
    disableEdit() {
      return this.selected.length != 1;
    },
    disableDel() {
      return this.selected.length <= 0;
    },
  },
  created() {
    //this.getMaterialList();
    this.getMaterialFolderList();
  },
  methods: {
    ...mapMutations([
      "SET_SEARCH",
      "SET_CURRENT_MATFOLDER",
      "SET_HOLDER_VISIBLE",
      "SET_SEARCH_TYPE",
    ]),
    ...mapActions([
      "getMaterialFolderList",
      "getMaterialList",
      "delMaterial",
      "saveMaterial",
      "changeMaterial",
      "renderMaterials",
      "materialSearch",
    ]),
    getMaterials(param = {}) {
      let that = this;
      this.getMaterialList(param).then((materials) => {
        let data = {
          ModelScene: this.global.ModelScene,
          MaterialScene: this.global.MaterialScene,
          materials: materials,
        };
        that.renderMaterials(data);
      });
    },
    handleFolderSelect(idx) {
      this.SET_CURRENT_MATFOLDER(idx);
      this.getMaterials();
    },
    handleAdd({ key }) {
      this.showEditor = true;
      this.SET_HOLDER_VISIBLE(true);
      this.drawTitle = this.addType[key].label;
      // this.materialType = this.addType[key].material;
      this.materialType = "pbr";
      if (this.global.NewMaterialScene.pickedMesh) {
        this.global.NewMaterialScene.pickedMesh.dispose();
      }
      document.getElementById("work").style.display = "none";
      document.getElementById("materialWork").style.display = "block";
      this.global.NewMaterialScene.loadMaterial(
        materialsData[this.addType[key].material]
      );
      this.materialData = materialsData[this.addType[key].material];
    },
    handleSelect(val) {
      this.selected = [...val];
    },
    handleSearch(e) {
      this.SET_SEARCH_TYPE(this.searchType);
      this.SET_SEARCH(e.target.value);
      this.getMaterials();
    },
    handleSearchType(e) {
      this.searchType = e;
    },
    handleChange(e) {
      this.SET_SEARCH(e.target.value);
    },
    handlePageChange(page, pageSize) {
      //this.getMaterialList({ page, pageSize });
      this.page = page;
      this.pageSize = pageSize;
      //this.getMaterials({ page, pageSize });
    },
    handleRefresh() {
      //this.getMaterialList();
      this.getMaterials();
    },
    handleMatChange(val) {
      this.materialParam = val;
      // let that = this;
      // let data = {
      //   ModelScene: this.global.NewMaterialScene,
      //   pbr: this.materialParam,
      // };
      // setTimeout(function () {
      //   that.changeMaterial(data);
      // }, 10);
    },
    handleOk(type) {
      let loading = this.openLoading();
      let that = this;
      let page = this.page;
      let pageSize = this.pageSize;
      if (type == "create") {
        that.materialParam.id = "";
      } else if (type == "update") {
        if (!that.materialParam.id) {
          return;
        }
      } else {
        return;
      }
      this.global.NewMaterialScene.screenShot().then((file) => {
        that.materialParam.param = that.global.NewMaterialScene.getMaterialData(
          that.materialParam.param
        );
        uploadCos([file], "works", (picture) => {
          that.materialParam.coverpic = picture[0];
          if (that.materialParam != null) {
            that.saveMaterial(that.materialParam);
          }
          setTimeout(function () {
            that.getMaterials({ page, pageSize });
          }, 500);
          document.getElementById("work").style.display = "block";
          document.getElementById("materialWork").style.display = "none";
          loading.close();
        });
      });
    },
    handleClose() {},
    confirmDel() {
      this.handleDel();
    },
    handleDel() {
      const ids = this.selected.map((s) => s.id);
      this.delMaterial(ids);
      this.init();
      let that = this;
      setTimeout(function () {
        that.getMaterials();
      }, 500);
    },
    init() {
      this.selected = [];
    },
    handleEdit(m) {
      //if(m.status){
      this.drawTitle = "editMaterial";
      this.showEditor = true;
      this.SET_HOLDER_VISIBLE(true);
      if (this.global.NewMaterialScene.pickedMesh) {
        this.global.NewMaterialScene.pickedMesh.dispose();
      }
      document.getElementById("work").style.display = "none";
      document.getElementById("materialWork").style.display = "block";
      this.global.NewMaterialScene.loadMaterial(m.material);
      this.materialData = m.material;
      this.materialType = "pbr";
      // document.getElementById("materialIframe").style.display = "block";
      // document.getElementById("materialIframe").src =
      //   "http://127.0.0.1/index.html?data=" + JSON.stringify(m.material);
      // }else{
      // 		this.materialData = {};
      // 		this.showEditor = false;
      // 		document.getElementById("work").style.display = "block";
      // 		document.getElementById("materialWork").style.display = "none";
      // }
    },
    //AI搜索图片
    async toSearchImage(file) {
      let that = this;
      let files = file.file;
      // let check = await this.checkImage(files);
      // if (!check) {
      //   this.$message.error("上传图片信息有误");
      //   return false;
      // }
      that.materialSearch(files);
    },
  },
};
</script>

<style lang="scss">
.tab-content {
  width: 100%;
  height: 100%;
  padding: 0 12px;
}
.tab-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .ant-input-group {
    margin-right: 0 !important;
  }
  .ant-btn-icon-only {
    width: 48px;
  }
  .ant-btn {
    margin-top: 8px;
    margin-right: 8px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>

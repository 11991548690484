<template>
  <div class="header">
    <div class="logo">LOGO</div>
    <div class="menu">
      <a-tooltip placement="bottom" title="上传">
        <i class="iconfont icon-upload primary-color"></i>
      </a-tooltip>
      <a-tooltip placement="bottom" title="鞋子">
        <i class="iconfont icon-shoes primary-color"></i>
      </a-tooltip>
      <a-tooltip placement="bottom" title="通知">
        <i class="iconfont icon-AlertOn"></i>
      </a-tooltip>
      <a-tooltip placement="bottom" title="帮助">
        <i class="iconfont icon-question"></i>
      </a-tooltip>
      <a-dropdown>
        <a class="ant-dropdown-link">
          <a-avatar size="small" icon="user" />
          <span class="user-info">lang hao</span>
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a href="javascript:;">修改</a>
          </a-menu-item>
          <a-menu-item>
            <a href="javascript:;">退出</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
// .header {
//   width: 100%;
//   height: 60px;
//   padding: 0 24px;
//   background-color: #ffffff;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 8px;
//   .logo {
//     width: 100px;
//     background-color: aquamarine;
//   }
//   .menu {
//     display: flex;
//     align-items: center;
//   }
//   .user-info {
//     margin-left: 8px;
//     color: #333;
//   }
//   .iconfont {
//     margin-right: 16px;
//   }
// }
</style>

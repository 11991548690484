<template>
	<div id="assets">
		<right-bar></right-bar>
		<!-- <left-bar></left-bar> -->
		<!-- <textures-list></textures-list> -->
		<!-- <materials-list type="2"></materials-list> -->
	</div>
</template>

<script>
import texturesList from "@/components/assets/textures/list";
import materialsList from "@/components/assets/materials/list";
import leftBar from "@/components/assets/leftBar";
import rightBar from "@/components/assets/rightBar";
export default {
	components:{
		texturesList,
		materialsList,
		rightBar,
	}
}
</script>

<style>
	#assets{
		padding: 10px;
	}
</style>

import { Message, MessageBox } from "element-ui";
import router from "@/router";
import api from "@/api";
import VueCookies from "vue-cookie";

export default {
  namespaced: true,
  state: {
    isUserLogin: false,
  },
  mutations: {
    toCheckLogin(state) {
      if (VueCookies.get("token") && VueCookies.get("user_info")) {
        state.isUserLogin = true;
      } else {
        state.isUserLogin = false;
      }
    },
  },
  actions: {
    /**
     * @description 登录
     * @param {Object} param context
     * @param {Object} param mobile {String} 用户账号
     * @param {Object} param password {String} 密码
     * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    async login(
      { state, dispatch, commit },
      { user_mobile = "", user_pwd = "" } = {}
    ) {
      return new Promise(async (resolve, reject) => {
        const res = await api.mobileLogin({ user_mobile, user_pwd });
        if (!res.userInfo && !res.token) {
          Message({
            type: "error",
            message: "The user does not exist",
            duration: 1 * 1000,
          });
          reject();
        } else {
          Message({
            type: "success",
            message: "Login successfully",
            duration: 1 * 1000,
          });
          //用户信息缓存
          await dispatch("jiyang/user/set", res.userInfo, { root: true });
          //token缓存
          await VueCookies.set("token", `bearer ${res.token}`, 30);
          await commit("toCheckLogin");
          resolve();
        }
      });
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} param context
     * @param {Object} param confirm {Boolean} 是否需要确认
     */
    logout({ commit, dispatch }, { confirm = false } = {}) {
      return new Promise((resolve) => {
        /**
         * @description 注销
         */
        async function logout() {
          // 删除cookie
          // 清空 vuex 用户信息
          await dispatch("jiyang/user/set", {}, { root: true });
          await VueCookies.delete("user_info");
          await VueCookies.delete("token");
          await commit("toCheckLogin");
          // // 跳转路由
          router.replace({
            name: "login",
          });
        }
        // 判断是否需要确认
        if (confirm) {
          MessageBox.confirm("Log Out or Not", "", {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
            customClass: "confirmModal",
          })
            .then(() => {
              logout();
              resolve();
            })
            .catch(() => {});
        } else {
          logout();
        }
      });
    },
    /**
     * @description 加载数据
     * @param {Object} state vuex state
     */
    load({ state, dispatch, commit }) {
      return new Promise(async (resolve) => {
        await dispatch("jiyang/user/load", { root: true });
        // end
        resolve();
      });
    },
  },
};

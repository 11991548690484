<template>
  <el-dialog
      :title="$t('selectedType')"
      :visible.sync="visible"
      :before-close="closeModal"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="550px"
  >
    <div class="create-type-center">
      <div class="create-type-box" @click="toAddProduct">
        <div class="create-type-box-img">
          <i class="iconfont icon-xiezi type-shoe-icon"></i>
        </div>
        <span class="type-text">{{$t('addGoods')}}</span>
      </div>
      <div class="create-type-box" @click="toAddSameProduct">
        <div class="create-type-box-img">
          <i class="iconfont icon-XX_051 type-icon"></i>
        </div>
        <span class="type-text">{{$t('addColor')}}</span>
      </div>

    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "addProductTypeDialog",
  props:{
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{

    }
  },
  methods:{
    closeModal(){
      this.$emit("update:visible", false);
    },
    toAddProduct(){
      this.closeModal()
      this.$emit('toAddProduct')
    },
  //  添加同款
    toAddSameProduct(){
      this.closeModal()
      this.$emit('toAddSameProduct')
    }
  }
}
</script>

<style lang="scss">
  .create-type-center{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 40px;
    .create-type-box{
      text-align: center;
      .create-type-box-img{
        width: 160px;
        height: 160px;
        border: 1px dotted #CFCFCF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 12px;
        .type-shoe-icon{
          font-size: 60px;
          color: #BFBFBF;
        }
        .type-icon{
          font-size: 50px;
          color: #BFBFBF;
        }
      }
      .type-text{
        font-size: 14px;
        color: #CFCFCF;
      }
    }

  }
</style>
<template>
  <div>
    <div class="middle">
      <reviewTable :data="leftArr"></reviewTable>
      <reviewTable v-if="rightArr.length > 0" :data="rightArr"></reviewTable>
    </div>
    <div class="remark-box" v-if="checkShowRemark">
      <div class="remark-title-box">
        {{ $t("remark") }}
      </div>
      <div class="remark-text-box" @click.stop="toEditRemark">
        <span v-if="!isCanEdit">{{ remarkText }}</span>
        <el-input
          ref="remarkInput"
          v-model="remarkText"
          size="mini"
          v-else
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import reviewTable from "../reviewTable/reviewTable";
import { mapState } from "vuex";
export default {
  name: "review-middle",
  components: {
    reviewTable,
  },
  data() {
    return {
      tableData: [],
      leftSpanArrs: [],
      rightSpanArrs: [],
      leftArr: [],
      rightArr: [],
      isCanEdit: false,
      remarkText: this.remark,
      extraLeftArr: [],
      extraRightArr: [],
    };
  },
  props: {
    table: {
      type: Array,
    },
    remark: {
      type: String,
    },
    checkShowRemark: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("jiyang/config", ["cosUrl"]),
  },
  watch:{
	  table:{
		  handler:function(val){
			  this.leftArr = [];
			  this.rightArr = [];
			  this.table = val;
			  this.getList(this.table);
		  },
		  deep:true
	  }
  },
  created() {
    this.getList(this.table);
  },
  mounted() {
    this.$nextTick(() => {
      let height = 1123 - 44 - 220 - 22; //获取表格最大高度
      let list = document.getElementsByClassName("reviewTableRow");
      this.getFullTable(height, list, this.rightArr.length, 1);
      this.getFullTable(height, list, this.leftArr.length, 0);
    });
  },
  methods: {
    //拆分左右数组
    async getList(table) {
      let leg = table.length;
      let left = Math.ceil(leg / 2) - 1;
      let data = this.sortArr(table, "part_id");
      for (let key in data) {
        let val = data[key];
        // val.cover_pic=val.cover_pic.replace(this.cosUrl,'/imgurl')
        // val.texture_remark_img=val.texture_remark_img.replace(this.cosUrl,'/imgurl')
        // val.cover_pic=await this.main(val.cover_pic)
        // val.texture_remark_img=await this.main(val.texture_remark_img)

        if (key <= left) {
          this.leftArr.push(val);
        } else {
          this.rightArr.push(val);
        }
      }
    },
    //判断左右超出页面高度
    getFullTable(pageHeight, list, length, type) {
      let height = 0;
      let index = 0;
      if (type == 0) {
        let sliceList = Array.from(list).slice(0, length);
        let check = sliceList.some((val, key) => {
          height += val.clientHeight;
          if (height >= pageHeight) {
            index = key;
            return true;
          }
        });
        if (check) {
          this.extraLeftArr = this.leftArr.slice(index, length);
          this.leftArr = this.leftArr.slice(0, index);
          this.$emit("getExtraLeftArr", this.extraLeftArr);
        }
      } else {
        let sliceList = Array.from(list).slice(
          this.leftArr.length,
          list.length
        );
        let check = sliceList.some((val, key) => {
          height += val.clientHeight;
          if (height >= pageHeight) {
            index = key;
            return true;
          }
        });
        if (check) {
          this.extraRightArr = this.rightArr.slice(index, length);
          this.rightArr = this.rightArr.slice(0, index);
          this.$emit("getExtraRightArr", this.extraRightArr);
        }
      }
    },
    //图片转base64
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      var dataURL = canvas.toDataURL("image/png"); // 可选其他值 image/jpeg
      return dataURL;
    },

    main(src) {
      let that = this;
      return new Promise((resolve) => {
        let url = "";
        if (src) {
          var image = new Image();
          image.src =
            src + "?v=" + Math.random() + "&imageMogr2/thumbnail/!10p"; // 处理缓存
          image.crossOrigin = "*"; // 支持跨域图片
          image.onload = function () {
            url = that.getBase64Image(image);
            resolve(url);
          };
        } else {
          resolve(url);
        }
      });
    },

    //是否编辑备注
    toEditRemark() {
      this.isCanEdit = true;
      setTimeout(() => {
        this.$refs.remarkInput.focus();
      }, 10);
    },
    clickPage() {
      this.isCanEdit = false;
    },
    sortArr(data, params) {
      const datas = JSON.parse(JSON.stringify(data)).sort(function (a, b) {
        if (a[params] != b[params]) {
          return a[params] - b[params];
        }
        return a.num - b.num;
      });
      return datas;
    },
  },
};
</script>

<style lang="scss">
$page-border: 3px solid #aaa;
.middle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 4px;
  .el-table td,
  .el-table th {
    padding: 3px 0 !important;
    border-right: 1px solid #dbddd6 !important;
    border-bottom: 1px solid #dbddd6 !important;
  }
  .el-table--border,
  .el-table--group {
    border: 1px solid #dbddd6;
    border-right: none;
    border-bottom: none;
  }
  .el-table .cell {
    padding-left: 6px !important;
    padding-right: 6px !important;
    line-height: 16px;
  }
  .el-table {
    font-size: 10px !important;
  }
  .texture_img {
    width: 25px;
    height: 25px;
    border: none;
    margin: 0 auto;
    background-color: #f2f2f2;
  }
}
.remark-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dbddd6;

  font-size: 10px;
  box-sizing: border-box;
  .remark-title-box {
    width: 100px;

    text-align: center;
    padding: 4px;
    box-sizing: border-box;
  }
  .remark-text-box {
    flex: 1;
    padding: 8px;
    text-align: left;
    border-left: 1px solid #dbddd6;
    box-sizing: border-box;
  }
}
</style>

<template>
  <div>
    <div class="list-form-header" v-if="!checkEdit">
      <div class="form-header-left">
        <div class="avatar-box" v-if="itemData.logo">
          <el-image
            class="company-img"
            :src="itemData.logo + '?imageMogr2/thumbnail/!30p'"
            fit="contain"
          ></el-image>
        </div>
        <div class="company-desc">
          <span>{{ itemData.desc }}</span>
        </div>
      </div>
      <div class="list-form-title-box">
        <div class="hide-left"></div>
        <div class="form-title-text">
          <span>{{ itemData.title }}</span>
        </div>
      </div>
    </div>
    <div class="list-form-header" v-else>
      <div class="form-header-left">
        <el-upload
          class="avatar-uploader"
          action=""
          accept=".jpg,.jpeg,.png,.JPG,.PNG"
          multiple
          :before-upload="beforeUpload"
          :show-file-list="false"
          :http-request="picUpload"
        >
          <div class="avatar-box" v-if="itemData.logo">
            <el-image
              class="company-img"
              :src="itemData.logo + '?imageMogr2/thumbnail/!30p'"
              fit="contain"
            ></el-image>
          </div>
          <div v-else class="avatar-add-box">
            <i class="el-icon-plus"></i>
            <span class="avatar-add-text">Add Company LOGO</span>
          </div>
        </el-upload>
        <div>
          <textarea
            v-model="itemData.desc"
            class="company-desc-textarea"
            style="resize: none"
            rows="5"
          ></textarea>
        </div>
      </div>

      <div class="list-form-title-box">
        <div class="hide-left"></div>
        <el-input
          class="list-form-title"
          v-model="itemData.title"
          size="mini"
          placeholder="单据名称"
          :style="{ width: formWidth + 'px!important' }"
          placeholder-style="color:#fff"
        ></el-input>
      </div>
      <span class="hidden-span" ref="hiddenSpan">{{ itemData.title }}</span>
    </div>
  </div>
</template>

<script>
import uploadCos from "@/utils/upload_to_cos";
import elementResizeDetectorMaker from "element-resize-detector";
export default {
  name: "formHeader",
  props: {
    checkEdit: {
      type: Boolean,
      default: true,
    },
    itemData: {
      type: Object,
    },
  },
  data() {
    return {
      logo: "",
      title: "QUOTATION",
      company: "",
      formWidth: 260,
    };
  },
  mounted() {
    if (this.checkEdit) {
      let erd = elementResizeDetectorMaker();
      this.$nextTick(() => {
        erd.listenTo(this.$refs.hiddenSpan, this.getSpanWidth);
      });
    }
  },
  methods: {
    //修改宽度
    getSpanWidth(element) {
      let width = element.clientWidth + 8;
      this.formWidth = width <= 200 ? 200 : width;
    },
    //上传图片限制
    beforeUpload(file) {
      let that = this;
      let limit_file = 4;
      const limit = file.size / 1024 / 1024 < limit_file;
      if (!limit) {
        this.$message({
          message: `${that.$t("uploadWarning")} ${limit_file}MB`,
          type: "warning",
        });
      }
      return limit;
    },
    // 选择图片
    async picUpload(file, key) {
      let loading = this.openLoading();
      let fileLists = [file.file];
      let url = await this.uploadImg(fileLists);
      this.itemData.logo = url[0];
      loading.close();
    },
    //上传图片
    uploadImg(pictureList) {
      return new Promise((resolve) => {
        uploadCos(pictureList, "works", (picture) => {
          resolve(picture);
        });
      });
    },
  },
};
</script>

<style lang="scss">
.list-form-header {
  padding: 20px 0 0 20px;
  display: flex;
  justify-content: space-between;
  min-height: 74px;
  border-bottom: 4px solid $base-config-color;
  align-items: flex-start;
  position: relative;
  .company-img {
    height: 76px;
    display: flex;
    justify-content: flex-start;
    img {
      height: 76px;
      width: auto !important;
    }
  }
  .company-name {
    font-size: 16px;
    font-weight: 500;
    color: #969799;
  }
  .avatar-add-box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 276px;
    height: 76px;
    .el-icon-plus {
      color: #bebebe;
      font-size: 30px;
      font-weight: 600;
      margin-right: 10px;
    }
    .avatar-add-text {
      font-size: 14px;
      color: #7a7c7f;
    }
  }
  .avatar-box {
    width: auto;
    height: 76px;
    line-height: 76px;
    text-align: center;
  }
  .company-desc-textarea {
    width: 402px;
    height: 104px;
    color: #7a7c7f;
    font-size: 18px;
    border: 1px solid #e2dede;
    padding: 6px 8px;
  }
  .company-desc {
    width: 462px;
    padding: 6px 8px;
    color: #7a7c7f;
    font-size: 18px;
  }
}
.list-form-title-box {
  background-color: $base-config-color;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px 0 36px;
  position: absolute;
  right: 0;
  bottom: 0;
  box-sizing: content-box;
  .hide-left {
    width: 0;
    height: 0;
    border-top: 46px solid #fff;
    border-right: 36px solid transparent;
    position: absolute;
    left: 0;
    top: 0;
  }
  .el-input__inner {
    height: 28px;
    line-height: 28px;
    color: #fff;
    font-weight: 600;
    font-size: 28px;
    min-width: 200px !important;
  }
  .form-title-text {
    height: 28px;
    line-height: 28px;
    color: #fff;
    font-weight: 600;
    font-size: 28px;
  }
}
.hidden-span {
  position: absolute;
  left: -99999px;

  color: #333;
  font-weight: 600;
  font-size: 34px;
}
</style>

<template>
  <div
    class="file-send"
    v-if="visible"
    :style="{ top: `${y}px`, left: `${x}px` }"
  >
    <div>
      <span class="label">发送给：</span>
      <div class="user-info">
        <!-- <img class="avatar" :src="userInfo.avatar" alt="avatar" /> -->
        <div class="avatar">
          <el-image :src="userInfo.avatar">
            <div slot="error" class="image-slot">
              <i class="el-icon-user"></i>
            </div>
          </el-image>
        </div>
        <span class="nickname">{{ userInfo.nickname }}</span>
      </div>
      <div class="file-info">
        <div class="file-item" v-for="file in files" :key="file.id">
          <!-- <img class="icon" :src="file.icon" alt="icon" /> -->
          <div class="icon">
            <el-image :src="file.icon">
              <div slot="error" class="image-slot">
                <i class="el-icon-document"></i>
              </div>
            </el-image>
          </div>
          <div class="file-item-detail">
            <span class="filename">{{ file.name }}</span>
            <span class="file-size">{{ parseInt(file.size / 1000) }} KB</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="btn" @click.stop="handleCancel">取消</button>
      <button class="btn primary" @click.stop="handleOk">发送</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    x: {
      type: Number,
      default: 40,
    },
    y: {
      type: Number,
      default: -235,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => [
        {
          id: 0,
          icon: "",
          name: "",
          size: 0,
          type: "image/png",
          file: null,
        },
      ],
    },
    userInfo: {
      type: Object,
      default: () => ({
        avatar: "",
        nickname: "",
      }),
    },
  },
  methods: {
    handleCancel() {
      this.close();
    },
    handleOk() {
      this.close();
      this.$emit("ok", this.files);
    },
    close() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="less" scoped>
.file-send {
  position: absolute;
  top: -215px;
  left: 47px;
  padding: 16px 16px;
  width: 358px;
  height: 296px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  background-color: #f3f3f3;
  .user-info {
    margin-top: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #aaa;
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }
    .nickname {
      width: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .file-info {
    height: 128px;
    overflow: auto;
    padding: 8px 0px;
  }
  .file-item {
    display: flex;
    padding: 8px 4px;
    .icon {
      margin: 16px;
      font-size: 30px;
    }
    .filename {
      width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 8px 0px;
    }
    .file-item-detail {
      display: flex;
      flex-direction: column;
    }
  }
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    .btn {
      width: 126px;
      height: 34px;
      background: #e1e1e1;
      border-radius: 3px;
      outline: none;
      border: none;
      color: #16c39a;
      cursor: pointer;
    }
    .btn.primary {
      background-color: #16c39a;
      color: #fff;
    }
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
const d3 = require("./utils/d3.v5.min.js");

import ElementUI from "element-ui";
import * as vClickOutside from "v-click-outside-x";
import VueCookies from "vue-cookie";
import api from "@/api";

import VueI18n from "vue-i18n";
import messages from "./i18n";
import { useAntd } from "./plugins";

import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/base.scss";
import "./assets/css/index.css";
import "./assets/css/v2.css";
import { dragSize } from "./utils/dragSize.js";

import TIM from "tim-js-sdk";
let options = {
  // SDKAppID: 1400580284, //这个需要替换成 新的IM的APPID
  SDKAppID: 1400620139, //这个需要替换成 新的IM的APPID
  // SDKAppID: 1400558391, //这个需要替换成 新的IM的APPID
};

Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });

Vue.use(VueCookies);
Vue.prototype.$TIM = TIM;
Vue.prototype.$im = TIM.create(options);
Vue.prototype.$api = api;
Vue.use(VueI18n);
Vue.use(vClickOutside);
useAntd();
Vue.config.productionTip = false;

Vue.directive("dragSize", dragSize);
const i18n = new VueI18n({
  locale: VueCookies.get("language") || "en", //设置地区
  messages, // 设置地区信息
});
var jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
if (jsSrc.indexOf("zh") != -1) {
  i18n.locale = "zh";
}
//加载框
Vue.prototype.openLoading = function (times = 20000) {
  const loading = this.$loading({
    lock: true,
    text: "Loading...",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.3)",
    body: true,
    customClass: "loading",
    // fullscreen:false
  });
  setTimeout(function () {
    loading.close();
  }, times);
  return loading;
};
//对象去空
Vue.prototype.filterParams = function (obj) {
  let _newPar = {};
  for (let key in obj) {
    if (
      (obj[key] === 0 || obj[key]) &&
      obj[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== ""
    ) {
      // 记录属性
      _newPar[key] = obj[key];
    }
  }
  return _newPar;
};
//全局遍历
Vue.prototype.global = {
  ModelScene: null,
  MaterialScene: null,
  NewMaterialScene: null,
  PickrColor: null,
  IsDown: false,
  PickrMaterialColor: null,
};

Vue.prototype.$d3 = d3;
new Vue({
  router,	
  store,
  i18n,
  render: (h) => h(App),
  created() {
    this.$store.commit("jiyang/user/setLanguage", i18n.locale);
    this.$store.dispatch("jiyang/user/load");
    this.$store.commit("jiyang/account/toCheckLogin");
  },
}).$mount("#app");

<template>
  <div class="form-table-list">
    <div class="tool-bar">
      <a-dropdown>
        <a @click="(e) => e.preventDefault()">
          <i class="iconfont icon-table-"></i>
          {{ $t(listType) }}
          <a-icon type="down" />
        </a>
        <a-menu slot="overlay" @click="handleViewChange">
          <a-menu-item key="tableView">
            <i class="iconfont icon-table-" />
            {{ $t("tableView") }}
          </a-menu-item>
          <a-menu-item key="cardView">
            <i class="iconfont icon-table-" />
            {{ $t("cardView") }}
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <div class="btn-group">
        <!--        <n-button-->
        <!--          icon="icon-edit"-->
        <!--          @click="toEditTemplate"-->
        <!--          v-if="info.user_type == 1 && formType != 5"-->
        <!--          class="btn-font"-->
        <!--          >{{ $t("editModule") }}</n-button-->
        <!--        >-->
        <n-button
          icon="icon-plus"
          @click="toAddForm"
          v-if="userRole === 'custom' && formType == 1"
          class="btn-font"
          >{{ $t("newEnquiry") }}</n-button
        >
        <n-button
          icon="icon-plus"
          @click="toAddForm"
          class="btn-font"
          v-if="userRole === 'company' && formType == 2"
          >{{ $t("newQuotation") }}</n-button
        >
        <n-button
          icon="icon-plus"
          @click="toAddForm"
          class="btn-font"
          v-if="userRole === 'company' && formType == 5"
          >{{ $t("newCBD") }}</n-button
        >
        <div v-click-outside="checkOutSide">
          <div class="form-search-box" v-show="!show" @click="showSearch">
            <i class="iconfont icon-sousuo1"></i>
            <span>{{ $t("searchForm") }}</span>
          </div>
          <div class="form-search-box" v-show="show">
            <i class="iconfont icon-sousuo1"></i>
            <input
              ref="input"
              type="text"
              class="search-input"
              placeholder="Search"
              v-model="search.sn"
              @keydown.enter="searchSn"
              @blur="searchSn"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-view" v-if="listType === 'cardView'">
      <div v-if="lists.length > 0">
        <div class="form-card-list">
          <formCard
            v-for="(val, key) in lists"
            :key="key"
            :formType="formType"
            :item="val"
            @click.native="emit(val)"
          ></formCard>
          <!--  占位元素  -->
          <listSpace
            v-for="i in 10"
            :key="i + 'key'"
            :item-width="420"
          ></listSpace>
        </div>
        <a-pagination
          class="pagination"
          v-model="pagination.current"
          :total="pagination.total"
          center
          show-less-items
        />
      </div>
      <a-empty v-else></a-empty>
    </div>
    <div class="n-table" v-if="listType === 'tableView'">
      <a-table
        :columns="allColumns"
        rowKey="id"
        :data-source="lists"
        size="small"
        :customRow="customRow"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <img
          style="width: 100px; heigth: 100px"
          slot="img_url"
          slot-scope="text, record"
          :src="record.img_url"
          alt=""
        />

        <div slot="states" slot-scope="states">
          <div
            class="form-tag"
            :class="[
              orderType(states) === 'enquiry'
                ? 'enquiry'
                : orderType(states) === 'quotation'
                ? 'quotation'
                : orderType(states) === 'quotationed'
                ? 'quotationed'
                : orderType(states) === 'order'
                ? 'order'
                : '',
            ]"
          >
            {{ stateArr[states <= 0 ? 0 : states] }}
          </div>
        </div>
        <div
          slot="filterDropdown"
          style="padding: 8px"
        >
          <a-range-picker
            :value="search.date"
            style="margin-bottom: 8px; display: block"
            @change="onChangeDate"
          />
        </div>
        <div
            slot="stateDropdown"
            style="padding: 8px"
        >
          <a-dropdown>
            <a-menu  @click="handleMenuClick">
              <a-menu-item v-for="val in stateFilter" :key="val.type"> {{val.label}} </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import NButton from "@/views/crm/tool-bar/button/index.vue";
import listSpace from "../listSpace";
import formCard from "./formCard";
import { mapState } from "vuex";
export default {
  components: {
    NButton,
    formCard,
    listSpace,
  },
  props: {
    formType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      mCustomers: [],
      listType: "tableView",
      show: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      search: {
        sn: "",
        date: [],
        state:""
      },
      searchDate: [],
      lists: [],
      type: this.formType,
      searchText: "",
      searchInput: null,
      searchedColumn: "",

      stateArr: [
        this.$t("closeForm"),
        this.$t("unEnquiry"),
        this.$t("isSendEnquiry"),
        this.$t("enquiryed"),
        this.$t("unQuotation"),
        this.$t("isQuotation"),
        this.$t("isConfirmQuotation"),
        this.$t("isPay"),
        this.$t("isEnd"),
      ],
	  user_info:{}
    };
  },
  computed: {
    ...mapState("jiyang/user", ["info", "userRole"]),
    stateFilter(){
      if(this.formType===1){
        return [
          {type:'',label: '全部'},
          {type:1,label:this.$t("unEnquiry")},
          {type:2,label:this.$t("isSendEnquiry")},
          {type:2,label:this.$t("enquiryed")},
        ]
      }else{
        return [
          {type:'',label: '全部'},
          {type:4,label:this.$t("unQuotation")},
          {type:5,label:this.$t("isQuotation")},
          {type:6,label:this.$t("isConfirmQuotation")},
        ]
      }

    },
    allColumns() {
      if (this.userRole === "custom") {
		if(this.type == 4){
			return [
			  {
			    title: this.$t("formSn"),
			    dataIndex: "sn",
			    key: "sn",
				customRender: (text, row) => {
					if(row.is_read == 0){
						return (
							<div>
							  {row.sn}
							  <span class="reddot"></span>
							</div>
						);
					}else{
						return (
							<div>
							  {row.sn}
							</div>
						);
					}
				},
			  },
			  {
			    title: this.$t("formCreateTime"),
			    dataIndex: "create_time",
			    key: "create_time",
			    scopedSlots: {
			      filterDropdown: "filterDropdown",
			      filterIcon: "filterIcon",
			      customRender: "customRender",
			    },
			    onFilter: (value, record) => {
			      console.log(value, record);
			    },
			  },
			  {
			    title: this.$t("formCreator"),
			    key: "create_user_name",
			    dataIndex: "create_user_name",
			  },
			  {
			    title: this.$t("formSalesman"),
			    dataIndex: "service_name",
			    key: "service_name",
			  },
			];
		}else{
			return [
			  {
			    title: this.$t("formSn"),
			    dataIndex: "sn",
			    key: "sn",
				customRender: (text, row) => {
					if(row.is_view == 0){
						return (
							<div>
							  {row.sn}
							  <span class="reddot"></span>
							</div>
						);
					}else{
						return (
							<div>
							  {row.sn}
							</div>
						);
					}
				},
			  },
			  {
			    title: this.$t("formCreateTime"),
			    dataIndex: "create_time",
			    key: "create_time",
			    scopedSlots: {
			      filterDropdown: "filterDropdown",
			      filterIcon: "filterIcon",
			      customRender: "customRender",
			    },
			    onFilter: (value, record) => {
			      console.log(value, record);
			    },
			  },
			  {
			    title: this.$t("formCreator"),
			    key: "createUser",
			    dataIndex: "createUser",
			    customRender: (text, record, index) => {
			      if (record.createUser) {
			        return `${record.createUser.last_name}${record.createUser.first_name}`;
			      }
			      return "";
			    },
			  },
			  {
			    title: this.$t("formSalesman"),
			    dataIndex: "salesman",
			    key: "salesman",
			    customRender: (text, record, index) => {
			      if (record.salesman) {
			        return `${record.salesman.last_name}${record.salesman.first_name}`;
			      }
			      return "";
			    },
			  },
			  {
			    title: this.$t("formTotal"),
			    dataIndex: "grandTotal",
			    key: "grandTotal",
			    sorter: (a, b) => a.grandTotal - b.grandTotal,
			  },
			  {
			    title: this.$t("formState"),
			    dataIndex: "state",
			    key: "states",
			    onFilter: (value, record) => {
			      console.log(value, record);
			    },
			    scopedSlots: {
				  filterDropdown: 'stateDropdown',
			      filterIcon: "filterIcon",
				  customRender: "states",
				},
			  },
			  {
			    label: "操作",
			    title: "操作",
			    width: "100px",
			    customRender: (text, row) => {
			      return (
			          <div onClick={this.noop}>
			            <i
			                class="el-icon-document-copy"
			                style="margin-right:20px;font-size:20px"
			                onClick={(e) => this.handleCopy(e, row)}
			            />
			            <i
			                class="el-icon-delete"
			                style="font-size:20px"
			                onClick={(e) => this.handleDelete(e, row)}
			            />
			          </div>
			      );
			    },
			  },
			];
		}
        
      } else {
        if (this.type === 5) {
          return [
            {
              title: this.$t("formSn"),
              dataIndex: "sn",
              key: "sn",
            },
            {
              title: "图片",
              dataIndex: "img_url",
              scopedSlots: { customRender: "img_url" },
            },

            {
              title: "款式号",
              dataIndex: "body_number",
              key: "body_number",
            },
            {
              title: "单价",
              dataIndex: "profit_total",
              key: "profit_total",
            },
            {
              title: "数量",
              dataIndex: "order_number",
              key: "order_number",
            },
          ];
        } else {
          return [
            {
              title: this.$t("formSn"),
              dataIndex: "sn",
              key: "sn",
			  customRender: (text, row) => {
				if(row.sales_is_view == 0){
					return (
						<div>
						  {row.sn}
						  <span class="reddot"></span>
						</div>
					);
				}else{
					return (
						<div>
						  {row.sn}
						</div>
					);
				}
			  },
            },
            {
              title: this.$t("formCreateTime"),
              dataIndex: "create_time",
              key: "create_time",
              scopedSlots: {
                filterDropdown: "filterDropdown",
                filterIcon: "filterIcon",
                customRender: "customRender",
              },
              onFilter: (value, record) => {
                console.log(value, record);
              },
            },
            {
              title: this.$t("formCompany"),
              key: "customer",
              dataIndex: "customer",
              customRender: (text, record, index) => {
                if (record.customer) {
                  return `${record.customer.enterprise}`;
                }
                return "";
              },
            },
            {
              title: this.$t("formCreator"),
              key: "createUser",
              dataIndex: "createUser",
              customRender: (text, record, index) => {
                if (record.createUser) {
                  return `${record.createUser.last_name}${record.createUser.first_name}`;
                }
                return "";
              },
            },

            {
              title: this.$t("formSalesman"),
              dataIndex: "salesman",
              key: "salesman",
              customRender: (text, record, index) => {
                if (record.salesman) {
                  return `${record.salesman.last_name}${record.salesman.first_name}`;
                }
                return "";
              },
            },
            {
              title: this.$t("formTotal"),
              dataIndex: "grandTotal",
              key: "grandTotal",
              sorter: (a, b) => a.grandTotal - b.grandTotal,
            },
            {
              title: this.$t("formState"),
              dataIndex: "state",
              key: "states",
              onFilter: (value, record) => {
                console.log(value, record);
              },
              scopedSlots: { filterDropdown: 'stateDropdown',
                filterIcon: "filterIcon",
				customRender: "states",
			  },
            },
            {
              label: "操作",
              title: "操作",
              width: "100px",
              customRender: (text, row) => {
                return (
                    <div onClick={this.noop}>
                      <i
                          class="el-icon-document-copy"
                          style="margin-right:20px;font-size:20px"
                          onClick={(e) => this.handleCopy(e, row)}
                      />
                      <i
                          class="el-icon-delete"
                          style="font-size:20px"
                          onClick={(e) => this.handleDelete(e, row)}
                      />
                    </div>
                );
              },
            },
          ];
        }
      }
    },
  },
  created() {
    this.getList();
  },
  watch: {
    /* formType(val) {
      this.type = val;
      this.pagination.current = 1;
      this.search = {
        sn: "",
        state:"",
        date:''
      };
      this.show = false;
      this.getList();
    }, */
	info(val){
		this.user_info = val;
	}
  },
  updated() {
    // 表格斑马行显示
    this.renderStripe();
  },
  methods: {
    onChangeDate(date, dateString) {
      this.search.date = dateString;
      if (dateString[0].length === 0) {
        this.search.date = [];
      }
      this.searchSn();
    },
    // 表格斑马行显示
    renderStripe() {
      const table = document.getElementsByClassName("ant-table-row");
      if (table.length > 0) {
        const rowList = [...table];
        rowList.map((row, index) => {
          if (index % 2 !== 0) {
            row.style.backgroundColor = "#f9f9f9";
          } else {
            row.style.backgroundColor = "#ffffff";
          }
        });
      }
    },
    handleMenuClick(e){
      this.search.state=e.key
      this.pagination.current = 1;
      this.getList()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      this.search.date = selectedKeys;
      this.searchSn();
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    //编辑模板
    toEditTemplate() {
      this.$emit("toEditTemplate");
    },
    orderType(state) {
      if (state === 1 || state === 2 || state === 3) {
        return "enquiry";
      } else if (state === 4) {
        return "quotation";
      } else if (state === 5) {
        return "quotationed";
      } else if (state === 6) {
        return "order";
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.emit(record);
          },
        },
      };
    },
    noop(e) {
      if (e) {
        e.stopPropagation();
      }
    },
    handleDelete(e,row){
      let data={
        id:row.id
      }
      this.$api.deleteForm(data).then(res=>{
        this.getList()
      })
    },
    handleCopy(e,row){
      let data={
        id:row.id
      }
      this.$api.copyForm(data).then(res=>{
        this.getList()
      })
    },
    showSearch() {
      this.show = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    checkOutSide() {
      if (this.search.sn) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    //搜索
    searchSn() {
      if (this.search.sn || this.search.date) {
        this.pagination.current = 1;
      }
      this.getList();
    },
    //获取列表
    getList(type) {
	  if(type)this.type = type;
      let loading = this.openLoading();
      const pager = { ...this.pagination };
      let keyword = this.filterParams(this.search);
      let data = {
        type: this.type,
        page: pager.current,
        page_size: pager.pageSize,
        search: keyword,
      };
      if (this.type == 4) {
        //推荐单
        this.$api.getCustomerRecommendList(data).then((res) => {
          this.lists = res.data;
          pager.total = res.total;
          this.pagination = pager;
          loading.close();
        });
      } else if (this.type === 5) {
        data = {
          page: pager.current,
          page_size: pager.pageSize,
        };
        this.$api.getCBDList(data).then((res) => {
          this.lists = res.data;
          pager.total = res.total;
          this.pagination = pager;
          loading.close();
        });
      } else {
        //询盘、报价、订单
		data.search.state = this.type == 3?'6':'1,2,3,4,5';
        this.$api.getFormList(data).then((res) => {
          this.lists = res.data;
          pager.total = res.total;
          this.pagination = pager;
          loading.close();
        });
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getList();
    },
    //新建表单
    toAddForm() {
      this.$emit("createForm");
    },
    //切换视图
    handleViewChange({ key }) {
      this.listType = key;
    },
    emit(record) {
      this.$emit("click", record);
    },
  },
};
</script>

<style lang="scss">
.form-table-list {
  .ant-table-column-title {
    font-size: $base-font-size;
    font-weight: $head-font-weight;
  }
  .tool-bar a {
    font-size: $head-font-size;
  }
  .icon-table- {
    font-size: $head-font-size;
  }
}
.n-table {
  background-color: #fff;
  .form-tag {
    width: 100px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: $base-font-size;
    background-color: #f2f2f2;
    border-radius: 13px;
  }
  .enquiry {
    background-color: #c6e4fe !important;
  }
  .quotation {
    background-color: #c6e4fe !important;
  }
  .quotationed {
    background-color: rgb(99, 216, 187) !important;
  }
  .order {
    background-color: #63d8bb !important;
  }
}
.card-view {
  .form-card-list {
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 10px 100px;
  }
}
.tool-bar {
  padding: 15px 100px;
  font-size: $base-font-size;
  height: $tool-bar-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: content-box;
  // margin-bottom: 22px;
  a {
    font-size: $base-font-size;
    font-weight: 700;
    color: #3a3b3d;
  }
  .btn-group {
    display: flex;
    align-items: center;
    .btn-font {
      font-size: $base-font-size !important;
    }
    .form-search-box {
      display: flex;
      align-items: center;
      font-size: $head-font-size;
      color: #7f7e79;
      .icon-sousuo1 {
        font-size: $head-font-size;
        color: #c8c7c6;
        font-weight: 600;
        margin-right: 12px;
      }
      .search-input {
        border: none;

        background-color: #f6f7fa;
      }
    }
  }
}
.crm {
  .iconfont {
    font-size: $head-font-size;
  }
}
.reddot{
	  width: 5px;
	  height: 5px;
	  border-radius: 5px;
	  display: inline-block;
	  background: red;
	  vertical-align: top;
  }
</style>

<template>
  <div class="resource-list__wrap">
    <div class="canvas-list">
      <canvas-list
        :src="m.coverpic"
        :title="m.sn"
        v-for="(m, index) in data"
        :key="m.id"
        :data="m"
        @select="(t) => handleSelect(m, t)"
        @click="handleClick(m)"
        @edit="(selected) => handleEdit(m, selected)"
      ></canvas-list>
      <!-- <resource
	    class="w-98px"
	    :src="m.url || m.coverpic"
	    :title="m.name"
	    v-for="m in data"
	    @click="handleClick(m)"
	    :key="m.id"
	    @select="(t) => handleSelect(m, t)"
	  ></resource> -->
      <!-- <resource
        :src="m.coverpic"
        :title="m.name"
        v-for="(m,index) in data"
        :key="m.id"
        @select="(t) => handleSelect(m, t)"
		@click="handleClick(index)"
      ></resource> -->
    </div>
    <div id="materials" style="text-align: left"></div>
    <div class="resource-list-page">
      <a-pagination
        size="small"
        v-model="current"
        :total="total"
        show-less-items
        @change="handleChange"
        :defaultPageSize="12"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");

import resource from "@/components/Resource.vue";
import canvasList from "@/components/CanvasList.vue";
// import * as BABYLON from "@babylonjs/core/Legacy/legacy";
// import "@babylonjs/loaders/OBJ";
// import "@babylonjs/loaders/STL";
// import "@babylonjs/loaders/glTF";
// import "@babylonjs/materials/legacy/legacy";
import { ThreeDScene } from "@/utils/babylonjs/methods";
import {
  materialsConfig,
  materials,
  paramConfig,
} from "@/utils/babylonjs/meshData";
export default {
  components: {
    resource,
    canvasList,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    data: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selected: [],
      current: this.currentPage,
    };
  },
  mounted() {
    this.getMaterialList();
    //创建12个材质
    // materialsConfig.boxId = "materials";
    // let canvasArr = [];
    // if (!document.getElementById(materialsConfig.boxId).hasChildNodes()) {
    //   for (let m = 0; m < 12; m++) {
    //     let a = new ThreeDScene(materialsConfig);
    //     canvasArr[m] = a;
    //   }
    // }
    // let that = this;
    // this.global.MaterialScene = canvasArr;
    // this.getMaterialList().then((materials) => {
    //   let data = {
    //     ModelScene: this.global.ModelScene,
    //     MaterialScene: this.global.MaterialScene,
    //     materials: materials,
    //   };
    //   that.renderMaterials(data);
    // });
  },
  methods: {
    ...mapActions([
      "createCanvas",
      "renderMaterials",
      "getMaterialList",
      "changeMaterial",
    ]),
    ...mapMutations(["SET_CANVAS"]),
    handleSelect(val, t) {
      if (t) {
        this.selected.push(val);
      } else {
        this.selected = this.selected.filter((f) => f.id != val.id);
      }
      this.$emit("selected", this.selected);
    },
    handleChange(page, pageSize) {
      this.$emit("page-change", page, pageSize);
      let that = this;
      this.getMaterialList({ page: page, pageSize: pageSize }).then(
        (materials) => {
          let data = {
            ModelScene: this.global.ModelScene,
            MaterialScene: this.global.MaterialScene,
            materials: materials,
          };
          that.renderMaterials(data);
        }
      );
    },
    handleClick(m) {
      // this.global.MaterialScene[index].canvas.click();
      let data = {
        ModelScene: this.global.ModelScene,
        pbr: m,
      };
      this.global.ModelScene.addHistory(m);
      this.changeMaterial(data);
      this.global.ModelScene.writePart(m.sn);
    },
    handleEdit(m, selected) {
      this.$emit("edit", { material: m, status: selected });
    },
  },
};
</script>

<style lang="scss">
.canvas-list {
  // height: 74vh;
  width: 316px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  // row-gap: 8px;
  overflow: unset !important;
  padding-right: 4px;
  // position: absolute;
  .resource {
    // margin-right: 7px;
  }
  .resource:nth-child(4n) {
    margin-right: 0;
  }
}
.resource-list-page {
  margin: 0 auto;
  margin-top: 8px;
}

#materials canvas {
  padding: 0 2px;
  border-radius: 20px;
}
</style>

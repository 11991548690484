import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
const messages = {
  en: {
    ...{
      groupName: "group name",
      tabNameBoard: "Material",
      tabNameLibary: "Texture",
      tabNameLogo: "LOGO",
      tipDel: "Are you sure delete it",
      placement: "Placement",
      pleaseSelect: "Please Select",
      yes: "Yes",
      no: "No",
      ok: "Save",
      cancel: "Cancel",
      selectOneTexture: "Select one texture",
      createMaterial: "Create material",
      labelUploadTexture: "Upload Texture",
      upload: "Upload",
      labelTextureName: "Texture Name",
      labelTextureNumber: "Texture Number",
      labelLogoName: "Logo Name",
      labelTextureType: "Texture Type",
      pickTexure: "Pick Texure",
      normal: "Normal",
      occ: "Occ",
      bump: "Bump",
      metalName: "Metal Name",
      material: "Material",
      type: "Type",
      texture: "Texture",
      folder: "Folder",
      mine: "Mine",
      newFolder: "New Folder",
      yourfolder: "Your create folder",
      admin: "Admin",
      create: "Create",
      new: "New",
      newPage: "New Page",
      tel: "Tel",
      company: "Company",
      contact: "Contact",
      state: "state",
      city: "City",
      publicInquiry: "Public Inquiry",
      publicClue: "Public",
      publicCustomer: "Public Customer",
      recommend: "Recommend",
      copyok: "success",
      companyCustomer: "Company Customer",
      myCustomer: "My Customer",
      leadName: "Lead",
      enterprise: "Enterprise",
      newCustomer: "New Customer",
      address: "Address",
      mobile: "Mobile",
      site: "Site",
      tax: "Tax",
      tag: "Tag",
      lead: "Lead",
      receive: "Receive",
	  giveUp:"Return to the high seas",
      creator: "Creator",
      salesman: "Seller",
      lastMessage: "Last Message",
      country: "Country",
      state: "state",
      province: "Province",
      city: "City",
      tax: "Tax",
      tag: "Tag",
      customer: "Customer",
      pleaseSelect: "Please select",
      form: "Form",
      sn: "Sn",
      theme: "Theme",
      status: "Statu",
      copy: "Copy",
      customerName: "Customer Name",
      contentMaxLen: "Content max length is ",
      customer: "Customer",
      tableView: "Table View",
      cardView: "Card View",
      detailView: "Detail View",
      searchFolder: "Search Folder",
      assign: "Assign",
      search: "Search",
      sendByEmail: "Send By Email",
      send: "Send",
      state: "State",
      addAttachment: "Add Attachment",
      recommended: "Recommended",
      fromLib: "from lib",
      fromLocal: "from local",
      share: "share",
      record: "record",
      template: "template",
      clickAddText: "type text",
      return: "Return back",
      makeMine: "Private",
      delete: "Delete",
      export: "Export",
      subject: "Subject",
      to: "To",
      shareTo: "Share",
      copylink: "Copy Link",
      submit: "Submit",
      norepeat: "No Repeat Add",
      scanToWatch: "Scan To Watch",
      clickTosave: "Click To Save",
      typeContent: "Type the content",
      typeFileName: "Type the file name",
      typeFileDesc: "Type the file desc",
      typeCompanyName: "Type the company",
      typeFirstName: "Type first name",
      typeLastName: "Type last name",
      typeStreet: "Type the streen",
      typeTel: "Type the tel",
      typeCity: "Type the city",
      typeMobile: "Type the mobile",
      typeProvince: "Type the province",
      typeEmail: "Type the email",
      typeCountry: "Type the country",
      typeSite: "Type the site",
      typeTax: "Type the tax",
      typeTag: "Type the tag",
      selectAtLeaseOneProduct: "Select at lease one product",
      quickShop: "Quick Shop",
      edit: "Edit",
      scaleSize: "Scale Size",
      rotationSize: "Rotation Size",
      horizontalMovement: "Horizontal Movement",
      verticalMovement: "Vertical Movement",
      environmentIntensity: "Environment Intensity",
      adjustColor: "Adjust Color",
      logoSize: "Logo Size",
      logoAngle: "Logo Angle",
      logoHorizontal: "Horizontal movement",
      logoVertical: "Vertical movement",
      materialNumber: "Material Number",
      materialType: "Material Type",
      materialRoughness: "Material Number",
      colorTexture: "Color Texture",
      textureSize: "Texture Size",
      bumpTexture: "Bump Texture",
      textureColor: "Texture Color",
      placeHolderMaterialName: "Please enter Material Name",
      placeHolderMaterialNumber: "Please enter Material Number",
      placeHolderMaterialType: "Please Select the art",
      createMetalMaterial: "Create Metal Material",
      createClothMaterial: "Create Fabric Material",
      createLeatherMaterial: "Create Leather Material",
      createFurMaterial: "Create Fur Material",
      createGlassMaterial: "Create Glass Material",
      createSoleMaterial: "Create Sole Material",
      hairLength: "Hair Length",
      save: "Save",
      deleteSelectedMaterials: "Deletes the Selected Assembly Material",
      deleteAllMaterials: "Delete All Materials",
      deleteAllLogos: "Delete All Logos",
      alphaSwitch: "Turn on Transparency",
      alphaIntensity: "Transparency",
      handleMaterialsSearch: "Please enter a Keyword to Search",
      clearCoatSwitch: "Transparent Coating",
      editMaterial: "Edit Material",
      updateMaterial: "Update",
      addMaterial: "Add",
      searchName: "Name",
      operate: "Operate",
      myInquiry: "My Inquiry",
	  getClueSuccess:"Succeeded in getting clues",
      searchNumber: "Number",
      searchCompany: "Company",
      isPrivate: "Is Public",
      isSpecial: "Special Texture",
      preview3d: "Scan the Code to View the 3D Effect",
	  continueAdd:"Continue Add",
	  addColor:"Add Color",
	  modelName:"Model Name",
	  styleNumber:"Style Number",
	  styleColor:'Style Color',
	  size:'Size',
      //登录页
      loginMobile: "Mobile",
	   loginEmail:"Email",
      loginPassword: "Password",
      login: "Log in",
      noneLoginMobile: "Please enter mobile number",
      noneLoginPassword: "Please enter password",
      logOut: "Log out",
      noneAccount: "Don't have an account?",
      signUp: "Sign up",
	  signSuccess:"Register Success",
      isHaveAccount: "Have an account?",
      isHaveAccountText: "Log in",
      confirmPassword: "Confirm Password",
      backLogin: "Back To Login",
      loginCodePlaceholder: "Identifying code",
      newPasswordPlaceholder: "New password",
      getCode: "Get Code",
	  pushCode: "Sending",
      enterprise: "Enterprise",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
	  forgetPassword:"Forgot Password",
	  forgetPasswordBtn:'Confirm',
	  rememberPassword:"Remember Password",
	  noneLoginEmail: "Please enter your email address",
	  phoneError:"Mobile phone number format error",
	  getCodeSuccess:"Successfully obtained the verification code",
	  emailError:"Incorrect mailbox format",
	  noneConfirmPwd:"Please enter a confirmation password",
	  twoPwdError:"The passwords entered two times are inconsistent!",
	  noneCompanyName:"Please enter a company name",
	  selectCountry:"Select Country",
	  resetSuccess:"Reset succeeded",
      //  首页列表
      nowImage: "Image",
      design: "Design",
      threeD: "3D",
      goods: "Goods",
      nonePage: "No Data",
      isPublic: "Public Or Not",
      public: "Public",
      privacy: "Privacy",
      filterGoods: "Filter",
      createTime: "Create Time",
      searchGoods: "Search",
      //  详情页
      addProject: "Add to Project",
      toDesign: "Design",
      threedDesign: "3D-Design",
      sameBottom: "Same Sole",
      sameClass: "Same Style",
      addSuccess: "Success",
      noneProject: "There is no project group to add, please go to create",
      editDesign: "Edit",
      //  2D拉线图
      saveDesign: "Save",
      exportDesign: "Export",
      designList: "List",
      backDesign: "Back",
      uploadWarning: "The size of the uploaded file cannot exceed",
      part: "Part",
      textureName: "Material",
      pleaseSearchTexture: "Please search for material",
      textureRemark: "Material Remark",
      materialColor: "Material Color",
      patonePlaceholder: "Please enter Pantone",
      setting: "Setting",
      textureSn: "Material Number",
      textureSnPlaceholder: "Please enter material number",
      textureTechnology: "Materials Technology",
      textureTechnologyPlaceholder: "Please enter materials technology",
      removeNode: "Delete",
      nonePantone: "No match to the Pantone",
      noneSearchPantone: "The Pantone number was not found",
      position: "Position",
      num: "Num",
      materialName: "Material Name",
      technology: "Technology",
      basic: "Information",
      form: "Form",
      imageSize: "Image Size",
      partPlaceholder: "Please select parts",
      TypeTheSerachText: "Type the serach text",
      emptyData: "No Data",
      //  基础信息
      bomSn: "Bom Number",
      bomNmae: "Name",
      bomDate: "Delivery Date",
      bomStyle: "Style Number",
      bomColor: "Style Color",
      bomModel: "Model Name",
      bomHeader: "Shoe Last",
      bomClient: "Customer",
      bomSize: "Size",
      bomNum: "Count",
      bomCancel: "Cancel",
      bomSubmit: "Submit",
      editBasic: "Edit basic information",
      bomNmaePlaceholder: "Please enter a name",
      bomDatePlaceholder: "Please select the date of delivery",
      bomStylePlaceholder: "Please enter the style number",
      bomColorPlaceholder: "Please enter the style color",
      bomModelPlaceholder: "Please enter the mold name",
      bomHeaderPlaceholder: "Please enter the last name",
      bomClientPlaceholder: "Please enter customer name",
      bomSizePlaceholder: "Please enter the size",
      bomNumPlaceholder: "Please enter the count",
      //  创建弹窗
      addGoods: "Add Goods",
      editGoods: "Edit Goods",
	  addColor: "Add Color",
      goodsName: "Name",
      goodsType: "Type",
      pleaseChoose: "请选择",
      goodsPrice: "Price",
      goodsUnit: "Unit (yuan)",
      goodsInform: "Introduction",
      goodsDesc: "Describe",
      goodsMainImage: "The Main Image",
      goodsOtherImage: "The Other Image",
      clickUpload: "Upload",
      goodsNamePlaceholder: "Please enter a name",
      goodsTypePlaceholder: "Please choose type",
      goodsInformPlaceholder: "Please enter introduction",
      goodsSizePlaceholder: "Please enter size",
      mostDesc: "A maximum of eight description items can be added",
      pleaseMainImage: "Please select the main image",
      pleaseThreeD: "Please select the 3D file",
      pleaseGoodsDesc: "Please fill in the product description",
	  selectedType: "Please select new type",
	  searchSameType: "Search for the same item",
	  confirmSeleted: "Confirm selection",
      //  预览
      remark: "Remark",
      image: "Image",
      color: "Color",
      exportPdf: "Export PDF",
      back: "Back",
      // 聊天系统
      meeting: "Message",
      meetingPicmsg: "[pic]",
      meetingPicECL: "[enclosure]",
      meetingCreate: "Create",
      meetingModify: "Modify",
      meetingOut: "Out",
      meetingName: "MeetingName",
      meetingNametip: "Please enter Meeting Name",
      meetingIntroduction: "Meeting Introduction",
      meetingIntroductiontip: "Please enter Meeting Introduction",
      meetingNotice: "Meeting Notice",
      meetingNoticetip: "Please enter Meeting Notice",
      meetingAddMember: "Add",
      meetingDelMember: "Del",
      meetingOuttishi: "Do you want to quit the Meeting?",
      messageTiShi: "Tip",
      messageSearch: "Search",
      meetingClose: "Out",
      meetingClosetishi: "Do you want to close the Meeting?",
      meetingClosetishi2: "The Meeting has been close!",
      //  表单
      recommendForm: "Recommend",
      quotationForm: "Quotation",
      enquiryForm: "Enquiry",
      orderForm: "Order",
      newEnquiry: "New Enquiry",
      newQuotation: "New Quotation",
      editModule: "Edit Template",
      submitModule: "Submit Template",
      exportForm: "Export",
      toEnquiry: "Enquiry",
      toOrder: "Order",
      intoQuotation: "Turn into quotation",
      toQuotation: "Quotation",
      sent: "Sent",
      saveForm: "Save",
      closeForm: "已关闭",
      unEnquiry: "未询盘",
      isSendEnquiry: "已发送询盘",
      enquiryed: "已询盘",
      unQuotation: "未报价",
      isQuotation: "已报价",
      isConfirmQuotation: "已确认报价",
      isPay: "已支付",
      isEnd: "已完成",
      searchForm: "搜索",
      formSn: "单号",
      formCreateTime: "创建时间",
      formCreator: "创建人",
      formSalesman: "跟进人",
      formTotal: "合计",
      formState: "状态",
      formCustomer: "客户",
      addBtn: "Add",
      CBD: "CBD",
      newCBD: "新建成本单",
	  tipBack:'Back Off',
	  tipForward:'Forward',
	  tipColor:'Smart Color Matching',
	  tipChange:'Change a Batch',
	  tipRecordVideo:'Record Video',
	  tipStopVideo:'Stop Recording',
	  tipEnterVoice:'Please Enter Voice Content',
	  tipEnterAnimation:'Please Enter the Animation ID',
	  buttonCancel:"Cancel",
	  buttonSave:'Save',
	  //导航栏
	  tab_1:'Home',
	  tab_2:'Product',
	  tab_3:'Workstation',
	  tab_4:'CRM',
	  tab_5:'List',
	  template: "template",
	  basicLibrary:"Basic Bibrary",
	  materialSupplier:"Supplier",
	  customPrivate:"Designated Customers",
	  custormSelect:"Please select a customer",
	  appoint:"Designated Customers"
    },
    ...elementEnLocale,
  },
  zh: {
    ...{
      groupName: "群聊名称",
      TypeTheSerachText: "请输入搜索内容",
      normal: "法线",
      occ: "occ",
      bump: "bump",
      quickShop: "Quick Shop",
      edit: "编辑",
      pleaseSelect: "请选择业务员",
      labelTextureName: "纹理名称",
      labelTextureNumber: "纹理编号",
      labelLogoName: "Logo名称",
      labelTextureType: "纹理类型",
      pickTexure: "选择纹理",
      createMaterial: "新建材质",
      upload: "上传图片",
      tabNameBoard: "素材",
      tabNameLibary: "纹理",
      tabNameLogo: "LOGO",
      tipDel: "是否确定删除",
      placement: "使用部位",
      pleaseSelect: "请选择",
      selectOneTexture: "选择一张纹理贴图",
      yes: "确定",
      no: "取消",
      ok: "确定",
      norepeat: "同一页不能添加相同产品",	
      receive: "领取",
	  giveUp:"放回公海",
      company: "企业名称",
      newCustomer: "创建客户",
      contact: "联系人",
      mobile: "手机",
      creator: "创建人",
      salesman: "跟进人",
      country: "国家",
      state: "省份",
      city: "城市",
      tax: "税号",
      state: "省份",
      newPage: "添加一页",
      province: "省份",
      fromLocal: "本地上传",
      share: "分享",
      tag: "Tag",
      copy: "复制",
      search: "搜索",
      shareTo: "分享",
      copylink: "复制链接",
      submit: "确认",
      scanToWatch: "扫一扫，即时查看",
      clickTosave: "鼠标点击保存",
      contentMaxLen: "内容最大长度为",
      copyok: "复制成功",
      sendByEmail: "发送邮件",
      send: "发送",
      addAttachment: "添加附件",
      publicInquiry: "公海询盘单",
      publicClue: "公海线索",
      clueName: "线索名称",
      myInquiry: "我的线索",
	  getClueSuccess:"领取线索成功",
      publicCustomer: "公海客户",
      companyCustomer: "公司客户",
      myCustomer: "我的客户",
      selectAtLeaseOneProduct: "请至少添加一个产品",
      cancel: "取消",
      metalName: "名称",
      material: "材料",
      type: "类型",
      texture: "纹理",
      folder: "文件夹",
      mine: "我的",
      customer: "客户",
      newFolder: "新建文件夹",
      yourfolder: "你创建的文件夹",
      admin: "管理员",
      customerName: "客户名称",
      status: "状态",
      new: "新建",
      tel: "电话",
      mobile: "手机",
      site: "网站",
      tax: "税号",
      tag: "标签",
      subject: "主题",
      to: "收件人",
      clickAddText: "点击添加文本",
      address: "地址",
      lead: "线索",
      leadName: "线索名称",
      customer: "客户",
      form: "表单",
      sn: "单号",
      theme: "主题",
      delete: "删除",
      record: "记录",
      export: "导出",
      lastMessage: "最近联系时间",
      assign: "分配给业务员",
      return: "放回公海客户",
      makeMine: "转为我的客户",
      createView: "创建视图",
      tableView: "表格视图",
      cardView: "卡片视图",
      detailView: "详细视图",
      create: "新建",
      recommend: "推荐",
      unrecommend: "未推荐",
      fromLib: "从库中选择",
      state: "状态",
      submit: "确认",
      searchFolder: "搜索文件夹",
      typeFileName: "输入文件夹名称",
      typeFileDesc: "请输入文件描述",
      typeContent: "请输入内容",
      typeCompanyName: "请输入企业名称",
      typeFirstName: "请输入名字",
      typeLastName: "请输入姓氏",
      typeStreet: "请输入街道",
      typeTel: "请输入电话",
      typeCity: "请输入城市",
      typeMobile: "请输入手机",
      typeProvince: "请输入省份",
      typeEmail: "请输入邮箱",
      typeCountry: "请输入国家",
      typeSite: "请输入网站",
      typeTax: "请输入税号",
      typeTag: "请输入标签",
      scaleSize: "缩放大小",
      rotationSize: "旋转角度",
      horizontalMovement: "水平移动",
      verticalMovement: "垂直移动",
      environmentIntensity: "亮度",
      adjustColor: "调节颜色",
      logoSize: "Logo 大小",
      logoAngle: "Logo 角度",
      logoHorizontal: "Logo 水平移动",
      logoVertical: "Logo 垂直移动",
      materialNumber: "素材编号",
      materialType: "素材类型",
      materialRoughness: "粗糙度",
      colorTexture: "颜色纹理",
      textureSize: "纹理大小",
      bumpTexture: "凹凸纹理",
      textureColor: "素材颜色",
      placeHolderMaterialName: "请输入材质名称",
      placeHolderMaterialNumber: "请输入材质编号",
      placeHolderMaterialStype: "请选择所属部件",
      createMetalMaterial: "创建金属",
      createClothMaterial: "创建布料",
      createLeatherMaterial: "创建皮革",
      createFurMaterial: "创建毛发",
      createGlassMaterial: "创建玻璃",
      createSoleMaterial: "创建大底",
      hairLength: "毛发长度",
      save: "保存",
      operate: "操作",
      deleteSelectedMaterials: "删除选择部件素材",
      deleteAllMaterials: "删除所有素材",
      deleteAllLogos: "删除所有Logo",
      alphaSwitch: "开启透明度",
      alphaIntensity: "透明度",
      handleMaterialsSearch: "请输入关键字进行搜索",
      clearCoatSwitch: "透明涂层",
      editMaterial: "编辑材料",
      updateMaterial: "更新",
      addMaterial: "添加",
      searchName: "名称",
      searchNumber: "编号",
      searchCompany: "厂商",
      isPrivate: "是否公开",
      isSpecial: "特殊纹理",
      preview3d: "手机扫码查看3D效果",
	  continueAdd:"继续添加",
	  addColor:"添加颜色",
	  modelName:"模具名称",
	  styleNumber:"款式编号",
	  styleColor:'款式颜色',
	  size:'尺 码',
      //登录页
      loginMobile: "登录手机号",
	  loginEmail:"登录邮箱",
      loginPassword: "登录密码",
      login: "登录",
      noneLoginMobile: "请输入手机号",
      noneLoginPassword: "请输入密码",
      logOut: "退出登录",
      noneAccount: "没有账号?",
      signUp: "注册",
	  signSuccess:"注册成功",
      isHaveAccount: "已有账号？",
      isHaveAccountText: "登录",
      confirmPassword: "确认密码",
      backLogin: "返回登录",
      loginCodePlaceholder: "请输入验证码",
      newPasswordPlaceholder: "请输入新密码",
      getCode: "获取验证码",
	  pushCode:"发送中",
      enterprise: "企业名称",
      firstName: "名字",
      lastName: "姓氏",
      email: "邮箱",
	  forgetPassword:"忘记密码",
	  forgetPasswordBtn:'确认',
	  rememberPassword:"记住密码",
	  noneLoginEmail: "请输入邮箱",
	  phoneError:"手机号格式错误",
	  getCodeSuccess:"获取验证码成功",
	  emailError:"邮箱格式有误",
	  noneConfirmPwd:"请输入确认密码",
	  twoPwdError:"两次输入的密码不一致!",
	  noneCompanyName:"请输入公司名称",
	  selectCountry:"选择国家",
	  resetSuccess:"重置成功",
      //导航栏
      profile: "公司简介",
      deliverable: "产品",
      workshop: "工作空间",
      lists: "表单",
      //  首页列表
      nowImage: "当前图片",
      design: "设计图",
      threeD: "3D模型",
      goods: "实物商品",
      nonePage: "暂无数据",
      isPublic: "是否公开",
      public: "公开",
      privacy: "私密",
      filterGoods: "筛选",
      createTime: "创建时间",
      searchGoods: "搜索",
      //  详情页
      addProject: "添加项目",
      toDesign: "设计",
      threedDesign: "3D设计",
      sameBottom: "同底",
      sameClass: "同款",
      addSuccess: "添加成功",
      noneProject: "暂无可添加的项目组，请前往创建",
      editDesign: "编辑",
      //  2D拉线图
      saveDesign: "保存",
      exportDesign: "导出",
      designList: "列表",
      backDesign: "返回",
      uploadWarning: "上传文件大小不能超过",
      part: "部件",
      textureName: "素材",
      pleaseSearchTexture: "请搜索素材",
      textureRemark: "素材备注",
      materialColor: "素材颜色",
      patonePlaceholder: "请输入潘通号",
      setting: "设置",
      textureSn: "素材编号",
      textureSnPlaceholder: "请输入素材编号",
      textureTechnology: "材料工艺",
      textureTechnologyPlaceholder: "请输入材料工艺",
      removeNode: "删除节点",
      nonePantone: "未匹配到潘通号",
      noneSearchPantone: "未查找到该潘通号",
      position: "位置",
      num: "序号",
      recommended: "已推荐",
      materialName: "素材名称",
      technology: "工艺",
      basic: "基础信息",
      form: "表单信息",
      imageSize: "图片大小",
      partPlaceholder: "请选择部件",
      emptyData: "暂无数据",
      //  基础信息
      bomSn: "单号",
      bomNmae: "名称",
      bomDate: "交样日期",
      bomStyle: "款式编号",
      bomColor: "款式颜色",
      bomModel: "模具名称",
      bomHeader: "楦头名称",
      bomClient: "客户名称",
      bomSize: "尺码",
      bomNum: "数量",
      bomCancel: "取消",
      bomSubmit: "确认",
      editBasic: "编辑基础信息",
      bomNmaePlaceholder: "请输入名称",
      bomDatePlaceholder: "请选择交样日期",
      bomStylePlaceholder: "请输入款式编号",
      bomColorPlaceholder: "请输入款式颜色",
      bomModelPlaceholder: "请输入模具名称",
      bomHeaderPlaceholder: "请输入楦头名称",
      bomClientPlaceholder: "请输入客户名称",
      bomSizePlaceholder: "请输入尺码",
      bomNumPlaceholder: "请输入数量",
      //  创建弹窗
      addGoods: "新增商品",
      editGoods: "编辑商品",
	  addColor: "新增颜色",
      goodsName: "名称",
      goodsType: "类型",
      pleaseChoose: "请选择",
      goodsPrice: "价格",
      goodsUnit: "单位（元）",
      goodsInform: "简介",
      goodsDesc: "描述",
      goodsMainImage: "主图",
      goodsOtherImage: "其他图片",
      clickUpload: "点击上传",
      goodsNamePlaceholder: "请输入名称",
      goodsTypePlaceholder: "请选择类型",
      goodsInformPlaceholder: "请输入简介",
      goodsSizePlaceholder: "请输入尺码",
      mostDesc: "描述最多添加8条",
      pleaseMainImage: "请选择主图",
      pleaseThreeD: "请选择3D文件",
      pleaseGoodsDesc: "请填写商品描述",
	  selectedType: "请选择新增类型",
	  searchSameType: "搜索同款",
	  confirmSeleted: "确认选择",
      //  预览
      remark: "业务备注",
      image: "图样",
      color: "颜色",
      exportPdf: "导出PDF",
      back: "返回",
      // 聊天系统
      meeting: "信息",
      meetingPicmsg: "[图片]",
      meetingPicECL: "[附件]",
      meetingCreate: "创建会议",
      meetingModify: "修改信息",
      meetingOut: "退出会议",
      meetingName: "会议名称",
      meetingNametip: "请输入会议名称",
      meetingIntroduction: "会议简介",
      meetingIntroductiontip: "请输入会议简介",
      meetingNotice: "会议公告",
      meetingNoticetip: "请输入会议公告",
      meetingAddMember: "添加",
      meetingDelMember: "删除",
      meetingOuttishi: "此操作将永久退出会议, 是否继续?",
      messageTiShi: "提示",
      messageSearch: "搜索",
      meetingClose: "解散会议",
      meetingClosetishi: "操作将永久解散会议, 是否继续?",
      meetingClosetishi2: "解散会议已经解散!",
      //  表单
      recommendForm: "推荐单",
      quotationForm: "报价单",
      enquiryForm: "询盘单",
      orderForm: "订单",
      newEnquiry: "新建询盘单",
      newQuotation: "新建报价单",
      editModule: "编辑模板",
      submitModule: "提交模板",
      exportForm: "导出",
      toEnquiry: "询盘",
      toOrder: "下单",
      intoQuotation: "进入报价",
      toQuotation: "报价",
      sent: "已发送",
      saveForm: "保存",
      closeForm: "已关闭",
      unEnquiry: "未询盘",
      isSendEnquiry: "已发送询盘",
      enquiryed: "已询盘",
      unQuotation: "未报价",
      isQuotation: "已报价",
      isConfirmQuotation: "已确认报价",
      isPay: "已支付",
      isEnd: "已完成",
      searchForm: "搜索",
      formSn: "单号",
      formCreateTime: "创建时间",
      formCreator: "创建人",
      formSalesman: "跟进人",
      formTotal: "合计",
      formState: "状态",
      formCustomer: "客户",
      formCompany: "企业名称",
      addBtn: "新增",
      CBD: "CBD",
      newCBD: "新建成本单",
	  tipBack:'返回',
	  tipForward:'前进',
	  tipColor:'智能配色',
	  tipChange:'换一批',
	  tipRecordVideo:'录制视频',
	  tipStopVideo:'停止录制',
	  tipEnterVoice:'请输入语音内容',
	  tipEnterAnimation:'请输入动画ID',
	  buttonCancel:"取消",
	  buttonSave:'保存',
	  //导航栏
	  tab_1:'首页',
	  tab_2:'产品',
	  tab_3:'工作站',
	  tab_4:'客户管理',
	  tab_5:'表单',
      template: "template",
	  basicLibrary:"基础库",
	  materialSupplier:"材料供应商",
	  customPrivate:"指定客户",
	  custormSelect:"请选择客户",
	  appoint:"指定客户"
	  
    },
    ...elementZhLocale,
  },
};

export default messages;

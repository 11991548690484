<template>
  <div class="material-table">
    <div>
      <div class="list-table-header">
        <div class="product-filter-left-box">
          <div
            class="filter-same-btn pointer-btn"
            v-for="(val, key) in currentList"
            :class="[current === key + 1 ? 'filter-same-active' : '']"
            @click="changeCurrent(key)"
            :key="key"
          >
            {{ val }}
          </div>
        </div>
        <div class="product-filter-right-box">
          <div
            class="list-search-btn"
            @click.stop="showSearchBox = !showSearchBox"
            v-if="!isInAiSearch"
          >
            {{ $t("filterGoods") }}
          </div>
          <div class="list-search">
            <el-input
              class="list-search-input"
              v-model="searchForm.name"
              @keyup.enter.native="search"
            ></el-input>
            <el-upload
              action=""
              accept=".jpg,.jpeg,.png,.JPG,.PNG"
              :show-file-list="false"
              ref="upload"
              :on-change="handleChange"
              :http-request="toSearchImage"
            >
              <i class="iconfont icon-zhaoxiangji image-search-icon"></i>
            </el-upload>
            <i
              class="iconfont icon-fangdajing list-search-icon"
              @click.stop="search"
            ></i>
          </div>
          <div class="add-product-btn pointer-btn" style="position: unset;" v-if="userRole === 'company'" @click="showTypeDialog">
            <i class="iconfont icon-ic_add add-product-icon"></i>
            <span>{{$t('addBtn')}}</span>
          </div>
        </div>

        <div class="header-search-hide-box" v-if="showSearchBox" @click.stop="">
          <el-card
            class="header-search-hide"
            ref="container"
            style="width: 480px"
          >
            <div class="filter-item">
              <div class="filter-item-title">{{ $t("createTime") }}</div>
              <el-date-picker
                style="width: 340px"
                v-model="searchForm.date"
                size="small"
                type="daterange"
                range-separator="-"
                start-placeholder="Start"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                end-placeholder="End"
              >
              </el-date-picker>
            </div>
            <el-button
              size="small"
              type="primary"
              class="btn"
              @click="search()"
              >{{ $t("searchGoods") }}</el-button
            >
          </el-card>
        </div>
      </div>
      <div class="ai-box" v-if="imageUrl">
        <div class="ai-image-box">
          <span>{{ $t("nowImage") }}：</span>
          <img class="ai-image" :src="imageUrl" alt="" />
          <i class="iconfont icon-icon-test ai-close" @click="clearAiImage"></i>
        </div>
      </div>
      <div
        class="search-box"
        v-if="searchForm.date.length > 0 && !isInAiSearch"
      >
        <div class="search-item">
          <span
            >Create time：{{ searchForm.date[0] }}-{{
              searchForm.date[1]
            }}</span
          >
          <i class="iconfont icon-close search-close" @click="closeSearch"></i>
        </div>
      </div>
      <div v-if="tableData.length > 0">
        <div class="design-ul" v-if="current === 1">
          <designView
            v-for="val in tableData"
            :key="val.id"
            :itemData="val"
            @toShowGoodsTree="toShowGoodsTree"
          ></designView>

          <!--  占位元素  -->
          <div class="data-item temp" v-for="i in 10" :key="i + 'key'"></div>
        </div>
        <div class="design-ul" v-else>
          <designSameCard
            v-for="val in tableData"
            :key="val.id"
            :current="current"
            :itemData="val"
            @toShowGoodsTree="toShowGoodsTree"
          ></designSameCard>
          <!--  占位元素  -->
          <div class="data-item temp" v-for="i in 10" :key="i + 'key'"></div>
        </div>
      </div>
    </div>
    <div v-if="tableData.length == 0" class="empty-box">
      <emptyPage></emptyPage>
    </div>
    <editProductDialog
      v-if="dialogCreateVisible"
      @createProduct="createProduct"
      @closeModal="closeModal"
      :itemData="productDetail"
      :form="productDetail"
    ></editProductDialog>
    <goodsTreeDialog
      v-if="isShowTreeDialog"
      :goodsData="goodsTreeData"
      @closeTreeModal="closeTreeModal"
    ></goodsTreeDialog>
    <addProductTypeDialog :visible.sync="dialogCreateType" @toAddProduct="toAddProduct" @toAddSameProduct="toAddSameProduct"></addProductTypeDialog>
    <chooseDesignDialog  :visible.sync="isShowSameProductDialog" @toSubmitProduct="toSubmitProduct"></chooseDesignDialog>
	<successDialog :visible.sync="isSuccessDialog" :itemData="productDetail" @continueAdd="continueAdd" @goProductDetail="goProductDetail"></successDialog>
  </div>
</template>

<script>
import designView from "../designView/designView";
import designSameCard from "@/components/designView/designSameCard";
import goodsTreeDialog from "@/components/goodsTreeDialog/goodsTreeDialog";
import emptyPage from "../emptyPage";
import addProductTypeDialog from "@/components/editProductDialog/addProductTypeDialog";
import editProductDialog from "@/components/editProductDialog/editProductDialog";
import chooseDesignDialog from "@/components/editProductDialog/chooseDesignDialog";
import successDialog from "@/components/editProductDialog/successDialog";
import {mapState} from "vuex";

export default {
  components: {
    designView,
    emptyPage,
    designSameCard,
    editProductDialog,
    goodsTreeDialog,
    addProductTypeDialog,
    chooseDesignDialog,
	successDialog
  },
  name: "materialTable",
  props: {
    tableData: {
      type: Array,
    },
    tableHeight: {
      type: Number,
      default: 500,
    },
    isInAiSearch: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchForm: {
        name: "",
        date: [],
      },
      typeList: [
        {
          value: 1,
          label: "设计图",
        },
        {
          value: 2,
          label: "3D",
        },
        {
          value: 3,
          label: "实物图",
        },
      ],
      list_type: 1,
      imageUrl: "",
      showSearchBox: false,
      currentList: ["全部", "同底", "同款"],
      current: 1,
      dialogCreateVisible: false,
      isShowTreeDialog: false,
      goodsTreeData: {},
      dialogCreateType:false,
      isShowSameProductDialog:false,
      productDetail:{},
	  isSuccessDialog:false
    };
  },
  created() {
    let that = this;
    document.addEventListener("click", (e) => {
      if (!that.showSearchBox) return;
      if (!that.$el.contains(e.target)) {
        that.showSearchBox = false;
      }
    });
  },
  computed:{
    ...mapState("jiyang/user",["info", "userRole"]),
  },
  methods: {
    //创建
    createProduct(data) {
      this.$emit("createProduct");
	  this.dialogCreateVisible = false;
	  this.isSuccessDialog = true;
	  this.productDetail=data
      /* if (type === 1) {
        this.closeModal();
      } */
    },
	//继续添加
	continueAdd(){
		this.dialogCreateVisible = true;
		this.isSuccessDialog = false;
		this.productDetail.color='';
		this.productDetail.file_info.main_file.img_url = '';
		this.productDetail.is_public = 1;
	},
	//查看详情
	goProductDetail(){
		this.isSuccessDialog = false;
		this.$router.push({
		  name: "productDetail",
		  query: {
		    id: this.productDetail.id,
		    type: this.productDetail.type,
		  },
		});
	},
    //提交选中商品
    toSubmitProduct(data){
      this.productDetail=data
      this.dialogCreateVisible=true
    },
    //同款选择
    toAddSameProduct(){
      this.isShowSameProductDialog=true
    },
    //关闭弹窗
    closeModal() {
      this.dialogCreateVisible = false;
	  this.productDetail = {};
    },
    //关闭弹窗
    closeTreeModal() {
      this.isShowTreeDialog = false;
    },
    //  切换列表形式
    changeView(type) {
      this.list_type = type;
    },
    //切换同底同款
    changeCurrent(key) {
      this.current = key + 1;
    },
    //显示选择新增类型弹窗
    showTypeDialog(){
      this.dialogCreateType=true
    },
    //显示新增商品弹窗
    toAddProduct() {
      this.dialogCreateVisible = true;
    },
    //搜索
    search() {
      let data = this.filterParams(this.searchForm);
      this.showSearchBox = false;
      this.$emit("searchDesign", data);
    },
    toShowGoodsTree(data) {
      this.goodsTreeData = data.data;
      this.isShowTreeDialog = true;
    },
    checkImage(file) {
      return new Promise((resolve) => {
        let check = true;
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = (e) => {
          let index = e.target.result.indexOf("RIFF", 0, 10);
          if (index != -1) {
            check = false;
          }
          resolve(check);
        };
      });
    },
    showFilter() {
      this.showSearchBox = true;
    },
    //关闭搜索
    closeSearch() {
      this.searchForm.date = "";
      let data = this.filterParams(this.searchForm);
      this.showSearchBox = false;
      this.$emit("searchDesign", data);
    },
    doNothing() {},
    //AI搜索图片
    async toSearchImage(file) {
      let that = this;
      let files = file.file;
      let check = await this.checkImage(files);
      if (!check) {
        this.$message.error("上传图片信息有误");
        return false;
      }
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.imageUrl = e.target.result; // 将图片路径赋值给src
      };
      reader.readAsDataURL(files);
      this.$emit("toSearchImage", files);
    },
    handleChange() {},
    //移除图片
    clearAiImage() {
      this.imageUrl = "";
      this.$emit("toSearchImage", "");
    },
    //跳转
    toEdit(data) {
      if (data.type == 2) {
        this.$router.push({
          name: "workspace",
          query: {
            id: data.id,
          },
        });
      } else {
        this.$router.push({
          name: "draw",
          query: {
            type: 2,
            id: data.id,
          },
        });
      }
    },
    //切换页面
    currentChange(arg) {
      this.$emit("currentChange", arg);
    },
    //    绑定3D
    bindModel(id) {
      this.$emit("bindModel", id);
    },
  },
};
</script>

<style lang="scss">
.pointer-btn {
  cursor: pointer;
}
.list-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  position: relative;
  padding: 0 10px;
  .product-filter-left-box {
    display: flex;
    align-items: center;
    .filter-same-btn {
      width: 82px;
      height: 32px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border: 1px solid $base-config-color;
      margin-right: 14px;
      background-color: #fff;
    }
    .filter-same-active {
      background-color: $base-config-color !important;
      color: #fff;
    }
  }
  .product-filter-right-box {
    display: flex;
    align-items: center;

    .add-product-btn {
      width: 82px;
      height: 32px;
      background-color: $base-config-color;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 2px;
      margin-left: 10px;
      .add-product-icon {
        font-size: 14px;
        margin-right: 6px;
      }
    }
  }
  .list-search {
    height: 32px;
    position: relative;
    .el-input__inner {
      width: 294px;
      height: 32px;
      padding: 0 34px;
    }
    .list-search-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      font-size: 18px;
      z-index: 1;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .image-search-icon {
      position: absolute;
      left: 12px;
      top: 50%;
      font-size: 18px;
      transform: translateY(-50%);
    }
  }
  .list-search-btn {
    margin-right: 10px;
    border-radius: 2px;
    height: 32px;
    width: 74px;
    border: 1px solid #dcdfe6;
    color: #333;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    cursor: pointer;
    background-color: #fff;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .change-icon-box {
    display: flex;
    align-items: center;
    .change-img-icon {
      color: #999;
      font-size: 18px;
      cursor: pointer;
    }
    .mr-10 {
      margin-right: 14px;
    }
    .change-list-icon {
      color: #999;
      font-size: 24px;
      cursor: pointer;
    }
    .change-active {
      color: #333;
      font-weight: 500;
    }
  }
}
.empty-box {
  margin-top: 200px;
}
.ai-box {
  text-align: left;
  padding: 0 10px;
}
.ai-image-box {
  text-align: left;

  display: inline-block;
  position: relative;
  padding-right: 20px;
  .ai-image {
    height: 120px;
    width: auto;
  }
  .ai-close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    cursor: pointer;
  }
}
.search-box {
  text-align: left;
  padding: 0 10px;
  margin-bottom: 12px;
  .search-item {
    position: relative;
    height: 28px;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    padding: 0 30px 0 10px;
    background-color: $base-config-color;
    color: #fff;
    border-radius: 16px;
    font-size: 12px;
    .search-close {
      color: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.material-table {
  height: 100%;
}
.check-btn {
  color: #4598b9;
  text-decoration: underline;
  cursor: pointer;
}
.design-ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .data-item {
    width: 212px;
  }
  &.temp {
    height: 0;
    margin-bottom: 0;
    border: none;
    padding: 0;
    box-sizing: content-box;
  }
}
.header-search-hide-box {
  position: absolute;
  right: 230px;
  top: 42px;
  z-index: 100;
  .header-search-hide {
    box-sizing: border-box;

    background-color: #fff;
    border-radius: 8px;

    text-align: right;
    .el-form-item {
      display: flex;
      .el-form-item__content {
        flex: 1;
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100% !important;
        }
      }
    }
    .filter-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .filter-item-title {
        margin-right: 10px;
      }
    }
    .hide-box-btn {
      text-align: right;
    }
  }
}
</style>

<template>
    <div class="empty-page">
        <img class="empty-image" src="@/static/icon/empty-none.png" alt="">
        <div class="empty-text">
            {{emptyText}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "emptyPage",
        props:{
            text:{
                type:String
            }
        },
        data(){
            return{
                emptyText:this.$t("nonePage")
            }
        },
        created() {
            if(this.text){
                this.emptyText=this.text
            }

        }
    }
</script>

<style lang="scss">
    .empty-page{
        margin: 0 auto;
        text-align: center;
        .empty-image{
            width: 200px;
            height: 200px;
        }
        .empty-text{
            font-size: 20px;
            font-weight: 500;
            color: #333;
            margin-top: 30px;
        }
    }
</style>

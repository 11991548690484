<template>
  <div class="cbd-part">
    <div class="cbd-part-title">{{ itemData.title }}</div>
    <el-table border :data="itemData.list">
      <el-table-column
        v-for="(val, key) in propList"
        :prop="val.prop"
        :label="val.label"
        :key="key"
        align="center"
        :width="val.width ? val.width : ''"
      >
        <template slot-scope="scope">
          <div>
            <span v-if="val.prop === 'cost'">{{ scope.row[val.prop] }}</span>
			<el-select
				v-else-if="val.prop === 'texture_name'"
				size="mini"
				style="width: 100%;"
			    v-model="scope.row[val.prop]"
				@change="handleChange(val.prop, scope.row, scope.$index)"
			    filterable
			    remote
			    reserve-keyword
			    placeholder=""
			    :remote-method="remoteMethod"
			    :loading="loading">
			    <el-option
			      v-for="item in options"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			  </el-select>
            <el-input
              v-else
              v-model="scope.row[val.prop]"
              @blur="partAmount(val.prop, scope.row, scope.$index)"
			  @keyup.enter.native="partAmount(val.prop, scope.row, scope.$index)"
			  @change="partAmount(val.prop, scope.row, scope.$index)"
              :type="val.type"
              autosize
              size="mini"
            ></el-input>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="part-bottom">
      <div class="part-add-btn" @click="addPart">
        <i class="iconfont icon-ic_add"></i>
      </div>
      <div class="part-total">
        <div class="total-text">小计：</div>
        <div class="count">￥{{ itemData.total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cbdPart",
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
	  options: [],
	  loading: false,
	  list:[],
      propList: [
        { prop: "part", label: "部件名称",type:"text", width: 120 },
        { prop: "texture_name", label: "材料名称",type:"text", width: 250 },
        { prop: "specification", label: "规格",type:"text", width: 120 },
        { prop: "net_usage", label: "净用量",type:"number", width: 120 },
        { prop: "loss", label: "中间码系数耗损",type:"text", width: 120 },
        { prop: "price", label: "材料单价(含税)",type:"number", width: 120 },
        { prop: "cost", label: "成本/双",type:"text", width: 120 },
        { prop: "remark", label: "备注",type:"textarea" },
      ],
    };
  },
  created(){
	  console.log(this.itemData);
  },
  methods: {
    //计算总数
    partAmount(prop, row, key) {
      let arr = ['texture_name',"net_usage", "loss", "price"];
      if (arr.indexOf(prop) < 0) return;
      let net_usage = this.checkNumber(row.net_usage);
	  let loss = this.toPoint(row.loss);
      let price = this.checkNumber(row.price);
      if (row.net_usage && row.price) {
        this.$emit("setAmount", {
          //amount: Math.floor(net_usage * price * (1 + loss) * 100) / 100,
		  amount: Math.round((net_usage+net_usage*loss)* price*100)/100,
          key,
          index: this.index,
        });
      } else {
        this.$emit("setAmount", { amount: "", key, index: this.index });
      }
    },
    //判断是否为数字
    checkNumber(params) {
      if (isNaN(params) || !params) {
        return 0;
      }
      return Number(params);
    },
	//百分数转小数
	toPoint(percent){
		if (/^\d+(\.\d+)?%$/.test(percent)) {
		    let str = percent.replace("%",'');
		    str=str/100;
		    return str;
		} else {
		     return 0;
		}
	},
    //添加行
    addPart() {
      this.$emit("addPart", { index: this.index });
    },
	remoteMethod(query) {
		if (query !== '') {
		  this.loading = true;
		  this.$api.getMaterialListData({material_name:query}).then((res)=>{
			  this.loading = false;
			  this.list = res.data;
			  this.options = res.data.map(item => {
				  return { value: `${item.material_name}`, label: `${item.material_name}` };
			  });
		  })
		} else {
		  this.options = [];
		}
	},
	handleChange(prop,row,key){
		let obj = this.list.find((item)=>{
			return item.material_name === row['texture_name'];
		})
		row.specification = obj.spec;
		row.price = obj.price;
		this.partAmount(prop,row,key);
	}
  },
};
</script>

<style lang="scss">
.cbd-part {
  .cbd-part-title {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
  }
  .el-textarea__inner,.el-input__inner {
    font-size: $base-font-size;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    resize: none;
    padding: 5px 0px !important;
	margin-left: 0px !important;
  }
  .el-select__input{
	  margin-left: 0px !important;
  }
  .part-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    .part-add-btn {
      font-size: 14px;
      font-weight: 500;
      color: $base-config-color;
      cursor: pointer;
    }
    .part-total {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      .count {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
</style>

<template>
  <div class="texture-list">
    <a-empty v-if="total == 0" />
    <div class="flex flex-start flex-wrap" v-else>
      <resource
        class="w-68px"
        v-if="type == 'logo'"
        :src="m.url || m.coverpic"
        :title="m.name"
        v-for="m in data"
        :key="m.id"
        :type="type"
        @click="handleClick(m)"
        @select="(t) => handleSelect(m, t)"
        @edit="handleEditLogo(m)"
      ></resource>
      <resource
        class="w-98px"
        v-if="type == 'texture'"
        :src="m.url || m.coverpic"
        :title="m.name"
        :type="type"
        :data="m"
        v-for="m in data"
        @click="handleTexture2(m)"
        :key="m.id"
        @select="(t) => handleSelect(m, t)"
        @edit="handleEdit(m)"
      ></resource>
    </div>
    <div class="resource-list-page" v-if="total > 0">
      <a-pagination
        size="small"
        v-model="current"
        :total="total"
        show-less-items
        @change="handleChange"
        :defaultPageSize="12"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");

import resource from "@/components/Resource.vue";
import { materialsData } from "@/utils/babylonjs/meshData";

export default {
  components: {
    resource,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    data: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: "logo",
    },
  },
  created() {},
  data() {
    return {
      selected: [],
      current: this.currentPage,
      materialsArr: [],
      logoData: {
        param: {
          metallic: 0,
          roughness: 1,
          environmentIntensity: 1.5,
          reflectionTexture: {
            url: "texture/environmentSpecular.env",
            type: "env",
            material: null,
          },
          albedoTexture: {
            url: "",
            id: 4,
            type: "color",
            material: [],
            vScale: 1,
            uScale: 1,
          },
        },
        size: { x: 20, y: 20, z: 20 },
        angle: 0,
      },
      materials: null,
    };
  },
  mounted() {
    this.materials = materialsData;
  },
  methods: {
    ...mapActions(["changeMaterial", "createLogo"]),
    ...mapMutations(["SET_TEXTURE_TYPE"]),
    handleSelect(val, t) {
      if (t) {
        this.selected.push(val);
      } else {
        this.selected = this.selected.filter((f) => f.id != val.id);
      }
      this.$emit("selected", this.selected);
    },
    handleClick(val) {
      this.logoData.param.albedoTexture.url = val.coverpic;
      let data = {
        logoData: this.logoData,
        ModelScene: this.global.ModelScene,
      };
      this.createLogo(data);
    },
	handleTexture2(val){
		if(val.param.type.name == 'Color mapping'){
			this.global.NewMaterialScene.setPBR({
			  albedoTexture: {
			    url: val.url,
			  },
			});
		}else if(val.param.type.name == 'bump mapping'){
			this.global.NewMaterialScene.setPBR({
			  bumpTexture: {
			    url: val.url,
			  },
			});
		}
	},
    handleTexture(val) {
      if (this.global.ModelScene.pickedMesh && val.url) {
        this.SET_TEXTURE_TYPE("texture");
        let defaultMaterial = {
          name: "",
          sn: "",
          cat_id: 2,
          shape: "cap",
          type: "pbr",
          material: "cloth",
          param: {
            reflectionTexture: {
              url: "texture/environmentSpecular.env",
              type: "env",
              material: null,
            },
            metallic: 0,
            roughness: 1,
            albedoTexture: {
              url: "",
              type: "color",
              material: null,
              vScale: 1,
              uScale: 1,
            },
            bumpTexture: {
              url: "",
              type: "bump",
              material: null,
              vScale: 1,
              uScale: 1,
            },
          },
        };
        if (val.cat_id == 6) {
          defaultMaterial.param.bumpTexture.url = val.url;
          this.global.ModelScene.setPBR({
            bumpTexture: {
              url: val.url,
            },
          });
        } else {
          defaultMaterial.param.albedoTexture.url = val.url;
          this.global.ModelScene.setPBR({
            albedoTexture: {
              url: val.url,
            },
          });
        }
        let data = {
          ModelScene: this.global.ModelScene,
          pbr: defaultMaterial,
        };
        // this.changeMaterial(data);
      } else {
        this.$message({
          message: "Please double-click the model to select the component!",
          type: "warning",
        });
      }
    },
    handleChange(page, pageSize) {
      this.$emit("page-change", page, pageSize);
    },
    handleEdit(m) {
      this.$emit("edit", m);
    },
    handleEditLogo(m) {
      this.$emit("edit", m);
    },
  },
};
</script>

<style lang="scss">
.texture-list {
  //   height: 74vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  row-gap: 8px;
  overflow: scroll;
  padding-right: 4px;
  .resource {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .resource:nth-child(3n) {
    margin-right: 0;
  }
}
.texture-list-page {
  margin: 0 auto;
  margin-top: 8px;
}
.w-98px {
  width: 98px;
}
</style>

<template>
  <div class="newmessage" v-if="boxshow">
    <div class="newmessage-tip">
      <li
        class="newmessage-item w-100 flex just-space-between hvr-color hvr-bg-color radius-4"
        @click="handleOpenConvs(newmsg)"
      >
        <div class="left">
          <img class="avatar mr-8 radius-8" :src="newmsg.meeting_img" alt="" />
          <div class="nick-lastmsg">
            <div class="text-color mb-4 font-size-16 flex just-space-between">
              <span class="font-size-14 font-600">
                {{ newmsg.meeting_name }}</span
              >
            </div>
            <div class="last-msg font-size-10 text-info-color">
              <span v-if="newmsg.unread > 1"> [{{ newmsg.unread }}条] </span>
              {{ newmsg.lastmsg }}
            </div>
          </div>
        </div>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewMessage",
  props: {
    newmsg: {
      type: Object,
      default: () => ({
        id: 1,
        meeting_img:
          "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/20201126/a3a21eadec048140613de291fa6b3152.png",
        meeting_name: "测试群",
        lastmsg:
          "测试信息3333333333333333333333333333333333333333333333333333333333333333333",
        unread: 2,
      }),
    },
  },
  data() {
    return {
      IMsign: "",
      boxshow: true,
      timeout: null,
    };
  },
  created() {
    this.timeout = setTimeout(this.hidetip, 6000);
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
  methods: {
    handleOpenConvs() {
      this.newmsg.unread = 0;
      this.$emit("click", this.newmsg);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.hidetip, 0);
    },
    hidetip() {
      this.$emit("delnewmsg", this.newmsg);
      this.boxshow = false;
    },
  },
};
</script>

<style lang="scss">
.newmessage {
  width: 60%;
  z-index: 15;
  position: absolute;
  margin-left: 20%;
  margin-top: 4px;
  border-radius: 2px;
  border: 1px solid #21c3a9;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 12%);
  .newmessage-tip {
    height: 56px;
    background: white;
  }
  .newmessage-item {
    padding-top: 4px;
    padding-bottom: 8px;
    padding-left: 10px;
    .left {
      padding: 4px 4px;
      display: flex;
      flex: 1;
    }
    .nick-lastmsg {
      padding: 4px 0px;
      width: 324px;
    }
    .avatar {
      width: 40px;
      height: 40px;
    }

    .last-msg {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 150px;
    }
  }
}
</style>

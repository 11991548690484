<template>
  <div class="assets">
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" :tab="$t('tabNameBoard')">
        <a-spin tip="Loading..." :spinning="loading">
          <material-list></material-list>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('tabNameLibary')">
        <a-spin tip="Loading..." :spinning="loading">
          <texture-list></texture-list>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="3" :tab="$t('tabNameLogo')">
        <a-spin tip="Loading..." :spinning="loading">
          <logo-list></logo-list>
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import MaterialList from "@/components/material/MaterialList.vue";
import LogoList from "@/components/logo-list/LogoList.vue";
import TextureList from "@/components/texture/TextureList.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("jiyang/material");
export default {
  name: "assets",
  components: { MaterialList, LogoList, TextureList },

  created() {},
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    tabChange() {},
  },
};
</script>

<style lang="scss">
.assets {
  height: 100%;
  min-width: 340px;
  max-width: 340px;
  width: 20%;
  background-color: #fff;
  text-align: center;
  .tab-content {
    width: 100%;
    height: 100%;
    padding: 0 12px;
  }
  .tab-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    .ant-input-group {
      margin-right: 16px;
    }
    .ant-btn-icon-only {
      width: 48px;
    }
    .ant-btn {
      margin-right: 8px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
</style>

export default ({ service, request }) => ({
  // 创建会议室
  getMeeting(data) {
    return request({
      url: `/v1/meeting/getmeeting`,
      method: "post",
      data,
    });
  },
  getmeetinguser(data) {
    return request({
      url: `/v1/meeting/getmeetinguser`,
      method: "post",
      data,
    });
  },
  getUserList(data) {
    return request({
      url: `/v1/user/getUserList`,
      method: "post",
      data,
    });
  },

  creatMeeting(data) {
    return request({
      url: `/v1/meeting/creat`,
      method: "post",
      data,
    });
  },
  outMeeting(data) {
    return request({
      url: `/v1/meeting/out`,
      method: "post",
      data,
    });
  },
  changeMeeting(data) {
    return request({
      url: `/v1/meeting/change`,
      method: "post",
      data,
    });
  },

  getIMsign() {
    return request({
      url: `/v1/meeting/getsign`,
      method: "post",
    });
  },
  addmpeople(data) {
    return request({
      url: `/v1/meeting/addpeople`,
      method: "post",
      data,
    });
  },
  delmpeople(data) {
    return request({
      url: `/v1/meeting/delpeople`,
      method: "post",
      data,
    });
  },
  backMsg(data) {
    return request({
      url: `v1/meeting/backMsg`,
      method: "post",
      data,
    });
  },

  /**
   *
   * 获取 聊天信息和 群成员
   * @param {*} data
   * @returns
   */
  seeMeeting(data) {
    return request({
      url: `/v1/meeting/seemeeting`,
      method: "post",
      data,
    });
  },
  sendMeetingmsg(data) {
    return request({
      url: `/v1/meeting/sendmsg`,
      method: "post",
      data,
    });
  },
  searchMeeting(data) {
    return request({
      url: `/v1/meeting/searchmeeting`,
      method: "post",
      data,
    });
  },
  searchMeetingmsg(data) {
    return request({
      url: `/v1/meeting/searchmsg`,
      method: "post",
      data,
    });
  },
  searchMeetinguser(data) {
    return request({
      url: `/v1/meeting/searchuser`,
      method: "post",
      data,
    });
  },
  updateMeetingUMlast(data) {
    return request({
      url: `/v1/meeting/updateusermsg`,
      method: "post",
      data,
    });
  },
});

// 引入d3
// import * as d3 from 'd3';

const d3 = require("./d3.v5.min.js");

let that;
let svgBoxWidth;
let svgBoxHeight;
let circle_r = 4; //半径
let rect_top = 0;
let rect_left = 0;
let multiple = 1;
let checkDrag = false;
class Svg {
  svgParam = {
    svg: "", //svg对象
    recGroup: "", //图框组
    lineGroup: "", //线条组
    imgGroup: "", //背景图片组
    flag: false, //判断直线是否完成
    line: "", //直线
    circle: "", //圆点
    texts: "", //圆点内文字
    idIndex: 1, //id标识
    checkIdIndex: 1, //排序标识
    circleName: 1, //点标识
    //线段坐标
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
    imgWidth: 850, //图片宽度
    imgHeight: 850, //图片高度
    rectWidth: 180, //图框宽度
    rectHeight: 64, //图框高度
    svgWidth: 0, //svg画板宽度
    svgHeight: 0, //svg画板高度
    svgImg: "", //svg画板背景图
    technology: "", //工艺
    texture_sn: "", //素材编号
    selectNum: 0, //当前选中id数值
    allData: [], //所有数据
    oneData: {}, //当前选中数据
    beginDraw: false, //是否允许拉线
    openModal: false, //是否打开创建模态框
    imgSize: 50, //图片尺寸
    isClickRect: false, //是否点击图框
    checkCreateLine: false, //是否正在拉线
    multiple: 1,
  };

  constructor(svgData) {
    that = this;
    this.removeSvg();
    this.svgParam = JSON.parse(JSON.stringify(that.svgParam));
    that.svgParam = Object.assign({}, that.svgParam, svgData);
    that.svgParam.data.param = that.svgParam;
    that.svgParam.svgWidth = svgData.data.svgWidth;
    that.svgParam.svgHeight = svgData.data.svgHeight;
    that.svgParam.multiple = svgData.data.multiple ? svgData.data.multiple : 1;
    multiple = svgData.data.multiple ? svgData.data.multiple : 1;
    svgBoxWidth = svgData.data.svgWidth;
    svgBoxHeight = svgData.data.svgHeight;
    that.svgParam.svg = d3
      .select("svg")
      .attr("width", svgData.data.svgWidth)
      .attr("height", svgData.data.svgHeight)
      .on("contextmenu", function () {
        window.event.returnValue = false;
        return false;
      })
      .on("mousedown", this.startDrawLine)
      .on("mousemove", this.moveLine)
      .on("mouseup", this.endLine);
    // this.createShadow()
    this.createImageSvg(svgData.data.img);

    // this.imgGroup=svg.append('g').attr('id','imgGroup')
  }
  //设置页面高度
  setSvgHeight(height) {
    d3.select("svg").attr("height", height);
    that.svgParam.svgHeight = height;
    svgBoxHeight = height;
  }
  //设置页面宽度
  setSvgWidth(width) {
    d3.select("svg").attr("width", width);
    that.svgParam.svgWidth = width;
    svgBoxWidth = width;
  }
  //创建线段
  startDrawLine() {
    if (!that.svgParam.beginDraw) return;
    that.svgParam.oneData = {};
    that.svgParam.checkCreateLine = !that.svgParam.checkCreateLine;
    let event = d3.event;
    if (event.button == 0) {
      if (!that.svgParam.flag) {
        let box = d3.select(this);
        let mouseXY = d3.mouse(this);
        that.svgParam.x1 = mouseXY[0];
        that.svgParam.y1 = mouseXY[1];
        that.createLine(
          that.svgParam.x1,
          that.svgParam.y1,
          that.svgParam.x1,
          that.svgParam.y1,
          that.svgParam.idIndex,
          that.svgParam.checkIdIndex
        );
        that.svgParam.flag = true;
      }
    } else if (event.button == 2) {
      that.svgParam.flag = false;
      d3.select(`#Imgline${that.svgParam.idIndex}`).remove();
      d3.select(`#circleBox${that.svgParam.idIndex}`).remove();
      d3.select(`#rectName${that.svgParam.idIndex}`).remove();
      // line.attr('x1',x1).attr('y1',y1).attr('x2',x1).attr('y2',y1).attr('stroke','red')
    }
  }

  moveLine() {
    if (!that.svgParam.flag) return;
    let box = d3.select(this);
    let mouseXY = d3.mouse(this);
    that.svgParam.x2 = mouseXY[0];
    that.svgParam.y2 = mouseXY[1];
    d3.select(`#Imgline${that.svgParam.idIndex}`)
      .attr("x2", that.svgParam.x2)
      .attr("y2", that.svgParam.y2);
  }
  endLine() {
    if (!that.svgParam.flag) return;
    // let box=d3.select(this)
    let mouseXY = d3.mouse(this);
    let lineLength;
    let lineX = Math.abs(mouseXY[0] - that.svgParam.x1);
    let lineY = Math.abs(mouseXY[1] - that.svgParam.y1);
    if (lineY > 0) {
      lineLength = Math.sqrt(Math.pow(lineX, 2) + Math.pow(lineY, 2));
    } else {
      lineLength = lineY;
    }
    if (!lineLength || lineLength < 100) {
      d3.select(`#Imgline${that.svgParam.idIndex}`).remove();
      d3.select(`#circleBox${that.svgParam.idIndex}`).remove();
    } else {
      // let height=that.svgParam.rectHeight
      // let width=that.svgParam.rectWidth
      // let recx=mouseXY[0]-width/2
      // let recy=mouseXY[1]-height/2
      that.drawRect(
        mouseXY[0],
        mouseXY[1],
        that.svgParam.idIndex,
        "",
        that.svgParam.checkIdIndex
      );
    }
    that.svgParam.flag = false;
  }
  //创建线条
  createLine(x1, y1, x2, y2, now_num, check_num) {
    if (!that.svgParam.lineGroup) return;
    that.svgParam.lineGroup
      .append("line")
      .attr("id", "Imgline" + now_num)
      .attr("recNum", now_num)
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)
      .attr("stroke", "red");
    let circle_g = that.svgParam.lineGroup
      .append("g")
      .attr("id", "circleBox" + now_num);
    that.svgParam.circle = circle_g
      .append("circle")
      .attr("id", "circleName" + now_num)
      .attr("circleNum", now_num)
      .attr("cx", x1)
      .attr("cy", y1)
      .attr("r", circle_r)
      .attr("fill", "red")
      .call(dragCircle);
  }
  //创建图片
  createImageSvg(data) {
    if (data.img_url) {
      console.log(that.svgParam.multiple);
      that.svgParam.svgImg = that.svgParam.svg
        .append("svg:image")
        .attr("xlink:href", data.img_url)
        .attr("id", "pageImg")
        .attr("width", that.svgParam.imgWidth * that.svgParam.multiple)
        .attr("height", that.svgParam.imgHeight * that.svgParam.multiple)
        .attr(
          "x",
          data.x
            ? data.x * that.svgParam.multiple
            : (that.svgParam.svgWidth - that.svgParam.imgWidth) / 2
        )
        .attr(
          "y",
          data.y
            ? data.y * that.svgParam.multiple
            : (that.svgParam.svgHeight - that.svgParam.imgHeight) / 2 + 40
        )
        .attr("transform", `scale(1,1)`);
      d3.select("#addImage").remove();
      that.svgParam.lineGroup = that.svgParam.svg
        .append("g")
        .attr("id", "lineGroup");
      that.svgParam.recGroup = that.svgParam.svg
        .append("g")
        .attr("id", "recGroup");
      that.svgParam.beginDraw = true;
    } else {
      let g = that.svgParam.svg
        .append("foreignObject")
        .attr("id", "addImage")
        .attr("x", (that.svgParam.svgWidth - 200) / 2)
        .attr("y", (that.svgParam.svgHeight - 200) / 2)
        .attr("width", 200)
        .attr("height", 200)
        .append("xhtml:div")
        .attr("class", "img-input-box");
      g.append("img")
        .attr(
          "src",
          "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/social/57616297740854500.4786038539652526.png"
        )
        .attr("class", "img-input-box-img")
        .on("click", function () {
          that.svgParam.openModal = true;
        });
    }
  }
  //关闭弹窗
  closeModal() {
    that.svgParam.openModal = false;
  }
  //保存svg
  saveSvg(svgEl, name) {
    // svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    var svgData = svgEl.outerHTML;
    var preface = '<?xml version="1.0" standalone="no"?>\r\n';
    var svgBlob = new Blob([preface, svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  //获取节点
  getSvgNode(callback) {
    let node = d3.select("svg").node();
    callback(node);
  }
  //创建标签框
  async drawRect(x, y, now_num, data, check_num, type = 0) {
    //type 0 创建  1初始化  2插入
    if (!that.svgParam.recGroup) return;
    for (let i = 0; i < now_num; i++) {
      d3.select(`#rectName${i}`).style("border", "1px solid #ccc");
    }
    that.svgParam.selectNum = now_num;
    // that.svgParam.isClickRect=!that.svgParam.isClickRect
    let height = that.svgParam.rectHeight;
    let width = that.svgParam.rectWidth;
    let moveX = x + width / 2;
    let moveY = y + height / 2;
    let foreign = that.svgParam.recGroup
      .append("foreignObject")
      .attr("id", `rectName${now_num}`)
      .attr("recNum", now_num)
      .attr("x", x - width / 2)
      .attr("y", y - height / 2)
      .attr("width", width)
      .attr("height", height)
      // .attr('transform',`translate(${moveX},${moveY}) scale(${that.svgParam.multiple},${that.svgParam.multiple}) translate(${-moveX},${-moveY})`)
      .style("border", "2px solid #21c3a9")
      .style("box-sizing", "border-box")
      .style("border-radius", "4px")
      .on("click", function () {
        for (let i = 0; i < that.svgParam.idIndex; i++) {
          d3.select(`#rectName${i}`).style("border", "1px solid #ccc");
        }
        d3.select(this).style("border", "2px solid #21c3a9");
        that.svgParam.selectNum = d3.select(this).attr("recNum");
        that.svgParam.oneData = that.getNowData(that.svgParam.selectNum);
        that.svgParam.isClickRect = !that.svgParam.isClickRect;
      })
      .on("mousedown", function () {
        for (let i = 0; i < that.svgParam.idIndex; i++) {
          d3.select(`#rectName${i}`).style("border", "1px solid #ccc");
        }
        that.svgParam.selectNum = d3.select(this).attr("recNum");
        that.svgParam.oneData = that.getNowData(that.svgParam.selectNum);
        d3.select(`#rectName${that.svgParam.selectNum}`).style(
          "border",
          "2px solid #21c3a9"
        );
        let mouseXY = d3.mouse(this);
        rect_left = mouseXY[0] - d3.select(this).attr("x");
        rect_top = mouseXY[1] - d3.select(this).attr("y");
        that.svgParam.isClickRect = !that.svgParam.isClickRect;
      })
      .call(dragRect)
      .append("xhtml:div")
      .attr("class", "rect-box")
      .attr("recNum", now_num);
    // .style('box-shadow','0px 4px 20px rgba(68, 68, 86, 0.16)')
    let imgDiv = foreign
      .append("div")
      .attr("class", "rect-img-box")
      .style("width", height + "px")
      .style("height", height + "px")

      .style(
        "background-color",
        data && data.color && data.cover_pic == "" ? data.color : "#f2f2f2"
      )
      .style(
        "background-image",
        data
          ? `url(${
              data.texture_id
                ? await this.main(data.cover_pic)
                : await this.main(data.texture_remark_img)
            })`
          : ""
      )
      .attr("recNum", now_num)
      .attr("id", `rectNameImgBox${now_num}`);
    imgDiv
      .append("div")
      .attr("class", "rect-box-tag")
      .attr("id", `rectText${now_num}`)
      .text(check_num);

    let tag = imgDiv.append("div").attr("id", `partTag${now_num}`);

    if (data.name) {
      tag.attr("class", "rect-part-tag").text(data.name);
    }

    // .append('img')
    // .attr('id',`rectNameImg${now_num}`)
    //     .attr('class','rect-img')
    //     .attr('src',url)
    //     .attr('width',height)
    //     .attr('height',height)
    let right = foreign
      .append("div")
      .attr("class", "right-box")
      .attr("id", `rectNameInputBox${now_num}`);
    right
      .append("div")
      .attr("id", `hideText${now_num}`)
      .attr("class", "hide-text")
      .text(data.technology);

    right
      .append("div")
      .attr("id", `hideTextSn${now_num}`)
      .attr("class", "hide-text")
      .text(data.sn);

    that.updateInputWidth(now_num);
    that.updateSnWidth(now_num);
    if (type != 1) {
      if (type == 0) {
        that.addData(now_num);
      }
      right
        .append("input")
        .attr("id", `textureSn${now_num}`)
        .attr("class", "right-input")
        .attr("placeholder", "Material number")

        .property("value", data ? data.sn : "")
        .on("keyup", function () {
          that.svgParam.texture_sn = d3.select(this).node().value;
          d3.select(`#hideTextSn${that.svgParam.selectNum}`).text(
            that.svgParam.texture_sn
          );
          that.updateSnWidth(that.svgParam.selectNum);
          that.updateData(now_num, "sn", that.svgParam.texture_sn);
        });
      right
        .append("input")
        .attr("id", `textureTechnology${now_num}`)
        .attr("class", "right-input")
        .attr("placeholder", "Materials technology")
        .property("value", data ? data.technology : "")
        .on("input", function () {
          that.svgParam.technology = d3.select(this).node().value;
          d3.select(`#hideText${that.svgParam.selectNum}`).text(
            that.svgParam.technology
          );
          that.updateInputWidth(that.svgParam.selectNum);
          that.updateData(now_num, "technology", that.svgParam.technology);
        });
      that.svgParam.idIndex++;
      that.svgParam.checkIdIndex++;
    } else {
      right
        .append("div")
        .attr("id", `textureTechnologyDiv${now_num}`)
        .attr("class", "right-input-div");
      right
        .append("div")
        .attr("id", `textureSnDiv${now_num}`)
        .attr("class", "right-input-div");
    }
  }
  //更新工艺输入框宽度
  updateInputWidth(num) {
    let textWidth = d3.select(`#hideText${num}`).node().clientWidth;
    let recWidth = textWidth + 90 <= 180 ? 180 : textWidth + 90;
    that.updateRectWidth(recWidth, num);
  }
  //更新编号输入框宽度
  updateSnWidth(num) {
    let textWidth = d3.select(`#hideTextSn${num}`).node().clientWidth;
    let recWidth = textWidth + 90 <= 180 ? 180 : textWidth + 90;
    that.updateRectWidth(recWidth, num);
  }
  //更新图框宽度
  updateRectWidth(width, num) {
    if (width === 180) return;
    let recWidth = d3.select(`#rectName${num}`).attr("width");
    let checkWidth = 0;
    let snWidth = d3.select(`#hideTextSn${num}`).node().clientWidth;
    let textWidth = d3.select(`#hideText${num}`).node().clientWidth;
    if (snWidth > textWidth) {
      checkWidth = snWidth;
    } else {
      checkWidth = textWidth;
    }
    width = checkWidth + 90;
    d3.select(`#rectName${num}`).attr("width", width);
  }
  //取消选中
  removeSelect() {
    for (let i = 0; i < that.svgParam.idIndex; i++) {
      d3.select(`#rectName${i}`).style("border", "1px solid #ccc");
    }
    that.svgParam.selectNum = 0;
    that.svgParam.oneData = {};
  }
  //获取当前项数据
  getNowData(now_num) {
    let data = {};
    that.svgParam.allData.some((val) => {
      if (val.nodeNum == now_num) {
        data = val;
        return true;
      }
    });
    return data;
  }
  //移除替换节点
  exportReplaceSvg(data) {
    let now_num = data.nodeNum;
    let check_num = data.num;
    this.showOrHideNode(0, now_num, check_num);
    this.createCircle(0, now_num, check_num);
  }
  //    替换输入框
  replaceInput(data) {
    console.log(data);
    let now_num = data.nodeNum;
    d3.select(`#textureSn${now_num}`).remove();
    d3.select(`#textureTechnology${now_num}`).remove();
    let right = d3.select(`#rectNameInputBox${now_num}`);
    right
      .append("div")
      .attr("id", `textureTechnologyDiv${now_num}`)
      .attr("class", "right-input-div")
      .text(data.sn ? data.sn : "");
    right
      .append("div")
      .attr("id", `textureSnDiv${now_num}`)
      .attr("class", "right-input-div")
      .text(data.technology ? data.technology : "");
  }
  //    隐藏/显示节点
  //    type  0隐藏  1显示
  showOrHideNode(type, now_num, check_num) {
    if (type == 0) {
      d3.select(`#rectName${now_num}`).style("display", "none");
      d3.select(`#Imgline${now_num}`).style("display", "none");
    } else {
      d3.select(`#rectName${now_num}`).style("display", "block");
      d3.select(`#Imgline${now_num}`).style("display", "block");
    }
  }
  //    恢复节点
  resetInput(data) {
    let now_num = data.nodeNum;
    let check_num = data.num;
    d3.select(`#textureSnDiv${now_num}`).remove();
    d3.select(`#textureTechnologyDiv${now_num}`).remove();
    let right = d3.select(`#rectNameInputBox${now_num}`);
    right
      .append("input")
      .attr("id", `textureSn${now_num}`)
      .attr("class", "right-input")
      .attr("placeholder", "Material number")

      .property("value", data ? data.sn : "")
      .on("keyup", function () {
        that.svgParam.texture_sn = d3.select(this).node().value;
        that.updateData(now_num, "sn", that.svgParam.texture_sn);
      });
    right
      .append("input")
      .attr("id", `textureTechnology${now_num}`)
      .attr("class", "right-input")
      .attr("placeholder", "Materials technology")
      .property("value", data ? data.technology : "")
      .on("input", function () {
        that.svgParam.technology = d3.select(this).node().value;
        that.updateData(now_num, "technology", that.svgParam.technology);
      });
    this.showOrHideNode(1, now_num);
    this.createCircle(1, now_num);
  }
  //    创建圆
  createCircle(type, now_num, check_num) {
    if (type == 0) {
      let x = d3.select(`#circleName${now_num}`).attr("cx");
      let y = d3.select(`#circleName${now_num}`).attr("cy");

      let circle_g = that.svgParam.svg
        .append("g")
        .attr("id", "circleCheckBox" + now_num);
      circle_g
        .append("circle")
        .attr("circleNum", now_num)
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", 10)
        .attr("fill", "#333");
      circle_g
        .append("text")
        .text(check_num)
        .attr("fill", "#fff")
        .attr("x", x)
        .attr("y", y)
        .attr("circleNum", now_num)
        .style("user-select", "none")
        .style("text-anchor", "middle")
        .attr("dy", "0.35em");
    } else {
      d3.select(`#circleCheckBox${now_num}`).remove();
    }
  }
  //    设置素材编号
  setTextureSn(val) {
    d3.select(`#hideTextSn${that.svgParam.selectNum}`).text(val);
    that.updateSnWidth(that.svgParam.selectNum);
    d3.select(`#textureSn${that.svgParam.selectNum}`).property("value", val);
    that.updateData(that.svgParam.selectNum, "sn", val);
  }
  //    设置素材工艺
  setTextureTechnology(val) {
    d3.select(`#hideText${that.svgParam.selectNum}`).text(val);
    that.updateInputWidth(that.svgParam.selectNum);
    d3.select(`#textureTechnology${that.svgParam.selectNum}`).property(
      "value",
      val
    );
    that.updateData(that.svgParam.selectNum, "technology", val);
  }
  //    设置图片颜色
  setImgColor(data, type) {
    if (type == 1) {
      d3.select(`#rectNameImgBox${that.svgParam.selectNum}`)
        .attr("pantone", data.pantone)
        .style("background-image", "")
        .style("background-color", data.color);
    }

    that.updateData(that.svgParam.selectNum, "pantone", data.pantone);
    that.updateData(that.svgParam.selectNum, "color", data.color);
    // that.updateData(that.svgParam.selectNum,'texture_id','')
    // that.updateData(that.svgParam.selectNum,'texture_name','')
    // that.updateData(that.svgParam.selectNum,'cover_pic','')
    // that.updateData(that.svgParam.selectNum,'texture_remark_img','')
  }
  //    设置图片
  async setImage(data, type = 0) {
    let url = data ? data.cover_pic : "";
    if (type == 0) {
      d3.select(`#rectNameImgBox${that.svgParam.selectNum}`)
        .style("background-image", `url('${await this.main(url)}')`)
        .style("background-color", "#f2f2f2");
      that.updateData(that.svgParam.selectNum, "cover_pic", url);
      that.updateData(that.svgParam.selectNum, "texture_remark_img", "");
      that.updateData(that.svgParam.selectNum, "sn", data ? data.sn : "");
    } else {
      d3.select(`#rectNameImgBox${that.svgParam.selectNum}`)
        .style("background-image", `url('${url}')`)
        .style("background-color", "#f2f2f2");
      that.updateData(that.svgParam.selectNum, "cover_pic", "");
      that.updateData(that.svgParam.selectNum, "texture_remark_img", url);
    }
    that.updateData(that.svgParam.selectNum, "texture_id", data ? data.id : "");
    that.updateData(
      that.svgParam.selectNum,
      "texture_name",
      data ? data.name : ""
    );
  }
  //    设置图片大小
  setImageSize(data) {
    let size = 1 + (data - 50) / 100;
    that.svgParam.imgSize = data;
    let imgWidth = Number(that.svgParam.svgImg.attr("width"));
    let imgHeight = Number(that.svgParam.svgImg.attr("height"));
    let imgX = Number(that.svgParam.svgImg.attr("x"));
    let imgY = Number(that.svgParam.svgImg.attr("y"));
    let moveX = imgX + imgWidth / 2;
    let moveY = imgY + imgHeight / 2;
    that.svgParam.svgImg.attr(
      "transform",
      `translate(${moveX},${moveY}) scale(${size},${size}) translate(${-moveX},${-moveY})`
    );
  }
  //    设置部件
  setPart(data) {
    that.updateData(that.svgParam.selectNum, "part_id", data.id);
    that.updateData(that.svgParam.selectNum, "name", data.name);
    d3.select(`#partTag${that.svgParam.selectNum}`)
      .attr("class", "rect-part-tag")
      .text(data.name);
    // if(data.type==1){
    //     d3.select(`#textureSn${that.svgParam.selectNum}`).attr('disabled','disabled')
    // }else{
    //     d3.select(`#textureSn${that.svgParam.selectNum}`).attr('disabled',null)
    // }
  }

  //    创建阴影
  createShadow() {
    let defs = that.svgParam.svg.append("defs");
    let filter = defs
      .append("filter")
      .attr("id", "drop-shadow")
      .attr("height", "130%");
    filter
      .append("feColorMatrix")
      .attr("in", "offOut")
      .attr("type", "matrix")
      .attr(
        "values",
        `0.5 0 0 0 0 
                            0 0.5 0 0 0 
                            0 0 0.5 0 0 
                            0 0 0 1 0`
      )
      .attr("result", "matrixOut");
    filter
      .append("feGaussianBlur")
      .attr("in", "matrixOut")
      .attr("stdDeviation", 2)
      .attr("result", "blur");

    filter
      .append("feOffset")
      .attr("in", "FillPaint")
      .attr("dx", 2)
      .attr("dy", 2)
      .attr("result", "offsetBlur");
    var feMerge = filter.append("feMerge");
    feMerge.append("feMergeNode").attr("in", "offsetBlur");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic");
  }
  //保存节点
  saveNode(callback) {
    let rects = [];
    let lines = [];
    d3.selectAll("foreignObject").each(function (d, i) {
      let rectNode = d3.select(this);
      let rectMsg = rectNode.node().getBoundingClientRect();
      let data = {
        x: rectMsg.x,
        y: rectMsg.y,
        num: Number(rectNode.attr("recNum")),
        info: that.svgParam.allData.filter(
          (val) => val.nodeNum == rectNode.attr("recNum")
        )[0],
      };
      rects.push(data);
    });

    d3.selectAll("line").each(function (d, i) {
      let node = d3.select(this);
      let line = {
        x1: node.attr("x1"),
        x2: node.attr("x2"),
        y1: node.attr("y1"),
        y2: node.attr("y2"),
        num: Number(node.attr("recNum")),
      };
      lines.push(line);
    });
    let img = d3.select("#pageImg");

    let imgArr = {
      imgSize: that.svgParam.imgSize,
      img_url: "",
      x: img.attr("x"),
      y: img.attr("y"),
    };
    let obj = {
      rects,
      lines,
      img: imgArr,
      svgHeight: that.svgParam.svgHeight,
      svgWidth: that.svgParam.svgWidth,
      allData: that.svgParam.allData,
    };
    callback(obj);
  }
  //删除节点
  removeNode() {
    d3.select(`#rectName${that.svgParam.selectNum}`).remove();
    d3.select(`#Imgline${that.svgParam.selectNum}`).remove();
    d3.select(`#circleBox${that.svgParam.selectNum}`).remove();
    let check = false;
    let nodeKey = 0;
    let isLast = false;
    that.svgParam.allData.some((val, key) => {
      if (check) {
        let num = val.num - 1;
        that.svgParam.allData[key].num = num;
        d3.select(`#rectText${val.nodeNum}`).text(num);
      }
      if (val.nodeNum == that.svgParam.selectNum) {
        check = true;
        nodeKey = key;
        if (key == that.svgParam.allData.length - 1) {
          isLast = true;
        }
      }
    });
    that.svgParam.allData.splice(nodeKey, 1);
    that.svgParam.checkIdIndex--;

    if (isLast && that.svgParam.allData.length > 0) {
      that.svgParam.oneData = that.svgParam.allData[nodeKey - 1];
      d3.select(`#rectName${that.svgParam.allData[nodeKey - 1].nodeNum}`).style(
        "border",
        "2px solid #21c3a9"
      );
      that.svgParam.selectNum = that.svgParam.allData[nodeKey - 1].nodeNum;
    } else if (!isLast && that.svgParam.allData.length > 0) {
      that.svgParam.oneData = that.svgParam.allData[nodeKey];
      d3.select(`#rectName${that.svgParam.oneData.nodeNum}`).style(
        "border",
        "2px solid #21c3a9"
      );
      that.svgParam.selectNum = that.svgParam.allData[nodeKey].nodeNum;
    } else {
      that.svgParam.oneData = {};
      that.svgParam.checkIdIndex = 1;
      that.svgParam.selectNum = 1;
    }
  }
  //添加数据
  addData(num) {
    that.svgParam.allData.push({
      part_id: 999999,
      name: "",
      num: that.svgParam.checkIdIndex,
      nodeNum: num,
      texture_name: "",
      texture_id: "",
      sn: "",
      technology: "",
      cover_pic: "",
      color: "",
      texture_remark_img: "",
    });
    that.svgParam.oneData = that.getNowData(num);
  }
  //更新数据
  updateData(num, param, value) {
    that.svgParam.allData.some((val, key) => {
      if (num == val.nodeNum) {
        val[param] = value;
        that.svgParam.allData.splice(key, 1, val);
        return true;
      }
    });

    if (num == that.svgParam.oneData.nodeNum) {
      that.svgParam.oneData[param] = value;
    }
  }
  //图片转base64
  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    let width = img.width;
    let height = img.height;
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    var dataURL = canvas.toDataURL("image/png"); // 可选其他值 image/jpeg
    return dataURL;
  }

  main(src) {
    return new Promise((resolve) => {
      let url = "";
      if (src) {
        var image = new Image();
        image.src = src + "?v=" + Math.random() + "&imageMogr2/thumbnail/!10p"; // 处理缓存
        image.crossOrigin = "*"; // 支持跨域图片
        image.onload = function () {
          url = that.getBase64Image(image);
          resolve(url);
        };
      } else {
        resolve(url);
      }
    });
  }
  //rgb颜色转换
  colorRGB2Hex(color) {
    var rgb = color.split(",");
    var r = parseInt(rgb[0].split("(")[1]);
    var g = parseInt(rgb[1]);
    var b = parseInt(rgb[2].split(")")[0]);

    var hex =
      "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    return hex;
  }
  //    移除svg
  removeSvg() {
    d3.selectAll("svg > *").remove();
  }
}

//拖拽函数
var dragRect = d3.drag().on("drag", function () {
  let width = d3.select(this).attr("width");
  let height = d3.select(this).attr("height");
  let num = d3.select(this).attr("recNum");
  let dx = d3.event.x - rect_left;
  let dy = d3.event.y - rect_top;
  let check = d3.select(this).attr("x", dx).attr("y", dy);
  if (d3.select(this).attr("y") <= 0) {
    d3.select(this).attr("y", 0);
  } else if (d3.select(this).attr("y") >= svgBoxHeight - height) {
    d3.select(this).attr("y", svgBoxHeight - height);
  }
  if (d3.select(this).attr("x") <= 0) {
    d3.select(this).attr("x", 0);
  } else if (d3.select(this).attr("x") >= svgBoxWidth - width) {
    d3.select(this).attr("x", svgBoxWidth - width);
  }
  d3.select(`#Imgline${num}`)
    .attr("x2", dx + width / 2)
    .attr("y2", dy + height / 2);
});
var dragCircle = d3.drag().on("drag", function () {
  let r = circle_r;
  let num = d3.select(this).attr("circleNum");

  let lines = d3.select(`#Imgline${num}`);
  let circle = d3
    .select(`#circleName${num}`)
    .attr("cx", d3.event.x)
    .attr("cy", d3.event.y);
  d3.select(`#Imgline${num}`).attr("x1", d3.event.x).attr("y1", d3.event.y);

  // d3.select(`#circleText${num}`).attr('x',d3.event.x).attr('y',d3.event.y)
  if (circle.attr("cy") <= r) {
    circle.attr("cy", r);
    lines.attr("y1", r);
    // d3.select(`#circleText${num}`).attr('y',r)
  } else if (circle.attr("cy") >= svgBoxHeight - r) {
    circle.attr("cy", svgBoxHeight - r);
    lines.attr("y1", svgBoxHeight - r);
    // d3.select(`#circleText${num}`).attr('y',svgBoxHeight-r)
  }
  if (circle.attr("cx") <= r) {
    circle.attr("cx", r);
    lines.attr("x1", r);
    // d3.select(`#circleText${num}`).attr('x',r)
  } else if (circle.attr("cx") >= svgBoxWidth - r) {
    lines.attr("x1", svgBoxWidth - r);
    circle.attr("cx", svgBoxWidth - r);
    // d3.select(`#circleText${num}`).attr('x',svgBoxWidth-r)
  }
});

export default Svg;

<template>
  <div class="operation-dialog" :style="{ top: top + 'px' }">
    <el-input
      class="operation-input"
      placeholder="请输入目标价格"
      v-model="operationPrice"
      @keyup.native="operationPrice = operationPrice.replace(/[^0-9_\.]/g, '')"
    ></el-input>
    <div class="operation-btn-box">
      <div class="operation-btn" @click="closeOperation">取消</div>
      <div class="operation-btn operation-submit-btn" @click="submitOperation">
        确定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "operationDialog",
  props: {
    top: {
      type: Number,
    },
  },
  data() {
    return {
      operationPrice: "",
    };
  },
  methods: {
    submitOperation() {
      if (!this.operationPrice) {
        this.$message.error("请输入目标价格");
        return false;
      }
      this.$emit("setOperationPrice", { price: this.operationPrice });
    },
    closeOperation() {
      this.$emit("closeOperation");
    },
  },
};
</script>

<style lang="scss">
.operation-dialog {
  width: 246px;
  height: 109px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.3);
  padding: 20px;
  position: absolute;
  right: 200px;
  top: 0;
  z-index: 1000;
  .operation-input {
    width: 100%;
    height: 30px;
    border: 1px solid #e0e0e0;
  }
  .operation-btn-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    padding: 0 8px;
    .operation-btn {
      width: 86px;
      height: 24px;
      background: #e1e1e1;
      box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.3);
      border-radius: 3px;
    }
    .operation-submit-btn {
      background: #64d9bb;
    }
  }
}
</style>

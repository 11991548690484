import dayjs from "dayjs";

export const mode = {
  add: "add",
  remove: "remove",
  create: "create",
};

export const uid = () => {
  const str = "abcdefghijklmn";
  let res = "";
  for (let i = 0; i < 10; i++) {
    let idx = parseInt(Math.random(0, str.length) * 10);
    res += str[idx];
  }
  return `${Date.now()}${res}`;
};

export const isJSON = (value) => {
  if (JSON.stringify(value) === "{}") {
    return false;
  } else {
    try {
      if (
        Object.prototype.toString.call(JSON.parse(value)) === "[object Object]"
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
};

export const timestampFormat = (time) => {
  dayjs.unix(time).format("YYYY-MM-DD HH:mm:ss");
};

<template>
  <div class="form-right" ref="formRight">
    <div>
      <formRightHeader
        @toEdit="toEdit"
        @toSubmit="toSubmit"
        @toSave="toSave"
        @toExportPdf="toExportPdf"
        @toList="toList"
        @submitForm="submitForm"
        @showHistory="showHistory"
        :checkEdit="checkEdit"
        :itemData="itemData"
        :isShowList="isShowList"
        :formType="formType"
        :isHistory="isHistory"
      ></formRightHeader>
      <div v-show="isShowList">
        <formTableList
          ref="formTableList"
          :formType="formType"
          @click="toDetail"
          @createForm="createForm"
          @toEditTemplate="toEditTemplate"
        ></formTableList>
      </div>
      <!--            <div class="hideForm">-->
      <!--            <formPage-->
      <!--              v-show="!isShowList && formType != 5"-->
      <!--              :checkEdit="checkEdit"-->
      <!--              :templateData="templateData"-->
      <!--              :formType="formType"-->
      <!--              :customId="customer_id"-->
      <!--              :customer="customer"-->
      <!--              @getCustom="getCustom"-->
      <!--              @getTop="getTop"-->
      <!--              ref="exportPage"-->
      <!--            >-->
      <!--            </formPage>-->
      <!--                  </div>-->
      <div class="form-right-center">
        <selectGoodsDialog
          :formTable="templateData.formPage.product.tableData"
          v-if="isShowGoodsDialog"
          ref="selectGoodsDialog"
          @submitProduct="submitProduct"
        ></selectGoodsDialog>
        <div
          class="hideGoods"
          v-if="isShowGoodsDialog"
          :class="[
            !isShowHideGoods || userRole === 'custom' ? 'hide-nav-width' : '',
          ]"
        ></div>
        <div id="exportPage" v-if="!isShowList && formType != 5">
          <exportPage
            ref="exportPage"
            :templateData="templateData"
            v-for="(val, key) in tableData"
            :checkEdit="checkEdit"
            :productData="val"
            :isHistory="isHistory"
            :index="key"
            :last="lastPage"
            :formType="formType"
            :customId="customer_id"
            :customer="customer"
            @getTop="getTop"
            @addProductRow="addProductRow"
            @createPage="createPage"
            @setTotal="setTotal"
            :key="key"
          ></exportPage>
        </div>
        <formHistory :visible.sync="isShowHistory" :sn="sn" :detailId="detailId" :historyList="historyList" @toFormDetail="toFormDetail"></formHistory>
      </div>
<!--      <div class="message-box" v-if="!isShowList && formType != 5">-->
<!--        <el-input type="textarea" v-model="formMessage" :disabled="formNowType >4"></el-input>-->
<!--        <div class="message-tips">Please enter message</div>-->
<!--        <el-upload-->
<!--            style="display: flex"-->
<!--            action=""-->
<!--            multiple-->
<!--            :on-change="handleChange"-->
<!--            ref="upload"-->
<!--            :file-list="fileList"-->
<!--            :http-request="uploadFile"-->
<!--        >-->
<!--          <span class="attach-btn">Add attachment</span>-->
<!--        </el-upload>-->
<!--      </div>-->
      <cbdPage
        v-if="!isShowList && formType == 5"
        :itemData="itemData"
        :isUpdate="isUpdate"
        ref="cbdPage"
      >
      </cbdPage>
    </div>

    <div
      class="hideTable"
      v-if="templateData.formPage && templateData.formPage.product"
    >
      <formTable
        :table="templateData.formPage.product.tableData"
        :totalData="templateData.formPage.product.totalData"
        :type="2"
      ></formTable>
    </div>
  </div>
</template>

<script>
import formPage from "./formPage";
import exportPage from "./exportPage";
import formTable from "./formTable";
import exportPdf from "../../utils/exportPdf";
import formRightHeader from "./formRightHeader";
import formTableList from "./formTableList";
import cbdPage from "../cbdList/cbdPage";
import selectGoodsDialog from "@/components/lists/selectGoodsDialog";
import { mapState, mapMutations, mapActions } from "vuex";
import uploadCos from "@/utils/upload_to_cos";
import formTemplate from "@/utils/formTemplate";
import formHistory from "@/components/lists/formHistory";
export default {
  components: {
    formHistory,
    exportPage,
    formTable,
    formRightHeader,
    formTableList,
    cbdPage,
    selectGoodsDialog,
  },
  mixins: [formTemplate],
  name: "formRight",
  props: {
    formId: {
      type: Number,
    },
    type: {
      type: Number,
      default: 1,
    },
    createProduct: {
      type: Object,
    },
  },
  data() {
    return {
      checkEdit: false,
      detailId: this.formId,
      itemData: {},
      isUpdate: true,
      isShowList: true,
      formListData: {},
      formMessage:"",
      templateData: {
        formHeader: {
          name: "formHeader",
          type: "header",
          data: {
            logo: "",
            desc: "",
            title: "Quotation",
          },
        },
        formFooter: {
          name: "formFooter",
          type: "footer",
          data: {
            desc: "Thank you for business with us!",
          },
        },
        formPage: {
          recommendDetail: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [
                { prop: "title", type: "title", width: 90 },
                { prop: "detail", type: "input", width: 310 },
              ],
              data: [
                {
                  title: { value: "Theme", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "Intro", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
              ],
            },
          },
          formSn: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [
                { prop: "title", type: "title", width: 90 },
                { prop: "detail", type: "input", width: 140 },
              ],
              data: [
                {
                  title: { value: "Maker", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "Enq No", type: "normal" },
                  detail: { value: "", type: "header" },
                },
                {
                  title: { value: "Date", type: "normal" },
                  detail: { value: "", type: "date" },
                },
              ],
            },
          },
        },
      },
      formType: this.type,
      pageList: [],
      tableData: [
        [

        ],
      ],
      offsetTop: 0,
      pageHeight: 1600,
      pageBottom: 0,
      pageHeader: 0,
      lastPage: 0,
      nowType: 1,
      page: 1,
      page_size: 1,
      isChangeStatus: false,
      customer_id: 0,
      customer: {},
      isCreate: false,
      isDetail: false,
      isFirstCreate: false,
      isShowHideGoods: false,

      pageZoom: 1,
      isShowHistory:false,
      historyList:[],
      isHistory:false,
	  sn:'',
	  isSelect:false
    };
  },
  watch: {
    offsetTop(val) {
      if (val > 0) {
        this.resetTable();
      }
    },
    formId(val) {
      this.detailId = val;
      this.checkEdit = false;
      this.getFormDetail();
    },
    type(val) {
      /* this.isShowList = true;
      this.itemData = {};
      this.formType = val;
      this.checkEdit = false;
	  this.$refs.formTableList.getList(this.formType); */
    },
    isCreate(val) {
      if (val) {
        this.checkEdit = false;
        this.createForm();
      }
    },
    isDetail(val) {
      if (val) {
        if (this.nowFormId != 0) {
          this.isShowList = false;
          this.itemData = {};
          this.isUpdate = false;
          this.detailId = this.nowFormId;
          this.checkEdit = false;
          this.getFormDetail();
        }
      }
    },

    navCollapse(val) {
      this.isShowHideGoods = val;
    },
  },
  created() {
    // this.toGetTemplate();
  },
  mounted() {
    const that = this;
    window.screenWidth = document.body.clientWidth;
    this.pageZoom = (window.screenWidth / 1920).toFixed(2);
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.pageZoom = (window.screenWidth / 1920).toFixed(2);
        that.pageTop = (65 / that.pageZoom).toFixed(2);
        that.$refs.formRight.style.zoom = that.pageZoom;
      })();
    };
  },
  computed: {
    ...mapState("jiyang/user", ["info", "userRole"]),
    ...mapState("jiyang/cart", ["nowFormId", "cart"]),
    ...mapState("jiyang/app", ["navCollapse"]),
    ...mapState("jiyang/form", ["formNowType"]),
    isShowGoodsDialog() {
      if (
        (this.formNowType === 4 || this.formNowType === 1) &&
		this.isSelect &&
		//this.formNowType === 1 &&
        !this.isShowList &&
        this.formType != 5
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations("jiyang/app", ["setCollapse"]),
    ...mapMutations("jiyang/form", ["setFormState", "setFormAllType"]),
    ...mapActions("jiyang/count", ["getCount"]),
    createForm() {
	  this.isSelect = true;
      this.isShowList = false;
      this.itemData = {};
      this.isUpdate = false;
      this.detailId = -1;
      this.checkEdit = false;
      this.customer_id = 0;
      this.isFirstCreate = true;
      this.setCollapse(true);
	  if(this.templateData.formPage.userMsg){
		  const values = this.templateData.formPage.userMsg.tableData.data;
		  values[2].detail.value = '';
		  values[3].detail.value = '';
		  values[4].detail.value = '';
	  }
	  if(this.templateData.formPage.product && this.templateData.formPage.product.tableData){
		  this.templateData.formPage.product.tableData = [];
	  }
      if (this.formType !== 5) {
        this.toGetTemplate();
      }
    },
    toEditTemplate() {
      this.isShowList = false;
      this.itemData = {};
      this.isUpdate = false;
      this.detailId = -1;
      this.checkEdit = true;
      this.customer_id = 0;
      this.isFirstCreate = false;
      this.toGetTemplate();
    },
	//获取列表
	getFormList(val){
		this.isShowList = true;
		this.itemData = {};
		this.formType = val;
		this.checkEdit = false;
		this.$refs.formTableList.getList(this.formType);
	},
    //重置列表信息
    resetFormList() {
      this.isShowList = true;
      this.detailId = -1;
      this.itemData = {};
      this.isUpdate = false;
      this.checkEdit = false;
      this.customer_id = 0;
    },
    //获取客户
    getCustom(data) {
      this.customer_id = data.user_id;
      this.customer = data;
    },
    //设置总数
    setTotal() {
      let lists = this.tableData.flat();
      let total = lists.reduce((total, item) => {
        return total + item.amount;
      }, 0);
      let totalData = this.templateData.formPage.product.totalData;
      totalData.subTotal = total;
      totalData.grandTotal =
        Math.floor(total * ((100 - totalData.discount) / 100) * 100) / 100;
    },
    //获取模板
    toGetTemplate() {
      let loading = this.openLoading();
      if (this.formType === 2) {
        this.setFormAllType(4);
        this.templateData = this.quoTemplateData;
        this.templateData.formPage.userMsg.tableData.data[0].detail.type =
          "custom";
        this.templateData.formPage.formSn.tableData.data[2].detail.value =
            this.getDate();
      } else {
        this.setFormAllType(1);
        this.templateData = this.inqTemplate;
        this.templateData.formPage.userMsg.tableData.data[0].detail.type =
          "unedit";
        this.templateData.formPage.userMsg.tableData.data[0].detail.value =
          this.info.enterprise;
        if (this.isFirstCreate) {
          let userMsg = localStorage.getItem("formUserMsg");
          if (userMsg) {
            this.templateData.formPage.userMsg = JSON.parse(userMsg);
          }
          this.templateData.formPage.formSn.tableData.data[2].detail.value =
            this.getDate();
          this.templateData.formPage.formSn.tableData.data[0].detail.value = `${this.info.last_name}${this.info.first_name}`;
        } else {
          this.templateData.formPage.formSn.tableData.data[2].detail.value = "";
        }
      }

      this.templateData.formPage.formSn.tableData.data[1].detail.type =
        "unedit";
      if (this.isCreate) {
        this.templateData.formPage.product.tableData = [this.createProduct];
      }
      let arr = [

      ];
      this.$set(this.tableData, 0, arr);
      loading.close();
    },
    //显示历史记录
    showHistory(){
      this.isShowHistory=true
    },
    //获取时间
    getDate() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      return y + "-" + m + "-" + d;
    },
    toFormDetail(data){
      this.isShowHistory=false
      this.isHistory=true
      this.getHistoryDetail(data)
    },
    //获取表单信息
    async getFormDetail() {
      let loading = this.openLoading();
      let data = {
        id:this.detailId,
      };
      this.isHistory=false
      if (this.formType != 5) {
        this.customer_id = 0;
        this.$api.getForm(data).then(async (res) => {
		  this.sn = res.sn;
          await this.detail(res)
          loading.close();
        });
      } else {
        this.isUpdate = true;
        this.$api.getCBDDetail(data).then((res) => {
          this.itemData = res.data;
          loading.close();
        });
      }
    },
    //获取历史记录详情
    getHistoryDetail(data){
      let loading = this.openLoading();
      let {id,type}=data
      let datas = {
        id,type
      };

      this.$api.getHistoryDetail(datas).then(async res=>{
        this.isUpdate = true;
        this.templateData = type===1?res.enquiryHistory.data:JSON.parse(res.quoteHistory.data);
        if(type===2){
          this.templateData.formHeader.data.title = "Quotation";
          this.templateData.formPage.formSn.tableData.data[1].title.value =
              "Quo No";
          this.templateData.formPage.userMsg.tableData.data[0].detail.type =
              "custom";
        }else{
          this.templateData.formPage.formSn.tableData.data[1].title.value =
              "Inq No";
        }
        await this.resetTable();
        loading.close();
      })
    },
    async detail(res){
	 
      if (!res.data.formHeader) {
        this.toGetTemplate();
      } else {
        this.isUpdate = true;
        res.data.formPage.formSn.tableData.data[1].detail.value = res.sn;
        this.templateData = res.data;
        this.customer_id = res.customer_id;
        this.customer = res.customer;
		console.log(this.formType);
        if (this.formType === 2) {
          this.setFormAllType(4);
          this.templateData.formHeader.data.title = "Quotation";
          this.templateData.formPage.formSn.tableData.data[1].title.value =
              "Quo No";
          this.templateData.formPage.userMsg.tableData.data[0].detail.type =
              "custom";
          
        } else {
          this.templateData.formPage.formSn.tableData.data[1].title.value =
              "Inq No";
        }

        await this.resetTable();
      }
      this.setCollapse(true);
      this.setFormAllType(res.state);
      this.itemData = res;
	  /* if ((this.userRole === "custom" && res.is_view === 0) || (this.userRole === "company" && res.sales_is_view === 0)) {
	    let datas = {
	      type: this.formType,
	      id: res.id,
	    };
	    this.$api.lookOver(datas).then((res) => {
	      this.getCount();
	    });
	  } */
    },
    //切换详情
    toDetail(data) {
		console.log(data);
	  this.isSelect = false;
	  if(this.userRole == 'custom'){
		  if(data.state < 5){
		  		  this.formType = 1;
		  }else if(data.state ==5){
		  		  this.formType = 2
		  }else if(data.state ==6){
		  		  this.formType = 3
		  }
	  }
      if (this.formType == 4) {
        const { id } = data;
        let url = this.$router.resolve({
          name: "recommendPreview",
          params: { id },
        });
        window.open(url.href, "_blank");
        return;
      }
      this.isShowList = false;
      this.detailId = data.id;
      this.getFormDetail();
    },
    //提交表单
    submitForm() {
      let data = {};
      this.templateData.formPage.product.tableData = this.tableData.flat();
      if (this.userRole === "custom" && this.formType == 2) {
        let lists = this.templateData.formPage.product.tableData;
        let check = lists.some((val) => {
          if (
            val.operation.type === "create" ||
            val.operation.type === "operation"
          )
            return true;
        });
        if (check) {
          let loading = this.openLoading();
          data = {
            type: 2,
            data: this.templateData,
            id: this.detailId,
          };
          this.$api.updateForm(data).then(async (res) => {
            this.$message.success("议价成功，请等待业务员确认");
            this.isShowList = true;
            await this.$refs.formTableList.getList();
            loading.close();
          });
        } else {
          data = {
            type: 3,
            data: this.templateData,
            form_id: this.detailId,
          };
          this.toChangeFormStatus(data, () => {
            this.$message.success("下单成功");
            this.$router.push({
              path: "lists",
              query: {
                type: 3,
              },
            });
          });
        }
      } else if (this.userRole === "company" && this.formType === 1) {
        let loading = this.openLoading();
        let items = {
          type: 2,
        };
        this.$api.getTemplate(items).then((res) => {
          res.data.formPage = this.$refs.exportPage[0].formData.formPage;
          let form = res.data;
          form.formPage.userMsg.tableData.data[0].title.value = "To";
          form.formPage.userMsg.tableData.data[0].detail.type = "custom";
          form.formPage.formSn.tableData.data[1].title.value = "Quo No";
          data = {
            type: 2,
            data: form,
            form_id: this.detailId,
          };
          this.toChangeFormStatus(data, (resData) => {
            this.detailId = resData.id;
            this.formType = 2;
            this.setFormState(this.formType);
            this.getFormDetail();
            loading.close();
          });
        });
      } else if (this.userRole === "company" && this.formType === 3) {
        data = {
          type: 3,
          state: 8,
          id: this.detailId,
        };
        this.$api.changeFormStatus(data).then(async (res) => {
          this.$message.success("确认订单成功");
          this.isShowList = true;
          await this.$refs.formTableList.getList();
        });
      } else {
        this.isChangeStatus = true;
        let lists = this.templateData.formPage.product.tableData;
        let checkList = true;
        if (lists.length === 1 && !lists[0].style_number) {
          checkList = false;
        }
        if (lists.length === 0 || !checkList) {
          this.$message.error("请选择商品");
          return;
        }
        if (this.isUpdate) {
          this.updateForm();
        } else {
          this.saveForm();
        }
      }
    },
    toSave() {
      this.isChangeStatus = false;
      if (this.formType === 5) {
        if (this.isUpdate) {
          this.toUpdateCBD();
        } else {
          this.toSaveCBD();
        }
      } else {
        if (this.isUpdate) {
          this.updateForm();
        } else {
          this.saveForm();
        }
      }
    },
    //上传图片
    uploadImg(pictureList) {
      return new Promise((resolve) => {
        uploadCos(pictureList, "works", (picture) => {
          resolve(picture);
        });
      });
    },
    //保存cbd
    async toSaveCBD() {
      let {
        allPartList: partList,
        allOtherList: otherList,
        allCostList: costList,
        allHeaderData: headerData,
        allTotalData: totalData,
      } = this.$refs.cbdPage;
      if (headerData.goodsImg) {
        let pic = await this.uploadImg([headerData.goodsImg]);
        headerData.goodsImg = pic[0];
      }
      let data = {
        partList,
        otherList,
        costList,
        headerData,
        totalData,
      };

      this.$api.saveCBD(data).then((res) => {
        this.toList();
      });
    },
    //更新cbd
    async toUpdateCBD() {
      let {
        allPartList: partList,
        allOtherList: otherList,
        allCostList: costList,
        allHeaderData: headerData,
        allTotalData: totalData,
      } = this.$refs.cbdPage;
      if (headerData.goodsImg && typeof headerData.goodsImg === "object") {
        let pic = await this.uploadImg([headerData.goodsImg]);
        headerData.goodsImg = pic[0];
      }
      let data = {
        id: this.detailId,
        partList,
        otherList,
        costList,
        headerData,
        totalData,
      };
      this.$api.updateCBD(data).then((res) => {
        this.toList();
      });
    },
    //返回列表
    toList() {
	  if(this.userRole == 'custom' && (this.formType == 1 || this.formType == 2)){
		  this.formType = 1;
	  }
      this.isShowList = true;
	  this.setCollapse(false)
      this.$refs.formTableList.getList();
    },
    //编辑
    toEdit() {
      let loading = this.openLoading();
      let data = {
        type: this.formType,
      };
      this.$api.getTemplate(data).then((res) => {
        this.templateData = res.data;
        this.checkEdit = true;
        loading.close();
      });
    },
    //提交编辑
    toSubmit() {
      let loading = this.openLoading();
      let data = {
        type: this.formType,
        data: this.$refs.exportPage.formData,
        // data: this.checkTemplate,
      };
      this.$api.updateTemplate(data).then((res) => {
        this.checkEdit = false;
        this.isShowList = true;
        this.toGetTemplate();
        loading.close();
      });
    },
    //切换表单状态
    toChangeFormStatus(data, callback) {
      let loading = this.openLoading();
      this.$api.saveForm(data).then(async (res) => {
        await callback(res);
        loading.close();
      });
    },
    //保存表单
    saveForm() {
      return new Promise((resolve, reject) => {
        let formData = this.templateData;
        let data = {
          type: this.formType,
          data: formData,
          form_id: 0,
          customer_id: this.templateData.customer_id,
          attach: "",
        };
        if (this.templateData.customer_id) {
          this.customer_id = this.templateData.customer_id;
        }
        if (this.formType === 2 && !this.customer_id) {
          this.$message.error("请选择客户");
          return false;
        }
        let loading = this.openLoading();
        this.$api.saveForm(data).then(async (res) => {
          localStorage.setItem(
            "formUserMsg",
            JSON.stringify(formData.formPage.userMsg)
          );
          this.detailId = res.id;
          await this.changeStatus(res);
          loading.close();
          resolve(true);
        });
      });
    },
    //更新表单
    updateForm() {
      let data = {
        type: this.formType,
        data: this.templateData,
        id: this.detailId,
        customer_id: this.templateData.customer_id,
      };
      let loading = this.openLoading();

      if (this.templateData.customer_id) {
        this.customer_id = this.templateData.customer_id;
      }
      if (this.formType === 2 && !this.customer_id) {
        this.$message.error("请选择客户");
        return false;
      }
      this.$api.updateForm(data).then(async (res) => {
        await this.changeStatus(res);
        loading.close();
      });
    },
    //切换状态
    async changeStatus(params) {
      if (this.isChangeStatus) {
        let data = {};
        if (this.formType === 1) {
          this.$message.success("询盘成功，请等待业务人员与您联系");
          data = {
            type: this.formType,
            state: 2,
            id: this.detailId,
          };
        } else if (this.formType === 2) {
          data = {
            type: this.formType,
            state: 5,
            id: this.detailId,
            customer_id: this.customer_id,
          };
          this.$message.success("报价成功，已将报价单发送客户");
        }
        this.$api.changeFormStatus(data).then(async (res) => {
          this.isShowList = true;
          await this.$refs.formTableList.getList();
        });
      } else {
        this.itemData = params;
        this.templateData.formPage.formSn.tableData.data[1].detail.value =
          params.sn;
        this.getFormDetail();
        this.$message.success("保存成功");
      }
    },
    //提交选中商品
    async submitProduct(data) {
      let list = data.data;

      let lists = list.filter(
        (val) =>
          !this.templateData.formPage.product.tableData.some(
            (item) => val.id === item.id
          )
      );
      this.templateData.formPage.product.tableData = [
        ...this.templateData.formPage.product.tableData,
        ...lists,
      ];
      await this.resetTable();
      // await this.$refs.selectGoodsDialog.clearGoods();
    },
    //重载表格
    resetTable() {
      this.$nextTick(() => {
        let list = Array.from(document.getElementsByClassName('getFormTableRowHeight'))
        this.tableData = [[]];
        let height = this.pageBottom + this.offsetTop + 55;
        list.forEach((val, key) => {
          height += val.clientHeight;
          if (height >= this.pageHeight) {
            this.tableData.push([
              this.deepClone(this.templateData.formPage.product.tableData[key]),
            ]);
            height = this.pageBottom + this.pageHeader + 55;
          } else {
            this.tableData[this.tableData.length - 1].push(
              this.deepClone(this.templateData.formPage.product.tableData[key])
            );
          }
        });
        this.lastPage = this.tableData.length - 1;
      });
    },
    deepClone(obj) {
      if (typeof obj === "object") {
        let result = Array.isArray(obj) ? [] : {};
        for (let i in obj) {
          result[i] = this.deepClone(obj[i]);
        }
        return result;
      } else {
        return obj;
      }
    },
    //插入数据
    setTableData() {
      this.tableData = [[]];
      this.templateData.formPage.product.tableData.forEach((val, key) => {
        this.tableData[this.tableData.length - 1].push(
          this.templateData.formPage.product.tableData[key]
        );
      });
      let list = document.getElementsByClassName("formTableRow");
    },
    addProductRow() {
      this.tableData[this.tableData.length - 1].push({
        style_number: "",
        img_url: "",
        intro: "",
        size: "",
        color: "",
        number: "",
        price: "",
        amount: "",
      });
    },
    //导出pdf
    toExportPdf() {
      if (this.isUpdate) {
        this.saveExportPdf();
      } else {
        this.saveForm().then((data) => {
          if (data) {
            this.saveExportPdf();
          }
        });
      }
    },
    saveExportPdf() {
      let that = this;
      let loading = this.openLoading();
      this.resetTable();
      // window.pageYoffset = 0;
      // document.documentElement.scrollTop = 0;
      // document.body.scrollTop = 0;
      setTimeout(() => {
        let doc = document.getElementById("exportPage");
        exportPdf(doc, that.itemData.sn ? that.itemData.sn : "单据").then(
          (res) => {
            that.isShowSlider = true;
            loading.close();
          }
        );
      }, 20);
    },
    getTop(data) {
      this.offsetTop = data.offsetTop;
      this.pageBottom = data.pageBottom;
      this.pageHeader = data.pageHeader;
      this.resetTable();
    },
    createPage() {
      let data = [
        {
          style: "",
          picture: "",
          description: "",
          size: "",
          color: "",
          qty: "",
          price: "",
          amount: "",
        },
      ];
      this.tableData.push(data);
      this.lastPage = this.tableData.length - 1;
      this.$refs.exportPage.checkCreate = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-right {
  /*overflow-x: scroll;*/
  /*overflow-y: hidden;*/
  /*padding-left: 14px;*/
  z-index: 1000;
  .form-right-center {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    position: relative;
    .hideGoods {
      width: 492px;
      transition: all 0.4s ease-in;
    }
    .hide-nav-width {
      width: 234px;
    }
  }
  .hideForm {
    display: block;
    position: absolute;
    top: -99999px;
    right: 0;
    z-index: -1000;
    height: auto;
    width: 1155px;
  }
  .hideTable {
    display: block;
    position: absolute;
    top: -88888px;
    right: 0;
    z-index: -1000;
    height: auto;
    width: 1155px;
  }
}
</style>

export default {
  namespaced: true,
  state: {
    formState: 1, //当前表单状态
    formNowType: 1, //当前表单详细状态
    partList: [
      {
        title: "鞋面",
        total: 0,
        list: [
          {
            part: "鞋头",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "领口",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "面前",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "鞋舌",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "鞋舌饰片",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "后跟饰片",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "眼套",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "后跟",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "鞋舌里",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "内里",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "眼扣",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "面前里",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "鞋舌泡棉",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "领口泡棉",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "中底板",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "鞋垫",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "鞋带",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
        ],
      },
      {
        title: "鞋底",
        total: 0,
        list: [
          {
            part: "底",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
        ],
      },
      {
        title: "辅料",
        total: 0,
        list: [
          {
            part: "线",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "胶水",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
        ],
      },
      {
        title: "包装成本",
        total: 0,
        list: [
          {
            part: "包装",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
        ],
      },
    ],
    otherPartList: [
      {
        title: "外协工艺",
        total: 0,
        list: [
          {
            part: "面前",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
          {
            part: "后跟饰片",
            texture_name: "",
            specification: "",
            net_usage: "",
            loss: "",
            price: "",
            cost: "",
            remark: "",
          },
        ],
      },
    ],
    costList: [
      {
        title: "人工成本",
        total: 0,
        list: [
          { label: "裁断单价", value: "" },
          { label: "手工单价", value: "" },
          { label: "针车单价", value: "" },
          { label: "包中底", value: "" },
          { label: "成型单价", value: "" },
        ],
      },
      {
        title: "制造成本",
        total: 0,
        list: [
          { label: "员工福利+管理人员工资", value: "" },
          { label: "厂房租金", value: "" },
          { label: "水电费", value: "" },
          { label: "折旧费", value: "" },
          { label: "开发费用", value: "" },
          { label: "运费", value: "" },
          { label: "社医保费", value: "" },
          { label: "检测费+其他", value: "" },
          { label: "模具", value: "" },
          { label: "其他合计(除模具)", value: "" },
        ],
      },
    ],
    headerData: {
      goodsImg: "",

      data: {
        custom: {
          label: "客人",
          value: "",
        },
        molding_method: {
          label: "成型方式",
          value: "",
        },
        order_sn: {
          label: "订单编号",
          value: "",
        },
        accounting_numbers: {
          label: "核算号码",
          value: "",
        },
        body_number: {
          label: "型体编号",
          value: "",
        },
        shoe_number: {
          label: "样鞋号码",
          value: "",
        },
        cut_number: {
          label: "斩刀编号",
          value: "",
        },
        order_size: {
          label: "订单尺码",
          value: "",
        },
        shoe_last: {
          label: "楦头",
          value: "",
        },
        order_number: {
          label: "订单双数",
          value: "",
        },
        block_number: {
          label: "底模编号",
          value: "",
        },
        empty: {
          label: "",
          value: "",
        },
      },
    },
    totalData: {
      total: {
        profit_total: 0,
        tax_profit_total: 0,
      },
      cost: {
        materials_cost: {
          label: "材料成本",
          value: "",
        },
        other_cost: {
          label: "外协工艺",
          value: "",
        },
        people_cost: {
          label: "人工成本",
          value: "",
        },
        create_cost: {
          label: "制造成本",
          value: "",
        },
        tax: {
          label: "税费",
          value: "",
          percent: 6,
        },
        profit: {
          label: "利润(P)",
          value: "",
          percent: 20,
        },
        current_material_ratio: {
          label: "现材料比率",
          value: "",
        },
        transaction_material_ratio: {
          label: "成交材料比率",
          value: "",
        },
      },
    },
  },
  mutations: {
    setFormState(state, type) {
      state.formState = type;
    },
    setFormAllType(state, type) {
      state.formNowType = type;
    },
  },
};

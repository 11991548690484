import { gid } from "@/views/crm/recommend/index";
export default {
  namespaced: true,
  state: {
    publicEnquiry: [],
    recommendPage: 0,
    recommend: [
      {
        id: gid(),
        column: 2,
        list: [
          {
            id: gid(),
            h: true,
          },
          {
            id: gid(),
            h: true,
          },
        ],
      },
    ],
  },
  mutations: {
    addProductFromLocal(state, payload = []) {
      const page = state.recommendPage;
      const list = state.recommend[page].list;
      const holder = list.filter((item) => item.h);
      const idx = [];
      let last = 1;
      list.forEach((item, i) => {
        if (item.h) {
          idx.push(i);
        }
      });

      if (holder && holder.length) {
        payload.forEach((p, index) => {
          if (index < idx.length) {
            state.recommend[page].list.splice(idx[index], 1, {
              id: gid(),
              desc: "",
              img: p,
              name: "",
              price: "",
            });
            last = index + 1;
          }
        });
      }

      for (let i = last; i < payload.length; i = i * 4) {
        let listItem = payload.slice(i, 4).map((img) => ({
          id: gid(),
          desc: "",
          name: "",
          price: "",
          img,
        }));

        const len = listItem.length;
        if (len < 4) {
          for (let i = 0; i < 4 - len; i++) {
            listItem.push({
              id: gid(),
              desc: "",
              name: "",
              price: "",
            });
          }
        }
        let pageItem = {
          id: gid(),
          column: 4,
          list: listItem,
        };

        state.recommend.push(pageItem);
      }
    },
    setPublicEnquiry(state, enquiry) {
      state.publicEnquiry = [...enquiry];
    },
    initRecommend(state, res) {
      state.recommend = res;
    },
    setCurrentPage(sate, page) {
      sate.recommendPage = page;
    },
    addPage(state, column = 2) {
      let list = new Array(column).fill({}).map(() => ({ id: gid(), h: true }));
      let page = {
        id: gid(),
        column: column,
        list,
      };
      state.recommend.push(page);
    },
    removePage(state, index) {
      state.recommend.splice(index, 1);
    },
    editRecommend(state, { idx, form }) {
      state.recommend.splice(idx, 1, {
        ...state.recommend[idx],
        ...form,
      });
    },
    addItem(state, { index, item, fn }) {
      const page = state.recommendPage;
      const list = state.recommend[page].list || [];
      const t = list.findIndex((t) => t.id == item.id);
      if (t >= 0) {
        fn && fn();
        return;
      }
      state.recommend[page].list.splice(index, 1, item);
    },
    hideHolder(state) {
      let res = state.recommend.filter((page) => page.list.filter((prod) => prod.img).length);
      state.recommend = res.map((page) => {
        const list = page.list.map((prod) => {
          const { h } = prod;
          return {
            ...prod,
            hide: h,
          };
        });
        return {
          ...page,
          list,
        };
      });
    },
    resetHolder(state) {
      state.recommend = state.recommend.map((page) => {
        const list = page.list.map((prod) => {
          return {
            ...prod,
            hide: false,
          };
        });
        return {
          ...page,
          list,
        };
      });
    },
    removeProduct(state, { index }) {
      const page = state.recommendPage;
      if (state.recommend[page].list.length == 2) {
        state.recommend[page].list.splice(index, 1, {
          id: gid(),
          h: true,
          hide: false,
        });
      } else {
        state.recommend[page].list.splice(index, 1);
      }
    },

    clearRecommend(state) {
      state.recommend = [
        {
          id: gid(),
          column: 2,
          list: [
            {
              id: gid(),
              h: true,
            },
            {
              id: gid(),
              h: true,
            },
          ],
        },
      ];
    },
  },
  getters: {
    publicEnquiryTotal(state) {
      return state.publicEnquiry.length;
    },
  },
};

export default ({request}) => ({
	//获取材料供应商
	getSupplierList({supplier_name='',page=1,page_size=10}){
		return request({
			url:'/v1/Supplier/getSupplierList',
			method:'post',
			data:{
				supplier_name,
				page,
				page_size
			}
		})
	},
	//创建供应商
	createSupplier(data){
		return request({
			url:'/v1/Supplier/createSupplier',
			method:'post',
			data:data
		})
	},
	//更新供应商信息
	updateSupplier(data){
		return request({
			url:'/v1/Supplier/updateSupplier',
			method:'post',
			data:data
		})
	},
	//供应商下拉框筛选
	getSupplierSelectList(){
		return request({
			url:'/v1/Supplier/getSupplierSelectList',
			method:'post',
			data:{
				supplier_name:''
			}
		})
	},
	//获取供应商信息
	getSupplierInfo(data){
		return request({
			url:'/v1/Supplier/getSupplierInfo',
			method:'post',
			data:data
		})
	},
	//删除供应商
	delSupplier({id=''}){
		return request({
			url:'/v1/Supplier/delSupplier',
			method:'post',
			data:{
				id
			}
		})
	},
	//创建材料
	createMaterial(data){
		return request({
			url:'/v1/Material/createMaterial',
			method:'post',
			data:data
		})
	},
	//更新材料
	updateMaterialData(data){
		return request({
			url:'/v1/Material/updateMaterial',
			method:'post',
			data:data
		})
	},
	//更新材料数量
	updateMaterialNum(data){
		return request({
			url:'/v1/Material/updateMaterialNum',
			method:'post',
			data:data
		})
	},
	//更新材料图片
	updateMaterialPicture(data){
		return request({
			url:'/v1/Material/updateMaterialPicture',
			method:'post',
			data:data
		})
	},
	//获取材料列表
	getMaterialListData({material_name='',supplier_id='',page=1,page_size=10}){
		return request({
			url:'/v1/Material/getMaterialList',
			method:'post',
			data:{
				material_name,
				supplier_id,
				page,
				page_size
			}
		})
	},
	//获取材料详情
	getMaterialInfo({id=''}){
		return request({
			url:'/v1/Material/getMaterialInfo',
			method:'post',
			data:{
				id
			}
		})
	},
	//删除材料
	delMaterialData({id=''}){
		return request({
			url:'/v1/material/delMaterial',
			method:'post',
			data:{
				id
			}
		})
	},
	//获取其它码段
	getOtherSize(data){
		return request({
			url:'/v1/material/getOtherSize',
			method:'post',
			data:data
		})
	}
})
export default {
	"11-0103TCX": "#EFEEE5",
	"11-0601TCX": "#F1F2F1",
	"11-0602TCX": "#F0F0EC",
	"11-0604TCX": "#EEEAE3",
	"11-4201TCX": "#F0F1F0",
	"11-4300TCX": "#EEEEE6",
	"11-4800TCX": "#E9EAE8",
	"11-0606TCX": "#F0EDE1",
	"11-0701TCX": "#F0EDE2",
	"12-0104TCX": "#E2DFCC",
	"12-0105TCX": "#D8D4C2",
	"12-5202TCX": "#DFDAC9",
	"13-0905TCX": "#DFD7C8",
	"13-4403TCX": "#CFCEC3",
	"11-0104TCX": "#EFEFDF",
	"11-0105TCX": "#EFEAD7",
	"11-0107TCX": "#F2EFDC",
	"11-0507TCX": "#F2EDD8",
	"12-0605TCX": "#DFD5BE",
	"12-0703TCX": "#E4DDC6",
	"12-0804TCX": "#E8E1C7",
	"12-0712TCX": "#F4E7C6",
	"12-0713TCX": "#F2E8C2",
	"12-0806TCX": "#ECE2BF",
	"12-0812TCX": "#F0E1BF",
	"12-0815TCX": "#F1E5BE",
	"13-0815TCX": "#E8D5A8",
	"13-0917TCX": "#ECD6A3",
	"12-0000TCX": "#E4DAC9",
	"12-0304TCX": "#E2D9CA",
	"12-1403TCX": "#DFD1BD",
	"13-0607TCX": "#D6CBB8",
	"13-0907TCX": "#DBD0BE",
	"13-0908TCX": "#DDD2BD",
	"13-1006TCX": "#DBCDB3",
	"11-0809TCX": "#F2E4D1",
	"11-0907TCX": "#F0E4D2",
	"11-1404TCX": "#F3E9E1",
	"12-0704TCX": "#EDE0CC",
	"12-0710TCX": "#F0E2C7",
	"12-1106TCX": "#F4EAE0",
	"12-1108TCX": "#EFE4D6",
	"11-0603TCX": "#E7DFD9",
	"11-1005TCX": "#EDE6E1",
	"11-1305TCX": "#F4E5DC",
	"11-1306TCX": "#F3E5DC",
	"11-2309TCX": "#F4E9E6",
	"11-2409TCX": "#EFE6E5",
	"12-2103TCX": "#EBE1DF",
	"11-4202TCX": "#EDEFEC",
	"11-4301TCX": "#E6E7E2",
	"11-4802TCX": "#E5EBE6",
	"11-4803TCX": "#E5EBE2",
	"12-4302TCX": "#E3E5DF",
	"12-5201TCX": "#DFE1D5",
	"12-6207TCX": "#DDE6DC",
	"11-4303TCX": "#E6EAE5",
	"11-4601TCX": "#E5EBEC",
	"11-4804TCX": "#E6EDE4",
	"11-4805TCX": "#DDEDE8",
	"12-4304TCX": "#E1E8E3",
	"12-4305TCX": "#D7E4E2",
	"12-5203TCX": "#D4DED9",
	"11-4604TCX": "#DFEAE9",
	"12-4306TCX": "#DEE2E1",
	"12-4705TCX": "#D6DFDC",
	"12-5303TCX": "#CDDBD7",
	"12-5403TCX": "#D2DEDA",
	"12-5508TCX": "#DBECE8",
	"12-5603TCX": "#D5DDD5",
	"12-0714TCX": "#F4DBB3",
	"12-0817TCX": "#F1D5A6",
	"12-0910TCX": "#E9D8B4",
	"13-0814TCX": "#EBD8B1",
	"13-0916TCX": "#EBD5A8",
	"13-1009TCX": "#DBCBAA",
	"14-1119TCX": "#E5C49E",
	"12-0311TCX": "#D5D5B8",
	"13-0611TCX": "#D4D0B1",
	"13-0711TCX": "#D6CEB1",
	"13-1007TCX": "#D7CEB3",
	"14-1014TCX": "#CAC1A0",
	"14-1108TCX": "#D4C9AC",
	"15-1216TCX": "#C0B290",
	"12-0404TCX": "#DBD9CE",
	"12-6204TCX": "#D7D7C8",
	"14-0105TCX": "#C3C0AA",
	"14-0210TCX": "#BBB59F",
	"14-6305TCX": "#BFBBAA",
	"14-6308TCX": "#BABAA4",
	"15-6307TCX": "#B4B5A5",
	"14-0108TCX": "#CAC5AE",
	"14-0708TCX": "#C2BAA5",
	"14-1107TCX": "#C1B9A5",
	"15-0309TCX": "#AFA893",
	"15-0513TCX": "#AEA88F",
	"16-1108TCX": "#A89E81",
	"16-1110TCX": "#A59977",
	"17-0610TCX": "#8B8A79",
	"17-1009TCX": "#968B77",
	"17-1107TCX": "#958D79",
	"17-1109TCX": "#9B9078",
	"17-1113TCX": "#8E826A",
	"17-1118TCX": "#84745A",
	"18-0617TCX": "#7D745E",
	"15-1306TCX": "#B6AA95",
	"16-1105TCX": "#A79E8B",
	"16-1106TCX": "#A29884",
	"17-0808TCX": "#88786A",
	"17-1310TCX": "#8A7D6B",
	"17-1312TCX": "#998A76",
	"17-1410TCX": "#847366",
	"14-0002TCX": "#C6C0B6",
	"16-0906TCX": "#ABA092",
	"16-1107TCX": "#9C9688",
	"16-1407TCX": "#A69A8A",
	"18-0513TCX": "#645D54",
	"18-1110TCX": "#7F7669",
	"18-1112TCX": "#766A5C",
	"13-0000TCX": "#D6D1C9",
	"13-0401TCX": "#CCC5B5",
	"13-0403TCX": "#C7C0B5",
	"13-5304TCX": "#CCC6BB",
	"14-1106TCX": "#C3BCAD",
	"15-1305TCX": "#B9B0A1",
	"16-0806TCX": "#A49C8E",
	"13-0002TCX": "#DCD7D4",
	"14-0000TCX": "#C6BFB8",
	"15-4503TCX": "#B6AEA5",
	"16-1305TCX": "#ADA59C",
	"16-1406TCX": "#A39890",
	"17-1210TCX": "#958D84",
	"17-1212TCX": "#8C8076",
	"14-4500TCX": "#C4C2B9",
	"14-4501TCX": "#C0BAAE",
	"15-6304TCX": "#AEAFA4",
	"16-0207TCX": "#A09E91",
	"17-0207TCX": "#8B8B80",
	"18-0510TCX": "#666C66",
	"18-4105TCX": "#7B7E79",
	"13-4303TCX": "#CBD0CF",
	"14-4102TCX": "#C6CBCC",
	"14-4103TCX": "#C0C4C3",
	"14-4201TCX": "#C8CACC",
	"14-4203TCX": "#BDBEBF",
	"15-4101TCX": "#ABB1B3",
	"16-4702TCX": "#959C99",
	"15-0000TCX": "#AEAAA5",
	"15-4502TCX": "#BAB3AD",
	"16-4402TCX": "#9A9B98",
	"16-5803TCX": "#9E9D98",
	"17-0205TCX": "#908D87",
	"17-1500TCX": "#827F7B",
	"17-1506TCX": "#887C77",
	"14-4503TCX": "#B5BFBB",
	"14-4804TCX": "#B8C0BB",
	"15-4003TCX": "#B2BAB6",
	"15-4702TCX": "#A4B0AC",
	"15-4703TCX": "#A1ADAA",
	"15-4704TCX": "#A9B3AE",
	"16-5904TCX": "#95A09A",
	"16-3801TCX": "#A49E9D",
	"17-1501TCX": "#8B8E8D",
	"17-4402TCX": "#898E8C",
	"18-0000TCX": "#66686A",
	"18-0306TCX": "#5A5D5A",
	"18-0503TCX": "#666766",
	"18-5105TCX": "#656D6D",
	"14-4002TCX": "#CAC7C6",
	"16-0000TCX": "#9E9D9C",
	"18-0201TCX": "#595E62",
	"18-0601TCX": "#6A6A6A",
	"18-4005TCX": "#6A6C70",
	"18-5203TCX": "#606263",
	"19-3908TCX": "#3F3E47",
	"16-3802TCX": "#A19E9D",
	"17-0000TCX": "#848487",
	"17-1502TCX": "#868485",
	"18-0403TCX": "#5D5A59",
	"18-3905TCX": "#615D65",
	"19-3903TCX": "#433D42",
	"19-3905TCX": "#585358",
	"17-0909TCX": "#807263",
	"18-0615TCX": "#635F4D",
	"18-0820TCX": "#675E45",
	"19-0618TCX": "#544B32",
	"19-0809TCX": "#675B4E",
	"19-0810TCX": "#5B554A",
	"19-0820TCX": "#5C5244",
	"19-0405TCX": "#42413E",
	"19-0506TCX": "#3C3E38",
	"19-0508TCX": "#3E3F3E",
	"19-0608TCX": "#413D38",
	"19-0614TCX": "#41382E",
	"19-0822TCX": "#524D40",
	"19-5708TCX": "#303737",
	"18-1306TCX": "#6E605D",
	"19-0000TCX": "#353635",
	"19-0812TCX": "#403935",
	"19-1101TCX": "#353030",
	"19-1102TCX": "#343132",
	"19-1111TCX": "#3D3434",
	"19-3803TCX": "#5C595B",
	"19-0303TCX": "#343333",
	"19-4005TCX": "#2E3238",
	"19-4006TCX": "#2C2F32",
	"19-4218TCX": "#2D3036",
	"19-4203TCX": "#2E3235",
	"19-4205TCX": "#34373B",
	"19-4305TCX": "#2F3435",
	"12-0811TCX": "#F0D9BC",
	"12-0813TCX": "#F1D6B4",
	"12-1009TCX": "#F6DDCB",
	"13-1010TCX": "#ECD5B8",
	"13-1014TCX": "#DFC2A0",
	"14-1120TCX": "#E7C7A5",
	"14-1122TCX": "#DBB68F",
	"14-1116TCX": "#D1B893",
	"14-1118TCX": "#D4B895",
	"15-1220TCX": "#C4A87F",
	"15-1225TCX": "#CDAA7F",
	"16-1333TCX": "#BA9065",
	"16-1334TCX": "#BA9972",
	"17-1328TCX": "#AD8460",
	"15-1116TCX": "#B8A98D",
	"15-1213TCX": "#BCA686",
	"15-1214TCX": "#C0AB8C",
	"15-1314TCX": "#C5A98E",
	"16-1212TCX": "#B19F87",
	"16-1310TCX": "#AE927C",
	"16-1320TCX": "#B89F86",
	"13-0513TCX": "#DAD4B8",
	"13-1008TCX": "#DDCDB5",
	"14-1112TCX": "#CDBB99",
	"16-0924TCX": "#BFA57E",
	"16-1010TCX": "#AB9677",
	"16-1315TCX": "#A69373",
	"17-1320TCX": "#A7896A",
	"14-0615TCX": "#CBC9A4",
	"18-1048TCX": "#714C30",
	"15-1217TCX": "#C6B694",
	"16-1324TCX": "#B59A6D",
	"17-0935TCX": "#846D3D",
	"17-1022TCX": "#9A8A68",
	"17-1028TCX": "#937E57",
	"17-1044TCX": "#966E4B",
	"17-1134TCX": "#9F744D",
	"17-1327TCX": "#967353",
	"18-1027TCX": "#664D3B",
	"18-1031TCX": "#715138",
	"18-1033TCX": "#76563C",
	"18-1124TCX": "#6F5241",
	"18-1130TCX": "#765446",
	"18-1222TCX": "#6B5043",
	"19-1121TCX": "#5E473C",
	"19-1217TCX": "#694D42",
	"19-1218TCX": "#584239",
	"19-1230TCX": "#6E493B",
	"18-0920TCX": "#716047",
	"18-0928TCX": "#6C5740",
	"18-0930TCX": "#6A5542",
	"18-1018TCX": "#7C654C",
	"18-1022TCX": "#7F6747",
	"19-0617TCX": "#615442",
	"19-0815TCX": "#574634",
	"18-1015TCX": "#716151",
	"18-1016TCX": "#685A48",
	"19-0712TCX": "#423933",
	"19-0814TCX": "#473A2F",
	"19-0912TCX": "#443731",
	"19-1020TCX": "#574333",
	"19-1116TCX": "#564235",
	"18-1312TCX": "#715C56",
	"19-0915TCX": "#3C3230",
	"19-1015TCX": "#4E423D",
	"19-1016TCX": "#413532",
	"19-1118TCX": "#55433C",
	"19-1213TCX": "#523F3B",
	"19-1314TCX": "#413433",
	"12-0807TCX": "#EBD4BF",
	"12-1005TCX": "#E9D4BF",
	"12-1006TCX": "#EBDAC7",
	"12-1007TCX": "#E8D1BD",
	"13-1011TCX": "#DEC6AC",
	"13-1013TCX": "#E4C4A7",
	"14-1210TCX": "#D7C3AC",
	"12-0601TCX": "#ECE3D6",
	"13-1106TCX": "#DFCFBE",
	"13-1108TCX": "#E4C8B7",
	"14-1209TCX": "#CBB7A2",
	"15-1215TCX": "#BBA68C",
	"15-1308TCX": "#BEAE9A",
	"16-1210TCX": "#AF9E89",
	"16-1318TCX": "#B29784",
	"16-1412TCX": "#A48D7F",
	"16-1414TCX": "#A28974",
	"16-1415TCX": "#A68D7B",
	"17-1319TCX": "#9B836C",
	"17-1321TCX": "#917560",
	"17-1418TCX": "#957D6D",
	"14-1212TCX": "#D1B7A1",
	"15-1309TCX": "#C1AE9C",
	"15-1315TCX": "#C7A994",
	"16-1221TCX": "#B3937F",
	"17-1223TCX": "#AA8971",
	"17-1322TCX": "#A07F66",
	"17-1417TCX": "#947563",
	"14-1213TCX": "#D2B29A",
	"16-1323TCX": "#B1886E",
	"16-1327TCX": "#BB8364",
	"16-1331TCX": "#CC9976",
	"17-1224TCX": "#AD8166",
	"17-1225TCX": "#B0876D",
	"17-1226TCX": "#A9836A",
	"12-0911TCX": "#F4D5BB",
	"12-0912TCX": "#F8D6B8",
	"12-0913TCX": "#F4D0B5",
	"12-0915TCX": "#FBD1BB",
	"12-1011TCX": "#F3D1B9",
	"13-1114TCX": "#F3C9AE",
	"14-1217TCX": "#DEB99D",
	"12-1008TCX": "#F0D8C3",
	"12-1010TCX": "#F9D8CA",
	"12-1107TCX": "#F1DDCF",
	"12-1206TCX": "#E9D4CA",
	"12-1209TCX": "#F2DCD0",
	"13-1404TCX": "#EFD1C6",
	"14-1307TCX": "#CFB4A8",
	"12-1404TCX": "#DBCCBE",
	"13-1107TCX": "#DECFC2",
	"13-1405TCX": "#E7D7CD",
	"14-1311TCX": "#E0B9AD",
	"15-1317TCX": "#C29E85",
	"16-1317TCX": "#B99B86",
	"17-1227TCX": "#A9806A",
	"14-1310TCX": "#D7B7AA",
	"14-1312TCX": "#E6BFB3",
	"14-1313TCX": "#DDB3A3",
	"14-1314TCX": "#E2BDAB",
	"15-1316TCX": "#C9A48E",
	"16-1219TCX": "#C09985",
	"16-1422TCX": "#B88771",
	"13-1109TCX": "#EBC7B2",
	"14-1220TCX": "#E7AF8F",
	"15-1318TCX": "#DDAD95",
	"15-1319TCX": "#E5B49A",
	"15-1322TCX": "#D09B81",
	"15-1327TCX": "#DF9D7B",
	"16-1220TCX": "#C49580",
	"16-1235TCX": "#BC8A65",
	"16-1336TCX": "#B6855A",
	"16-1341TCX": "#C48F65",
	"16-1432TCX": "#AB784E",
	"17-1137TCX": "#A67247",
	"17-1330TCX": "#A17452",
	"18-1030TCX": "#916B53",
	"17-1143TCX": "#AE734E",
	"17-1230TCX": "#A37762",
	"17-1336TCX": "#A86F49",
	"17-1340TCX": "#A4623C",
	"17-1430TCX": "#A57053",
	"18-1142TCX": "#935635",
	"18-1154TCX": "#935931",
	"16-1328TCX": "#C78D6B",
	"16-1439TCX": "#BF7651",
	"17-1147TCX": "#A6674C",
	"18-1140TCX": "#8A553F",
	"18-1239TCX": "#9B5E45",
	"18-1244TCX": "#8A4E36",
	"19-1241TCX": "#774B3A",
	"16-1332TCX": "#C78362",
	"16-1429TCX": "#B16E52",
	"17-1347TCX": "#B0654B",
	"17-1436TCX": "#B86E53",
	"18-1248TCP": "#B0583A",
	"18-1250TCX": "#9F5235",
	"18-1450TCX": "#BB5B46",
	"13-1012TCX": "#CDB8A0",
	"14-1012TCX": "#B49B80",
	"14-5002TCX": "#999B9B",
	"15-0927TCX": "#B78B5F",
	"16-0836TCX": "#B49B57",
	"16-1325TCX": "#AD6B4E",
	"18-1537TCX": "#A45641",
	"17-1422TCX": "#8F6E5D",
	"18-1137TCX": "#855E4B",
	"18-1229TCX": "#865E4F",
	"18-1235TCX": "#8D5D4F",
	"18-1314TCX": "#7C6054",
	"18-1320TCX": "#855E53",
	"18-1321TCX": "#8D6F60",
	"18-1230TCX": "#834B39",
	"18-1238TCX": "#875445",
	"18-1242TCX": "#885441",
	"18-1336TCX": "#986358",
	"18-1433TCX": "#96594D",
	"18-1441TCX": "#A05747",
	"19-1333TCX": "#804A3D",
	"19-1220TCX": "#65443C",
	"19-1228TCX": "#734E46",
	"19-1235TCX": "#60413A",
	"19-1320TCX": "#714442",
	"19-1430TCX": "#724C44",
	"19-1431TCX": "#64413D",
	"19-1436TCX": "#6A443E",
	"18-1326TCX": "#7D5C53",
	"18-1425TCX": "#886159",
	"19-1012TCX": "#594440",
	"19-1317TCX": "#503636",
	"19-1321TCX": "#593738",
	"19-1322TCX": "#593E3E",
	"19-1420TCX": "#543B39",
	"19-1245TCX": "#89463A",
	"19-1325TCX": "#6B4140",
	"19-1327TCX": "#623A3D",
	"19-1331TCX": "#6C3A3C",
	"19-1334TCX": "#7F463F",
	"19-1338TCX": "#753A3C",
	"11-0510TCX": "#F5EED3",
	"11-0617TCX": "#F6EEC4",
	"12-0715TCX": "#F4E1AD",
	"13-0822TCX": "#F1DAA4",
	"13-0922TCX": "#E3CB91",
	"14-0935TCX": "#DEC280",
	"14-1031TCX": "#D5B26E",
	"12-0619TCX": "#D5D09C",
	"13-0613TCX": "#DCD0AC",
	"13-0715TCX": "#DBCDA7",
	"13-0915TCX": "#DECDA2",
	"14-0925TCX": "#D7C793",
	"14-1110TCX": "#D3C09B",
	"15-0719TCX": "#BDAC7E",
	"12-0626TCX": "#DBD590",
	"13-0624TCX": "#D7D190",
	"13-0725TCX": "#DECA85",
	"14-0740TCX": "#D6B450",
	"14-0826TCX": "#D3BE7D",
	"15-0643TCX": "#BEAD3B",
	"16-0847TCX": "#AD8D30",
	"13-0640TCX": "#DDD161",
	"14-0626TCX": "#CDBC7E",
	"14-0647TCX": "#D3C54D",
	"14-0755TCX": "#E4BB25",
	"15-0743TCX": "#C5A646",
	"16-0742TCX": "#AF8E31",
	"17-0839TCX": "#AF8D1F",
	"14-0721TCX": "#C5B47F",
	"14-1025TCX": "#CCB77B",
	"15-0636TCX": "#BAB160",
	"15-0730TCX": "#BEA968",
	"15-0732TCX": "#C4AD63",
	"16-0730TCX": "#B69F5E",
	"16-0737TCX": "#A89A51",
	"11-0616TCX": "#F3E6AD",
	"11-0618TCX": "#F0EEB0",
	"11-0620TCX": "#EFED98",
	"11-0710TCX": "#EFEEB2",
	"12-0721TCX": "#F2E89A",
	"12-0722TCX": "#F0E1A5",
	"12-0740TCX": "#F3ED7C",
	"12-0633TCX": "#E1DE7D",
	"12-0738TCX": "#F1DB6D",
	"13-0632TCX": "#D8D37D",
	"13-0720TCX": "#EAD98B",
	"13-0739TCX": "#E1C564",
	"14-0636TCX": "#D6CE7B",
	"14-0827TCX": "#E5CD7F",
	"12-0642TCX": "#F4DF5B",
	"12-0643TCX": "#F8E72C",
	"12-0752TCX": "#FBE337",
	"13-0648TCX": "#DFD34F",
	"13-0746TCX": "#F2D041",
	"13-0752TCX": "#F5C71A",
	"14-0756TCX": "#F6D300",
	"13-0758TCX": "#FFD100",
	"13-0850TCX": "#FFD858",
	"13-0858TCX": "#FFDC01",
	"13-0859TCX": "#FFC600",
	"14-0760TCX": "#FFD300",
	"14-0848TCX": "#EFC050",
	"14-0852TCX": "#F6C324",
	"12-0720TCX": "#F1DE9C",
	"12-0727TCX": "#FDE182",
	"12-0736TCX": "#F8D974",
	"12-0824TCX": "#F9E397",
	"12-0825TCX": "#F9E18E",
	"13-0755TCX": "#F3CF55",
	"14-0754TCX": "#E8C13F",
	"14-0837TCX": "#DBB960",
	"15-0751TCX": "#CCA01D",
	"15-0850TCX": "#D4AB31",
	"15-0942TCX": "#C29E4D",
	"16-0946TCX": "#BC9337",
	"16-0953TCX": "#C4941F",
	"16-0954TCX": "#BC8B1F",
	"15-0948TCX": "#C59636",
	"15-0953TCX": "#CC8F1C",
	"15-1132TCX": "#C9A969",
	"16-0948TCX": "#B48834",
	"16-0952TCX": "#CA981E",
	"16-1126TCX": "#AB915C",
	"16-1133TCX": "#B29051",
	"14-1036TCX": "#D8AC59",
	"15-1046TCX": "#D69E3C",
	"15-1142TCX": "#D5A149",
	"16-0945TCX": "#C4994B",
	"16-0947TCX": "#D1A14A",
	"16-0950TCX": "#C69241",
	"16-1139TCX": "#BC8E48",
	"14-1113TCX": "#DAC29C",
	"16-0928TCX": "#BC9C69",
	"16-1326TCX": "#B59964",
	"17-1047TCX": "#B88E4D",
	"17-1129TCX": "#A77E3D",
	"18-0937TCX": "#815F34",
	"18-0940TCX": "#91672C",
	"17-0942TCX": "#967444",
	"17-1036TCX": "#927141",
	"17-1045TCX": "#AE8657",
	"17-1125TCX": "#9A794B",
	"17-1128TCX": "#9A7240",
	"18-0939TCX": "#916F3B",
	"19-1034TCX": "#755C3B",
	"13-0840TCX": "#FFDA71",
	"13-0941TCX": "#FFD372",
	"14-0846TCX": "#E3B04B",
	"14-0850TCX": "#FFC849",
	"14-0951TCX": "#E9A916",
	"14-0957TCX": "#FFC007",
	"15-0955TCX": "#EFAD00",
	"12-0826TCX": "#FCDB95",
	"13-0932TCX": "#EEC374",
	"13-0939TCX": "#F3BC63",
	"13-0940TCX": "#FAC668",
	"14-0847TCX": "#F3C36B",
	"14-0936TCX": "#E4C389",
	"14-1038TCX": "#DAB77E",
	"13-0935TCX": "#FFCB7C",
	"13-0942TCX": "#FBB755",
	"13-0945TCX": "#FFC864",
	"13-1025TCX": "#FDD597",
	"14-0941TCX": "#EEA94A",
	"14-1041TCX": "#E0AA5A",
	"14-1045TCX": "#F1AE51",
	"13-0947TCX": "#FFBC4F",
	"14-0955TCX": "#FFB025",
	"15-1049TCX": "#E9A23B",
	"15-1050TCX": "#DE9E36",
	"16-1054TCX": "#D39027",
	"18-0935TCX": "#A87226",
	"18-0950TCX": "#9A6734",
	"16-0940TCX": "#C69C64",
	"16-1143TCX": "#CC9350",
	"16-1144TCX": "#D09D5D",
	"17-1040TCX": "#BF8B45",
	"17-1048TCX": "#BD7B27",
	"18-0933TCX": "#826142",
	"18-1160TCX": "#A96D35",
	"12-0921TCX": "#E7BD8E",
	"13-1015TCX": "#DFC19C",
	"13-1016TCX": "#E3C7A6",
	"13-1018TCX": "#E5BD8E",
	"13-1024TCX": "#EDC594",
	"14-1127TCX": "#E1B587",
	"15-1231TCX": "#D5A372",
	"12-0822TCX": "#F7D29E",
	"13-1027TCX": "#F5BF85",
	"13-1030TCX": "#FBC58C",
	"13-1031TCX": "#FED29C",
	"14-1051TCX": "#FFB75E",
	"14-1128TCX": "#FFBE79",
	"15-1145TCX": "#F7AE62",
	"14-1050TCX": "#FFAD4A",
	"15-1147TCX": "#E09540",
	"16-1140TCX": "#D38B3B",
	"16-1142TCX": "#E09C53",
	"16-1148TCX": "#CF8943",
	"16-1342TCX": "#D38E4F",
	"17-1046TCX": "#C1752E",
	"14-1064TCX": "#FFAC00",
	"14-1159TCX": "#FFA326",
	"15-1054TCX": "#F19B23",
	"15-1058TCX": "#FB9D18",
	"15-1062TCX": "#FFB100",
	"15-1150TCX": "#E68515",
	"15-1153TCX": "#F2932E",
	"13-1020TCX": "#FFC49D",
	"14-1133TCX": "#F0AB73",
	"15-1234TCX": "#E09D66",
	"15-1237TCX": "#DE965C",
	"16-1150TCX": "#D3833D",
	"16-1346TCX": "#CA7A42",
	"16-1443TCX": "#CF7D49",
	"14-1135TCX": "#FFB17E",
	"14-1139TCX": "#FAA669",
	"14-1231TCX": "#FFB481",
	"15-1242TCX": "#F0955F",
	"15-1245TCX": "#FFA668",
	"16-1337TCX": "#D78257",
	"16-1338TCX": "#E28C61",
	"16-1253TCX": "#DD7A39",
	"16-1255TCX": "#E5761F",
	"16-1260TCX": "#D26537",
	"16-1350TCX": "#DC793D",
	"16-1448TCX": "#C7622B",
	"16-1454TCX": "#D96D39",
	"17-1353TCX": "#C3653C",
	"15-1157TCX": "#FC8A17",
	"15-1160TCX": "#FFA44A",
	"15-1164TCX": "#FF8D00",
	"15-1263TCX": "#FF8400",
	"16-1257TCX": "#F38235",
	"16-1356TCX": "#F3741F",
	"17-1350TCX": "#FF770D",
	"15-1247TCX": "#F79256",
	"16-1343TCX": "#F48850",
	"16-1357TCX": "#FF894D",
	"16-1359TCX": "#FE7D31",
	"16-1364TCX": "#FF6D2D",
	"16-1459TCX": "#E9693D",
	"16-1462TCX": "#F2672E",
	"16-1349TCX": "#F27245",
	"16-1360TCX": "#FF8856",
	"16-1361TCX": "#F86A38",
	"16-1362TCX": "#FC6336",
	"16-1452TCX": "#F16B46",
	"17-1462TCX": "#F4512C",
	"17-1464TCX": "#EF5327",
	"12-0917TCX": "#FED1B0",
	"13-1017TCX": "#F5CBA9",
	"13-1019TCX": "#FCC79D",
	"13-1022TCX": "#F5C39A",
	"13-1023TCX": "#FFC196",
	"13-1026TCX": "#FFD4B2",
	"14-1225TCX": "#FAC09A",
	"13-1021TCX": "#FCC1A2",
	"14-1224TCX": "#EFAC87",
	"14-1230TCX": "#F9B086",
	"15-1333TCX": "#E29276",
	"16-1340TCX": "#D1805F",
	"16-1435TCX": "#D1775C",
	"17-1446TCX": "#B75E4A",
	"14-1227TCX": "#F2AD86",
	"15-1239TCX": "#FFA480",
	"15-1331TCX": "#FBA481",
	"15-1334TCX": "#EB9776",
	"15-1340TCX": "#F5926C",
	"16-1344TCX": "#E67D53",
	"16-1442TCX": "#F78766",
	"16-1440TCX": "#C76753",
	"16-1441TCX": "#D16A4F",
	"16-1450TCX": "#DF6E51",
	"17-1444TCX": "#C75F4E",
	"18-1354TCX": "#B44E3D",
	"18-1447TCX": "#C35D48",
	"18-1448TCX": "#BF5346",
	"18-1343TCX": "#9C5849",
	"18-1444TCX": "#9D4844",
	"18-1451TCX": "#B05846",
	"18-1535TCX": "#B86050",
	"18-1540TCX": "#994541",
	"18-1547TCX": "#93302C",
	"19-1250TCX": "#8D3D2E",
	"13-1318TCX": "#FFC7B6",
	"14-1219TCX": "#FBC1AC",
	"14-1316TCX": "#E1AD9D",
	"14-1318TCX": "#EAAC9C",
	"15-1523TCX": "#E09A88",
	"16-1330TCX": "#D19584",
	"17-1341TCX": "#CE806C",
	"16-1329TCX": "#E1907F",
	"16-1431TCX": "#CE8276",
	"16-1526TCX": "#CE7F75",
	"17-1524TCX": "#BA7872",
	"17-1525TCX": "#A3695F",
	"18-1435TCX": "#A26967",
	"18-1436TCX": "#B17069",
	"16-1522TCX": "#C4867C",
	"17-1424TCX": "#B36F68",
	"17-1514TCX": "#B27F7F",
	"17-1518TCX": "#B27A78",
	"17-1520TCX": "#B06F6B",
	"18-1438TCX": "#955251",
	"18-1630TCX": "#AC5D5E",
	"17-1532TCX": "#AD6659",
	"17-1540TCX": "#C46858",
	"18-1346TCX": "#AD584D",
	"18-1350TCX": "#A04E42",
	"18-1434TCX": "#A05851",
	"18-1443TCX": "#A45A52",
	"18-1536TCX": "#AC554E",
	"18-1442TCX": "#8F413D",
	"18-1449TCX": "#933D3A",
	"18-1531TCX": "#8C4443",
	"18-1629TCX": "#BA6364",
	"18-1648TCX": "#AF4249",
	"18-1658TCX": "#A22E37",
	"19-1540TCX": "#7D3C38",
	"14-1324TCX": "#FCB3A8",
	"14-1418TCX": "#FABCB1",
	"14-1419TCX": "#F9B5A9",
	"14-1420TCX": "#FAB1A9",
	"16-1434TCX": "#E29892",
	"16-1520TCX": "#DE938E",
	"16-1624TCX": "#D97A78",
	"14-1228TCX": "#FFBBA2",
	"14-1323TCX": "#F6AC97",
	"15-1423TCX": "#FA9E93",
	"15-1435TCX": "#FF9A90",
	"15-1530TCX": "#F99584",
	"16-1529TCX": "#ED8A81",
	"16-1532TCX": "#D4776C",
	"15-1433TCX": "#FEA188",
	"16-1539TCX": "#EC7266",
	"16-1542TCX": "#FF8371",
	"16-1543TCX": "#FF8272",
	"16-1544TCX": "#F5786B",
	"16-1546TCX": "#FA7268",
	"17-1656TCX": "#ED5656",
	"16-1632TCX": "#F28085",
	"16-1640TCX": "#EC707B",
	"16-1641TCX": "#F27377",
	"17-1643TCX": "#E46A6D",
	"17-1644TCX": "#D66467",
	"17-1647TCX": "#EA636B",
	"18-1649TCX": "#DC5D61",
	"17-1545TCX": "#B7484F",
	"17-1654TCX": "#CB3145",
	"17-1641TCX": "#BA4653",
	"18-1643TCX": "#A53E4B",
	"18-1651TCX": "#DA4A52",
	"18-1762TCX": "#D53547",
	"17-1456TCX": "#E15D44",
	"17-1558TCX": "#DA413D",
	"17-1562TCX": "#E74D3A",
	"17-1563TCX": "#E34132",
	"17-1564TCX": "#DC443A",
	"18-1445TCX": "#DB4834",
	"18-1561TCX": "#D5392C",
	"16-1451TCX": "#F6624C",
	"16-1541TCX": "#F77463",
	"17-1544TCX": "#C15950",
	"17-1547TCX": "#E4665C",
	"17-1553TCX": "#C84B45",
	"18-1454TCX": "#BD4538",
	"18-1555TCX": "#B82928",
	"17-1663TCX": "#D6394B",
	"18-1664TCX": "#DB3844",
	"18-1550TCX": "#B93A3E",
	"18-1660TCX": "#CD2A3E",
	"18-1662TCX": "#CE2B37",
	"18-1763TCX": "#C81D31",
	"18-1652TCX": "#BF384A",
	"18-1661TCX": "#C3374D",
	"18-1761TCX": "#B5123F",
	"18-1764TCX": "#C52242",
	"19-1760TCX": "#B52C45",
	"19-1762TCX": "#AD1C42",
	"19-1764TCX": "#B02541",
	"18-1655TCX": "#BC2A3A",
	"18-1663TCX": "#BE2239",
	"19-1557TCX": "#9B2335",
	"19-1663TCX": "#B8283D",
	"19-1664TCX": "#BC243C",
	"19-1761TCX": "#AD1236",
	"19-1763TCX": "#B81B36",
	"14-1513TCX": "#EEB0AE",
	"14-1521TCX": "#F5A8A5",
	"14-1714TCX": "#F1ACB0",
	"15-1621TCX": "#F6A1A0",
	"15-1717TCX": "#EEA2A9",
	"16-1626TCX": "#E08087",
	"16-1720TCX": "#E3868F",
	"15-1821TCX": "#F496A1",
	"16-1723TCX": "#E8768F",
	"16-1735TCX": "#F97394",
	"17-1927TCX": "#D0697B",
	"17-1928TCX": "#E87191",
	"17-1929TCX": "#CD657F",
	"17-1930TCX": "#F16085",
	"15-1624TCX": "#FB8C98",
	"15-1626TCX": "#FF8894",
	"15-1922TCX": "#F58FA3",
	"16-1620TCX": "#DB6D7B",
	"16-1731TCX": "#F87B90",
	"17-1736TCX": "#E5697B",
	"17-1744TCX": "#EC5D73",
	"17-1753TCX": "#D73E5A",
	"17-1755TCX": "#E63E62",
	"17-1842TCX": "#D3305D",
	"18-1754TCX": "#D72D5C",
	"18-1755TCX": "#E44165",
	"18-1756TCX": "#DC3359",
	"18-1856TCX": "#C51554",
	"17-1740TCX": "#C14A64",
	"18-1741TCX": "#B63157",
	"18-1760TCX": "#B61745",
	"18-1852TCX": "#C21E56",
	"18-1945TCX": "#BE185A",
	"19-1860TCX": "#A1224A",
	"19-1955TCX": "#9F1F4C",
	"13-2806TCX": "#EEC2DA",
	"14-2311TCX": "#EFA5C8",
	"14-2710TCX": "#EAB0CE",
	"15-2214TCX": "#E38FB7",
	"15-2215TCX": "#E99ABE",
	"15-2718TCX": "#E08BBB",
	"17-2520TCX": "#C66091",
	"15-1920TCX": "#EB81A2",
	"15-2216TCX": "#F388B3",
	"15-2217TCX": "#E680AA",
	"17-2120TCX": "#D27497",
	"16-2126TCX": "#E6679A",
	"17-2127TCX": "#D75F8D",
	"17-1831TCX": "#BB466F",
	"17-1937TCX": "#E95985",
	"17-2031TCX": "#C3447A",
	"17-2033TCX": "#DE5285",
	"18-2043TCX": "#CC3A71",
	"18-2120TCX": "#D65076",
	"18-2143TCX": "#CB2F70",
	"16-2124TCX": "#EE79A5",
	"17-2036TCX": "#D0417E",
	"17-2227TCX": "#BE427B",
	"17-2230TCX": "#DD5A91",
	"18-2133TCX": "#CD4E7C",
	"18-2336TCX": "#BA397B",
	"18-2436TCX": "#CC397B",
	"17-2624TCX": "#C24C92",
	"17-2625TCX": "#CF6BA9",
	"17-2627TCX": "#D361A3",
	"18-2326TCX": "#AB3F76",
	"18-2328TCX": "#B33A7F",
	"18-2333TCX": "#C7458B",
	"18-2525TCX": "#9F4576",
	"11-2511TCX": "#F2E1E6",
	"12-2904TCX": "#EFDADD",
	"14-1508TCX": "#DCB5B4",
	"14-1511TCX": "#EDB8BA",
	"16-1610TCX": "#CB868F",
	"16-1617TCX": "#D0868E",
	"17-1718TCX": "#B77B82",
	"12-1304TCX": "#F5DDDA",
	"12-2102TCX": "#EBDBDB",
	"12-2902TCX": "#E4D3D2",
	"15-1611TCX": "#D7A2A4",
	"15-1614TCX": "#D0959E",
	"18-1634TCX": "#AF5263",
	"18-1635TCX": "#B55868",
	"17-1537TCX": "#AE5459",
	"17-1633TCX": "#B34A5E",
	"18-1633TCX": "#B04E5D",
	"19-1656TCX": "#852839",
	"19-1759TCX": "#A33546",
	"19-1862TCX": "#98243A",
	"19-1940TCX": "#7A2F40",
	"18-1631TCX": "#994758",
	"19-1532TCX": "#833B3F",
	"19-1543TCX": "#8C3D45",
	"19-1650TCX": "#7D303D",
	"19-1655TCX": "#943543",
	"19-1840TCX": "#98364A",
	"19-1934TCX": "#7D3443",
	"18-1426TCX": "#864E51",
	"19-1524TCX": "#704147",
	"19-1530TCX": "#853E4A",
	"19-1533TCX": "#894A4C",
	"19-1629TCX": "#77353D",
	"19-1725TCX": "#672E3B",
	"19-1726TCX": "#6A323F",
	"12-1207TCX": "#F3D1C8",
	"12-1212TCX": "#F8D1CC",
	"13-1310TCX": "#F5C5C2",
	"13-1406TCX": "#F0D3C9",
	"13-1407TCX": "#F6D7D0",
	"13-1504TCX": "#E6CFC8",
	"14-1905TCX": "#E5C3C3",
	"11-1408TCX": "#F3DDDB",
	"14-1309TCX": "#DDC0B9",
	"14-1506TCX": "#D6BAB2",
	"15-1415TCX": "#E4AEA5",
	"15-1512TCX": "#CCA69F",
	"15-1516TCX": "#D4A299",
	"16-1516TCX": "#C08A81",
	"13-1408TCX": "#F0C7C1",
	"13-1409TCX": "#F8CBC7",
	"13-1510TCX": "#FDC4BD",
	"14-1907TCX": "#E1B9BA",
	"15-1515TCX": "#DEAAA4",
	"16-1511TCX": "#CF9693",
	"16-1518TCX": "#CB8683",
	"17-1522TCX": "#AB5C6D",
	"17-1623TCX": "#A55972",
	"17-1723TCX": "#A1506D",
	"18-1420TCX": "#7D4D55",
	"18-1619TCX": "#854B5B",
	"18-1718TCX": "#864E61",
	"18-1725TCX": "#8F495F",
	"18-2027TCX": "#812F4F",
	"19-1850TCX": "#9A2D4E",
	"19-2024TCX": "#743146",
	"19-2025TCX": "#7E314D",
	"19-2030TCX": "#7F2A49",
	"19-2033TCX": "#862C4D",
	"19-2047TCX": "#9D3158",
	"12-1310TCX": "#F8D5E0",
	"12-2903TCX": "#E1C9D6",
	"12-2905TCX": "#EAD0DE",
	"12-2906TCX": "#F3DAE1",
	"13-1904TCX": "#E9C9D1",
	"13-2803TCX": "#E4CBD2",
	"14-2305TCX": "#D8A8B7",
	"12-1305TCX": "#F4E0E3",
	"12-1605TCX": "#EFD6D5",
	"12-1706TCX": "#F6D6D8",
	"12-1708TCX": "#F9C8CB",
	"13-1513TCX": "#FBC8C5",
	"13-2004TCX": "#EACBCE",
	"13-2005TCX": "#F5C6C8",
	"13-1906TCX": "#F8C5D4",
	"13-2006TCX": "#F5BFCC",
	"13-2010TCX": "#F2BDCD",
	"14-1909TCX": "#E8B7C1",
	"14-1911TCX": "#F4B4C4",
	"15-1816TCX": "#ED9FAE",
	"15-1912TCX": "#E299B0",
	"15-2210TCX": "#D194AE",
	"16-1708TCX": "#BB8D9E",
	"16-1712TCX": "#C0889D",
	"16-1715TCX": "#CF849D",
	"16-2107TCX": "#B1869F",
	"16-2111TCX": "#B37E9A",
	"16-2215TCX": "#CF86A3",
	"13-2804TCX": "#EAC5D2",
	"13-2805TCX": "#EAC2D6",
	"14-2307TCX": "#DCAABB",
	"14-2808TCX": "#E7B8D3",
	"14-3207TCX": "#DBB2D1",
	"14-3209TCX": "#DBA4CC",
	"15-3214TCX": "#D39BCB",
	"15-2913TCX": "#DF9DCC",
	"16-2614TCX": "#D290B5",
	"16-3115TCX": "#C581B2",
	"16-3116TCX": "#CD81B8",
	"16-3118TCX": "#DB8AC3",
	"17-3014TCX": "#AA709F",
	"18-3025TCX": "#98508E",
	"16-3320TCX": "#C081BA",
	"17-3020TCX": "#BC6CA7",
	"17-3323TCX": "#AA66AA",
	"18-3015TCX": "#824B77",
	"18-3224TCX": "#B163A3",
	"18-3230TCX": "#A55790",
	"19-2428TCX": "#723157",
	"17-3023TCX": "#B65DA1",
	"18-2527TCX": "#99326D",
	"18-3027TCX": "#AE4F93",
	"19-2430TCX": "#6D324E",
	"19-2431TCX": "#873260",
	"19-2432TCX": "#82305A",
	"19-2434TCX": "#A23773",
	"17-2617TCX": "#A85387",
	"18-2320TCX": "#923B77",
	"18-2929TCX": "#8F3975",
	"18-3022TCX": "#94417B",
	"18-3339TCX": "#9F4284",
	"19-2630TCX": "#953975",
	"19-2924TCX": "#853B75",
	"18-3324TCX": "#864483",
	"18-3331TCX": "#934D91",
	"19-2820TCX": "#713867",
	"19-3022TCX": "#63385E",
	"19-3138TCX": "#894180",
	"19-3230TCX": "#6D3362",
	"19-3336TCX": "#7D3F7C",
	"13-3801TCX": "#DCD1C9",
	"14-1305TCX": "#BCABA0",
	"19-2312TCX": "#644151",
	"16-1508TCX": "#B99C96",
	"16-1509TCX": "#BAA5A0",
	"16-1510TCX": "#B09793",
	"16-1703TCX": "#AB9895",
	"15-1607TCX": "#C5A4A4",
	"16-1707TCX": "#AD9194",
	"16-1806TCX": "#AE8F92",
	"18-1512TCX": "#80585D",
	"18-1612TCX": "#7E5E60",
	"18-1616TCX": "#865058",
	"18-1807TCX": "#896F73",
	"17-1510TCX": "#917875",
	"18-1409TCX": "#6B5859",
	"19-1518TCX": "#4F3A3C",
	"19-1606TCX": "#57414A",
	"19-1619TCX": "#48383E",
	"19-1620TCX": "#594249",
	"19-1621TCX": "#5B4249",
	"15-1511TCX": "#C3A191",
	"17-1516TCX": "#9A716E",
	"18-1415TCX": "#6F504F",
	"19-1521TCX": "#603E44",
	"19-1623TCX": "#5A3C44",
	"19-1625TCX": "#56393E",
	"19-2118TCX": "#4B3840",
	"19-1522TCX": "#5D303A",
	"19-1525TCX": "#67393F",
	"19-1526TCX": "#61373F",
	"19-1528TCX": "#5C343F",
	"19-1617TCX": "#653B47",
	"19-1627TCX": "#58333E",
	"19-1718TCX": "#503643",
	"14-3204TCX": "#CFAFC2",
	"15-1905TCX": "#C6AFB5",
	"15-2205TCX": "#C1A4B4",
	"15-2705TCX": "#C4AAB5",
	"15-2706TCX": "#C3B1B4",
	"15-3207TCX": "#C49FBA",
	"16-3205TCX": "#B89CA9",
	"17-1608TCX": "#AD6F84",
	"17-1612TCX": "#99637B",
	"17-1710TCX": "#9A6884",
	"17-1818TCX": "#A65E7E",
	"18-1716TCX": "#7F4D64",
	"18-1720TCX": "#8B4A66",
	"19-2410TCX": "#6B3453",
	"15-1906TCX": "#CCA2AB",
	"17-1511TCX": "#976F7B",
	"17-1610TCX": "#9C7687",
	"18-1709TCX": "#84586C",
	"18-1710TCX": "#8D6176",
	"18-2109TCX": "#8A6B76",
	"18-3011TCX": "#835A76",
	"17-1512TCX": "#A67A84",
	"17-1614TCX": "#965A67",
	"18-1418TCX": "#80505D",
	"18-1613TCX": "#824D5C",
	"18-1614TCX": "#7D4F5C",
	"19-1716TCX": "#5D3B4C",
	"18-1411TCX": "#6D4C5A",
	"19-1608TCX": "#5E4153",
	"19-2014TCX": "#603E4F",
	"19-2311TCX": "#5F434F",
	"19-2315TCX": "#59354A",
	"19-2514TCX": "#51354A",
	"19-2520TCX": "#4E3145",
	"16-3307TCX": "#B091AA",
	"16-3310TCX": "#B38FB1",
	"17-3410TCX": "#9C7694",
	"18-3012TCX": "#7A5873",
	"18-3013TCX": "#724B66",
	"18-3211TCX": "#83677F",
	"18-3220TCX": "#8F6F89",
	"18-3218TCX": "#7E5581",
	"18-3418TCX": "#856088",
	"18-3522TCX": "#7B5685",
	"19-2524TCX": "#622F53",
	"19-3223TCX": "#6D456A",
	"19-3325TCX": "#764672",
	"19-3424TCX": "#724A76",
	"18-3415TCX": "#755C78",
	"19-2009TCX": "#5A435A",
	"19-2814TCX": "#604566",
	"19-2816TCX": "#4D354A",
	"19-3218TCX": "#513653",
	"19-3323TCX": "#573D56",
	"19-3518TCX": "#54355D",
	"19-3215TCX": "#503E5C",
	"19-3217TCX": "#4C2D4C",
	"19-3316TCX": "#483A49",
	"19-3519TCX": "#46354D",
	"19-3520TCX": "#432F4E",
	"19-3619TCX": "#4D4155",
	"19-3714TCX": "#503F57",
	"19-3617TCX": "#3D3249",
	"19-3620TCX": "#54446C",
	"19-3712TCX": "#3F3949",
	"19-3720TCX": "#3F314F",
	"19-3722TCX": "#4C4168",
	"19-3725TCX": "#443C54",
	"19-3728TCX": "#493B5D",
	"13-3802TCX": "#DDD5DF",
	"13-3803TCX": "#D7CECF",
	"13-3804TCX": "#D6CCD3",
	"14-3803TCX": "#D1C4C3",
	"15-3802TCX": "#B7A9AD",
	"17-1505TCX": "#98858D",
	"17-3808TCX": "#9B8C98",
	"13-3805TCX": "#CEC4D7",
	"14-3805TCX": "#BDB3C0",
	"16-3304TCX": "#A593A1",
	"17-1605TCX": "#9C848F",
	"18-1405TCX": "#6E5862",
	"18-1706TCX": "#6A5767",
	"19-1624TCX": "#563F47",
	"14-3904TCX": "#BCB9CA",
	"14-3907TCX": "#BCB8CB",
	"17-3810TCX": "#8C8397",
	"17-3906TCX": "#918D9A",
	"17-3910TCX": "#958FA2",
	"18-3710TCX": "#7B7282",
	"18-3712TCX": "#72697D",
	"16-3810TCX": "#A29BB6",
	"16-3812TCX": "#9993B3",
	"17-3812TCX": "#8A829E",
	"17-3817TCX": "#8881A4",
	"18-3714TCX": "#5C516D",
	"18-3812TCX": "#68627A",
	"19-3716TCX": "#483D58",
	"14-3903TCX": "#C2BBC4",
	"15-0703TCX": "#B3ABAC",
	"16-3803TCX": "#A7A1A7",
	"17-2601TCX": "#91888B",
	"17-3802TCX": "#908C91",
	"18-1404TCX": "#685A60",
	"18-1703TCX": "#6A636D",
	"13-3405TCX": "#E3CADE",
	"13-3406TCX": "#DFD2DF",
	"14-3206TCX": "#D4B9D1",
	"14-3710TCX": "#BEB3CF",
	"14-3812TCX": "#BEB2D5",
	"15-3507TCX": "#BAA8C1",
	"15-3508TCX": "#C1ACC5",
	"14-3612TCX": "#C6B0D5",
	"15-3412TCX": "#D2ADD5",
	"16-3416TCX": "#C693C7",
	"16-3520TCX": "#B284BE",
	"16-3521TCX": "#BEA0C8",
	"16-3617TCX": "#BD97CB",
	"17-3313TCX": "#9E759B",
	"17-3619TCX": "#8F69A5",
	"17-3628TCX": "#9164AB",
	"17-3730TCX": "#8877B2",
	"18-3520TCX": "#75568C",
	"18-3533TCX": "#8B559B",
	"19-3526TCX": "#734D85",
	"19-3642TCX": "#5D4580",
	"18-3518TCX": "#754C90",
	"18-3633TCX": "#77529A",
	"19-3220TCX": "#5C3960",
	"19-3438TCX": "#794784",
	"19-3536TCX": "#6F4181",
	"19-3540TCX": "#693B71",
	"19-3542TCX": "#694281",
	"18-3615TCX": "#594577",
	"19-3514TCX": "#5A3764",
	"19-3528TCX": "#5A3663",
	"19-3622TCX": "#5B4A6B",
	"19-3640TCX": "#473053",
	"19-3731TCX": "#43355D",
	"13-3820TCX": "#D5C9DD",
	"15-3620TCX": "#BBA0CE",
	"15-3817TCX": "#B2A4D4",
	"16-3823TCX": "#9B90C8",
	"17-3615TCX": "#8B79A6",
	"17-3725TCX": "#9B84C0",
	"18-3718TCX": "#7D7098",
	"16-3110TCX": "#B88BB3",
	"16-3525TCX": "#AA8BB2",
	"16-3815TCX": "#A893C0",
	"17-3612TCX": "#90779A",
	"18-3410TCX": "#5B485C",
	"18-3513TCX": "#6E5B7A",
	"18-3715TCX": "#6B5D74",
	"17-3826TCX": "#8076AA",
	"17-3834TCX": "#7F6FB0",
	"18-3737TCX": "#6D579A",
	"18-3838TCX": "#645394",
	"19-3632TCX": "#53396A",
	"19-3737TCX": "#513B75",
	"19-3748TCX": "#584587",
	"18-3828TCX": "#656398",
	"18-3834TCX": "#706A99",
	"18-3840TCX": "#6459A2",
	"18-3943TCX": "#5B5EA6",
	"19-3730TCX": "#59497F",
	"19-3847TCX": "#4C4280",
	"19-3850TCX": "#4E468B",
	"18-3817TCX": "#646583",
	"18-3930TCX": "#6874A1",
	"19-3830TCX": "#2D2C4D",
	"19-3832TCX": "#3E4071",
	"19-3839TCX": "#3D3E63",
	"19-3842TCX": "#494575",
	"19-3936TCX": "#4C4E77",
	"13-4105TCX": "#D5D5DE",
	"14-3905TCX": "#C2BFD3",
	"14-3911TCX": "#BBB9D8",
	"15-3807TCX": "#BCB8C9",
	"15-3909TCX": "#A8ACC9",
	"15-3910TCX": "#A4A4BF",
	"16-3907TCX": "#9E9EAB",
	"16-3925TCX": "#8F99CF",
	"16-3931TCX": "#9B9DC7",
	"17-3930TCX": "#8690CB",
	"17-3932TCX": "#7A83BF",
	"18-3833TCX": "#63659E",
	"18-3944TCX": "#5860A2",
	"18-3946TCX": "#5265B0",
	"16-3930TCX": "#949AC2",
	"17-3925TCX": "#8A8DB5",
	"18-3820TCX": "#65658E",
	"18-3963TCX": "#3E4081",
	"19-3947TCX": "#4C4A7E",
	"19-3951TCX": "#3D407F",
	"19-3955TCX": "#3F448C",
	"17-3922TCX": "#6F7A9F",
	"17-3924TCX": "#747CA8",
	"18-3927TCX": "#5F698F",
	"18-3932TCX": "#505C84",
	"19-3933TCX": "#2F3654",
	"19-3939TCX": "#2E3561",
	"19-3940TCX": "#2D355A",
	"16-3911TCX": "#9F9AAE",
	"17-3917TCX": "#7683A0",
	"19-3915TCX": "#414256",
	"19-3919TCX": "#52586E",
	"19-3926TCX": "#4A4F68",
	"19-3928TCX": "#485470",
	"19-3935TCX": "#3E4465",
	"13-4110TCX": "#C3CBD9",
	"14-4106TCX": "#B9C0CD",
	"14-4110TCX": "#B7C2DA",
	"16-3919TCX": "#8F9BB8",
	"17-3936TCX": "#6386BF",
	"17-4030TCX": "#5D89BA",
	"18-3937TCX": "#5072A7",
	"15-3930TCX": "#7C9ED9",
	"16-3920TCX": "#8A9CC4",
	"16-3929TCX": "#8297CE",
	"19-4056TCX": "#2E5493",
	"17-3919TCX": "#8691B7",
	"17-3934TCX": "#697FC2",
	"18-3935TCX": "#657BB8",
	"14-4112TCX": "#ACC0D9",
	"14-4115TCX": "#A2B8D4",
	"14-4121TCX": "#92B5D9",
	"15-3920TCX": "#8DAED7",
	"16-4020TCX": "#759ACD",
	"16-4032TCX": "#638CC7",
	"17-4037TCX": "#5A7CC2",
	"16-4021TCX": "#708FB5",
	"17-3923TCX": "#61769D",
	"18-3928TCX": "#496491",
	"18-4027TCX": "#4F6988",
	"19-4027TCX": "#253659",
	"19-4039TCX": "#3A5B8D",
	"19-4044TCX": "#2C4578",
	"17-4015TCX": "#667B98",
	"18-3920TCX": "#4D5D83",
	"18-3921TCX": "#41597E",
	"19-4026TCX": "#384F6B",
	"19-4028TCX": "#32435A",
	"19-4030TCX": "#405479",
	"19-4118TCX": "#35485E",
	"15-4319TCX": "#7CB1CD",
	"15-4323TCX": "#5DABD5",
	"16-4127TCX": "#669EC2",
	"16-4134TCX": "#52A3D6",
	"17-4131TCX": "#3D81AC",
	"18-4036TCX": "#4E81AD",
	"18-4232TCX": "#246C8F",
	"15-4225TCX": "#6DACD6",
	"16-4132TCX": "#6CA0DC",
	"17-4027TCX": "#5677A6",
	"17-4139TCX": "#458AC6",
	"18-4029TCX": "#375D8A",
	"18-4041TCX": "#386499",
	"19-4037TCX": "#355E91",
	"16-4120TCX": "#7DA1BF",
	"18-4039TCX": "#4D7CBC",
	"18-4043TCX": "#3267AD",
	"18-4051TCX": "#2760A7",
	"19-4052TCX": "#1D4E89",
	"19-4053TCX": "#255498",
	"11-0605TCX": "#EDE6DE",
	"11-0609TCX": "#F1ECCA",
	"11-0615TCX": "#F3EAC3",
	"11-0619TCX": "#F6E3B4",
	"11-0622TCX": "#EEE78E",
	"11-1001TCX": "#EFEBE7",
	"11-0205TCX": "#ECEAD0",
	"11-0304TCX": "#DDE3D5",
	"11-0410TCX": "#E7EACB",
	"12-0225TCX": "#B9EAB3",
	"12-0312TCX": "#D7E8BC",
	"12-0313TCX": "#CBD5B1",
	"12-0315TCX": "#D4DBB2",
	"12-0317TCX": "#BFD1AD",
	"12-0322TCX": "#CADEA5",
	"12-0418TCX": "#D3CCA3",
	"12-0426TCX": "#D5D593",
	"12-0435TCX": "#C9D77E",
	"12-0520TCX": "#DFE69F",
	"12-0521TCX": "#E1E3A9",
	"12-0524TCX": "#DFDE9B",
	"12-0525TCX": "#E3EAA5",
	"12-0530TCX": "#E5E790",
	"12-0106TCX": "#D3DEC4",
	"12-0108TCX": "#D6DEC9",
	"12-0109TCX": "#D2E7CA",
	"12-0607TCX": "#E9EAC8",
	"12-0709TCX": "#E4CFB6",
	"12-0711TCX": "#F6E199",
	"12-0717TCX": "#F4E3B5",
	"12-0718TCX": "#E7D391",
	"12-0729TCX": "#EBCF89",
	"12-0737TCX": "#F8DC6C",
	"12-0741TCX": "#DFEF87",
	"12-0758TCX": "#FACE6D",
	"12-4604TCX": "#C8E0E0",
	"12-4607TCX": "#BCD3D5",
	"12-4608TCX": "#AAD5DB",
	"12-4609TCX": "#B5CED4",
	"12-4610TCX": "#C9DCDC",
	"12-4805TCX": "#CBDCDF",
	"12-4806TCX": "#CCDAD7",
	"12-5204TCX": "#CFDFDB",
	"12-5206TCX": "#C6E3E1",
	"12-5404TCX": "#E0E6D7",
	"12-5406TCX": "#C3DDD6",
	"12-5407TCX": "#D2E8E0",
	"12-5408TCX": "#C7E5DF",
	"12-5409TCX": "#B8E2DC",
	"12-5410TCX": "#BCE3DF",
	"12-5504TCX": "#CEE1D4",
	"12-5505TCX": "#C3DBD4",
	"12-5506TCX": "#C0DCCD",
	"12-5507TCX": "#BAE5D6",
	"12-5808TCX": "#BAE1D3",
	"12-6205TCX": "#CFDBD1",
	"12-6206TCX": "#D8E3D7",
	"12-6208TCX": "#DCE4D7",
	"13-0107TCX": "#C4D1C2",
	"13-0111TCX": "#BFD1B3",
	"13-0116TCX": "#B4D3B2",
	"13-0117TCX": "#A0DAA9",
	"13-0210TCX": "#C2CBB4",
	"13-0212TCX": "#C5CFB6",
	"13-0215TCX": "#C3D3A8",
	"13-0220TCX": "#B2E79F",
	"13-0221TCX": "#A9D39E",
	"13-0317TCX": "#C5CF98",
	"13-0319TCX": "#CFE09D",
	"13-0324TCX": "#BED38E",
	"13-0331TCX": "#AFCB80",
	"13-0333TCX": "#E1D590",
	"13-0522TCX": "#CBCE91",
	"13-0530TCX": "#CDD78A",
	"13-0532TCX": "#C5CC7B",
	"13-0535TCX": "#C6E67A",
	"13-0540TCX": "#C3D363",
	"13-0550TCX": "#C0D725",
	"13-0608TCX": "#D0D3B7",
	"13-0614TCX": "#DCD8A8",
	"13-0633TCX": "#E7DF99",
	"12-5209TCX": "#C3E9E4",
	"13-0442TCX": "#B0C965",
	"13-0645TCX": "#D3D95F",
	"13-0650TCX": "#D5D717",
	"13-0756TCX": "#F9D857",
	"13-0759TCX": "#F4BF3A",
	"13-2802TCX": "#F2C1D1",
	"13-2807TCX": "#F2CFDC",
	"13-3920TCX": "#BDC6DC",
	"13-4103TCX": "#C9D3DC",
	"13-4108TCX": "#D5D5D8",
	"13-4111TCX": "#BFCAD6",
	"13-4200TCX": "#B5CEDF",
	"13-5412TCX": "#96DFCE",
	"13-5414TCX": "#87D8C3",
	"13-5714TCX": "#87D7BE",
	"13-5907TCX": "#B2CFBE",
	"13-5911TCX": "#AACCB9",
	"13-6006TCX": "#CAD3C1",
	"13-6007TCX": "#BED3BB",
	"13-6008TCX": "#BCD9C8",
	"13-6009TCX": "#AFDDCC",
	"13-6105TCX": "#CBCEBE",
	"13-6106TCX": "#C5CCC0",
	"13-6107TCX": "#C1CEC1",
	"13-6108TCX": "#B8CCBA",
	"13-6110TCX": "#AACEBC",
	"13-6208TCX": "#BCCAB3",
	"14-0114TCX": "#B5C1A5",
	"14-0115TCX": "#B4C79C",
	"14-0116TCX": "#B5C38E",
	"14-0121TCX": "#A7C796",
	"14-0123TCX": "#A3C893",
	"14-0127TCX": "#8BC28C",
	"14-0156TCX": "#7ED37F",
	"14-0216TCX": "#B6BA99",
	"14-0217TCX": "#C0CBA1",
	"14-0223TCX": "#B4BB85",
	"14-0226TCX": "#A3C57D",
	"14-0232TCX": "#A1CA7B",
	"14-0244TCX": "#97BC62",
	"13-4304TCX": "#C0CEDA",
	"13-4305TCX": "#D1D5D0",
	"13-4404TCX": "#C6D2D2",
	"13-4405TCX": "#BFCDCC",
	"13-4409TCX": "#B2D4DD",
	"13-4308TCX": "#B5C7D3",
	"13-4411TCX": "#A1C8DB",
	"13-4909TCX": "#ACDFDD",
	"13-4910TCX": "#9FD9D7",
	"13-5305TCX": "#C1CCC2",
	"13-5306TCX": "#B0D3D1",
	"13-5309TCX": "#99C5C4",
	"13-5313TCX": "#81D7D3",
	"13-5409TCX": "#A1D7C9",
	"13-4804TCX": "#C4D6D3",
	"13-4809TCX": "#A5CFD5",
	"14-0418TCX": "#BAB696",
	"14-0425TCX": "#C2C18D",
	"14-0434TCX": "#BABC72",
	"14-0445TCX": "#B5BF50",
	"14-0446TCX": "#B5CC39",
	"14-0452TCX": "#9FC131",
	"14-0627TCX": "#CFC486",
	"14-0851TCX": "#FBC85F",
	"14-3949TCX": "#B7C0D7",
	"14-4105TCX": "#BABCC0",
	"14-4206TCX": "#B0B7BE",
	"14-4210TCX": "#A3B4C4",
	"14-4214TCX": "#96B3D2",
	"14-4306TCX": "#A2B6B9",
	"14-4307TCX": "#A9C0CB",
	"14-4310TCX": "#78BDD4",
	"14-4311TCX": "#A9CADA",
	"14-4313TCX": "#9DC3D4",
	"14-4908TCX": "#A8C0BB",
	"14-5413TCX": "#81C3B4",
	"14-5416TCX": "#60C9B3",
	"14-5420TCX": "#58C8B6",
	"14-5706TCX": "#A9BDB1",
	"14-5707TCX": "#ADC3B4",
	"14-5711TCX": "#8EC5B6",
	"14-5713TCX": "#76C1B2",
	"14-5714TCX": "#7ACCB8",
	"14-5718TCX": "#77CFB7",
	"14-5721TCX": "#4BC3A8",
	"14-6007TCX": "#B7C2B2",
	"14-6008TCX": "#B5CBBB",
	"14-6011TCX": "#9BBEA9",
	"14-6017TCX": "#7FBB9E",
	"14-6312TCX": "#AAC0AD",
	"14-6316TCX": "#9FC09C",
	"14-6319TCX": "#8BBA94",
	"14-6324TCX": "#82B185",
	"14-6327TCX": "#7CB083",
	"14-6329TCX": "#76B583",
	"14-6330TCX": "#6BCD9C",
	"14-6340TCX": "#6DCE87",
	"14-6408TCX": "#ABA798",
	"15-0146TCX": "#79C753",
	"15-0318TCX": "#B2AC88",
	"15-0326TCX": "#A4AE77",
	"15-0332TCX": "#9FAF6C",
	"15-0336TCX": "#9CAD60",
	"15-0341TCX": "#8DB051",
	"15-0343TCX": "#88B04B",
	"15-0522TCX": "#B5AD88",
	"15-0523TCX": "#B0B487",
	"15-0525TCX": "#B3B17B",
	"15-0531TCX": "#A3A969",
	"15-0533TCX": "#C4BF71",
	"15-0535TCX": "#AFAF5E",
	"15-0538TCX": "#B0B454",
	"14-4502TCX": "#BAC2BA",
	"14-4504TCX": "#BCC8C6",
	"14-4505TCX": "#BFC8C3",
	"14-4506TCX": "#9EB6B8",
	"14-4508TCX": "#9EC1CC",
	"14-4510TCX": "#99C1CC",
	"14-4511TCX": "#88C3D0",
	"14-4512TCX": "#95C0CB",
	"14-4516TCX": "#87C2D4",
	"14-4522TCX": "#4ABBD5",
	"14-4317TCX": "#A5C5D9",
	"14-4318TCX": "#8ABAD3",
	"14-4807TCX": "#B4C8C2",
	"14-4809TCX": "#A3CCC9",
	"14-4810TCX": "#9CC2C5",
	"14-4811TCX": "#7BC4C4",
	"14-4812TCX": "#85CED1",
	"14-4814TCX": "#83C5CD",
	"14-4816TCX": "#58C9D4",
	"15-0543TCX": "#B5B644",
	"15-0545TCX": "#7EC845",
	"15-0548TCX": "#B8AF23",
	"15-0628TCX": "#B7B17A",
	"15-0646TCX": "#C7B63C",
	"15-1114TCX": "#AE997D",
	"15-1218TCX": "#CEB899",
	"15-1304TCX": "#B7A793",
	"15-1307TCX": "#B6A893",
	"15-1506TCX": "#A5958F",
	"15-3800TCX": "#A7A19E",
	"15-3908TCX": "#A9ADC2",
	"15-3912TCX": "#9A9EB3",
	"15-3915TCX": "#A5B3CC",
	"15-3932TCX": "#819AC1",
	"15-4005TCX": "#A0BCD0",
	"15-4008TCX": "#9BABBB",
	"15-4717TCX": "#64A1AD",
	"15-4720TCX": "#38AFCD",
	"15-4722TCX": "#44BBCA",
	"15-4825TCX": "#32BECC",
	"15-5205TCX": "#A5B2AA",
	"15-5207TCX": "#89ACAC",
	"15-5209TCX": "#87B9BC",
	"15-5210TCX": "#76A7AB",
	"15-5217TCX": "#53B0AE",
	"15-5218TCX": "#67BCB3",
	"15-5416TCX": "#56BEAB",
	"15-5421TCX": "#00B89F",
	"15-5425TCX": "#00AF9F",
	"15-5516TCX": "#3AB0A2",
	"15-5519TCX": "#45B5AA",
	"15-5534TCX": "#009B5C",
	"15-5704TCX": "#B2B6AC",
	"15-5706TCX": "#A3B5A6",
	"15-5711TCX": "#7BB5A3",
	"15-5718TCX": "#55C6A9",
	"15-5728TCX": "#00B694",
	"15-5812TCX": "#9BC2B1",
	"15-5819TCX": "#64BFA4",
	"15-6114TCX": "#97C1A1",
	"15-6120TCX": "#7CB08A",
	"15-6123TCX": "#60B892",
	"15-6310TCX": "#A8B197",
	"15-6313TCX": "#ADBBA1",
	"15-6315TCX": "#A8BBA2",
	"15-6316TCX": "#92AF88",
	"15-6317TCX": "#9EBC97",
	"15-6322TCX": "#7CB68E",
	"15-6340TCX": "#45BE76",
	"15-6410TCX": "#AFAB97",
	"15-6414TCX": "#A1AD92",
	"15-6423TCX": "#91AC80",
	"15-6428TCX": "#86A96F",
	"15-6432TCX": "#6FA26B",
	"15-6437TCX": "#7BB369",
	"15-6442TCX": "#79B465",
	"16-0110TCX": "#A7AE9E",
	"16-0123TCX": "#89A06B",
	"16-0205TCX": "#9A9186",
	"16-0213TCX": "#999B85",
	"16-0220TCX": "#8AA282",
	"16-0224TCX": "#7D956D",
	"16-0228TCX": "#759465",
	"15-4020TCX": "#9BB7D4",
	"15-4030TCX": "#9EB4D3",
	"15-4105TCX": "#A3BDD3",
	"15-4305TCX": "#98A0A5",
	"15-4306TCX": "#A3A9A6",
	"15-4307TCX": "#7F8793",
	"15-4309TCX": "#A2B9C2",
	"15-4312TCX": "#8FADBD",
	"15-4415TCX": "#72A8BA",
	"15-4421TCX": "#5CACCE",
	"15-4427TCX": "#4CA5C7",
	"15-4706TCX": "#99AEAE",
	"15-4707TCX": "#A5BCBB",
	"15-4712TCX": "#76AFB6",
	"16-0230TCX": "#9BB53E",
	"16-0233TCX": "#739957",
	"16-0235TCX": "#7AAB55",
	"16-0237TCX": "#75A14F",
	"16-0421TCX": "#91946E",
	"16-0430TCX": "#9AA067",
	"16-0435TCX": "#A0AC4F",
	"16-0439TCX": "#909B4C",
	"16-0518TCX": "#A49A79",
	"16-0526TCX": "#928E64",
	"15-4714TCX": "#61AAB1",
	"15-4715TCX": "#6BAAAE",
	"16-0532TCX": "#A09D59",
	"16-0540TCX": "#A3A04E",
	"16-0613TCX": "#A39F86",
	"16-0632TCX": "#9A8B4F",
	"16-0639TCX": "#AF9841",
	"16-0840TCX": "#B9A023",
	"16-0920TCX": "#BCA483",
	"16-1104TCX": "#A49887",
	"16-1109TCX": "#928475",
	"16-1118TCX": "#A49775",
	"16-1120TCX": "#B09A77",
	"16-1149TCX": "#C87629",
	"16-1164TCX": "#DF7500",
	"16-1363TCX": "#E95C20",
	"16-0713TCX": "#A0987C",
	"16-0726TCX": "#A39264",
	"16-1449TCX": "#B45422",
	"16-1710TCX": "#B98391",
	"16-3800TCX": "#978D89",
	"16-3817TCX": "#9F86AA",
	"16-3850TCX": "#A19FA5",
	"16-3915TCX": "#98979A",
	"16-3916TCX": "#92949B",
	"16-3921TCX": "#96A3C7",
	"16-3922TCX": "#9BA9CA",
	"16-4010TCX": "#8C9DAD",
	"16-4013TCX": "#8699AB",
	"16-4019TCX": "#899BB8",
	"16-4030TCX": "#849BCC",
	"16-4400TCX": "#94908B",
	"16-4404TCX": "#8F9E9D",
	"16-4408TCX": "#8C9FA1",
	"16-4411TCX": "#86A1A9",
	"16-4414TCX": "#769DA6",
	"16-4421TCX": "#5BACC3",
	"16-4427TCX": "#289DBE",
	"16-4519TCX": "#6198AE",
	"16-4109TCX": "#879BA3",
	"16-4114TCX": "#829CA5",
	"16-4525TCX": "#52A2B4",
	"16-4529TCX": "#14A3C7",
	"16-4530TCX": "#3CADD4",
	"16-4535TCX": "#00B1D2",
	"16-4610TCX": "#70A4B0",
	"16-4612TCX": "#6F9FA9",
	"16-4703TCX": "#9C9B98",
	"16-4706TCX": "#8A9A9A",
	"16-4712TCX": "#6D9192",
	"16-4719TCX": "#5D9CA4",
	"16-4725TCX": "#00ABC0",
	"16-4728TCX": "#00A0B0",
	"16-4834TCX": "#009DAE",
	"16-5101TCX": "#929090",
	"16-5427TCX": "#00AA92",
	"16-5431TCX": "#00A78B",
	"16-5515TCX": "#619187",
	"16-5533TCX": "#00A28A",
	"16-5721TCX": "#40A48E",
	"16-5804TCX": "#8A9691",
	"16-5806TCX": "#8A9992",
	"16-5807TCX": "#818F84",
	"16-5808TCX": "#8C9C92",
	"16-5810TCX": "#7E9285",
	"16-5815TCX": "#729B8B",
	"16-5820TCX": "#589F7E",
	"16-5825TCX": "#2EA785",
	"16-5907TCX": "#86A293",
	"16-5917TCX": "#709A89",
	"16-5919TCX": "#70A38D",
	"16-5924TCX": "#4F9E81",
	"16-5930TCX": "#3AA278",
	"16-5932TCX": "#0F9D76",
	"16-5938TCX": "#00A170",
	"16-5942TCX": "#00A776",
	"16-6008TCX": "#959889",
	"16-6030TCX": "#66BC91",
	"16-6116TCX": "#739072",
	"16-6127TCX": "#4B9B69",
	"16-6138TCX": "#339C5E",
	"16-6216TCX": "#879F84",
	"16-6240TCX": "#2BAE66",
	"16-6318TCX": "#7A9B78",
	"16-6324TCX": "#77A276",
	"16-6329TCX": "#699E6D",
	"16-6339TCX": "#55A860",
	"16-6340TCX": "#39A845",
	"16-6444TCX": "#4DB560",
	"17-0115TCX": "#80856D",
	"17-0119TCX": "#6F8C69",
	"17-0123TCX": "#658E67",
	"17-0133TCX": "#699158",
	"17-0145TCX": "#44883C",
	"16-5106TCX": "#90A8A4",
	"16-5109TCX": "#73A89E",
	"16-5112TCX": "#6DA29E",
	"16-5114TCX": "#649B9E",
	"16-5119TCX": "#549F98",
	"16-5121TCX": "#60A0A3",
	"16-5123TCX": "#279D9F",
	"16-5127TCX": "#00AAA9",
	"16-5304TCX": "#95A69F",
	"16-5412TCX": "#599F99",
	"16-5418TCX": "#4D9E9A",
	"16-5421TCX": "#149C88",
	"16-5422TCX": "#30A299",
	"16-5425TCX": "#00AF9D",
	"17-0210TCX": "#788F74",
	"17-0215TCX": "#7E9B76",
	"17-0220TCX": "#748C69",
	"17-0230TCX": "#6B8D53",
	"17-0235TCX": "#769358",
	"17-0324TCX": "#849161",
	"17-0330TCX": "#81894E",
	"17-0510TCX": "#938B78",
	"17-0517TCX": "#746C57",
	"17-0525TCX": "#858961",
	"17-0336TCX": "#819548",
	"17-0535TCX": "#8D8B55",
	"17-0613TCX": "#807D6F",
	"17-0618TCX": "#817A65",
	"17-0620TCX": "#817A60",
	"17-0625TCX": "#8E855F",
	"17-0627TCX": "#847A59",
	"17-0836TCX": "#927B3C",
	"17-0840TCX": "#9A803A",
	"17-0843TCX": "#9C7E41",
	"17-0929TCX": "#998456",
	"17-1019TCX": "#8C7C61",
	"17-1038TCX": "#977C61",
	"17-0636TCX": "#857946",
	"17-1140TCX": "#C16512",
	"17-1311TCX": "#8D7E71",
	"17-1316TCX": "#937B6A",
	"17-1342TCX": "#A15325",
	"17-1349TCX": "#E86800",
	"17-1360TCX": "#E8703A",
	"17-1449TCX": "#C34121",
	"17-1452TCX": "#D15837",
	"17-1461TCX": "#E2552C",
	"17-1463TCX": "#DD4124",
	"17-1503TCX": "#787376",
	"17-1609TCX": "#A66E7A",
	"17-3617TCX": "#9D7BB0",
	"17-3907TCX": "#7E7D88",
	"17-3911TCX": "#7F7C81",
	"17-3915TCX": "#79839B",
	"17-3918TCX": "#717F9B",
	"17-3920TCX": "#7181A4",
	"17-3933TCX": "#81839A",
	"17-4014TCX": "#807D7F",
	"17-4020TCX": "#66829A",
	"17-4021TCX": "#798EA4",
	"17-4023TCX": "#5B7E98",
	"17-4041TCX": "#4F84C4",
	"17-4111TCX": "#748995",
	"17-4123TCX": "#5487A4",
	"17-4247TCX": "#007BB2",
	"17-4320TCX": "#5C899B",
	"17-4328TCX": "#3686A0",
	"17-4336TCX": "#0084BD",
	"17-4405TCX": "#84898C",
	"17-4408TCX": "#7A898F",
	"17-4412TCX": "#6D8994",
	"17-4421TCX": "#3C7D90",
	"17-4427TCX": "#157EA0",
	"17-4432TCX": "#0088B0",
	"17-4433TCX": "#0086BB",
	"17-4435TCX": "#008CC1",
	"17-4440TCX": "#0087B6",
	"17-4540TCX": "#008DB9",
	"17-4716TCX": "#47788A",
	"17-4724TCX": "#1A7F8E",
	"17-4728TCX": "#00859C",
	"17-4730TCX": "#00849F",
	"17-4735TCX": "#008799",
	"17-4818TCX": "#558F91",
	"17-4911TCX": "#648589",
	"17-4919TCX": "#478589",
	"17-4928TCX": "#008C96",
	"17-5024TCX": "#007F7C",
	"17-5025TCX": "#008583",
	"17-5029TCX": "#008381",
	"17-5034TCX": "#008684",
	"17-5102TCX": "#8D8F8F",
	"17-5107TCX": "#7C8C87",
	"17-5110TCX": "#6A8988",
	"17-5111TCX": "#658C88",
	"17-5117TCX": "#358082",
	"17-5122TCX": "#379190",
	"17-5126TCX": "#009499",
	"17-5130TCX": "#009288",
	"17-5330TCX": "#008E80",
	"17-5335TCX": "#009B8C",
	"17-5421TCX": "#108780",
	"17-5430TCX": "#008778",
	"17-5513TCX": "#4F7C74",
	"17-5528TCX": "#007D69",
	"17-5633TCX": "#009276",
	"17-5638TCX": "#009E82",
	"17-5641TCX": "#009473",
	"17-5722TCX": "#427D6D",
	"17-5734TCX": "#00846B",
	"17-5735TCX": "#008C69",
	"17-5912TCX": "#5B7763",
	"17-5923TCX": "#3A795E",
	"17-5936TCX": "#009B75",
	"17-5937TCX": "#009E6D",
	"17-6009TCX": "#616F65",
	"17-6030TCX": "#008658",
	"17-6153TCX": "#008C45",
	"17-6206TCX": "#888D82",
	"17-6212TCX": "#717E6F",
	"17-6219TCX": "#558367",
	"17-6229TCX": "#3C824E",
	"17-6319TCX": "#6F8D6A",
	"17-6323TCX": "#768A75",
	"17-6333TCX": "#487D49",
	"18-0108TCX": "#647755",
	"18-0110TCX": "#61845B",
	"18-0117TCX": "#5F7355",
	"18-0119TCX": "#59754D",
	"18-0121TCX": "#547053",
	"18-0125TCX": "#4B6D41",
	"18-0130TCX": "#53713D",
	"18-0135TCX": "#476A30",
	"18-0228TCX": "#595F34",
	"18-0312TCX": "#6E6E5C",
	"18-0316TCX": "#666B54",
	"18-0317TCX": "#525F48",
	"18-0322TCX": "#545A3E",
	"18-0332TCX": "#77824A",
	"18-0420TCX": "#616652",
	"18-0422TCX": "#6E7153",
	"18-0426TCX": "#656344",
	"18-0430TCX": "#676232",
	"18-0435TCX": "#6A6F34",
	"18-0627TCX": "#67592A",
	"18-0629TCX": "#71643E",
	"18-0724TCX": "#7C6E4F",
	"18-0825TCX": "#75663E",
	"18-0830TCX": "#7A643F",
	"18-0832TCX": "#7A6332",
	"18-0835TCX": "#997B38",
	"18-0840TCX": "#805D24",
	"18-0324TCX": "#757A4E",
	"18-0328TCX": "#5E6737",
	"18-0515TCX": "#646356",
	"18-0521TCX": "#646049",
	"18-0523TCX": "#5B5A41",
	"18-0525TCX": "#818455",
	"18-0527TCX": "#6A6A45",
	"18-0622TCX": "#756D47",
	"18-1017TCX": "#816D5E",
	"18-0538TCX": "#7B7F32",
	"18-1029TCX": "#8B6A4F",
	"18-1108TCX": "#807669",
	"18-1148TCX": "#864C24",
	"18-1163TCX": "#A05C17",
	"18-1210TCX": "#847A75",
	"18-1246TCX": "#944A1F",
	"18-1248TCX": "#B55A30",
	"18-1249TCX": "#BB5C14",
	"18-1304TCX": "#6D625B",
	"18-1345TCX": "#9B4722",
	"18-1355TCX": "#A23C26",
	"18-1340TCX": "#9E4624",
	"18-1564TCX": "#CA3422",
	"18-1657TCX": "#AA182B",
	"18-1950TCX": "#B61C50",
	"18-2140TCX": "#CB3373",
	"18-3635TCX": "#634878",
	"18-3620TCX": "#5F4E72",
	"18-3628TCX": "#9469A2",
	"18-3907TCX": "#5E5B60",
	"18-3910TCX": "#626879",
	"18-3912TCX": "#585E6F",
	"18-3916TCX": "#677283",
	"18-3922TCX": "#59728E",
	"18-3918TCX": "#546477",
	"18-3933TCX": "#717388",
	"18-3945TCX": "#4960A8",
	"18-3949TCX": "#3850A0",
	"18-4006TCX": "#66676D",
	"18-4023TCX": "#3B5F78",
	"18-4025TCX": "#516B84",
	"18-4026TCX": "#46647E",
	"18-4028TCX": "#4B5B6E",
	"18-4032TCX": "#266691",
	"18-4011TCX": "#5F7278",
	"18-4016TCX": "#767275",
	"18-4018TCX": "#405D73",
	"18-4020TCX": "#557088",
	"18-4034TCX": "#30658E",
	"18-4045TCX": "#0F5F9A",
	"18-4140TCX": "#0072B5",
	"18-4141TCX": "#3272AF",
	"18-4148TCX": "#08589D",
	"18-4214TCX": "#58646D",
	"18-4215TCX": "#5C6D7C",
	"18-4217TCX": "#577284",
	"18-4220TCX": "#5C798E",
	"18-4222TCX": "#35637C",
	"18-4225TCX": "#1F6680",
	"18-4231TCX": "#09577B",
	"18-4244TCX": "#0061A3",
	"18-4247TCX": "#0075B3",
	"18-4252TCX": "#0077B3",
	"18-4320TCX": "#4E6E81",
	"18-4330TCX": "#007EB1",
	"18-4334TCX": "#1478A7",
	"18-4417TCX": "#436573",
	"18-4432TCX": "#00698B",
	"18-4434TCX": "#005780",
	"18-4440TCX": "#0075AF",
	"18-4510TCX": "#697A7E",
	"18-4522TCX": "#2D6471",
	"18-4525TCX": "#00819D",
	"18-4528TCX": "#00758F",
	"18-4530TCX": "#006380",
	"18-4535TCX": "#007BAA",
	"18-4537TCX": "#0074A8",
	"18-4711TCX": "#6E8082",
	"18-4718TCX": "#426972",
	"18-4726TCX": "#097988",
	"18-4728TCX": "#00656E",
	"18-4733TCX": "#007A8E",
	"18-4735TCX": "#008491",
	"18-4612TCX": "#536D70",
	"18-4834TCX": "#00656B",
	"18-5102TCX": "#73706F",
	"18-5112TCX": "#4C6969",
	"18-5115TCX": "#316C6B",
	"18-5121TCX": "#20706F",
	"18-5128TCX": "#007C7A",
	"18-5606TCX": "#576664",
	"18-5610TCX": "#4C7E86",
	"18-5611TCX": "#556962",
	"18-5612TCX": "#567572",
	"18-5616TCX": "#325B51",
	"18-5618TCX": "#36716F",
	"18-5619TCX": "#0A6F69",
	"18-5620TCX": "#226C63",
	"18-5621TCX": "#3A725F",
	"18-5622TCX": "#578270",
	"18-5624TCX": "#006E5B",
	"18-5633TCX": "#007558",
	"18-5642TCX": "#008763",
	"18-5718TCX": "#3E6257",
	"18-5725TCX": "#29685F",
	"18-5806TCX": "#6B7169",
	"18-5841TCX": "#007D60",
	"18-5210TCX": "#5C5658",
	"18-5308TCX": "#486B67",
	"18-5315TCX": "#255958",
	"18-5322TCX": "#005F56",
	"18-5338TCX": "#006B54",
	"18-5410TCX": "#4E6866",
	"18-5418TCX": "#29675C",
	"18-5424TCX": "#00675B",
	"18-4930TCX": "#008786",
	"18-4936TCX": "#006D70",
	"18-5020TCX": "#00736C",
	"18-5913TCX": "#3E524B",
	"18-6011TCX": "#53665C",
	"18-6018TCX": "#3E6F58",
	"18-6022TCX": "#378661",
	"18-6024TCX": "#1F7349",
	"18-6114TCX": "#4F6B58",
	"18-6216TCX": "#5B7961",
	"18-6320TCX": "#477050",
	"18-6330TCX": "#3D7245",
	"19-0201TCX": "#434447",
	"19-0307TCX": "#444940",
	"19-0309TCX": "#50574C",
	"19-0312TCX": "#55584C",
	"19-0315TCX": "#414F3C",
	"19-0323TCX": "#4A5335",
	"19-0511TCX": "#545144",
	"19-0512TCX": "#585442",
	"19-0515TCX": "#535040",
	"19-0516TCX": "#574D35",
	"19-0622TCX": "#63563B",
	"19-0808TCX": "#685C53",
	"19-0414TCX": "#434237",
	"19-0415TCX": "#394034",
	"19-0417TCX": "#3A4032",
	"19-0419TCX": "#414832",
	"19-0509TCX": "#36362D",
	"18-6030TCX": "#007844",
	"19-0230TCX": "#495E35",
	"19-0840TCX": "#3F352F",
	"19-0910TCX": "#433937",
	"19-0916TCX": "#5F4C40",
	"19-1018TCX": "#34292A",
	"19-1103TCX": "#363031",
	"19-1106TCX": "#392D2B",
	"19-1109TCX": "#382E2D",
	"19-1337TCX": "#6A2E2A",
	"19-1531TCX": "#752329",
	"19-1535TCX": "#6A282C",
	"19-1555TCX": "#7D2027",
	"19-1559TCX": "#9D202F",
	"19-1652TCX": "#77202F",
	"19-1662TCX": "#A2242F",
	"19-1724TCX": "#64242E",
	"19-1757TCX": "#AA0A27",
	"19-1758TCX": "#A11729",
	"19-1863TCX": "#941E32",
	"19-1930TCX": "#6C2831",
	"19-2039TCX": "#A52350",
	"19-2041TCX": "#A22452",
	"19-2045TCX": "#A32857",
	"19-3628TCX": "#46295A",
	"19-3638TCX": "#563474",
	"19-3713TCX": "#2C2A33",
	"19-3810TCX": "#343148",
	"19-3815TCX": "#2A293E",
	"19-3864TCX": "#273C76",
	"19-3900TCX": "#524D50",
	"19-3901TCX": "#4D4B4F",
	"19-3906TCX": "#4A4B4D",
	"19-3907TCX": "#48464A",
	"19-3909TCX": "#2E272A",
	"19-3910TCX": "#4E5055",
	"19-3918TCX": "#46444C",
	"19-3920TCX": "#2B2E43",
	"19-3921TCX": "#2B3042",
	"19-3922TCX": "#262934",
	"19-3923TCX": "#282D3C",
	"19-3924TCX": "#2A2A35",
	"19-3925TCX": "#363756",
	"19-3927TCX": "#3B3B48",
	"19-3929TCX": "#4A556B",
	"19-3938TCX": "#313D64",
	"19-3950TCX": "#384883",
	"19-3952TCX": "#203C7F",
	"19-3953TCX": "#253668",
	"19-4004TCX": "#2A2B2D",
	"19-4008TCX": "#2B2929",
	"19-4009TCX": "#2F3441",
	"19-4010TCX": "#2C313D",
	"19-4011TCX": "#282B34",
	"19-4012TCX": "#272F38",
	"19-4013TCX": "#2E2F36",
	"19-4014TCX": "#434854",
	"19-4015TCX": "#323137",
	"19-4019TCX": "#3C3F4A",
	"19-4020TCX": "#262B37",
	"19-4021TCX": "#2C333E",
	"19-4022TCX": "#323441",
	"19-4023TCX": "#363B48",
	"19-4024TCX": "#2A3244",
	"19-4025TCX": "#353A4C",
	"19-3964TCX": "#274374",
	"19-4033TCX": "#123955",
	"19-4035TCX": "#305679",
	"19-4049TCX": "#034F84",
	"19-4050TCX": "#1A5091",
	"19-4057TCX": "#1E4477",
	"19-4104TCX": "#41424A",
	"19-4150TCX": "#00539C",
	"19-4151TCX": "#00589B",
	"19-4215TCX": "#4E545B",
	"19-4220TCX": "#46515A",
	"19-4227TCX": "#3C586B",
	"19-4229TCX": "#3E4F5C",
	"19-4234TCX": "#0B5369",
	"19-4241TCX": "#0F4E67",
	"19-4245TCX": "#005A92",
	"19-4316TCX": "#39505C",
	"19-4318TCX": "#3A5C6E",
	"19-4324TCX": "#1F495B",
	"19-4326TCX": "#203E4A",
	"19-4329TCX": "#18576C",
	"19-4340TCX": "#005871",
	"19-4342TCX": "#005E7D",
	"19-4110TCX": "#34414E",
	"19-4121TCX": "#2C4053",
	"19-4125TCX": "#274357",
	"19-4127TCX": "#325B74",
	"19-4517TCX": "#32575D",
	"19-4524TCX": "#00585E",
	"19-4526TCX": "#1B5366",
	"19-4535TCX": "#006175",
	"19-4726TCX": "#274E55",
	"19-4818TCX": "#405E5C",
	"19-4820TCX": "#33565E",
	"19-4826TCX": "#2A5C6A",
	"19-4906TCX": "#324241",
	"19-4914TCX": "#18454B",
	"19-4916TCX": "#1F595C",
	"19-4922TCX": "#006361",
	"19-5212TCX": "#303D3C",
	"19-5217TCX": "#035453",
	"19-5220TCX": "#12403C",
	"19-5226TCX": "#005B5D",
	"19-5320TCX": "#203B3D",
	"19-5350TCX": "#23312D",
	"19-5406TCX": "#213631",
	"19-5408TCX": "#395551",
	"19-5411TCX": "#355048",
	"19-5413TCX": "#335959",
	"19-5414TCX": "#264A48",
	"19-5420TCX": "#11574A",
	"19-5511TCX": "#335749",
	"19-5513TCX": "#314F40",
	"19-5621TCX": "#43544B",
	"19-5914TCX": "#3C4E47",
	"19-5917TCX": "#35463D",
	"19-5918TCX": "#2E3D30",
	"19-5920TCX": "#334D41",
	"19-6026TCX": "#12674A",
	"19-6050TCX": "#264E36",
	"19-6110TCX": "#37413A",
	"19-6311TCX": "#37503D",
	"19-5004TCX": "#464E4D",
	"19-1536TCX": "#7B3539",
	"19-1534TCX": "#72262C",
	"19-0413TCX": "#46483C",
	"19-1429TCX": "#6E362C",
	"19-1432TCX": "#56352D",
	"19-1435TCX": "#66352B",
	"19-1419TCX": "#4A342E",
	"19-1233TCX": "#634235",
	"19-1234TCX": "#5A3E36",
	"19-1224TCX": "#5D4236",
	"19-1214TCX": "#5C3E35",
	"19-1215TCX": "#543B35",
	"19-1110TCX": "#3C2D2E",
	"19-0510TCX": "#5F5B4C",
	"19-0823TCX": "#5D5348",
	"19-0403TCX": "#5E5749",
	"19-0203TCX": "#49494D",
	"18-5025TCX": "#006865",
	"18-5845TCX": "#006E51",
	"18-5204TCX": "#615E5F",
	"18-4630TCX": "#006B7E",
	"18-4538TCX": "#0078A7",
	"18-4430TCX": "#007290",
	"18-4250TCX": "#006CA9",
	"18-4048TCX": "#2D62A3",
	"18-3950TCX": "#5B609E",
	"18-3929TCX": "#4E6482",
	"18-3917TCX": "#4D587A",
	"18-3908TCX": "#615C60",
	"18-3634TCX": "#7D5D99",
	"18-3839TCX": "#5A4E8F",
	"18-3540TCX": "#6F4685",
	"18-3530TCX": "#936A98",
	"18-3521TCX": "#624076",
	"18-3340TCX": "#632A60",
	"18-3120TCX": "#8E4483",
	"18-3014TCX": "#7B4368",
	"18-2045TCX": "#C62168",
	"18-1951TCX": "#C01352",
	"18-1759TCX": "#B2103C",
	"18-1708TCX": "#6F5965",
	"18-1705TCX": "#735B6A",
	"18-1659TCX": "#B91228",
	"18-1653TCX": "#B61032",
	"18-1654TCX": "#BA0B32",
	"18-1549TCX": "#BC322C",
	"18-1551TCX": "#B4262A",
	"18-1541TCX": "#73362A",
	"18-1506TCX": "#675657",
	"18-1440TCX": "#8E3C36",
	"18-1028TCX": "#684832",
	"17-4245TCX": "#007CB7",
	"18-1325TCX": "#783937",
	"18-1155TCX": "#935529",
	"18-1049TCX": "#AB6819",
	"18-1050TCX": "#A86217",
	"18-0625TCX": "#716A4D",
	"18-0330TCX": "#4E632C",
	"18-0220TCX": "#65663F",
	"18-0107TCX": "#5A7247",
	"17-4530TCX": "#0084A1",
	"17-4429TCX": "#3183A0",
	"17-4032TCX": "#5D89B3",
	"17-4033TCX": "#5480AC",
	"17-3914TCX": "#838487",
	"17-3929TCX": "#8895C5",
	"17-4028TCX": "#4C6A92",
	"17-4029TCX": "#5A789A",
	"17-4016TCX": "#848182",
	"17-3940TCX": "#707BB4",
	"17-3735TCX": "#9787B8",
	"17-3520TCX": "#9879A2",
	"17-3640TCX": "#9479AF",
	"17-3240TCX": "#B76BA3",
	"17-2411TCX": "#988088",
	"17-1926TCX": "#CE5B78",
	"17-2034TCX": "#CE3175",
	"17-1708TCX": "#725F69",
	"17-1450TCX": "#BE4B3B",
	"17-1361TCX": "#F45520",
	"17-1345TCX": "#B95B3F",
	"17-1145TCX": "#C46215",
	"17-1314TCX": "#897560",
	"17-1142TCX": "#8B593E",
	"17-1115TCX": "#8D7960",
	"17-1108TCX": "#948A7A",
	"17-1052TCX": "#93592B",
	"17-1105TCX": "#918579",
	"17-0949TCX": "#B1832F",
	"17-0630TCX": "#988C75",
	"16-1103TCX": "#ADA396",
	"17-0530TCX": "#797B3A",
	"16-4033TCX": "#5D81BB",
	"16-3905TCX": "#9896A4",
	"16-3720TCX": "#9F90C1",
	"16-1548TCX": "#F7786B",
	"16-1606TCX": "#98878C",
	"16-1460TCX": "#FC642D",
	"16-1347TCX": "#C5733D",
	"16-1348TCX": "#C57644",
	"16-1358TCX": "#F96714",
	"16-1545TCX": "#F77464",
	"16-1453TCX": "#F96531",
	"16-1438TCX": "#A46F44",
	"16-1345TCX": "#D27F63",
	"16-0543TCX": "#9A9738",
	"16-0545TCX": "#9C9A40",
	"16-0550TCX": "#ABA44D",
	"15-4428TCX": "#5DAFCE",
	"15-3716TCX": "#B09FCA",
	"15-3720TCX": "#B3A0C9",
	"13-3207TCX": "#F7CEE0",
	"15-3919TCX": "#91A8D0",
	"15-3520TCX": "#B99BC5",
	"15-1520TCX": "#EB9687",
	"15-1425TCX": "#C0916C",
	"15-1429TCX": "#CC7357",
	"15-1430TCX": "#BD8C66",
	"15-1264TCX": "#FE840E",
	"15-1262TCX": "#FE8C18",
	"15-1335TCX": "#FE7E03",
	"15-1243TCX": "#FEA166",
	"15-1151TCX": "#F4963A",
	"15-1125TCX": "#CCA580",
	"15-1051TCX": "#D7942D",
	"15-0960TCX": "#D69C2F",
	"15-1020TCX": "#BFA58A",
	"15-1040TCX": "#B18F6A",
	"15-1045TCX": "#D9922E",
	"14-4320TCX": "#79B5D8",
	"14-4620TCX": "#95DEE3",
	"14-4710TCX": "#82C2C7",
	"14-4615TCX": "#83C2CD",
	"14-4315TCX": "#98BFCA",
	"14-4202TCX": "#AFB1B4",
	"14-4122TCX": "#92B6D5",
	"14-4107TCX": "#B9BABD",
	"14-3912TCX": "#9FA9BE",
	"14-3906TCX": "#B1AAB3",
	"14-1803TCX": "#D4BAB6",
	"14-1315TCX": "#CFB095",
	"14-1241TCX": "#F9AA7D",
	"14-1052TCX": "#FBAA4C",
	"14-1208TCX": "#C0AC92",
	"14-0952TCX": "#D8AE47",
	"13-4810TCX": "#98DDDE",
	"13-5410TCX": "#ABD3DB",
	"13-4720TCX": "#91DCE8",
	"14-0340TCX": "#BADF30",
	"13-4201TCX": "#C3C6C8",
	"13-4104TCX": "#C6C5C6",
	"13-2808TCX": "#EBCED5",
	"13-1520TCX": "#F7CAC9",
	"13-1511TCX": "#F7CDC7",
	"13-1125TCX": "#F5B895",
	"13-1208TCX": "#E2BDB3",
	"13-1308TCX": "#DACAB7",
	"13-0851TCX": "#FED55D",
	"13-0644TCX": "#F3DD3E",
	"13-1105TCX": "#C7BBA4",
	"13-0849TCX": "#FED450",
	"13-0946TCX": "#E7AA56",
	"13-0919TCX": "#D2C29D",
	"13-0651TCX": "#CCDB1E",
	"13-0646TCX": "#EAD94E",
	"13-0443TCX": "#C0DB3A",
	"12-2901TCX": "#DFCDC6",
	"12-4301TCX": "#D6CEBE",
	"11-1302TCX": "#F1E6DE",
	"12-1813TCX": "#F7D1D4",
	"12-2907TCX": "#F8E0E7",
	"12-1110TCX": "#F5D6C6",
	"12-0742TCX": "#F3E779",
	"11-4801TCX": "#E8E3D9",
	"11-4001TCX": "#EDF1FE",
	"12-0646TCX": "#EBDF67",
	"12-0110TCX": "#D7CFBB",
	"11-4302TCX": "#F0EFE2",
	"11-0515TCX": "#F6EBC8",
	"11-0623TCX": "#ECE99B",
	"11-0607TCX": "#F3EEE7",
	"11-0608TCX": "#F0EDE5",
	"11-0110TCX": "#EFE0CD",
	"11-0106TCX": "#F0EAD6",
	"19-5030TCX": "#254445",
	"19-5230TCX": "#184A45",
	"19-5232TCX": "#15463E",
	"19-4536TCX": "#00637C",
	"19-4540TCX": "#005265",
	"19-4126TCX": "#29495C",
	"19-4122TCX": "#293B4D",
	"19-4120TCX": "#0F3B57",
	"19-3917TCX": "#4D4B50",
	"19-4055TCX": "#2A4B7C",
	"19-4045TCX": "#004B8D",
	"19-4048TCX": "#155187",
	"19-4038TCX": "#123850",
	"19-4034TCX": "#0E3A53",
	"19-4031TCX": "#35435A",
	"19-4003TCX": "#2B272B",
	"19-4029TCX": "#223A5E",
	"19-3930TCX": "#434452",
	"19-3911TCX": "#26262A",
	"19-3902TCX": "#3A363B",
	"19-3831TCX": "#27293D",
	"19-2429TCX": "#61224A",
	"19-2620TCX": "#47243B",
	"19-3750TCX": "#3E285C",
	"19-3425TCX": "#6D4773",
	"19-2420TCX": "#4D233D",
	"11-0202TCX": "#E9E2D1",
	"11-0108TCX": "#F3EBD6",
	"11-0700TCX": "#F4F7FF",
	"11-1006TCX": "#EDE4DF",
	"11-1007TCX": "#EEE0DC",
	"11-2509TCX": "#EBDBE1",
	"11-4606TCX": "#DAF2F1",
	"11-4607TCX": "#E7F4F2",
	"12-0602TCX": "#E6DECF",
	"12-0735TCX": "#ECD966",
	"12-0908TCX": "#D6CBBA",
	"12-1303TCX": "#F4DDDF",
	"12-1105TCX": "#F3DBD3",
	"12-2908TCX": "#F5D6E6",
	"12-4108TCX": "#CCD6E6",
	"12-4202TCX": "#C2DAE8",
	"12-4300TCX": "#D7D6CE",
	"12-1808TCX": "#F7C9CF",
	"12-1406TCX": "#D8C8BD",
	"12-2807TCX": "#F3D4DF",
	"12-4303TCX": "#CBD9E7",
	"12-4611TCX": "#CBE9F2",
	"12-4401TCX": "#B4DCEA",
	"13-0400TCX": "#CBC1B3",
	"13-0003TCX": "#D3C9BE",
	"12-5703TCX": "#CDD4CB",
	"12-5207TCX": "#C9EDED",
	"12-5208TCX": "#D1EFED",
	"13-0647TCX": "#F5DF4D",
	"13-0404TCX": "#CBC3B7",
	"12-5302TCX": "#BAD7D9",
	"13-0749TCX": "#F1CD39",
	"13-0751TCX": "#F1C700",
	"13-0643TCX": "#D5D859",
	"13-0753TCX": "#E8D11F",
	"13-0754TCX": "#F6CD4C",
	"13-0760TCX": "#F0CC4A",
	"13-0757TCX": "#DFB136",
	"13-1104TCX": "#DBCABA",
	"13-1501TCX": "#F5D4CD",
	"13-1716TCX": "#FFBBB9",
	"13-2007TCX": "#E6C4C5",
	"13-2008TCX": "#E8B4C2",
	"13-2809TCX": "#EFBED4",
	"13-4202TCX": "#BFD5EB",
	"13-4302TCX": "#C5D1DD",
	"13-4401TCX": "#C0E3F0",
	"13-4410TCX": "#C3E1EE",
	"13-4306TCX": "#D3E4F1",
	"13-4307TCX": "#88CDE7",
	"13-2800TCX": "#F0C9E1",
	"13-2801TCX": "#F7CDDB",
	"13-2003TCX": "#F7C0BF",
	"13-5411TCX": "#A3CCD6",
	"13-4908TCX": "#B4E8EC",
	"14-0442TCX": "#A6BE47",
	"14-0443TCX": "#BCCA25",
	"14-1026TCX": "#CCB584",
	"14-0757TCX": "#E3BD33",
	"14-0758TCX": "#D7AC00",
	"14-0849TCX": "#F3C65F",
	"14-0745TCX": "#E2C207",
	"14-0750TCX": "#DCC13A",
	"14-0751TCX": "#E9BE21",
	"14-1904TCX": "#E8AFB6",
	"14-3205TCX": "#EDBEDC",
	"14-3203TCX": "#DBB4C8",
	"14-2806TCX": "#F0BDD5",
	"14-2304TCX": "#D5ADB8",
	"14-0958TCX": "#E7B200",
	"14-1910TCX": "#F7B9C2",
	"14-4005TCX": "#B7D1EA",
	"14-4104TCX": "#BABEBF",
	"14-4123TCX": "#9BBCD8",
	"14-4124TCX": "#96BAE0",
	"14-4209TCX": "#A4B4C6",
	"14-4316TCX": "#A1C3DA",
	"14-4215TCX": "#AFC3D3",
	"14-4211TCX": "#ABB9C9",
	"14-4618TCX": "#96D8DE",
	"14-4715TCX": "#9FDFE4",
	"14-4513TCX": "#76B5C7",
	"14-4514TCX": "#9AD4EA",
	"14-4515TCX": "#8DCCDD",
	"15-0635TCX": "#CCB932",
	"15-0549TCX": "#B9BD2B",
	"15-0544TCX": "#A2A524",
	"14-4818TCX": "#77BABE",
	"14-4815TCX": "#7FBFC5",
	"15-0542TCX": "#A29C1F",
	"15-0647TCX": "#CEB00D",
	"15-0954TCX": "#D79D00",
	"15-1115TCX": "#B7A990",
	"15-0956TCX": "#E9A900",
	"15-1915TCX": "#EB7C96",
	"15-1919TCX": "#FC97A5",
	"15-1822TCX": "#FB90A2",
	"15-1712TCX": "#F8A3A4",
	"15-3216TCX": "#D39FC8",
	"15-2218TCX": "#F1A9CB",
	"15-2213TCX": "#F1A6CC",
	"15-4009TCX": "#939EB3",
	"15-3917TCX": "#89A5CB",
	"15-4304TCX": "#8B9198",
	"15-4031TCX": "#A3BBE0",
	"15-3922TCX": "#94B2DF",
	"16-0436TCX": "#9EA450",
	"15-4713TCX": "#54AFBC",
	"15-4504TCX": "#B9B1AB",
	"15-4710TCX": "#63B7C5",
	"15-4711TCX": "#6AA8AF",
	"16-0229TCX": "#8FAB3A",
	"16-0544TCX": "#97913A",
	"16-0640TCX": "#9B892F",
	"16-0634TCX": "#8D8646",
	"16-0541TCX": "#A19436",
	"15-4716TCX": "#62BBC5",
	"16-0531TCX": "#919047",
	"16-1101TCX": "#AA9D89",
	"16-1102TCX": "#B4AA9C",
	"16-1211TCX": "#9F8D81",
	"16-1119TCX": "#A1947D",
	"16-1213TCX": "#A69483",
	"16-0838TCX": "#CDB127",
	"16-0748TCX": "#BC9312",
	"16-3321TCX": "#B977AE",
	"16-2118TCX": "#D56793",
	"16-1615TCX": "#DE838D",
	"16-4016TCX": "#91A5BE",
	"16-4018TCX": "#6B86AA",
	"16-3923TCX": "#A2A9D1",
	"16-3917TCX": "#8D8E92",
	"16-4022TCX": "#7E95B5",
	"16-4023TCX": "#8192AB",
	"16-4029TCX": "#7291C1",
	"16-4520TCX": "#69BBDD",
	"16-5102TCX": "#979799",
	"16-4526TCX": "#38A4D0",
	"16-4121TCX": "#7198BB",
	"16-4118TCX": "#81A0BA",
	"16-2122TCX": "#F07BA5",
	"17-0208TCX": "#8C8A7F",
	"17-0209TCX": "#909189",
	"17-0340TCX": "#728839",
	"17-0345TCX": "#6B8542",
	"17-0529TCX": "#757331",
	"17-0619TCX": "#898270",
	"17-0332TCX": "#6D8F3E",
	"17-0232TCX": "#60843C",
	"16-5426TCX": "#1BA491",
	"17-0807TCX": "#897569",
	"17-1112TCX": "#9B8F7F",
	"17-1317TCX": "#917567",
	"17-1318TCX": "#A28774",
	"17-1221TCX": "#AC8266",
	"17-1409TCX": "#7E695F",
	"17-1324TCX": "#9C7961",
	"17-1325TCX": "#A88968",
	"17-1508TCX": "#7C7977",
	"17-1640TCX": "#BE394F",
	"17-1741TCX": "#C54966",
	"17-1412TCX": "#837067",
	"17-1739TCX": "#C63C5F",
	"17-2235TCX": "#E96393",
	"17-2519TCX": "#CB5B8A",
	"17-1746TCX": "#F05E73",
	"17-2619TCX": "#D96BA1",
	"17-2623TCX": "#CA669D",
	"17-3310TCX": "#B380AA",
	"17-3320TCX": "#A8639C",
	"17-2521TCX": "#C35989",
	"17-3912TCX": "#657088",
	"17-3913TCX": "#86868C",
	"17-4013TCX": "#7B797B",
	"17-3926TCX": "#76849B",
	"17-4018TCX": "#708497",
	"17-4019TCX": "#7A92A5",
	"17-4129TCX": "#2D80A7",
	"17-4725TCX": "#00899B",
	"17-5440TCX": "#00847A",
	"17-5104TCX": "#939597",
	"18-0202TCX": "#5E6064",
	"17-6154TCX": "#008C4E",
	"17-5730TCX": "#008A7A",
	"17-5527TCX": "#007667",
	"17-4140TCX": "#5C97CB",
	"18-0226TCX": "#3E5E32",
	"18-0230TCX": "#4F6C35",
	"18-0323TCX": "#627C4C",
	"18-0514TCX": "#696B62",
	"18-0428TCX": "#6D6B3A",
	"18-1014TCX": "#816852",
	"18-0516TCX": "#6A685D",
	"18-0517TCX": "#616054",
	"18-0529TCX": "#62633D",
	"18-0530TCX": "#7C823F",
	"18-0537TCX": "#7D7F2E",
	"18-1019TCX": "#856A57",
	"18-1020TCX": "#876D58",
	"18-1024TCX": "#815B3C",
	"18-1025TCX": "#8A6749",
	"18-0540TCX": "#828B33",
	"18-1125TCX": "#7B5947",
	"18-1323TCX": "#7E3F3A",
	"18-1324TCX": "#907162",
	"18-1422TCX": "#846157",
	"18-1307TCX": "#695650",
	"18-1243TCX": "#875341",
	"18-1232TCX": "#6D4D3E",
	"18-1341TCX": "#884D40",
	"18-1453TCX": "#A73730",
	"18-1432TCX": "#9B574B",
	"18-1552TCX": "#9A2B2E",
	"18-1553TCX": "#B31A23",
	"18-1559TCX": "#D0342C",
	"18-1665TCX": "#C63A4F",
	"18-1563TCX": "#BB3339",
	"18-1722TCX": "#833A5B",
	"18-1750TCX": "#BB2649",
	"18-2042TCX": "#C43362",
	"18-2615TCX": "#A3497C",
	"18-2330TCX": "#CA4286",
	"18-3024TCX": "#88447B",
	"18-3223TCX": "#9C4C8D",
	"18-3906TCX": "#575358",
	"18-3923TCX": "#4F677A",
	"18-3919TCX": "#536679",
	"18-3936TCX": "#5E79A8",
	"18-4010TCX": "#6F6C70",
	"18-4004TCX": "#626367",
	"18-4143TCX": "#3073B7",
	"18-4221TCX": "#667E91",
	"18-4037TCX": "#296C97",
	"18-4218TCX": "#496275",
	"18-4035TCX": "#557EA2",
	"18-4022TCX": "#345E8B",
	"18-4017TCX": "#79797F",
	"18-4015TCX": "#77777A",
	"18-4033TCX": "#527EA9",
	"18-4631TCX": "#006E7F",
	"18-4835TCX": "#00666C",
	"18-4833TCX": "#00656A",
	"18-5206TCX": "#5B585A",
	"18-5016TCX": "#006D65",
	"18-6026TCX": "#006339",
	"18-6028TCX": "#227949",
	"18-5425TCX": "#00695B",
	"19-0205TCX": "#414143",
	"19-0220TCX": "#36492E",
	"19-0404TCX": "#4C4D44",
	"19-0913TCX": "#493A35",
	"19-0231TCX": "#395228",
	"18-6031TCX": "#00723B",
	"18-6033TCX": "#007449",
	"19-1119TCX": "#594536",
	"19-1100TCX": "#3B3739",
	"19-1216TCX": "#56443F",
	"19-1223TCX": "#5E3F32",
	"19-1227TCX": "#6B4331",
	"19-0406TCX": "#4D4C47",
	"19-1428TCX": "#714439",
	"19-1242TCX": "#6F4433",
	"19-1529TCX": "#5B2B38",
	"19-1527TCX": "#6B3339",
	"19-1537TCX": "#7E212A",
	"19-1541TCX": "#7A3B31",
	"19-1558TCX": "#9F2436",
	"19-1554TCX": "#951528",
	"19-1653TCX": "#8C2230",
	"19-1657TCX": "#8C2A37",
	"19-1875TCX": "#8E1F2C",
	"19-1755TCX": "#9E1528",
	"19-1756TCX": "#B01F32",
	"19-3811TCX": "#2F2C3F",
	"19-3812TCX": "#272836",
	"19-3851TCX": "#323455",
	"19-3912TCX": "#484849",
	"19-3932TCX": "#2E344A",
	"19-3937TCX": "#252A48",
	"19-3934TCX": "#3C4862",
	"19-3916TCX": "#38373D",
	"19-3838TCX": "#302C4D",
	"19-4016TCX": "#FFFFFF",
	"19-4017TCX": "#333B4C",
	"19-4018TCX": "#2E2E3B",
	"19-3954TCX": "#333E83",
	"19-3942TCX": "#252A50",
	"19-3943TCX": "#23305B",
	"19-3944TCX": "#343646",
	"19-4041TCX": "#2E5283",
	"19-4042TCX": "#284972",
	"19-4054TCX": "#3A5589",
	"19-4051TCX": "#323841",
	"19-4058TCX": "#32508E",
	"19-4105TCX": "#343540",
	"19-4108TCX": "#323C46",
	"19-4109TCX": "#30343F",
	"19-4111TCX": "#1F2C43",
	"19-4112TCX": "#313F4A",
	"19-4117TCX": "#354253",
	"19-4128TCX": "#263B51",
	"19-4129TCX": "#34465A",
	"19-4534TCX": "#16525A",
	"19-4523TCX": "#0A4F5C",
	"19-4405TCX": "#33393B",
	"19-4406TCX": "#3C3E40",
	"19-4727TCX": "#27535A",
	"19-4908TCX": "#2E3C41",
	"19-5002TCX": "#383D3F",
	"19-4918TCX": "#075055",
	"19-6027TCX": "#005D43",
	"19-5421TCX": "#005348",
	"19-5005TCX": "#3B3D3C",
	"19-5025TCX": "#1D3F43",
	"19-6350TCX": "#1F5129"
}

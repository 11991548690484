import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index.vue";
import review from "../views/review/index.vue";
import draw from "../views/draw/index.vue";
import login from "../views/system/account/login";
import register from "../views/system/account/register";
import forgetPassword from "../views/system/account/forgetPassword";
import productDetail from "../views/productDetail/index";
import profile from "../views/profile/index";
import lists from "../views/lists/index";
import material from "@/components/assets/index.vue";
import VueCookies from "vue-cookie";
import Layout from "../layout/default.vue";
import im from "../components/im/im.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/Home",
    name: "Home",
    component: () => import("@/views/home/index.vue"),
    meta: {
      keepAlive: true,
      auth: true,
    },
  },
  {
    path: "/draw",
    name: "draw",
    component: () => import("@/views/draw/index.vue"),
    meta: {
      keepAlive: true,
      auth: true,
      isBack: false, //用于判断是否返回
	  hideNav:true
    },
  },
  {
    path: "/review",
    name: "review",
    component: () => import("@/views/review/index.vue"),
    meta: {
      auth: true,
      hideNav: true,
    },
  },
  {
    path: "/productDetail",
    name: "productDetail",
    component: () => import("@/views/productDetail/index.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/",
    name: "profile",
    component: () => import("@/views/profile/index.vue"),
    meta: {
      auth: true,
    },
  },

  // 登录
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/system/account/login"),
  },
  // 注册
  {
    path: "/register",
    name: "register",
    component: register,
  },
  // 忘记密码
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: forgetPassword,
  },
  // 表单
  {
    path: "/lists",
    name: "lists",
    component: () => import("@/views/lists/index.vue"),
  },
  // 添加IM
  {
    path: "/im",
    name: "im",
    component: im,
  },
  {
    path: "/crm",
    name: "crm",
    redirect: "/crm/lead?menu=publicInquiry",
    component: () => import("@/views/crm/index.vue"),
    children: [
      {
        path: "lead",
        name: "lead",
        component: () => import("@/views/crm/lead/index.vue"),
      },
      {
        path: "recommend",
        name: "recommend",
        component: () => import("@/views/crm/recommend/index.vue"),
      },

      {
        path: "customer",
        name: "customer",
        component: () => import("@/views/crm/customer/index.vue"),
      },
      {
        path: "crmLists",
        name: "crmLists",
        component: () => import("@/views/crm/lists/index.vue"),
      },
	  {
	    path: "material",
	    name: "material",
	    component: () => import("@/views/crm/material/index.vue"),
	  },
	  {
	    path: "supplier",
	    name: "supplier",
	    component: () => import("@/views/crm/material/supplier.vue"),
	  }
    ],
  },
  {
    path: "/crm",
    name: "crm",
    redirect: "/crm/company",
    component: () => import("@/views/crm/index.vue"),
    children: [
      {
        path: "company",
        name: "company",
        component: () => import("@/views/crm/company/index.vue"),
      },
    ],
  },
  {
    path: "/recommend-preview/:id",
    name: "recommendPreview",
    component: () => import("@/views/crm/preview/index.vue"),
  },
  {
    path: "/workspace",
    component: Layout,
    redirect: "workspace",
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/workspace",
        name: "workspace",
        meta: {
          hideNav: true,
        },
        component: () => import("@/views/Work.vue"),
      },
    ],
  },
  {
    path: "/project-group",
    component: Layout,
    redirect: "/project-group",
    meta: {
      auth: true,
      keepAlive: false,
    },
    children: [
      {
        path: "/project-group",
        name: "project-group",
        component: () => import("@/views/project-group/index.vue"),
      },
    ],
  },
  // {
  //   path: "/im",
  //   redirect: "im",
  //   component: () => import("@/layout/blank.vue"),
  //   meta: {
  //     auth: true,
  //     hideNav: true
  //   },
  //   children: [
  //     {
  //       path: "/im",
  //       name: "im",
  //       component: () => import("@/components/v2/im/Index.vue"),
  //       meta: {
  //         auth: true,
  //         hideNav: true
  //       }
  //     }
  //   ]
  // },

  {
    path: "*",
    redirect: "/404",
    component: () => import("@/layout/blank.vue"),
    meta: {
      auth: true,
      hideNav: true,
    },
    children: [
      {
        path: "/404",
        component: () => import("@/views/404.vue"),
        meta: {
          auth: true,
          hideNav: true,
        },
      },
    ],
  },
  {
    path: "/assets",
    name: "assets",
    component: material,
  },
  {
    path: "/workspace",
    name: "workspace",
    meta: {
      hideNav: true,
    },
    component: () => import("@/components/WorkSpace.vue"),
  },
  {
    path: "/materialsEdit",
    name: "materialsEdit",
    meta: {
      hideNav: false,
    },
    component: () => import("@/components/commonPage/materialsEdit.vue"),
  },
  {
    path: "/3dVisaf",
    name: "3dVisaf",
    meta: {
      hideNav: true,
    },
    component: () => import("@/views/Work.vue"),
  },
  {
    path: "/3dDesign",
    name: "3dDesign",
    meta: {
      hideNav: true,
    },
    component: () => import("@/components/WorkSpace.vue"),
  },
  {
    path: "/market",
    name: "market",
    meta: {
      hideNav: false,
    },
    component: () => import("@/views/market/index.vue"),
  },
  {
	path:"/materialDetail",
	name:"materialDetail",
	meta: {
	  hideNav: true,
	},
	component:()=>import("@/views/crm/material/materialDetail.vue")
  }
];
const router = new VueRouter({
  // mode: 'history',
  routes,
});
/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  //判断是否需要登录
  if (to.matched.some((r) => r.meta.auth)) {
    const token = VueCookies.get("token");
    if (token && token !== "undefined") {
      next();
    } else if (to.name == "3dDesign") {
      next({
        name: "3dDesign",
      });
    }else if(to.name == "workspace"){
		next({
		  name: "workspace",
		});
	}else if(to.name == "profile"){
		next();
	}else if(to.name == "materialDetail"){
		next({
		  name: "materialDetail",
		});
	} else {
      //没有登录的时候跳转到登录界面
      next({
        name: "login",
      });
    }
  } else {
    next();
  }
  //检测路由是否携带参数
  const checkIdRouter = ["workspace", "draw", "productDetail"];
  if (checkIdRouter.indexOf(to.name) >= 0) {
    if (to.query.id || to.query.type || to.query.product_id) {
      next();
    } else {
      next({
        path: "/404",
      });
    }
  }
});
export default router;

<template>
  <div class="product-detail-box">
    <div class="back-header-box">
      <div class="back-btn-box" @click="backPage">
        <i class="iconfont icon-arrowLeft-fill back-btn"></i>
        <span>Back</span>
      </div>
    </div>

    <div class="product-basic-box">
      <div class="product-msg-box">
        <div class="product-detail-img-box" v-if="itemData">
			<span class="design-type">{{
			  itemData.type == 1
				? $t("design")
				: itemData.type == 2
				? $t("threeD")
				: $t("goods")
			}}</span>
          <div class="product-image-box" ref="cartRef"> 
            <div class="product-image-item" v-if="swiperList.length == 0">
              <el-image
                v-if="itemData.type == 3 && itemData.file_info.gif_file"
                class="product-image"
                :src="
                  itemData.file_info.gif_file + '?imageMogr2/thumbnail/!70p'
                "
                fit="contain"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <el-image
                v-else
                class="product-image"
                :src="itemData.file_info.main_file.img_url"
                fit="contain"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <el-carousel
              :autoplay="false"
              style="width: 100%; height: 100%; padding: 30px"
              ref="carousel"
              v-else
            >
              <el-carousel-item
                v-for="(val, key) in swiperList"
                :key="key"
                name="key"
                style="height: 100%"
              >
                <el-image
                  class="product-image"
                  :src="val.img_url"
                  fit="contain"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-carousel-item>
            </el-carousel>
            <!--          <cart-animation-->
            <!--            :visible.sync="showCartAni"-->
            <!--            :product="createForm"-->
            <!--            :templateData="templateData"-->
            <!--            :x="x"-->
            <!--            :y="y"-->
            <!--          ></cart-animation>-->
          </div>
          <div class="product-mini-img-box" v-if="swiperList.length > 0">
            <div
              class="product-mini-img"
              :class="[val.img_url ? '' : 'product-mini-none']"
              v-for="(val, key) in miniPicList"
              :key="key"
            >
              <el-image
                @click="setActiveItem(key)"
                v-if="val.img_url"
                class="mini-img"
                :src="val.img_url"
                fit="contain"
              >
              </el-image>
              <div v-else></div>
            </div>
          </div>
        </div>

        <div class="product-msg">
          <aggregation :data="itemData.statistics"></aggregation>
          <div class="product-name">
            {{ itemData.name }}
          </div>
          <div class="design-tag-box">
            <div class="card-icon-box">
              <i class="iconfont icon-yanjing card-icon"></i>
              <span>{{itemData.view_count}}</span>
            </div>
            <div class="card-icon-box">
              <i
                v-if="itemData.is_like_count == 0"
                @click="addProject"
                class="iconfont icon-shoucang card-icon"
              ></i>
              <i
                class="iconfont icon-shoucangxuanzhong card-icon"
                @click="delProject"
                v-else
              ></i>
              <span>{{itemData.like_count}}</span>
            </div>
            <div class="card-icon-box" @click.stop="shareGoods">
              <i class="iconfont icon-fenxiang card-icon"></i>
              <span>{{itemData.share_count}}</span>
            </div>
          </div>
          <div class="product-msg-detail">
            {{ itemData.intro }}
          </div>
          <div class="product-msg-price">${{ itemData.price }}</div>
          <div class="product-title">Product details</div>
          <ul v-if="itemData.desc && itemData.desc.length > 0">
            <li
              class="product-msg-detail-item"
              v-for="(val, key) in itemData.desc"
              :key="key"
            >
              {{ val }}
            </li>
          </ul>
		  <div class="product-msg-detail">{{$t("modelName")}} : {{ itemData.model_name }}</div>
		  <div class="product-msg-detail">{{$t("styleNumber")}} : {{ itemData.style_number }}</div>
		  <div class="product-msg-detail">{{$t("styleColor")}} : {{ itemData.color }}</div>
		  <div class="product-msg-detail">{{$t("size")}} : {{ itemData.size }}</div>
          
		  
        </div>
		<div class="product-detail-bottom">
		            <!--            <div>-->
		            <!--              <i-->
		            <!--                class="iconfont icon-shoucang product-like-box"-->
		            <!--                v-if="itemData.is_like_count == 0"-->
		            <!--                @click.stop="addProject"-->
		            <!--              ></i>-->
		            <!--              <i-->
		            <!--                class="iconfont icon-shoucangxuanzhong product-is-like-box"-->
		            <!--                v-else-->
		            <!--                @click="delProject"-->
		            <!--              ></i>-->
		            <!--            </div>-->
		
		            <el-dropdown
		              trigger="click"
		              v-if="projectList && projectList.length > 0"
		              @command="toBindProject"
		            >
		              <div class="product-detail-btn">
		                {{ $t("addProject") }}
		              </div>
		              <div>
		                <el-dropdown-menu class="project-dropdown" slot="dropdown">
		                  <el-dropdown-item
		                    v-for="(val, key) in projectList"
		                    :command="val.id"
		                    :key="key"
		                    >{{ val.project_name }}</el-dropdown-item
		                  >
		                </el-dropdown-menu>
		              </div>
		            </el-dropdown>
		            <div class="product-detail-btn" @click="noneProject" v-else>
		              {{ $t("addProject") }}
		            </div>
		            <div class="product-detail-btn" v-if="type == 1" @click="toDesign">
		              {{ $t("toDesign") }}
		            </div>
		            <!--            <div v-if="userRole === 'custom'">-->
		            <!--              <div-->
		            <!--                class="product-detail-btn"-->
		            <!--                @click="onAddCart"-->
		            <!--                v-if="nowFormId != 0"-->
		            <!--              >-->
		            <!--                添加至当前表单-->
		            <!--              </div>-->
		            <!--              <div class="product-detail-btn" @click="toCreateForm" v-else>-->
		            <!--                创建表单-->
		            <!--              </div>-->
		            <!--            </div>-->
		
		            <div class="product-detail-btn" v-if="type == 2" @click="toThreeD">
		              {{ $t("threedDesign") }}
		            </div>
					<div class="btn-group-box" v-if="userRole === 'company'">
						<div class="product-detail-btn" @click="showBtn = !showBtn">
							{{$t('operate')}} 
							<i class="el-icon-arrow-down" v-if="showBtn"></i>
							<i class="el-icon-arrow-up" v-else></i>
						</div>
						<div class="btn-item" v-show="showBtn" :style="{top:type==2?'-70px':'-100px'}">
							<div class="product-detail-btn" v-if="type == 1 || type == 3" @click="toShowEdit(3)">{{$t('addColor')}}</div>
							<div class="product-detail-btn" @click="toShowEdit(2)">{{$t('edit')}}</div>
							<div class="product-detail-btn" @click="delProduct">{{$t('delete')}}</div>
						</div>
					</div>
		            <!-- <div
		              class="product-detail-btn"
		              @click="toShowEdit"
		              v-if="userRole === 'company'"
		            >
		              {{ $t("editDesign") }}
		            </div> -->
		            <div
		                class="product-detail-btn del-btn"
		                @click="delProduct"
		                v-if="userRole === 'company'"
		            >
		              {{$t("delete")}}
		            </div>
		<!--            <div-->
		<!--                class="product-detail-btn"-->
		<!--                @click="toShowEdit"-->
		<!--                v-if="userRole === 'company'"-->
		<!--            >-->
		<!--              {{ $t("editDesign") }}-->
		<!--            </div>-->
		          </div>
      </div>
    </div>
    <div class="near-product-box">
      <div class="near-product-header">
        <div
          class="same-btn"
          :class="{ 'same-btn-active': currentTab == 1 }"
          @click="changeTab(1)"
        >
          {{ $t("sameBottom") }}
        </div>
        <div
          class="same-btn"
          :class="{ 'same-btn-active': currentTab == 2 }"
          @click="changeTab(2)"
        >
          {{ $t("sameClass") }}
        </div>
      </div>
      <div class="design-ul" v-if="relatedList && relatedList.length > 0">
        <designView
          v-for="(val, key) in relatedList"
          :key="key"
          :itemData="val"
          :detailType="2"
          @changeDetail="changeDetail"
        ></designView>
        <!--  占位元素  -->
        <div class="data-item temp" v-for="i in 10" :key="i + 'key'"></div>
      </div>
      <div class="empty-box" v-else>
        <emptyPage></emptyPage>
      </div>
    </div>
    <!--    <productDialog-->
    <!--      :createType="2"-->
    <!--      @editProduct="editProduct"-->
    <!--      :createForm="createForm"-->
    <!--      :itemData="itemData"-->
    <!--      @closeModal="closeModal"-->
    <!--      v-if="dialogCreateVisible"-->
    <!--    ></productDialog>-->
    <editProductDialog
      v-if="dialogCreateVisible"
      :createType="createType"
      @editProduct="editProduct"
      @createProduct="createProduct"
      :itemData="itemData"
      :form="createForm"
      @closeModal="closeModal"
    ></editProductDialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import designView from "@/components/designView/designView";
import aggregation from "@/components/aggregation";
import emptyPage from "../../components/emptyPage";
import productDialog from "../../components/productDialog/productDialog";
import CartAnimation from "@/components/cart-animation/index.vue";
import editProductDialog from "@/components/editProductDialog/editProductDialog";
let that;
export default {
  name: "productDetail",
  components: {
    designView,
    emptyPage,
    productDialog,
    aggregation,
    editProductDialog,
  },
  data() {
    return {
      id: "",
      type: 1,
      bomType: 0,
      itemData: {},
      swiperList: [],
      miniPicList: [],
      currentTab: 1,
      relatedList: [],
      projectList: [],
      dialogCreateVisible: false,
      createForm: {
        id: "",
        name: "",
        style_number: "",
        model_name: "",
        type: 1,
        file_info: {},
        price: "",
        intro: "",
        is_public: 1,
        desc: [""],
        color: "",
        size: "",
		customPrivate:[]
      },
      x: 0,
      y: 0,
      cartRef: null,
      showCartAni: false,
      nowFormBtnType: "create",
      templateData: {},
	  showBtn:false,
	  createType:2
    };
  },
  computed: {
    ...mapState("jiyang/menu", ["menuType"]),
    ...mapState("jiyang/user", ["info", "userRole"]),
    ...mapState("jiyang/cart", ["info", "nowFormId"]),
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name == "productDetail") {
          this.id = this.$route.query.id;
          this.type = this.$route.query.type;
          this.getDetail();
        }
      },
      deep: true,
    },
  },
  created() {
    that = this;
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getDetail();
    this.toGetProjectList();
  },
  methods: {
    ...mapMutations("jiyang/cart", ["setFormId"]),
    //获取详情
    getDetail() {
      let data = {
        id: this.id,
      };
      let loading = this.openLoading();
      this.swiperList = [];
      this.miniPicList = [];
      this.$api.getProductDetail(data).then(async (res) => {
        if (!res) {
          this.$router.replace({
            path: "/404",
          });
          loading.close();
          return false;
        }
        await this.toGetFormList();
        if (res.file_info.remark_file) {
          if (res.file_info.remark_file.length > 0) {
            if (res.type == 3 && res.file_info.gif_file) {
              this.swiperList.push({
                img_url: res.file_info.gif_file,
              });
            }
            this.swiperList.push({
              img_url: res.file_info.main_file.img_url,
            });
            let list = res.file_info.remark_file.map((val) => {
              return { img_url: val };
            });
            this.swiperList = [...this.swiperList, ...list];
            this.miniPicList = JSON.parse(JSON.stringify(this.swiperList));

            if (this.swiperList.length >= 5) {
              this.miniPicList = this.swiperList.slice(0, 5);
            } else {
              let count = 5 - this.swiperList.length;
              for (let i = 0; i < count; i++) {
                this.miniPicList.push({ img_url: "" });
              }
            }
          }
        }
        Object.keys(that.createForm).forEach((key) => {
          that.createForm[key] = res[key];
        });
        that.itemData = res;
        loading.close();
        that.toGetRelatedList();
      });
    },
    //深拷贝
    deepClone(params) {
      console.log(params);
      if (typeof params !== "object") return params;
      let newObj = Array.isArray(params) ? [] : {};
      for (let i in params) {
        newObj[i] = this.deepClone(params[i]);
      }
    },
    //分享商品
    shareGoods() {
      this.$store.commit("callIM", {
        id: this.itemData.id,
        name: this.itemData.name,
        url: this.itemData.file_info.main_file.img_url,
        type: this.itemData.type,
      });
    },
    //切换轮播
    setActiveItem(key) {
      this.$refs.carousel.setActiveItem(key);
    },
    //获取表单列表
    toGetFormList() {
      let data = {
        page: 1,
        page_size: 1,
        type: 1,
        search: {
          state: 1,
        },
      };
      this.$api.getFormList(data).then(async (res) => {
        if (res.total > 0) {
          this.setFormId(res.data[0].id);
          await this.getFormDetail(res.data[0].id);
        } else {
          this.setFormId(0);
        }
      });
    },
    //获取表单信息
    async getFormDetail(id) {
      let data = {
        id,
      };
      this.$api.getForm(data).then((res) => {
        this.templateData = res.data;
      });
    },
    closeModal() {
      this.dialogCreateVisible = false;
    },
    //编辑信息
    toShowEdit(type) {
	  this.createType = type;
      this.dialogCreateVisible = true;
	  this.showBtn = false;
    },
    editProduct(type) {
      if (type === 1) {
        this.dialogCreateVisible = false;
      }
      this.getDetail();
    },
    createProduct(){
      this.dialogCreateVisible = false;
      this.getDetail();
    },
    delProduct(){
		this.showBtn = false;
      let data={
        id:this.id
      }
      this.$api.deleteProduct(data).then(res=>{
        this.$message.error('删除成功')
        this.backPage()
      })

    },
    //获取项目组列表
    toGetProjectList() {
      this.$api.queryFolders("").then((res) => {
        this.projectList = res.data;
      });
    },
    //添加到项目
    toBindProject(command) {
      let data = {
        product_id: this.id,
        project_id: command,
      };
      this.$api.goodsJoinProject(data).then((res) => {
        this.$message.success(that.$t("addSuccess"));
      });
    },
    noneProject() {
      this.$message.error(that.$t("noneProject"));
    },
    //切换
    changeTab(type) {
      this.currentTab = type;
      this.toGetRelatedList();
    },
    //获取相关设计
    toGetRelatedList() {
      if (this.currentTab == 1) {
        let data = {
          id: this.id,
          model_name: this.itemData.model_name,
        };
        this.$api.getRelatedList(data).then((res) => {
          this.relatedList = res;
        });
      } else {
        let data = {
          id: this.id,
          style_number: this.itemData.style_number,
        };
        this.$api.getRelatedList(data).then((res) => {
          this.relatedList = res;
        });
      }
    },

    //跳转拉线图
    toDesign() {
      let data = this.itemData;
      data["style_color"] = this.itemData.color;
	  data = JSON.stringify(data);
      this.$router.push({
        name: "draw",
        query: {
          type: 1,
          imageUrl: this.itemData.file_info.main_file.img_url,
          product_id: this.itemData.id,
          basicMsg: data,
        },
      });
    },
    //跳转3D页面
    toThreeD() {
      this.$router.push({
        name: "workspace",
        query: {
          product_id: this.itemData.id,
          file: this.itemData.file_info.main_file.file,
          model_versions: this.itemData.model_versions,
        },
      });
    },
    //切换数据
    changeDetail(data) {
      this.id = data.id;
      this.type = data.type;
      this.getDetail();
    },
    //添加心愿单
    addProject() {
      let data = {
        product_id: this.itemData.id,
        type: 1,
      };
      this.$api.addBaseProject(data).then((res) => {
        this.itemData.is_like_count = 1;
      });
    },
    //移除心愿单
    delProject() {
      let data = {
        product_id: this.itemData.id,
        type: 1,
      };
      this.$api.delBaseProject(data).then((res) => {
        this.itemData.is_like_count = 0;
      });
    },
    //返回
    backPage() {
      this.$router.back();
    },
    //跳转创建表单
    toCreateForm() {
      this.createForm["img_url"] = this.createForm.file_info.main_file.img_url;
      this.$router.push({
        path: "lists",
        query: {
          type: 1,
          isCreate: true,
          product: JSON.stringify(this.createForm),
        },
      });
    },
    onAddCart() {
      this.showCartAni = true;
      let target = this.$refs.cartRef.querySelector(".product-image");
      const { x, y, width, height } = target.getBoundingClientRect();
      this.x = y + height / 2;
      this.y = x + width / 2;
    },
  },
};
</script>

<style lang="scss">
@import "productDetail";
.product-detail-box .product-basic-box .product-msg-box .product-image-box {
  position: relative;
}
.design-type{
	position: relative;
	top: -200px;
	left: 62px;
	z-index: 10;
}

.btn-group-box{
	position: absolute;
	top: 0px;
	height: 100px;
	
	.btn-item{
		position: absolute;
		top:-100px;
		
		.product-detail-btn{
			background-color:#fff !important;
			color:#000 !important;
			border:1px solid #d6d3d3;
		}
	}
}
</style>

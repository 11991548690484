<template>
  <div class="total-table" ref="totalTable">
    <div v-if="type === 1 && !isUnEdit">
      <div class="add-table-box" @click="addRow" v-if="!checkEdit">
        <i class="iconfont icon-ic_add"></i>
        <span class="add-text">New</span>
      </div>
    </div>
    <div v-else></div>
    <div class="total-table-position">
      <el-table
        :data="totalList"
        header-cell-class-name="table-header"
        :border="true"
        :show-header="false"
        style="width: 240px"
      >
        <el-table-column prop="title" label="Title" align="left">
          <template slot-scope="scope">
            <div>
              <div
                class="discount-box"
                v-if="scope.row.type === 'input'"
                @click="toEditDiscount"
              >
                {{ scope.row.title
                }}<span class="discount-color">({{ discountPercent }})</span>
                <el-card class="discount-input-box" v-if="isShowDiscount">
                  <el-input
                    ref="discount"
                    v-model="totalData.discount"
                    size="mini"
                    maxlength="3"
                    @keyup.native="
                      totalData.discount = totalData.discount.replace(
                        /[^0-9_]/g,
                        ''
                      )
                    "
                    @blur="hideDiscount"
                  ></el-input>
                </el-card>
              </div>
              <span v-else>{{ scope.row.title }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="Price"
          align="center"
          width="120px"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.type === 'input'">
              <!--              <el-input-->
              <!--                v-if="isShowDiscount"-->
              <!--                ref="discount"-->
              <!--                v-model="totalData.discount"-->
              <!--                size="mini"-->
              <!--                maxlength="3"-->
              <!--                @keyup.native="-->
              <!--                  totalData.discount = totalData.discount.replace(-->
              <!--                    /[^0-9_]/g,-->
              <!--                    ''-->
              <!--                  )-->
              <!--                "-->
              <!--                @blur="hideDiscount"-->
              <!--              ></el-input>-->
              <div class="row-height">${{ saleMoney }}</div>
            </div>
            <div class="row-height" v-else>${{ totalData.subTotal }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="grand-total-box form-text-color">
        <div class="grand-title">Grand Total</div>
        <div class="grand-total">${{ totalData.grandTotal }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "formTotalTable",
  props: {
    checkEdit: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Number,
      default: 1,
    },
    totalData: {
      type: Object,
      default: () => {
        return {
          discount: 0,
          subTotal: 0,
          grandTotal: 0,
        };
      },
    },
  },
  computed: {
    ...mapState("jiyang/user", ["info", "userRole"]),
    ...mapState("jiyang/form", ["formState", "formNowType"]),
    isUnEdit() {
      console.log(this.info.user_type, this.formState, this.formNowType);
      if (
        ((this.info.user_type == 2 || this.info.user_type == 4) &&
          this.formState == 2) ||
        this.formState == 3 ||
        (this.info.user_type != 2 &&
          this.info.user_type != 4 &&
          this.formState == 1) ||
        this.formNowType >= 5
      ) {
        return true;
      }
      return false;
    },
    discountPercent() {
      let tag = "%";
      return `${this.totalData.discount}%`;
      // return this.totalData.discount > 100
      //   ? 100 + tag
      //   : this.totalData.discount == ""
      //   ? 0 + tag
      //   : this.totalData.discount + tag;
    },
    saleMoney() {
      return (
        Math.floor(
          this.totalData.subTotal *
            (this.totalData.discount > 100
              ? 1
              : this.totalData.discount / 100) *
            100
        ) / 100
      );
    },
  },
  data() {
    return {
      totalList: [
        {
          title: "Sub Total",
          type: "normal",
          total: 0,
        },
        {
          title: "Discount",
          type: "input",
          total: 0,
        },
      ],
      isShowDiscount: false,
      nowIndex: -1,
      isShowSetting: false,
    };
  },
  methods: {
    addRow() {
      // if (
      //   this.table.length > 0 &&
      //   !this.table[this.table.length - 1]?.style_number
      // )
      //   return false;
      this.$emit("addRow");
    },
    changeDiscount() {},
    //修改折扣
    toEditDiscount() {
      if (this.checkEdit) return;
      if (this.userRole === "custom") return;
      this.isShowDiscount = true;
      setTimeout(() => {
        this.$refs.discount.focus();
      }, 100);
    },
    hideDiscount() {
      this.$emit("setTotal");
      this.isShowDiscount = false;
    },
  },
};
</script>
<style lang="scss">
.el-table td,
.el-table th {
  padding: 4px 0 !important;
}
.total-table {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /*height: 100px;*/
  .el-table .cell {
    font-size: $base-font-size;
  }
  .add-table-box {
    cursor: pointer;
    color: $base-config-color;
    padding: 10px 0;
    user-select: none;
    .icon-ic_add {
      font-size: 12px;
    }
    .add-text {
      margin-left: 4px;
    }
  }

  .total-table-position {
    .el-table {
      font-weight: 600;
      color: #000;
      overflow: visible;
      .cell {
        padding: 0 3px !important;
        overflow: visible;
      }
    }
    .el-table__body-wrapper {
      overflow: visible !important;
    }
    .discount-box {
      position: relative;
      cursor: pointer;
      .discount-input-box {
        position: absolute;
        bottom: -30px;
        left: 0;
        width: 120px;
        height: 30px;
        background-color: #fff;
        z-index: 1000;
      }
    }
    .discount-color {
      color: red;
    }
    .grand-total-box {
      width: 240px;
      justify-content: space-between;
      display: flex;
      font-weight: 600;
      padding-top: 6px;
      font-size: $base-font-size;
      .grand-title {
        width: 120px;
        text-align: left;
        padding-left: 10px;
      }
      .grand-total {
        text-align: center;
        width: 120px;
      }
    }
  }
}
</style>

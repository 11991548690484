import api from "@/api";

export default {
  namespaced: true,
  state: {
    cart: [],
    nowFormId: 0,
  },
  mutations: {
    addCart(state, product) {
      state.cart.push(product);
    },
    removeCart(state, product) {
      state.cart.splice((p) => p.id == product.id);
    },
    setFormId(state, id) {
      state.nowFormId = id;
    },
  },
  actions: {},
  getters: {
    cartTotal(state) {
      return state.cart.length;
    },
  },
};

<template>
  <div class="edit-product-box">
    <el-dialog
      :title="createType !== 2 ? (JSON.stringify(form) == '{}'?$t('addGoods'):$t('addColor')) : $t('editGoods')"
      :visible.sync="dialogCreateVisible"
      :before-close="closeModal"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="1240px"
    >
      <el-form
        class="edit-product-dialog"
        label-width="120px"
        ref="form"
        :model="createForm"
        :rules="rules"
        :inline="true"
      >
        <div class="product-dialog-left">
          <el-form-item
            :label="$t('goodsMainImage')"
            style="padding-left: 10px; box-sizing: border-box; width: 500px"
          >
            <div class="design-item-box">
              <div class="design-item hover-animate">
                <el-upload
                  action=""
                  accept=".jpg,.jpeg,.png,.JPG,.PNG"
                  multiple
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                  :http-request="picUpload"
                >
                  <div class="design-image-box">
                    <el-image
                      class="design-image"
                      v-if="imageUrl"
                      :src="imageUrl"
                      fit="contain"
                    >
                    </el-image>
                    <div class="design-image-none" v-else>
                      <div>
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                      </div>

                      <div class="upload-text">请点击上传图片</div>
                    </div>
                  </div>
                </el-upload>
                <div class="design-msg-box">
                  <div class="design-name cloumn-1">
                    {{
                      createForm.name === ""
                        ? "此处显示名称..."
                        : createForm.name
                    }}
                  </div>
                  <div class="design-detail cloumn-1">
                    {{
                      createForm.intro === ""
                        ? "此处显示详情..."
                        : createForm.intro
                    }}
                  </div>
                  <div class="design-price">
                    {{
                      createForm.price === ""
                        ? "此处显示价格"
                        : `$${createForm.price}`
                    }}
                  </div>
                  <div class="design-card-bottom">
                    <div class="card-icon-box">
                      <i class="iconfont icon-yanjing card-icon"></i>
                      <span>0</span>
                    </div>
                    <div class="card-icon-box">
                      <i class="iconfont icon-shoucang card-icon"></i>
                      <span>0</span>
                    </div>
                    <div class="card-icon-box">
                      <i class="iconfont icon-fenxiang card-icon"></i>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('threeD')"
            style="padding-left: 10px; box-sizing: border-box; width: 500px"
            v-if="createForm.type === 2"
          >
            <div>
              <el-upload
                style="display: flex"
                action=""
                accept=".gltf"
                multiple
                :on-change="handleChange"
                ref="upload"
                :file-list="fileList"
                :http-request="uploadThree3d"
              >
                <el-button size="small">{{ $t("clickUpload") }}</el-button>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item
            label="GIF"
            style="padding-left: 10px; box-sizing: border-box"
            v-if="createForm.type == 3"
          >
            <div style="width: 250px">
              <el-upload
                class="avatar-uploader"
                action=""
                accept=".gif,.GIF"
                multiple
                :before-upload="beforeUpload"
                :show-file-list="false"
                :http-request="picGifUpload"
              >
                <div class="avatar-box" v-if="gifUrl">
                  <div>
                    <img :src="gifUrl" class="avatar" />
                  </div>
                </div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item
            label="细节图片"
            style="padding-left: 10px; display: flex"
          >
            <div style="min-width: 250px">
              <el-upload
                list-type="picture-card"
                :file-list="img_list"
                action=""
                accept=".jpg,.jpeg,.png,.JPG,.PNG"
                multiple
                :on-remove="handleRemove"
                :before-upload="beforeUpload"
                :http-request="picUploadList"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
        </div>
        <div class="product-dialog-right">
          <el-form-item :label="$t('goodsType')" class="form-item" prop="type">
            <el-select
              style="width: 250px"
              v-model="createForm.type"
              size="small"
              :disabled="createType == 2"
            >
              <el-option
                v-for="(item, index) in createTypeList"
                :key="index"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('goodsName')" class="form-item" prop="name">
            <el-input
              style="width: 250px"
              v-model="createForm.name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('goodsPrice')"
            class="form-item"
            prop="price"
          >
            <el-input
              :placeholder="$t('goodsUnit')"
              style="width: 250px"
              v-model="createForm.price"
              size="small"
              @keyup.native="
                createForm.price = createForm.price.replace(/[^0-9_\.]/g, '')
              "
            ></el-input>
          </el-form-item>
		  <el-form-item
		  :label="$t('isPrivate')"
		  class="form-item"
		  prop="is_public"
		  >
			<el-select  style="width: 250px"
              v-model="createForm.is_public"
			  @change="handleChangePublic"
              size="small">
			  <el-option :label="$t('public')" v-bind:value="1"></el-option>
			  <el-option :label="$t('privacy')" v-bind:value="0"></el-option>
			  <el-option :label="$t('appoint')" v-bind:value="2"></el-option>
			</el-select>
		  </el-form-item>
		  <el-form-item
		     v-if="createForm.is_public == 2"
			:label="$t('customPrivate')"
			class="form-item"
		  >
			<el-select style="width: 250px"
              size="small"
			  v-model="createForm.customPrivate"
			  multiple 
			  :placeholder="$t('custormSelect')">
			  <el-option
				v-for="item in customOptions"
				:key="item.value"
				:label="item.last_name+item.first_name"
				:value="item.user_id">
			  </el-option>
			</el-select>
		  </el-form-item>
          <el-form-item
            :label="$t('bomModel')"
            class="form-item"
            prop="model_name"
          >
            <el-input
              style="width: 250px"
              v-model="createForm.model_name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('bomStyle')"
            class="form-item"
            prop="style_number"
          >
            <el-input
              style="width: 250px"
              v-model="createForm.style_number"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('bomColor')" class="form-item" prop="color">
            <el-input
              style="width: 250px"
              v-model="createForm.color"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('bomSize')" class="form-item" prop="size">
            <el-input
              style="width: 250px"
              v-model="createForm.size"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('goodsInform')"
            class="form-item"
            style="flex-shrink: 0"
            prop="intro"
          >
            <div style="min-width: 250px">
              <el-input
                type="textarea"
                :rows="2"
                v-model="createForm.intro"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('goodsDesc')"
            class="form-item"
            prop="desc"
            style="display: flex; flex-wrap: wrap"
          >
            <div
              class="desc-item"
              v-for="(val, key) in createForm.desc"
              :key="key"
            >
              <el-input
                :ref="`desc${key}`"
                class="form-desc"
                v-model="createForm.desc[key]"
                size="small"
                @keyup.enter.native="addInput(key)"
              ></el-input>
              <i
                class="iconfont icon-jia add-input-icon"
                @click="addInput(key)"
                v-if="
                  key == createForm.desc.length - 1 &&
                  createForm.desc.length < 8
                "
              ></i>
              <i
                class="iconfont icon-jian add-input-icon"
                @click="removeInput(key)"
                v-if="
                  key != createForm.desc.length - 1 &&
                  createForm.desc.length > 1
                "
              ></i>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div class="dialog-btn enter-btn-box">
        <!-- <el-button size="big" @click="addMore" v-if="createType === 1">
          {{$t("continueAdd")}}
        </el-button> -->
       <!-- <el-button size="big" @click="closeModal" v-else> 取消 </el-button> -->
        <el-button
		  v-if="createType !== 3"
          class="btn"
          size="big"
          type="primary"
          @click="toCreate('form')"
        >
          {{ $t("bomSubmit") }}
        </el-button>
        <el-button
            v-if="createType == 3"
            class="btn"
            size="big"
            type="primary"
            @click="toCreate2('form')"
        >
          {{$t("addColor")}}
        </el-button>
        <!--        <el-button-->
        <!--          v-if="createType == 2"-->
        <!--          class="btn"-->
        <!--          size="big"-->
        <!--          type="danger"-->
        <!--          @click="toCreate2('form')"-->
        <!--        >-->
        <!--          {{ $t("addBtn") }}-->
        <!--        </el-button>-->
      </div>
    </el-dialog>
	<div id="modelWork"></div>
  </div>
</template>

<script>
import { modelConfig } from "@/utils/babylonjs/meshData";
import { ThreeDScene } from "@/utils/babylonjs/methods";
import uploadCos from "@/utils/upload_to_cos";

export default {
  name: "editProductDialog",
  props: {
    createType: {
      type: Number,
      default: 1,
    },
    itemData: {
      type: Object,
    },
    form: {
      type: Object,
    },
    // createForm: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       name: "",
    //       style_number: "",
    //       model_name: "",
    //       type: 1,
    //       file_info: {},
    //       price: "",
    //       intro: "",
    //       is_public: 1,
    //       desc: [""],
    //     };
    //   },
    // },
    dialogCreateVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imageUrl: "",
      img_file: "",
      three_d_file: "",
      gif_file: "",
      gifUrl: "",
      fileList: [],
      img_file_list: [],
      img_list: [],
      createForm: {
        name: "",
        style_number: "",
        model_name: "",
        type: 1,
        file_info: {},
        price: "",
        intro: "",
        is_public: 1,
        desc: [""],
		customPrivate:[]
      },
      dialogImageUrl: "",
      dialogVisible: false,
	  customOptions:[]
    };
  },
  computed: {
    createTypeList() {
      return [
        {
          type: 1,
          name: this.$t("design"),
        },
        {
          type: 2,
          name: this.$t("threeD"),
        },
        {
          type: 3,
          name: this.$t("goods"),
        },
      ];
    },
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t("goodsNamePlaceholder"),
            trigger: "blur",
          },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        style_number: [
          {
            message: this.$t("bomStylePlaceholder"),
            trigger: "blur",
          },
        ],
        model_name: [
          {
            message: this.$t("bomModelPlaceholder"),
            trigger: "blur",
          },
        ],
        desc: [
          {
            required: true,
            message: this.$t("pleaseGoodsDesc"),
            trigger: "blur",
          },
        ],
        color: [
          {
            required: true,
            message: this.$t("bomColorPlaceholder"),
            trigger: "blur",
          },
        ],
        intro: [
          {
            required: true,
            message: this.$t("goodsInformPlaceholder"),
            trigger: "blur",
          },
        ],
        size: [
          {
            required: true,
            message: this.$t("goodsSizePlaceholder"),
            trigger: "blur",
          },
        ],
        // shoe_last_name:[{
        // 	required:true
        // }]
      };
    },
  },
  created() {
    if(Object.keys(this.form).length>0){
      this.createForm = JSON.parse(JSON.stringify(this.form));
    }
    if(Object.keys(this.itemData).length>0){
      this.imageUrl = this.itemData.file_info.main_file.img_url;
      if (this.itemData.file_info.remark_file) {
        this.img_list = this.itemData.file_info.remark_file.map((item) => {
          return {
            url: item,
          };
        });
      }
      if (this.itemData.type == 3) {
        this.gifUrl = _.get(this.itemData, "file_info.gif_file.img_url", "");
      }
      if (this.itemData.type == 2) {
        let url = {
          name: this.itemData.name,
          url: this.itemData.file_info.main_file.file,
          img_url: this.itemData.file_info.main_file.img_url,
        };
        this.fileList = [url];
      }
    }
	this.getCustomerList();
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    //添加输入框
    addInput(key) {
      let that = this;
      if (this.createForm.desc.length == 8) {
        // this.$message.error(this.$t("mostDesc"))
        return;
      }
      let str = "";
      this.createForm.desc.push(str);
      setTimeout(() => {
        this.$refs["desc" + (key + 1)][0].focus();
      }, 100);
    },
    //移除输入框
    removeInput(key) {
      this.createForm.desc.splice(key, 1);
    },
    async addMore() {
      let check = await this.toCreate("form", 2);
      if (check) {
		  this.$message({
			showClose: true,
			message: this.$t("addSuccess"),
			type: 'success'
		  });
        //Object.assign(this.$data, this.$options.data());
      }
    },
    //提交
    toCreate(formName, type = 1) {
      let form = JSON.parse(JSON.stringify(this.createForm));
      if (
        this.img_file == "" &&
        this.imageUrl == "" &&
        this.createForm.type != 2
      ) {
        this.$message.error(this.$t("pleaseMainImage"));
        return false;
      }
      if (
        this.three_d_file == "" &&
        this.fileList.length == 0 &&
        this.createForm.type == 2
      ) {
        this.$message.error(this.$t("pleaseThreeD"));
        return false;
      }
      return new Promise(resolve => {
        form.desc = form.desc.filter((val) => Boolean(val));
        if (form.desc.length == 0) {
          this.$message.error(this.$t("pleaseGoodsDesc"));
          return false;
        }
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let that = this;
            let loading = this.openLoading(60000);
            let file_info = {
				main_file: {
				  img_url: '',
				  file: '',
				}
			};
            if (this.createForm.type == 2) {
              if (this.three_d_file) {
                let files = [this.three_d_file];
                let url = await this.uploadImg(files);
                /*let img_url = null;
                modelConfig.boxId = "modelWork";
                let b = new ThreeDScene(modelConfig);
                await b.loadModel(url[0]).then(async () => {
                  setTimeout(function () {
                    b.screenShot().then(async (imgFiles) => {
                      img_url = await that.uploadImg([imgFiles]);

                      file_info = {
                        main_file: {
                          img_url: img_url[0],
                          file: url[0],
                        },
                      };
                      if (this.img_file) {
                        let three_d_img_files = [this.img_file];
                        let img = await this.uploadImg(three_d_img_files);
                        file_info = {
                          main_file: {
                            img_url: img[0],
                            file: url[0],
                          },
                        };
                      }
                      form.file_info = file_info;
                      if (that.createType == 2) {
                        that.$api.editProduct(form).then((res) => {
                          that.$emit("editProduct", type);
                          loading.close();
                        });
                      } else {
                        that.$api.addProduct(form).then((res) => {
                           this.$emit("createProduct", res);
                          loading.close();
                        });
                      }
                    });
                  }, 10); 
				  
                });*/
				file_info.main_file.file = url[0];
				if (this.img_file) {
				  let three_d_img_files = [this.img_file];
				  let img = await this.uploadImg(three_d_img_files);
				  file_info.main_file.img_url = img[0];
				}
				form.file_info = file_info;
				if (that.createType == 2) {
				  that.$api.editProduct(form).then((res) => {
				    that.$emit("editProduct", type);
				    loading.close();
				  });
				} else {
				  that.$api.addProduct(form).then((res) => {
				     this.$emit("createProduct", res);
				    loading.close();
				  });
				}
              } else {
                if (that.createType == 2) {
                  if (this.img_file) {
                    let files = [this.img_file];
                    let url = await this.uploadImg(files);
                    file_info = {
                      main_file: {
                        img_url: url[0],
                        file: that.fileList[0].url,
                      },
                    };
                  } else {
                    file_info = {
                      main_file: {
                        img_url: that.fileList[0].img_url,
                        file: that.fileList[0].url,
                      },
                    };
                  }

                  form.file_info = file_info;
                  that.$api.editProduct(form).then((res) => {
                    that.$emit("editProduct", type);
                    loading.close();
                  });
                }
              }
            } else {
              let list = [];
              let fileList = [];
              if (this.img_file) {
                let files = [this.img_file];
                let url = await this.uploadImg(files);
                file_info = {
                  main_file: {
                    img_url: url[0],
                  },
                };
              } else {
                file_info = {
                  main_file: {
                    img_url: this.imageUrl,
                  },
                };
              }
              if (this.createForm.type == 3) {
                if (this.gif_file) {
                  let gif_files = [this.gif_file];
                  let gif_url = await this.uploadImg(gif_files);
                  file_info = { ...file_info, ...{ gif_file: gif_url[0] } };
                } else {
                  if (this.gifUrl) {
                    file_info = { ...file_info, ...{ gif_file: this.gifUrl } };
                  }
                }
              }

              if (this.img_list.length > 0) {
                list = this.img_list.map((val) => val.url);
              }
              if (this.img_file_list.length > 0) {
                let readyList = this.img_file_list.filter(
                    (val) => val.status == "ready"
                );
                let checkList = this.img_file_list.filter((val) => !val.status);
                let checkFile = [];
                if (readyList.length > 0) {
                  fileList = await this.uploadImg(readyList);
                }
                if (checkList.length > 0) {
                  checkFile = await this.uploadImg(checkList);
                }
                fileList = [...fileList, ...checkFile];
              }
              file_info = {
                ...file_info,
                ...{ remark_file: [...list, ...fileList] },
              };
              form.file_info = file_info;
              if (this.createType == 2) {
                this.$api.editProduct(form).then((res) => {
                  this.$emit("editProduct", type);
                  loading.close();
                });
              } else {
                this.$api.addProduct(form).then((res) => {
                  this.$emit("createProduct", res);
                  loading.close();
                });
              }
            }
            resolve(true)
          }
        });
      })

    },
    toCreate2(formName) {
      let form = JSON.parse(JSON.stringify(this.createForm));
      if (
        this.img_file == "" &&
        this.imageUrl == "" &&
        this.createForm.type != 2
      ) {
        this.$message.error(this.$t("pleaseMainImage"));
        return;
      }
      if (
        this.three_d_file == "" &&
        this.fileList.length == 0 &&
        this.createForm.type == 2
      ) {
        this.$message.error(this.$t("pleaseThreeD"));
        return;
      }
      form.desc = form.desc.filter((val) => Boolean(val));
      if (form.desc.length == 0) {
        this.$message.error(this.$t("pleaseGoodsDesc"));
        return;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let that = this;
          let loading = this.openLoading();
          let file_info = {};
          if (this.createForm.type == 2) {
            if (this.three_d_file) {
              let files = [this.three_d_file];
              let url = await this.uploadImg(files);
              let img_url = null;
              modelConfig.boxId = "modelWork";
              let b = new ThreeDScene(modelConfig);
              await b.loadModel(url[0]).then(async () => {
                setTimeout(function () {
                  b.screenShot().then(async (imgFiles) => {
                    img_url = await that.uploadImg([imgFiles]);

                    file_info = {
                      main_file: {
                        img_url: img_url[0],
                        file: url[0],
                      },
                    };
                    if (this.img_file) {
                      let three_d_img_files = [this.img_file];
                      let img = await this.uploadImg(three_d_img_files);
                      file_info = {
                        main_file: {
                          img_url: img[0],
                          file: url[0],
                        },
                      };
                    }
                    form.file_info = file_info;
                    that.$api.addProduct(form).then((res) => {
                      that.$emit("createProduct");
                      loading.close();
                    });
                  });
                }, 10);
              });
            } else {
              if (that.createType == 2) {
                if (this.img_file) {
                  let files = [this.img_file];
                  let url = await this.uploadImg(files);
                  file_info = {
                    main_file: {
                      img_url: url[0],
                      file: that.fileList[0].url,
                    },
                  };
                } else {
                  file_info = {
                    main_file: {
                      img_url: that.fileList[0].img_url,
                      file: that.fileList[0].url,
                    },
                  };
                }

                form.file_info = file_info;
                that.$api.addProduct(form).then((res) => {
                  that.$emit("editProduct");
                  loading.close();
                });
              }
            }
          } else {
            let list = [];
            let fileList = [];
            if (this.img_file) {
              let files = [this.img_file];
              let url = await this.uploadImg(files);
              file_info = {
                main_file: {
                  img_url: url[0],
                },
              };
            } else {
              file_info = {
                main_file: {
                  img_url: this.imageUrl,
                },
              };
            }
            if (this.createForm.type == 3) {
              if (this.gif_file) {
                let gif_files = [this.gif_file];
                let gif_url = await this.uploadImg(gif_files);
                file_info = { ...file_info, ...{ gif_file: gif_url[0] } };
              } else {
                if (this.gifUrl) {
                  file_info = { ...file_info, ...{ gif_file: this.gifUrl } };
                }
              }
            }

            if (this.img_list.length > 0) {
              list = this.img_list.map((val) => val.url);
            }
            if (this.img_file_list.length > 0) {
              let readyList = this.img_file_list.filter(
                (val) => val.status == "ready"
              );
              let checkList = this.img_file_list.filter((val) => !val.status);
              let checkFile = [];
              if (readyList.length > 0) {
                fileList = await this.uploadImg(readyList);
              }
              if (checkList.length > 0) {
                checkFile = await this.uploadImg(checkList);
              }
              fileList = [...fileList, ...checkFile];
            }
            file_info = {
              ...file_info,
              ...{ remark_file: [...list, ...fileList] },
            };
            form.file_info = file_info;
            this.$api.addProduct(form).then((res) => {
              this.$emit("createProduct");
              loading.close();
            });
          }
        }
      });
    },
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    //上传图片限制
    beforeUpload(file) {
      let that = this;
      let limit_file = 4;
      const limit = file.size / 1024 / 1024 < limit_file;
      if (!limit) {
        this.$message({
          message: `${that.$t("uploadWarning")} ${limit_file}MB`,
          type: "warning",
        });
      }
      return limit;
    },
    // 选择图片
    picUpload(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.imageUrl = e.target.result; // 将图片路径赋值给src
      };
      reader.readAsDataURL(file.file);
      this.img_file = file.file;
    },
    //上传gif
    picGifUpload(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.gifUrl = e.target.result; // 将图片路径赋值给src
      };
      reader.readAsDataURL(file.file);
      this.gif_file = file.file;
    },
    // 选择图片
    picUploadList(file, fileList) {
      console.log(file, fileList);
      this.img_file_list.push(file.file);
    },
    handleRemove(file, fileList) {
      if (this.createType == 2) {
        if (file.status == "success") {
          this.img_list.some((val, key) => {
            if (val.url == file.url) {
              this.img_list.splice(key, 1);
              return true;
            }
          });
        }
      }
      this.img_file_list = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传3D
    uploadThree3d(file) {
      this.three_d_file = file.file;
    },

    //上传图片
    uploadImg(pictureList) {
      return new Promise((resolve) => {
        uploadCos(pictureList, "works", (picture) => {
          resolve(picture);
        });
      });
    },
	//获取客户
	getCustomerList(){
		let data = {
			pageSize: 1000,
			page: 1,
			current: 1,
	    }
		this.$api.getCustomerList(data).then((res)=>{
			if(res.data){
				this.customOptions = res.data;
			}else{
				this.customOptions = [];
			}
		})
	},
	handleChangePublic(){
		if(this.createForm.is_public == 2){
			
		}else{
			this.createForm.customPrivate = [];
		}
	}
  },
};
</script>

<style lang="scss">
$image-size: 100px;
$eidt-product-border: 1px solid #bfbfbf;
.edit-product-box {
  .enter-btn-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 24px;
    box-sizing: border-box;
    margin: 20px auto 0 auto;
  }
  .el-dialog__body {
    padding: 0 0 30px 0 !important;
  }
  .el-upload-list__item.is-success .el-upload-list__item-status-label {
    display: none;
  }
  .el-form-item__label {
    flex-shrink: 0;
  }
  .el-upload--picture-card {
    width: $image-size;
    height: $image-size;
    line-height: $image-size;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: $image-size;
    height: $image-size;
    line-height: $image-size;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    height: auto;
  }
}
.edit-product-dialog {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 580px;
  border-top: $eidt-product-border;
  border-bottom: $eidt-product-border;
  padding: 20px 0;

  .el-form-item__content {
    line-height: 12px !important;
  }
  .el-form-item {
    margin-bottom: 16px !important;
  }

  .desc-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .add-input-icon {
      font-size: 24px;
      cursor: pointer;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: $base-config-color;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: $image-size;
    height: $image-size;
    line-height: $image-size;
    text-align: center;
  }
  .avatar-box {
    line-height: $image-size;
    width: $image-size;
    height: $image-size;
  }
  .avatar {
    width: $image-size;
    height: auto;
  }
  .form-desc {
    width: 250px;
    margin-right: 10px;
  }
  .el-upload--picture-card {
    width: $image-size;
    height: $image-size;
    line-height: $image-size;
  }
  .product-dialog-left {
    $item-border-color: 1px solid #e2dede;
    margin-right: 100px;
    .design-item-box {
      .el-card__body {
        padding: 0;
      }
      box-sizing: content-box;
      .design-item {
        width: 234px;
        height: 290px;
        border: $item-border-color;
        padding: 0 !important;
        margin-bottom: 16px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        background-color: #fff;
        .design-image-box {
          width: 234px;
          height: 164px;
          border-bottom: $item-border-color;
          /*padding:60px 40px 30px 40px;*/
          padding: 10px 4px;
          opacity: 1;
          position: relative;
          .design-image {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-icon-picture-outline {
              font-size: 32px;
              color: #999;
            }
          }

          .design-type-text {
            position: absolute;
            left: 12px;
            top: 12px;
            font-size: 12px;
            color: #333;
            font-weight: 500;
          }
        }
        .design-image-none {
          text-align: center;
          position: relative;
          margin: 0 auto;
          .upload-text {
            font-size: 12px;
            color: #4d4d4d;
          }
        }
        .cloumn-1 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .show-btn {
          opacity: 1 !important;
        }

        .hide-btn {
          opacity: 0;
        }
        .hide-img {
          animation: hide 1s;
        }
        @keyframes hide {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
        .design-msg-box {
          position: relative;
          padding: 20px;
          font-size: 12px;
          height: 118px;
          margin-right: 30px;
          .design-name {
            font-size: 12px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 6px;
            width: 200px;
          }
          .design-detail {
            color: #4d4d4d;
            font-size: 10px;
            line-height: 20px;
            margin-bottom: 6px;
            width: 200px;
            font-weight: 500;
            text-align: left;
          }
          .design-price {
            font-size: 12px;
            font-weight: 500;
            text-align: left;
          }
          .design-card-bottom {
            position: absolute;
            bottom: 14px;
            left: 20px;
            display: flex;
            align-items: center;
            margin-top: 12px;
            .card-icon-box {
              display: flex;
              align-items: center;
              margin-right: 16px;
              color: #8e8e8e;
              .card-icon {
                font-size: 13px;
                margin-right: 6px;
              }
              .card-icon-text {
                font-size: 12px;
              }
            }
          }
          .design-btn-box {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 202px;
            height: 32px;
            background-color: $base-config-color;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #fff;
            font-weight: 500;
            cursor: pointer;
            opacity: 0;
          }
          .design-like-box {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            right: 1px;
            border-top: 1px solid #fff;

            /*border-left:1px solid #fff;*/
            z-index: 1;
            background-color: #fff;
            cursor: pointer;
            .design-like {
              font-size: 18px;
            }
            .design-is-like {
              font-size: 18px;
              color: $base-star-color;
            }
          }
        }
      }

      .show-border-hover {
        transform: scale(1.02, 1.02);
        border: 1px solid $base-config-color !important;
        box-shadow: 0 0 4px 4px #f2f2f2;
      }

      .show-border-bottom {
        border-bottom: 1px solid $base-config-color !important;
      }
      .show-border-top {
        border-top: 1px solid $base-config-color !important;
      }
    }
  }
  .product-dialog-right {
    overflow-y: auto;
  }
  
  .el-form-item__content{
	  vertical-align: middle;
  }
}
</style>

<template>
  <div
    class="draw"
    :class="[isHiddenX ? 'hidden-x' : '']"
    :style="{ height: svgBoxHeight + 'px' }"
    @mouseup="mouseUp"
  >
    <div class="tool-box" :style="{ height: svgBoxHeight + 'px' }">
      <div
        class="svg-box"
        :style="{ width: svgWidth + 'px', height: svgBoxHeight + 'px' }"
      >
        <div class="svg-header" :style="{ width: svgWidth + 'px' }">
          <div class="iconfont icon-undo" @click="backPage"></div>
          <div class="right-icon-box">
            <i class="iconfont icon-export" @click="showRemarkModal(2)"></i>
            <i class="iconfont icon-save ml-10" @click="showRemarkModal(1)"></i>
          </div>
        </div>
        <div class="svg-page">
          <svg
            style="display: block"
            id="svgBox"
            @click.capture="handleClick"
          ></svg>
        </div>
      </div>
      <tools
        ref="tools"
        :add-form="addForm"
        :imgSize="imgSize"
        :checkShowColor="checkShowColor"
        :isFirstCreate="isFirstCreate"
        :tableData="tableData"
        :partList="partList"
        @changeRemark="changeRemark"
        @changeImgSize="changeImgSize"
        @getTextureImg="getTextureImg"
        @removeNode="removeNode"
        @getPart="getPart"
        @getColor="getColor"
        @getImage="getImage"
        @changeSn="changeSn"
        @changeTech="changeTech"
        @selected="handleSelect"
        :svgData="svgData"
      ></tools>
	  <!-- <div class="reviewPage"  :class="{'changeBig':!changeWidth}">
		  <i v-if="changeWidth" class="el-icon-d-arrow-left changeWidth" @click="changeWidth = !changeWidth"></i>
		  <i v-else class="el-icon-d-arrow-right changeWidth" @click="changeWidth = !changeWidth"></i>
		 <review  :data="reviewData"></review>
		 <bom :data="reviewData" :nowNode="nowNode" :materialName="materialName" @updateData="updateData"></bom>
		  <instruct :data="reviewData" :nowNode="nowNode" :materialName="materialName" @updateData="updateData"></instruct>
	  </div> -->
      <!--            <materialTree v-show="isShowTable"  :formData="pageData"  :datas="tableData" @showEditModal="showEditModal" @closeTableModal="closeTableModal"></materialTree>-->
    </div>
    <div class="hidden-upload">
      <el-upload
        ref="upload"
        class="upload-item"
        action=""
        accept=".jpg,.jpeg,.png,.JPG,.PNG"
        multiple
        :before-upload="beforeUpload"
        :show-file-list="false"
        :http-request="picUpload"
      >
      </el-upload>
    </div>
    <remarkDialog
      @closeRemarkModal="closeRemarkModal"
      @submitRemark="submitRemark"
      :remarkText="remarkText"
      :dialogVisible="dialogVisible"
    ></remarkDialog>
    <createDialog
      @closeModal="closeModal"
      @toCreate="toCreate"
      @toEdit="toEdit"
      :type="editType"
      :add-form="addForm"
      v-if="dialogEnterVisible"
    ></createDialog>
  </div>
</template>

<script>
// import editTools from "@/components/editTools/editTools";
import tools from "@/components/tools/tools";
import Svg from "@/utils/svgClass";
import uploadCos from "@/utils/upload_to_cos";
import createDialog from "../../components/createDialog/createDialog";
import materialTree from "../../components/materialTree/materialTree";
import remarkDialog from "../../components/remarkDialog/remarkDialog";
import { svgAsDataUri } from "save-svg-as-png";
import review from "@/views/review/index"
// import bom from "@/components/spreadJs/bom";
// import instruct from "@/components/spreadJs/instruct";
let that;
export default {
  components: {
    createDialog,
    tools,
    remarkDialog,
	review,
	// bom,
	// instruct
  },
  data() {
    return {
      svgWidth: 1500, //svg宽度
      svgHeight: 800, //svg高度
      toolWidth: 300, //工具栏宽度
      svgBoxHeight: 800, //页面高度
      svgs: {}, //svg对象
      toolForm: {
        technology: "",
        texture_sn: "",
      },
      pageHeight: "100%",
      tableData: [],
      itemData: {},
      svgData: {},
      msg: {},
      dialogEnterVisible: false,
      imageUrl: "",
      img_file: "",
      imgSize: 50,
      partList: [],
      codeImg: "",
      addForm: {
        name: "",
        delivery_date: "",
        style_number: "",
        style_color: "",
        model_name: "",
        shoe_last_name: "",
        count: "",
        client: "",
        size: "",
      },
      pageMsg: {},
      svgPng: "",
      pageData: {
        name: "",
        delivery_date: "",
        style_number: "",
        style_color: "",
        model_name: "",
        shoe_last_name: "",
        count: "",
        client: "",
        size: "",
      },
      isDown: false,
      lastX: 0,
      checkX: 0,
      checkFirst: false,
      checkWidth: 300,
      size: 50,
      isShowTools: false,
      isShowTable: false,
      textureRemarkList: [],
      checkRemarkList: [],
      isClickRect: true,
      checkShowColor: false,
      isHiddenX: true,
      dialogVisible: false,
      editType: 0,
      remarkText: "",
      submitType: 1,
      drawType: 1,
      draw_id: 0,
      isFirstEnter: false,
      isFirstCreate: false,
      version: 0,
      sn: "",
      createType: 1, //创建图片类型
      gif_file: "", //gif文件
      loading: "",
      multiple: 1,
      uploadTextureList: [],
      selected: 0,
	  changeWidth:true,
	  reviewData:{},
	  nowNode:{},
	  materialName:''
    };
  },
  watch: {
    //svg数据
    svgData: {
      handler: function (val) {
        if (!val.data) return;
        let param = val.data.param;
        if (param.openModal) {
          // that.showModal()
          that.toUploadImage();
        }
        if (Object.keys(param.oneData).length > 0) {
          // this.isShowTools=true
          // this.isShowTable=false
          this.$refs.tools.toolTab = 2;
        }
        if (param.allData.length > 0) {
          this.tableData = param.allData;
        }
      },
      deep: true,
    },
    //是否弹出颜色筛选
    "svgData.data.param.oneData.num": function (val) {
      if (this.tableData.length > 0) {
        this.checkShowColor = !this.checkShowColor;
      }
    },
    //创建线条
    // 'svgData.data.param.checkCreateLine':function (val) {
    //     this.isShowTable=false
    //     this.isShowTools=false
    // },
    //点击svg拉线框
    "svgData.data.param.isClickRect": function (val) {
      if (this.tableData.length > 0) {
        // this.isShowTable=false
        // this.isShowTools=true
        this.$refs.tools.toolTab = 2;
        if (this.svgs.svgParam.oneData.texture_id) {
          let data = {
            id: this.svgs.svgParam.oneData.texture_id,
            name: this.svgs.svgParam.oneData.texture_name,
            cover_pic: this.svgs.svgParam.oneData.cover_pic,
          };
          this.$refs.tools.textureList = [data];
        }

        // this.$refs.tools.fileterTexture(that.svgs.svgParam.oneData.keyword)
      }
    },
	"svgData.data.param.oneData.sn":function(val){
		this.materialName = val;
	},
	"svgData.data.param.oneData":{
		handler:function(val){
			this.nowNode = val;
		},
		deep: true,
	}
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "review") {
      to.meta.isBack = true;
    }
    next();
  },
  // beforeRouteLeave: (to, from, next) => {
  //   if (confirm("确定离开此页面吗？") == true) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // },
  created() {
    that = this;
    this.isFirstEnter = true;
  },
  mounted() {
    // let fullWidth=document.documentElement.clientWidth-that.svgWidth
    //    监听屏幕
    window.onresize = () => {
      return (() => {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight - 74;
        that.svgs.setSvgHeight(height);
        if (width < this.svgWidth) {
          that.isHiddenX = false;
        } else {
          that.isHiddenX = true;
        }
        that.svgBoxHeight = height;
        that.svgWidth = document.documentElement.clientWidth - 360;
        that.svgs.setSvgWidth(this.svgWidth);
        // that.pageHeight=document.documentElement.clientHeight
      })();
    };
  },
  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      Object.assign(this.$data, this.$options.data());
      let query = this.$route.query;
      this.drawType = query.type;
      this.draw_id = query.id;
      this.imageUrl = query.imageUrl; // 将图片路径赋值给src
      this.product_id = query.product_id;
      this.toGetPart();
      this.pageHeight = document.documentElement.clsientssHeight;
      this.svgWidth = document.documentElement.clientWidth - 360;
      this.svgBoxHeight = document.documentElement.clientHeight - 74;
      //设置工具栏宽度
      let clientWidth = document.documentElement.clientWidth - this.svgWidth;
      this.toolWidth = clientWidth > 360 ? clientWidth : 360;
      this.checkWidth = clientWidth > 360 ? clientWidth : 360;
      document.body.ondragstart = () => {
        return false;
      };
      //设置svg画板
      this.svgHeight = document.documentElement.clientHeight - 110;
      if (this.drawType == 1 || !this.draw_id) {
        that.toCreate();
        if (query.basicMsg) {
          Object.keys(that.addForm).forEach((key) => {
            that.addForm[key] = JSON.parse(query.basicMsg)[key];
          });
        }
		that.reviewData['pageData'] = that.addForm;
      } else {
        this.setSvg();
      }
    }
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  methods: {
    //开始拖拽
    moveStart(e) {
      this.isDown = true;
      document.addEventListener("mousemove", this.mouseMove);
      this.lastX = e.screenX;
      if (!this.checkFirst) {
        this.checkX = e.screenX;
        this.checkFirst = true;
      }
    },
    mouseMove(event) {
      if (!this.isDown) return;
      if (this.checkX <= event.screenX) return;
      if (this.checkX - event.screenX >= 200) return;
      this.toolWidth += this.lastX - event.screenX;
      if (this.toolWidth - 200 > this.checkWidth) {
        this.toolWidth = this.checkWidth + 200;
      }
      this.lastX = event.screenX;
    },
    mouseUp() {
      this.lastX = "";
      this.isDown = false;
      document.removeEventListener("mousemove", this.mouseMove);
    },
    //修改备注
    changeRemark(data) {
      this.remark = data;
    },
    //上传图片限制
    beforeUpload(file) {
      let limit_file = 4;
      const limit = file.size / 1024 / 1024 < limit_file;
      if (!limit) {
        this.$message({
          message: `${that.$t("uploadWarning")} ${limit_file}MB`,
          type: "warning",
        });
      }
      return limit;
    },
    //上传图片
    toUploadImage() {
      this.$refs["upload"].$children[0].$refs.input.click();
    },
    // 选择图片
    picUpload(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.imageUrl = e.target.result; // 将图片路径赋值给src
        that.toCreate();
      };
      reader.readAsDataURL(file.file);
      this.img_file = file.file;
    },
    //显示备注信息弹窗
    //type 1 保存   2导出
    showRemarkModal(type) {
      this.submitType = type;
      let formKey = Object.keys(this.addForm);
      let formVal = Object.keys(this.filterParams(this.addForm));
      if (formKey.length > formVal.length) {
        this.showEditModal();
        // this.$message.error('基础信息还未完善，请填写完成再进行保存')
        return false;
      }
      this.saveSvg();
      // this.dialogVisible=true
    },
    //关闭备注信息弹窗
    closeRemarkModal() {
      this.dialogVisible = false;
    },
    //备注提交
    submitRemark(text) {
      this.remark = text;
      this.saveSvg();
    },
    //返回页面
    backPage() {
      this.$router.back();
    },
    //设置素材备注图片
    getTextureImg(data) {
      let check = that.textureRemarkList.some((val, key) => {
        if (val.num == data.num) {
          if (data.cover_pic) {
            this.$set(that.textureRemarkList, key, data);
          } else {
            that.textureRemarkList.splice(key, 1);
          }
          return true;
        }
      });
      if (!check) {
        that.textureRemarkList.push(data);
      }
      that.svgs.setImage(data, 1);
    },
    //跳转预览
    async toReview(data) {
      this.svgs.svgParam.checkCreateLine = !this.svgs.svgParam.checkCreateLine;
      setTimeout(() => {
        let query = JSON.stringify(data);
        this.closeRemarkModal();
        localStorage.setItem("review", query);
        this.$router.push({
          path: "review",
        });
        that.loading.close();
      }, 10);
    },
    //导出svg图片
    toExportSvgImg() {
      let that = this;
      return new Promise((resolve, reject) => {
        // that.loading=this.openLoading()
		that.svgs.svgParam.allData.forEach((val) => {
          that.svgs.replaceInput(val);
        });
        this.svgs.getSvgNode((node) => {
          svgAsDataUri(node).then(async (res) => {
            let name = `svg${new Date().getTime()}.jpg`;
            let data = await that.dataURLtoFile(res, name);
            let url = await that.uploadImg(data);
            resolve(url);
            // that.loading.close()
          });
        });
      });
    },
    //导出svg图片
    toExportBomImg() {
      let that = this;
      return new Promise((resolve, reject) => {
        // that.loading=this.openLoading()
        that.svgs.svgParam.allData.forEach((val) => {
          that.svgs.exportReplaceSvg(val);
        });
        this.svgs.getSvgNode((node) => {
          svgAsDataUri(node).then(async (res) => {
            let name = `bom${new Date().getTime()}.jpg`;
            let data = await that.dataURLtoFile(res, name);
            let url = await that.uploadImg(data);
            resolve(url);
            // that.loading.close()
          });
        });
      });
    },
    dataURLtoFile(dataurl, filename) {
      //将base64转换为文件
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    //关闭表格
    closeTableModal() {
      this.isShowTable = false;
      this.isShowTools = false;
      this.svgs.removeSelect();
    },
    //关闭属性
    closeMsgModal() {
      this.isShowTools = false;
      this.svgs.removeSelect();
    },
    //显示表格
    showTable() {
      this.isShowTable = !this.isShowTable;
      this.isShowTools = false;
      this.svgs.removeSelect();
    },
    //移动
    startMove(e) {
      console.log(e);
    },
    //显示创建
    showModal() {
      this.editType = 0;
      this.dialogEnterVisible = true;
    },
    //关闭弹窗
    closeModal() {
      this.dialogEnterVisible = false;
      this.svgs.closeModal();
    },
    //重置数据
    resetPage() {
      this.checkRemarkList = [];
      this.textureRemarkList = [];
      this.remarkText = "";
    },
    //创建画布
    async toCreate() {
      that.dialogEnterVisible = false;
      await that.createSvg();
      // this.img_file = data.img_file
      // this.createType=data.createType
      // this.gif_file=data.gif_file
      this.resetPage();
      setTimeout(() => {
        that.svgs.createImageSvg({
          img_url: that.imageUrl,
        });
        that.drawType = 1;
        that.draw_id = "";
        that.svgs.closeModal();
        that.pageData = JSON.parse(JSON.stringify(that.addForm));
        that.isFirstCreate = true;
        that.$refs.tools.toolTab = 1;
      }, 100);
    },
    //显示弹窗
    showEditModal() {
      this.dialogEnterVisible = true;
      if (this.drawType == 2) {
        this.addForm = JSON.parse(JSON.stringify(this.pageData));
      }
      this.editType = 1;
    },
    //修改基础信息
    toEdit(data) {
      this.pageData = JSON.parse(JSON.stringify(that.addForm));
      this.dialogEnterVisible = false;
      this.showRemarkModal(this.submitType);
    },
    //删除节点
    removeNode() {
      this.svgs.removeNode();
      setTimeout(() => {
        if (that.tableData.length == 0) {
          // that.isShowTools=false
          this.$refs.tools.toolTab = 3;
        }
      }, 100);
    },
    //获取部件
    toGetPart() {
      this.$api.getPart().then((res) => {
        that.partList = res;
      });
    },
    //保存
    saveSvg() {
      let that = this;
      this.loading = this.openLoading();
      this.pageData = JSON.parse(JSON.stringify(that.addForm));
      this.svgs.saveNode(async function (data) {
        await that.setRemarkImg();
        if (that.drawType == 1) {
          data.img.img_url = that.imageUrl;
        } else {
          data.img.img_url = that.svgs.svgParam.data.img.img_url;
        }
        let remark_list = [];
        if (that.checkRemarkList.length > 0) {
          remark_list = that.checkRemarkList.filter((item) =>
            that.textureRemarkList.every((val) => item.num != val.num)
          );
        }
        let remark = {
          remark: {
            remark: that.remark,
            textureRemarkList: [...that.textureRemarkList, ...remark_list],
          },
        };
        data.allData = that.svgs.svgParam.allData;
        data["remark"] = remark.remark;
        data["pageData"] = that.pageData;
        data["textureList"] = that.uploadTextureList;
        let datas = {
          ...that.pageData,
          ...{ label: data },
          ...{ label_attribute: data.allData },
          ...remark,
          ...{ type: 1 },
          ...{ version: that.version },
        };
        if (that.drawType == 1) {
          datas = { ...datas, ...{ product_id: that.product_id } };
          that.$api.saveBom(datas).then(async (res) => {
            if (that.submitType == 2) {
              await that.svgs.removeSelect();
              let svg_img = await that.toExportSvgImg();
              let bom_img = await that.toExportBomImg();
              that.svgs.svgParam.allData.forEach((val) => {
                that.svgs.resetInput(val);
              });
              data["bom_img"] = bom_img[0];
              data["svg_img"] = svg_img[0];
              that.pageData = { ...that.pageData, ...{ sn: res.sn } };
              that.toReview({ ...data, ...{ sn: res.sn } });
            } else {
              that.saveInfo();
              that.loading.close();
            }
          });
        } else {
          datas = { ...datas, ...{ id: that.draw_id } };
          that.$api.updateBom(datas).then(async (res) => {
            if (res.updateUser) {
              that.loading.close();
              let user = res.updateUser;
              let first_name = res.updateUser.first_name
                ? res.updateUser.first_name
                : "";
              let last_name = res.updateUser.last_name
                ? res.updateUser.last_name
                : "";
              that
                .$confirm(
                  `检测到当前设计图被用户${
                    !first_name && !last_name
                      ? user.user_mobile
                      : first_name + last_name
                  }更新,是否覆盖?`,
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                .then(() => {
                  that.version = res.version;
                  that.saveSvg();
                })
                .catch(() => {});
            } else {
              that.version = res.version;
              if (that.submitType == 2) {
                await that.svgs.removeSelect();
                let svg_img = await that.toExportSvgImg();
                let bom_img = await that.toExportBomImg();
                that.svgs.svgParam.allData.forEach((val) => {
                  that.svgs.resetInput(val);
                });
                data["bom_img"] = bom_img[0];
                data["svg_img"] = svg_img[0];
                that.pageData = { ...that.pageData, ...{ sn: that.sn } };
                that.toReview({ ...data, ...{ sn: that.sn } });
              } else {
                that.saveInfo();
                that.loading.close();
              }
            }
          });
        }
      });
    },
    //保存
    saveInfo() {
      that.closeRemarkModal();
      that.$router.back();
      that.loading.close();
    },
    //上传备注图
    async setRemarkImg() {
      if (that.textureRemarkList.length > 0) {
        let list = that.textureRemarkList;
        for (let i in list) {
          if (list[i].img_file) {
            var img = await that.uploadImg(list[i].img_file);
            let val = list[i];
            val.cover_pic = img[0];
            val.img_file = "";
            await that.svgs.svgParam.allData.some((item, index) => {
              if (item.nodeNum == list[i].num) {
                item.texture_remark_img = img[0];
                that.$set(that.svgs.svgParam.allData, index, item);
                that.textureRemarkList.splice(i, 1, val);
                if (item.sn) {
                  that.uploadTextureList.push({
                    img_url: item.texture_remark_img,
                    sn: item.sn,
                  });
                }

                return true;
              }
            });
          }
        }
      }
    },
    //上传图片
    uploadImg(file) {
      let pictureList = [file];
      return new Promise((resolve) => {
        uploadCos(pictureList, "works", (picture) => {
          resolve(picture);
        });
      });
    },

    //设置颜色
    getColor(data) {
      let type = 1; //是否设置颜色
      if (this.svgs.svgParam.oneData.cover_pic != "") {
        type = 0;
      }
      this.svgs.setImgColor(data, type);
    },
    //设置图片
    getImage(data) {
      this.svgs.setImage(data);
    },
    //设置编号
    changeSn(val) {
      this.svgs.setTextureSn(val);
    },
    //设置工艺
    changeTech(val) {
      this.svgs.setTextureTechnology(val);
    },
    //设置部件
    getPart(data) {
      this.svgs.setPart(data);
    },
    //设置图片大小
    changeImgSize(data) {
      let check = Object.keys(this.svgData).length;
      if ((check > 0 && !this.svgData.data.param.beginDraw) || check == 0)
        return;
      this.svgs.setImageSize(data);
    },
    //创建svg
    createSvg() {
      var e = document.querySelector("svg");
      var child = e.lastElementChild;
      while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
      }
      setTimeout(() => {
        this.svgData = {
          data: {
            svgWidth: this.svgWidth,
            svgHeight: this.svgHeight,
            img: {
              img_url: "",
            },
            param: {},
          },
        };
        this.svgs = new Svg(this.svgData);
        that.textureRemarkList = [];
      }, 50);
    },
    setSvg() {
      let that = this;
      this.loading = that.openLoading();

      this.$api
        .getBom({
          id: that.draw_id,
        })
        .then((res) => {
          let data = res.label;

          this.multiple = this.svgWidth / data.svgWidth;
          let multiple = 1;
          if (data.svgWidth > this.svgWidth) {
            multiple = this.multiple;
          }
          that.svgData = {
            data: {
              // svgWidth:data.svgWidth>this.svgWidth?data.svgWidth:this.svgWidth,
              // svgHeight:data.svgHeight>this.svgHeight?data.svgHeight:this.svgHeight,
              svgWidth: this.svgWidth,
              svgHeight: this.svgHeight,
              img: data.img,
              multiple: multiple,
              param: {},
            },
          };
          if (data.svgWidth > this.svgWidth) {
            that.isHiddenX = false;
          }
          // that.pageHeight=data.svgHeight>this.svgHeight?data.svgHeight+96:this.svgHeight+96
          that.pageHeight = this.svgHeight + 110;
          that.svgs = new Svg(that.svgData);
          that.svgs.svgParam.allData = res.label.allData;
          that.svgs.svgParam.oneData =
            res.label_attribute.length > 0
              ? res.label_attribute[res.label_attribute.length - 1]
              : {};
          that.imgSize = data.img.imgSize ? data.img.imgSize : 50;
          that.changeImgSize(that.imgSize);
          that.remarkText = res.remark.remark;
          that.checkRemarkList = res.remark.textureRemarkList;
          that.svgHeight = data.svgHeight;
          that.version = res.version;
          that.sn = res.sn;
          if (data.lines.length > 0) {
            data.lines.forEach((val, key) => {
              that.svgs.createLine(
                Number(val.x1) * multiple,
                Number(val.y1) * multiple,
                Number(val.x2) * multiple,
                Number(val.y2) * multiple,
                Number(that.svgs.svgParam.allData[key].nodeNum),
                Number(that.svgs.svgParam.allData[key].num)
              );
              that.svgs.drawRect(
                Number(val.x2) * multiple,
                Number(val.y2) * multiple,
                Number(that.svgs.svgParam.allData[key].nodeNum),
                data.rects[key].info,
                Number(that.svgs.svgParam.allData[key].num),
                2
              );
            });
          }
          Object.keys(that.pageData).forEach((key) => {
            that.pageData[key] = res[key];
          });
          Object.keys(that.addForm).forEach((key) => {
            that.addForm[key] = res[key];
          });
          // that.$d3.select("#svgBox").attr('transform',`scale(${multiple},${multiple})`)
          that.isFirstCreate = true;
          that.loading.close();
        });
    },
    handleSelect(row) {
      this.selected = row;
      this.$nextTick(() => {
        const svg = document
          .getElementById("svgBox")
          .getElementById("recGroup").children;
        [...svg].forEach((e) => {
          e.style = "border:1px solid #ccc";
          if (e.attributes.recNum.value == this.selected) {
            e.style = "border:2px solid #21c3a9";
          }s
        });
      });
    },
    handleClick(e) {
		let that = this;
		 this.svgs.saveNode(async function (data) {
			 //let svg_img = await that.toExportSvgImg();
			//let bom_img = await that.toExportBomImg();
			//data["bom_img"] = bom_img[0];
			 //data["svg_img"] = svg_img[0];
			 that.reviewData = data;
			 console.log(that.reviewData);
			 that.svgs.setPart({name:'帮面'});
		 })
      const path = e.path;
      path.forEach((p) => {
        if (p.tagName == "foreignObject") {
          this.selected = p.attributes.recNum.value;
          return;
        }
      });
    },
	updateData(data){
		if(data.nodeNum){
			this.svgs.svgParam.selectNum = data.nodeNum
			this.svgs.setTextureSn(data.materialName);
			this.svgs.setImage(data)
		}
	}
  },
};
</script>
<style lang="scss">
@import "draw.scss";
</style>

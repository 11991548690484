var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"formTables",staticClass:"form-table-box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"id":"formTableData","data":_vm.tableData,"border":true,"header-cell-class-name":"table-header","row-class-name":_vm.formTableRow,"row-style":_vm.formRowStyle},on:{"row-click":_vm.rowClick,"header-dragend":_vm.headerDragend}},_vm._l((_vm.propAllData),function(val){return _c('el-table-column',{key:val.prop,attrs:{"prop":val.prop,"label":val.label,"align":"center","width":val.width ? val.width : ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[(val.prop == 'style_number')?_c('div',{staticClass:"style-number-box"},[_c('span',[_vm._v(_vm._s(scope.row.style_number))]),(
                  _vm.nowIndex === scope.$index &&
                  !_vm.checkEdit &&
                  _vm.isShowSetting
                )?_c('div',{staticClass:"setting-box"},[_c('i',{staticClass:"iconfont icon-jian",on:{"click":function($event){$event.stopPropagation();return _vm.removeProduct(scope.$index)}}})]):_vm._e()]):(val.prop == 'img_url')?_c('div',[(_vm.checkEdit)?_c('div',{staticClass:"row-height"},[_vm._v("+")]):_c('div',{style:({ height: scope.row.height + 'px' })},[(scope.row.img_url)?_c('img',{staticClass:"form-img",attrs:{"src":scope.row.img_url + '?imageMogr2/thumbnail/!30p'},on:{"click":function($event){return _vm.showDetailDialog(scope.row, scope.$index)}}}):_vm._e()])]):(
                val.prop == 'intro' ||
                val.prop == 'size' ||
                val.prop == 'color' ||
                val.prop == 'number' ||
                val.prop == 'price' ||
                val.prop == 'operation'
              )?_c('div',[_c('div',[(
                    (!_vm.checkEdit && !_vm.isUnEdit) ||
                    val.prop === 'operation' ||
                    (_vm.isUnEdit &&
                      val.prop === 'price' &&
                      _vm.userRole === 'company'&&!_vm.isHistory)
                  )?_c('div',[(val.prop == 'intro')?_c('div',[_c('InputTextArea',{staticClass:"textarea-input",attrs:{"autoSize":""},model:{value:(scope.row.intro),callback:function ($$v) {_vm.$set(scope.row, "intro", $$v)},expression:"scope.row.intro"}})],1):_vm._e(),(val.prop == 'size')?_c('div',[_c('InputTextArea',{staticClass:"textarea-input",attrs:{"autoSize":""},model:{value:(scope.row.size),callback:function ($$v) {_vm.$set(scope.row, "size", $$v)},expression:"scope.row.size"}})],1):_vm._e(),(val.prop == 'color')?_c('div',[_c('InputTextArea',{staticClass:"textarea-input",attrs:{"autoSize":""},model:{value:(scope.row.color),callback:function ($$v) {_vm.$set(scope.row, "color", $$v)},expression:"scope.row.color"}})],1):_vm._e(),(val.prop == 'number')?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(scope.row.number),expression:"scope.row.number"}],staticClass:"table-input",domProps:{"value":(scope.row.number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(scope.row, "number", $event.target.value)},function($event){return _vm.changeInput(scope.row, scope.$index)}],"keyup":function($event){scope.row.number = scope.row.number.replace(/\D/g, '')},"blur":function($event){return _vm.setAmount(scope.row, scope.$index, val.prop)}}})]):_vm._e(),(val.prop == 'operation')?_c('div',[(_vm.userRole === 'custom')?_c('div',{staticClass:"operation-btn-box"},[(scope.row.operation.type === 'operation')?_c('div',{staticClass:"operation-btn operation"},[_vm._v(" 目标:"+_vm._s(scope.row.operation.price)+" ")]):(scope.row.operation.type === 'confirmed')?_c('div',{staticClass:"operation-btn operation"},[_vm._v(" 已确认 ")]):_c('div',{staticClass:"operation-btn-box"},[_c('div',{staticClass:"operation-btn confirm",on:{"click":function($event){return _vm.confirmOperation(scope.$index)}}},[_vm._v(" 确认 ")]),_c('div',{staticClass:"operation-btn bargain",on:{"click":_vm.toOperation}},[_vm._v(" 议价 ")])]),(
                          scope.row.operation.type === 'operation' ||
                          scope.row.operation.type === 'confirmed'
                        )?_c('i',{staticClass:"iconfont icon-undo",on:{"click":_vm.backOperation}}):_vm._e()]):_c('div',[_c('div',{staticClass:"operation-btn-box"},[(scope.row.operation.type === 'operation')?_c('div',{staticClass:"operation-btn operation"},[_vm._v(" 目标:"+_vm._s(scope.row.operation.price)+" ")]):(scope.row.operation.type === 'confirmed')?_c('div',{staticClass:"operation-btn operation"},[_vm._v(" 已确认 ")]):_c('div',{staticClass:"operation-btn unconfirmed"},[_vm._v(" 未确认 ")])])])]):_vm._e(),(val.prop == 'price')?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(scope.row.price),expression:"scope.row.price"}],staticClass:"table-input",domProps:{"value":(scope.row.price)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(scope.row, "price", $event.target.value)},function($event){return _vm.changeInput(scope.row, scope.$index)}],"keyup":function($event){scope.row.price = scope.row.price.replace(
                          /[^0-9_\.]/g,
                          ''
                        )},"focus":function($event){return _vm.intoPrice(scope.row, scope.$index)},"blur":function($event){return _vm.setAmount(scope.row, scope.$index, val.prop)}}})]):_vm._e()]):_c('div',{staticClass:"row-height"},[_vm._v(" "+_vm._s(scope.row[val.prop])+" ")])])]):_c('div',[_vm._v(" "+_vm._s(scope.row[val.prop])+" ")])])]}}],null,true)})}),1),(_vm.isShowOperation)?_c('operationDialog',{attrs:{"top":_vm.operationTop},on:{"setOperationPrice":_vm.setOperationPrice,"closeOperation":_vm.closeOperation}}):_vm._e()],1),(_vm.isShowDetailModal)?_c('formTableDetail',{attrs:{"itemData":_vm.itemData},on:{"toNextGoods":_vm.toNextGoods,"closeDetailModal":_vm.closeDetailModal}}):_vm._e(),_c('el-upload',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"uploadImg",attrs:{"action":"","accept":".jpg,.jpeg,.png,.JPG,.PNG","multiple":"","before-upload":_vm.beforeUpload,"show-file-list":false,"http-request":_vm.picUpload}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
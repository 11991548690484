<template>
  <div class="cbd-header">
    <div class="cbd-table-box">
      <div
        class="cbd-table-column"
        v-for="(val, key) in tableData.data"
        :key="key"
      >
        <div class="cbd-table-key-box key-color">
          <span>{{ val.label }}</span>
        </div>
        <div class="cbd-table-key-box">
          <el-input
            v-model="val.value"
            type="textarea"
            autosize
            size="mini"
          ></el-input>
        </div>
      </div>
    </div>

    <div class="goods-msg-picture">
      <el-upload
        class="upload-goods-img"
        action=""
        accept=".jpg,.jpeg,.png,.JPG,.PNG"
        multiple
        :before-upload="beforeUpload"
        :show-file-list="false"
        :http-request="picUpload"
      >
        <el-image
          v-if="imageUrl"
          :src="imageUrl"
          class="avatar"
          fit="contain"
        ></el-image>
        <div v-else>
          <div class="iconfont icon-ic_add icon-add"></div>
          <!--          <div class="upload-text">-->
          <!--            <div>选择图片</div>-->
          <!--          </div>-->
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: "cbdHeader",
  props: {
    headerData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      isUnEdit: false,
      imageUrl: this.headerData.goodsImg,
      img_file: "",
      tableData: this.headerData,
    };
  },
  watch: {
    headerData(val) {
      this.tableData = val;
      this.imageUrl = val.goodsImg;
    },
  },
  methods: {
    // 选择图片
    picUpload(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.imageUrl = e.target.result; // 将图片路径赋值给src
      };
      reader.readAsDataURL(file.file);
      this.img_file = file.file;
      this.tableData.goodsImg = file.file;
    },
    //上传图片限制
    beforeUpload(file) {
      let that = this;
      let limit_file = 4;
      const limit = file.size / 1024 / 1024 < limit_file;
      if (!limit) {
        this.$message({
          message: `${that.$t("uploadWarning")} ${limit_file}MB`,
          type: "warning",
        });
      }
      return limit;
    },
  },
};
</script>

<style lang="scss">
$cbd-border: #ebeef5;
.cbd-header {
  width: 100%;
  display: flex;
  position: relative;
  .cbd-table-box {
    width: 50%;
    border-left: 1px solid $cbd-border;
    border-top: 1px solid $cbd-border;
    display: flex;
    flex-wrap: wrap;

    .cbd-table-column {
      width: 50%;
      min-height: 22px;
      flex-shrink: 0;
      display: flex;
      overflow: hidden;
      position: relative;
      .cbd-table-key-box {
        display: flex;
        align-items: center;
        width: 50%;
        min-height: 22px;
        padding: 4px;
        border-right: 1px solid $cbd-border;
        border-bottom: 1px solid $cbd-border;
      }
    }
  }
  .key-color {
    color: $base-config-color;
    font-weight: 500;
  }
  .goods-msg-picture {
    width: 50%;
    height: 100%;
    border: 1px solid $cbd-border;
    border-left: none;
    position: absolute;
    right: 0;
    top: 0;
    .upload-goods-img {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .avatar {
        width: auto;
        height: 160px;
      }
      .icon-add {
        font-size: 40px;
      }
    }
  }

  .el-table {
    font-size: $base-font-size;
    color: #333;
  }
  .w-full {
    width: 100%;
  }
  span {
    font-size: $base-font-size;
  }
  .el-table .cell {
    padding-left: 6px !important;
    padding-right: 6px !important;
    box-sizing: content-box;
    line-height: 20px;
  }
  .el-input--mini .el-input__icon {
    line-height: 22px;
  }
  .el-table td,
  .el-table th {
    padding: 1px 0 !important;
  }
  .el-input__inner {
    height: 22px;
    line-height: 22px;
    font-size: $base-font-size;
    width: 100% !important;
  }
  .el-textarea__inner {
    font-size: $base-font-size;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    resize: none;
    padding: 5px 0px !important;
  }
  .form-title {
    .el-input__inner {
      font-weight: 600;
      font-size: $base-font-size;
    }
  }
  .input-cell {
    min-height: 22px;
    display: flex;
    align-items: center;
    width: 100%;
    .el-input--mini {
      width: 100%;
    }
  }
  .header-text {
    color: $base-config-color;
    font-weight: 600;
    font-size: $base-font-size;
  }
}
</style>

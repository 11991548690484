<template>
  <a-form class="material-form">
    <a-form-item>
      <span slot="label">
        {{ $t("materialName") }}
        <!-- <a-tooltip placement="bottom" title="name">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-input
        v-model="material.name"
        :placeholder="$t('placeHolderMaterialName')"
        @change="handleNameChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("materialNumber") }}
        <!--  <a-tooltip placement="bottom" title="name">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-input
        v-model="material.sn"
        :placeholder="$t('placeHolderMaterialNumber')"
        @change="handleNumberChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("placement") }}
        <!-- <a-tooltip placement="bottom" title="材质编号">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-cascader
        :options="placementOptions"
        :placeholder="$t('pleaseSelect')"
        @change="onChangePlacement"
      />
    </a-form-item>
    <!-- <a-form-item>
      <span slot="label">
        metallic
        <a-tooltip placement="bottom" title="metallic">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-slider :min="0" :max="1" :step="0.1" @change="handlemetallicChange" />
    </a-form-item> -->
    <a-form-item>
      <span slot="label">
        {{ $t("materialRoughness") }}
        <!-- <a-tooltip placement="bottom" title="roughness">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-slider
        :min="0.3"
        :max="1"
        :step="0.1"
        v-model="material.param.roughness"
        @change="handleRoughnessChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("environmentIntensity") }}
        <!-- <a-tooltip placement="bottom" title="environment intensity">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-slider
        :min="0.1"
        :max="5"
        :step="0.1"
        v-model="material.param.environmentIntensity"
        @change="handleEnvironmentIntensityChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("colorTexture") }}
        <!-- <a-tooltip placement="bottom" title="albedo texture">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <image-picker
        :visible.sync="showImagePick"
        @ok="handleOkAlbedoTexture"
      ></image-picker>
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("alphaSwitch") }}
      </span>
      <a-switch
        checked-children="开"
        un-checked-children="关"
        @change="switchChange"
      />
    </a-form-item>
    <!-- <a-row>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            vScale
            <a-tooltip placement="bottom" title="albedoTexture vScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number
            @change="handlealbedoTexturevScaleChange"
          ></a-input-number>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            uScale
            <a-tooltip placement="bottom" title="albedoTexture uScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number
            @change="handlealbedoTextureUScaleChange"
          ></a-input-number>
        </a-form-item>
      </a-col>
    </a-row> -->
    <a-form-item>
      <span slot="label">
        {{ $t("textureSize") }}
        <!-- <a-tooltip placement="bottom" title="albedoTexture vScale">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-slider
        :min="0.1"
        :max="2"
        :step="0.1"
        v-model="material.param.albedoTexture.uScale"
        @change="handlealbedoTexturevScaleChange"
      ></a-slider>
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("bumpTexture") }}
        <!-- <a-tooltip placement="bottom" title="">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <image-picker
        :visible.sync="showImagePick"
        @ok="handleOkBumpTexture"
      ></image-picker>
    </a-form-item>
    <!-- <a-row>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            vScale
            <a-tooltip placement="bottom" title="bump texture vScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number @change="handleBumpVChange"></a-input-number>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            uScale
            <a-tooltip placement="bottom" title="bump texture uScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number @change="handleBumpUChange"></a-input-number>
        </a-form-item>
      </a-col>
    </a-row> -->
    <!-- <a-form-item>
      <span slot="label">
        uv scale
        <a-tooltip placement="bottom" title="bump texture vScale">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-slider
        :min="0.1"
        :max="10"
        :step="0.1"
        @change="handleBumpVChange"
      ></a-slider>
    </a-form-item> -->
    <!-- <a-form-item>
      <span slot="label">
        ambient Texture
        <a-tooltip placement="bottom" title="ambientTexture">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <image-picker
        :visible.sync="showImagePick"
        @ok="handleOkambientTexture"
      ></image-picker>
    </a-form-item> -->
    <!-- <a-row>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            vScale
            <a-tooltip placement="bottom" title="ambient Texture vScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number
            @change="handleambientTextureVChange"
          ></a-input-number>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            uScale
            <a-tooltip placement="bottom" title="ambient Textureu Scale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number
            @change="handleambientTextureUChange"
          ></a-input-number>
        </a-form-item>
      </a-col>
    </a-row> -->
    <a-form-item>
      <span slot="label">
        {{ $t("textureSize") }}
        <!-- <a-tooltip placement="bottom" title="ambient Texture vScale">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-slider
        :min="0.1"
        :max="2"
        :step="0.1"
        v-model="material.param.bumpTexture.uScale"
        @change="handleambientTextureVChange"
      ></a-slider>
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("textureColor") }}
        <!-- <a-tooltip placement="bottom" title="材质颜色">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <color-picker
        class="color-picker"
        v-model="material.param.albedoColor"
        @change="handleAlbeColorPicker"
      ></color-picker>
    </a-form-item>
  </a-form>
</template>

<script>
import ImagePicker from "@/components/ImagePicker";
import mixin from "@/components/material/mixin";
import ColorPicker from "@/components/v2/color-picker/ColorPicker.vue";
export default {
  name: "cloth",
  components: {
    ImagePicker,
    ColorPicker,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    option: {
      type: Object,
      default: () => ({
        name: "",
        sn: "",
        cat_id: 2,
        shape: "cap",
        type: "pbr",
        material: "cloth",
        param: {
          reflectionTexture: {
            url: "texture/environmentSpecular.env",
            type: "env",
            material: null,
          },
          metallic: 0,
          roughness: 1,
          environmentIntensity: 1,
          albedoTexture: {
            url: "",
            type: "color",
            material: null,
            vScale: 1,
            uScale: 1,
          },
          bumpTexture: {
            url: "",
            type: "bump",
            material: null,
            vScale: 1,
            uScale: 1,
            level: 1,
          },
          ambientTexture: {
            url: "",
            type: "occ",
            material: null,
            vScale: 1,
            uScale: 1,
            level: 1,
          },
        },
      }),
    },
  },
  data() {
    return {
      material: this.option,
      showImagePick: false,
    };
  },
  methods: {
    handleNameChange(e) {
      this.material.name = e.target.value;
      this.emit(this.material);
    },
    handleNumberChange(e) {
      this.material.sn = e.target.value;
      this.emit(this.material);
    },
    handleChange() {
      this.emit(this.material);
    },
    handleRoughnessChange(val) {
      this.material.param.roughness = val;
      this.emit(this.material);
    },
    handlemetallicChange(val) {
      this.material.param.metallic = val;
      this.emit(this.material);
    },
    handleEnvironmentIntensityChange(val) {
      this.material.param.environmentIntensity = val;
      this.emit(this.material);
    },
    handlealbedoTexturevScaleChange(val) {
      this.material.param.albedoTexture = {
        ...this.material.param.albedoTexture,
        vScale: val,
        uScale: val,
      };
      this.emit(this.material);
    },
    handlealbedoTextureUScaleChange(val) {
      this.material.param.albedoTexture = {
        ...this.material.param.albedoTexture,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleOkAlbedoTexture(file) {
      if (file) {
        this.material.param.albedoTexture = {
          ...this.material.param.albedoTexture,
          ...file,
        };
      } else {
        this.material.param.albedoTexture = {
          ...this.material.param.albedoTexture,
          url: null,
          id: null,
          type: null,
          material: null,
        };
      }
      this.emit(this.material);
    },
    handleOkBumpTexture(file) {
      if (file) {
        this.material.param.bumpTexture = {
          ...this.material.param.bumpTexture,
          ...file,
        };
      } else {
        this.material.param.bumpTexture = {
          ...this.material.param.bumpTexture,
          url: null,
          id: null,
          type: null,
          material: null,
        };
      }
      this.emit(this.material);
    },
    handleBumpVChange(val) {
      this.material.param.bumpTexture = {
        ...this.material.param.bumpTexture,
        vScale: val,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleBumpUChange(val) {
      this.material.param.bumpTexture = {
        ...this.material.param.bumpTexture,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleOkReflection(file) {
      if (file) {
        this.material.param.reflectionTexture = {
          ...this.material.param.reflectionTexture,
          ...file,
        };
      } else {
        this.material.param.reflectionTexture = {
          ...this.material.param.reflectionTexture,
          url: null,
          id: null,
          type: null,
          material: null,
        };
      }
      this.emit(this.material);
    },
    handleOkambientTexture(file) {
      if (file) {
        this.material.param.ambientTexture = {
          ...this.material.param.ambientTexture,
          ...file,
        };
      } else {
        this.material.param.ambientTexture = {
          ...this.material.param.ambientTexture,
          url: null,
          id: null,
          type: null,
          material: null,
        };
      }
      this.emit(this.material);
    },
    handleambientTextureUChange(val) {
      this.material.param.ambientTexture = {
        ...this.material.param.ambientTexture,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleambientTextureVChange(val) {
      this.material.param.ambientTexture = {
        ...this.material.param.ambientTexture,
        vScale: val,
        vScale: val,
      };
      this.emit(this.material);
    },
    handleAlbeColorPicker(e) {
      this.material.param.reflectionColor = e;
      this.emit(this.material);
    },
    switchChange(checked) {
      if (checked) {
        this.material.param.transparencyMode = 3;
        this.emit(this.material);
      } else {
        this.material.param.transparencyMode = null;
        this.emit(this.material);
      }
    },
  },
  mixins: [mixin],
};
</script>
<style lang="scss">
.ant-collapse {
  border-radius: 0;
}

.color-preview--wrap {
  position: relative;
}
.color-preview {
  width: 32px;
  height: 32px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
</style>

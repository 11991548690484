<template>
  <div class="aggregation">
    <ul>
      <!-- <img class="box_hide_img" @mouseover="isShow = !isShow" :src=img_path /> -->
      <li
        class="aggregation-item"
        v-for="(item, idx) in aggregation"
        :key="idx"
        @click="onMouseEnter(idx)"
      >
        <i class="iconfont icon-order"></i>
        <div class="right">
          <span class="number">{{ item.number }}</span>
          <span class="label">{{ item.label }}</span>
        </div>
      </li>
    </ul>
    <relation-table
      :visible.sync="visibble"
      :style="{ top }"
      :data="tableData"
      :colunms="colunms"
    ></relation-table>
  </div>
</template>

<script>
import RelationTable from "@/components/table/RelationTable.vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        enquired: 0,
        quoted: 0,
        recommend: 0,
        ordered: 0,
      }),
    },
  },
  components: {
    RelationTable,
  },
  data() {
    return {
      //img_path:require("../../../public/jiantouxia.png"),
      colunms: [],
      tableData: [],
      top: "1px",
      visibble: false,
      aggregation: [
        {
          number: this.data.enquired,
          label: "Enquired",
        },
        {
          number: this.data.quoted,
          label: "Quoted",
        },
        {
          number: this.data.recommend,
          label: "Recommend",
        },
        {
          number: this.data.ordered,
          label: "Ordered",
        },
      ],
    };
  },
  created() {},
  methods: {
    init() {},
    configColumns() {
      switch (this.type) {
        case "Enquired":
          this.colunms = [
            {
              title: "Quote No",
              dataIndex: "no",
              key: "no",
              align: "center",
              width: 128,
            },
            {
              title: "Customer",
              dataIndex: "customer",
              key: "customer",
              align: "center",
              width: 128,
            },
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
              align: "center",
              width: 128,
            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",
              align: "center",
              width: 128,
            },
          ];
          break;
        case "Quoted":
          this.colunms = [
            {
              title: "Quote No",
              dataIndex: "no",
              key: "no",
              align: "center",
              width: 128,
            },
            {
              title: "Customer",
              dataIndex: "customer",
              key: "customer",
              align: "center",
              width: 128,
            },
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
              align: "center",
              width: 128,
            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",
              align: "center",
              width: 128,
            },
          ];
          break;
        case "Recommend":
          this.colunms = [
            {
              title: "Quote No",
              dataIndex: "no",
              key: "no",
              align: "center",
              width: 128,
            },
            {
              title: "Customer",
              dataIndex: "customer",
              key: "customer",
              align: "center",
              width: 128,
            },
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
              align: "center",
              width: 128,
            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",
              align: "center",
              width: 128,
            },
          ];
          break;
        case "Ordered":
          this.colunms = [
            {
              title: "Ordered No",
              dataIndex: "no",
              key: "no",
              align: "center",
              width: 128,
            },
            {
              title: "Customer",
              dataIndex: "customer",
              key: "customer",
              align: "center",
              width: 128,
            },
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
              align: "center",
              width: 128,
            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",
              align: "center",
              width: 128,
            },
          ];
          break;
      }
    },
    onMouseEnter(idx) {
      // const h = 69;
      // this.top = h * idx + "px";
      // this.visibble = true;
      // this.type = this.aggregation[idx].label;
    },
  },
  // computed:{
  //  showList:function(){
  //   if(this.isShow == false){
  // 	  var showList=[];
  // 	  if(this.aggregation.length > 1){

  // 		  showList.push(this.aggregation[0]);
  // 	  }
  // 	  else{
  // 		  showList = this.aggregation
  // 	  }
  // 	  this.active = '';
  // 	  //this.img_path = require("../../../public/jiantouxia.png");
  // 	  return showList;
  //   }
  //   else{
  // 	  //this.img_path = require("../../../public/jiantouup.png");

  // 	  return this.aggregation;
  //   }
  //  },
  // },
};
</script>

<style lang="scss" scoped>
.aggregation {
  position: absolute;
  right: 0px;
  top: 0px;
  border: 1px solid #ccc;
  z-index: 3;
  background-color: #fff;
  color: var(--primaryColor);
  cursor: pointer;
  border-radius: 4px;
  height: 80px;
  width: 60px;
  background-image: url(../../../public/bar_chart.png);
  background-position: center center;
  background-size: 40px, 68px;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 1px #909090;
  li {
    font-size: 0px;
  }
  i {
    font-size: 0px;
  }
  &:hover {
    background-image: none;
    height: 277px;
    width: 190px;
    transition: width 0.3s, height 0.2s;
    .aggregation-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 14px 0px 14px 10px;
      border-bottom: 1px solid #ccc;
      &:hover {
        background: #f1fffa;
      }
      > i {
        font-size: 40px;
        margin-right: 8px;
      }
      span {
        display: block;
        font-size: 16px;
      }
      .number {
        margin-bottom: 4px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
::v-deep .relation-table__wrap {
  position: absolute;
  right: 192px;
  top: 1px;
  z-index: 2;
}
</style>

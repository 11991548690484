<template>
  <transition name="expand">
    <ul class="folder" v-show="visiable">
      <li
        class="item-folder"
        :class="idx == current ? 'selected' : ''"
        v-for="(f, idx) in folders"
        :key="f.id"
        @click="handleClick(idx)"
      >
        <span class="title">
          <i class="iconfont icon-wenjianjia"></i>
          <span>{{ f.name }}</span>
        </span>
        <span class="count">{{ f.num }}</span>
      </li>
    </ul>
  </transition>
</template>

<script>
export default {
  props: {
    visiable: {
      type: Boolean,
      default: false,
    },
    folders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      current: 0,
    };
  },

  computed: {},
  created() {},
  watch: {
    visiable(newVale) {
      this.show = newVale;
    },
  },
  methods: {
    handleClick(idx) {
      // this.SET_CURRENT_FOLDER(id);
      this.current = idx;
      this.$emit("selected", idx);
    },
  },
};
</script>

<style lang="scss">
.folder {
  max-height: 160px;
  width: 100%;
  padding: 0;
  overflow: auto;
  .item-folder {
    width: 100%;
    height: 32px;
    padding: 4px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: start;
    border-bottom: 1px solid #ccc;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > i {
        margin-right: 12px;
        font-size: 16px;
      }
    }
    &:hover {
      background-color: #ccc;
      cursor: pointer;
    }
  }
  .item-folder.selected {
    background-color: var(--primaryColor);
    color: #fff;
  }
}
.expand-enter-active {
  transition: all 0.2s linear;
}
.expand-leave-active {
  transition: all 0.2s linear;
  height: 0px;
}
.expand-enter {
  height: 0px;
}
.expand-leave-to {
  // opacity: 0;
  transition: all 0.2s linear;
  height: 0;
}
</style>

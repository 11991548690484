<template>
	<div v-if="origin_web == 1" class="profile-main">
		<img
		  src="@/assets/image/profile_1.png"
		  alt=""
		/>
		<img
		  src="@/assets/image/profile_2.png"
		  alt=""
		/>
	</div>
	<div v-else-if="origin_web == 3" class="profile-main">
		<img src="https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/fjqgy/20230516094028.jpg">
		<div style="width: 100%;padding: 100px 50px;">
			<img style="width: 50%;" src="https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/fjqgy/20230516094049.jpg">
			<div style="width: 50%;display: inline-block;vertical-align: top;padding-left: 20px;">
				<p style="font-size: 20px;">福建第二轻工业学校简介</p>
				<span style="line-height: 24px;margin-left: 20px;font-size: 16px;">福建第二轻工业学校位于福州市繁华的四大商圈之一-----首山路学生街，系中等职业学校教育改革发展示范学校、教育厅直属的国家级重点学校、福建省示范性现代职业院校建设工程项目学校、紧缺型技能人才培养基地、福建省文明学校、平安校园。学校集中专教育、五年制大专及职业培训于一体，开设有紧密对接海西经济区支柱产业（轻纺制造业）和新兴产业（现代服务业）的7 大类 16个专业，形成了“轻纺制造业、美妆形象设计、现代服务业、机电信息、室内设计、烹饪加工和连锁物流”等7条专业链。在校学生达三千多人，是一所具有鲜明特色的现代职业教育院校。
 福建第二轻工业学校推行“校企合作、产教融合、订单培养和现代学徒制”人才培养模式。服装设计鞋类设计与著名的阿帕索公司和福州朗浩公司、会计电算化专业与中华小餐饮研究所内20多家餐饮公司开展订单培养合作。室内设计专业与烹饪专业通过引进企业项目，聘请企业工程师或技术能手到校任教，全方位培养学生职业技能和职业素养，实现与企业的零距离对接。
 先进的办学理念和人才培养模式以及高素质的教师队伍，带来良好的办学成果，近三年60多名同学在全国职业院校技能大赛或全省职业院校大赛获得“一等奖、二等奖”，毕业生就业率和就业对口率长期保持在100%和98%，受到企业和学生家长的广泛认可。</span>
			</div>
		</div>
		<img src="https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/fjqgy/20230516094054.png">
	</div>
    <div v-else class="profile-main">
		<div class="bg-box"></div>
		<img class="ranch_logo" :src="cos_path+'ranch_logo.png'">
		<div class="banner-box" :style="{background:'url('+cos_path+'ranch_bg_1.png)'}">
			<div class="banner-title">
				<h1>Free your movement</h1>
				<p>Most helpful supplier to footwear Brands</p>
			</div>
		</div>
		<div class="profile-box" :style="{background:'url('+cos_path+'ranch_bg_2.png)'}">
			<div class='box-width'>
				<h1 class="profile-title-color">About Us</h1>
				<p class="profile-text">
					Ranch International Limited was incorporated in 2004 and is a foreign trade company with import and export business rights. The company is mainly engaged in the export of various footwear, and has its own production enterprise, Haochuan Shoes Factory and New Product Research and Development Center. It has nearly 20 professional footwear factories. covering Quanzhou ,Jinjiang, Putian, Fuqing Fuzhou Jianyang and Wenzhou. In addition.the company has also established a long-term friendly cooperative relationship with powerful shoe Material suppliers, and the good internal and external environment has greatly promoted the rapid development of the company's business
				</p>
			</div>
			<div style="text-align: left;margin-top: 90px;" class="box-width">
				<video style="background: #000;margin-left: 40px;width: calc(50% - 40px);" controls="controls" height="300px" src="https://file.visaf.net/2022129visaf3dmodel.mp4"></video>
				<div class="three-box">
					<h1 class="profile-title-color">3D Rendering</h1>
					<div class="three-item" style="cursor: pointer;" @click="dialogVisible3D = true">
						<div>
							<img :src="cos_path+'ranch_icon_1.png'">
							<p>Design</p>
						</div>
						<div>
							<img :src="cos_path+'ranch_icon_2.png'">
							<p>Modeling</p>
						</div>
						<div>
							<img :src="cos_path+'ranch_icon_3.png'">
							<p>3D Rendering</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="shoes-box" :style="{background:'url('+cos_path+'ranch_bg_3.png)'}">
			<div class="box-width">
				<h1>Product Center</h1>
				<div class="newMore">
					<button @click="goProducts">View More</button>
					<div class="newText">
						Click to see more product content, including design drawings, 3D models and physical merchandise
					</div>
				</div>
				<Banner style="left: 200px;" :bannerData="bannerData"></Banner>
			</div>
		</div>
		<div class="products-box">
			<h1 class="profile-title-color">My Service</h1>
			<div class="products box-width">
				<div  v-for="(item,index) in productsData" @click="getInfo(index)" style="cursor: pointer;">
					<img :src="item.img">
					<div class="product-name">
						{{item.name}}
					</div>
				</div>
			</div>
		</div>
		<div class="customers-box" :style="{background:'url('+cos_path+'ranch_bg_4.png)'}">
			<div class="box-width">
				<div class="customers-left">
					<h1>My Customers</h1>
					<p>Longstanding relationships we have built with our
						clients and suppliers, cultivates a balanced. inclusive.
						collaborative environment where we can gain human
						insight, generate ideas, expand our
						knowledge
					</p>
				</div>
				<div class="customers-right">
					<img :src="cos_path+'customer-logo-1.png'">
					<img :src="cos_path+'customer-logo-2.png'">
					<img :src="cos_path+'customer-logo-3.png'">
					<img :src="cos_path+'customer-logo-5.png'">
					<img :src="cos_path+'customer-logo-6.png'">
					<img :src="cos_path+'customer-logo-7.png'">
					<img :src="cos_path+'customer-logo-4.png'">
				</div>
			</div>
		</div>
		<div class="contact-box">
			<div class="box-width">
				<div class="contact-left">
					<h1 class="profile-title-color">Contact Us</h1>
					<p style="line-height: 20px;color: rgb(58, 110, 123)">If you are interested in our products or need customized services, please write down your requirements, we will contact you as soon as possible!</p>
					<!-- <div>
						<el-radio v-model="Info.checked" label="1">I'm a supplier</el-radio>
					</div>
					<div>
						<el-radio v-model="Info.checked" label="0">I'm a manufacturer</el-radio>
					</div> -->
					<!-- <div>
						<el-checkbox></el-checkbox>
						<span>I want to be a dealer </span>
					</div> -->
				</div>
				<div class="contact-right">
					<el-input style="width: calc(50% - 10px);" placeholder="First Name" v-model="Info.name"></el-input>
					<el-input style="width: calc(50% - 10px);margin-left:20px" placeholder="Last Name" v-model="Info.name2"></el-input>
					<el-input placeholder="Company" v-model="Info.enterprise"></el-input>
					<VuePhoneNumberInput v-model="Info.mobile" @update="update" style="margin-bottom: 10px;"></VuePhoneNumberInput>
					<el-input placeholder="Email" v-model="Info.email"></el-input>
					<el-input
					  type="textarea"
					  :rows="10"
					  maxlength="50"
					  show-word-limit
					  placeholder="Please enter the consultation content"
					  v-model="Info.textarea">
					</el-input>
				</div>
				<el-button class="profile-submit" @click="submitClick">Submit</el-button>
			</div>
		</div>
		<div class="footer-box" :style="{background:'url('+cos_path+'ranch_bg_5.png)'}">
			<div class="box-width">
				<div class="footer-left">
					<img :src="cos_path+'ranch_logo.png'">
					<h1>RANCH INTERNATIONAL LIMITED</h1>
					<p>phone: vicky +86 138 5902 9552</p>
					<p>email: vicky@ranchfootwear.com</p>
					<p>address: 38 Houyu Road,Fuxing Investment,Jin'an District,Fuzhou</p>
				</div>
				<div class="footer-right">
					<!-- <img :src="cos_path+'photos-13.png'">
					<p>Customer Service Consultation</p>
					<img :src="cos_path+'photos-14.png'">
					<p>Scan Code to Download JiYang App</p> -->
				</div>
			</div>
		</div>
		<!-- <img :src="cos_path+'ranch_bg_1.png'"> -->
		<!-- <img :src="cos_path+'ranch_bg_2.png'"> -->
		<!-- <img :src="cos_path+'ranch_bg_3.png'"> -->
		<!-- <img :src="cos_path+'ranch_bg_4.png'"> -->
		<!-- <img :src="cos_path+'ranch_bg_5.png'"> -->
		<el-dialog
		  :title="productsData[productsIndex].name"
		  :visible.sync="dialogVisible"
		  width="80%">
		  <div>
			  <img style="width: 60%;" :src="productsData[productsIndex].img" />
			  <p style="padding: 10px 80px;line-height: 20px;text-indent:26px">{{productsData[productsIndex].info}}</p>
		  </div>
		  <span slot="footer" class="dialog-footer">
			<div>
				<i class="el-icon-arrow-left" style="float: left;cursor:pointer" :style="{color:(productsIndex==0?'#999':'')}" @click="Previous()">Previous</i>
				<span @click="Next()" style="cursor:pointer" :style="{color:(productsIndex==productsData.length-1?'#999':'')}">
					Next<i class="el-icon-arrow-right"></i>
				</span>
			</div>
		  </span>
		</el-dialog>
		<el-dialog
		  title="3D Rendering  介绍"
		  :visible.sync="dialogVisible3D"
		  width="80%">
		  <div style="padding: 0px 60px;line-height: 20px;text-align: left;">
			 <p>内容介绍：We have full experiences with various kinds of products, the last 16 years we mainly work with European and China customers who request the high quality standards and flexible production capacity.We have full experiences with various kinds of products, the last 16 years we mainly work with European and China customers who request the high quality standards and flexible production capacity.</p>
			 <p>3D建模-实物的过程</p>
			 <p>3D建模能做什么？核心优势是什么？Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget odio.</p>
		  </div>
		  <el-button @click="goProducts" style="background: rgb(58, 110, 123);color: #fff;">Design Now</el-button>
		</el-dialog>
	</div>
</template>

<script>
import Banner from '../../components/banner.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
	name: "profile",
	components: {
		Banner,
		VuePhoneNumberInput
	},
	data() {
		return {
		  cos_path:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/',
		  bannerData:[
			  {
				  src:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-10.png',
				  link:''
			  },
			  {
				  src:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-11.png',
				  link:''
			  },
			  {
				  src:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-12.png',
				  link:''
			  }
		  ],
		  productsData:[
			  {
				  name:'Technical Service',
				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-01.png',
				  info:'We have full experiences with various kinds of products, the last 16 years we mainly work with European and China customers who request the high quality standards and flexible production capacity.We have full experiences with various kinds of products, the last 16 years we mainly work with European and China customers who request the high quality standards and flexible production capacity.'
			  },
			  {
				  name:'Customized',
				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-02.png'
			  },
			  {
				  name:'Brand Service',
				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-03.png'
			  },
			  {
				  name:'Event Planning',
				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-04.png'
			  },
			  {
			  				  name:'Supply Chain',
			  				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-05.png'
			  },
			  {
			  				  name:'Development Capability',
			  				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-06.png'
			  },
			  {
			  				  name:'Most Updated Tech Application',
			  				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-07.png'
			  },
			  {
			  				  name:'Material Sourcing',
			  				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-08.png'
			  },
			  {
			  				  name:'Inhouse Treatment',
			  				  img:'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/profile_html_img/photos-09.png'
			  }
		  ],
		  Info:{
			  name:'',
			  name2:'',
			  phone:'',
			  email:'',
			  textarea:'',
			  checked:'',
			  isPhone:false,
			  enterprise:''
		  },
		  origin_web:1,
		  dialogVisible:false,
		  productsIndex:0,
		  dialogVisible3D:false
		}
	},	
	created(){
		console.log(location.origin);
		if(location.origin == 'https://bom.futuroartigiano.net' || location.origin == 'http://en.ranchfootwear.com' || location.origin == 'http://www.ranchfootwear.com' || location.origin == 'http://192.168.4.3:8080'){
			this.origin_web = 2;
		}else if(location.origin == 'http://localhost:8080' || location.origin == 'https://fjqgy.futuroartigiano.net'){
			this.origin_web = 3;
		}
	},
	methods:{
		submitClick(){
			console.log(this.Info);
			if(this.Info.name == ''){
				this.$message({
				  message: 'The first name cannot be empty',
				  type: 'warning'
				});
				return;
			}
			if(!this.Info.email && !this.Info.phone){
				this.$message({
				  message: 'Please enter the correct email or mobile number',
				  type: 'warning'
				});
				return;
			}
			if(this.Info.email && !this.checkContact(this.Info.email)){
				this.$message({
				  message: 'Incorrect mailbox format',
				  type: 'warning'
				});
				return;
			}
			if(this.Info.phone && !this.Info.isPhone){
				this.$message({
				  message: 'Mobile number is incorrect',
				  type: 'warning'
				});
				return;
			}
			if(!this.Info.enterprise){
				this.$message({
				  message: 'Company cannot be empty',
				  type: 'warning'
				});
				return;
			}
			let data = {
				first_name:this.Info.name,
				last_name:this.Info.name2,
				user_email:this.Info.email,
				user_mobile:this.Info.phone,
				des:this.Info.textarea,
				clue_user_type:this.Info.checked,
				enterprise:this.Info.enterprise
			}
			let that = this;
			this.$api.postContact(data).then((res)=>{
				that.Info = {
				  name:'',
				  name2:'',
				  phone:'',  
				  textarea:'',
				  checked:'',
				  mobile:'',
				  enterprise:''
				};
				that.$message({
				  message: 'Information submitted successfully',
				  type: 'success'
				});
			})
			
		},
		goProducts(){
			this.$router.push({
			  name:'Home',
			})
		},
		//检查联系方式
		checkContact(value){
			const regEmail = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;//邮箱
		  if (regEmail.test(value)) {
			// 合法邮箱或手机号
			return true;
		  }else{
			return false;
		  }
		},
		update(val){
			if(val && val.formatInternational){
				this.Info.phone = val.formatInternational
			}else{
				this.Info.phone = '';
			}
			if(val.isValid){
				this.Info.isPhone = true;
			}else{
				this.Info.isPhone = false;
			}
		},
		getInfo(index){
			this.productsIndex = index;
			this.dialogVisible = true;
			console.log(index);
		},
		Previous(){
			if(this.productsIndex>0){
				this.productsIndex--;
			}
		},
		Next(){
			if(this.productsIndex<this.productsData.length-1){
				this.productsIndex++;
			}
		}
	}
}
</script>

<style scoped lang="scss">
.profile-main{
    width: 100%;
	min-width: 1200px;
    text-align: center;
	background: rgb(220,221,223);
    img{
        width: 100%;
        height: auto;
    }
	
	table {
	  border-collapse: collapse;
	  table-layout: fixed;
	  width: 100%;
	}
	
	th {
	  background-color: #eee;
	}
	
	tr:first-of-type th {
	  width: 100px;
	}
	
	tr:first-of-type th:first-of-type {
	  width: 25px;
	}
	
	td {
	  border: 1px solid #ccc;
	  height: 1.5em;
	  overflow: hidden;
	}
	

	.top-box-inner {

	}

	.top-box-inner::after {
		/* 这个伪类的作用就是一个圆弧的背景色 */
		width: 100%;
		height: 200px;
		position: absolute;
		left: 0;
		/* 之所以left:20%,是因为width:140%，宝贝可以是是别的值，例如width:160%，那么left:30%才能水平居中，可以发现规律的 */
		top: 780px;
		z-index: 2;
		/*层叠顺序，最底层显示*/
		content: '';
		border-radius: 50% 50% 0 0;
		/*分别对应 左上 右上 右下 左下*/
		background-color: #fff;
		/* 将这个伪类水平居中 */
	}
	
	.ranch_logo{
		width: 100px;
		position: absolute;
		top:70px;
		left:40px
	}
	
	.banner-box{
		width: 100%;
		height: 700px;
		background-size: 100% 700px !important;
		
		.banner-title{
			position: absolute;
			top: 300px;
			left: 40px;
			color:rgb(224, 224, 224);
			word-spacing:5px;
			font-size: 16px;
			text-align: left;
			
			h1{
				color: #fff;
				font-size: 22px;
				line-height: 30px;
				font-weight: bold;
			}
		}
	}
	
	.profile-box{
		width: 100%;
		height: 600px;
		background-size: 100% 600px !important; 
		position: relative;
		top: -100px;
		
		.profile-text{
			margin: 30px 100px;
			font-size: 16px;
			line-height: 22px;
			color: #666;
			word-spacing:5px
		}
	}
	
	.three-box{
		display: inline-block;
		text-align: center;
		width: 50%;
		height: 300px;
		vertical-align: top;
		padding-top:40px;
		
		.three-item{
			display: flex;
			padding-top: 30px;
			
			div{
				width: 33.33%;
				
				img{
					width: auto;
					height:60px
				}
				p{
					margin-top: 20px;
					font-size: 18px;
				}
			}
		}
	}
	
	.shoes-box{
		width: 100%;
		height: 650px;
		background-size: 100% 650px !important;
		position: relative;
		z-index: 2;
		
		h1{
			color:#fff;
			position: relative;
			left:120px;
			top: 230px;
			width: 200px;
		}
	}
	
	.products-box{
		padding-top: 20px;
		position: relative;
		z-index: 2;
		.products{
			text-align: left;
			padding: 30px;
			div{
				display: inline-block;
				width:33.33%;
				margin-bottom: 30px;
				
				img{
					width: calc(100% - 20px);
					margin-left: 10px;
				}
				
				.product-name{
					width: calc(100% - 20px);
					margin-left: 10px;
					height: 50px;
					background: rgb(64, 64, 66);
					margin-bottom:0px;
					color: #fff;
					font-size:18px;
					line-height: 50px;
					text-align: center;
				}
			}
		}
	}
	
	.customers-box{
		position: relative;
		z-index: 2;
		width: 100%;
		height: 800px;
		background-size: 100% 800px !important;
		
		.customers-left{
			width: 50%;
			color:#fff;
			padding-top: 250px;
			display: inline-block;
			
			h1{
				color:#fff;
				width: 200px;
				margin-left: 80px;
				text-align: left;
			}
			
			p{
				margin: 40px 80px;
				text-align: left;
				color:rgb(224, 224, 224);
				word-spacing:5px;
				font-size: 16px;
				line-height: 24px;
			}
		}
		
		.customers-right{
			width: 50%;
			display: inline-block;
			padding-top: 250px;
			vertical-align: top;
			
			img{
				width: 140px;
				margin:20px
			}
		}
	}
	
	.contact-box{
		.contact-left{
			width: 400px;
			display: inline-block;
			text-align: left;
			padding-left: 80px;
			color: rgb(125, 125, 125);
			vertical-align: top;
			margin-top: 100px;
			
			h1{
				margin-bottom: 30px;
			}
			
			div{
				margin-top: 15px;
			}
			.el-checkbox{
				margin-right: 10px;
			}
		}
		
		.contact-right{
			width:calc(100% - 400px);
			display: inline-block;
			padding: 100px 100px 50px 200px;
			text-align: center;
			
			.el-input{
				margin-bottom: 10px;
			}
		}
		
		.profile-submit{
			border-radius: 20px;
			background: rgb(58,110,123);
			color:#fff;
			margin-left: 40%;
		}
	}
	
	.bg-box{
		width: 100%;
		height: 1700px;
		background: #fff;
		position: absolute;
		z-index: 1;
		top: 1700px;
	}
	
	.footer-box{
		width: 100%;
		height: 600px;
		background-size: 100% 600px !important;
		
		.footer-left{
			display: inline-block;
			width: 50%;
			padding: 200px 0px 0px 80px;
			text-align: left;
			
			img{
				width: 100px;
			}
			
			h1{
				color:#fff;
				margin:80px 0px;
			}
			
			p{
				color:rgb(224, 224, 224);
			}
		}
		
		.footer-right{
			display: inline-block;
			width: 50%;
			
			img{
				width: 100px;
			}
			
			p{
				color:rgb(224, 224, 224);
				margin-top: 20px;
				margin-bottom:40px;
			}
		}
	}
	
	.profile-title-color{
		color: rgb(58, 110, 123);
	}
	
	.box-width{
		width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.newMore{
		position: relative;
		left: 140px;
		top: 250px;
		text-align:left;
		
		button{
			background: #fff;
			border:none;
			padding:7px;
			border-radius: 5px;
		}
		
		/* button:hover+.newText{
			display: block;
		} */
		
		.newText{
			width: 200px;
			color:#fff;
			border:1px solid #fff;
			padding:10px;
			border-radius:5px;
			position:relative;
			left:90px;
			display:none;
		}
	}
}
</style>

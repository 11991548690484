<template>
  <div class="btn" :class="disable ? 'disable' : null" @click="handleClick">
    <i :class="icon" class="iconfont"></i><span><slot></slot></span>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      if (this.disable) {
        return;
      }
      this.$emit("click");
    },
  },
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  padding: 4px 8px;
  border-radius: 6px;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  user-select: none;
  position: relative;
  font-family: Avenir, Helvetica, "Microsoft YaHei", 微软雅黑 Arial, sans-serif;
  i {
    margin-right: 6px;
  }
  &:hover {
    background-color: rgba(55, 53, 47, 0.08);
  }
  &:last-child {
    margin-right: 0;
    i {
      margin-right: 0px;
    }
  }
}
.btn.disable {
  cursor: not-allowed;
}
</style>

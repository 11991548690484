<template>
  <div class="default">
    <!--    <Header></Header>-->
    <Main>
      <router-view></router-view>
    </Main>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Main from "@/components/Main";
export default {
  components: {
    // Header,
    Main,
  },
};
</script>

<style lang="scss">
.default {
  // background-color: var(--bgColor);
}
</style>

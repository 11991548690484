var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"design-item-box",on:{"click":_vm.toDetail}},[_c('div',{staticClass:"design-item hover-animate",class:[_vm.isMouseEnter || _vm.isChoose ? 'show-border-hover' : '']},[_c('div',{staticClass:"design-image-box hover-animate",class:[
        _vm.itemData.type == 2 ? 'three-d-image-box' : '',
        _vm.isMouseEnter || _vm.isChoose ? 'show-border-bottom' : '',
      ]},[(_vm.itemData.type == 3 && _vm.itemData.file_info.gif_file)?_c('el-image',{staticClass:"design-image",attrs:{"src":_vm.itemData.file_info.gif_file + '?imageMogr2/thumbnail/!20p',"fit":"contain"}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])]):_c('el-image',{staticClass:"design-image",attrs:{"src":_vm.itemData.file_info.main_file.img_url +
          `${
            _vm.itemData.type === 3
              ? '?imageMogr2/thumbnail/!10p'
              : '?imageMogr2/thumbnail/!20p'
          }`,"fit":"contain"}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])]),_c('span',{staticClass:"design-type-text"},[_vm._v(_vm._s(_vm.itemData.type == 1 ? _vm.$t("design") : _vm.itemData.type == 2 ? _vm.$t("threeD") : _vm.$t("goods")))])],1),_c('div',{staticClass:"design-msg-box"},[_c('div',{staticClass:"design-name cloumn-1"},[_vm._v(" "+_vm._s(_vm.itemData.name)+" ")]),_c('div',{staticClass:"design-detail cloumn-1"},[_vm._v(" "+_vm._s(_vm.itemData.intro)+" ")]),_c('div',{staticClass:"design-price"},[_vm._v("$"+_vm._s(_vm.itemData.price))]),(_vm.isToChooseType === 1)?_c('div',{staticClass:"design-card-bottom"},[_c('div',{staticClass:"card-icon-box"},[_c('i',{staticClass:"iconfont icon-yanjing card-icon"}),_c('span',[_vm._v(_vm._s(_vm.itemData.view_count))])]),_c('div',{staticClass:"card-icon-box",on:{"click":function($event){$event.stopPropagation();return _vm.changeCollect.apply(null, arguments)}}},[(_vm.is_like === 0)?_c('i',{staticClass:"iconfont icon-shoucang card-icon"}):_c('i',{staticClass:"iconfont icon-shoucangxuanzhong card-icon"}),_c('span',[_vm._v(_vm._s(_vm.itemData.like_count))])]),_c('div',{staticClass:"card-icon-box",on:{"click":function($event){$event.stopPropagation();return _vm.shareGoods.apply(null, arguments)}}},[_c('i',{staticClass:"iconfont icon-fenxiang card-icon"}),_c('span',[_vm._v(_vm._s(_vm.itemData.share_count))])])]):_vm._e()]),(
        _vm.isToChooseType === 1 &&
        _vm.itemData.relatedModel &&
        _vm.itemData.relatedModel.length > 0
      )?_c('div',{staticClass:"iconfont icon-Partition share-icon",on:{"click":function($event){$event.stopPropagation();return _vm.toShowGoodsTree.apply(null, arguments)}}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="single-message" @click="handleClickMsg()" v-if="!revoke">
      <a-dropdown :trigger="['contextmenu']">
        <div class="flex" v-if="isSelf == 0">
          <div class="avatar-wrap mr-8">
            <div class="avatar">
              <el-image :src="avatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>
          <div>
            <span class="nick mb-4">{{ nick || "" }}</span>
            <div class="msg chat-left msg-left" v-if="mMessage.type == 'text'">
              {{ mMessage.detail.content }}
            </div>
            <div class="msg chat-left msg-left" v-if="mMessage.type == 'media'">
              <el-image
                :src="mMessage.detail.url"
                :preview-src-list="[mMessage.detail.url]"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div
              class="msg chat-left msg-file"
              v-if="mMessage.type == 'shareGood'"
            >
              <img
                :src="
                  mMessage.detail.url
                    ? require('@/assets/image/folder.png')
                    : mMessage.detail.url
                "
                alt=""
              />
              <br />
              <span>{{ mMessage.detail.name }}</span>
            </div>
          </div>
        </div>
        <div v-else class="flex just-end w-100">
          <div class="text-align-right">
            <span class="nick mb-4">{{ nick || "" }}</span>
            <div
              class="msg msg-right chat-right"
              v-if="mMessage.type == 'text'"
            >
              {{ mMessage.detail.content }}
            </div>
            <div
              class="msg msg-right chat-right"
              v-if="mMessage.type == 'media'"
            >
              <div
                class="loading"
                v-loading="!mMessage.finish"
                v-if="!mMessage.finish"
              ></div>
              <el-image
                v-else
                :src="mMessage.detail.url"
                :preview-src-list="[mMessage.detail.url]"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div
              class="msg msg-right chat-right msg-file"
              v-if="mMessage.type == 'shareGood'"
            >
              <img
                :src="
                  mMessage.detail.url
                    ? require('@/assets/image/folder.png')
                    : mMessage.detail.url
                "
                alt=""
              />
              <br />
              <span>{{ mMessage.detail.name }}</span>
            </div>
          </div>
          <div class="avatar-wrap mr-8">
            <img class="avatar" :src="avatar" alt="avatar" />
          </div>
        </div>
        <a-menu slot="overlay" @click="handleClickMenu">
          <a-menu-item key="1"> 撤回消息 </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <div class="revoke" v-else>{{ nick }}撤回了消息</div>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: Object },
    revoke: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: String,
      default: "./avatar2.jpg",
    },
    isSelf: {
      type: Number,
      default: 1,
    },
    nick: {
      type: String,
      default: "老王",
    },
  },
  data() {
    return {
      mMessage: {
        detail: {
          id: 0,
          content: "",
          url: "",
        },
        finish: 0,
        type: "text",
      },
    };
  },
  watch: {
    message: {
      deep: true,
      handler(val) {
        this.mMessage = this.transfrom(val);
      },
    },
  },
  created() {
    this.mMessage = this.transfrom(this.message);
  },
  methods: {
    transfrom(message) {
      const msgType = message.meeting_chat_type;
      const sub = message.meeting_chat_sub;
      const finish = message.finish;
      let detail = {
        url: "",
        content: "",
      };
      let type = "text";
      switch (String(msgType)) {
        case "0":
          type = "text";
          detail.content = message.msg.content || "";
          break;
        case "4":
          break;
        case "5":
          // [{"name":"u=1683995662,3686817099&fm=26&fmt=auto.webp","type":"image/webp","size":12332,"id":1655360640108,"file":{"uid":1655360640108}}]
          type = "shareGood";
          detail = JSON.parse(sub);
          break;
        case "6":
          type = "media";
          detail = JSON.parse(sub);
          break;
      }
      return {
        detail,
        type,
        finish,
      };
    },
    handleClickMenu(menu) {
      if (menu.key == "1") {
        this.$emit("revoke");
      }
    },
    handleClickMsg() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="less">
.single-message {
  padding: 8px 8px;
  ::selection {
    background-color: blue;
  }
  .avatar-wrap {
    padding: 2px;
    border: 1px solid #aaa;
    width: 33px;
    height: 33px;
    // margin-top: 10px;
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .nick {
    color: #b2b2b2;
    font-size: 12px;
    margin-right: 9px;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .msg {
    white-space: normal;
    word-wrap: break-word;
    color: #050505;
    max-width: 280px;
    background: #fff;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #f3f3f3;
    padding: 6px 6px;
    text-align: justify;
    .loading {
      height: 100px;
      width: 100px;
    }
    img {
      max-width: 100px;
    }
    span {
      text-align: center;
    }
  }
  .msg-right {
    background: #21c3a9;
    color: #fff;
    margin-right: 10px;
    margin-top: 4px;
  }

  .msg-file {
    text-align: center;
    cursor: pointer;
  }
  .msg-left {
    margin-right: 10px;
    margin-top: 4px;
  }
  .chat-left::before {
    content: "";
    border: 6px solid transparent;
    border-right: 6px #fff solid;
    display: inline-block;
    position: absolute;
    top: 6px;
    left: -11px;
  }
  .chat-right::before {
    content: "";
    border: 6px solid transparent;
    border-left: 6px var(--primaryColor) solid;
    display: inline-block;
    position: absolute;
    top: 6px;
    right: -11px;
  }
}
.revoke {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: #686868;
}
</style>

import { render, staticRenderFns } from "./formCard.vue?vue&type=template&id=4cd0884d"
import script from "./formCard.vue?vue&type=script&lang=js"
export * from "./formCard.vue?vue&type=script&lang=js"
import style0 from "./formCard.vue?vue&type=style&index=0&id=4cd0884d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
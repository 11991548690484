<template>
  <div class="design-item-box" @click="toDetail">
    <div
      class="design-item hover-animate"
      :class="[isMouseEnter || isChoose ? 'show-border-hover' : '']"
    >
      <div
        class="design-image-box hover-animate"
        :class="[
          itemData.type == 2 ? 'three-d-image-box' : '',
          isMouseEnter || isChoose ? 'show-border-bottom' : '',
        ]"
      >
        <el-image
          v-if="itemData.type == 3 && itemData.file_info.gif_file"
          class="design-image"
          :src="itemData.file_info.gif_file + '?imageMogr2/thumbnail/!20p'"
          fit="contain"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-image
          v-else
          class="design-image"
          :src="
            itemData.file_info.main_file.img_url +
            `${
              itemData.type === 3
                ? '?imageMogr2/thumbnail/!10p'
                : '?imageMogr2/thumbnail/!20p'
            }`
          "
          fit="contain"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <span class="design-type-text">{{
          itemData.type == 1
            ? $t("design")
            : itemData.type == 2
            ? $t("threeD")
            : $t("goods")
        }}</span>
      </div>
      <div class="design-msg-box">
        <div class="design-name cloumn-1">
          {{ itemData.name }}
        </div>
        <div class="design-detail cloumn-1">
          {{ itemData.intro }}
        </div>
        <div class="design-price">${{ itemData.price }}</div>
        <div class="design-card-bottom" v-if="isToChooseType === 1">
          <div class="card-icon-box">
            <i class="iconfont icon-yanjing card-icon"></i>
            <span>{{itemData.view_count}}</span>
          </div>
          <div class="card-icon-box" @click.stop="changeCollect">
            <i
              class="iconfont icon-shoucang card-icon"
              v-if="is_like === 0"
            ></i>
            <i class="iconfont icon-shoucangxuanzhong card-icon" v-else></i>
            <span>{{itemData.like_count}}</span>
          </div>
          <div class="card-icon-box" @click.stop="shareGoods">
            <i class="iconfont icon-fenxiang card-icon"></i>
            <span>{{itemData.share_count}}</span>
          </div>
        </div>
        <!--        <div v-if="isToChooseType === 1">-->
        <!--          <div-->
        <!--            class="design-btn-box hover-animate"-->
        <!--            :class="[isMouseEnter ? 'show-btn' : '']"-->
        <!--          >-->
        <!--            Quick shop-->
        <!--          </div>-->
        <!--          <div-->
        <!--            class="design-like-box hover-animate"-->
        <!--            @click.stop="changeCollect"-->
        <!--            :class="[isMouseEnter ? 'show-border-top' : '']"-->
        <!--          >-->
        <!--            <i-->
        <!--              class="iconfont icon-shoucang design-like"-->
        <!--              v-if="is_like == 0"-->
        <!--            ></i>-->
        <!--            <i-->
        <!--              class="iconfont icon-shoucangxuanzhong design-is-like"-->
        <!--              v-else-->
        <!--            ></i>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div
        v-if="
          isToChooseType === 1 &&
          itemData.relatedModel &&
          itemData.relatedModel.length > 0
        "
        class="iconfont icon-Partition share-icon"
        @click.stop="toShowGoodsTree"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "designView",
  props: {
    itemData: {
      type: Object,
    },
    detailType: {
      type: Number,
      default: 1,
    },
    isToChooseType: {
      type: Number,
      default: 1,
    },
    isChoose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMouseEnter: false,
      is_like: 0,
      timer: "",
    };
  },
  created() {
    this.is_like = this.itemData.is_like_count;
  },
  methods: {
    // 防抖函数
    debounce(func, wait) {
      let that = this;
      return function () {
        let context = this,
          args = arguments;
        if (that.timer) clearTimeout(that.timer);
        that.timer = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    //显示树状弹窗
    toShowGoodsTree() {
      this.$emit("toShowGoodsTree", { data: this.itemData });
    },
    //分享商品
    shareGoods() {
      this.$store.commit("callIM", {
        id: this.itemData.id,
        name: this.itemData.name,
        url: this.itemData.file_info.main_file.img_url,
        type: this.itemData.type,
      });
    },
    //显示按钮
    showBtn() {
      if (this.isToChooseType != 1) return false;
      this.isMouseEnter = true;
    },
    //隐藏按钮
    hideBtn() {
      this.isMouseEnter = false;
    },
    changeCollect() {
      let that = this;

      if (that.is_like == 1) {
        that.delProject();
      } else {
        that.addProject();
      }
      // this.debounce(function () {
      //
      // },300)()
    },
    //添加心愿单
    addProject() {
      let loading = this.openLoading();
      let data = {
        product_id: this.itemData.id,
        type: 1,
      };
      this.$api.addBaseProject(data).then((res) => {
        this.is_like = 1;
        this.itemData.like_count++
        loading.close();
      });
    },
    //移除心愿单
    delProject() {
      let loading = this.openLoading();
      let data = {
        product_id: this.itemData.id,
        type: 1,
      };
      this.$api.delBaseProject(data).then((res) => {
        this.is_like = 0;
        this.itemData.like_count--
        loading.close();
      });
    },
    //跳转
    toDetail() {
      if (this.isToChooseType === 1) {
        if (this.detailType === 1) {
          this.$router.push({
            name: "productDetail",
            query: {
              id: this.itemData.id,
              type: this.itemData.type,
            },
          });
        } else {
          this.$emit("changeDetail", {
            id: this.itemData.id,
            type: this.itemData.type,
          });
        }
      } else {
        this.$emit("chooseProduct", {
          item: this.itemData,
          check: this.isChoose,
        });
      }
    },
  },
};
</script>

<style lang="scss">
$item-border-color: 1px solid #e2dede;
.design-item-box {
  .el-card__body {
    padding: 0;
  }
  box-sizing: content-box;
  .hover-animate {
    transition: all 0.3s ease-out;
  }
  .design-item {
    width: 234px;
    height: 290px;
    border: $item-border-color;
    //border: 1px solid #fff;
    padding: 0 !important;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: #fff;

    .share-icon {
      position: absolute;
      right: 12px;
      top: 12px;
    }
    .three-d-image-box {
      /*padding:40px 40px 10px 40px!important;*/
      padding: 10px 4px;
    }
    .design-image-box {
      width: 234px;
      height: 184px;
      border-bottom: $item-border-color;
      /*padding:60px 40px 30px 40px;*/
      padding:30px 4px 10px 4px;
      opacity: 1;
      .design-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-icon-picture-outline {
          font-size: 32px;
          color: #999;
        }
      }
      .design-type-text {
        position: absolute;
        left: 12px;
        top: 12px;
        font-size: 12px;
        color: #333;
        font-weight: 500;
      }
    }
    .cloumn-1 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .show-btn {
      opacity: 1 !important;
    }

    .hide-btn {
      opacity: 0;
    }
    .hide-img {
      animation: hide 1s;
    }
    @keyframes hide {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    .design-msg-box {
      position: relative;
      padding:10px 20px;
      font-size: 12px;
      height: 104px;
      margin-right: 30px;
      .design-name {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 6px;
        width: 200px;
      }
      .design-detail {
        color: #4d4d4d;
        font-size: 10px;
        line-height: 20px;
        margin-bottom: 6px;
        width: 200px;
        font-weight: 500;
        text-align: left;
      }
      .design-price {
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }
      .design-card-bottom {
        position: absolute;
        bottom: 14px;
        left: 20px;
        display: flex;
        align-items: center;
        margin-top: 12px;
        .card-icon-box {
          display: flex;
          align-items: center;
          margin-right: 16px;
          color: #8e8e8e;
          .card-icon {
            font-size: 13px;
            margin-right: 6px;
          }
          .card-icon-text {
            font-size: 12px;
          }
          .icon-shoucangxuanzhong{
            color: #f6ee76;
          }
        }
      }
      .design-btn-box {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 202px;
        height: 32px;
        background-color: $base-config-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        opacity: 0;
      }
      .design-like-box {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 1px;
        border-top: 1px solid #fff;

        /*border-left:1px solid #fff;*/
        z-index: 1;
        background-color: #fff;
        cursor: pointer;
        .design-like {
          font-size: 18px;
        }
        .design-is-like {
          font-size: 18px;
          color: $base-star-color;
        }
      }
    }
  }

  .show-border-hover {
    transform: scale(1.02, 1.02);
    border: 1px solid $base-config-color !important;
    box-shadow: 0 0 4px 4px #f2f2f2;
  }

  .show-border-bottom {
    border-bottom: 1px solid $base-config-color !important;
  }
  .show-border-top {
    border-top: 1px solid $base-config-color !important;
  }
}
</style>

export default ({ service, request }) => ({
  getSeaCustomerList(page) {
    let data = usePage(page);
    return request({
      url: "/v1/customer/getSeaCustomerList",
      method: "post",
      data,
    });
  },
  getCustomerListTransform(page) {
    let data = usePage(page);
    return request({
      url: "/v1/customer/getCustomerList",
      method: "post",
      data,
    });
  },
  getCustomerList(page) {
    let data = usePage(page);
    return request({
      url: "/v1/customer/getCustomerList",
      method: "post",
      data,
    });
  },
  getOwnerCustomerList(page) {
    let data = usePage(page);
    return request({
      url: "/v1/customer/getOwnerCustomerList",
      method: "post",
      data,
    });
  },
  createCustomer(customer) {
    return request({
      url: "/v1/customer/createCustomer",
      method: "post",
      data: customer,
    });
  },
  updateCustomer(customer) {
    return request({
      url: "/v1/customer/updateCustomer",
      method: "post",
      data: customer,
    });
  },
  getSeaCustomerToOwner(customer) {
    let { id } = customer;
    return request({
      url: "/v1/customer/getSeaCustomerToOwner",
      method: "post",
      data: { id },
    });
  },
  getCustomerSelectList(data) {
    let { name } = data;
    return request({
      url: "/v1/customer/getCustomerSelectList",
      method: "post",
      data: { name },
    });
  },
  setSeaCustomerToService({ customer_id, service_id }) {
    return request({
      url: "/v1/customer/setSeaCustomerToService",
      method: "post",
      data: { customer_id, service_id },
    });
  },
  putServiceCustomerToSea({ id }) {
    return request({
      url: "/v1/customer/putServiceCustomerToSea",
      method: "post",
      data: { customer_id: id },
    });
  },
});

const usePage = (pageInfo) => {
  let { pageSize, page, current } = pageInfo || {
    pageSize: 9,
    page: 1,
    current: 1,
  };
  page = current || page;
  return {
    page,
    page_size: pageSize,
  };
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"wrapClassName":_vm.wrapClassName,"title":_vm.title,"width":_vm.width,"visible":_vm.visible,"destroyOnClose":true,"mask":_vm.mask,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.handleClose}},[_c('a-form',{attrs:{"layout":_vm.formLayout}},[_c('a-form-item',{attrs:{"label":_vm.$t('labelTextureName')}},[_c('a-input',{model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('labelTextureType')}},[_c('a-select',{on:{"change":_vm.handleSelectChange},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.logoFolders),function(opt,idx){return _c('a-select-option',{key:idx,attrs:{"value":opt.id}},[_vm._v(_vm._s(opt.name)+" ")])}),1)],1),_c('a-form-item',{staticClass:"image",attrs:{"label":_vm.$t('texture')}},[_c('upload',{attrs:{"fileCallBack":_vm.logoFileCallBack}})],1),_c('a-form-item',{staticClass:"image",attrs:{"label":_vm.$t('normal')}},[_c('upload',{attrs:{"fileCallBack":_vm.normalFileCallBack}})],1)],1),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.handleClose}},[_vm._v(" Cancel ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v("Save")]),_c('a-button',{attrs:{"type":"danger"},on:{"click":_vm.handleEdit}},[_vm._v("Save")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="create-product-dialog">
    <el-dialog
      :title="$t('addGoods')"
      :visible.sync="dialogCreateVisible"
      :before-close="closeModal"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="1200px"
    >
      <el-form
        class="enter-dialog"
        label-width="120px"
        ref="form"
        :model="form"
        :inline="true"
        :rules="rules"
      >
        <div class="dialog-item-ul">
          <el-form-item :label="$t('goodsName')" class="form-item" prop="name">
            <el-input
              style="width: 250px"
              v-model="form.name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('goodsType')" class="form-item" prop="type">
            <el-select
              style="width: 250px"
              v-model="form.type"
              size="small"
              :disabled="createType == 2"
            >
              <el-option
                v-for="(item, index) in createTypeList"
                :key="index"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="dialog-item-ul">
          <el-form-item
            :label="$t('goodsPrice')"
            class="form-item"
            prop="price"
          >
            <el-input
              :placeholder="$t('goodsUnit')"
              style="width: 250px"
              v-model="form.price"
              size="small"
              @keyup.native="form.price = form.price.replace(/[^0-9_\.]/g, '')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('bomStyle')"
            class="form-item"
            prop="style_number"
          >
            <el-input
              style="width: 250px"
              v-model="form.style_number"
              size="small"
            ></el-input>
          </el-form-item>
        </div>
        <div class="dialog-item-ul">
          <el-form-item
            :label="$t('bomModel')"
            class="form-item"
            prop="model_name"
          >
            <el-input
              style="width: 250px"
              v-model="form.model_name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('isPublic')" class="form-item" prop="type">
            <el-select
              style="width: 250px"
              v-model="form.is_public"
              size="small"
            >
              <el-option :label="$t('public')" :value="1"> </el-option>
              <el-option :label="$t('privacy')" :value="0"> </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="dialog-item-ul">
          <el-form-item :label="$t('bomSize')" class="form-item" prop="size">
            <el-input
              style="width: 250px"
              v-model="form.size"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('bomColor')" class="form-item" prop="color">
            <el-input
              style="width: 250px"
              v-model="form.color"
              size="small"
            ></el-input>
          </el-form-item>
        </div>
        <div class="dialog-item-ul">
          <el-form-item
            :label="$t('goodsDesc')"
            class="form-item"
            prop="desc"
            style="display: flex; flex-wrap: wrap; width: 100%"
          >
            <div class="desc-item" v-for="(val, key) in form.desc" :key="key">
              <el-input
                :ref="`desc${key}`"
                class="form-desc"
                v-model="form.desc[key]"
                size="small"
                @keyup.enter.native="addInput(key)"
              ></el-input>
              <i
                class="iconfont icon-jia add-input-icon"
                @click="addInput(key)"
                v-if="key == form.desc.length - 1 && form.desc.length < 8"
              ></i>
              <i
                class="iconfont icon-jian add-input-icon"
                @click="removeInput(key)"
                v-if="key != form.desc.length - 1 && form.desc.length > 1"
              ></i>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('goodsInform')"
            class="form-item"
            style="flex-shrink: 0"
            prop="intro"
          >
            <div style="min-width: 250px">
              <el-input
                type="textarea"
                :rows="2"
                v-model="form.intro"
              ></el-input>
            </div>
          </el-form-item>
        </div>
        <div class="dialog-item-ul">
          <el-form-item
            :label="$t('goodsMainImage')"
            style="padding-left: 10px; box-sizing: border-box"
            v-if="form.type != 2 || createType == 2"
          >
            <div style="width: 250px">
              <el-upload
                class="avatar-uploader"
                action=""
                accept=".jpg,.jpeg,.png,.JPG,.PNG"
                multiple
                :before-upload="beforeUpload"
                :show-file-list="false"
                :http-request="picUpload"
              >
                <div class="avatar-box" v-if="imageUrl">
                  <div>
                    <img :src="imageUrl" class="avatar" />
                  </div>
                </div>

                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item
            label="GIF"
            style="padding-left: 10px; box-sizing: border-box"
            v-if="form.type == 3"
          >
            <div style="width: 250px">
              <el-upload
                class="avatar-uploader"
                action=""
                accept=".gif,.GIF"
                multiple
                :before-upload="beforeUpload"
                :show-file-list="false"
                :http-request="picGifUpload"
              >
                <div class="avatar-box" v-if="gifUrl">
                  <div>
                    <img :src="gifUrl" class="avatar" />
                  </div>
                </div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
        </div>
        <div class="dialog-item-ul" v-if="form.type == 2">
          <el-form-item
            :label="$t('threeD')"
            style="padding-left: 10px; box-sizing: border-box"
          >
            <div>
              <el-upload
                style="display: flex"
                action=""
                accept=".gltf"
                multiple
                :on-change="handleChange"
                ref="upload"
                :file-list="fileList"
                :http-request="uploadThree3d"
              >
                <el-button size="small">{{ $t("clickUpload") }}</el-button>
              </el-upload>
            </div>
          </el-form-item>
          <div></div>
        </div>
        <div class="dialog-item-ul" v-if="form.type != 2">
          <el-form-item
            :label="$t('goodsOtherImage')"
            style="padding-left: 10px; display: flex"
          >
            <div style="min-width: 250px">
              <el-upload
                list-type="picture-card"
                :file-list="img_list"
                action=""
                accept=".jpg,.jpeg,.png,.JPG,.PNG"
                multiple
                :on-remove="handleRemove"
                :before-upload="beforeUpload"
                :http-request="picUploadList"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <div></div>
        </div>

        <el-form-item class="dialog-btn enter-btn-box">
          <el-button size="big" @click="closeModal">
            {{ $t("bomCancel") }}
          </el-button>
          <el-button
            class="btn"
            size="big"
            type="primary"
            @click="toCreate('form')"
          >
            {{ $t("bomSubmit") }}
          </el-button>
          <el-button
            v-if="createType == 2"
            class="btn"
            size="big"
            type="danger"
            @click="toCreate2('form')"
          >
            {{ $t("addBtn") }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div id="modelWork"></div>
  </div>
</template>

<script>
import uploadCos from "@/utils/upload_to_cos";
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
// import "@babylonjs/loaders/OBJ";
// import "@babylonjs/loaders/STL";
import "@babylonjs/loaders/glTF";
// import "@babylonjs/materials/legacy/legacy";
import { MeshWriter } from "meshwriter";
import { ThreeDScene } from "@/utils/babylonjs/methods";
import { modelConfig } from "@/utils/babylonjs/meshData";
export default {
  name: "productDialog",
  props: {
    createType: {
      type: Number,
      default: 1,
    },
    itemData: {
      type: Object,
    },
    createForm: {
      type: Object,
    },
    dialogCreateVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imageUrl: "",
      img_file: "",
      three_d_file: "",
      gif_file: "",
      gifUrl: "",
      fileList: [],
      img_file_list: [],
      img_list: [],
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: this.$t("goodsNamePlaceholder"),
            trigger: "blur",
          },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        style_number: [
          {
            message: this.$t("bomStylePlaceholder"),
            trigger: "blur",
          },
        ],
        model_name: [
          {
            message: this.$t("bomModelPlaceholder"),
            trigger: "blur",
          },
        ],
        desc: [
          {
            required: true,
            message: this.$t("pleaseGoodsDesc"),
            trigger: "blur",
          },
        ],
        color: [
          {
            required: true,
            message: this.$t("bomColorPlaceholder"),
            trigger: "blur",
          },
        ],
        intro: [
          {
            required: true,
            message: this.$t("goodsInformPlaceholder"),
            trigger: "blur",
          },
        ],
        size: [
          {
            required: true,
          },
        ],
        // shoe_last_name:[{
        // 	required:true
        // }]
      },
      dialogImageUrl: "",
      dialogVisible: false,
      createTypeList: [
        {
          type: 1,
          name: this.$t("design"),
        },
        {
          type: 2,
          name: this.$t("threeD"),
        },
        {
          type: 3,
          name: this.$t("goods"),
        },
      ],
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.createForm));
    if (this.createType == 2) {
      if (this.itemData.file_info.remark_file) {
        this.img_list = this.itemData.file_info.remark_file.map((item) => {
          return {
            url: item,
          };
        });
      }
      this.imageUrl = this.itemData.file_info.main_file.img_url;
      if (this.itemData.type == 3) {
        this.gifUrl = _.get(this.itemData, "file_info.gif_file.img_url", "");
      }
      if (this.itemData.type == 2) {
        let url = {
          name: this.itemData.name,
          url: this.itemData.file_info.main_file.file,
          img_url: this.itemData.file_info.main_file.img_url,
        };
        this.fileList = [url];
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    //添加输入框
    addInput(key) {
      let that = this;
      if (this.form.desc.length == 8) {
        // this.$message.error(this.$t("mostDesc"))
        return;
      }
      let str = "";
      this.form.desc.push(str);
      setTimeout(() => {
        this.$refs["desc" + (key + 1)][0].focus();
      }, 100);
    },
    //移除输入框
    removeInput(key) {
      this.form.desc.splice(key, 1);
    },
    //提交
    toCreate(formName) {
      let form = JSON.parse(JSON.stringify(this.form));
      if (this.img_file == "" && this.imageUrl == "" && this.form.type != 2) {
        this.$message.error(this.$t("pleaseMainImage"));
        return;
      }
      if (
        this.three_d_file == "" &&
        this.fileList.length == 0 &&
        this.form.type == 2
      ) {
        this.$message.error(this.$t("pleaseThreeD"));
        return;
      }
      form.desc = form.desc.filter((val) => Boolean(val));
      if (form.desc.length == 0) {
        this.$message.error(this.$t("pleaseGoodsDesc"));
        return;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let that = this;
          let loading = this.openLoading();
          let file_info = {};
          if (this.form.type == 2) {
            if (this.three_d_file) {
              let files = [this.three_d_file];
              let url = await this.uploadImg(files);
              let img_url = null;
              modelConfig.boxId = "modelWork";
              let b = new ThreeDScene(modelConfig);
              await b.loadModel(url[0]).then(async () => {
                setTimeout(function () {
                  b.screenShot().then(async (imgFiles) => {
                    img_url = await that.uploadImg([imgFiles]);

                    file_info = {
                      main_file: {
                        img_url: img_url[0],
                        file: url[0],
                      },
                    };
                    if (this.img_file) {
                      let three_d_img_files = [this.img_file];
                      let img = await this.uploadImg(three_d_img_files);
                      file_info = {
                        main_file: {
                          img_url: img[0],
                          file: url[0],
                        },
                      };
                    }
                    form.file_info = file_info;
                    if (that.createType == 2) {
                      that.$api.editProduct(form).then((res) => {
                        that.$emit("editProduct");
                        loading.close();
                      });
                    } else {
                      that.$api.addProduct(form).then((res) => {
                        that.$emit("createProcut");
                        loading.close();
                      });
                    }
                  });
                }, 10);
              });
            } else {
              if (that.createType == 2) {
                if (this.img_file) {
                  let files = [this.img_file];
                  let url = await this.uploadImg(files);
                  file_info = {
                    main_file: {
                      img_url: url[0],
                      file: that.fileList[0].url,
                    },
                  };
                } else {
                  file_info = {
                    main_file: {
                      img_url: that.fileList[0].img_url,
                      file: that.fileList[0].url,
                    },
                  };
                }

                form.file_info = file_info;
                that.$api.editProduct(form).then((res) => {
                  that.$emit("editProduct");
                  loading.close();
                });
              }
            }
          } else {
            let list = [];
            let fileList = [];
            if (this.img_file) {
              let files = [this.img_file];
              let url = await this.uploadImg(files);
              file_info = {
                main_file: {
                  img_url: url[0],
                },
              };
            } else {
              file_info = {
                main_file: {
                  img_url: this.imageUrl,
                },
              };
            }
            if (this.form.type == 3) {
              if (this.gif_file) {
                let gif_files = [this.gif_file];
                let gif_url = await this.uploadImg(gif_files);
                file_info = { ...file_info, ...{ gif_file: gif_url[0] } };
              } else {
                if (this.gifUrl) {
                  file_info = { ...file_info, ...{ gif_file: this.gifUrl } };
                }
              }
            }

            if (this.img_list.length > 0) {
              list = this.img_list.map((val) => val.url);
            }
            if (this.img_file_list.length > 0) {
              let readyList = this.img_file_list.filter(
                (val) => val.status == "ready"
              );
              let checkList = this.img_file_list.filter((val) => !val.status);
              let checkFile = [];
              if (readyList.length > 0) {
                fileList = await this.uploadImg(readyList);
              }
              if (checkList.length > 0) {
                checkFile = await this.uploadImg(checkList);
              }
              fileList = [...fileList, ...checkFile];
            }
            file_info = {
              ...file_info,
              ...{ remark_file: [...list, ...fileList] },
            };
            form.file_info = file_info;
            if (this.createType == 2) {
              this.$api.editProduct(form).then((res) => {
                this.$emit("editProduct");
                loading.close();
              });
            } else {
              this.$api.addProduct(form).then((res) => {
                this.$emit("createProcut");
                loading.close();
              });
            }
          }
        }
      });
    },
    toCreate2(formName) {
      let form = JSON.parse(JSON.stringify(this.form));
      if (this.img_file == "" && this.imageUrl == "" && this.form.type != 2) {
        this.$message.error(this.$t("pleaseMainImage"));
        return;
      }
      if (
        this.three_d_file == "" &&
        this.fileList.length == 0 &&
        this.form.type == 2
      ) {
        this.$message.error(this.$t("pleaseThreeD"));
        return;
      }
      form.desc = form.desc.filter((val) => Boolean(val));
      if (form.desc.length == 0) {
        this.$message.error(this.$t("pleaseGoodsDesc"));
        return;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let that = this;
          let loading = this.openLoading();
          let file_info = {};
          if (this.form.type == 2) {
            if (this.three_d_file) {
              let files = [this.three_d_file];
              let url = await this.uploadImg(files);
              let img_url = null;
              modelConfig.boxId = "modelWork";
              let b = new ThreeDScene(modelConfig);
              await b.loadModel(url[0]).then(async () => {
                setTimeout(function () {
                  b.screenShot().then(async (imgFiles) => {
                    img_url = await that.uploadImg([imgFiles]);

                    file_info = {
                      main_file: {
                        img_url: img_url[0],
                        file: url[0],
                      },
                    };
                    if (this.img_file) {
                      let three_d_img_files = [this.img_file];
                      let img = await this.uploadImg(three_d_img_files);
                      file_info = {
                        main_file: {
                          img_url: img[0],
                          file: url[0],
                        },
                      };
                    }
                    form.file_info = file_info;
                    that.$api.addProduct(form).then((res) => {
                      that.$emit("createProcut");
                      loading.close();
                    });
                  });
                }, 10);
              });
            } else {
              if (that.createType == 2) {
                if (this.img_file) {
                  let files = [this.img_file];
                  let url = await this.uploadImg(files);
                  file_info = {
                    main_file: {
                      img_url: url[0],
                      file: that.fileList[0].url,
                    },
                  };
                } else {
                  file_info = {
                    main_file: {
                      img_url: that.fileList[0].img_url,
                      file: that.fileList[0].url,
                    },
                  };
                }

                form.file_info = file_info;
                that.$api.addProduct(form).then((res) => {
                  that.$emit("editProduct");
                  loading.close();
                });
              }
            }
          } else {
            let list = [];
            let fileList = [];
            if (this.img_file) {
              let files = [this.img_file];
              let url = await this.uploadImg(files);
              file_info = {
                main_file: {
                  img_url: url[0],
                },
              };
            } else {
              file_info = {
                main_file: {
                  img_url: this.imageUrl,
                },
              };
            }
            if (this.form.type == 3) {
              if (this.gif_file) {
                let gif_files = [this.gif_file];
                let gif_url = await this.uploadImg(gif_files);
                file_info = { ...file_info, ...{ gif_file: gif_url[0] } };
              } else {
                if (this.gifUrl) {
                  file_info = { ...file_info, ...{ gif_file: this.gifUrl } };
                }
              }
            }

            if (this.img_list.length > 0) {
              list = this.img_list.map((val) => val.url);
            }
            if (this.img_file_list.length > 0) {
              let readyList = this.img_file_list.filter(
                (val) => val.status == "ready"
              );
              let checkList = this.img_file_list.filter((val) => !val.status);
              let checkFile = [];
              if (readyList.length > 0) {
                fileList = await this.uploadImg(readyList);
              }
              if (checkList.length > 0) {
                checkFile = await this.uploadImg(checkList);
              }
              fileList = [...fileList, ...checkFile];
            }
            file_info = {
              ...file_info,
              ...{ remark_file: [...list, ...fileList] },
            };
            form.file_info = file_info;
            this.$api.addProduct(form).then((res) => {
              this.$emit("createProcut");
              loading.close();
            });
          }
        }
      });
    },
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    //上传图片限制
    beforeUpload(file) {
      let that = this;
      let limit_file = 4;
      const limit = file.size / 1024 / 1024 < limit_file;
      if (!limit) {
        this.$message({
          message: `${that.$t("uploadWarning")} ${limit_file}MB`,
          type: "warning",
        });
      }
      return limit;
    },
    // 选择图片
    picUpload(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.imageUrl = e.target.result; // 将图片路径赋值给src
      };
      reader.readAsDataURL(file.file);
      this.img_file = file.file;
    },
    //上传gif
    picGifUpload(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.gifUrl = e.target.result; // 将图片路径赋值给src
      };
      reader.readAsDataURL(file.file);
      this.gif_file = file.file;
    },
    // 选择图片
    picUploadList(file, fileList) {
      console.log(file, fileList);
      this.img_file_list.push(file.file);
    },
    handleRemove(file, fileList) {
      if (this.createType == 2) {
        if (file.status == "success") {
          this.img_list.some((val, key) => {
            if (val.url == file.url) {
              this.img_list.splice(key, 1);
              return true;
            }
          });
        }
      }
      this.img_file_list = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传3D
    uploadThree3d(file) {
      this.three_d_file = file.file;
    },

    //上传图片
    uploadImg(pictureList) {
      return new Promise((resolve) => {
        uploadCos(pictureList, "works", (picture) => {
          resolve(picture);
        });
      });
    },
  },
};
</script>

<style lang="scss">
$image-size: 100px;

.create-product-dialog {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: $base-config-color;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: $image-size;
    height: $image-size;
    line-height: $image-size;
    text-align: center;
  }
  .avatar-box {
    line-height: $image-size;
    width: $image-size;
    height: $image-size;
  }
  .avatar {
    width: $image-size;
    height: auto;
  }
  .form-desc {
    width: 250px;
    margin-right: 10px;
  }
  .el-upload-list__item.is-success .el-upload-list__item-status-label {
    display: none;
  }
  .el-form-item__label {
    flex-shrink: 0;
  }
  .el-upload--picture-card {
    width: $image-size;
    height: $image-size;
    line-height: $image-size;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: $image-size;
    height: $image-size;
    line-height: $image-size;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    height: auto;
  }

  .upload-item {
    box-sizing: content-box;
    text-align: center;
  }
  .enter-dialog {
    box-sizing: border-box;
    .form-item {
      padding-left: 10px;
    }
    .dialog-text {
      margin: 10px 0;
      font-size: 15px;
      display: flex;
      align-items: center;
      .text-list {
        margin: 10px 0;
        width: 40%;
        text-align: right;
      }
    }
    .enter-btn-box {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 0 24px;
      box-sizing: border-box;
      margin: 20px auto 0 auto;
    }
    .dialog-item-ul {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .desc-item {
      display: flex;
      align-items: center;
      .add-input-icon {
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
}
</style>

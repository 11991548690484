<template>
  <div class="w-100 flex flex-1 just-center mb-4">
    <span class="font-size-10 text-gray">{{ format }}</span>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    current: {
      default: Date.now().toString(),
      type: [String, Number],
    },
  },
  computed: {
    format() {
      if (String(this.current).length == 10) {
        return dayjs.unix(parseInt(this.current)).format("YYYY-MM-DD HH:mm:ss");
      }
      return dayjs(parseInt(this.current)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style></style>

<template>
  <a-drawer
    :wrapClassName="wrapClassName"
    :title="title"
    :width="width"
    :visible="visible"
    :destroyOnClose="true"
    :mask="mask"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleClose"
  >
    <a-form :layout="formLayout">
      <a-form-item :label="$t('labelTextureName')">
        <a-input v-model="name"></a-input>
      </a-form-item>
      <a-form-item :label="$t('labelTextureType')">
        <a-select v-model="selected" @change="handleSelectChange">
          <a-select-option
            :value="opt.id"
            v-for="(opt, idx) in logoFolders"
            :key="idx"
            >{{ opt.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="image" :label="$t('texture')">
        <upload :fileCallBack="logoFileCallBack"></upload>
      </a-form-item>
      <a-form-item class="image" :label="$t('normal')">
        <upload :fileCallBack="normalFileCallBack"></upload>
      </a-form-item>
    </a-form>
    <!-- <div class="big-preview mask" v-if="preview">
      <img :src="current.src" alt="preview" />
      <i class="iconfont icon-icon-test" @click="handleBigClose"></i>
    </div> -->
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="handleClose">
        Cancel
      </a-button>
      <a-button type="primary" @click="handleOk">Save</a-button>
      <a-button type="danger" @click="handleEdit">Save</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("jiyang/material");
import upload from "@/components/Upload";
export default {
  components: {
    upload,
  },
  props: {
    wrapClassName: {
      type: String,
      default: "drawer",
    },
    visible: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      default: "Logo",
    },
    mask: {
      default: false,
      type: Boolean,
    },
    width: {
      type: Number,
      default: 420,
    },
    value: {
      default: () => {},
      type: Object,
    },
    current: {
      type: Object,
      default: () => {},
    },
    logoInfo: {
      type: Object,
      DEFAULT: () => {},
    },
  },
  data() {
    return {
      formLayout: "horizontal",
      name: "",
      lgoFileUrl: "",
      normalFileUrl: "",
      selected: "",
      logoSelectOption: [],
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState(["logoFolders"]),
  },
  methods: {
    ...mapActions(["saveLogo", "updateLogo"]),
    handleSelectChange(opt) {
      this.type = this.textureSelectOption.find((t) => t.id == opt);
      this.cat_id = opt;
    },
    logoFileCallBack(file) {
      this.logoFileUrl = file[0];
    },
    normalFileCallBack(file) {
      this.normalFileUrl = file[0];
    },
    handleOk() {
      this.saveLogo({
        name: this.name,
        logo: this.logoFileUrl,
        normal: this.normalFileUrl,
        cat_id: this.selected,
      });
      this.$emit("update:visible", false);
      this.init();
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    init() {
      this.name = "";
      this.url = "";
      this.type = "";
    },
    handleEdit() {
      this.name = this.logoInfo ? this.logoInfo.name : "";
      this.logoFileUrl = this.logoInfo ? this.logoInfo.coverpic : "";
      this.normalFileUrl = this.logoInfo ? this.logoInfo.normalFileUrl : "";

      this.updateLogo({
        name: this.name,
        logo: this.logoFileUrl,
        normal: this.normalFileUrl,
        cat_id: this.selected,
        id: this.logoInfo.id,
      });
    },
  },
};
</script>

<style lang="scss">
.textureeditor {
  .preview {
    width: 100px;
    height: 100px;
  }
}
.big-preview {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 0.2 ease;
  > img {
    width: 400px;
    object-fit: contain;
  }
  > .iconfont {
    margin-top: 16px;
    color: #fff;
  }
}
</style>

<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogEnterVisible"
    v-if="dialogEnterVisible"
    :before-close="closeModal"
    :modal-append-to-body="false"
    width="900px"
  >
    <el-form
      class="enter-dialog"
      label-width="110px"
      ref="addForm"
      :model="addForm"
      :inline="true"
      :rules="rules"
    >
      <div class="dialog-item-ul">
        <el-form-item :label="$t('bomNmae')" class="form-item" prop="name">
          <el-input
            style="width: 250px"
            v-model="addForm.name"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('bomDate')"
          class="form-item"
          prop="delivery_date"
        >
          <el-date-picker
            style="width: 250px"
            value-format="yyyy-MM-dd"
            v-model="addForm.delivery_date"
            size="small"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <!--            <div class="dialog-item-ul" v-if="type==0">-->
      <!--                <el-form-item label="图片" style="padding-left: 10px;box-sizing: border-box" >-->
      <!--                    <div style="min-width: 250px" >-->
      <!--                        <el-upload-->
      <!--                            class="upload-item"-->
      <!--                            action=""-->
      <!--                            accept=".jpg,.jpeg,.png,.JPG,.PNG"-->
      <!--                            multiple-->
      <!--                            :before-upload="beforeUpload"-->
      <!--                            :show-file-list="false"-->
      <!--                            :http-request="picUpload"-->
      <!--                        >-->
      <!--                            <img v-if="imageUrl" :src="imageUrl" class="avatar">-->
      <!--                            <div v-else>-->
      <!--                                <div class="iconfont icon-ic_add icon-add"></div>-->
      <!--                                <div class="upload-text">-->
      <!--                                    <div>选择图片(必选)</div>-->
      <!--                                </div>-->
      <!--                            </div>-->
      <!--                        </el-upload>-->
      <!--                    </div>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="实物动图" style="padding-left: 10px;box-sizing: border-box" v-if="createType==2">-->
      <!--                    <div style="min-width: 250px" >-->
      <!--                        <el-upload-->
      <!--                            class="upload-item"-->
      <!--                            action=""-->
      <!--                            accept=".gif"-->
      <!--                            multiple-->
      <!--                            :before-upload="beforeUpload"-->
      <!--                            :show-file-list="false"-->
      <!--                            :http-request="chooseGif"-->
      <!--                        >-->
      <!--                            <img v-if="gifUrl" :src="gifUrl" class="avatar">-->
      <!--                            <div v-else>-->
      <!--                                <div class="iconfont icon-ic_add icon-add"></div>-->
      <!--                                <div class="upload-text">-->
      <!--                                    <div>选择gif图(非必选)</div>-->
      <!--                                </div>-->
      <!--                            </div>-->
      <!--                        </el-upload>-->
      <!--                    </div>-->
      <!--                </el-form-item>-->
      <!--            </div>-->
      <!--            <div class="dialog-item-ul" v-if="type==2">-->
      <!--                <el-form-item label="模型" style="padding-left: 10px;box-sizing: border-box">-->
      <!--                    <div >-->
      <!--                        <el-upload-->
      <!--                            style="display: flex"-->
      <!--                            action=""-->
      <!--                            accept=".gltf"-->
      <!--                            multiple-->
      <!--                            :on-change="handleChange"-->
      <!--                            ref="upload"-->
      <!--                            :file-list="fileList"-->
      <!--                            :http-request="uploadThree3d"-->
      <!--                        >-->
      <!--                            <el-button size="small">点击上传</el-button>-->
      <!--                        </el-upload>-->
      <!--                    </div>-->

      <!--                </el-form-item>-->
      <!--                <div></div>-->
      <!--            </div>-->
      <div class="dialog-item-ul">
        <el-form-item
          :label="$t('bomStyle')"
          class="form-item"
          prop="style_number"
        >
          <el-input
            style="width: 250px"
            v-model="addForm.style_number"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('bomColor')"
          class="form-item"
          prop="style_color"
        >
          <el-input
            style="width: 250px"
            v-model="addForm.style_color"
            size="small"
          ></el-input>
        </el-form-item>
      </div>
      <div class="dialog-item-ul">
        <el-form-item
          :label="$t('bomModel')"
          class="form-item"
          prop="model_name"
        >
          <el-input
            style="width: 250px"
            v-model="addForm.model_name"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('bomHeader')"
          class="form-item"
          prop="shoe_last_name"
        >
          <el-input
            style="width: 250px"
            v-model="addForm.shoe_last_name"
            size="small"
          ></el-input>
        </el-form-item>
      </div>
      <div class="dialog-item-ul">
        <el-form-item :label="$t('bomClient')" class="form-item" prop="client">
          <el-input
            style="width: 250px"
            v-model="addForm.client"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('bomSize')" class="form-item" prop="size">
          <el-input
            style="width: 250px"
            v-model="addForm.size"
            size="small"
          ></el-input>
        </el-form-item>
      </div>
      <div class="dialog-item-ul">
        <el-form-item :label="$t('bomNum')" class="form-item" prop="count">
          <el-input
            style="width: 250px"
            v-model="addForm.count"
            size="small"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item class="dialog-btn enter-btn-box">
        <el-button size="big" @click="closeModal">
          {{ $t("bomCancel") }}
        </el-button>
        <el-button
          class="btn"
          size="big"
          type="primary"
          @click="toCreate('addForm')"
        >
          {{ $t("bomSubmit") }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import uploadCos from "@/utils/upload_to_cos";
import moment from "moment";
export default {
  name: "createDialog",
  props: {
    dialogEnterVisible: {
      type: Boolean,
      default: true,
    },
    addForm: {
      type: Object,
    },
    type: {
      type: Number,
      default: 1,
    },
    parentId: {
      type: Number,
      default: 0,
    },
    create: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    type(val) {
      if (val == 1) {
        this.title = this.$t("editBasic");
      } else if (val == 0) {
        this.title = "新增物料清单";
      } else if (val == 2) {
        this.title = "上传3D模型";
      }
    },
  },

  data() {
    return {
      imageUrl: "",
      img_file: "",
      gif_file: "",
      gifUrl: "",
      three_d_file: "",
      title: "新增物料清单",
      fileList: [],
      createType: this.create,
      createTypeList: [
        {
          type: 1,
          name: "设计图",
        },
        {
          type: 2,
          name: "实物图",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: this.$t("bomNmaePlaceholder"),
            trigger: "blur",
          },
        ],
        delivery_date: [
          {
            required: true,
            message: this.$t("bomDatePlaceholder"),
            trigger: "change",
          },
        ],
        style_number: [
          {
            required: true,
            message: this.$t("bomStylePlaceholder"),
            trigger: "blur",
          },
        ],
        style_color: [
          {
            required: true,
            message: this.$t("bomColorPlaceholder"),
            trigger: "blur",
          },
        ],
        model_name: [
          {
            required: true,
            message: this.$t("bomModelPlaceholder"),
            trigger: "blur",
          },
        ],
        shoe_last_name: [
          {
            required: true,
            message: this.$t("bomHeaderPlaceholder"),
            trigger: "blur",
          },
        ],
        count: [
          {
            required: true,
            message: this.$t("bomNumPlaceholder"),
            trigger: "blur",
          },
        ],
        client: [
          {
            required: true,
            message: this.$t("bomClientPlaceholder"),
            trigger: "blur",
          },
        ],
        size: [
          {
            required: true,
            message: this.$t("bomSizePlaceholder"),
            trigger: "blur",
          },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          // Can not select days before today and today
          return time.getTime() < Date.now();
        },
      },
    };
  },
  created() {
    this.imageUrl = "";
    if (this.type == 0) {
      this.title = "新增物料清单";
    } else if (this.type == 1) {
      this.title = this.$t("editBasic");
    } else if (this.type == 2) {
      this.title = "上传3D模型";
    }
  },
  methods: {
    moment,
    closeModal() {
      this.$emit("closeModal");
    },
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    //上传图片限制
    beforeUpload(file) {
      let that = this;
      let limit_file = 4;
      const limit = file.size / 1024 / 1024 < limit_file;
      if (!limit) {
        this.$message({
          message: `${that.$t("uploadWarning")} ${limit_file}MB`,
          type: "warning",
        });
      }
      return limit;
    },
    // 选择图片
    picUpload(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.imageUrl = e.target.result; // 将图片路径赋值给src
      };
      reader.readAsDataURL(file.file);
      this.img_file = file.file;
    },
    //选择gif图
    chooseGif(file) {
      let that = this;
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        this.gifUrl = e.target.result;
      };
      reader.readAsDataURL(file.file);
      this.gif_file = file.file;
    },
    //上传3D
    uploadThree3d(file) {
      this.three_d_file = file.file;
    },
    //创建画布
    toCreate(formName) {
      let that = this;
      if (this.img_file == "" && that.type == 0) {
        this.$message.success("请选择图片");
        return;
      }
      if (this.three_d_file == "" && that.type == 2) {
        this.$message.success("请选择3D文件");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.type == 0) {
            that.$emit("toCreate", {
              createType: that.createType,
              imageUrl: that.imageUrl,
              img_file: that.img_file,
              gif_file: that.gif_file,
            });
          } else if (that.type == 1) {
            that.$emit("toEdit", {
              createType: that.createType,
            });
          } else if (that.type == 2) {
            that.$emit("toCreateThreeD", that.three_d_file);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
  transition: none;
}

::v-deep .el-list-enter,
::v-deep .el-list-leave-active {
  opacity: 0;
}
::v-deep .el-upload-list {
  height: 40px;
}
.upload-item {
  box-sizing: content-box;
}
.enter-dialog {
  box-sizing: border-box;
  .form-item {
    padding-left: 10px;
    box-sizing: border-box;
  }
  .dialog-text {
    margin: 10px 0;
    font-size: 15px;
    display: flex;
    align-items: center;
    .text-list {
      margin: 10px 0;
      width: 40%;
      text-align: right;
    }
  }
  .enter-btn-box {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .dialog-item-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

<template>
  <div class="add-cell">
    <i class="iconfont icon-ic_add" @click="addRow"></i>
  </div>
</template>

<script>
  export default {
    name: "addCell",
    methods:{
      addRow(){
        this.$emit('addRow')
      },
    },
  }
</script>

<style lang="scss">
  .add-cell{
    height: 22px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border: 1px solid #EBEEF5;
    border-top: none;
    background-color: #fff;
    .icon-ic_add{
      color: $base-config-color;
      cursor: pointer;
      font-size:8px ;
    }
  }
</style>

<template>
  <a-form class="material-form">
    <a-form-item>
      <span slot="label">
        {{ $t("materialName") }}
        <!-- <a-tooltip placement="bottom" title="物料名称">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-input
        v-model="material.name"
        :placeholder="$t('placeHolderMaterialName')"
        theme="classic"
        @change="handleNameChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("materialNumber") }}
        <!-- <a-tooltip placement="bottom" title="材质编号">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-input
        v-model="material.sn"
        :placeholder="$t('placeHolderMaterialNumber')"
        theme="classic"
        @change="handleNumberChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("placement") }}
        <!-- <a-tooltip placement="bottom" title="材质编号">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-cascader
        :options="placementOptions"
        :placeholder="$t('pleaseSelect')"
        @change="onChangePlacement"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("colorTexture") }}
        <!-- <a-tooltip placement="bottom" title="材质颜色">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <color-picker
        class="color-picker"
        v-model="material.param.albedoColor"
        @change="handleAlbeColorPicker"
      ></color-picker>
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("materialRoughness") }}
        <!-- <a-tooltip placement="bottom" title="The lower the roughness, the stronger the reflective effect, and vice versa.">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-slider
        :min="0"
        :max="0.3"
        :step="0.01"
        v-model="material.param.roughness"
        @change="handleMicroSurfaceChange"
      />
    </a-form-item>
    <!-- <a-form-item>
      <span slot="label">
        reflectivity color
        <a-tooltip placement="bottom" title="reflectivity color">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <color-picker
        class="color-picker"
        :defaultValue="material.param.reflectivityColor"
        @change="handleRefColorPicker"
      ></color-picker>
    </a-form-item> -->
    <!-- <a-form-item>
      <span slot="label">
        micro surface
        <a-tooltip placement="bottom" title="micro surface">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-slider
        :min="0"
        :max="1"
        :step="0.01"
        v-model="material.param.microSurface"
        @change="handleMicroSurfaceChange"
      />
    </a-form-item> -->
    <!-- <a-form-item>
      <span slot="label">
        reflection texture
        <a-tooltip placement="bottom" title="reflection texture">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <picker @ok="handleImageChange"></picker>
    </a-form-item> -->
  </a-form>
</template>

<script>
import ColorPicker from "@/components/v2/color-picker/ColorPicker.vue";
import Picker from "@/components/ImagePicker";
import mixin from "@/components/material/mixin";
import { materialsData } from "@/utils/babylonjs/meshData";

export default {
  name: "glass",
  components: {
    ColorPicker,
    // Picker,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ok: {
      type: Function,
      default: () => {},
    },
    option: {
      type: Object,
      default: () => ({
        id: 1,
        cat_id: 5,
        type: "pbr",
        name: "",
        sn: "",
        shape: "ball",
        material: "glass",
        param: {
          reflectionTexture: {
            url: "texture/environmentSpecular.env",
            id: 1,
            type: "env",
            material: null,
          },
          // microSurface: 0.96,
          // albedoColor: { r: 1, g: 1, b: 1 },
          metallic: 0,
          roughness: 0,
          subSurface: {
            isRefractionEnabled: true,
          },
          // reflectivityColor: { r: 255, g: 0, b: 0 },
        },
      }),
    },
  },

  data() {
    return {
      disableUpload: false,
      disabledPick: false,
      showImagePick: false,
      material: Object.assign(this.option, this.value),
      //material:null,
    };
  },
  mounted() {
    this.material = materialsData.glass;
  },
  created() {},
  methods: {
    handleNameChange(e) {
      //this.option.name = e.target.value;
      this.material.name = e.target.value;
      this.emit(this.material);
    },
    handleNumberChange(e) {
      this.material.sn = e.target.value;
      this.emit(this.material);
    },
    handleAlbeColorPicker(e) {
      console.log("color", e);
      console.log(this.material);
      this.emit(this.material);
    },
    handleRefColorPicker(e) {
      console.log("color", e);
      this.emit(this.material);
    },
    handleMicroSurfaceChange() {
      this.emit(this.material);
    },
    handleImageChange(file) {
      if (file) {
        this.material.param.reflectionTexture = {
          ...this.material.param.reflectionTexture,
          ...file,
        };
      } else {
        this.material.param.reflectionTexture = {};
      }
      this.emit(this.material);
    },
    init() {},
  },
  mixins: [mixin],
};
</script>
<style lang="scss">
.ant-drawer-body {
  padding: 0 16px;
}
</style>
<template>
  <div class="create-group__wrap border-left" v-if="visible">
    <!-- <div
      class="
        conv-header
        text-color
        flex
        just-space-between
        align-center
        p-h-14
        border-bottom
      "
    >
      <span class="title">{{ title }}</span>
      <span>
        <i
          class="iconfont hvr-color ml-16"
          :class="icon"
          v-for="(icon, idx) in icons"
          :key="idx"
          @click="handleIconClick(idx)"
        ></i>
      </span>
    </div> -->

    <div class="create-group bg-white p-h-14">
      <!-- <i
        class="close iconfont icon-guanbi1 pointer"
        @click.stop="handleClose"
      ></i> -->
      <field
        :label="$t('groupName')"
        v-model="createroom.name"
        :default="groupInfo.name"
        @enter="handleEnter"
        :placeholder="$t('meetingNametip')"
        :disabled="disableInput"
      ></field>
      <field
        :label="$t('meetingIntroduction')"
        v-model="createroom.introduction"
        :default="groupInfo.introduction"
        @enter="handleEnter"
        :disabled="disableInput"
        :placeholder="$t('meetingIntroductiontip')"
      ></field>
      <field
        :label="$t('meetingNotice')"
        v-model="createroom.notification"
        :default="groupInfo.notification"
        @enter="handleEnter"
        :disabled="disableInput"
        :placeholder="$t('meetingNoticetip')"
      ></field>
      <hr />
      <search v-model="username" class="mt-12"></search>
      <div class="memeber-list flex flex-wrap mt-12">
        <div
          v-if="!disableInput"
          class="form-item flex flex-col align-center just-center"
        >
          <button
            class="btn input radius-4 text-color font-size-12 border-dash mb-10 w-35p pointer"
            @click="handleAddMember"
          >
            <i class="iconfont icon-ic_add text-info-color font-size-12"></i>
          </button>
          <label class="font-size-14 w-42p text-center">{{
            $t("meetingAddMember")
          }}</label>
        </div>
        <div
          v-if="!disableInput"
          class="form-item flex flex-col align-center align-center just-center"
        >
          <button
            class="btn input radius-4 text-color font-size-12 border-dash mb-10 w-35p pointer"
            @click="handleDelMember"
          >
            <i
              class="iconfont icon-jianhao text-info-color font-size-12 text-color"
            ></i>
          </button>
          <label class="font-size-14 text-color w-42p text-center">{{
            $t("meetingDelMember")
          }}</label>
        </div>
        <div
          v-for="m in groupMemberFilter"
          :key="m.id"
          :class="m.selected ? 'selected' : null"
          @click="handleMemberClick(m)"
        >
          <member
            :avatar="m.head_img"
            :nick="m.last_name + m.first_name"
          ></member>
          <!-- <i class="iconfont icon-check text-info-color"></i> -->
        </div>
      </div>
    </div>
    <!-- <div v-if="!btntype" class="form-item mt-12 flex flex-col mb-4">
        <n-button @click="handleOutMeeting">{{
          !isOwn ? $t("meetingOut") : $t("meetingClose")
        }}</n-button>
      </div> -->
    <div class="form-item flex flex-col footer">
      <!-- <div v-if="isOwn || btntype">
        <n-button @click="handleConfirm" color="transparent">{{
          this.btnname
        }}</n-button>
      </div> -->
      <!-- <div v-if="!btntype">
        <n-button @click="handleOutMeeting" color="transparent">{{
          !isOwn ? $t("meetingOut") : $t("meetingClose")
        }}</n-button>
      </div> -->
      <n-button @click="handleOutMeeting" color="transparent">{{
        !isOwn ? $t("meetingOut") : $t("meetingClose")
      }}</n-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createNamespacedHelpers } from "vuex";
import field from "../components/field.vue";
import NButton from "../components/button.vue";
import search from "../components/search.vue";
import member from "../member/index.vue";
const {
  mapState: mapStateGroup,
  mapActions: mapActionsGroup,
  mapMutations: mapMutationsGroup,
} = createNamespacedHelpers("jiyang/im");
export default {
  components: {
    member,
    field,
    search,
    NButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    btntype: {
      type: Boolean,
      default: true,
    },
    ownid: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      user_info: null,
      selected: [],
      createroom: {
        name: "",
        avatar: "",
        introduction: "",
        notification: "",
        memberList: [],
      },
      addressList: [],
      showAddress: false,
      username: "",
      btnname: this.$t("meetingCreate"),
      userpage: 0,
      userpagesize: 50,
      icons: ["icon-ellipsis", "icon-close"],
      members: [],
      oldmembers: [],
    };
  },
  computed: {
    ...mapStateGroup([
      "showContactModal",
      "groupMember",
      "current",
      "groupList",
      "current",
    ]),
    ...mapState("jiyang/user", ["info"]),
    groupInfo() {
      // meeting_img: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/20201126/a3a21eadec048140613de291fa6b3152.png"
      // meeting_name: "龙 智渊,蒋 翼...3人"
      // meeting_notice: "MBR MEMBER"
      // meeting_remarks: "MBR MEMBER"
      const {
        meeting_remarks = "",
        meeting_notice = "",
        meeting_name = "",
        meeting_img = "",
      } = this.groupList.find((g) => g.meeting_id == this.current.meeting_id) ||
      {};
      return {
        name: meeting_name,
        avatar: meeting_img,
        introduction: meeting_remarks,
        notification: meeting_notice,
        memberList: [],
      };
    },
    disableInput() {
      return this.ownid != this.user_info.id;
    },
    disableDel() {
      return this.members.every((m) => m.selected == false);
    },
    isOwn() {
      return this.ownid == this.user_info.id ? true : false;
    },
    searchName() {
      return this.username;
    },
    groupMemberFilter() {
      if (this.searchName) {
        return this.groupMember.filter((m) =>
          m.nickname.includes(this.searchName)
        );
      }
      return this.groupMember;
    },
  },
  watch: {
    groupInfo(newVal) {
      this.createroom = newVal || {
        name: "",
        avatar: "",
        introduction: "",
        notification: "",
        memberList: [],
      };
    },
    btntype(val) {
      if (val) {
        this.btnname = this.$t("meetingCreate");
        this.members = [];
        this.createroom = {
          name: "",
          avatar: "",
          introduction: "",
          notification: "",
          memberList: [],
        };
      } else {
        this.btnname = this.$t("meetingModify");
      }
    },
    visible(val) {
      //重置用户列表
      if (val) {
        this.fetchGroupInfo();
      }
      if (val) {
        if (this.addressList) {
          this.addressList.forEach((user) => {
            let id = this.members.findIndex((me) => me.id == user.id);
            this.$set(user, "selected", false);
            if (id >= 0) this.$set(user, "selected", true);
          });
        }
      } else {
        this.showAddress = false;
      }
    },
  },
  mounted() {
    this.searchMeetinguser();
  },
  created() {
    this.user_info = this.info;
  },
  methods: {
    ...mapMutationsGroup([
      "setContactModalShow",
      "setModalConfig",
      "setModalConfig",
      "removeGroup",
    ]),
    ...mapActionsGroup(["fetchGroupInfo"]),
    handleEnter() {
      this.changeGroup();
    },
    changeGroup() {
      // {meeting_id : '会议ID',
      // room:'腾讯会议ID',
      // name: '会议名称',
      // avatar: '会议头像',
      // introduction: '会议备注',
      // notification: '会议公告',
      // memberList: [{id:1},{id:2}],delList: [{id:1},{id:2}]}
      this.$api.changeMeeting({
        ...this.createroom,
        avatar: "",
        room: this.current.room,
        meeting_id: this.current.meeting_id,
        memberList: this.groupMember,
      });

      this.$im.updateGroupProfile({
        groupID: this.current.room,
        name: this.createroom.name, // 修改群名称
        introduction: this.createroom.introduction,
        notification: this.createroom.notification,
      });
    },
    handleClose() {
      this.close();
      this.$emit("close");
    },
    handleOk() {
      this.close();
    },
    handleConfirm() {
      if (this.btntype) {
        this.createMeeting();
      } else {
        this.changeMeeting();
      }
    },
    handleOutMeeting() {
      this.outMeeting();
    },
    handleMemberClick(m) {
      m.selected
        ? this.$set(m, "selected", false)
        : this.$set(m, "selected", true);
      const idx = this.members.findIndex((me) => me.id == m.id);
      idx != -1 && this.members.splice(idx, 1, m);
    },
    handleAddMember() {
      this.setContactModalShow(true);
      this.setModalConfig({
        mode: "add",
      });
    },
    handleAddressListChge(members) {
      members.forEach((user) => {
        if (user.selected) {
          const idx = this.members.findIndex((me) => me.id == user.id);
          let new_user = this.cloneObj(user);
          new_user.selected = false;
          idx < 0 ? this.members.push(new_user) : "";
        }
      });
    },
    handleDelMember() {
      this.setModalConfig({
        mode: "remove",
      });
      this.setContactModalShow(true);

      // this.members.forEach((user) => {
      //   if (user.selected) {
      //     //还原添加列表选择项
      //     const idx = this.addressList.find((me) => me.id == user.id);
      //     idx ? (idx.selected = false) : "";
      //   }
      // });
      // this.members = this.members.filter((m) => !m.selected);
    },
    outMeeting() {
      this.$confirm(
        !this.isOwn ? this.$t("meetingOuttishi") : this.$t("meetingClosetishi"),
        this.$t("messageTiShi"),
        {
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          let data = {
            meeting_id: this.current.meeting_id,
            room: this.current.room,
            isOwn: this.isOwn,
          };
          this.$api.outMeeting(data).then((res) => {
            this.close();
            this.members = [];
            this.$emit("newmeeting", this.createroom, -1);
            this.createroom = {
              name: "",
              avatar: "",
              introduction: "",
              notification: "",
              memberList: [],
            };
            this.removeGroup();
          });
        })
        .catch(() => {});
    },
    createMeeting() {
      let _this = this;
      this.members.forEach((user) => {
        _this.createroom.memberList.push({ id: user.id });
      });

      this.$api.creatMeeting(this.createroom).then((res) => {
        this.close();
        this.members = [];
        this.createroom = {
          name: "",
          avatar: "",
          introduction: "",
          notification: "",
          memberList: [],
        };
        res.meeting_img =
          "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
          res.meeting_img;
        this.$emit("newmeeting", res, 0);
      });
    },
    changeMeeting() {
      let _this = this;
      this.createroom.delList = [];
      this.createroom.memberList = [];
      //查找新的数据
      this.members.forEach((user) => {
        let id = this.oldmembers.findIndex((me) => me.id == user.id);
        if (id < 0) _this.createroom.memberList.push({ id: user.id });
      });
      //查找删除数据
      this.oldmembers.forEach((user) => {
        let id = this.members.findIndex((me) => me.id == user.id);
        if (id < 0) _this.createroom.delList.push({ id: user.id });
      });

      this.$api.changeMeeting(this.createroom).then((res) => {
        this.close();
        //删除用户列表
        this.oldmembers.length = 0;
        this.members.forEach((user) => {
          _this.oldmembers.push(user);
        });
        this.members = [];
        this.$emit("newmeeting", this.createroom, 1);
        this.createroom = {
          name: "",
          avatar: "",
          introduction: "",
          notification: "",
          memberList: [],
        };

        //this.$emit("newmeeting",res);
        //this.meetinglist.push(res);
      });
    },
    setNowMeetingMsg(meeting, userlist) {
      this.createroom.name = meeting.meeting_name;
      this.createroom.introduction = meeting.meeting_remarks;
      this.createroom.notification = meeting.meeting_notice;
      this.createroom.meeting_id = meeting.meeting_id;
      this.createroom.room = meeting.room;
      this.oldmembers = userlist;
      this.members = this.cloneObj(userlist);
    },
    searchMeetinguser() {
      let data = {
        name: this.username,
        page: this.userpage,
        pagesize: this.userpagesize,
      };
      this.$api.searchMeetinguser(data).then((res) => {
        this.addressList = res;
        this.addressList.forEach((user) => {
          user.head_img = user.head_img;
        });
      });
    },
    close() {
      this.$emit("update:visible", false);
    },
    cloneObj(obj) {
      var newObj = {};
      if (obj instanceof Array) {
        newObj = [];
      }
      for (var key in obj) {
        var val = obj[key];
        //newObj[key] = typeof val === 'object' ? arguments.callee(val) : val; //arguments.callee 在哪一个函数中运行，它就代表哪个函数, 一般用在匿名函数中。
        newObj[key] = typeof val === "object" ? this.cloneObj(val) : val;
      }
      return newObj;
    },
  },
};
</script>

<style lang="less">
.create-group__wrap {
  box-sizing: content-box;
  top: -1px;
  position: absolute;
  right: -0px;
  width: 273px;
  height: 100%;
  border: 1px solid rgb(178, 178, 178);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-color: #b2b2b2;
  flex-shrink: 0;
  flex-grow: 0;
  transform: translate(273px, 0);
  .footer {
    color: #ff0000;
    display: flex;
    align-items: center;
    height: 46px;
    border-top: 1px solid #b2b2b2;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .memeber-list {
    overflow: auto;
    // height: 47%;
    flex: 1;
    gap: 25px;
    align-content: flex-start;
    padding-bottom: 16px;
  }
}

.create-group {
  display: flex;
  flex-direction: column;
  height: calc(100% - 46px);
  .w-35p {
    width: 35px;
    height: 35px;
  }
  .close {
    position: absolute;
    top: 5px;
    right: 13px;
    color: red;
  }
  .member-list {
    // height: 300px;
    height: 340px;
    overflow: auto;
  }
  .member-list-change {
    height: 296px;
  }
  .member-list-change2 {
    height: 424px;
  }
  .input {
    outline: none;
    background-color: #f2f2f2;
  }
  .btn {
    outline: none;
  }
  .member-item {
    display: flex;
  }
  .selected {
    background: #e4e4e4;
    .iconfont {
      color: var(--primaryColorV2);
    }
  }
}
</style>

export default ({ service, request }) => ({
  // 获取模板信息
  getTemplate(params) {
    return request({
      url: `/v1/form/template`,
      method: "get",
      params,
    });
  },
  //  更新模板信息
  updateTemplate(data) {
    return request({
      url: `/v1/form/template`,
      method: "post",
      data,
    });
  },
  //获取表单列表
  getFormList(params) {
    return request({
      url: `/v1/form`,
      method: "get",
      params,
    });
  },
  //  保存表单信息
  saveForm(data) {
    return request({
      url: `/v1/form`,
      method: "post",
      data,
    });
  },
  //  获取指定表单信息
  getForm(params) {
    return request({
      url: `/v1/form/${params.id}`,
      method: "get",
      params,
    });
  },
  //  更新表单信息
  updateForm(data) {
    return request({
      url: `/v1/form/${data.id}`,
      method: "put",
      data,
    });
  },
  //删除指定表单
  deleteForm(data){
    return request({
      url: `/v1/form/${data.id}`,
      method: "delete",
      data,
    });
  },
  //复制表单
  copyForm(data){
    return request({
      url: `/v1/form/copy`,
      method: "post",
      data,
    });
  },
  //  改变表单状态
  changeFormStatus(data) {
    return request({
      url: `/v1/form/changeStatus/${data.id}`,
      method: "put",
      data,
    });
  },
  //  获取公海询盘单
  getSeaEnquiry(params) {
    return request({
      url: `/v1/form/getSeaEnquiry`,
      method: "get",
      params,
    });
  },
  //领取公海询盘单
  receiveSeaEnquiry(data) {
    return request({
      url: `/v1/form/receiveSeaEnquiry`,
      method: "post",
      data,
    });
  },
  //  客户筛选接口
  getCustomerSelectList(data) {
    return request({
      url: `/v1/customer/getCustomerSelectList`,
      method: "post",
      data,
    });
  },
  //获取客户推荐单列表
  getCustomerRecommendList(params) {
    return request({
      url: `v1/recommendform/getCustomerRecommendList`,
      method: "post",
      params,
    });
  },
  //  未读消息
  unreadCount(params) {
    return request({
      url: `/v1/form/unreadCount`,
      method: "get",
      params,
    });
  },
  //查看表单
  lookOver(data) {
    return request({
      url: `/v1/form/lookOver/${data.id}`,
      method: "put",
      data,
    });
  },
  //  获取cbd列表
  getCBDList(params) {
    return request({
      url: `/v1/cbd`,
      method: "get",
      params,
    });
  },
  //  获取cbd详情
  getCBDDetail(params) {
    return request({
      url: `/v1/cbd/${params.id}`,
      method: "get",
      params,
    });
  },
  //  保存CBD
  saveCBD(data) {
    return request({
      url: `/v1/cbd`,
      method: "post",
      data,
    });
  },
  //  更新CBD
  updateCBD(data) {
    return request({
      url: `/v1/cbd/${data.id}`,
      method: "put",
      data,
    });
  },
  //  删除cbd
  delCBD(data) {
    return request({
      url: `/v1/cbd/${params.id}`,
      method: "delete",
      data,
    });
  },
//  获取历史记录列表
  getHistory(params) {
    return request({
      url: `/v1/form/historyList`,
      method: "get",
      params,
    });
  },
//  获取历史记录详情
  getHistoryDetail(params){
    return request({
      url: `/v1/form/readHistory/${params.id}`,
      method: "get",
      params,
    });
  },
});

<template>
  <div class="material-tree">
    <el-table
      :empty-text="$t('emptyData')"
      :data="tableData"
      row-key="part_id"
      default-expand-all
      @row-click="handleClick"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        :label="$t('position')"
        align="center"
        width="80"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="num" :label="$t('num')" align="center" width="50">
      </el-table-column>
      <el-table-column
        prop="texture_name"
        :label="$t('materialName')"
        align="center"
      >
        <template slot-scope="scope">
          <div class="test">
            sn{{ scope.row.sn }}{{ scope.row.texture_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="technology"
        :label="$t('technology')"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "materialTable",
  props: {
    datas: {
      type: Array,
    },
  },
  watch: {
    datas(val) {
      this.tableData = val;
      this.getSpanArr(this.tableData, "part_id");
    },
  },
  data() {
    return {
      tableData: this.datas,
      spanArrs: [],
    };
  },
  created() {
    setTimeout(() => {
      this.getSpanArr(this.tableData, "part_id");
    }, 100);
  },
  methods: {
    //修改基础信息
    editMsg() {
      this.$emit("showEditModal");
    },
    //关闭
    closeTableModal() {
      this.$emit("closeTableModal");
    },
    // groupBy 数组
    groupBy(data, params) {
      const groups = {};
      data.forEach((v) => {
        const group = JSON.stringify(v[params]);
        groups[group] = groups[group] || [];
        groups[group].push(v);
      });
      return Object.values(groups);
    },
    // 计算 数据合并 索引
    getSpanArr(data, params) {
      let datas = JSON.parse(JSON.stringify(data));
      // 控制合并的数组
      this.spanArrs = [];

      // // arr 处理
      this.groupBy(datas, params).forEach((val) => {
        let arr = {
          name: val[0].name,
          part_id: `${Math.random()}` + val[0].part_id,
        };
        let item = val.map((val1) => {
          delete val1.name;
          val1.part_id = `${Math.random()}` + val1.part_id;
          return val1;
        });
        if (val.length > 1) {
          val = val.sort(function (a, b) {
            return a.num - b.num;
          });
        }
        arr["children"] = val;
        this.spanArrs.push(arr);
      });
      this.tableData = this.spanArrs;
    },
    handleClick(row, column, event) {
      row.num && this.$emit("selected", row.num);
    },
  },
};
</script>

<style lang="scss">
.material-tree {
  width: 99%;
  max-height: 100%;
  background-color: #fff;
  /*border-left: 1px solid #dcdcdc;*/
  box-sizing: border-box;
  .basis-msg {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .basis-title {
      width: 100%;
      font-weight: 600;
      color: #333;
      font-size: 16px;
      text-align: left;
      margin-bottom: 10px;
      .edit-text {
        margin-left: 4px;
        color: #3596b9;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .basis-li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      width: 50%;
      margin-bottom: 4px;
      padding-left: 4px;
      box-sizing: border-box;
      .basis-text-title {
        width: 60px;
        text-align: right;
        flex-shrink: 0;
      }
      .basis-text {
        flex: 1;
        text-align: left;
        font-weight: 600;
        word-wrap: break-word;
        word-break: break-all;
        display: flex;
        align-items: center;
      }
    }
  }
  .close-box {
    /*width: 100%;*/
    /*height: 20px;*/
    text-align: right;
    /*padding-right: 10px;*/
    position: absolute;
    top: 10px;
    right: 10px;
    box-sizing: border-box;
    /*background-color: #fff;*/
    .close-btn {
      font-size: 16px;
      color: #999;

      cursor: pointer;
    }
  }

  .el-table {
    font-size: 12px;
  }
  .el-table td,
  .el-table th {
    padding: 6px 0;
  }
}
</style>

<template>
  <div class="search mr-16 w-100">
    <i
      class="iconfont icon-fangdajing font-size-14 text-info-color"
      @click="handleClick"
    ></i>
    <input
      class="input w-100 outline radius-4 p-6 font-size-14 focus border-gray bg-gray-1"
      ref="input"
      @click="$refs.input.focus()"
      :value="value"
      type="text"
      placeholder="搜索"
      @input="handleInput"
      @keypress.enter="handleEnter"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      inputVal: "",
    };
  },
  methods: {
    handleClick() {
      this.emit();
    },
    emit() {
      this.$emit("enter", this.inputVal);
    },
    handleInput(e) {
      const {
        target: { value },
      } = e;
      this.inputVal = value;
      this.$emit("input", value);
    },
    handleEnter(e) {
      const {
        target: { value },
      } = e;
      this.inputVal = value;
      this.emit();
    },
  },
};
</script>

<style lang="less">
.search {
  .input {
    text-indent: 20px;
  }
  position: relative;
  > i {
    top: 50%;
    left: 5px;
    position: absolute;
    transform: translate(0, -50%);
  }
}
</style>

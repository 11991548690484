<template>
  <div class="modal-wrap" v-if="visible">
    <div class="modal"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" less>
.modal {
  width: 717px;
  height: 548px;
  background: #efefef;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // box-shadow: 0 0 14px 4px rgb(139 139 139 / 22%);
  z-index: 1001;
}
</style>

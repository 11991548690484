<template>
  <modal :visible="showContactModal">
    <template>
      <div class="contact flex">
        <div class="contact-list">
          <div class="header">
            <Search @enter="handleEnter" @input="handleInput"></Search>
          </div>
          <div class="list-wrap">
            <a-collapse
              v-if="modalConfig.mode != 'remove'"
              v-model="activeKey"
              :bordered="false"
              expandIconPosition="right"
            >
              <a-collapse-panel key="1" header="员工">
                <ul class="list">
                  <li
                    class="contact-item"
                    v-for="contact in employeesFilter"
                    :key="contact.id"
                  >
                    <a-checkbox
                      v-model="contact.selected"
                      :disabled="contact.disabled"
                      @change="(e) => handleChange(e, contact)"
                    ></a-checkbox>
                    <img class="avatar" :src="contact.avatar" alt="avatar" />
                    <span class="nickname">{{ contact.nickname }}</span>
                  </li>
                </ul>
              </a-collapse-panel>
              <a-collapse-panel key="2" header="企业">
                <ul class="list">
                  <li
                    class="contact-item"
                    v-for="contact in enterpriseFilter"
                    :key="contact.id"
                  >
                    <a-checkbox
                      v-model="contact.selected"
                      :disabled="contact.disabled"
                      @change="(val) => handleChange(val, contact)"
                    ></a-checkbox>
                    <img class="avatar" :src="contact.avatar" alt="avatar" />
                    <span class="nickname">{{ contact.nickname }}</span>
                  </li>
                </ul>
              </a-collapse-panel>
            </a-collapse>
            <ul class="list" v-else>
              <li
                class="contact-item"
                v-for="contact in removeMemberFilter"
                :key="contact.id"
              >
                <a-checkbox
                  v-model="contact.selected"
                  :disabled="contact.disabled"
                  @change="(val) => handleChange(val, contact)"
                ></a-checkbox>
                <img class="avatar" :src="contact.avatar" alt="avatar" />
                <span class="nickname">{{ contact.nickname }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="selected-list">
          <div class="header">
            <span class="label">{{ title }}</span>
            <span>已选择{{ selectedContacts.length }}</span>
          </div>
          <ul class="list">
            <li
              class="contact-item"
              v-for="contact in selectedContacts"
              :key="contact.id"
            >
              <img class="avatar" :src="contact.avatar" alt="avatar" />
              <i
                class="iconfont icon-guanbi1"
                @click.stop="handleDel(contact)"
              ></i>
              <span class="nickname">{{ contact.nickname }}</span>
            </li>
          </ul>
          <div class="footer">
            <button class="btn" @click.stop="handleCancel">取消</button>
            <button class="btn primary" @click.stop="handleOk">完成</button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Search from "../search.vue";
import Modal from "./base.vue";
const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers("jiyang/im");

export default {
  components: {
    Modal,
    Search,
  },
  data() {
    return {
      activeKey: 1,
      searchName: "",
      employees: [],
      enterprise: [],
      selectedContacts: [],
      pageInfo: {
        page: 1,
        pageSize: 100,
      },
      contacts: [],
    };
  },
  created() {
    //
    // this.employees = this._employees;
    //
    // this.enterprise = this._enterprise;
    //
    // this.contacts = this.totalContact;
  },
  methods: {
    ...mapMutations([
      "setContactModalShow",
      "setTotalContact",
      "removeGroupContact",
      "resetContact",
      "setCancelSelect",
    ]),
    ...mapActions(["fetchGroupInfo"]),
    handleInput(val) {
      this.searchName = val;
    },
    handleDel(contact) {
      this.selectedContacts = this.selectedContacts.filter(
        (c) => c.id !== contact.id
      );
      this.unSelected(contact);
    },

    handleEnter(val) {
      this.searchName = val;
    },

    emit() {
      this.$emit("change", this.selectedContacts);
    },

    handleChange(e, contact) {
      const {
        target: { checked },
      } = e;
      if (checked) {
        this.selectedContacts.push({ ...contact, selected: true });
      } else {
        this.selectedContacts = this.selectedContacts.filter(
          (c) => c.id !== contact.id
        );
      }
      this.unSelected(contact, checked);
    },

    unSelected(contact, checked) {
      const idx = this.enterprise.findIndex((c) => c.id == contact.id);
      if (idx != -1) {
        this.enterprise.splice(idx, 1, {
          ...contact,
          selected: checked,
        });
      }

      const idx2 = this.employees.findIndex((c) => c.id == contact.id);
      if (idx2 != -1) {
        this.employees.splice(idx2, 1, {
          ...contact,
          selected: checked,
        });
      }
    },

    handleCancel() {
      this.setContactModalShow(false);
      this.selectedContacts = [];
      // this.resetContact();
      this.$emit("cancel");
      this.reset();
    },

    async createGroup(user) {
      //  avatar: ""
      // introduction: ""
      // memberList: [{id: 12}, {id: 13}, {id: 14}, {id: 15}, {id: 12}, {id: 13}, {id: 14}, {id: 15}]
      // name: "1111"
      // notification: ""

      if (!user.length) {
        return;
      }
      const param = {
        avatar: "",
        introduction: "",
        memberList: user,
        name: user
          .map((u) => u.nickname)
          .splice(0, 4)
          .join(" "),
        notification: "",
      };
      await this.$api.creatMeeting(param);
      this.$emit("ok");
    },

    async removeGroupMember(members) {
      const data = {
        meeting_id: this.current.meeting_id,
        room: this.current.room,
        memberList: members,
      };
      this.$api.delmpeople(data);
    },

    async addGroupMember(members) {
      const data = {
        meeting_id: this.current.meeting_id,
        room: this.current.room,
        memberList: members,
      };
      this.$api.addmpeople(data);
    },

    handleOk() {
      this.setContactModalShow(false);
      this.$emit("ok", this.selectedContacts);
      this.switch();
      this.reset();
    },

    async switch() {
      switch (this.modalConfig.mode) {
        case "create":
          this.createGroup(this.selectedContacts);
          break;
        case "remove":
          await this.removeGroupMember(this.selectedContacts);
          this.fetchGroupInfo();
          break;
        case "add":
          await this.addGroupMember(this.selectedContacts);
          this.fetchGroupInfo();
          break;
      }
      this.resetContact();
    },

    initCreateDate(members) {
      this.enterprise = this.enterprise.map((m) => ({
        ...m,
        selected: false,
        disabled: false,
      }));
      this.employees = this.employees.map((m) => ({
        ...m,
        selected: false,
        disabled: false,
      }));
    },

    initRemoveDate(members) {
      this.enterprise = members.map((m) => ({ ...m, selected: false }));
      this.employees = members.map((m) => ({ ...m, selected: false }));
    },

    initAddDate(members) {
      let enterprise = this._enterprise.slice();
      let employees = this._employees.slice();
      members.forEach((m) => {
        enterprise.forEach((en) => {
          if (en.id == m.id) {
            en.selected = true;
            en.disabled = true;
          }
        });

        employees.forEach((em) => {
          if (em.id == m.id) {
            em.selected = true;
            em.disabled = true;
          }
        });
      });

      this.enterprise = enterprise;
      this.employees = employees;
    },

    init() {
      const members = this.groupMember;
      switch (this.modalConfig.mode) {
        case "create":
          this.initCreateDate(members);
          break;
        case "remove":
          this.initRemoveDate(members);
          break;
        case "add":
          this.initAddDate(members);
          break;
      }
    },

    reset() {
      this.selectedContacts = [];
      this.enterprise = this.groupMember.map((m) => ({
        ...m,
        selected: false,
      }));
      this.employees = this.groupMember.map((m) => ({
        ...m,
        selected: false,
      }));
    },
  },

  computed: {
    ...mapState([
      "current",
      "showContactModal",
      "totalContact",
      "modalConfig",
      "groupMember",
      // "userGroup",
      "_employees",
      "_enterprise",
    ]),

    title() {
      let title = "选择联系人";
      switch (this.modalConfig.mode) {
        case "add":
          title = "添加联系人";
          break;
        case "remove":
          title = "删除联系人";
          break;
        case "create":
          title = "选择联系人";
          break;
      }
      return title;
    },

    enterpriseFilter() {
      if (this.searchName) {
        return this.enterprise.filter((c) =>
          c.nickname.includes(this.searchName)
        );
      }
      return this.enterprise;
    },
    removeMemberFilter() {
      return this.groupMember;
    },
    employeesFilter() {
      if (this.searchName) {
        return this.employees.filter((c) =>
          c.nickname.includes(this.searchName)
        );
      }
      return this.employees;
    },
  },
  watch: {
    showContactModal: {
      deep: true,
      async handler(val) {
        // this.init();
        // if (val) {
        //   this.groupMember.forEach((m) => {
        //     this.employees.forEach((e) => {
        //       if (m.id == e.id) {
        //         e.disabled = true;
        //       }
        //     });
        //   });
        // }
      },
    },
    modalConfig: {
      deep: true,
      handler(val) {
        this.init();
      },
    },
    groupMember: {
      deep: true,
      handler(members) {
        if (!members.length) {
          this.enterprise = this._enterprise;
          this.employees = this._employees;
          return;
        }
        this.init();
      },
    },
  },
};
</script>

<style lang="less">
.contact {
  .ant-collapse {
    background-color: transparent;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  .list {
    .contact-item {
      cursor: pointer;
      &:hover {
        background-color: #e7e7e7;
      }
    }
  }
}
.contact-list {
  width: 334px;
  height: 547px;
  background: #f7f7f7;

  //   height: 400px;
  //   overflow: scroll;
  .header {
    padding: 17px 28px;
  }
  .list-wrap {
    overflow: hidden;
    height: 480px;
  }
  .list {
    padding-bottom: 40px;
    height: 400px;
    overflow: auto;
    .contact-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 21px;
      padding: 0 30px;
      .avatar {
        width: 40px;
        height: 40px;
        object-fit: contain;
        box-shadow: 0px 0px 10px rgba(46, 46, 46, 0.15);
        margin-right: 9px;
        margin-left: 9px;
      }
      .nickname {
        width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .ant-checkbox-wrapper {
        border-radius: 50%;
      }
      .ant-checkbox-inner {
        border-radius: 50%;
      }
      .ant-checkbox-checked::after {
        border-radius: 50%;
      }
    }
  }
}
.selected-list {
  width: 383px;
  height: 547px;
  background: #efefef;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 20px 34px;
    align-items: center;
    .label {
      font-size: 18px;
      color: #000000;
    }
    .selected-count {
      background: #9a9a9a;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 40px;
    gap: 46px;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: scroll;
    height: 420px;
  }
  .contact-item {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    position: relative;
    .iconfont {
      color: #ababab;
      font-size: 16px;
      position: absolute;
      top: -5px;
      right: 0px;
    }
  }
  .avatar {
    width: 49px;
    height: 49px;
    box-shadow: 0px 0px 10px rgba(46, 46, 46, 0.15);
    margin-bottom: 9px;
  }
  .nickname {
    text-align: center;
    width: 66px;
    font-size: 14px;
    font-family: STHeitiSC-Medium;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000000;
  }
  .footer {
    display: flex;
    gap: 22px;
    justify-content: center;
    .btn {
      width: 126px;
      height: 34px;
      background: #e1e1e1;
      border-radius: 3px;
      outline: none;
      border: none;
      color: #16c39a;
      cursor: pointer;
    }
    .btn.primary {
      background-color: #16c39a;
      color: #fff;
    }
  }
}
</style>

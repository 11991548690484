<template>
  <el-card class="form-right-page" v-if="formData.formPage">
    <div id="pageHeader" v-if="formData.formHeader">
      <component
        is="formHeader"
        :checkEdit="checkEdit"
        :itemData="formData.formHeader.data"
      />
    </div>
    <div class="page-all-form">
      <div v-if="formType == 4" class="page-center">
        <div class="page-msg-box" id="msgTableBox">
          <component
            is="normalTable"
            :checkEdit="checkEdit"
            :table="formData.formPage.recommendDetail.tableData"
            index="userMsg"
            @addRow="addRow"
          />
          <component
            is="normalTable"
            :checkEdit="checkEdit"
            :table="formData.formPage.formSn.tableData"
            index="formSn"
            @addRow="addRow"
          />
        </div>
        <div class="recommend-list">
          <recommendCard
            v-for="(val, key) in recommendList"
            :itemData="val"
            :index="key"
            :key="key"
            @showProduct="showProduct"
            @closeProduct="closeProduct"
          ></recommendCard>
        </div>
      </div>
      <div v-else class="page-center">
        <div
          class="page-msg-box"
          id="msgTableBox"
          v-if="formData.formPage.userMsg"
        >
          <component
            is="normalTable"
            :checkEdit="checkEdit"
            :table="formData.formPage.userMsg.tableData"
            :customerId="customer_id"
            :customer="customer"
            ref="userMsg"
            :customArr="customArr"
            :formType="formType"
            index="userMsg"
            @addRow="addRow"
            @changeCustom="changeCustom"
          />
          <component
            is="normalTable"
            :checkEdit="checkEdit"
            :table="formData.formPage.formSn.tableData"
            index="formSn"
            @addRow="addRow"
          />
        </div>
        <div
          class="page-product-box"
          id="formTables"
          v-if="formData.formPage.product"
        >
          <component
            is="formTable"
            :table="formData.formPage.product.tableData"
            :formType="formType"
            :checkEdit="checkEdit"
            @setAmount="setAmount"
            @removeProduct="removeProduct"
            @showProduct="showProduct"
          />
          <component
            is="formTotalTable"
            :checkEdit="checkEdit"
            :totalData="formData.formPage.product.totalData"
            @addRow="addProductRow"
            @setTotal="setTotal"
          />
        </div>
        <div id="paymentBox" v-if="formData.formPage.payment">
          <div class="page-payment-box">
            <component
              is="normalTable"
              :checkEdit="checkEdit"
              :table="formData.formPage.payment.tableData"
              index="payment"
              @addRow="addRow"
            />
          </div>
          <div class="page-payment-box">
            <component
              is="normalTable"
              :checkEdit="checkEdit"
              :table="formData.formPage.terms.tableData"
              index="terms"
              @addRow="addRow"
            />
          </div>
        </div>
      </div>
    </div>

    <component
      v-if="formData.formFooter.data"
      is="formFooter"
      :checkEdit="checkEdit"
      :itemData="formData.formFooter.data"
    />
    <addProductDialog
      v-if="isShowProduct"
      :top="productTop"
      @closeDialog="closeDialog"
      @submitProduct="submitProduct"
    ></addProductDialog>
  </el-card>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector";
import normalTable from "./normalTable";
import formTable from "./formTable";
import uploadCos from "@/utils/upload_to_cos";
import formHeader from "./formHeader";
import formFooter from "./formFooter";
import addProductDialog from "./addProductDialog";
import recommendCard from "./recommendCard";
import formTotalTable from "./formTotalTable";
let that;
export default {
  name: "formPage",
  components: {
    formHeader,
    formFooter,
    formTable,
    normalTable,
    addProductDialog,
    recommendCard,
    formTotalTable,
  },
  props: {
    checkEdit: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Array,
    },
    templateData: {
      type: Object,
    },
    formType: {
      type: Number,
      default: 2,
    },
    customId: {
      type: Number,
      default: 0,
    },
    customer: {
      type: Object,
    },
  },

  data() {
    return {
      page: 0,
      tableData: this.table,
      checkCreate: true,
      formIndex: 0,
      sublineLeft: 0,
      isShowSubline: false,
      isShowProduct: false,
      productTop: 0,
      productIndex: 0,
      formData: this.templateData,
      pageBottom: 0,
      pageHeader: 0,
      checkWatch: false,
      customer_id: this.customId,
      customArr: [],
    };
  },
  watch: {
    customId(val) {
      this.customer_id = val;
      this.$nextTick(() => {
        this.$refs.userMsg.custom_id = val;
      });
    },
    customer(val) {
      console.log(val);
      this.$nextTick(() => {
        this.$refs.userMsg.customer = val;
      });
    },
    templateData(val) {
      this.formData = val;
      if (val.customer_id) {
        this.customer_id = val.customer_id;
      } else {
        this.customer_id = 0;
      }
      if (!this.checkWatch) {
        let erd = elementResizeDetectorMaker();
        this.$nextTick(async () => {
          //监听基础信息
          await erd.listenTo(
            document.getElementById("paymentBox"),
            that.resizeMsg
          );
          await erd.listenTo(
            document.getElementById("pageHeader"),
            that.getHeaderHieght
          );
          erd.listenTo(document.getElementById("formTables"), that.resizeTable);
        });
        this.checkWatch = true;
      }
    },
  },
  created() {
    console.log(this.checkEdit);
    this.toGetCustomerList();
  },
  mounted() {
    that = this;
  },
  directives: {
    drag: {
      // 指令的定义
      inserted: function (el, binding) {
        if (binding.value.checkEdit == false) return;
        //获取元素
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        let isDown = false;
        let width = 0;
        let height = 0;
        //鼠标按下事件
        el.onmousedown = function (e) {
          isDown = false;
          //获取x坐标和y坐标
          x = e.clientX;
          y = e.clientY;
          //获取左部和顶部的偏移量
          l = el.offsetLeft;
          t = el.offsetTop;
          width = el.offsetWidth;
          height = el.offsetHeight;
          //开关打开
          isDown = true;
          //设置样式
          el.style.cursor = "move";
          binding.value.showSubline();

          //鼠标移动
          document.onmousemove = function (e) {
            if (isDown === false) {
              return;
            }
            //获取x和y
            let nx = e.clientX;
            let ny = e.clientY;
            //计算移动后的左偏移量和顶部的偏移量
            let nl = nx - (x - l);
            let nt = ny - (y - t);
            el.style.left =
              nl <= 0 ? 0 : nl >= 856 - width ? 856 - width : nl + "px";
            el.style.top =
              nt <= 0 ? 0 : nt >= 1211 - height ? 1211 - height : nt + "px";
          };
          //鼠标抬起事件
          document.onmouseup = function (e) {
            //开关关闭
            isDown = false;
            el.style.cursor = "default";
            document.onmousemove = null;
            document.onmouseup = null;
            binding.value.hideSubline();
          };
        };
      },
    },
  },
  methods: {
    closeProduct() {},
    //显示辅助线
    showSubline() {
      this.isShowSubline = true;
    },
    hideSubline() {
      this.isShowSubline = false;
    },
    toGetCustomerList() {
      this.$api.getOwnerCustomerList({ pageSize: 1000 }).then((res) => {
        // this.currentPage = res.current_page;
        this.customArr = res.data;
      });
    },
    //显示选择商品
    showProduct(data) {
      if (this.checkEdit) return;
      let formRight = document.getElementById("formRight");
      let scrollTop = formRight.scrollTop;
      this.productIndex = data.index;
      if (data.isRecommend) {
        this.productTop = data.top;
      } else {
        this.productTop = data.top + scrollTop;
      }

      this.isShowProduct = true;
    },
    //选择客户
    changeCustom(data) {
      const customer = this.customArr.find((c) => c.user_id == data.id);
	  console.log(customer);
      this.customer_id = customer.user_id;
      this.formData["customer_id"] = customer.user_id;
	  const values = this.formData.formPage.userMsg.tableData.data;
      values[2].detail.value = customer.address;
      values[3].detail.value = customer.user_mobile;
      values[4].detail.value = customer.user_email;
      this.$emit("getCustom", customer);
    },
    //提交选中商品
    submitProduct(data) {
      let item = data.data;
      if (this.formType === 3) {
        Object.keys(item[0]).forEach((val, key) => {
          this.recommendList[this.productIndex][val] = item[0][val];
        });
      } else {
        this.formData.formPage.product.tableData.splice(
          this.productIndex,
          1,
          ...item
        );
      }

      this.isShowProduct = false;
    },
    //关闭弹窗
    closeDialog() {
      this.isShowProduct = false;
    },
    //设置总数
    setAmount(data) {
      let index = data.index;
      this.formData.formPage.product.tableData[index].amount = data.amount;

      this.$set(
        this.formData.formPage.product.tableData,
        index,
        this.formData.formPage.product.tableData[index]
      );
      this.setTotal();
    },
    //设置总价
    setTotal() {
      let total = this.formData.formPage.product.tableData.reduce(
        (total, item) => {
          return total + item.amount;
        },
        0
      );
      let totalData = this.formData.formPage.product.totalData;
      totalData.subTotal = total;
      totalData.discount =
        totalData.discount > 100
          ? 100
          : totalData.discount == ""
          ? 0
          : totalData.discount;
      totalData.grandTotal =
        Math.floor((total - total * (totalData.discount / 100)) * 100) / 100;
    },

    //添加数据
    addProductRow() {
      this.formData.formPage.product.tableData.push({
        style_number: "",
        img_url: "",
        intro: "",
        size: "",
        color: "",
        number: "",
        price: "",
        amount: "",
      });
    },
    //移除数据
    removeProduct(data) {
      let index = data.index;
      this.formData.formPage.product.tableData.splice(index, 1);
      this.setTotal();
    },
    //选中表单
    chooseTable(val, key) {
      this.formIndex = key;
    },
    //监听信息
    resizeMsg(element) {
      this.pageBottom = element.offsetHeight + 55;
    },
    getHeaderHieght(element) {
      this.pageHeader = element.offsetHeight;
    },
    //监听表格
    resizeTable(element) {
      let height =
        element.offsetTop + element.offsetHeight + 40 + this.pageBottom;
      that.$emit("getTop", {
        offsetTop: element.offsetTop,
        pageBottom: this.pageBottom,
        pageHeader: this.pageHeader,
      });
      if (height >= 1634 && this.checkCreate) {
        this.checkCreate = false;
        that.$emit("createPage");
      }
    },
    addProduct() {
      this.tableData.push({
        style: "",
        picture: "",
        description: "",
        size: "",
        color: "",
        number: "",
        price: "",
        amount: "",
      });
    },
    addRow(data) {
      let index = data.index;
      let props = this.formData.formPage[index].tableData.props;
      let firstData = this.formData.formPage[index].tableData.data[1];
      let obj = {};
      props.forEach((val) => {
        let type = firstData[val.prop].type;
        obj[val.prop] = { value: "", type };
      });
      this.formData.formPage[index].tableData.data.push(obj);
    },
  },
};
</script>

<style lang="scss">
.form-right-page {
  margin: 0 auto;
  /*width: 856px;*/
  width: 1155px;
  /*min-height: 1634px;*/
  /*min-height: 1211px;*/
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
  position: relative;
  overflow: visible !important;
  /*overflow: hidden;*/
  margin-bottom: 60px;
  .el-card__body {
    padding: 0 !important;
  }
  .el-input__inner {
    border: none !important;
    /*border-bottom:1px solid #c0c4cc!important;*/
    border-radius: 0 !important;
    padding: 0 4px !important;
    background-color: transparent !important;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 30px !important;
  }
  .el-table {
    user-select: none !important;
  }
  .page-all-form {
    padding: 0 40px;
  }
  .page-center {
    margin-bottom: 30px;
  }
  .page-msg-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .page-product-box {
    margin-top: 40px;
    width: 100%;
  }
  .page-payment-box {
    width: 532px;
    margin-bottom: 18px;
  }
  .subline {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    border-left: 1px dashed $base-config-color;
  }
  .module-position {
    position: absolute;
    z-index: 1001;
    box-sizing: content-box;
    .move-box {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      background-color: $base-config-color;
    }
  }
  .module-position-active {
    border: 1px dashed $base-config-color;
    display: block;
    z-index: 9999;
  }

  .cellBg {
    background-color: #ebf8f9;
  }
  .recommend-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
  }
  .form-text-color {
    color: $base-config-color;
  }
  .form-text-weight {
    font-weight: 600;
  }
  .form-footer {
    height: 14px;
    position: absolute;
    bottom: 28px;
  }
}
</style>

<template>
    <div class="review" @click="hideBorder">
        <div id="reviewPage" class="review-box">
            <div class="review-table-page" id="reviewTablePage">
                <reviewHeader ref="header" :svgData="svgData" :sn="sn" :bomUrl="bomUrl"></reviewHeader>
                <reviewMiddle ref="middle" :table="table" :remark="remark" :checkShowRemark="!isShowMorePage" @getExtraRightArr="getExtraRightArr"  @getExtraLeftArr="getExtraLeftArr"></reviewMiddle>
            </div>
            <div class="review-table-page mt-20" v-if="isShowMorePage">
                <div class="middle">
                    <reviewTable :data="leftArr"></reviewTable>
                    <reviewTable v-if="rightArr.length>0" :data="rightArr"></reviewTable>
                </div>
                <div class="remark-box">
                    <div class="remark-title-box">
                        {{$t("remark")}}
                    </div>
                    <div class="remark-text-box" @click.stop="toEditRemark">
                        <span v-if="!isCanEdit">{{remark}}</span>
                        <el-input ref="remarkInput" v-model="remark" size="mini" v-else></el-input>
                    </div>
                </div>
            </div>
           <reviewRemark :svgUrl="svgUrl" :isShowSlider="isShowSlider"></reviewRemark>
            <div v-if="remarkList.length>0">	
<!--                <reviewRemark v-for="(val,key) in remarkList" :svgUrl="val.cover_pic" :isShowSlider="isShowSlider" :key="key"></reviewRemark>-->
                <div class="remark-page  mt-20" v-for="(val,key) in remarkList" :key="key">
                    <div class="remark-image-box" v-for="(item,index) in val" :key="index">
                        <img :src="item.cover_pic" class="remark-image" alt="">
                    </div>
                </div>
            </div>

        </div>
       <el-button style="position: fixed;right: 120px;top: 20px" @click="exportPdf">{{$t("exportPdf")}}</el-button>
        <el-button style="position: fixed;right: 30px;top: 20px" @click="backPage">{{$t("back")}}</el-button>
    </div>
</template>

<script>
    import reviewHeader from '@/components/review/review-header.vue'
    import reviewMiddle from '@/components/review/review-middle.vue'
    import reviewRemark from "../../components/reviewRemark/reviewRemark";
    import html2pdf from "../../utils/html2pdf";
    import reviewTable from "@/components/reviewTable/reviewTable";
    import {mapState} from 'vuex'
    let that
    export default {
        name: "index",
        components:{
            reviewHeader,reviewMiddle,reviewRemark,reviewTable
        },
		props:{
			data:{
				type:Object,
				default:{}
			}
		},
        data(){
            return{
                clientWidth:100,
                scale:1,
                svgUrl:'',
                bomUrl:'',
                svgData:{},
                table:[],
                remarkList:[],
                remark:'',
                sn:'',
                isShowSlider:true,
                isShowMorePage:false,
                leftArr:[],
                rightArr:[],
                isCanEdit:false,
                remarkPageLength:0,
                remarkImageList:[],
                onePage:4,
            }
        },
        computed:{
            ...mapState('jiyang/config', [
                'cosUrl'
            ]),
        },
        async created(){
            that=this
            let data=JSON.parse(localStorage.getItem('review'))
			//let data = this.data;
            this.svgData=data.pageData
            this.sn=data.sn
            this.table=data.allData
            this.remark=data.remark.remark
            this.remarkList=this.getRemarkListPage(data.remark.textureRemarkList,this.onePage)
            this.svgUrl=await this.getImageBlob(data.svg_img)
            this.bomUrl=await this.getImageBlob(data.bom_img)
        },
		watch:{
			data:{
				handler:async function(val){
					let data = val;
					this.svgData=data.pageData
					this.sn=data.sn
					this.table=data.allData
					//this.remark=data.remark.remark
					//this.remarkList=this.getRemarkListPage(data.remark.textureRemarkList,this.onePage)
					this.svgUrl=await this.getImageBlob(data.svg_img)
					this.bomUrl=await this.getImageBlob(data.bom_img)
				},
				 deep: true,
			}
		},
        mounted() {
            let clientHeight=document.documentElement.clientHeight
            this.clientWidth=clientHeight*(210/297)
            this.scale = this.populateIframeScale();

        },
        methods:{
            hideBorder(){
                this.$refs.header.clickPage()
                this.$refs.middle.clickPage()
                this.isCanEdit=false
            },
            getExtraLeftArr(data){
                this.leftArr=data
                this.isShowMorePage=true
            },
            getExtraRightArr(data){
                this.rightArr=data
                this.isShowMorePage=true
            },
            //获取备注图片页数
            getRemarkListPage(data, num) {
                let index = 0
                let array = []
                while(index < data.length) {

                    array.push(data.slice(index, index += num));
                }
                return array ;
            },
            //获取图片blob
            getImageBlob(url) {
                let that=this
                return new Promise(resolve => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.responseType = 'blob';
                    xhr.onload =async function () {
                        if (parseInt(this.status, 10) === 200) {
                            that.blobToDataURI(this.response,function (urls) {
                                resolve(urls)
                            })

                        }
                    };
                    xhr.send();
                })
            },
            blobToDataURI(blob, callback) {
                let that=this
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function (e) {
                    callback(e.target.result);
                }
            },

            //是否编辑备注
            toEditRemark(){
                this.isCanEdit=true
                setTimeout(()=>{
                    this.$refs.remarkInput.focus()
                },10)

            },
            //导出pdf
            exportPdf(){
                let that=this
                let loading=this.openLoading()
                that.isShowSlider=false
                this.hideBorder()
                window.pageYoffset = 0;
                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
                setTimeout(()=>{
                    let doc=document.getElementById('reviewPage')
                    let less=20

                    if(this.remarkList.length>0){
                        less+=this.remarkList.length*20
                    }
                    html2pdf(doc,this.sn,less,this.isShowMorePage).then(res=>{
                        that.isShowSlider=true
                        loading.close()
                    })
                },20)

            },
            backPage(){
                this.$router.back(-1)
            },
            //获取DPI
            js_getDPI() {
                var arrDPI = new Array();
                if (window.screen.deviceXDPI != undefined) {
                    arrDPI[0] = window.screen.deviceXDPI;
                    arrDPI[1] = window.screen.deviceYDPI;
                } else {
                    var tmpNode = document.createElement("DIV");
                    tmpNode.style.cssText =
                        "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                    document.body.appendChild(tmpNode);
                    arrDPI[0] = parseInt(tmpNode.offsetWidth);
                    arrDPI[1] = parseInt(tmpNode.offsetHeight);
                    tmpNode.parentNode.removeChild(tmpNode);
                }
                return arrDPI;
            },
            // 转换单位
            js_convertMm() {
                const dpiArray = this.js_getDPI();
                let templateArray = [];
                //转换的原理可以百度一下， 260和360是设置的div宽高
                let px = (260 * dpiArray[0]) / 25.4;
                let py = (360 * dpiArray[1]) / 25.4;
                templateArray[0] = px;
                templateArray[1] = py;
                return templateArray;
            },
            // 计算缩放比
            populateIframeScale() {
                const winWidth = window.innerWidth - 50;
                const winHeight = window.innerHeight - 50;
                let templateSize = this.js_convertMm();
                const widthScale = Number((winWidth / templateSize[0]).toFixed(2));
                const heightScale = Number((winHeight / templateSize[1]).toFixed(2));
                if (
                    (widthScale <= 0.85 || heightScale <= 0.85) &&
                    widthScale <= heightScale
                ) {
                    return widthScale;
                } else if (
                    (widthScale <= 0.85 || heightScale <= 0.85) &&
                    widthScale > heightScale
                ) {
                    return heightScale;
                    //如果计算得到的比例大于0.85，则按0.85的缩放比显示，因为预览的下面还有关闭按钮，需要预留一定的空间
                } else if (widthScale > 0.85 && heightScale > 0.85) {
                    return 0.85;
                }
                return 1;
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "review";
</style>

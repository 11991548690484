<template>
  <div class="form-lists">
    <!--    <div class="form-header"></div>-->
    <div class="form-center">
      <formLeftMenu
        @getList="getList"
        @resetList="resetList"
        :type="formType"
      ></formLeftMenu>
      <div class="form-right-box" id="formRight">
        <formRight
          ref="formRight"
          :type="formType"
          :isCreate="isCreate"
          :createProduct="createProduct"
        ></formRight>
      </div>
    </div>
  </div>
</template>

<script>
import formLeftMenu from "../../components/lists/formLeftMenu";
import formRight from "../../components/lists/formRight";
import { mapMutations } from "vuex";
export default {
  name: "index",
  components: {
    formLeftMenu,
    formRight,
  },
  data() {
    return {
      formType: 1,
      isCreate: false,
      createProduct: {},
    };
  },
  created() {
    this.setMenu(4);
    if (this.$route.query.type) {
      this.formType = Number(this.$route.query.type);
      this.setFormState(this.formType);
    }
    this.$nextTick(() => {
      if (this.$route.query.isCreate) {
        this.$refs.formRight.isCreate = true;
        this.createProduct = JSON.parse(this.$route.query.product);
      } else {
        this.$refs.formRight.isCreate = false;
        this.createProduct = {};
      }
      if (this.$route.query.isDetail) {
        this.$refs.formRight.isDetail = true;
      } else {
        this.$refs.formRight.isDetail = false;
      }
    });
  },
  methods: {
    ...mapMutations("jiyang/menu", ["setMenu"]),
    ...mapMutations("jiyang/form", ["setFormState"]),
    getList(data) {
      this.formType = data.type;
	  this.$refs.formRight.getFormList(this.formType);
    },
    resetList() {
      this.$refs.formRight.resetFormList();
    },
  },
};
</script>

<style lang="scss">
@import "lists";
</style>

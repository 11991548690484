<template>
  <div class="product-card pointer mr-28" :class="selected ? 'selected' : null">
    <div class="card-body card-body-texture flex just-center align-center relative">
      <span class="type">{{ typeName }}</span>
      <!-- <i
        class="iconfont check font-size-20"
        :class="selected ? 'icon-check' : 'icon-check1'"
        @click="handleClick"
      >
      </i> -->
      <i class="iconfont icon-fenxiang check font-size-20" @click="handleClick">
      </i>

      <div class="main-pic">
        <img class="img_texture" :src="src" alt="pic" />
      </div>
    </div>
    <div class="card-info card-info-texture flex flex-col p-22">
      <span class="title font-size-12 mb-6">{{ name }}</span>
      <span class="desc mb-6">{{ desc }}</span>
      <span class="price mb-6">${{ price }}</span>
    </div>
    <div class="card-footer flex align-center">
      <div
        class="flex flex-1 quickshop just-center align-center"
        @click="handleQuickShop"
      >
        <!-- {{ $t("quickshop") }}  -->
        <span class="text-white label">{{ $t(label) }}</span>
      </div>
      <div
        class="box flex just-center align-center"
        @click="handleClickLike"
        v-if="favicon"
      >
        <i
          class="iconfont icon-shoucangxuanzhong design-is-like"
          v-if="like"
        ></i>
        <i class="iconfont icon-shoucang design-like" v-else></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "WOMEN",
    },
    label: {
      type: String,
      default: "quickShop",
    },
    desc: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "000000",
    },
    src: {
      type: String,
      default:
        "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.25484010499150321634805740.676.png",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    like: {
      type: Boolean,
      default: false,
    },
    favicon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: this.checked,
    };
  },
  created() {},
  computed: {
    typeName() {
      return this.type == 1 ? "TEXTURE" : "MATERIAL";
    },
  },
  methods: {
    handleClick() {
      this.selected = !this.selected;
      this.$emit("change", this.id, this.name, this.type, this.src);
    },
    handleQuickShop() {
      this.$emit("bottom", { id: this.id, type: this.type });
    },
    handleClickLike() {
      let t = this.like;
      this.$emit("update:like", !t);
      this.$emit("like", !t);
    },
  },
};
</script>

<style lang="scss">
.product-card {
  width: 234px;
  height: 283px;
  background: #ffffff;
  border: 1px solid #e2dede;
  transition: all 0.5s ease;
  display: inline-block;
  margin-bottom: 10px;
  .design-is-like {
	color: #ffe356;
  }
  .card-body-texture {
	width: 232px;
	height: 200px !important;
	background: #ffffff;
	border-bottom: 1px solid #e2dede;
	padding: 10px 4px;
	opacity: 1;

	.check {
	  width: 20px;
	  height: 20px;
	  position: absolute;
	  top: 13px;
	  right: 10px;
	}
	.type {
	  width: 36px;
	  font-size: 12px;
	  font-family: STHeitiSC;
	  font-weight: 500;
	  color: #323131;
	  position: absolute;
	  top: 16px;
	  left: 24px;
	}
	.icon-check {
	  color: var(--primary-color-v2);
	}
  }
  .main-pic {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.img_texture {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }
  .title {
	font-weight: 600;
	color: #2f2e2e;
  }
  .desc {
	width: 100%;
	font-size: 10px;
	font-weight: 400;
	color: #4d4d4d;
  }
  .price {
	width: 100%;
	font-size: 12px;
	color: #000000;
  }
  .card-info-texture {
	width: 100%;
	height: 50px !important;
  }
  .card-footer {
	width: 100%;
	height: 32px;
	position: relative;
	.quickshop {
	  // opacity: 0;
	  transition: all 0.5s ease;
	}

	.label {
	  // padding-left: 28px;
	}
	.box {
	  width: 31px;
	  height: 30px;
	  background: #fff;
	  position: absolute;
	  right: 1px;

	  // border-left: 1px solid #e2dede;
	  // border-top: 1px solid #e2dede;
	  .love {
		width: 20px;
		height: 20px;
		+ .like {
		  fill: #f34747;
		}
	  }
	}
  }
  &:hover {
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.09);
	transform: translate3d(0, 0, 4px) scale(1.02, 1.02);
	border: 1px solid var(--primaryColorV2);
	.card-body {
	  border-bottom: 1px solid var(--primaryColorV2);
	}
	// .box {
	//   border-left: 1px solid var(--primaryColorV2);
	//   border-top: 1px solid var(--primaryColorV2);
	// }
	.card-footer {
	  background: #21c3a9;
	  color: #fff;
	  opacity: 1;
	  .design-like {
		color: #2c3e50;
	  }
	}
  }
}
.product-card.selected {
  border: 1px solid var(--primaryColorV2);
  .card-body {
	border-bottom: 1px solid var(--primaryColorV2);
  }
  .box {
	border-left: 1px solid var(--primaryColorV2);
	border-top: 1px solid var(--primaryColorV2);
  }
}

</style>
<template>
  <VEmojiPicker
    @select="selectEmoji"
    v-if="visible"
    emojiSize="18"
    :showSearch="false"
    :emojisByRow="10"
    v-click-outside.stop.prevent.capture="hide"
  />
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
export default {
  components: {
    VEmojiPicker,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit("select", emoji);
      this.emit();
    },
    hide() {
      this.emit();
    },
    emit() {
      //��ʱ����emoji  //�������غ� �������� ������������
      let _this = this;
      setTimeout(function() {
        _this.$emit("update:visible", false);
      }, 0);          
    },
  },
};
</script>

<style>
</style>
<template>
  <button
    class="radius-4 font-size-18 p-8 outline border-none pointer w-100"
    :class="type"
    :style="{
      backgroundColor: color,
    }"
    @click="handleClick"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style></style>

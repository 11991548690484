<template>
  <div>
    <a-button
      class="pick-btn"
      @click="showImagePick = true"
      :disabled="disabled"
      >{{$t('pickTexure')}}</a-button
    >
    <div class="image-pick__preview" v-if="visible && !disabled">
      <div>
        <img class="img-preview" :src="img.coverpic || img.url" alt="" />
        <span>{{ img.name }}</span>
      </div>
      <a-icon type="delete" @click="handleDel" />
    </div>
    <image-modal :visible.sync="showImagePick" @ok="handleOk"></image-modal>
  </div>
</template>

<script>
import ImageModal from "@/components/ImageModal";
export default {
  components: {
    ImageModal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      default: () => ({
        id: 1,
        coverpic:
          "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.59003942783389341632710816.884.png",
        url: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.59003942783389341632710816.884.png",
        name: "texture",
      }),
    },
  },
  data() {
    return {
      showImagePick: false,
      visible: false,
      img: Object.assign(
        {
          id: 1,
          coverpic:
            "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.59003942783389341632710816.884.png",
          url: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.59003942783389341632710816.884.png",
          name: "texture",
        },
        this.image
      ),
    };
  },
  methods: {
    handleDel() {
      this.visible = false;
      this.emit();
    },
    handleOk(image) {
      this.img = image;
      this.visible = true;
      this.emit();
    },
    emit() {
      if (this.visible) {
        this.$emit("ok", this.img);
      } else {
        this.$emit("ok");
      }
    },
  },
};
</script>

<style lang="scss">
.image-pick__preview {
  margin-top: 8px;
  padding: 8px;
  height: 66px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000a6;
  .img-preview {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
}
</style>
export default ({ service, request }) => ({
// 用户登录
  mobileLogin (data) {
    return request({
      url: '/v1/user/pwdLogin',
      method: 'post',
      data
    })
  },
//  获取验证码
  getCode(params) {
    return request({
      url: '/v1/user/getCode',
      method: 'post',
      params
    })
  },
//  用户注册
  register (data) {
    return request({
      url: '/v1/user/registryCustomer',
      method: 'post',
      data
    })
  },
  //重置密码
  resetPwd(data){
	return request({
	  url: '/v1/user/resetPwd',
	  method: 'post',
	  data
	})
  }
})

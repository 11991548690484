<template>
  <a-drawer
    :wrapClassName="wrapClassName"
    :title="title"
    :width="width"
    :visible="visible"
    :destroyOnClose="true"
    :mask="mask"
    :maskClosable="true"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleClose"
  >
    <a-form :layout="formLayout">
      <a-form-item :label="$t('labelTextureName')">
        <a-input v-model="name"></a-input>
      </a-form-item>
      <!-- <a-form-item :label="$t('labelTextureNumber')">
        <a-input v-model="number"></a-input>
      </a-form-item> -->
      <a-form-item :label="$t('labelTextureType')">
        <a-select @change="handleSelectChange" v-model="textureType">
          <a-select-option
            :value="opt.id"
            v-for="(opt, idx) in textureSelectOption"
            :key="idx"
            >{{ opt.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
	  <a-form-item label="纹理类型">
		  <a-select @change="handleSelectChange" v-model="textureType2">
		    <a-select-option
		      :value="opt.id"
		      v-for="(opt, idx) in textureSelectOption2"
		      :key="idx"
		      >{{ opt.name }}
		    </a-select-option>
		  </a-select>
	  </a-form-item>
	  <a-form-item label="材料类型">
		  <a-select @change="handleSelectChange" v-model="textureType3">
			<a-select-option
			  :value="opt.id"
			  v-for="(opt, idx) in textureSelectOption3"
			  :key="idx"
			  >{{ opt.name }}
			</a-select-option>
		  </a-select>
	  </a-form-item>
      <a-form-item class="image" :label="$t('texture')">
        <upload
          :fileCallBack="fileCallBack"
          :beforeUpload="handleBeforeUpload"
        ></upload>
      </a-form-item>
    </a-form>
    <div class="big-preview mask" v-if="preview">
      <img :src="current.src" alt="preview" />
      <i class="iconfont icon-icon-test" @click="handleBigClose"></i>
    </div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="handleClose">
        {{ $t("cancel") }}
      </a-button>
      <a-button type="primary" @click="handleOk"> {{ $t("ok") }}</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("jiyang/material");
import upload from "@/components/Upload";
export default {
  components: {
    upload,
  },
  props: {
    wrapClassName: {
      type: String,
      default: "drawer",
    },
    visible: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      default: "Texture",
    },
    mask: {
      default: true,
      type: Boolean,
    },
    width: {
      type: Number,
      default: 420,
    },
    value: {
      default: () => {},
      type: Object,
    },
    current: {
      type: Object,
      default: () => {},
    },
    textureType: {
      type: Number,
      default: 5,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: null,
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formLayout: "horizontal",
      imageUrl: "",
      preview: false,
      // name: "",
      type: "",
      fileUrl: "",
      number: "",
      //textureType: 5,
      textureTypeMap: null,
	  textureSelectOption2:[
		  {
			  id:1,
			  name:'纺织'
		  }
	  ],
	  textureSelectOption3:[
		  {
			  id:1,
			  name:'方形'
		  }
	  ],
	  textureType2:1,
	  textureType3:1
    };
  },
  created() {
    this.textureTypeMap = new Map([
      ["color", "color"],
      ["nrm", "nrm"],
      ["occ", "occ"],
    ]);
  },
  computed: {
    ...mapState(["textureSelectOption"]),
  },
  methods: {
    ...mapActions(["saveTexture"]),
    handleSelectChange(opt) {
      this.type = this.textureSelectOption.find((t) => t.id == opt);
      this.cat_id = opt;
    },
    handleBeforeUpload(file) {
      let names = file.name.split("_");
      if (this.number.trim() == "") {
        this.number = names[0];
      }
      names.forEach((name) => {
        if (this.textureTypeMap.get(name.toLowerCase())) {
          this.textureType = this.textureTypeMap.get(name);
        }
      });
      return true;
    },
    fileCallBack(file) {
      this.fileUrl = file[0];
    },
    handleOk() {
      if (this.id) {
        this.saveTexture({
          name: this.name,
          url: this.url,
          type: this.textureSelectOption.find((t) => t.id == this.textureType),
          cat_id: this.textureType,
          id: this.id,
        });
      } else {
        this.saveTexture({
          name: this.name,
          url: this.fileUrl,
          type: this.type,
          cat_id: this.textureType,
          id: this.id,
        });
      }

      this.$emit("update:visible", false);
      this.init();
    },
    handleClose() {
      this.init();
      this.$emit("update:visible", false);
    },
    init() {
      this.name = "";
      this.url = "";
      this.type = "";
      this.textureType = 5;
      this.number = "";
    },
  },
};
</script>

<style lang="scss">
.textureeditor {
  .preview {
    width: 100px;
    height: 100px;
  }
}
.big-preview {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 0.2 ease;
  > img {
    width: 400px;
    object-fit: contain;
  }
  > .iconfont {
    margin-top: 16px;
    color: #fff;
  }
}
</style>

<template>
  <div class="neko-input base-height" :style="{ width: width }">
    <i class="required" v-if="required"></i>
    <input
      class="base-border"
      type="text"
      :value="value"
      @input="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: "n-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    width: {
      default: "",
      type: String,
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss">
.neko-input {
  display: flex;
  > input {
    width: 100%;
    height: 100%;
    outline: none;
    &:focus {
      border-color: var(--forceColor);
    }
  }
}
</style>

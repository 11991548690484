<template>
  <component
    :is="material"
    @mat-change="emit"
    :value="value"
    :materialData="materialData"
  ></component>
</template>

<script>
import mixin from "@/components/material/mixin";
export default {
  mixins: [mixin],
  props: {
    material: {
      default: "Metal",
      type: String,
    },
    value: {
      default: () => {},
      type: Object,
    },
    materialData: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style></style>

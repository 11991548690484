import { render, staticRenderFns } from "./CartIndicator.vue?vue&type=template&id=0371f3ea"
import script from "./CartIndicator.vue?vue&type=script&lang=js"
export * from "./CartIndicator.vue?vue&type=script&lang=js"
import style0 from "./CartIndicator.vue?vue&type=style&index=0&id=0371f3ea&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
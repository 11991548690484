export default ({ service, request }) => ({
// 获取Bom列表
    getBomtable (params) {
        return request({
            url: `/v1/bomtable`,
            method: 'get',
            params
        })
    },
//    绑定3D
    bindThreeD(params) {
        return request({
            url: `/v1/bomTable/bind/${params.id}`,
            method: 'put',
            params
        })
    },
//    AI搜图列表
    getDesignImageList(data) {
        return request({
            url: `/v1/product/identifySearch`,
            method: 'post',
            data
        })
    },
})


<template>
  <div class="chat" v-show="isshow">
    <audio src="@/assets/sound/tishi.mp3" ref="imaudio" />
    <div
      id="box"
      class="
        chat-mini
        box-shadow-light
        border
        flex
        just-space-between
        align-center
        font-size-18 font-700
      "
      @mousedown="move"
      @click.stop="handleClick(2)"
      v-show="!showConversation"
      key="1"
    >
      <span class="icon text-info-color">
        <img
          draggable="false"
          class="chat_img"
          src="../../../public/liaotian.png"
        />
      </span>
    </div>
    <conversation
      :visible="showConversation"
      key="2"
      @newmessage="newmessage"
      @playaudio="playaudio"
    ></conversation>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapMutations: mapMutationsGroup, mapState: mapStateGroup } =
  createNamespacedHelpers("jiyang/im");
import Conversation from "./conversation/conversation";
export default {
  components: {
    Conversation,
  },
  computed: {
    ...mapStateGroup(["showConversation"]),
  },
  data() {
    const tW = 800;
    const tH = 800;
    return {
      // showConversation: true,
      isMouseMove: false,
      //icons: ["icon-ellipsis", "icon-bianji", "icon-jiantoushang"],
      icons: ["", "", "icon-jiantoushang"],
      havenewmessge: false,
      isshow: true,
    };
  },
  created() {
    let that = this;
    //点击聊天窗口外区域隐藏聊天窗
    // document.addEventListener("click", (e) => {
    //   if (!this.showConversation) return;
    //   if (!that.$el.contains(e.target)) {
    //     that.hidden();
    //   }
    // });
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.meta.isshowIM == undefined || val.meta.isshowIM) {
          this.isshow = true;
        } else this.isshow = false;
      },
    },
  },
  methods: {
    ...mapMutationsGroup(["setShowConversation"]),
    eHandler(data) {
      this.width = data.width;
      this.height = data.height;
      this.left = data.left;
      this.top = data.top;
      this.event = data.eventName;
    },
    handleClick(idx) {
      if (idx == 0) {
        return;
      }
      if (idx == 1) {
        return;
      }
      if (idx == 2 && this.isMouseMove == false) {
        this.show();
        return;
      }
      // if (idx == 2) {
      //   this.setShowConversation(true);
      // }
      this.isMouseMove = false;
    },

    move(e) {
      var box = document.getElementById("box");
      let odiv = e.target; //获取目标元素
      //算出鼠标相对元素的位置
      // let disX = e.clientX - odiv.offsetLeft;

      //let disY = e.clientY - odiv.offsetTop;

      //console.log(odiv.offsetLeft);

      //odiv.style.position = 'absolute';
      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        this.isMouseMove = true;
        let left = e.clientX - 30;
        let top = e.clientY - 30;

        //移动当前元素
        box.style.left = left + "px";
        box.style.top = top + "px";
        return false;
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },

    show() {
      this.setShowConversation(true);
      this.havenewmessge = false;
    },
    hidden() {
      this.setShowConversation(false);
    },
    newmessage() {
      this.havenewmessge = true;
    },
    playaudio() {
      //todo
      // this.$refs.imaudio.play();
    },
  },
  filters: {
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    },
  },
};
</script>

<style lang="scss">
.resizable-content {
  height: 100%;
  width: 100%;
  background-color: aqua;
}
.resizable {
  position: absolute !important;
}
.chat-expand-enter-active {
  transition: height 0.2s linear;
}

.chat-icon {
  color: #f80000;
  position: absolute;
}
.chat {
  .chat-mini {
    z-index: 999;
    position: fixed;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 2px solid #edeaea;
    background: #fff;
    font-family: Helvetica;
    color: #000000;
    right: 1%;
    bottom: 30%;
    padding: 0 20px;
  }
  .chat_img {
    pointer-events: none;
    width: 30px;
    height: 30px;
    margin-left: -2px;
  }
}
</style>

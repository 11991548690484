// import * as BABYLON from '@babylonjs/core';
// import "@babylonjs/loaders/OBJ";
// import "@babylonjs/loaders/STL";
// import "@babylonjs/loaders/glTF";
import * as commonFn from "./common.js";

/* 3D场景类 */
var ThreeDScene = /** @class */ (function () {
  function ThreeDScene(config) {
    this.canvas = null;
    this.engine = null;
    this.scene = null;
    this.camera = null;
    this.camera2 = null;
    this.hdrTexture = null;
    this.meshes = null;
    this.objMeshes = {};
    this.pickedMesh = null;
	this.pickedMeshArr = [];
    this.pickResult = null;
    this.container = null;
    this.pointer = {};
    this.logos = {};
    this.wd = null;
    this.light = null;
    this.ground = null;
    this.sign = {};
    this.highlight = null; //高亮
	this.origin = window.location.origin;
	this.isPath = false;
	this.logoEdit = false;
	this.kbInfo = {
		shift:false
	};
	this.activeTime = null;
	this.historyData = {
		index:0,
		step:[],
		mesh:{},
		init:{}
	};
	this.partList = [];
	this.mergeMeshes = {};
	//this.mergeMesh = null;
	this.partArr = {};
	this.isRotation = false;
    this.config = {
      canvas: null,
      boxId: "boxId",
      canvasId: null,
      hdrTexture: null,
      hemiLight: {
        paramType: "object",
        intensity: 0.9,
        position: { x: 0, y: 1, z: 0 },
      },
      scene: {
        paramType: "object",
        clearColor: { r: 1, g: 1, b: 1, a: 0 },
        ground: false,
      },
      camera: {
        setPosition: null,
        lowerRadiusLimit: null,
        upperRadiusLimit: null,
        attachControl: null,
      },
      material: {
        paramType: "object",
        meshes: {
          cap: null,
        },
        initPBR: {
          metallic: 0,
          roughness: 1,
          albedoColor: { r: 0.7, g: 0.7, b: 0.7 },
          reflectionColor: { r: 1, g: 1, b: 1 },
          environmentIntensity: 0.8,
          reflectionTexture: {},
        },
      },
      meshes: {
        size: 200,
      },
      css: {
        width: null,
        height: null,
      },
      screenShot: {
        width: 1024,
        height: 900,
      },
    };

    this.loadConfig(config);
    this.createScene();
  }

  ThreeDScene.prototype = {
    /**
     * 加载配置数据
     * @param{Object}
     */
    loadConfig: function (data) {
      let _this = this;
      Object.keys(this.config).forEach(function (key) {
        if (data[key]) {
          if (
            _this.config[key] != null &&
            typeof _this.config[key] == "object" &&
            _this.config[key].paramType == "object"
          ) {
            Object.keys(_this.config[key]).forEach(function (key2) {
              if (data[key][key2]) {
                _this.config[key][key2] = data[key][key2];
              }
            });
          } else {
            _this.config[key] = data[key];
          }
        }
      });
    },
    /**
     *创建3D场景
     * @param{String}
     */
    createScene: function () {
      let _this = this;
      let data = this.config;
      Object.keys(data).forEach(function (key) {
        if (data[key] == null) {
          return;
        }
        switch (key) {
          case "boxId":
            if (_this.config.canvas) {
              _this.canvas = _this.config.canvas;
            } else if (data[key]) {
              _this.canvas = document.createElement("canvas");
			  console.log(data[key]);
              document.getElementById(data[key]).appendChild(_this.canvas);
            } else {
              _this.canvas = document.createElement("canvas");
              document.body.appendChild(_this.canvas);
            }
            if (_this.config.canvasId) _this.canvas.id = _this.config.canvasId;

            if (_this.config.css.width)
              _this.canvas.style.width = _this.config.css.width;
            if (_this.config.css.height)
              _this.canvas.style.height = _this.config.css.height;
            _this.canvas.onfocus = function () {
              this.style.border = 0;
              this.style.outline = "none";
            };

            _this.engine = new BABYLON.Engine(_this.canvas, true, {
              stencil: true,
              doNotHandleContextLost: true,
            });
            _this.engine.enableOfflineSupport = false;
            _this.engine.doNotHandleContextLost = true;
            _this.scene = new BABYLON.Scene(_this.engine);
            _this.camera = new BABYLON.ArcRotateCamera(
              "Camera",
              Math.PI / 2,
              Math.PI / 2,
              80,
              BABYLON.Vector3.Zero(),
              _this.scene
            );
            _this.camera2 = new BABYLON.ArcRotateCamera(
              "Camera2",
              Math.PI / 2,
              Math.PI / 2,
              80,
              BABYLON.Vector3.Zero(),
              _this.scene
            );
            _this.highlight = new BABYLON.HighlightLayer("hl", _this.scene); //高亮线条
            _this.blurHorizontalSize = 0.3;
            _this.blurVerticalSize = 0.3;

            _this.light = new BABYLON.DirectionalLight(
              "DirectionalLight",
              new BABYLON.Vector3(0, -1, 0),
              _this.scene
            );
            _this.light.position = new BABYLON.Vector3(0, 100, 0);
            _this.light.intensity = 2;
            // _this.light.shadowMaxZ = 130;
            // _this.light.shadowMinZ = 10;
            _this.scene.ambientColor = new BABYLON.Color3(1, 1, 1);
            // _this.scene.environmentTexture = new BABYLON.CubeTexture.CreateFromPrefilteredData(_this.config.hdrTexture, _this.scene);
            _this.scene.environmentTexture = new BABYLON.HDRCubeTexture(
              "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/texture/startup.hdr?origin="+_this.origin,
              _this.scene,
              128,
              false,
              true,
              false,
              true
            );
            _this.scene.environmentIntensity = 0.3;

            if (_this.config.scene.ground) {
              _this.ground = BABYLON.MeshBuilder.CreateGround(
                "ground",
                { width: 100000, height: 100000 },
                _this.scene
              );
              _this.ground.position.y = -2;
              _this.ground.receiveShadows = true;
              _this.ground.material = _this.getPBR(
                _this.config.material.initPBR
              );
            }

			//_this.engine.setHardwareScalingLevel(0.4);//视图清晰程度
			if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
				_this.engine.setHardwareScalingLevel(0.4);//视图清晰程度
			  }
            new BABYLON.HighlightLayer("dianchi", _this.scene, {
              mainTextureRatio: 3,
            });
			
			let videoNum=0;
            _this.engine.runRenderLoop(function () {
              if (_this.light) {
                _this.light.direction = new BABYLON.Vector3(
                  -_this.camera.position.x,
                  -(_this.camera.position.y + 100) * 6,
                  -_this.camera.position.z
                );
              }
              if (_this.ground && _this.camera.position.y < 0) {
                _this.ground.isVisible = false;
              } else if (_this.ground && _this.camera.position.y > 0) {
                _this.ground.isVisible = true;
              }
			  if(_this.meshes && _this.isRotation){
				  videoNum+=0.005;
				  _this.meshes[0].rotation = new BABYLON.Vector3(0,videoNum,0);
			  }
              _this.scene.render();
            });

            window.addEventListener("resize", function () {
              _this.engine.resize();
            });

            _this.canvas.addEventListener("mouseenter", function (event) {
              if (!_this.canvas.id) {
                new BABYLON.Tools.CreateScreenshotUsingRenderTarget(
                  _this.engine,
                  _this.camera,
                  {
                    width: _this.canvas.width * 5,
                    height: _this.canvas.height * 5,
                  },
                  function (filePath) {
                    document.getElementById("preview").src = filePath;
                  }
                );
              }
            });

            _this.canvas.addEventListener("mouseleave", function () {
              document.getElementById("preview").src = "";
            });

            break;
          case "hdrTexture":
            // _this.hdrTexture =
            //   new BABYLON.CubeTexture.CreateFromPrefilteredData(
            //     data[key],
            //     _this.scene
            //   );
            break;
          case "hemiLight":
            // let position_light = new BABYLON.Vector3(data[key].position.x, data[key].position.y, data[key].position.z)
            // let light = new BABYLON.HemisphericLight("HemiLight", position_light, _this.scene);
            // if(data[key].intensity)light.intensity = data[key].intensity;
            break;
          case "scene":
            if (data[key].clearColor) {
              let clearColor = data[key].clearColor;
              _this.scene.clearColor = new BABYLON.Color4(
                clearColor.r,
                clearColor.g,
                clearColor.b,
                clearColor.a
              );
            }
            break;
          case "camera":
            if (data[key].setPosition) {
              let setPosition = data[key].setPosition;
              _this.camera.setPosition(
                new BABYLON.Vector3(setPosition.x, setPosition.y, setPosition.z)
              );
              _this.camera2.setPosition(
                new BABYLON.Vector3(
                  setPosition.x * 0.9,
                  setPosition.y * 0.9,
                  setPosition.z * 0.9
                )
              );
            }
            if (data[key].lowerRadiusLimit)
              _this.camera.lowerRadiusLimit = data[key].lowerRadiusLimit;
            if (data[key].upperRadiusLimit)
              _this.camera.upperRadiusLimit = data[key].upperRadiusLimit;
            if (data[key].attachControl)
              _this.camera.attachControl(_this.canvas, data[key].attachControl);
            break;
        }
      });
    },
    /**
     * 加载模型
     * @param{String}
     */
    loadModel: function (url) {
      return new Promise((resolve) => {
        if (url == null) {
          resolve();
        }
        let _this = this;
        BABYLON.SceneLoader.LoadAssetContainer(
          "",
          url,
          this.scene,
          function (container) {
            _this.meshes = container.meshes;
            _this.container = container;

            let min = _this.meshes[0].getBoundingInfo().boundingBox.minimum;
            let max = _this.meshes[0].getBoundingInfo().boundingBox.maximum;

            let shadowGenerator = _this.createShadow();
            for (let i = 0; i < _this.meshes.length; i++) {
              let meshMin =
                _this.meshes[i].getBoundingInfo().boundingBox.minimumWorld;
              let meshMax =
                _this.meshes[i].getBoundingInfo().boundingBox.maximumWorld;
              min = BABYLON.Vector3.Minimize(min, meshMin);
              max = BABYLON.Vector3.Maximize(max, meshMax);

              _this.meshes[i].material = _this.getPBR(
                _this.config.material.initPBR
              );
              _this.objMeshes[_this.meshes[i].name] = _this.meshes[i];

              _this.meshes[i].receiveShadows = true;
              shadowGenerator.addShadowCaster(_this.meshes[i]); 
			  
			  if(_this.meshes[i].name != '__root__'){
				  _this.partList.push({
					  name:_this.meshes[i].name,
					  id:i,
					  position:{},
					  active:false,
					  sn:'',
					  label:''
				  });
			  }
            }
			// BABYLON.Mesh.MergeMeshes([_this.meshes[9],_this.meshes[10]]);
			// BABYLON.Mesh.MergeMeshes([_this.meshes[11],_this.meshes[12],_this.meshes[13]]);
			
            let meshlength = [];
            meshlength.push(Math.abs(min.x - max.x));
            meshlength.push(Math.abs(min.y - max.y));
            meshlength.push(Math.abs(min.z - max.z));
            let maxlength = meshlength[0];
            for (let i = 1; i < 3; i++) {
              if (maxlength < meshlength[i]) maxlength = meshlength[i];
            }

            _this.wd = meshlength[2] / meshlength[1];

            let scale = maxlength / _this.config.meshes.size;
            _this.meshes[0].scaling.x = _this.meshes[0].scaling.x / scale;
            _this.meshes[0].scaling.y = _this.meshes[0].scaling.y / scale;
            _this.meshes[0].scaling.z = -_this.meshes[0].scaling.z / scale;

            let postion_y;
            if (Math.abs(max.y + min.y) > 250) {
              postion_y = Math.abs(max.y + min.y) / scale / 2;
            } else {
              postion_y = Math.abs(max.y + min.y) / scale / 2 - 10;
            }

            let position_z = -Math.abs(max.z + min.z) / scale / 2;
            _this.camera.setTarget(
              new BABYLON.Vector3(0, postion_y, position_z)
            );
            _this.camera2.setTarget(
              new BABYLON.Vector3(0, postion_y, position_z)
            );
            container.addAllToScene();
            resolve(_this.meshes);
          }
        );
      });
    },
    /**
     * 导出模型
     * @param{String} 导出文件格式类型
     */
    modelExport: function (type) {
      if (type == null) {
        type = "glb";
      }

      if (type == "gltf") {
        BABYLON.GLTF2Export.GLTFAsync(this.scene, "fileName").then((gltf) => {
          gltf.downloadFiles();
        });
      } else if (type == "glb") {
        BABYLON.GLTF2Export.GLBAsync(this.scene, "fileName").then((glb) => {
          glb.downloadFiles();
        });
      }
    },
    /**
     * 创建pbr材质
     * @param{Object} 材质参数
     */
    getPBR: function (data) {
      let pbr = new BABYLON.PBRMaterial("pbr", this.scene);
      pbr.disableLighting = false; //不接受灯光
      pbr.forceIrradianceInFragment = true; //环境辐照度
      pbr.enableSpecularAntiAliasing = false; //关闭镜面反射抗锯齿
      //pbr.usePhysicalLightFalloff = false;
      pbr.brdf.useSphericalHarmonics = true;
      //pbr.brdf.useEnergyConservation = true ;
      pbr.useAlphaFromAlbedoTexture = true;
      pbr.backFaceCulling = true;
      let _this = this;
      Object.keys(data).forEach(function (key) {
        if (data[key] == null) return;
        _this.pbrMaterial(data, pbr, key);
      });
      return pbr;
    },
    getParamType(val) {
      let data = {
        texture: [
          "albedoTexture",
          "bumpTexture",
          "ambientTexture",
          "reflectivityTexture",
        ],
        color: [
          "albedoColor",
          "reflectivityColor",
          "ambientColor",
          "reflectionColor",
        ],
        value: [
          "environmentIntensity",
          "alpha",
          "linkRefractionWithTransparency",
          "indexOfRefraction",
          "microSurface",
          "useMicroSurfaceFromReflectivityMapAlpha",
          "specularIntensity",
          "metallic",
          "roughness",
          "directIntensity",
          "cameraExposure",
          "cameraContrast",
          "isRefractionEnabled",
          "transparencyMode",
        ],
        children: ["subSurface", "clearCoat"],
        cubeTexture: [
          "reflectionTexture",
          "refractionTexture",
          "environmentTexture",
        ],
      };
      let type = null;
      Object.keys(data).forEach(function (key) {
        if (data[key].indexOf(val) > -1) {
          type = key;
        }
      });
      return type;
    },
    pbrMaterial(data, pbr, key, sign) {
      switch (this.getParamType(key)) {
        case "cubeTexture":
          //if(data[key].url)pbr[key] = new BABYLON.CubeTexture.CreateFromPrefilteredData(data[key].url, this.scene);
          break;
        case "texture":
          if (data[key].url) {
            if (sign == "getPBRParam") {
              pbr[key].url = data[key].url;
            } else {
              pbr[key] = new BABYLON.Texture(data[key].url, this.scene);
            }
          }
          if (pbr[key]) {
            if (data[key].uScale) pbr[key].uScale = data[key].uScale;
            if (data[key].vScale) pbr[key].vScale = data[key].vScale;
            if (data[key].wAng) pbr[key].wAng = data[key].wAng;
            if (data[key].vOffset) pbr[key].vOffset = data[key].vOffset;
            if (data[key].uOffset) pbr[key].uOffset = data[key].uOffset;
            if (data[key].level) pbr[key].level = data[key].level;
            if (data[key].id) pbr[key].id = data[key].id;

            // if (key == "albedoTexture" && !data[key].wAng) {
            //   pbr[key].wAng = 3.14;
            // }
          }
          break;
        case "color":
          pbr[key] = new BABYLON.Color3(data[key].r, data[key].g, data[key].b);
          break;
        case "value":
          pbr[key] = data[key];
          break;
        case "children":
          //subSurface
          if (data[key].indexOfRefraction)
            pbr[key].indexOfRefraction = data[key].indexOfRefraction;
          if (data[key].isRefractionEnabled)
            pbr[key].isRefractionEnabled = data[key].isRefractionEnabled;
          if (data[key].isTranslucencyEnabled)
            pbr[key].isTranslucencyEnabled = data[key].isTranslucencyEnabled;
          //clearCoat
          if (data[key].isEnabled) pbr[key].isEnabled = data[key].isEnabled;
          if (data[key].intensity) pbr[key].intensity = data[key].intensity;
          break;
      }

      return pbr;
    },
    setPBR(data) {
      if (!this.pickedMesh) {
        return;
      }
      let pbr = this.pickedMesh.material;
      let _this = this;
      Object.keys(data).forEach(function (key) {
        if (!data[key]) return;
        _this.pbrMaterial(data, pbr, key);
      });
    },
    /**
     * 创建毛发材质
     * @param{Object} 材质参数
     */
    getFur(data) {
      var furMaterial = new BABYLON.FurMaterial("fur", this.scene);
      furMaterial.furLength = data.furLength;
      furMaterial.furAngle = data.furAngle;
      furMaterial.furColor = new BABYLON.Color3(
        data.furColor.r,
        data.furColor.g,
        data.furColor.b
      );
      furMaterial.diffuseTexture = new BABYLON.Texture(
        data.diffuseTexture.url,
        this.scene
      );
      furMaterial.furTexture = BABYLON.FurMaterial.GenerateTexture(
        "furTexture",
        this.scene
      );
      furMaterial.furSpacing = data.furSpacing;
      furMaterial.furDensity = data.furDensity;
      furMaterial.furSpeed = data.furSpeed;
      furMaterial.furGravity = new BABYLON.Vector3(0, -1, 0);

      return furMaterial;
    },
    /*
     * 材质预览放大
     */
    blowUp: function () {
      let _this = this;
      let materialImg = document.createElement("img");
      materialImg.style.position = "absolute";
      materialImg.style.width = "350px";
      materialImg.style.height = "350px";
      materialImg.style.top = "100px";
      materialImg.style.right = "25px";

      this.canvas.onmouseover = function () {
        new BABYLON.Tools.CreateScreenshotUsingRenderTarget(
          _this.engine,
          _this.camera,
          1600,
          function (filePath) {
            materialImg.src = filePath;
            document.body.appendChild(materialImg);
          }
        );
      };
      this.canvas.onmouseout = function () {
        materialImg.remove();
      };
    },
    /**
     *模型部件双击点击高亮
     */
    highLight: function (fn) {
      let _this = this;
      let hl = new BABYLON.HighlightLayer("hl", this.scene); //高亮线条
      hl.blurHorizontalSize = 0.3;
      hl.blurVerticalSize = 0.3;

      this.canvas.ondblclick = function () {
        _this.pointer = {
          pointerX: _this.scene.pointerX,
          pointerY: _this.scene.pointerY,
        };
        let pickResult = _this.scene.pick(
          _this.scene.pointerX,
          _this.scene.pointerY
        );
        if (_this.pickedMesh) {
          hl.removeMesh(_this.pickedMesh);
        }
        if (!pickResult.pickedMesh) {
          _this.pickedMesh = null;
          fn(_this.pickedMesh);
          return;
        }
        let color = new BABYLON.Color3(0.13, 0.76, 0.66);
        //hl.addMesh(pickResult.pickedMesh, color);
        //_this.pickedMesh = pickResult.pickedMesh;
        //_this.pickResult = pickResult;
        fn(_this.pickedMesh);
      };
    },
    /**
     * 替换材质
     * @param{Object} 材质参数
     * @param{function} 回调
     */
    selectMaterial: function (pbr, fn) {
      let _this = this;
      this.canvas.onclick = function () {
        fn(pbr);
      };
    },
    initCamera() {
      this.camera.setPosition(new BABYLON.Vector3(0, 0, 80));
      this.camera.setTarget(BABYLON.Vector3.Zero());
    },
    loadMaterial: function (data) {
      this.initCamera();
      switch (data.type) {
        case "pbr":
          let pbr = this.getPBR(data.param);
          switch (data.shape) {
            case "ball":
              let sphere = new BABYLON.Mesh.CreateSphere(
                "sphere",
                50,
                50,
                this.scene
              );
              this.meshes = sphere;
              this.meshes.material = pbr;
              this.pickedMesh = sphere;
              break;
            case "cap":
              // sphere = new BABYLON.Mesh.CreateSphere('sphere', 80, 80, this.scene);
              // this.meshes = sphere;
              // this.meshes.material = pbr;
              // this.pickedMesh = sphere;

              this.loadModel(this.config.material.meshes.cap).then(
                async (resData) => {
                  this.meshes[1].material = pbr;
                  this.pickedMesh = this.meshes[1];
                  this.camera.setPosition(new BABYLON.Vector3(-60, 240, -220));
                  this.camera2.setPosition(
                    new BABYLON.Vector3(-60 * 0.9, 240 * 0.9, -220 * 0.9)
                  );
                }
              );
              break;
          }
          break;
        case "fur":
          let sphere = new BABYLON.Mesh.CreateSphere(
            "sphere",
            35,
            35,
            this.scene
          );
          let fur = this.getFur(data.param);
          sphere.material = fur;
          this.meshes = sphere;
          this.pickedMesh = sphere;
          let shells = BABYLON.FurMaterial.FurifyMesh(
            sphere,
            data.param.quality
          );
          break;
        case "standard":
          break;
      }
    },
    screenShot: function () {
      return new Promise((resolve) => {
        // if(this.config.camera.setPosition){
        // 	let setPosition = this.config.camera.setPosition;
        // 	this.camera.setPosition(new BABYLON.Vector3(setPosition.x,setPosition.y,setPosition.z));
        // }
        // document.getElementById(this.canvas.id).style.height = document.getElementById(this.canvas.id).clientWidth/this.wd+'px';
        // this.engine.resize();
        let _this = this;
        new BABYLON.Tools.CreateScreenshotUsingRenderTarget(
          this.engine,
          this.camera2,
          //{ width: document.getElementById(this.canvas.id).clientWidth*3, height: document.getElementById(this.canvas.id).clientHeight*3 },
          { width: 1024, height: 1024 },
          function (filePath) {
            var file = commonFn.dataURLtoFile(filePath);
            // document.getElementById(_this.canvas.id).style.height = document.getElementById(_this.canvas.id).clientWidth/_this.wd+'px';
            // _this.engine.resize();
            resolve(file);
          }
        );
      });
    },
    screenShot2: function () {
      return new Promise((resolve) => {
        let _this = this;
        new BABYLON.Tools.CreateScreenshotUsingRenderTarget(
          this.engine,
          this.camera2,
          { width: 1024, height: 1024 },
          function (filePath) {
            resolve(filePath);
          }
        );
      });
    },
    createLogo: function (data) {
      let pickResult = this.pickResult;
      if (
        pickResult &&
        pickResult.hit &&
        pickResult.pickedMesh.name != "mesh_logo_name"
      ) {
        let position = pickResult.pickedPoint;
        let normal = pickResult.getNormal(true);
        let size = new BABYLON.Vector3(data.size.x, data.size.y, data.size.z);
        let angle = data.angle;
        let logoData = {
          position: position,
          normal: normal,
          size: size,
          angle: angle,
        };
		
		let logo_name;
		if(this.logos[this.pickedMesh.name]){
			logo_name = this.pickedMesh.name + "[MESHLOGO]"+this.logos[this.pickedMesh.name].length;
		}else{
			this.logos[this.pickedMesh.name]=[];
			logo_name = this.pickedMesh.name + "[MESHLOGO]0";
		}
        let decal = BABYLON.MeshBuilder.CreateDecal(
          logo_name,
          this.pickedMesh,
          logoData
        );
        let decalMaterial = this.getPBR(data.param);
        decalMaterial.transparencyMode =
          BABYLON.Material.MATERIAL_ALPHATESTANDBLEND; //开启png透明
        decalMaterial.zOffset = -0.5;
        decalMaterial.backFaceCulling = false;
        decal.material = decalMaterial;
        this.logos[this.pickedMesh.name].push({
			mesh:decal,
			position:position,
			normal:normal,
			size:size,
			angle,angle,
			param:data.param
		});
        data.position = {
          x: position.x,
          y: position.y,
          z: position.z,
        };
        data.normal = {
          x: normal.x,
          y: normal.y,
          z: normal.z,
        };
        //data.meshName = this.pickedMesh.name;
        data.pointerX = this.pointer.pointerX;
        data.pointerY = this.pointer.pointerY;
		
		this.pickedMesh = decal;
		//this.camera.inputs.attached.pointers.detachControl();
		//this.camera.inputs.attached.mousewheel.detachControl();
        return data;
      }
    },
    getLogo: function (mesh, data) {
      let position = new BABYLON.Vector3(
        data.position.x,
        data.position.y,
        data.position.z
      );
      let normal = new BABYLON.Vector3(
        data.normal.x,
        data.normal.y,
        data.normal.z
      );
      let size = new BABYLON.Vector3(data.size.x, data.size.y, data.size.z);
      let angle = data.angle;

      let logoData = {
        position: position,
        normal: normal,
        size: size,
        angle: angle,
      };
      // this.meshes.forEach((item)=>{
      // 	if(item.name == this.pickedMesh.name.split('[MESHLOGO]')[0]){
      // 		mesh = item;
      // 	}
      // })
	  let logo_index = this.logos[mesh.name]?this.logos[mesh.name].length:0;
      let logo_name = mesh.name + "[MESHLOGO]"+logo_index;
      let decal = BABYLON.MeshBuilder.CreateDecal(logo_name, mesh, logoData);
      let decalMaterial = this.getPBR(data.param);
      decalMaterial.transparencyMode =
        BABYLON.Material.MATERIAL_ALPHATESTANDBLEND; //开启png透明
      decalMaterial.zOffset = -0.5;
      decalMaterial.backFaceCulling = false;
      decal.material = decalMaterial;
      //this.logos[mesh.name] = decal;
	  if(this.logos[mesh.name]){
		  this.logos[mesh.name].push({
				  mesh:decal,
				  position: position,
				  normal: normal,
				  size: size,
				  angle: angle,
				  param:data.param
		  })
	  }else{
		  this.logos[mesh.name] = [{
				  mesh:decal,
				  position: position,
				  normal: normal,
				  size: size,
				  angle: angle,
				  param:data.param
		  }]
	  }
    },
    moveLogo: function (mesh, data) {
      let pickResult = this.scene.pick(data.pointerX, data.pointerY);
      let position = pickResult.pickedPoint;
      if (!position) {
        return;
      }
      let normal = pickResult.getNormal(true);
      let size = new BABYLON.Vector3(data.size.x, data.size.y, data.size.z);
      let angle = data.angle;

      let logoData = {
        position: position,
        normal: normal,
        size: size,
        angle: angle,
      };

      let logo_name = this.pickedMesh.name + "[MESHLOGO]";
      let decal = BABYLON.MeshBuilder.CreateDecal(logo_name, mesh, logoData);
      let decalMaterial = this.getPBR(data.param);
      decalMaterial.transparencyMode =
        BABYLON.Material.MATERIAL_ALPHATESTANDBLEND; //开启png透明
      decalMaterial.zOffset = -0.5;
      decalMaterial.backFaceCulling = false;
      decal.material = decalMaterial;
      //this.logos[mesh.name] = decal;
	  
      data.position = {
        x: position.x,
        y: position.y,
        z: position.z,
      };
      data.normal = {
        x: normal.x,
        y: normal.y,
        z: normal.z,
      };
      data.meshName = mesh.name;

      return data;
    },
	changeLogo:function(){
		let pickResult = this.scene.pick(this.pointer.pointerX-340, this.pointer.pointerY);
		let position = pickResult.pickedPoint;
		if (!position) {
		  return;
		}
		let mesh_name = this.pickedMesh.name.split("[MESHLOGO]")[0];
		let logo_index = this.pickedMesh.name.split("[MESHLOGO]")[1];
		let mesh = this.objMeshes[mesh_name];
		let logo_name = this.pickedMesh.name;
		
		let normal = pickResult.getNormal(true);
		let size = this.logos[mesh.name][logo_index].size
		let angle = this.logos[mesh.name][logo_index].angle;
		let logoData = {
		  position: position,
		  normal: normal,
		  size: size,
		  angle: angle,
		};

		let decal = BABYLON.MeshBuilder.CreateDecal(logo_name, mesh, logoData);
		decal.material = this.logos[mesh.name][logo_index].mesh.material;
		this.logos[mesh.name][logo_index] = {
			mesh:decal,
			position:position,
			normal:normal,
			angle:angle,
			size:size,
			param:this.logos[mesh.name][logo_index].param
		};
		this.pickedMesh = decal;
	},
	changeSizeLogo:function(type,kb){
		if(this.pickedMesh && this.pickedMesh.name.indexOf('[MESHLOGO]') > 0 && this.logoEdit){
			let logo_index = this.pickedMesh.name.split('[MESHLOGO]')[1];
			let mesh_name = this.pickedMesh.name.split('[MESHLOGO]')[0];
			let logo_name = this.pickedMesh.name;
			let mesh = this.objMeshes[mesh_name];
			let position = this.logos[mesh.name][logo_index].position;
			let normal = this.logos[mesh.name][logo_index].normal;
			let angle;
			let size;
			if(kb == 'shift'){
				size = this.logos[mesh.name][logo_index].size;
				if(type == '+'){
					angle = this.logos[mesh.name][logo_index].angle+0.1;
				}else if(type == '-'){
					angle = this.logos[mesh.name][logo_index].angle-0.1;
				}
			}else{
				angle = this.logos[mesh.name][logo_index].angle
				if(type == '+'){
					size = this.logos[mesh.name][logo_index].size.x+1;
				}else if(type == '-'){
					size = this.logos[mesh.name][logo_index].size.x-1;
				}
				size = new BABYLON.Vector3(size, size, size);
			}
			let logoData = {
			  position: position,
			  normal: normal,
			  size: size,
			  angle: angle,
			};
			let decal = BABYLON.MeshBuilder.CreateDecal(logo_name, mesh, logoData);
			decal.material = this.logos[mesh.name][logo_index].mesh.material;
			this.logos[mesh.name][logo_index] = {
				mesh:decal,
				position:position,
				normal:normal,
				angle:angle,
				size:size,
				param:this.logos[mesh.name][logo_index].param
			};
			this.pickedMesh = decal;
		}
	},
    deleteLogo: function () {
	  if(this.pickedMesh && this.pickedMesh.name.indexOf('[MESHLOGO]') > 0){
		  this.pickedMesh.dispose();
		  return;
	  }
      // if (
      //   this.pickResult &&
      //   this.pickResult.pickedMesh &&
      //   this.logos[this.pickResult.pickedMesh.name]
      // ) {
      //   this.logos[this.pickResult.pickedMesh.name].dispose();
      //   this.logos[this.pickResult.pickedMesh.name] = null;
      // }
    },
	cancelLogo:function(){
		if(this.pickedMesh && this.pickedMesh.name.indexOf('[MESHLOGO]') > 0){
			let mesh_name = this.pickedMesh.name.split('[MESHLOGO]');
			delete this.logos[mesh_name[0]][mesh_name[1]];
			this.pickedMesh.dispose();
			return;
		}
	},
    dorelease: function () {
      this.container.removeAllFromScene();
      this.engine.dispose();
	  this.pickedMesh.dispose();
	  this.camera.dispose();
	  this.scene.dispose();
      // delete this.engine;
      // delete this.scene;
      // delete this.camera;
      // this.canvas.parentNode.removeChild(this.canvas);
      // this.engine = null;
      // this.camera = null;
      // this.scene = null;
      // this.hdrTexture = null;
      // this.meshes = null;
      // this.pickedMesh = null;
      // this.pickResult = null;
      // this.canvas = null;
      // this.container = null;
    },
    createShadow: function () {
      //添加阴影
      let shadowGenerator = new BABYLON.ShadowGenerator(2048, this.light);
      shadowGenerator.bias = 0.00000001;//偏倚
      shadowGenerator.normalBias = 0.000000000001;//正态偏差
      shadowGenerator.forceBackFacesOnly = false;//(ture:黑块、false:正常)
      shadowGenerator.frustumEdgeFalloff = 0;//霜动边缘（0清晰、1模糊）
      shadowGenerator.usePoissonSampling = true;//泊松采样
     // shadowGenerator.usePercentageCloserFiltering = true;//百分比更接近过滤
      shadowGenerator.useBlurCloseExponentialShadowMap = true;//模糊指数阴影图
      shadowGenerator.useContactHardeningShadow = false;//true时性能影响大
      shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;

      //模糊指数阴影贴图
      // shadowGenerator.useBlurExponentialShadowMap = false;
      // shadowGenerator.blurScale = 2;
      // shadowGenerator.blurBoxOffset = 1;
      // shadowGenerator.useKernelBlur = true;
      // shadowGenerator.blurKernel = 1;//模糊

      //百分比更接近过滤
      //shadowGenerator.usePercentageCloserFiltering = true;

      //接触硬化阴影
      // shadowGenerator.useContactHardeningShadow = true;
      // shadowGenerator.contactHardeningLightSizeUVRatio = 0;
      // shadowGenerator.filteringQuality = BABYLON.ShadowGenerator.QUALITY_LOW;

      //视锥边缘衰减
      //shadowGenerator.frustumEdgeFalloff = 0.5;

      //设置黑暗
      shadowGenerator.setDarkness(0.3);

      this.scene.shadowsEnabled = true;

      return shadowGenerator;
    },
    //创建材质获取材质参数
    getMaterialData: function (data) {
      let _this = this;
      if (this.pickedMesh && this.pickedMesh.material && data) {
        let pbr = _this.pickedMesh.material;
        Object.keys(data).forEach(function (key) {
          if (_this.pickedMesh.material[key] == null) {
            return;
          }
          _this.pbrMaterial(pbr, data, key, "getPBRParam");
        });
      }
      return data;
    },
    //保存设计获取材质参数
    getMaterialData2: function (data, meshName) {
      let _this = this;
      let pbr = _this.objMeshes[meshName].material;
      Object.keys(data).forEach(function (key) {
        if (pbr[key] == null) {
          return;
        }
        _this.pbrMaterial(pbr, data, key, "getPBRParam");
      });
      return data;
    },
    //随机配色
    designColor: function (image) {
      let _this = this;
      let designData = {};
      let colorArr = commonFn.getPictureColor(image);
      _this.meshes.forEach(function (item, index) {
        let n = Math.floor(Math.random() * colorArr.length + 1) - 1;
        let color = colorArr[n];
        let hsv = commonFn.rgbtohsv(color[0], color[1], color[2]);
        let light = hsv[2] / 100;
        color = commonFn.hsvtorgb(hsv[0], hsv[1], hsv[2]);
        let rgb = { r: color[0] / 255, g: color[1] / 255, b: color[2] / 255 };
        designData[item.name] = {
          abledoColor: rgb,
          directIntensity: light,
          reflectionColor: rgb,
        };
        _this.config.material.initPBR.albedoColor = rgb;
        _this.config.material.initPBR.directIntensity = light;
        //_this.config.material.initPBR.environmentIntensity = light;
        _this.config.material.initPBR.reflectionColor = rgb;
        item.material.dispose();
        item.material = _this.getPBR(_this.config.material.initPBR);
      });

      return JSON.stringify(designData);
    },
    //给模型上色
    setColor: function (data) {
      let _this = this;
      Object.getOwnPropertyNames(data).forEach(function (key) {
        _this.config.material.initPBR.albedoColor = data[key].abledoColor;
        _this.config.material.initPBR.reflectionColor =
          data[key].reflectionColor;
        _this.config.material.initPBR.directIntensity =
          data[key].directIntensity;
        _this.objMeshes[key].material = _this.getPBR(
          _this.config.material.initPBR
        );
      });
    },
    pathColor: function (x, y, z, meshName = "") {
      let text;
      meshName = meshName ? meshName : this.pickedMesh.name;
      if (this.sign[meshName]) {
        if(this.sign[meshName].line)this.sign[meshName].line.dispose();
        if(this.sign[meshName].mesh)this.sign[meshName].mesh.dispose();
        text = this.sign[meshName].text;
      } else {
        text = Object.keys(this.sign).length + 1;
        text = text.toString();
      }
      var quadraticBezierVectors = BABYLON.Curve3.CreateQuadraticBezier(
        new BABYLON.Vector3(x, y, z),
        new BABYLON.Vector3(x, y, z),
        new BABYLON.Vector3(x, 180 - x, z),
        25
      );
      var quadraticBezierCurve = BABYLON.Mesh.CreateLines(
        "qbezier",
        quadraticBezierVectors.getPoints(),
        this.scene
      );
      quadraticBezierCurve.color = new BABYLON.Color3(1, 0.5, 0.5);
      quadraticBezierCurve.isVisible = this.isPath;

      let Writer = MeshWriter(this.scene, {
        scale: 0.25,
        defaultFont: "Arial",
      });
      let textMesh = new Writer(text, {
        "font-family": "Arial",
        "letter-height": 40,
        "letter-thickness": 5,
        color: "#1C3870",
        anchor: "center",
        colors: {
          diffuse: "#F0F0F0",
          specular: "#000000",
          ambient: "#ff00f0",
          emissive: "#ff00f0",
        },
        position: {
          x: x * 4 + 15,
          y: 180 * 4 - x * 4 - 3,
          z: z * 4 - 10,
        },
      });

      let sps = textMesh.getSPS();
      sps.mesh.rotation.x = -3.14 / 2;
      sps.mesh.rotation.y = -3.14 / 2;
      sps.mesh.rotation.z = 0;
	  sps.mesh.isVisible = this.isPath;

      this.sign[meshName] = {
        line: quadraticBezierCurve,
        mesh: sps.mesh,
        text: text,
        position: { x: x, y: y, z: z },
        meshName: meshName,
      };
    },
	//选中部件
	selectPart:function(meshName){
		let color = new BABYLON.Color3(0.13, 0.76, 0.66);
		let mesh = this.objMeshes[meshName];
		this.highlight.removeAllMeshes();
		this.highlight.addMesh(mesh, color);
		this.pickedMesh = mesh;
		this.pickedMeshArr = {};
		this.pickedMeshArr[mesh.name] = mesh;
		
		let _this = this;
		for(let i in _this.partArr){
			let part = _this.partArr[i];
			part.forEach(function(item){
				if(_this.pickedMeshArr[item.name]){
					item.active = true;	
				}else{
					item.active = false;
				}
			})
		}
	},
	//获取定位
	getPosition:function(){
		if(this.pickedMesh){
			let position = this.camera.position;
			let _this = this;
			this.partList.forEach(function(item){
				if(item.name == _this.pickedMesh.name){
					item.position = {
						x:position.x,
						y:position.y,
						z:position.z
					};
				}
			})
			
			for(let i in this.partArr){
				let part = this.partArr[i];
				part.forEach(function(item){
					if(item.name == _this.pickedMesh.name){
						item.position = {
							x:position.x,
							y:position.y,
							z:position.z
						};
					}
				})
			}
		}
	},
	//定位
	setPosition:function(ps){
		if(ps){
			let _this = this;
			let a=0,b=0,c=0;
			let limit = _this.camera.lowerRadiusLimit;
			_this.camera.lowerRadiusLimit = 0;
			if(_this.activeTime)clearInterval(_this.activeTime);
			_this.activeTime = setInterval(function(){
				let position = _this.camera.position
				let x = parseInt(position.x)
				let y = parseInt(position.y)
				let z = parseInt(position.z)
				if(x<parseInt(ps.x)){
					x++
				}else if(x>parseInt(ps.x)){
					x--
				}else{
					a=1;
				}
				if(y<parseInt(ps.y)){
					y++
				}else if(y>parseInt(ps.y)){
					y--
				}else{
					b=1;
				}
				if(z<parseInt(ps.z)){
					z++
				}else if(z>parseInt(ps.z)){
					z--
				}else{
					c=1;
				}
				_this.camera.setPosition(
				  new BABYLON.Vector3(x, y, z)
				);
				if(a+b+c == 3){
					_this.camera.lowerRadiusLimit = limit;
					clearInterval(_this.activeTime)
				}
			},3)
		}
	},
    //鼠标事件
    pointerEvent: function (fn) {
      let _this = this;
      let move_length = 0;
      let color = new BABYLON.Color3(0.13, 0.76, 0.66);

      //模型触发事件
      _this.scene.onPointerObservable.add((pointerInfo) => {
        if (pointerInfo.pickInfo.hit) {
          //鼠标坐标
          _this.pointer = {
            pointerX: pointerInfo.event.clientX,
            pointerY: pointerInfo.event.clientY,
          };
		  
		  //选中地面
		  if (pointerInfo.pickInfo.pickedMesh.name == "ground") {
		    _this.highlight.removeAllMeshes();
		    _this.pickedMesh = null;
		  	_this.pickedMeshArr = [];
		    // _this.camera.inputs.attachInput(_this.camera.inputs.attached.mousewheel);
		    // _this.camera.attachControl(_this.canvas,true);
		    return false;
		  }
        }

        //事件类型
        switch (pointerInfo.type) {
          case BABYLON.PointerEventTypes.POINTERDOWN: //鼠标按下
            move_length = 0;
            break;
          case BABYLON.PointerEventTypes.POINTERUP: //鼠标松开
            //鼠标点击
            if (pointerInfo.pickInfo.hit && move_length < 5) {
				
            }else if(pointerInfo.pickInfo.hit && move_length > 5){
			  if(_this.logoEdit){
				  _this.deleteLogo();
				  _this.changeLogo();
			  }
			}else if (!pointerInfo.pickInfo.hit && move_length < 5) {
              
            }
            break;
          case BABYLON.PointerEventTypes.POINTERMOVE: //鼠标移动
            move_length++;
            break;
          case BABYLON.PointerEventTypes.POINTERWHEEL: //鼠标滚轮
			   if(_this.logoEdit && pointerInfo.event.wheelDelta == 120 || _this.logoEdit && pointerInfo.event.wheelDelta == 240){
				   _this.deleteLogo();
				   if(this.kbInfo.shift){
					   _this.changeSizeLogo('+','shift');
				   }else{
					   _this.changeSizeLogo('+');
				   }
			   }else if(_this.logoEdit && pointerInfo.event.wheelDelta == -120 || _this.logoEdit && pointerInfo.event.wheelDelta == -240){
				   _this.deleteLogo();
				   if(this.kbInfo.shift){
				   		_this.changeSizeLogo('-','shift');
				   }else{
				   		_this.changeSizeLogo('-');
				   }
			   }
            break;
          case BABYLON.PointerEventTypes.POINTERPICK://拾取
				if(pointerInfo.event.which == 1){//左击
					if(_this.kbInfo.shift){
						if(_this.pickedMeshArr[pointerInfo.pickInfo.pickedMesh.name]){
							_this.highlight.removeMesh(pointerInfo.pickInfo.pickedMesh);
							delete _this.pickedMeshArr[pointerInfo.pickInfo.pickedMesh.name];
							_this.pickedMesh = null;
						}else{
							_this.highlight.addMesh(pointerInfo.pickInfo.pickedMesh, color);
							_this.pickedMesh = pointerInfo.pickInfo.pickedMesh;
							_this.pickedMeshArr[pointerInfo.pickInfo.pickedMesh.name] = pointerInfo.pickInfo.pickedMesh;
						}
						//选中部件
						_this.partList.forEach(function(item){
							if(_this.pickedMeshArr[item.name]){
								item.active = true;		
							}else{
								item.active = false;
							}
						})
						
						for(let i in _this.partArr){
							let part = _this.partArr[i];
							part.forEach(function(item){
								if(_this.pickedMeshArr[item.name]){
									item.active = true;	
								}else{
									item.active = false;
								}
							})
						}

						fn();
						return;
					}
					_this.highlight.removeAllMeshes();
					_this.highlight.addMesh(pointerInfo.pickInfo.pickedMesh, color);
					_this.pickedMesh = pointerInfo.pickInfo.pickedMesh;
					_this.pickedMeshArr = [];
					_this.pickedMeshArr[pointerInfo.pickInfo.pickedMesh.name] = pointerInfo.pickInfo.pickedMesh;
					//_this.pickResult = pointerInfo.pickInfo;
					fn();
					if(_this.isPath){
						_this.pathColor(pointerInfo.pickInfo.pickedPoint.x,pointerInfo.pickInfo.pickedPoint.y,pointerInfo.pickInfo.pickedPoint.z);  
					}
					
					let is_logo = _this.pickedMesh.name.indexOf("[MESHLOGO]")
					if(is_logo>0){
						  _this.logoEdit = true;
						  _this.camera.inputs.attached.pointers.detachControl();
						  _this.camera.inputs.attached.mousewheel.detachControl();
					}else{
						  _this.logoEdit = false;
						  _this.camera.inputs.attached.pointers.detachControl();
						  _this.camera.inputs.attached.mousewheel.detachControl();
						  _this.camera.inputs.attachInput(_this.camera.inputs.attached.mousewheel);
						  _this.camera.inputs.attachInput(_this.camera.inputs.attached.pointers);
					}
					
					//选中部件
					_this.partList.forEach(function(item){
						if(_this.pickedMeshArr[item.name]){
							item.active = true;
						}else{
							item.active = false;
						}
					})
					
					for(let i in _this.partArr){
						let part = _this.partArr[i];
						part.forEach(function(item){
							if(_this.pickedMeshArr[item.name]){
								item.active = true;	
							}else{
								item.active = false;
							}
						})
					}
				}
            break;
          case BABYLON.PointerEventTypes.POINTERTAP://单击
				if(pointerInfo.event.which == 3){//右击
					
				}else if(!pointerInfo.pickInfo.hit && pointerInfo.event.which == 1){
					_this.highlight.removeAllMeshes();
					_this.pickedMesh = null;
					_this.camera.inputs.attached.pointers.detachControl();
					_this.camera.inputs.attached.mousewheel.detachControl();
					_this.camera.inputs.attachInput(_this.camera.inputs.attached.mousewheel);
					_this.camera.inputs.attachInput(_this.camera.inputs.attached.pointers);
					
					_this.pickedMeshArr = [];
					_this.partList.forEach(function(item){
						if(_this.pickedMeshArr[item.name]){
							item.active = true;		
						}else{
							item.active = false;
						}
					})
					
					for(let i in _this.partArr){
						let part = _this.partArr[i];
						part.forEach(function(item){
							if(_this.pickedMeshArr[item.name]){
								item.active = true;	
							}else{
								item.active = false;
							}
						})
					}
				}
            break;
          case BABYLON.PointerEventTypes.POINTERDOUBLETAP: //双击
            break;
        }
      });
    },
    //键盘事件
    keyboard: function () {
      this.scene.onKeyboardObservable.add((kbInfo) => {
        switch (kbInfo.type) {
          case BABYLON.KeyboardEventTypes.KEYDOWN:
            switch (kbInfo.event.key) {
              case "ArrowUp":
                break;
              case "ArrowDown":
                break;
              case "ArrowLeft":
                break;
              case "ArrowRight":
                break;
              case "Shift":
					this.kbInfo.shift = true;
                break;
            }
            break;
          case BABYLON.KeyboardEventTypes.KEYUP:
            switch (kbInfo.event.key) {
              case "ArrowUp":
                break;
              case "ArrowDown":
                break;
              case "ArrowLeft":
                break;
              case "ArrowRight":
                break;
              case "Shift":
					this.kbInfo.shift = false;
                break;
            }
            break;
        }
      });
    },
	stepBack:function(){
		let index = this.historyData.index;
		let mesh = this.historyData.mesh;
		let meshes = this.objMeshes;	
		let step = this.historyData.step;
		let scene = this;
		if(index>0){
		   this.historyData.index--;
		  index = this.historyData.index;
		  if(step[index].index == 0 && this.historyData.init[step[index].mesh]){
			   meshes[step[index].mesh].material = this.getPBR(this.historyData.init[step[index].mesh].param);
			   this.stepBack();
		  }else{
			meshes[step[index].mesh].material = mesh[step[index].mesh][step[index].index]
						  ? this.getPBR(mesh[step[index].mesh][step[index].index].param)
						  : this.getPBR(scene.config.material.initPBR);
			if(!mesh[step[index].mesh][step[index].index]){
				this.stepBack();
			}
		  }
		}
	},
	stepForward:function(){
		let index = this.historyData.index;
		let mesh = this.historyData.mesh;
		let meshes = this.objMeshes;	
		let step = this.historyData.step;
		let scene = this;
		if(index < step.length - 1){
		   this.historyData.index++;
		   index = this.historyData.index;
		  if(mesh[step[index].mesh][step[index].index]){
		  		meshes[step[index].mesh].material = this.getPBR(mesh[step[index].mesh][step[index].index].param);
		  }else{
			   this.stepForward();
		  }
		}
	},
	addHistory:function(m){
		if(this.historyData.mesh[this.pickedMesh.name]){
			 this.historyData.mesh[this.pickedMesh.name].push(m)
			 this.historyData.step.push({
				 mesh:this.pickedMesh.name,
				 index:this.historyData.mesh[this.pickedMesh.name].length - 1
			 })
		}else{
			 this.historyData.mesh[this.pickedMesh.name] = [""];
			  this.historyData.mesh[this.pickedMesh.name].push(m)
				  this.historyData.step.push({
					 mesh:this.pickedMesh.name,
					 index:0
				  })
			  this.historyData.step.push({
				 mesh:this.pickedMesh.name,
				 index:1
			  })
		}
		this.historyData.index = this.historyData.step.length - 1;
	},
	cacheModel:function(){
		return new Promise((resolve) => {
		  let _this = this;
		  commonFn.db_create(dbConfig).then((db) => {
		    if (db) {
		      commonFn
		        .db_read(db, dbConfig.table_name, dbConfig.product_id)
		        .then(async (result) => {
		          if (result && result.version == dbConfig.model_versions) {
		            await commonFn.readModel(result.blob).then(async (result) => {
		              resolve(result);
		            });
		          } else {
		            commonFn
		              .loadModel(dbConfig.model_file)
		              .then(async (result) => {
		                let data = {
		                  id: dbConfig.product_id,
		                  blob: result,
		                  version: dbConfig.model_versions,
		                };
		                commonFn
		                  .db_add(db, dbConfig.table_name, data)
		                  .then(async (result) => {
		                    commonFn
		                      .db_read(
		                        db,
		                        dbConfig.table_name,
		                        dbConfig.product_id
		                      )
		                      .then(async (result) => {
		                        await commonFn
		                          .readModel(result.blob)
		                          .then(async (result) => {
		                            resolve(result);
		                          });
		                      });
		                  });
		              });
		          }
		        });
		    }
		  });
		});
	},
	forColor:function(){
		let _this = this;
		let designData = _this.designColor(pictureData);
		_this.screenShot2().then((file) => {
		  let img = document.createElement("img");
		  img.src = file;
		  img.style.height = "33%";
		  img.setAttribute("data", designData);
		  img.onclick = function () {
		    document.getElementById(id).style.display = "none";
		    let data = JSON.parse(img.getAttribute("data"));
		    _this.setColor(data);
		  _this.isDesign = false;
		  };
		  document.getElementById(id).appendChild(img);
		  index++;
		  if (index < 9) {
		    setTimeout(function () {
		      _this.forColor(index,id,pictureData);
		    }, 100);
		  }
		});
	},
	signShow:function(){
		this.isPath = !this.isPath;
		let sign = this.sign;
		Object.keys(sign).forEach(function (key) {
		  sign[key].line.isVisible = !sign[key].line.isVisible;
		  sign[key].mesh.isVisible = !sign[key].mesh.isVisible;
		});
	},
	mergeMesh:function(){
		if(this.mergeMeshes[this.pickedMesh.name]){
			this.mergeMeshes[this.pickedMesh.name].mesh.isVisible = true;
		}else{
			let mergeMeshesArr = [];
			 for(let i in this.pickedMeshArr){
				 mergeMeshesArr.push(this.pickedMeshArr[i]);
				 this.pickedMeshArr[i].isVisible = false;
			 }
			 let mergeMesh = BABYLON.Mesh.MergeMeshes(mergeMeshesArr,false,true,undefined,false,true);
			this.objMeshes[mergeMesh.name] = mergeMesh;
			this.mergeMeshes[mergeMesh.name] = {
				mesh:mergeMesh,
				meshes:mergeMeshesArr
			};
		}
	},
	cancelMerge:function(){
		this.mergeMeshes[this.pickedMesh.name].mesh.isVisible = false;
		this.mergeMeshes[this.pickedMesh.name].meshes.forEach(function(item){
			item.isVisible = true;
		})
	},
	mergePart:function(){
		let part = this.partArr.part1;
		if(part){
			let mergeMeshesArr = [];
			 for(let i in part){
				 this.objMeshes[part[i].name].isVisible = false;
				 mergeMeshesArr.push(this.objMeshes[part[i].name]);
			 }
			 let mergeMesh = BABYLON.Mesh.MergeMeshes(mergeMeshesArr,false,true,undefined,false,true);
			 this.mergeMesh = mergeMesh;
			this.objMeshes[mergeMesh.name] = mergeMesh;
		}
	},
	cancelMergePart:function(){
		this.mergeMesh.dispose();
		let part = this.partArr.part1;
		if(part){
			 for(let i in part){
				 this.objMeshes[part[i].name].isVisible = true;
			 }
		}
	},
	handleMergeMesh:function(){
		let mergeMeshesArr = [];
		for(let i in this.pickedMeshArr){
			this.pickedMeshArr[i].isVisible = false;
			mergeMeshesArr.push(this.pickedMeshArr[i]);
		}
		let mergeMesh = BABYLON.Mesh.MergeMeshes(mergeMeshesArr,false,true,undefined,false,true);
		this.objMeshes[mergeMesh.name] = mergeMesh;
	},
	writePart:function(sn){
		for(let i in this.partArr){
			this.partArr[i].forEach(function(item){
				if(item.active){
					item.sn = sn;
				}
			})
		}
	}
  };
  return ThreeDScene;
})();

export { ThreeDScene };

<template>
  <div class="list-form-footer">
    <el-input
      v-if="checkEdit"
      class="font-size-16"
      v-model="itemData.desc"
      size="mini"
      placeholder="页尾文案"
    ></el-input>
    <div v-else class="footer-text">
      {{ itemData.desc }}
    </div>
  </div>
</template>

<script>
export default {
  name: "formFooter",
  props: {
    checkEdit: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
    },
  },
  data() {
    return {
      text: "",
    };
  },
};
</script>

<style lang="scss">
.list-form-footer {
  height: 40px;
  border-top: 2px solid $base-config-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  .el-input__inner {
    height: 34px;
    line-height: 34px;
    color: #3d3d3d;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .footer-text {
    height: 34px;
    line-height: 34px;
    color: #3d3d3d;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
}
</style>

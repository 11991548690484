<template>
  <div class="cart-ani" ref="self">
    <img class="image" :src="src" />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import anime from "animejs";
export default {
  data() {
    return {
      tl: null,
      self: null,
      top: 0,
      left: 0,
    };
  },
  computed: {
    ...mapState("jiyang/cart", ["cart", "nowFormId"]),
    src() {
      if (this.product.file_info) {
        let url = this.product.file_info;
        switch (this.product.type) {
          case 3:
            url = url.gif_file;
            break;

          default:
            url = url.main_file.img_url;
        }
        return url;
      }
      return "";
    },
  },
  mounted() {
    this.setupAni();
    window.addEventListener("resize", this.setupAni);
  },
  methods: {
    ...mapMutations("jiyang/cart", ["addCart"]),
    setupAni() {
      this.$nextTick(() => {
        const target = document.querySelector(".navbar .order");
        const { x, y } = target.getBoundingClientRect();
        this.top = y;
        this.left = x;
        console.log(x, y);
        this.tl = anime
          .timeline({
            targets: ".cart-ani .image",
            left: [100, this.left / 2 + 100],
            top: [300, this.top],
            opacity: [0, 1],
            backgroundColor: "red",
            borderRadius: ["0%", "50%"],
            easing: "easeInOutQuad",
            autoplay: false,
          })
          .add({
            opacity: 1,
            scale: 1,
            duration: 600,
          })
          .add({
            scale: 1.2,
            duration: 500,
          })
          .add({
            scale: 0,
            opacity: 0,
            duration: 400,
          });
      });
    },
    complete() {
      this.product["img_url"] = this.product.file_info.main_file.img_url;
      this.templateData.formPage.product.tableData = [
        ...this.templateData.formPage.product.tableData,
        ...[this.product],
      ];
      this.updateForm();
      this.addCart(this.product);
      this.$emit("update:visible", false);
    },
    //更新表单
    updateForm() {
      let data = {
        type: 1,
        data: this.templateData,
        id: this.nowFormId,
      };
      let loading = this.openLoading();
      this.$api.updateForm(data).then(async (res) => {
        this.$message.success("添加成功");
        loading.close();
      });
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.setupAni);
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        let data = this.templateData.formPage.product.tableData;
        let check = data.find((val) => val.id == this.product.id);
        if (check) {
          this.$message.error("当前表单已存在该商品");
          this.$emit("update:visible", false);
          return;
        }
        this.tl.play();
        this.tl.finished.then(this.complete);
      }
    },
  },
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => {},
    },
    templateData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.cart-ani {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  pointer-events: none;
  svg {
    width: 100%;
    height: 100%;
  }
  .circle {
    fill: none;
    stroke: #21c3a9;
    stroke-miterlimit: 10;
    stroke-width: 4px;
  }
  .image {
    opacity: 1;
    width: 80px;
    position: absolute;
    border: 1px solid var(--primaryColor);
  }
}
</style>

<template>
  <a-form class="tab-content" v-if="visiable">
    <div class="set-title">
      {{ $t("tabNameLibary") }}
    </div>
    <!-- <div class="set-title" v-else-if="type == 'logo'">
      {{ $t("tabNameLogo") }}
    </div> -->
    <div>
      <a-form-item>
        <span slot="label">
          {{ $t("scaleSize") }}
          <!-- <a-tooltip placement="bottom" title="缩放纹理大小">
			      <a-icon type="question-circle-o" />
			    </a-tooltip> -->
        </span>
        <a-slider
          :min="0.1"
          :max="10"
          :step="0.1"
          @change="handleTextureSizeChange"
        ></a-slider>
      </a-form-item>
      <a-form-item>
        <span slot="label">
          {{ $t("rotationSize") }}
          <!-- <a-tooltip placement="bottom" title="旋转纹理角度">
			      <a-icon type="question-circle-o" />
			    </a-tooltip> -->
        </span>
        <a-slider
          :min="-4"
          :max="4"
          :step="0.000001"
          @change="handleTextureAngleChange"
        ></a-slider>
      </a-form-item>
      <a-form-item>
        <span slot="label">
          {{ $t("horizontalMovement") }}
          <!-- <a-tooltip placement="bottom" title="水平移动纹理">
			      <a-icon type="question-circle-o" />
			    </a-tooltip> -->
        </span>
        <a-slider
          :min="0.1"
          :max="10"
          :step="0.1"
          @change="handleTextureHorizontalChange"
        ></a-slider>
      </a-form-item>
      <a-form-item>
        <span slot="label">
          {{ $t("verticalMovement") }}
          <!-- <a-tooltip placement="bottom" title="垂直移动纹理">
			      <a-icon type="question-circle-o" />
			    </a-tooltip> -->
        </span>
        <a-slider
          :min="0.1"
          :max="10"
          :step="0.1"
          @change="handleTextureVerticalChange"
        ></a-slider>
      </a-form-item>
      <a-form-item>
        <span slot="label">
          {{ $t("environmentIntensity") }}
          <!-- <a-tooltip placement="bottom" title="environment intensity">
			      <a-icon type="question-circle-o" />
			    </a-tooltip> -->
        </span>
        <a-slider
          :min="0.1"
          :max="5"
          :step="0.1"
          @change="handleEnvironmentIntensityChange"
        />
      </a-form-item>
      <a-form-item>
        <span slot="label">
          {{ $t("alphaIntensity") }}
          <!-- <a-tooltip placement="bottom" title="environment intensity">
			      <a-icon type="question-circle-o" />
			    </a-tooltip> -->
        </span>
        <a-slider
          :min="0"
          :max="1"
          :step="0.1"
          @change="handleAlphaIntensityChange"
        />
      </a-form-item>
      <a-form-item>
        <span slot="label">
          {{ $t("adjustColor") }}
          <!-- <a-tooltip placement="bottom" title="调试">
				     <a-icon type="question-circle-o" />
				   </a-tooltip> -->
        </span>
        <color-picker
          class="color-picker"
          v-if="showColorPick"
          @change="handleAlbeColorPicker"
        ></color-picker>
      </a-form-item>
    </div>
    <!-- <div v-else-if="type == 'logo'">
      <a-form-item>
        <span slot="label">
          {{ $t("logoSize") }}
        </span>
        <a-slider
          :min="5"
          :max="200"
          :step="1"
          :default-value="20"
          @change="handleLogoSizeChange"
        ></a-slider>
      </a-form-item>
      <a-form-item>
        <span slot="label">
          {{ $t("logoAngle") }}
        </span>
        <a-slider
          :min="0"
          :max="6.4"
          :step="0.01"
		  :default-value="0"
          @change="handleLogoAngleChange"
        ></a-slider>
      </a-form-item>
	  <a-form-item>
		  <div>
			   <a-icon class="arrowForword" type="caret-up" @click="targetChange('top')"/>
		  </div>
		  <div>
			  <a-icon class="arrowForword" type="caret-left" @click="targetChange('left')"/>
			  <a-icon class="arrowForword" type="caret-down" @click="targetChange('down')"/>
			  <a-icon class="arrowForword" type="caret-right" @click="targetChange('right')"/>
		  </div>
	  </a-form-item> -->
      <!-- <a-form-item>
        <span slot="label">
          {{ $t("logoHorizontal") }}
        </span>
        <a-slider
          :min="-10"
          :max="10"
          :step="1"
          :default-value="0"
          @change="handleLogoHorizontalChange"
        ></a-slider>
      </a-form-item>
      <a-form-item>
        <span slot="label">
          {{ $t("logoVertical") }}
        </span>
        <a-slider
          :min="-10"
          :max="10"
          :step="1"
          :default-value="0"
          @change="handleLogoVerticalChange"
        ></a-slider>
      </a-form-item> -->
    </div>
  </a-form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");

import ColorPicker from "@/components/v2/color-picker/ColorPicker.vue";

export default {
  components: { ColorPicker },
  props: {
    visiable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "texture",
    },
    title: {
      type: String,
      default: "Texture set",
    },
  },
  data() {
    return {
      showColorPick: true,
    };
  },
  computed: {
    ...mapState(["saveModelData","logos"]),
  },
  mounted() {},
  methods: {
    ...mapActions(["textureSet", "createLogo", "getLogo", "moveLogo"]),
    handleTextureSizeChange(val) {
      // if (this.global.ModelScene.pickedMesh) {
      //   let data = {
      //     ModelScene: this.global.ModelScene,
      //     val: val,
      //     type: "size",
      //   };
      //   this.textureSet(data);
      // }
      this.global.ModelScene.setPBR({
        albedoTexture: {
          uScale: val,
          vScale: val,
        },
        bumpTexture: {
          uScale: val,
          vScale: val,
        },
      });
    },
    handleTextureAngleChange(val) {
      // if (this.global.ModelScene.pickedMesh) {
      //   let data = {
      //     ModelScene: this.global.ModelScene,
      //     val: val,
      //     type: "angle",
      //   };
      //   this.textureSet(data);
      // }
      this.global.ModelScene.setPBR({
        albedoTexture: {
          wAng: val,
        },
        bumpTexture: {
          wAng: val,
        },
      });
    },
    handleTextureHorizontalChange(val) {
      // if (this.global.ModelScene.pickedMesh) {
      //   let data = {
      //     ModelScene: this.global.ModelScene,
      //     val: val,
      //     type: "horizontal",
      //   };
      //   this.textureSet(data);
      // }
      this.global.ModelScene.setPBR({
        albedoTexture: {
          uOffset: val,
        },
        bumpTexture: {
          uOffset: val,
        },
      });
    },
    handleTextureVerticalChange(val) {
      // if (this.global.ModelScene.pickedMesh) {
      //   let data = {
      //     ModelScene: this.global.ModelScene,
      //     val: val,
      //     type: "vertical",
      //   };
      //   this.textureSet(data);
      // }
      this.global.ModelScene.setPBR({
        albedoTexture: {
          vOffset: val,
        },
        bumpTexture: {
          vOffset: val,
        },
      });
    },
    handleAlbeColorPicker(val) {
      // if (
      //   this.global.ModelScene.pickedMesh &&
      //   this.global.ModelScene.pickedMesh.material
      // ) {
      //   let data = {
      //     ModelScene: this.global.ModelScene,
      //     val: val,
      //     type: "albedoColor",
      //   };
      //   this.textureSet(data);
      // }
      let light = 0.3 * val.r + 0.6 * val.g + 0.1 * val.b;
      light = light < 0.3 ? 0.3 : light;
      this.global.ModelScene.setPBR({
        albedoColor: val,
        reflectionColor: val,
        directIntensity: light,
      });
    },
    handleEnvironmentIntensityChange(val) {
      // if (this.global.ModelScene.pickedMesh) {
      //   let data = {
      //     ModelScene: this.global.ModelScene,
      //     val: val,
      //     type: "EnvironmentIntensity",
      //   };
      //   this.textureSet(data);
      // }
      this.global.ModelScene.setPBR({
        environmentIntensity: val,
      });
    },
    handleLogoSizeChange(val) {
      this.saveModelData[this.global.ModelScene.pickedMesh.name].logo.size = {
        x: val,
        y: val,
        z: val,
      };
      let data = {
        logoData:
          this.saveModelData[this.global.ModelScene.pickedMesh.name].logo,
        ModelScene: this.global.ModelScene,
      };
      this.global.ModelScene.deleteLogo();
      this.getLogo(data);
    },
    handleLogoAngleChange(val) {
      this.saveModelData[this.global.ModelScene.pickedMesh.name].logo.angle =
        val;
      let data = {
        logoData:
          this.saveModelData[this.global.ModelScene.pickedMesh.name].logo,
        ModelScene: this.global.ModelScene,
      };
      this.global.ModelScene.deleteLogo();
      this.getLogo(data);
    },
    handleLogoHorizontalChange(val) {
      this.saveModelData[this.global.ModelScene.pickedMesh.name].logo.pointerX =
        this.global.ModelScene.pointer.pointerX + val;
      let data = {
        logoData:
          this.saveModelData[this.global.ModelScene.pickedMesh.name].logo,
        ModelScene: this.global.ModelScene,
      };
      this.moveLogo(data);
    },
    handleLogoVerticalChange(val) {
      this.saveModelData[this.global.ModelScene.pickedMesh.name].logo.pointerY =
        this.global.ModelScene.pointer.pointerY + val;
      let data = {
        logoData:
          this.saveModelData[this.global.ModelScene.pickedMesh.name].logo,
        ModelScene: this.global.ModelScene,
      };
      this.moveLogo(data);
    },
    handleAlphaIntensityChange(val) {
      // if (this.global.ModelScene.pickedMesh) {
      //   let data = {
      //     ModelScene: this.global.ModelScene,
      //     val: val,
      //     type: "alpha",
      //   };
      //   this.textureSet(data);
      // }
      this.global.ModelScene.setPBR({
        alpha: val,
      });
    },
	targetChange(type){
		if(type == 'top'){
			this.saveModelData[this.global.ModelScene.pickedMesh.name].logo.pointerY--;
			this.global.ModelScene.deleteLogo();
			this.global.ModelScene.moveLogo(this.global.ModelScene.pickedMesh,this.saveModelData[this.global.ModelScene.pickedMesh.name].logo);
		}else if(type == 'left'){
			this.saveModelData[this.global.ModelScene.pickedMesh.name].logo.pointerX--;
			this.global.ModelScene.deleteLogo();
			this.global.ModelScene.moveLogo(this.global.ModelScene.pickedMesh,this.saveModelData[this.global.ModelScene.pickedMesh.name].logo);
		}else if(type == 'right'){
			this.saveModelData[this.global.ModelScene.pickedMesh.name].logo.pointerX++;
			this.global.ModelScene.deleteLogo();
			this.global.ModelScene.moveLogo(this.global.ModelScene.pickedMesh,this.saveModelData[this.global.ModelScene.pickedMesh.name].logo);
		}else if(type == 'down'){
			this.saveModelData[this.global.ModelScene.pickedMesh.name].logo.pointerY++;
			this.global.ModelScene.deleteLogo();
			this.global.ModelScene.moveLogo(this.global.ModelScene.pickedMesh,this.saveModelData[this.global.ModelScene.pickedMesh.name].logo);
		}
	}
  },
};
</script>

<style lang="scss">
.tab-content {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  .set-title {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
  }
}
.arrowForword{
	font-size: 30px;
	margin: 0 10px;
}
</style>

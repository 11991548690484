<template>
  <div class="member flex flex-col align-center">
    <!-- <img :src="avatar" alt="" class="avatar p-2 base-border mb-10" /> -->
    <div class="avatar p-2 base-border mb-10">
      <el-image :src="avatar">
        <div slot="error" class="image-slot">
          <i class="el-icon-user"></i>
        </div>
      </el-image>
    </div>
    <span class="nick text-color font-size-14 w-42p text-center">
      {{ nick }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    nick: {
      type: String,
      default: "昵称",
    },

    avatar: {
      type: String,
      default: "/img/logo.17662947.png",
    },
  },
};
</script>

<style lang="scss" scoped>
.member {
  .nick {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .avatar {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
}
</style>

<template>
  <a-drawer
    :wrapClassName="wrapClassName"
    :width="width"
    :title="title"
    :visible="visible"
    getContainer=".prod"
    :mask="mask"
    :destroyOnClose="true"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleClose"
  >
    <catalog
      :material="material"
      @mat-change="emit"
      :value="value"
      :materialData="materialData"
    ></catalog>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="handleClose">{{
        $t("cancel")
      }}</a-button>
      <a-button
        type="danger"
        :style="{ marginRight: '8px' }"
        @click="handleCreate"
        >{{ $t("addMaterial") }}</a-button
      >
      <a-button type="primary" @click="handleOk" v-if="materialData.id">{{
        $t("updateMaterial")
      }}</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");
import catalog from "@/components/material/catalog/Catalog";
import mixin from "@/components/material/mixin";
export default {
  components: {
    catalog,
  },
  props: {
    wrapClassName: {
      type: String,
      default: "n-drawer",
    },
    visible: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      default: "编辑",
    },
    mask: {
      default: false,
      type: Boolean,
    },
    width: {
      type: Number,
      default: 420,
    },
    material: {
      default: "metal",
      type: String,
    },
    value: {
      default: () => {},
      type: Object,
    },
    materialData: {
      type: Object,
      default: {},
    },
  },
  created() {},
  methods: {
    ...mapMutations(["SET_HOLDER_VISIBLE"]),
    emit(val) {
      this.$emit("mat-change", val);
    },
    handleOk() {
      this.$emit("update:visible", false);
      this.$emit("ok", "update");
      this.SET_HOLDER_VISIBLE(false);
    },
    handleCreate() {
      this.$emit("update:visible", false);
      this.$emit("ok", "create");
      this.SET_HOLDER_VISIBLE(false);
    },
    handleClose() {
      this.$emit("update:visible", false);
      this.SET_HOLDER_VISIBLE(false);
      document.getElementById("work").style.display = "block";
      document.getElementById("materialWork").style.display = "none";
      document.getElementById("materialIframe").style.display = "none";
      document.getElementById("materialIframe").src = "";
    },
  },
  mixins: [mixin],
};
</script>

<style lang="scss">
.image-upload-picker {
  position: relative;
  .pick-btn {
    position: absolute;
    top: 4px;
    left: 113px;
  }
}
.n-drawer {
  text-align: left;
}
</style>

<template>
  <div class="conversation-list">
    <div class="flex flex-1 h-100 just-center align-center" v-if="loading">
      <a-spin />
    </div>
    <template v-else>
      <div
        class="flex flex-1 h-100 just-center align-center"
        v-if="!groupList.length"
      >
        <a-empty />
      </div>
      <ul class="conversation-wrap" v-else>
        <li
          class="conversation-item w-100 flex just-space-between hvr-color hvr-bg-color radius-4"
          :class="selected == user.meeting_id ? 'selected' : null"
          v-for="(user, idx) in groupList"
          :key="idx"
          @click="handleOpenConvs(user)"
        >
          <div class="left">
            <div class="avatar mr-8 radius-8">
              <el-image :src="user.meeting_img">
                <div slot="error" class="image-slot">
                  <i class="el-icon-user"></i>
                </div>
              </el-image>
            </div>
            <!-- <img class="avatar mr-8 radius-8" :src="user.meeting_img" alt="" /> -->
            <i
              class="iconfont icon-tishi1 conversation-tishi"
              v-if="user.unread > 0"
            ></i>
            <div class="nick-lastmsg">
              <span class="nickname font-size-14 font-600">
                {{ user.meeting_name }}</span
              >
              <span class="time font-size-10 text-info-color">
                {{ user.formatTime }}
              </span>
              <!-- <div class="text-color mb-4 font-size-16 flex just-space-between">
                <span class="nickname font-size-14 font-600">
                  {{ user.meeting_name }}</span
                >
                <span class="time font-size-10 text-info-color">
                  {{ user.formatTime }}
                </span>
              </div> -->
              <!-- <div class="last-msg font-size-10 text-info-color">
              <span v-if="user.unread > 0"> [{{ user.unread }}条] </span>
              {{ user.lastmsg }}
            </div> -->
            </div>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";
// import { timestampFormat } from "../utils";
import { createNamespacedHelpers } from "vuex";
const { mapMutations: mapMutationsGroup,mapState } =
  createNamespacedHelpers("jiyang/im");

export default {
  data() {
    return {
      selected: "",
      showlist: [],
      list: [],
      loading: true,
      //获取会议的页码和长度
      meetingpage: 0,
      meetingpagesize: 50,
    };
  },
  watch: {
    list: {
      deep: true,
      handler(val) {
        this.showlist = val;
      },
    },
  },
  computed:{
    ...mapState(['groupList'])
  },
  mounted() {
    this.getGroupList();
  },
  methods: {
    ...mapMutationsGroup(["setGroupList"]),
    handleOpenConvs(user) {
      user.unread = 0;
      this.selected = user.meeting_id;
      this.$emit("click", user);
    },
    searchMeetingGroup(name, file) {
      if (name == "") {
        this.showlist = this.list;
      } else {
        let data = { name: name, file: file };
        this.$api.searchMeeting(data).then((res) => {
          // console.log(res);
          if (res.length == 0) {
            this.showlist = null;
            return;
          }

          this.showlist = this.list.filter((item) => {
            return (
              res.findIndex((val) => item.meeting_id == val.meeting_id) != -1
            );
          });
          // console.log(this.showlist);
        });
      }
    },
    async getGroupList() {
      this.loading = true;
      let data = { page: this.meetingpage, pagesize: this.meetingpagesize };
      const list = await this.$api.getMeeting(data);
      const baseUrl = "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com";

      this.showlist = list.map((group) => {
        let lastmsg = "";
        if (group.meeting_chat_type == 4) {
          lastmsg = this.$t("meetingPicmsg");
        } else if (group.meeting_chat_type == 5) {
          lastmsg = this.$t("meetingPicECL");
        }
        let formatTime = "";
        let unixTime = Number(group.update_time);
        let time = dayjs.unix(group.update_time);
        let today = dayjs().startOf("day").unix();
        let yestday = dayjs().subtract(1, "day").startOf("day").unix();
        if (unixTime > today) {
          formatTime = time.format("HH:mm");
        }
        if (unixTime >= yestday && unixTime <= today) {
          formatTime = "昨天";
        }
        if (today - unixTime >= 86400 && unixTime < yestday) {
          formatTime = time.format("YY/MM/DD");
        }

        return {
          ...group,
          lastmsg,
          meeting_img: baseUrl + group.meeting_img,
          formatTime: formatTime,
          time: dayjs.unix(group.update_time).format("YY/MM/DD"),
        };
      });

      this.setGroupList(this.showlist);
      this.loading = false;

      // this.$api.getMeeting(data).then((res) => {
      //   console.log(res);
      //   this.list = res;
      //   let _this = this;
      //   this.list.forEach((msg) => {
      //     msg.meeting_img =
      //       "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
      //       msg.meeting_img;
      //     if (msg.meeting_chat_type == 4)
      //       msg.lastmsg = _this.$t("meetingPicmsg");
      //     else if (msg.meeting_chat_type == 5)
      //       msg.lastmsg = _this.$t("meetingPicECL");
      //     this.timeTostr(msg);
      //   });
      //   _this.loading = false;
      // });
    },
    newmeeting(newmeeting, type) {
      if (type < 0) {
        const idx = this.list.findIndex(
          (me) => me.meeting_id == newmeeting.meeting_id
        );
        idx >= 0 ? this.list.splice(idx, 1) : "";
        return;
      }
      let meeting = this.list.find(
        (me) => me.meeting_id == newmeeting.meeting_id
      );
      if (meeting == undefined) {
        newmeeting.unread = 0;
        // this.timeTostr(newmeeting);
        this.list.push(newmeeting);
        this.sortmeeting();
      } else {
        meeting.meeting_name = newmeeting.name;
        meeting.meeting_notice = newmeeting.notification;
        meeting.meeting_remarks = newmeeting.introduction;
      }
    },
    newmeetingmsg(newVal, isnow) {
      let _this = this;
      let ismatching = false;
      this.list.forEach((msg) => {
        if (newVal.to == msg.room) {
          ismatching = true;
          msg.update_time = newVal.time;
          // _this.timeTostr(msg);
          if (isnow == 0) {
            msg.unread++;
            this.$emit("addnewmsg", msg);
          } else msg.unread = 0;
          newVal.payload.data = parseInt(newVal.payload.data);
          if (newVal.payload.data == 0)
            msg.lastmsg = newVal.payload.description;
          else if (newVal.payload.data == 4)
            msg.lastmsg = _this.$t("meetingPicmsg");
          else if (newVal.payload.data == 5)
            msg.lastmsg = _this.$t("meetingPicECL");
        }
      });
      if (ismatching) this.sortmeeting();
      else {
        let data = {
          page: this.meetingpage,
          pagesize: this.meetingpagesize,
          room: newVal.to,
        };
        this.$api.getMeeting(data).then((res) => {
          res[0].meeting_img =
            "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
            res[0].meeting_img;
          if (res[0].meeting_chat_type == 4)
            res[0].lastmsg = _this.$t("meetingPicmsg");
          else if (res[0].meeting_chat_type == 5)
            res[0].lastmsg = _this.$t("meetingPicECL");
          // this.timeTostr(res[0]);
          this.list.push(res[0]);
        });
      }
    },
    sortmeeting() {
      this.list.sort(function (a, b) {
        return b.update_time - a.update_time;
      });
    },
    //时间戳转时间
    timeTostr(msg) {
      // return (msg.time = new Date(msg.update_time * 1000)
      //   .toLocaleString()
      //   .replace(/:\d{1,2}$/, " "));
      // // console.log(msg.time);
    },
  },
};
</script>

<style lang="less">
.conversation-list {
  // height: 757px;
  // height: 80%;
  flex: 1;
  overflow: hidden;
  padding-bottom: 16px;
  .conversation-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* padding: 0px 12px; */
    overflow-y: auto;
    overflow-x: hidden;
  }
  .conversation-tishi {
    position: absolute;
    color: rgb(255, 0, 0);
    margin-left: 30px;
  }
  .selected {
    background-color: #c7c6c5;
  }
  .conversation-item {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 4px;
    padding-bottom: 8px; */
    height: 80px;
    flex-shrink: 0;
    .left {
      padding: 0 16px;
      display: flex;
      align-items: center;
      flex: 1;
      position: relative;
    }
    .nick-lastmsg {
      padding: 4px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      .nickname {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100px;
      }
      .time {
        display: flex;
        align-items: center;
      }
      /* width: 324px; */
    }
    .avatar {
      width: 40px;
      height: 40px;
      display: flex;
      font-size: 36px;
      justify-content: center;
      align-items: center;
    }

    .last-msg {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* width: 288px; */
    }
  }
}
</style>

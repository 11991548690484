<template>
  <div class="mask-layer"></div>
</template>

<script>
export default {
  name: "maskLayer",
};
</script>

<style scoped>
.mask-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9998;
  background-color: #000;
  opacity: 0.4;
}
</style>

export default {
    data() {
        return {
            placementOptions: [
                {
                    value: "鞋子",
                    label: "鞋子",
                    children: [
                        {
                            value: "大底",
                            label: "大底",
                        },
                        {
                            value: "帮面",
                            label: "内里",
                        },
                        {
                            value: "鞋带",
                            label: "鞋带",
                        },
                        {
                            value: "饰扣",
                            label: "饰扣",
                        },
                    ],
                },
            ],
        }
    },
    methods: {
        emit(val) {
            this.$emit("mat-change", val);
        },
        toFloat(val) {
            return parseFloat((val / 255).toFixed(2));
        },
        toInt(val) {
            return parseInt(val * 255);
        },
        onChangePlacement(value, selectedOptions) {
            console.log(value, selectedOptions);
        },
    }
}
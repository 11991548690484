<template>
  <div class="message-send-wrap">
    <div class="message-send">
      <div class="tool bg-gray-3">
        <i
          class="iconfont font-size-22 pointer"
          :class="icon"
          v-for="(icon, idx) in icons"
          :key="idx"
          @click="handleToolClick(idx)"
        ></i>
        <emoji :visible.sync="showEmoji" @select="handleSelectEmoji"></emoji>
      </div>
      <div
        class="w-100 relative textarea-wrap"
        @drop="handleDrop"
        @dragend="handleDrgend"
        @dragenter="handleDragenter"
        @dragover="handleDragOver"
        @dragleave="handleDragLeave"
        ref="textareaWrap"
      >
        <!-- v-model="textarea" -->
        <textarea
          contenteditable="true"
          v-model="textarea"
          :disabled="disabled"
          class="edit-area bg-gray-3"
          rows="10"
          autocomplete
          maxlength="1000"
          ref="editor"
          @click="$refs.editor.focus()"
          @keyup.enter="handleSend"
        ></textarea>
        <button class="btn send pointer primary-color-v2" @click="handleSend">
          发送
        </button>
      </div>
      <!-- <a-dropdown :trigger="['contextmenu']">
        <template #overlay>
          <a-menu @click="handleClick">
            <a-menu-item key="1">粘贴</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown> -->
      <file-send-confirm
        :visible.sync="showSendConfirm"
        :files="files"
        :userInfo="userInfo"
        @ok="handleOk"
      />
    </div>
    <div class="mask" v-if="disabled">
      <span>选择一个左侧会话窗口开始聊天</span>
    </div>
    <a-modal
      v-model="visible"
      title="是否发送图片"
      @ok="handleModalOk"
      cancelText="取消"
      okText="发送"
    >
      <img
        class="preveiw-img"
        :src="imgSrc"
        alt=""
        width="100%"
        height="100%"
      />
    </a-modal>
  </div>
</template>

<script>
import emoji from "./emoji.vue";
import FileSendConfirm from "../components/modal/file-uploader-modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("jiyang/im");
export default {
  components: {
    emoji,
    FileSendConfirm,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      showEmoji: false,
      textarea: "",
      icons: ["icon-biaoqing", "icon-wenjianjia_o", ""],
      files: [],
      showSendConfirm: false,
      imgSrc: "",
      blob: null,
    };
  },
  mounted() {
    this.$refs.editor.addEventListener("paste", (e) => {
      const dataTransfer = e.clipboardData.items;
      const items = [].slice.call(dataTransfer).filter((item) => {
        return item.type.indexOf("image") !== -1;
      });
      if (items.length === 0) {
        return;
      }
      const dataTransferItem = items[0];
      const blob = dataTransferItem.getAsFile();
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.imgSrc = e.target.result;
        this.visible = true;
        this.blob = blob;
      };
      fileReader.readAsDataURL(blob);
    });
  },
  methods: {
    handleDrag(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.files = Array.from(files).map((f, idx) => {
        return {
          id: idx,
          icon: "",
          name: f.name,
          size: f.size,
          type: f.type,
          file: f,
        };
      });
      this.showSendConfirm = true;
    },
    handleDrgend(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragenter(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    handleDragOver(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragLeave(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    handleSend(e) {
      if (this.textarea.trim() == "") {
        this.$message("不能发送空消息");
        return;
      }
      this.$emit("sendmsg", 0, this.textarea);
      this.textarea = "";
    },
    handleToolClick(idx) {
      if (idx == 0) {
        this.emoji();
        return;
      }
      if (idx == 1) {
        this.$emit("uploadfile");
        return;
      }
      if (idx == 2) {
        this.$emit("sendinside");
        return;
      }
    },
    emoji() {
      this.showEmoji = true;
    },
    async handleClick() {
      // console.log("click");
      const text = await navigator.clipboard.read();
      // console.log(text);
      this.textarea += text;
    },
    handleSelectEmoji(emoji) {
      this.textarea += emoji.data;
    },
    handleModalOk() {
      this.$emit("upload", [
        {
          id: Date.now(),
          name: `剪贴板图片 ${Date.now()}`,
          type: "image/png",
          file: this.blob,
        },
      ]);
      this.visible = false;
    },
    handleOk(files) {
      this.$emit("upload", files);
    },
  },
  computed: {
    ...mapState(["current", "sdkReady"]),
    userInfo() {
      return {
        avatar: this.current.meeting_img,
        nickname: this.current.meeting_name,
      };
    },
    tip() {
      return this.sdkReady
        ? "选择一个左侧会话窗口开始聊天"
        : "聊天系统初始化中";
    },
  },
};
</script>

<style lang="less" scoped>
.message-send-wrap {
  position: relative;
  width: 100%;
  height: calc(100% - 68px - 55%);
  .mask {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 99%;
    top: 0px;
    left: 0px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .preveiw-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.message-send {
  width: 100%;
  height: 100%;
  position: relative;
  .tool {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 10px;
    position: relative;
    > i {
      color: #a9a9a9;
      margin-right: 8px;
    }
    .emoji-picker {
      position: absolute;
      top: 41px;
      left: 0px;
      z-index: 3;
      .container-emoji {
        height: 124px !important;
      }
    }
  }
  .textarea-wrap {
    height: calc(100% - 55px);
    overflow: hidden;
  }
  .edit-area {
    width: 100%;
    height: 80%;
    border: none;
    outline: none;
    padding-left: 10px;
    resize: none;
  }
  .send {
    outline: none;
    border: none;
    position: absolute;
    bottom: 13px;
    right: 32px;
    color: #16c39a;
    background: #e1e1e1;
    padding: 4px 8px;
  }
  .send {
    width: 74px;
    height: 30px;
    padding: 4px 4px;
  }
}
</style>

export default ({ service, request }) => ({
  getClueList(data) {
   // let data = usePage(page);
    return request({
      url: "/v1/clue/getList",
      method: "post",
      data,
    });
  },
  receiveClue(data) {
    let { customer_id } = data;
    return request({
      url: "/v1/clue/acceptClue",
      method: "post",
      data: {
        id: customer_id,
      },
    });
  },
  createClue(data) {
    return request({
      url: "/v1/clue/saveClue",
      method: "post",
      data,
    });
  },
  async getPublicInquiry(pageInfo) {
    pageInfo = usePage(pageInfo);
    const { total, per_page, current_page, data } = await request({
      url: "/v1/form/getSeaEnquiry",
      method: "get",
      data: { pageInfo },
    });
    return { total, per_page, current_page, data };
  },
  receivePublicInquiry(data) {
    console.log("公海询盘");
    let { id } = data;
    return request({
      url: "/v1/form/receiveSeaEnquiry/" + data.id,
      method: "post",
    });
  },
  //把线索放回公海
  putClueToSea(data){
	  return request({
		  url:'v1/clue/putClueToSea',
		  method:'post',
		  data
	  })
  }
});

const usePage = (pageInfo) => {
  const { pageSize, current } = pageInfo;
  return {
    page: current,
    page_size: pageSize,
  };
};

export default ({ request }) => ({
    // // 商品列表
    // getProductList(params) {
    //     return request({
    //         url: '/v1/product',
    //         method: 'get',
    //         params
    //     })
    // },
    // //新增商品
    // addProduct(data) {
    //     return request({
    //         url: '/v1/product',
    //         method: 'post',
    //         data
    //     })
    // },
    // //    商品详情
    // getProductDetail(params) {
    //     return request({
    //         url: `/v1/product/${params.id}`,
    //         method: 'get',
    //         params
    //     })
    // },
    // //    添加心愿单
    // addBaseProject(data) {
    //     return request({
    //         url: '/v1/project/addBaseProject',
    //         method: 'post',
    //         data
    //     })
    // },
    // //    移除心愿单
    // delBaseProject(data) {
    //     return request({
    //         url: '/v1/project/delBaseProject',
    //         method: 'post',
    //         data
    //     })
    // },
    // //    获取相关设计
    // getRelatedList(params) {
    //     return request({
    //         url: `/v1/product/related/${params.id}`,
    //         method: 'get',
    //         params
    //     })
    // },
    // //    添加到项目组
    // goodsJoinProject(data) {
    //     return request({
    //         url: '/v1/project/goodsJoinProject',
    //         method: 'post',
    //         data
    //     })
    // },
    // //    获取项目组列表
    // getProjectList(data) {
    //     return request({
    //         url: '/v1/project/getSelectList',
    //         method: 'post',
    //         data
    //     })
    // },
    createFolder(data) {
        return request({
            url: '/v1/project/saveProject',
            method: 'post',
            data
        })
    },
    
    queryFolders(name) {
        return request({
            url: '/v1/project/getList',
            method: 'post',
            data: { project_name: name ,page_size:100}
        })
    },
    
     queryWish() {
        return request({
            url: '/v1/project/getWishInfo',
            method: 'post',
        })
    },
    queryWishInfo(id) {
         return request({
            url: '/v1/project/getWishInfo',
            method: 'post',
        })
     },
    // filterFolder(name) {
    //     return request({
    //         url: '/v1/project/getSelectList',
    //         method: 'post',
    //         data: {
    //             project_name:name
    //         }
    //     })
    // },
    queryProductList({ foldId,searchname="",type=0, page = 1, pageSize = 12 } = {}) {
        return request({
            url: '/v1/project/getProjectGoodsList',
            method: 'post',
            data: { page, page_size: pageSize, project_id: foldId,search:searchname,type,type }
        })
    }
})

<template>
  <div class="color-picker">
    <div class="color-preview"></div>
  </div>
</template>

<script>
// One of the following themes
import "@simonwep/pickr/dist/themes/classic.min.css"; // 'classic' theme
import "@simonwep/pickr/dist/themes/monolith.min.css"; // 'monolith' theme
import "@simonwep/pickr/dist/themes/nano.min.css"; // 'nano' theme
// Modern or es5 bundle (pay attention to the note below!)
import Pickr from "@simonwep/pickr";
import colorString from "color-string";
export default {
  model: {
    prop: "color",
    event: "input",
  },
  props: {
    theme: {
      type: String,
      default: "classic",
      validate: (value) => {
        return ["classic", "monolith", "nano"].indexOf(value) !== -1;
      },
    },
    defaultValue: {
      type: Object,
      default: () => ({
        r: 0,
        g: 0,
        b: 0,
      }),
    },
    saveBtn: {
      type: String,
      default: "",
    },
    clearBtn: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "model",
    },
  },
  data() {
    return {
      pickr: null,
    };
  },
  computed: {
    default() {
      let { r = 0, g = 0, b = 0 } = this.defaultValue;
      r = this.toInt(r) || 0;
      g = this.toInt(g) || 0;
      b = this.toInt(b) || 0;
      const hex = colorString.to.hex([r, g, b]);
      return hex;
    },
  },
  mounted() {
    this.pickr = Pickr.create({
      el: ".color-preview",
      theme: this.theme, // or 'monolith', or 'nano'
      //   container: ".color-picker",
      inline: true,
      //   swatches: [
      //     "rgba(244, 67, 54, 1)",
      //     "rgba(233, 30, 99, 0.95)",
      //     "rgba(156, 39, 176, 0.9)",
      //     "rgba(103, 58, 183, 0.85)",
      //     "rgba(63, 81, 181, 0.8)",
      //     "rgba(33, 150, 243, 0.75)",
      //     "rgba(3, 169, 244, 0.7)",
      //     "rgba(0, 188, 212, 0.7)",
      //     "rgba(0, 150, 136, 0.75)",
      //     "rgba(76, 175, 80, 0.8)",
      //     "rgba(139, 195, 74, 0.85)",
      //     "rgba(205, 220, 57, 0.9)",
      //     "rgba(255, 235, 59, 0.95)",
      //     "rgba(255, 193, 7, 1)",
      //   ],
      default: this.default,
      showAlways: true,
      position: "right",
      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,

        // Input / output Options
        interaction: {
          //   hex: true,
          //   rgba: true,
          //   hsla: true,
          //   hsva: true,
          //   cmyk: true,
          input: true,
          clear: this.clearBtn.length > 0,
          save: this.saveBtn.length > 0,
        },
      },
    });
    this.pickr
      .on("hide", (instance) => {
        // console.log('Event: "hide"', instance);
        this.hide(instance);
      })
      .on("show", (color, instance) => {
        // console.log('Event: "show"', color, instance);
        this.show(color);
      })
      .on("save", (color, instance) => {
        // console.log('Event: "save"', color, instance);
        this.show(color);
      })
      .on("clear", (instance) => {
        // console.log('Event: "clear"', instance);
        this.clear(instance);
      })
      .on("change", (color, source, instance) => {
        // console.log('Event: "change"', color, source, instance);/
        // console.log(color);
        // this.change(color);
      })
      .on("changestop", (source, instance) => {
        // console.log('Event: "changestop"', source, instance);
        // console.log(instance.getColor());
        this.change(instance.getColor());
      })
      .on("cancel", (instance) => {
        // console.log('Event: "cancel"', instance);
        this.cancel(instance);
      })
      .on("swatchselect", (color, instance) => {
        // console.log('Event: "swatchselect"', color, instance);
        this.$emit(color);
      });

    if (this.type == "model") {
      this.global.PickrColor = this.pickr;
    } else if (this.type == "material") {
      this.global.PickrMaterialColor = this.pickr;
    }
  },
  methods: {
    hide(val) {
      this.$emit("hide");
    },
    clear(val) {
      this.$emit("clear", val);
    },
    show() {
      this.$emit("show");
    },
    save(val) {
      this.$emit("save", this.rgb2float(val));
    },
    change(val) {
      this.$emit("change", this.rgb2float(val));
      this.$emit("input", this.rgb2float(val));
    },
    input() {
      this.$emit("input", this.rgb2float(val));
    },
    cancel(val) {
      this.$emit("cancel", val);
    },
    swatchselect(val) {
      this.$emit(val);
    },
    toFloat(val) {
      return parseFloat((val / 255).toFixed(2));
    },
    toInt(val) {
      return parseInt(val * 255);
    },
    rgb2float(val) {
      let [r, g, b] = val.toRGBA();
      r = this.toFloat(r);
      g = this.toFloat(g);
      b = this.toFloat(b);
      return { r, g, b };
    },
  },
};
</script>

<style>
.pickr {
  display: none;
}
.pcr-app[data-theme="classic"] {
  width: 23.5em;
}
.pcr-app .pcr-interaction .pcr-result {
  height: 20px;
}
</style>

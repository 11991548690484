import {
  Button,
  message,
  Tabs,
  Select,
  Input,
  Tooltip,
  Dropdown,
  Menu,
  Icon,
  Avatar,
  Spin,
  Form,
  Drawer,
  Row,
  Col,
  DatePicker,
  Popconfirm,
  Upload,
  Pagination,
  Slider,
  InputNumber,
  Collapse,
  Modal,
  Empty,
  Cascader,
  Switch,
  Table,
  Tag,
  Checkbox,
  Radio,
  Popover,
} from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "@/assets/css/ant-theme.less";
import {
  Metal,
  Cloth,
  Leather,
  Fur,
  Glass,
  Sole,
  PBR,
} from "@/components/index";
import Vue from "vue";

import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
const antComps = [
  Button,
  message,
  Tabs,
  Select,
  Input,
  Tooltip,
  Dropdown,
  Menu,
  Icon,
  Avatar,
  Spin,
  Form,
  Drawer,
  Col,
  Row,
  DatePicker,
  Popconfirm,
  Upload,
  Pagination,
  Slider,
  InputNumber,
  Collapse,
  Modal,
  Empty,
  Cascader,
  Switch,
  Table,
  Tag,
  Checkbox,
  Radio,
  Popover,
];
const customComp = [Metal, Cloth, Leather, Fur, Glass, Sole, PBR];
const useAntd = () => {
  antComps.forEach((c) => Vue.use(c));
};
customComp.forEach((c) => Vue.use(c));
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);
message.config({ maxCount: 1 });
Vue.prototype.$amessage = message;

export { useAntd };

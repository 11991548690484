let paramConfig = {
  cosUrl: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com",
};
//加载模型配置参数
let modelConfig = {
  boxId: "work",
  canvasId: "renderCanvas",
  hemiLight: {
    intensity: 0,
  },
  camera: {
    //setPosition:{x:240,y:90,z:-60},
    setPosition: { x: 325, y: 181, z: -159 },
    //setPosition:{x:240,y:90,z:0},
    lowerRadiusLimit: 100,
    upperRadiusLimit: 500,
    attachControl: true,
  },
  hdrTexture:
    "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/texture/environmentSpecular.env",
  meshes: {
    size: 250,
  },
  scene: {
    clearColor: { r: 0, g: 0, b: 0, a: 0 },
    ground: false,
  },
  material: {
    meshes: {
      cap: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/model/materials-04.gltf",
      laces:
        "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/model/materials-06.gltf",
    },
  },
};

//加载模型配置参数
let materialWorkConfig = {
  boxId: "materialWork",
  canvasId: "renderCanvas",
  hemiLight: {
    intensity: 0.9,
  },
  camera: {
    lowerRadiusLimit: 100,
    upperRadiusLimit: 400,
    attachControl: false,
  },
  hdrTexture:
    "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/texture/environmentSpecular.env",
  meshes: {
    size: 180,
  },
  scene: {
    clearColor: { r: 0, g: 0, b: 0, a: 0 },
  },
  material: {
    meshes: {
      cap: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/model/materials-04.gltf",
      laces:
        "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/model/materials-06.gltf",
    },
  },
};

//加载材质配置参数
let materialsConfig = {
  canvas: "",
  boxId: "",
  scene: {
    clearColor: { r: 0, g: 0, b: 0, a: 0 },
  },
  camera: {
    lowerRadiusLimit: 50,
    upperRadiusLimit: 400,
    attachControl: false,
  },
  material: {
    meshes: {
      cap: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/model/materials-04.gltf",
      laces:
        "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/model/materials-06.gltf",
    },
  },
  hdrTexture:
    "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/texture/environmentSpecular.env",
  css: {
    width: "25%",
    height: "6rem",
  },
  meshes: {
    size: 210,
  },
  screenShot: {
    width: 1024,
    height: 1024,
  },
};

// let materials = [
// 	{
// 		id:1,
// 		name:'反射玻璃',
// 		shape:'ball',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:'environments/environmentSpecular.env',
// 			refractionTexture:'environments/environmentSpecular.env',
// 			linkRefractionWithTransparency:true,
// 			indexOfRefraction:0.8,
// 			alpha:0,
// 			microSurface:1,
// 			reflectivityColor:{r:0.2,g:0.2,b:0.2},
// 			albedoColor:{r:0.85,g:0.85,b:0.85},
// 		}
// 	},
// 	{
// 		id:2,
// 		name:'金属',
// 		shape:'ball',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:'environments/environmentSpecular.env',
// 			microSurface:0.96,
// 			albedoColor:{r:0.01,g:0.01,b:0.01},
// 			reflectivityColor:{r:0.85,g:0.85,b:0.85},
// 		}
// 	},
// 	{
// 		id:3,
// 		name:'塑料',
// 		shape:'ball',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:'environments/environmentSpecular.env',
// 			microSurface:0.96,
// 			albedoColor:{r:0.206,g:0.94,b:1},
// 			reflectivityColor:{r:0.003,g:0.003,b:0.003}
// 		}
// 	},
// 	{
// 		id:4,
// 		name:'木头',
// 		shape:'cap',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:'environments/environmentSpecular.env',
// 			environmentIntensity:3,
// 			specularIntensity:0.3,
// 			reflectivityTexture:null,
// 			useMicroSurfaceFromReflectivityMapAlpha:true,
// 			albedoColor:{r:1,g:1,b:1},
// 			albedoTexture:{
// 				url:'https://minio.cnbabylon.com/public/Assets/albedo.png',
// 				vScale:0.5,
// 				uScale:0.5,
// 			},
// 			metallic:0,
// 			roughness:1
// 		}
// 	},
// 	{
// 		id:5,
// 		name:'皮革',
// 		shape:'cap',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:'environments/environmentSpecular.env',
// 			metallic:0,
// 			roughness:0.2,
// 			environmentIntensity:1.5,
// 			albedoTexture:{
// 				url:'materials/bj_19___Default_BaseColor.png',
// 				vScale:0.1,
// 				uScale:0.1,
// 			},
// 			bumpTexture:{
// 				url:'materials/bj_19___Default_Normal.png',
// 				vScale:0.1,
// 				uScale:0.1,
// 				level:1
// 			},
// 			// ambientColor:{r:0.5,g:0.5,b:0.5},
// 			// reflectionColor:{r:0.8,g:0.8,b:0.8}
// 		}
// 	},
// 	{
// 		id:6,
// 		name:'布料',
// 		shape:'cap',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:'environments/environmentSpecular.env',
// 			metallic:0,
// 			roughness:1,
// 			environmentIntensity:1.3,
// 			albedoTexture:{
// 				url:'materials/abcd.jpg',
// 				vScale:0.5,
// 				uScale:0.5,
// 			},
// 			bumpTexture:{
// 				url:'materials/Cotton03.jpg',
// 				vScale:0.5,
// 				uScale:0.5,
// 				level:1.2
// 			},
// 			ambientTexture:{
// 				url:'materials/Cotton03_occ.jpg',
// 				vScale:0.5,
// 				uScale:0.5,
// 				level:1
// 			}
// 		}
// 	},
// 	{
// 		id:7,
// 		name:'绒毛',
// 		shape:'cap',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:'environments/environmentSpecular.env',
// 			metallic:0,
// 			roughness:1,
// 			environmentIntensity:2,
// 			albedoTexture:{
// 				url:'materials/T_Fabric_FurSynthRough_BCH_T_Fabric_FurSynthRough_opacity.png',
// 				vScale:0.8,
// 				uScale:0.8,
// 			},
// 			bumpTexture:{
// 				url:'materials/T_Fabric_FurSynthRough_N.png',
// 				vScale:0.8,
// 				uScale:0.8,
// 				level:1
// 			}
// 		}
// 	},
// 	{
// 		id:8,
// 		name:'透明玻璃',
// 		shape:'ball',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:null,
// 			metallic:0,
// 			roughness:0,
// 			subSurface:{
// 				indexOfRefraction:1,
// 				isRefractionEnabled:true,
// 			},
// 			environmentTexture:'environments/environmentSpecular.env'
// 		}
// 	},
// 	{
// 		name:'半透明物质',
// 		shape:'ball',
// 		type:'pbr',
// 		param:{
// 			reflectionTexture:null,
// 			metallic:0,
// 			roughness:0,
// 			subSurface:{
// 				isTranslucencyEnabled:true
// 			},
// 			environmentTexture:'environments/environmentSpecular.env'
// 		}
// 	},
// 	{
// 		name:'毛发',
// 		shape:'ball',
// 		type:'fur',
// 		param:{
// 			url:'materials/fur.jpg',
// 			quality:20
// 		}
// 	},
// 	{
// 		name:'标准材料',
// 		shape:'cap',
// 		type:'standard',
// 		param:{

// 		}
// 	}
// ]

let materialsData = {
  metal: {
    name: "",
    sn: "",
    cat_id: 3,
    shape: "ball",
    type: "pbr",
    material: "metal",
    is_private: 1,
    is_special: 0,
    param: {
      reflectionTexture: {
        url: "texture/environmentSpecular.env",
        type: "env",
        material: null,
      },
      metallic: 1,
      roughness: 0,
      albedoColor: { r: 1, g: 1, b: 1 },
      reflectionColor: { r: 1, g: 1, b: 1 },
      albedoTexture: {
        url: "",
        type: "color",
        material: null,
        vScale: 1,
        uScale: 1,
      },
      bumpTexture: {
        url: "",
        type: "bump",
        material: null,
        vScale: 1,
        uScale: 1,
        level: 1,
      },
    },
  },
  leather: {
    name: "",
    sn: "",
    cat_id: 1,
    shape: "cap",
    type: "pbr",
    material: "leather",
    is_private: 1,
    is_special: 0,
    param: {
      reflectionTexture: {
        url: "texture/environmentSpecular.env",
        type: "env",
        material: null,
      },
      metallic: 0,
      roughness: 0.2,
      albedoColor: { r: 1, g: 1, b: 1 },
      reflectionColor: { r: 1, g: 1, b: 1 },
      albedoTexture: {
        url: "",
        type: "color",
        material: null,
        vScale: 1,
        uScale: 1,
      },
      bumpTexture: {
        url: "",
        type: "bump",
        material: null,
        vScale: 1,
        uScale: 1,
        level: 1,
      },
    },
  },
  cloth: {
    name: "",
    sn: "",
    cat_id: 2,
    shape: "cap",
    type: "pbr",
    material: "cloth",
    is_private: 1,
    is_special: 0,
    param: {
      reflectionTexture: {
        url: "texture/environmentSpecular.env",
        type: "env",
        material: null,
      },
      metallic: 0,
      roughness: 1,
      albedoColor: { r: 1, g: 1, b: 1 },
      reflectionColor: { r: 1, g: 1, b: 1 },
      albedoTexture: {
        url: "",
        type: "color",
        material: null,
        vScale: 1,
        uScale: 1,
      },
      bumpTexture: {
        url: "",
        type: "bump",
        material: null,
        vScale: 1,
        uScale: 1,
        level: 1,
      },
      ambientTexture: {
        url: "",
        type: "occ",
        material: null,
        vScale: 1,
        uScale: 1,
        level: 1,
      },
    },
  },
  fur: {
    name: "",
    sn: "",
    cat_id: 4,
    shape: "ball",
    type: "fur",
    material: "fur",
    is_private: 1,
    is_special: 0,
    param: {
      diffuseTexture: {
        url: "",
        type: "fur",
        material: null,
      },
      quality: 20,
      furLength: 4,
      furAngle: 0,
      furColor: { r: 1, g: 1, b: 1 },
      furSpacing: 6,
      furDensity: 10,
      furSpeed: 2000,
    },
  },
  glass: {
    name: "",
    sn: "",
    cat_id: 10,
    shape: "ball",
    type: "pbr",
    material: "glass",
    is_private: 1,
    is_special: 0,
    param: {
      reflectionTexture: {
        url: "texture/environmentSpecular.env",
        type: "env",
        material: null,
      },
      indexOfRefraction: 0.52,
      alpha: 0.7,
      directIntensity: 0.0,
      environmentIntensity: 0.7,
      cameraExposure: 0.66,
      cameraContrast: 1.66,
      microSurface: 1,
      reflectivityColor: { r: 0.2, g: 0.2, b: 0.2 },
      albedoColor: { r: 0.95, g: 0.95, b: 0.95 },
      reflectionColor: { r: 0.95, g: 0.95, b: 0.95 },
      metallic: 0,
      roughness: 1,
    },
  },
  sole: {
    name: "",
    sn: "",
    cat_id: 11,
    shape: "ball",
    type: "pbr",
    material: "leather",
    is_private: 1,
    is_special: 0,
    param: {
      reflectionTexture: {
        url: "texture/environmentSpecular.env",
        type: "env",
        material: null,
      },
      metallic: 0,
      roughness: 0.2,
      albedoColor: { r: 1, g: 1, b: 1 },
      reflectionColor: { r: 1, g: 1, b: 1 },
      albedoTexture: {
        url: "",
        type: "color",
        material: null,
        vScale: 1,
        uScale: 1,
      },
      bumpTexture: {
        url: "",
        type: "bump",
        material: null,
        vScale: 1,
        uScale: 1,
        level: 1,
      },
    },
  },
  pbr: {
    name: "",
    sn: "",
    cat_id: 2,
    shape: "cap",
    type: "pbr",
    material: "cloth",
    is_private: 1,
    param: {
      metallic: 0,
      roughness: 1,
      indexOfRefraction: 1,
      alpha: 1,
      directIntensity: 0,
      environmentIntensity: 1,
      cameraExposure: 1,
      cameraContrast: 1,
      microSurface: 1,
      reflectivityColor: { r: 0, g: 0, b: 0 },
      albedoColor: { r: 1, g: 1, b: 1 },
      reflectionColor: { r: 1, g: 1, b: 1 },
      reflectionTexture: {
        url: "texture/environmentSpecular.env",
        type: "env",
        material: null,
      },
      albedoTexture: {
        url: "",
        type: "color",
        material: null,
        vScale: 1,
        uScale: 1,
      },
      bumpTexture: {
        url: "",
        type: "bump",
        material: null,
        vScale: 1,
        uScale: 1,
        level: 1,
      },
      ambientTexture: {
        url: "",
        type: "occ",
        material: null,
        vScale: 1,
        uScale: 1,
        level: 1,
      },
    },
  },
};

let materials = [
  {
    id: 1,
    name: "金属",
    shape: "ball",
    type: "pbr",
    material: "metal",
    param: {
      reflectionTexture: {
        url: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/texture/environmentSpecular.env",
        id: 1,
        type: "env",
        material: null,
      },
      microSurface: 0.96,
      albedoColor: { r: 0.01, g: 0.01, b: 0.01 },
      reflectivityColor: { r: 0.85, g: 0.85, b: 0.85 },
    },
  },
  {
    id: 2,
    name: "布料",
    shape: "cap",
    type: "pbr",
    material: "cloth",
    param: {
      reflectionTexture: {
        url: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/texture/environmentSpecular.env",
        id: 1,
        type: "env",
        material: null,
      },
      metallic: 0,
      roughness: 1,
      environmentIntensity: 1.3,
      // albedoTexture:{
      // 	url:'materials/abcd.jpg',
      // 	id:2,
      // 	type:'color',
      // 	material:['cloth'],
      // 	vScale:0.5,
      // 	uScale:0.5,
      // },
      // bumpTexture:{
      // 	url:'materials/Cotton03.jpg',
      // 	id:3,
      // 	type:'bump',
      // 	material:['cloth'],
      // 	vScale:0.5,
      // 	uScale:0.5,
      // 	level:1.2
      // },
      // ambientTexture:{
      // 	url:'materials/Cotton03_occ.jpg',
      // 	id:3,
      // 	type:'occ',
      // 	material:null,
      // 	vScale:0.5,
      // 	uScale:0.5,
      // 	level:1
      // }
    },
  },
  {
    id: 3,
    name: "皮革",
    shape: "cap",
    type: "pbr",
    material: "leather",
    param: {
      reflectionTexture: {
        url: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/texture/environmentSpecular.env",
        id: 1,
        type: "env",
        material: null,
      },
      metallic: 0,
      roughness: 0.2,
      environmentIntensity: 1.5,
      // albedoTexture:{
      // 	url:'materials/bj_19___Default_BaseColor.png',
      // 	id:4,
      // 	type:'color',
      // 	material:['leather'],
      // 	vScale:0.1,
      // 	uScale:0.1,
      // },
      // bumpTexture:{
      // 	url:'materials/bj_19___Default_Normal.png',
      // 	id:5,
      // 	type:'bump',
      // 	material:['leather'],
      // 	vScale:0.1,
      // 	uScale:0.1,
      // 	level:1
      // }
    },
  },
  // {
  // 	id:4,
  // 	name:'毛发',
  // 	shape:'ball',
  // 	type:'fur',
  // 	material:'fur',
  // 	param:{
  // 		diffuseTexture:{
  // 			url:'materials/fur.jpg',
  // 			id:6,
  // 			type:'fur',
  // 			material:['fur']
  // 		},
  // 		quality:20,
  // 		furLength:4,
  // 		furAngle:0,
  // 		furColor:{r:1,g:1,b:1},
  // 		furSpacing:6,
  // 		furDensity:10,
  // 		furSpeed:2000
  // 	}
  // }
];

export {
  modelConfig,
  materials,
  paramConfig,
  materialsConfig,
  materialsData,
  materialWorkConfig,
};

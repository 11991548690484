<template>
  <div class="normal-table-position">
    <el-table
      :data="table.data"
      header-cell-class-name="table-header"
      :border="true"
      :show-header="false"
    >
      <el-table-column
        v-for="(val, key) in table.props"
        :prop="val.prop"
        label="Title"
        align="left"
        :key="key"
        :width="val.width"
      >
        <template slot-scope="scope">
          <div>
            <div v-if="!isUnEdit">
              <div v-if="val.type === 'input'" class="input-cell">
                <div
                  v-if="scope.row[val.prop].type === 'normal'"
                  class="w-full"
                >
                  <el-input
                    v-model="scope.row[val.prop].value"
                    type="textarea"
                    autosize
                    size="mini"
                    v-if="!checkEdit"
                  ></el-input>
                  <span v-else>{{ scope.row[val.prop].value }}</span>
                </div>
                <div v-if="scope.row[val.prop].type === 'date'">
                  <el-date-picker
                    v-if="!checkEdit"
                    v-model="scope.row[val.prop].value"
                    size="mini"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                  <span v-else>{{ scope.row[val.prop].value }}</span>
                </div>
                <div v-if="scope.row[val.prop].type === 'header'">
                  <span class="header-text">{{
                    scope.row[val.prop].value
                  }}</span>
                </div>
                <div v-if="scope.row[val.prop].type === 'input'" class="w-full">
                  <el-input
                    v-model="scope.row[val.prop].value"
                    type="textarea"
                    autosize
                    size="mini"
                    v-if="!checkEdit"
                  ></el-input>
                  <span v-else>{{ scope.row[val.prop].value }}</span>
                </div>
                <div
                  v-if="scope.row[val.prop].type === 'custom'"
                  class="w-full"
                >
                  <el-select
                    v-if="!checkEdit"
                    v-model="custom_id"
                    placeholder="请选择"
                    @change="changeCustom"
                    filter
                  >
                    <el-option label=" " :value="0" style="display: none">
                    </el-option>
                    <el-option
                      v-for="(val, key) in customList"
                      :label="val.enterprise"
                      :value="val.user_id"
                      :key="key"
                    >
                    </el-option>
                  </el-select>
                  <span v-else></span>
                </div>
                <div
                  v-if="scope.row[val.prop].type === 'unedit'"
                  class="unedit-text"
                >
                  <span>{{ scope.row[val.prop].value }}</span>
                </div>
              </div>
              <div v-if="val.type === 'title'" class="form-title">
                <el-input
                  v-model="scope.row[val.prop].value"
                  size="mini"
                  v-if="checkEdit"
                ></el-input>
                <span v-else class="font-600">{{
                  scope.row[val.prop].value
                }}</span>
              </div>
            </div>
            <div v-else>
              <div v-if="val.type === 'input'" class="input-cell">
                <div v-if="scope.row[val.prop].type === 'header'">
                  <span class="header-text">{{
                    scope.row[val.prop].value
                  }}</span>
                </div>
                <div
                  v-else-if="scope.row[val.prop].type === 'custom'"
                  class="w-full"
                >
                  <span>{{ customer.enterprise }}</span>
                </div>
                <span v-else>{{ scope.row[val.prop].value }}</span>
              </div>
              <div v-if="val.type === 'title'" class="form-title">
                <span class="font-600">{{ scope.row[val.prop].value }}</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <addCell @addRow="addRow" v-if="checkEdit"></addCell>
  </div>
</template>

<script>
import addCell from "./addCell";
import { mapState } from "vuex";
export default {
  name: "normalTable",
  components: {
    addCell,
  },
  props: {
    table: {
      type: Object,
      default: () => {
        return {
          props: [
            { prop: "title", type: "title" },
            { prop: "value", type: "input" },
          ],
          data: [
            { title: "From", value: "" },
            { title: "Attention", value: "" },
            { title: "Address", value: "" },
            { title: "Phone", value: "" },
            { title: "Email", value: "" },
          ],
        };
      },
    },
    checkEdit: {
      type: Boolean,
      default: false,
    },
    index: {
      type: String,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    customArr: {
      type: Array,
    },
    checkExport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customList: this.customArr,
      custom_id: 0,
      customer: {},
    };
  },
  computed: {
    ...mapState("jiyang/user", ["info", "userRole"]),
    ...mapState("jiyang/form", ["formState", "formNowType"]),
    isUnEdit() {
      if (
        ((this.info.user_type == 2 || this.info.user_type == 4) &&
          this.formState == 2) ||
        (this.info.user_type != 2 &&
          this.info.user_type != 4 &&
          this.formState == 1) ||
        this.formState == 3 ||
        this.formNowType >= 5
      ) {
        return true;
      }
      return false;
    },
    tableWidth() {
      if (this.index === "terms" || this.index === "payment") {
        return 500 + "px";
      } else {
        return 300 + "px";
      }
    },
  },
  watch: {
    customArr(val) {
      this.customList = val;
    },
  },
  created() {
    // if (
    //   this.index == "userMsg" &&
    //   this.userRole === "custom" &&
    //   this.formState == 1
    // ) {
    //   this.table.data.shift();
    // }
  },
  methods: {
    //  添加行
    addRow() {
      this.$emit("addRow", {
        index: this.index,
      });
    },

    //选择客户
    changeCustom(e) {
      this.$emit("changeCustom", {
        id: this.custom_id,
      });
    },
    flexColumnWidth(str, data, flag = "max") {
      // str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
      // flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
      // flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
      str = str + "";
      let tableData = data.data;
      let columnContent = "";
      if (
        !tableData ||
        !tableData.length ||
        tableData.length === 0 ||
        tableData === undefined
      ) {
        return;
      }
      if (!str || !str.length || str.length === 0 || str === undefined) {
        return;
      }
      if (flag === "equal") {
        // 获取该列中第一个不为空的数据(内容)
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str].length > 0) {
            // console.log('该列数据[0]:', tableData[0][str])
            columnContent = tableData[i][str];
            break;
          }
        }
      } else {
        // 获取该列中最长的数据(内容)
        let index = 0;
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i][str] === null) {
            return;
          }
          const now_temp = tableData[i][str].value + "";
          const max_temp = tableData[index][str].value + "";
          if (now_temp.length > max_temp.length) {
            index = i;
          }
        }
        columnContent = tableData[index][str].value;
      }
      // 以下分配的单位长度可根据实际需求进行调整
      let flexWidth = 12;
      for (const char of columnContent) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          flexWidth += 6;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          flexWidth += 13;
        } else {
          // 其他种类字符，为字符分配8个单位宽度
          flexWidth += 6;
        }
      }
      let props = data.props;
      props.some((val) => {
        if (val.prop === str) {
          if (flexWidth < val.width) {
            flexWidth = val.width;
          }
          return true;
        }
      });
      return flexWidth + "px";
    },
  },
};
</script>

<style lang="scss">
.normal-table-position {
  .el-table {
    font-size: $base-font-size;
    color: #333;
  }
  .unedit-text {
    min-height: 28px;
    line-height: 28px;
  }
  .w-full {
    width: 100%;
  }
  span {
    font-size: $base-font-size;
  }
  .el-table .cell {
    padding-left: 6px !important;
    padding-right: 6px !important;
    box-sizing: content-box;
    line-height: 20px;
  }
  .el-input--mini .el-input__icon {
    line-height: 22px;
  }
  .el-table td,
  .el-table th {
    padding: 1px 0 !important;
  }
  .el-select {
    height: 22px;
    line-height: 22px;
    width: 100% !important;
    .el-input__icon {
      line-height: 22px !important;
    }
  }
  .el-input__inner {
    height: 28px;
    line-height: 28px;
    font-size: $base-font-size;
    width: 100% !important;
  }
  .el-textarea__inner {
    font-size: $base-font-size;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    resize: none;
    padding: 5px 0px !important;
  }
  .form-title {
    .el-input__inner {
      font-weight: 600;
      font-size: $base-font-size;
    }
  }
  .input-cell {
    min-height: 22px;
    display: flex;
    align-items: center;
    width: 100%;
    .el-input--mini {
      width: 100%;
    }
  }
  .header-text {
    color: $base-config-color;
    font-weight: 600;
    font-size: $base-font-size;
  }
}
</style>

<template>
    <div class="threeDialog">
        <el-dialog title="绑定3D模型" :visible.sync="dialogThreeDVisible" v-if="dialogThreeDVisible" :before-close="closeModal" :modal-append-to-body='false' width="800px">
<!--            <div class="btn-box">-->
<!--                <el-button size="mini" @click="toUploadModel">上传</el-button>-->
<!--            </div>-->

            <el-table
                max-height="500"
                :data="tableData"
                border
                style="width: 100%;">
                <el-table-column
                    prop="cover_pic"
                    label="图样"
                    align="center"
                >
                    <template>

                    </template>
                </el-table-column>
                <el-table-column
                    prop="sn"
                    label="单号"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="style_number"
                    label="款式编号"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    width="160"
                    prop="create_time"
                    label="创建日期"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    width="120"
                    prop="createUser"
                    label="创建人"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div>
                            <div v-if="scope.row.createUser.first_name&&scope.row.createUser.last_name">
                                <span>{{scope.row.createUser.first_name}}{{scope.row.createUser.last_name}}</span>
                            </div>
                            <div v-else>
                                <span>{{scope.row.createUser.user_mobile}}</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="do"
                    label="操作"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div>
                            <el-button size="mini" @click="checkBind(scope.row,1)" v-if="!scope.row.pater_id">
                                绑定
                            </el-button>
                            <el-button size="mini" @click="checkBind(scope.row,2)" v-else>
                                替换
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    big
                    :total="total"
                    :current-page="page"
                    :page-size="page_size"
                    layout="prev, pager, next"
                    @current-change="currentChange"
                />
            </div>
        </el-dialog>
    </div>

</template>

<script>
    export default {
        name: "selectThreeDialog",
        props:{
            dialogThreeDVisible:{
                type:Boolean,
                default:true
            },
            parentId:{
                type:Number,
                default:0
            },
        },
        data(){
            return{
                total:0,
                page:1,
                page_size:10,
                tableData:[],
                updateType:1,
            }
        },
        created(){
            this.get3DTableData()
        },
        methods:{
            closeModal(){
                this.$emit('closeModal')
            },
            //绑定
            checkBind(row,type){
                let that=this
                this.updateType=type
                this.$confirm(type==1?`是否绑定该3D模型?`:`当前3D模型已绑定设计图,是否覆盖?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText:  '取消',
                    type: 'warning'
                }).then(() => {
                    let force=type==1?0:1
                    that.toBind(row.id,force)
                }).catch(() => {

                })
            },
            //去绑定
            toBind(id,force){
                let that=this
                let data={
                    id,
                    pater_id:this.parentId,
                    force:force
                }
                this.$api.bindThreeD(data).then(res=>{
                    if(res.error_info){
                        this.$confirm(`检测到当前3D模型已绑定设计图,是否覆盖?`, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText:  '取消',
                            type: 'warning'
                        }).then(() => {
                            that.toBind(id,1)
                        }).catch(() => {

                        })
                    }else{
                        this.$emit('bindThreeD')
                    }
                })
            },
            //上传
            toUploadModel(){
                this.$emit('toUploadModel',this.parentId)
            },
            //3d分页
            currentChange(arg){
                this.page = arg // 当前页：arg
                this.get3DTableData()
            },
            //获取3d列表
            get3DTableData(){
                let that=this
                let data={
                    page:this.page,
                    page_size:this.page_size,
                    type:2,
                    search:{}
                }
                this.$api.getBomtable(data).then(res=>{
                    that.tableData=res.data
                    that.total=res.total
                })
            },
        },
    }
</script>

<style  lang="scss">
    .threeDialog{
        .el-dialog__body {
            padding: 10px 20px 20px 20px;
        }
        .btn-box{
            text-align: left;
            margin-bottom: 10px;
        }
    }

</style>

//获取路由参数
let getRouteParam = function (name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
};

//图片转换
let dataURLtoFile = function (dataurl, filename = "file.png") {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(dataurl.substring(dataurl.indexOf(",") + 1));
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

//打开本地数据库
let db_create = function (config) {
  return new Promise((resolve) => {
    var db;
    var indexedDB =
      window.indexedDB ||
      window.webkitIndexedDB ||
      window.mozIndexedDB ||
      window.msIndexedDB;

    var request = indexedDB.open(config.db_name, config.db_version);

    request.onerror = function (e) {
      resolve();
      //console.log(e.currentTarget.error.message);
    };
    request.onsuccess = function (e) {
      db = e.target.result;
      console.log("打开数据库成功");
      resolve(db);
    };
    request.onupgradeneeded = function (event) {
      db = event.target.result;
      //模型表
      if (!db.objectStoreNames.contains(config.table_name)) {
        console.log("创建表" + config.table_name + "成功");
        db.createObjectStore(config.table_name, { keyPath: config.table_key });
      }
    };
  });
};

//读取本地数据
var db_read = function (db, table_name, key_id) {
  return new Promise((resolve) => {
    let store = db
      .transaction([table_name], "readwrite")
      .objectStore(table_name);
    let req = store.get(key_id);
    req.onerror = function (event) {
      console.log("读取数据失败");
    };
    req.onsuccess = function (event) {
      resolve(req.result);
    };
  });
};

//添加数据
var db_add = function (db, table_name, data) {
  return new Promise(async (resolve) => {
    let req = db
      .transaction([table_name], "readwrite")
      .objectStore(table_name)
      .put(data);
    req.onsuccess = await function (event) {
      console.log("数据写入成功");
    };

    req.onerror = await function (event) {
      console.log("数据写入失败");
    };

    resolve();
  });
};

//缓存模型
var loadModel = function (path) {
  return new Promise((resolve) => {
    var request = new XMLHttpRequest();
    request.open("GET", path, true);
    request.responseType = "blob";
    request.send();
    request.onreadystatechange = function () {
      if (request.readyState === 4 && request.status == 200) {
        var reader = new FileReader();
        //将文件以Data URL形式进行读入页面
        reader.readAsArrayBuffer(request.response);
        reader.onload = function (e) {
          var bf = this.result;
          var blob = new Blob([bf], { type: "text/plain" });
          resolve(blob);
        };
      }
    };
  });
};

//读取本地模型
var readModel = function (modelBlob) {
  return new Promise((resolve) => {
    //读取本地文件，以gbk编码方式输出
    var reader = new FileReader();
    reader.readAsText(modelBlob, "gbk");
    reader.onload = async function () {
      //读取完毕后输出结果
      resolve("data:" + this.result);
    };
  });
};

function image2Base64(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  var dataURL = canvas.toDataURL("image/png");
  return dataURL;
}

function getImgBase64(url, fn) {
  var base64 = "";
  var img = new Image();
  img.src = url;
  img.onload = function () {
    base64 = image2Base64(img);
    fn(base64);
  };
}

//转换为十六进制方法
function colorHex(string) {
  //十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;

  if (/^(rgb|RGB)/.test(string)) {
    var aColor = string.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
    var strHex = "#";
    for (var i = 0; i < aColor.length; i++) {
      var hex = Number(aColor[i]).toString(16);
      if (hex === "0") {
        hex += hex;
      }
      strHex += hex;
    }
    if (strHex.length !== 7) {
      strHex = string;
    }
    return strHex;
  } else if (reg.test(string)) {
    var aNum = string.replace(/#/, "").split("");
    if (aNum.length === 6) {
      return string;
    } else if (aNum.length === 3) {
      var numHex = "#";
      for (var i = 0; i < aNum.length; i += 1) {
        numHex += aNum[i] + aNum[i];
      }
      return numHex;
    }
  } else {
    return string;
  }
}

// r,g,b范围为[0,255],转换成h范围为[0,360]
// s,l为百分比形式，范围是[0,100],可根据需求做相应调整
function rgbtohsl(r, g, b) {
  r = r / 255;
  g = g / 255;
  b = b / 255;

  var min = Math.min(r, g, b);
  var max = Math.max(r, g, b);
  var l = (min + max) / 2;
  var difference = max - min;
  var h, s, l;
  if (max == min) {
    h = 0;
    s = 0;
  } else {
    s = l > 0.5 ? difference / (2.0 - max - min) : difference / (max + min);
    switch (max) {
      case r:
        h = (g - b) / difference + (g < b ? 6 : 0);
        break;
      case g:
        h = 2.0 + (b - r) / difference;
        break;
      case b:
        h = 4.0 + (r - g) / difference;
        break;
    }
    h = Math.round(h * 60);
  }
  s = Math.round(s * 100); //转换成百分比的形式
  l = Math.round(l * 100);
  return [h, s, l];
}

// r,g,b范围为[0,255],转换成h范围为[0,360]
// s,v为百分比形式，范围是[0,100],可根据需求做相应调整
function rgbtohsv(r, g, b) {
  r = r / 255;
  g = g / 255;
  b = b / 255;
  var h, s, v;
  var min = Math.min(r, g, b);
  var max = (v = Math.max(r, g, b));
  var l = (min + max) / 2;
  var difference = max - min;

  if (max == min) {
    h = 0;
  } else {
    switch (max) {
      case r:
        h = (g - b) / difference + (g < b ? 6 : 0);
        break;
      case g:
        h = 2.0 + (b - r) / difference;
        break;
      case b:
        h = 4.0 + (r - g) / difference;
        break;
    }
    h = Math.round(h * 60);
  }
  if (max == 0) {
    s = 0;
  } else {
    s = 1 - min / max;
  }
  s = Math.round(s * 100);
  v = Math.round(v * 100);
  return [h, s, v];
}

//输入的h范围为[0,360],s,l为百分比形式的数值,范围是[0,100]
//输出r,g,b范围为[0,255],可根据需求做相应调整
function hsltorgb(h, s, l) {
  var h = h / 360;
  var s = s / 100;
  var l = l / 100;
  var rgb = [];

  if (s == 0) {
    rgb = [Math.round(l * 255), Math.round(l * 255), Math.round(l * 255)];
  } else {
    var q = l >= 0.5 ? l + s - l * s : l * (1 + s);
    var p = 2 * l - q;
    var tr = (rgb[0] = h + 1 / 3);
    var tg = (rgb[1] = h);
    var tb = (rgb[2] = h - 1 / 3);
    for (var i = 0; i < rgb.length; i++) {
      var tc = rgb[i];
      console.log(tc);
      if (tc < 0) {
        tc = tc + 1;
      } else if (tc > 1) {
        tc = tc - 1;
      }
      switch (true) {
        case tc < 1 / 6:
          tc = p + (q - p) * 6 * tc;
          break;
        case 1 / 6 <= tc && tc < 0.5:
          tc = q;
          break;
        case 0.5 <= tc && tc < 2 / 3:
          tc = p + (q - p) * (4 - 6 * tc);
          break;
        default:
          tc = p;
          break;
      }
      rgb[i] = Math.round(tc * 255);
    }
  }

  return rgb;
}

//输入的h范围为[0,360],s,l为百分比形式的数值,范围是[0,100]
//输出r,g,b范围为[0,255],可根据需求做相应调整
function hsvtorgb(h, s, v) {
  var s = s / 100;
  var v = v / 100;
  var h1 = Math.floor(h / 60) % 6;
  var f = h / 60 - h1;
  var p = v * (1 - s);
  var q = v * (1 - f * s);
  var t = v * (1 - (1 - f) * s);
  var r, g, b;
  switch (h1) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

//获取主题色
function getColor(cube, colorArr) {
  var qulity = 100;
  var maxr = cube[0][0],
    minr = cube[0][0],
    maxg = cube[0][1],
    ming = cube[0][1],
    maxb = cube[0][2],
    minb = cube[0][2];
  for (var i = 0; i < cube.length; i++) {
    if (cube[i][0] > maxr) {
      maxr = cube[i][0];
    }
    if (cube[i][0] < minr) {
      minr = cube[i][0];
    }
    if (cube[i][1] > maxg) {
      maxg = cube[i][1];
    }
    if (cube[i][1] < ming) {
      ming = cube[i][1];
    }
    if (cube[i][2] > maxb) {
      maxb = cube[i][2];
    }
    if (cube[i][2] < minb) {
      minb = cube[i][2];
    }
  }

  if (maxr - minr < qulity && maxg - ming < qulity && maxb - minb < qulity) {
    var r = 0,
      g = 0,
      b = 0;
    for (var i = 0; i < cube.length; i++) {
      r += cube[i][0];
      g += cube[i][1];
      b += cube[i][2];
    }

    colorArr.push([r / cube.length, g / cube.length, b / cube.length]);
  } else {
    var maxrgb = 0;
    var rgbindex = 0;
    var rgbmiddle = 0;

    if (maxr - minr > maxrgb) {
      maxrgb = maxr - minr;
      rgbmiddle = (maxr + minr) / 2;
      rgbindex = 0;
    }
    if (maxg - ming > maxrgb) {
      maxrgb = maxg - ming;
      rgbmiddle = (maxg + ming) / 2;
      rgbindex = 1;
    }
    if (maxb - minb > maxrgb) {
      maxrgb = maxb - minb;
      rgbmiddle = (maxb + minb) / 2;
      rgbindex = 2;
    }

    //排序
    cube.sort(function (x, y) {
      return x[rgbindex] - y[rgbindex];
    });
    var cubea = new Array();
    var cubeb = new Array();
    for (var i = 0; i < cube.length; i++) {
      if (cube[i][rgbindex] < rgbmiddle) {
        cubea.push(cube[i]);
      } else {
        cubeb.push(cube[i]);
      }
    }

    getColor(cubeb, colorArr);
    getColor(cubea, colorArr);
  }
}

function getPictureColor(image) {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");

  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, 300, 150);
  var data = ctx.getImageData(0, 0, image.width, image.height).data; //读取整张图片的像素。

  var rgbArray = new Array();
  for (var i = 0; i < data.length; i += 4) {
    var rdata = data[i]; //240-250
    var gdata = data[i + 1]; //70-100
    var bdata = data[i + 2]; //80-120
    var adata = data[i + 3];
    if (adata > 125) {
      rgbArray.push([rdata, gdata, bdata, adata]);
    }
  }
  var colorArr = [];
  getColor(rgbArray, colorArr);
  return colorArr;
}

export {
  getRouteParam,
  dataURLtoFile,
  db_create,
  db_read,
  db_add,
  loadModel,
  readModel,
  getImgBase64,
  colorHex,
  rgbtohsl,
  rgbtohsv,
  hsltorgb,
  hsvtorgb,
  getPictureColor,
};

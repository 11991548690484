export default ({ service, request }) => ({
  getRecommendRecordList({ id }) {
    return request({
      url: "/v1/recommendform/getRecommendRecordList",
      method: "post",
      data: { id },
    });
  },
  copyRecommendForm(id) {
    return request({
      url: "/v1/recommendform/copyRecommendForm",
      method: "post",
      data: id,
    });
  },
  getUserByNameList(data) {
    return request({
      url: "/v1/users/getUserByNameList",
      method: "post",
      data,
    });
  },
  sendRecommendFormByEmailAccount(data) {
    return request({
      url: "/v1/recommendform/sendRecommendFormByEmailAccount",
      method: "post",
      data,
    });
  },
  sendRecommendFormByEmail(data) {
    return request({
      url: "/v1/recommendform/sendRecommendFormByEmail",
      method: "post",
      data,
    });
  },
  saveEmailFile(data) {
    return request({
      url: "/v1/recommendform/saveEmailFile",
      method: "post",
      data,
    });
  },
  saveAndSendRecommendForm(data) {
    return request({
      url: "/v1/recommendform/saveAndSendRecommendForm",
      method: "post",
      data: transform(data),
    });
  },
  sendRecommendForm(data) {
    return request({
      url: "/v1/recommendform/sendRecommendForm",
      method: "post",
      data,
    });
  },
  deleteRecommendForm(id) {
    return request({
      url: "/v1/recommendform/deleteRecommendForm",
      method: "post",
      data: { id },
    });
  },
  async getRecommendFormInfo(id) {
    const result = await request({
      url: "/v1/recommendform/getRecommendFormInfo",
      method: "post",
      data: { id },
    });

    return resp2model(result);
  },
  updateRecommendForm(data) {
    return request({
      url: "/v1/recommendform/updateRecommendForm",
      method: "post",
      data: transform(data),
    });
  },
  updateRecommendTheme(data) {
    return request({
      url: "/v1/recommendform/updateRecommendTheme",
      method: "post",
      data,
    });
  },
  updateRecommendFormOrgin(data) {
    return request({
      url: "/v1/recommendform/updateRecommendForm",
      method: "post",
      data,
    });
  },
  getRecommendList(data) {
    let pageInfo = usePage(data);
    return request({
      url: "/v1/recommendform/getList",
      method: "post",
      data: {
        ...data,
        ...pageInfo,
      },
    });
  },
  saveRecommendForm(data) {
    return request({
      url: "/v1/recommendform/saveRecommendForm",
      method: "post",
      data: transform(data),
    });
  },
});

const usePage = (pageInfo) => {
  const { pageSize, current } = pageInfo;
  return {
    page: current,
    page_size: pageSize,
  };
};

const transform = (data) => {
  const { pages = [], id, customer_id_arr, customer_id, author = "" } = data;
  let body = {
    id,
    customer_id,
    customer_id_arr,
    theme: "",
    description: "",
    product_list: [],
  };
  let pageInfo = {
    subheadlines: [],
    title: [],
    conf: [],
    author,
  };
  pages.forEach((p) => {
    pageInfo.conf.push(p.list.filter((i) => !i.h).length);
    pageInfo.title.push(p.title);
    pageInfo.subheadlines.push(p.subheadlines);
  });
  body.theme = pages[0].title;
  // body.description =pages[0].subheadlines
  body.description = JSON.stringify(pageInfo);
  pages.forEach((p) => {
    let list = (p.list || [])
      .filter((prod) => !prod.h)
      .map((prod) => ({
        product_id: prod.id,
        product_img: prod.img && prod.img.split("?")[0],
        desc: prod.desc || "",
        product_name: prod.name,
        price: prod.price,
      }))
      .filter((p) => p.product_img != "");
    body.product_list = body.product_list.concat(list);
  });
  return body;
};

const model2Req = (m) => {
  const { title, subheadlines, author, list, customer_id } = m;
  let product_list = list.map((t) => ({
    product_id: t.id,
    product_img: t.img,
    desc: t.desc,
  }));
  return {
    customer_id: customer_id,
    product_list,
    theme: title,
    author,
    description: subheadlines,
  };
};

const resp2model = (data) => {
  const {
    customer_id,
    id,
    sn,
    service_id,
    description,
    create_user_id,
    theme,
    recommend_time,
    status,
    is_read,
    product_list,
  } = data;

  let pageInfo = {};
  try {
    pageInfo = JSON.parse(description);
  } catch (e) {}
  let pages = [];
  let conf = pageInfo.conf || [];

  let subheadlines = pageInfo.subheadlines || [];
  let titles = pageInfo.title || [];
  let author = pageInfo.author;

  conf.forEach((i, idx) => {
    if (i > 0) {
      const r = product_list.splice(0, i).map((p) => {
        return {
          id: p.product_id,
          img: p.product_img,
          desc: p.desc,
          name: p.product_name,
          price: p.price,
        };
      });
      pages.push({
        title: titles[idx] || "",
        subheadlines: subheadlines[idx] || "",
        column: i,
        list: r,
      });
    }
  });
  return {
    id,
    sn,
    service_id,
    create_user_id,
    customer_id,
    title: theme,
    subheadlines: description,
    pages,
    author,
    status,
    is_read,
  };
};

<template>
  <div class="tab-content">
    <div class="tab-header">
      <!-- <a-input-group compact>
        <a-select style="width: 36%" default-value="all">
          <a-select-option value="all">All</a-select-option>
          <a-select-option value="other">Other</a-select-option>
        </a-select>
        <a-input
          style="width: 64%"
          placeholder="请输入内容"
          @change="handleSearch"
        />
      </a-input-group> -->
      <a-input
        :placeholder="$t('handleMaterialsSearch')"
        @change="handleSearch"
      >
        <a-tooltip slot="suffix" title="">
          <el-upload
            action=""
            accept=".jpg,.jpeg,.png,.JPG,.PNG"
            :show-file-list="false"
            ref="upload"
            :http-request="toSearchImage"
          >
            <i class="iconfont icon-zhaoxiangji image-search-icon"></i>
          </el-upload>
        </a-tooltip>
      </a-input>
      <a-button
        icon="folder"
        @click="showFolder = !showFolder"
        type="info"
      ></a-button>
      <a-button icon="plus" type="primary" @click="clickEditor"></a-button>
      <!-- <a-button
        icon="edit"
        :disabled="disableEdit"
        type="success"
        @click="showEditor = true"
      ></a-button> -->
      <a-popconfirm
        :title="$t('tipDel')"
        :ok-text="$t('yes')"
        :cancel-text="$t('no')"
        @confirm="confirmDel"
        :disabled="disableDel"
      >
        <a-button icon="delete" type="danger" :disabled="disableDel"></a-button>
      </a-popconfirm>
      <a-button icon="sync" @click="handleRefresh"></a-button>
    </div>
    <folder
      :visiable.sync="showFolder"
      :folders="textureFolders"
      @selected="handleFolderSelect"
    ></folder>
    <textures
      :data="filterTexture"
      @page-change="handlePageChange"
      @selected="handleSelect"
      :total="texturePage.total"
      :pageSize="texturePage.per_page"
      @edit="handleEdit"
      type="texture"
    ></textures>
    <texture-editor
      :visible.sync="showEditor"
      :current="selected[0]"
      :title="$t('texture')"
      :textureType="texttureData.cat_id"
      :name="texttureData.name"
      :id="texttureData.id"
      :url="texttureData.url"
    ></texture-editor>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers("jiyang/material");
import textures from "@/components/Textures.vue";
import folder from "@/components/Folder.vue";
import TextureEditor from "@/components/texture/TextureEditor.vue";

export default {
  components: {
    textures,
    folder,
    TextureEditor,
  },
  data() {
    return {
      showFolder: true,
      showEditor: false,
      selected: [],
      texttureData: {
        cat_id: 5,
        name: "",
        id: "",
        url: "",
      },
    };
  },
  computed: {
    ...mapState(["materials", "folders", "textureFolders", "texturePage"]),
    ...mapGetters(["filterTexture"]),
    disableEdit() {
      return this.selected.length != 1;
    },
    disableDel() {
      return this.selected.length <= 0;
    },
  },
  created() {
    this.getTextureFolderList();
    this.getTextureList();
  },
  methods: {
    ...mapMutations([
      "SET_MATERIALS",
      "SET_SEARCH",
      "SET_CURRENT_TEXTUREFOLDER",
    ]),
    ...mapActions([
      "getTextureFolderList",
      "getTextureList",
      "delTexture",
      "textureSearch",
    ]),
    handleFolderSelect(idx) {
      this.SET_CURRENT_TEXTUREFOLDER(idx);
      this.getTextureList();
    },

    handleSelect(val) {
      this.selected = [...val];
    },
    handlePageChange(page, pageSize) {
      this.getTextureList({ page, pageSize });
    },
    handleSearch(e) {
      this.SET_SEARCH(e.target.value);
      this.getTextureList();
    },
    confirmDel() {
      this.handleDel();
    },
    handleRefresh() {
      this.getTextureList();
    },
    handleDel() {
      const ids = this.selected.map((s) => s.id);
      this.delTexture(ids);
      this.init();
    },
    init() {
      this.selected = [];
    },
    handleEdit(m) {
      this.showEditor = true;
      this.texttureData = m;
    },
    clickEditor() {
      this.showEditor = true;
      this.texttureData = {
        cat_id: 5,
        name: "",
        id: "",
        url: "",
      };
    },
    //AI搜索图片
    async toSearchImage(file) {
      let that = this;
      let files = file.file;
      // let check = await this.checkImage(files);
      // if (!check) {
      //   this.$message.error("上传图片信息有误");
      //   return false;
      // }

      that.textureSearch(files);
    },
  },
};
</script>

<style lang="scss">
// .resource-list {
//   height: 80vh;
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   align-content: flex-start;
//   row-gap: 8px;
//   overflow: scroll;
//   padding-right: 4px;
//   .resource {
//     margin-right: 7px;
//   }
//   .resource:nth-child(4n) {
//     margin-right: 0;
//   }
// }
</style>

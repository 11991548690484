<template>
  <div>
    <div class="form-right-header">
      <div class="form-right-header-left">
		<div class="form-title-box" v-if="isMaterial">
			<span class="form-title base-font-color" @click="toList">{{navName}}</span>
		</div>
        <div class="form-title-box" v-else>
          <span
            class="form-title base-font-color"
            v-if="formType != 5"
            @click="toList"
            >
			 <span v-if="userRole == 'custom'">
				 {{
				   formType === 1
				     ? $t("enquiryForm")
				     : formType === 2
				     ? $t("enquiryForm")
				     : formType === 3
				     ? $t("orderForm")
				     : $t("recommendForm")
				 }}
			 </span>
			 <span v-else>
				{{
				  formType === 1
				    ? $t("enquiryForm")
				    : formType === 2
				    ? $t("quotationForm")
				    : formType === 3
				    ? $t("orderForm")
				    : $t("recommendForm")
				}}
			 </span>
			</span
          >
          <span class="form-title base-font-color" v-else @click="toList"
            >成本分析单</span
          >
          <span class="form-title-num" v-if="itemData.sn && !isShowList"
            >/{{ itemData.sn }}</span
          >
          <span class="form-title-num" v-if="!itemData.sn && !isShowList"
            >/{{
              formType === 1
                ? $t("newEnquiry")
                : formType === 2
                ? $t("newQuotation")
                : "新建成本单"
            }}</span
          >
        </div>
      </div>
      <div class="form-right-header-right" v-if="!isShowList && !isMaterial">
        <div
          class="form-save-btn"
          @click="toSave"
          v-if="
            ((userRole === 'custom' && formType == 1) ||
              (userRole === 'company' && formType == 2) ||
              formType === 5) &&
            !checkEdit&&!isHistory
          "
        >
          {{ $t("saveForm") }}
        </div>
        <div v-if="info.user_type == 1&&!isHistory">
          <!--          <div class="form-tool-btn" @click="toEdit" v-if="checkEdit == false">-->
          <!--            <i class="iconfont icon-bianji"></i>-->
          <!--            <div class="tool-btn-text">{{ $t("editModule") }}</div>-->
          <!--          </div>-->
          <div class="form-tool-btn" @click="toSubmit" v-if="checkEdit">
            <i class="iconfont icon-bianji"></i>
            <div class="tool-btn-text">{{ $t("submitModule") }}</div>
          </div>
        </div>
        <div class="form-tool-btn" @click="showHistory"  v-if="formType != 5&&itemData.sn">
          <i class="iconfont icon-order"></i>
          <span class="tool-btn-text">记录</span>
        </div>
        <div class="form-tool-btn" @click="toExportPdf" v-if="formType != 5&&!isHistory">
          <i class="iconfont icon-huixingzhen"></i>
          <div class="tool-btn-text">{{ $t("exportForm") }}</div>
        </div>

      </div>
    </div>
    <div class="form-right-type-box" v-if="!isShowList && formType != 5&&!isHistory && !isMaterial">
      <div class="type-box-left" v-if="formType != 3&&formNowType<=5">
        <div
          class="submit-btn mr-30"
          @click="submitForm"
          v-if="userRole === 'custom'"
        >
          {{ formType === 1 ? $t("toEnquiry") : $t("toOrder") }}
        </div>
        <div class="submit-btn mr-30" @click="submitForm" v-else>
          {{ formType === 1 ? $t("intoQuotation") : $t("toQuotation") }}
        </div>
        <div
          class="submit-btn mr-30"
          @click="submitForm"
          v-if="userRole === 'custom' && formType === 2"
        >
          议价
        </div>
        <!--        <div class="send-btn mr-30">Send by E-mail</div>-->
        <!--        <div class="send-btn">Send by Chat</div>-->
      </div>
      <div v-else class="type-box-left">
        <!--        <div-->
        <!--          class="submit-btn mr-30"-->
        <!--          @click="submitForm"-->
        <!--          v-if="userRole === 'company'"-->
        <!--        >-->
        <!--          Confirm order-->
        <!--        </div>-->
      </div>
      <div class="type-box-right">
        <div class="progress-box">
          <div class="progress-tag bg-progress"></div>
          <div class="progress-title progress-text">
            {{ formType === 1 ? $t("toEnquiry") : $t("toQuotation") }}
          </div>
          <div class="progress-hr-right bg-progress"></div>
        </div>
        <div class="progress-box">
          <div
            class="progress-tag"
            :class="[
              itemData.state === 2 ||
              itemData.state === 5 ||
              itemData.state === 6
                ? 'bg-progress'
                : '',
            ]"
          ></div>
          <div
            class="progress-title"
            :class="[
              itemData.state === 2 ||
              itemData.state === 5 ||
              itemData.state === 6
                ? 'progress-text'
                : '',
            ]"
          >
            {{ $t("sent") }}
          </div>
          <div
            class="progress-hr-left bg-progress"
            :class="[
              itemData.state === 2 ||
              itemData.state === 5 ||
              itemData.state === 6
                ? 'bg-progress'
                : '',
            ]"
          ></div>
          <div
            class="progress-hr-right"
            :class="[
              itemData.state === 2 ||
              itemData.state === 5 ||
              itemData.state === 6
                ? 'bg-progress'
                : '',
            ]"
          ></div>
        </div>
        <div class="progress-box">
          <div
            class="progress-tag"
            :class="[itemData.state === 6 ? 'bg-progress' : '']"
          ></div>
          <div
            class="progress-title"
            :class="[itemData.state === 6 ? 'progress-text' : '']"
          >
            {{ $t("orderForm") }}
          </div>
          <div
            class="progress-hr-left"
            :class="[
              itemData.state === 2 ||
              itemData.state === 5 ||
              itemData.state === 6
                ? 'bg-progress'
                : '',
            ]"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "formRightHeader",
  props: {
    checkEdit: {
      type: Boolean,
      default: true,
    },
    itemData: {
      type: Object,
	  default:function(){
		  return {
			  sn:'',
			  state:''
		  }
	  }
    },
    isShowList: {
      type: Boolean,
	  default:false
    },
    formType: {
      type: Number,
    },
    isHistory: {
      type: Boolean,
      default:false
    },
	isMaterial:{
		type:Boolean,
		default:false
	},
	navName:{
		type:String,
		default:""
	}
  },
  data() {
    return {};
  },
  created(){
	  console.log(this.formType);
  },
  watch:{
	  itemData(val){
		 console.log(this.itemData);
	  }
  },
  computed: {
    ...mapState("jiyang/user", ["info", "userRole"]),
    ...mapState("jiyang/form", [ "formNowType"]),
  },
  methods: {
    //跳转列表
    toList() {
      this.$emit("toList");
    },
    //保存表单
    toSave() {
      this.$emit("toSave");
    },
    //提交模板
    toSubmit() {
      this.$emit("toSubmit");
    },
    //编辑模板
    toEdit() {
      this.$emit("toEdit");
    },
    //导出pdf
    toExportPdf() {
      this.$emit("toExportPdf");
    },
    //提交单据
    submitForm() {
      this.$emit("submitForm");
    },
  //  显示历史记录
    showHistory(){
      this.$emit('showHistory')
    },
  },
};
</script>
<style lang="scss">
.form-right-header {
  // height: 66px;
  line-height: $head-line-height;
  background-color: #fff;
  padding-left: 32px;
  // padding: 11px 63px 8px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d3d3;
  .form-right-header-left {
    /*width: 100%;*/
    .form-title-box {
      display: flex;
      align-items: center;
      // margin-bottom: 6px;
      font-size: $head-font-size;
      font-weight: $head-font-weight;
      .form-title {
        margin-right: 4px;
        cursor: pointer;
      }
      .form-title-num {
        color: #959595;
      }
    }
  }
  .form-right-header-right {
    display: flex;
    align-items: center;
    .form-save-btn {
      width: 106px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $base-config-color;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      border-radius: 2px;
      cursor: pointer;
    }
    .form-tool-btn {
      display: flex;
      align-items: center;
      margin:0 20px;
      cursor: pointer;
      .icon-huixingzhen {
        font-size: 18px;
        color: #959595;
      }
      .icon-bianji {
        font-size: 16px;
        color: #959595;
      }
      .icon-shanchu {
        font-size: 16px;
        color: #959595;
      }
      .tool-btn-text {
        font-size: 16px;
        /*font-weight: 600;*/
        margin-left: 4px;
        color: #959595;
      }
    }
  }
  .header-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left-box {
      display: flex;
      align-items: center;
    }
  }
  .form-right-header-btn {
    width: 48px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    border: 1px solid #e2dede;
    background-color: #fff;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .bg {
    border: none;
    background-color: $base-config-color;
    color: #fff;
  }
}
.form-right-type-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;

  /*border-bottom: 1px solid #d6d3d3;*/
  width: 100%;
  padding-left: 30px;
  .type-box-left {
    display: flex;
    align-items: center;
    .submit-btn {
      height: 30px;
      padding: 0 20px;
      display: inline-block;
      background-color: $base-config-color;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
    }
    .send-btn {
      font-size: 14px;
      font-weight: 600;
      color: $base-config-color;
      cursor: pointer;
    }
    .mr-30 {
      margin-right: 30px;
    }
  }
  .bg-progress {
    background-color: $base-config-color !important;
  }
  .progress-text {
    color: $base-config-color !important;
  }
  .type-box-right {
    display: flex;
    align-items: center;
    padding-right: 40px;
    .progress-box {
      position: relative;
      text-align: center;
      padding: 0 27px;
      .progress-tag {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: #959595;
        margin: 0 auto;
      }
      .progress-title {
        font-size: 14px;
        color: #959595;
        font-weight: 600;
        margin-top: 4px;
      }
      .progress-hr-right {
        position: absolute;
        top: 9px;
        left: calc(50% + 9px);
        width: calc(50% - 9px);
        height: 3px;
        background-color: #959595;
      }
      .progress-hr-left {
        position: absolute;
        top: 9px;
        right: calc(50% + 9px);
        width: calc(50% - 9px);
        height: 3px;
        background-color: #959595;
      }
    }
  }
}
</style>

<template>
  <div class="m-banner-wrap" v-if="bannerData.length">
    <div class="m-banner-list">
      <div
        class="u-banner-item fade"
        v-for="(item, index) in bannerData"
        :key="index"
        v-show="index===activeIndex"
        :style="`background: url(${item.src}) no-repeat center; background-size: cover;`"
        @mouseenter="onStop" @mouseleave="onStart" @click="goExhibitsPage(item.link)">
       <!-- <a :href="item.link" target="_blank"></a> -->
      </div>
    </div>
    <div class="m-dot-list" v-if="bannerData.length > 1">
      <div v-for="n of bannerData.length" :key="n" :class="['u-dot-item', {active: (n-1)===activeIndex}]" @mouseenter="onEnter(n-1)">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Banner',
  props: {
    bannerData: { // banner数组
      type: Array,
      required: true, // 必传
      default: () => {
        return []
      }
    },
    interval: { // 切换间隔ms
      type: Number,
      default: 3000
    }
  },
  data () {
    return {
      activeIndex: 0,
      timer: null
    }
  },
  mounted () {
    setTimeout(() => {
      this.onStart()
    }, 1000)
    window.onfocus = () => { // 页面激活
      this.onStart()
    }
    window.onblur = () => { // 页面失焦
      this.onStop()
    }
  },
  methods: {
    onStart () {
      clearInterval(this.timer)
      if (this.bannerData.length > 1) {
        this.timer = setInterval(this.onMove, this.interval)
      }
    },
    onStop () {
      clearInterval(this.timer)
    },
    onEnter (index) {
      clearInterval(this.timer)
      this.activeIndex = index
      this.onStart()
    },
    onMove () {
      if (this.activeIndex === this.bannerData.length - 1) {
        this.activeIndex = 0
      } else {
        this.activeIndex++
      }
    },
	// 跳转展品页面 gtype=商品类型
	goExhibitsPage(gtype) {
	  if (!gtype) return;
	  // this.$router.push({name: 'Easel', params: {good_type: gtype}});
	  if(gtype == 'join'){
		  this.$router.push({name: 'join'});
	  }else{
		  this.$router.push({name: 'Exhibits', params: {gdtype: gtype}});
	  }
	},
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
<style lang="less" scoped>
@mainColor: #108ee9;
.m-banner-wrap {
  width: 600px;
  height: 350px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  .m-banner-list {
    height: 600px;
    .u-banner-item {
      height: 350px;
      width: 100%;
      a {
        display: block;
        height: 100%;
      }
    }
    .fade { // 切换banner时的过渡效果
      -webkit-animation: fadein 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: fadein 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
    @-webkit-keyframes fade {
	  0% {
	    opacity: 0;
	  }
	  100% {
	    opacity: 1;
	  }
	}
	@keyframes fade {
	  0% {
	    opacity: 0;
	  }
	  100% {
	    opacity: 1;
	  }
	}
  }
  .m-dot-list {
    width: 380px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    margin-left: -210px;
    text-align: center;
    .u-dot-item { // 圆点样式
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 5px;
      background: #fff;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 50%;
      opacity: 0.8;
    }
    .active { // 圆点选中样式
      width: 30px;
      background: @mainColor;
      border: 1px solid @mainColor;
      border-radius: 12px;
      opacity: 1;
    }
  }
}
</style>
<template>
  <div class="home" @click="closeFilter">
    <div class="table-box" :style="{ 'max-height': pageHeight + 'px' }">
      <div>
        <materialTable
          ref="materialTable"
          :tableHeight="tableHeight"
          :table-data="tableData"
          :total="total"
          :current-page="page"
          :page-size="page_size"
          :isInAiSearch="isInAiSearch"
          @current-change="currentChange"
          @toSearchImage="toSearchImage"
          @bindModel="bindModel"
          @searchDesign="searchDesign"
          @createProduct="createProduct"
        >
        </materialTable>
        <div class="pagination" v-if="tableData.length > 0 && !isInAiSearch">
          <el-pagination
            background
            big
            :total="total"
            :current-page="page"
            :page-size="page_size"
            layout="prev, pager, next"
            @current-change="currentChange"
          />
        </div>
      </div>
    </div>

    <selectThreeDialog
      v-if="dialogThreeDVisible"
      @bindThreeD="bindThreeD"
      @closeModal="closeThreeModal"
      :parentId="parentId"
      @toUploadModel="toUploadModel"
    ></selectThreeDialog>
    <!--    <createDialog-->
    <!--      @closeModal="closeModal"-->
    <!--      @toCreateThreeD="toCreateThreeD"-->
    <!--      :parentId="parentId"-->
    <!--      :type="2"-->
    <!--      :add-form="addForm"-->
    <!--      v-if="dialogEnterVisible"-->
    <!--    ></createDialog>-->
    <!--    <createProduct-->
    <!--      @createProcut="createProcut"-->
    <!--      v-if="userRole === 'company'"-->
    <!--    ></createProduct>-->
  </div>
</template>

<script>
import materialTable from "../../components/materialTable/materialTable";
import selectThreeDialog from "../../components/selectThreeDialog/selectThreeDialog";
import uploadCos from "@/utils/upload_to_cos";
import { mapState, mapMutations } from "vuex";
let that;
let checkDetail = false;
export default {
  components: {
    materialTable,
    selectThreeDialog,
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "productDetail") {
      checkDetail = true;
    }
    next();
  },
  data() {
    return {
      page: 1,
      page_size: 20,
      tableData: [],
      total: 0,
      tableHeight: 800,
      pageHeight: 1000,
      menuType: -1,
      addForm: {
        name: "",
        delivery_date: "",
        style_number: "",
        style_color: "",
        model_name: "",
        shoe_last_name: "",
        count: "",
        client: "",
        size: "",
      },
      dialogEnterVisible: false,
      parentId: 0,
      dialogThreeDVisible: false,
      isBindThreeD: false,
      isFirstEnter: true,
      isInAiSearch: false,
      img_file: "",
      search_form: {},
    };
  },
  computed: {
    ...mapState("jiyang/account", ["isUserLogin"]),
    ...mapState("jiyang/user", ["info", "userRole"]),
  },
  watch: {
    $route: {
      handler() {
        if (!checkDetail) {
          this.init();
        }

        if (this.$route.name == "Home" && !this.isFirstEnter) {
          this.menuType = this.$route.query.type;
          this.getTableData();
        }
      },
      deep: true,
    },
  },
  created() {
    that = this;
    if (this.$route.query.type) {
      this.menuType = this.$route.query.type;
    } else {
      this.menuType = -1;
    }
    this.isFirstEnter = true;
    this.toCheckLogin();
    this.tableHeight = document.documentElement.clientHeight - 150;
    this.pageHeight = document.documentElement.clientHeight - 150;
    if (this.isFirstEnter) {
      this.getTableData();
      this.isFirstEnter = false;
    }
  },
  mounted() {
    //    监听屏幕
    window.onresize = () => {
      return (() => {
        that.tableHeight = document.documentElement.clientHeight - 150;
        that.pageHeight = document.documentElement.clientHeight - 150;
      })();
    };
  },
  activated() {
    this.setMenu(2);
  },
  methods: {
    ...mapMutations("jiyang/account", ["toCheckLogin"]),
    ...mapMutations("jiyang/menu", ["setMenu"]),
    //上传刷新列表
    createProduct() {
      this.getTableData();
    },
    //关闭筛选
    closeFilter() {
      //   this.init();
      this.$refs.materialTable.showSearchBox = false;
    },
    //显示上传弹窗
    toUploadThreeD() {
      this.parentId = 0;
      this.isBindThreeD = false;
      this.addForm = {
        name: "",
        delivery_date: "",
        style_number: "",
        style_color: "",
        model_name: "",
        shoe_last_name: "",
        count: "",
        client: "",
        size: "",
      };
      this.dialogEnterVisible = true;
    },
    //显示3D弹窗
    bindModel(id) {
      this.parentId = id;
      this.dialogThreeDVisible = true;
    },
    //关闭3D弹窗
    closeThreeModal() {
      this.dialogThreeDVisible = false;
      this.isBindThreeD = false;
    },
    //已绑定3D
    bindThreeD() {
      this.getTableData();
      this.dialogThreeDVisible = false;
      this.isBindThreeD = false;
    },
    //绑定模型上传
    toUploadModel(id) {
      this.parentId = id;
      this.isBindThreeD = true;
      this.dialogEnterVisible = true;
    },
    //创建3d
    toCreateThreeD(file) {
      let pictureList = [file];
      let loading = this.openLoading();
      uploadCos(pictureList, "model", (file_url) => {
        let data = {
          type: 2,
          label: {
            three_d_url: file_url[0],
          },
          pater_id: that.parentId,
        };
        let params = {
          ...data,
          ...this.addForm,
        };
        this.$api.saveBom(params).then(async (res) => {
          await that.getTableData();
          if (that.isBindThreeD) {
            await that.toBind(res.id);
          }
          that.isBindThreeD = false;
          that.dialogEnterVisible = false;
          that.dialogThreeDVisible = false;
          loading.close();
        });
      });
    },
    //去绑定
    toBind(id) {
      let data = {
        id,
        pater_id: this.parentId,
        force: 0,
      };
      this.$api.bindThreeD(data).then((res) => {});
    },
    //关闭创建
    closeModal() {
      this.dialogEnterVisible = false;
    },

    //筛选
    searchDesign(form) {
      this.page = 1;
      this.search_form = form;
      this.getTableData();
    },
    //ai搜索图片
    toSearchImage(file) {
      this.img_file = file;
      this.page = 1;
      if (file) {
        this.isInAiSearch = true;
      } else {
        this.isInAiSearch = false;
      }

      this.getTableData();
    },
    //获取列表
    getTableData() {
      let loading = this.openLoading();
      if (this.isInAiSearch) {
        var forms = new FormData();
        forms.append("image", this.img_file);
        forms.append("page", this.page);
        forms.append("page_size", this.page_size);
        if (this.menuType > 0) {
          forms.append("type", this.menuType);
        }
        this.$api.getDesignImageList(forms).then((res) => {
          that.tableData = res.bomInfo;
          that.total = _.get(res, "ai_info.result_num", 0);
          loading.close();
        });
      } else {
        let data = {
          page: this.page,
          page_size: this.page_size,
        };
        let search = {};
        if (this.menuType > 0) {
          search = { ...this.search_form, ...{ type: this.menuType } };
        } else {
          search = this.search_form;
        }
        data = { ...data, ...{ search } };
        this.$api.getProductList(data).then((res) => {
          that.tableData = res.data;
          that.total = res.total;
          loading.close();
        });
      }
    },
    //设计分页
    currentChange(arg) {
      this.page = arg; // 当前页：arg
      this.getTableData();
    },
    init() {
      this.page = 1;
    },
  },
};
</script>
<style lang="scss">
@import "home.scss";
</style>

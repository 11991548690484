<template>
  <a-form class="material-form">
    <a-form-item>
      <span slot="label">
        {{ $t("materialName") }}
        <!-- <a-tooltip title="name" placement="bottom">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-input
        v-model="material.name"
        :placeholder="$t('placeHolderMaterialName')"
        width="12"
        @change="handleNameChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("materialNumber") }}
        <!-- <a-tooltip title="name" placement="bottom">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-input
        v-model="material.sn"
        :placeholder="$t('placeHolderMaterialNumber')"
        width="12"
        @change="handleNumberChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("clearCoatSwitch") }}
      </span>
      <a-switch
        checked-children="开"
        un-checked-children="关"
        @change="switchChange"
      />
      <a-slider
        :min="0"
        :max="1"
        :step="0.1"
        v-model="material.param.clearCoat.intensity"
        @change="handleClearCoatChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("materialRoughness") }}
        <!-- <a-tooltip placement="bottom" title="The lower the roughness, the stronger the reflective effect, and vice versa.">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-slider
        :min="0"
        :max="1"
        :step="0.1"
        v-model="material.param.roughness"
        @change="handleRoughnessChange"
      />
    </a-form-item>
    <a-row>
      <!-- <a-col :span="24">
        <a-form-item :wrapperCol="{ span: 12 }" :labelCol="{ span: 12 }">
          <span slot="label">
            roughness
            <a-tooltip
              placement="bottom"
              title="often shortened to roughness, is a component of surface texture. It is quantified by the
            deviations in the direction of the normal vector of a real surface from its ideal form.
            If these deviations are large, the surface is rough; if they are small, the surface is
            smooth. In surface metrology, roughness is typically considered to be the
            high-frequency, short-wavelength component of a measured surface. However, in practice
            it is often necessary to know both the amplitude and frequency to ensure that a surface
            is fit for a purpose."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider :min="0" :max="100" @change="handleRoughnessChange" />
        </a-form-item>
      </a-col> -->
      <!-- <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 12 }" :labelCol="{ span: 12 }">
          <span slot="label">
            metallic&nbsp;
            <a-tooltip
              placement="bottom"
              title="Specifies the metallic scalar value of the material. Can also be used to scale the
            metalness values of the metallic texture."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider :min="0" :max="1" @change="handlemetallicChange" />
        </a-form-item>
      </a-col> -->
    </a-row>
    <a-form-item>
      <span slot="label">
        {{ $t("environmentIntensity") }}
        <!-- <a-tooltip placement="bottom" title="environment intensity">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-slider
        :min="0.1"
        :max="5"
        :step="0.1"
        v-model="material.param.environmentIntensity"
        @change="handleEnvironmentIntensityChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("alphaIntensity") }}
        <!-- <a-tooltip placement="bottom" title="environment intensity">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-slider
        :min="0"
        :max="1"
        :step="0.1"
        v-model="material.param.alpha"
        @change="handleAlphaIntensityChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("colorTexture") }}
        <!--  <a-tooltip placement="bottom" title="A texture map with more than two colors can make the material show rich color effects">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <!-- <div class="image-upload-picker">
        <upload :fileCallBack="fileCallAlbedoTexture"></upload>
        <a-button @click="showImagePick = true">选择图片</a-button>
      </div> -->
      <image-picker
        :visible.sync="showImagePick"
        @ok="handleOkAlbedoTexture"
      ></image-picker>
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("textureSize") }}
        <!-- <a-tooltip placement="bottom" title="albedoTexture vScale">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-slider
        :min="0.1"
        :max="2"
        :step="0.1"
        v-model="material.param.albedoTexture.uScale"
        @change="handlealbedoTexturevScaleChange"
      ></a-slider>
    </a-form-item>
    <!-- <a-row>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            vScale
            <a-tooltip placement="bottom" title="albedoTexture vScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider
            :min="0"
            :max="100"
            @change="handlealbedoTexturevScaleChange"
          ></a-slider>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            uScale
            <a-tooltip placement="bottom" title="albedoTexture uScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number
            @change="handlealbedoTextureUScaleChange"
          ></a-input-number>
        </a-form-item>
      </a-col>
    </a-row> -->
    <a-form-item>
      <span slot="label">
        {{ $t("bumpTexture") }}
        <!--  <a-tooltip
          placement="bottom"
          title="A blue texture map can enhance the three-dimensional effect of material texture."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <image-picker
        :visible.sync="showImagePick"
        @ok="handleOkBumpTexture"
      ></image-picker>
    </a-form-item>
    <!-- <a-row>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            vScale
            <a-tooltip placement="bottom" title="albedoTexture vScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number @change="handleBumpVChange"></a-input-number>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 14 }" :labelCol="{ span: 10 }">
          <span slot="label">
            uScale
            <a-tooltip placement="bottom" title="albedoTexture uScale">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number @change="handleBumpUChange"></a-input-number>
        </a-form-item>
      </a-col>
    </a-row> -->
    <a-form-item>
      <span slot="label">
        {{ $t("textureSize") }}
        <!-- <a-tooltip placement="bottom" title="albedoTexture uv scale">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-slider
        :min="0.1"
        :max="2"
        :step="0.1"
        v-model="material.param.bumpTexture.uScale"
        @change="handleBumpVChange"
      ></a-slider>
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("textureColor") }}
        <!-- <a-tooltip placement="bottom" title="材质颜色">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <color-picker
        class="color-picker"
        v-model="material.param.albedoColor"
        @change="handleAlbeColorPicker"
      ></color-picker>
    </a-form-item>
    <!-- <a-form-item>
      <span slot="label">
        reflection texture
        <a-tooltip placement="bottom" title="reflection texture">
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <image-picker
        :visible.sync="showImagePick"
        @ok="handleOkReflection"
      ></image-picker>
    </a-form-item> -->
  </a-form>
</template>
<script>
// import Upload from "@/components/Upload";
import ColorPicker from "@/components/v2/color-picker/ColorPicker.vue";
import ImagePicker from "@/components/ImagePicker";
import mixin from "@/components/material/mixin";
export default {
  name: "sole",
  components: {
    // Upload,
    ImagePicker,
    ColorPicker,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    option: {
      type: Object,
      default: () => ({
        name: "",
        sn: "",
        cat_id: 11,
        shape: "ball",
        type: "pbr",
        material: "leather",
        param: {
          reflectionTexture: {
            url: "texture/environmentSpecular.env",
            type: "env",
            material: null,
          },
          metallic: 0,
          roughness: 0.2,
          alpha: 1,
          albedoTexture: {
            url: "",
            type: "color",
            material: null,
            vScale: 1,
            uScale: 1,
          },
          bumpTexture: {
            url: "",
            type: "bump",
            material: null,
            vScale: 1,
            uScale: 1,
            level: 1,
          },
          clearCoat: {
            isEnabled: false,
            intensity: 1,
          },
        },
      }),
    },
  },
  data() {
    return {
      showImagePick: false,
      material: Object.assign(this.option, this.value),
    };
  },
  methods: {
    handleNameChange(e) {
      this.material.name = e.target.value;
      this.emit(this.material);
    },
    handleNumberChange(e) {
      this.material.sn = e.target.value;
      this.emit(this.material);
    },
    handleChange() {
      this.emit(this.material);
    },
    handleRoughnessChange(val) {
      this.material.param.roughness = val;
      this.emit(this.material);
    },
    handlemetallicChange(val) {
      this.material.param.metallic = val;
      this.emit(this.material);
    },
    handleEnvironmentIntensityChange(val) {
      this.material.param.environmentIntensity = val;
      this.emit(this.material);
    },
    handlealbedoTexturevScaleChange(val) {
      this.material.param.albedoTexture = {
        ...this.material.param.albedoTexture,
        vScale: val,
        uScale: val,
      };
      this.emit(this.material);
    },
    handlealbedoTextureUScaleChange(val) {
      this.material.param.albedoTexture = {
        ...this.material.param.albedoTexture,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleOkAlbedoTexture(file) {
      if (file) {
        this.material.param.albedoTexture = {
          ...this.material.param.albedoTexture,
          ...file,
        };
      } else {
        this.material.param.albedoTexture = {};
      }

      this.emit(this.material);
    },
    handleOkBumpTexture(file) {
      if (file) {
        this.material.param.bumpTexture = {
          ...this.material.param.bumpTexture,
          ...file,
        };
      } else {
        this.material.param.bumpTexture = {};
      }

      this.emit(this.material);
    },
    handleBumpVChange(val) {
      this.material.param.bumpTexture = {
        ...this.material.param.bumpTexture,
        vScale: val,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleBumpUChange(val) {
      this.material.param.bumpTexture = {
        ...this.material.param.bumpTexture,
        uScale: val,
      };
      this.emit(this.material);
    },
    handleOkReflection(file) {
      if (file) {
        this.material.param.reflectionTexture = {
          ...this.material.param.reflectionTexture,
          ...file,
        };
      } else {
        this.material.param.reflectionTexture = {};
      }
      this.emit(this.material);
    },
    setUrl(file) {
      return file.url;
    },
    handleColorPicker() {
      this.emit(this.material);
    },
    handleAlbeColorPicker(e) {
      this.material.param.reflectionColor = e;
      this.emit(this.material);
    },
    handleAlphaIntensityChange(val) {
      this.material.param.alpha = val;
      this.emit(this.material);
    },
    switchChange(checked) {
      if (checked) {
        this.material.param.clearCoat.isEnabled = true;
        this.emit(this.material);
      } else {
        this.material.param.clearCoat.isEnabled = false;
        this.emit(this.material);
      }
    },
    handleClearCoatChange(val) {
      this.material.param.clearCoat.intensity = val;
      this.emit(this.material);
    },
  },
  mixins: [mixin],
};
</script>

<style lang="scss">
.material-form {
  padding-left: 8px;
  .ant-form-item {
    margin-bottom: 12px;
  }
  padding-top: 16px;
  // .anticon {
  //   padding-top: 13px;
  // }
  .ant-input-number {
    width: 100%;
  }
  // .ant-col .ant-form-item-control-wrapper {
  //   width: 200px;
  // }
  // .filepond--wrapper {
  //   width: 388px;
  // }
}
</style>

<template>
  <div>
    <div class="login-box">
      <div class="login-form">
        <img class="login-logo" src="@/assets/logo.png" />
        <div class="register-title">
          {{ $t("signUp") }}
        </div>
        <el-form
          class="login-input-box"
          ref="loginForm"
          label-position="top"
          :rules="rules"
          :model="formLogin"
          size="default"
        >
          <el-form-item prop="user_email" class="login-input-item">
            <el-input
              type="text"
              class="login-input"
              v-model="formLogin.user_email"
              :placeholder="$t('email')"
            >
            </el-input>
			<div class="text-error" v-if="show_check_email">
			  {{ check_email }}
			</div>
          </el-form-item>
          <el-form-item prop="sms_code" class="login-input-item">
            <el-input
              type="text"
              class="login-input"
              v-model="formLogin.sms_code"
              :placeholder="$t('loginCodePlaceholder')"
            >
              <el-button
                slot="append"
                :disabled="showCount"
                size="mini"
                @click="onGetCode"
              >
                <span class="code-btn" v-if="!showCount">{{
                  isGetCode?$t("getCode"):$t("pushCode")
                }}</span>
                <span v-else>{{ sec }}s</span>
              </el-button>
            </el-input>
          </el-form-item>
          <!-- <el-form-item prop="code" class="login-input-item">
            <el-select
              placeholder="请选择国家"
              filterable
              v-model="formLogin.country"
              :filter-method="dataFilter"
              style="width: 100%"
            >
              <el-option
                v-for="item in country"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.value
                }}</span>
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item prop="user_pwd" class="login-input-item">
            <el-input
              type="password"
              auto-complete="new-password"
              class="login-input"
              v-model="formLogin.user_pwd"
              :placeholder="$t('loginPassword')"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            prop="check_pwd"
            class="login-input-item"
            :class="[show_check_text ? 'border-error' : '']"
          >
            <el-input
              type="password"
              auto-complete="new-password"
              v-model="check_pwd"
              @blur="checkPwd"
              :placeholder="$t('confirmPassword')"
            ></el-input>
            <div class="text-error" v-if="show_check_text">
              {{ check_text }}
            </div>
          </el-form-item>
		  <el-form-item prop="user_mobile" class="login-input-item">
			  <VuePhoneNumberInput v-model="formLogin.user_mobile" @update="getPhoneInfo"></VuePhoneNumberInput>
		  </el-form-item>
          <el-form-item prop="enterprise" class="login-input-item">
            <el-input
              type="text"
              class="login-input"
              v-model="formLogin.enterprise"
              :placeholder="$t('enterprise')"
            >
            </el-input>
          </el-form-item>
          <!-- <el-form-item prop="user_mobile" class="login-input-item">
            <el-input
              type="text"
              class="login-input"
              @blur="checkEmail"
              v-model="formLogin.user_mobile"
              :placeholder="$t('noneLoginMobile')"
            >
            </el-input>
            <div class="text-error" v-if="show_check_email">
              {{ check_email }}
            </div>
          </el-form-item> -->
          <div class="name-input-box">
            <el-form-item prop="first_name" class="name-input-item">
              <el-input
                type="text"
                class="login-input"
                v-model="formLogin.first_name"
                :placeholder="$t('firstName')"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="last_name" class="name-input-item">
              <el-input
                type="text"
                class="login-input"
                v-model="formLogin.last_name"
                :placeholder="$t('lastName')"
              >
              </el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="register-btn-box">
          <div class="register-btn" @click="submit">
            {{ $t("signUp") }}
          </div>
        </div>
      </div>
      <div class="register-box">
        <span>{{ $t("isHaveAccount") }}</span>
        <span class="register-text" @click="toLogin">{{
          $t("isHaveAccountText")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import countryList from "@/utils/country.js";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  name: "register",
  components: {
  	VuePhoneNumberInput
  },
  data() {
    return {
      formLogin: {
        user_mobile: "",
        user_pwd: "",
        sms_code: "",
        enterprise: "",
        first_name: "",
        last_name: "",
        user_email: "",
        country: "中国",
      },
      check_text: "",
      show_check_text: false,
      check_pwd: "",
      check_email: "",
      show_check_email: false,
      code_text: this.$t('getCode'),
      sec: 60,
      showCount: false,
      country: [],
      checkCountry: [],
	  isGetCode:true,
      // 校验
      rules: {
        user_mobile: [
          { required: true, message: this.$t("noneLoginMobile"), trigger: "blur" },
        ],
        user_pwd: [{ required: true, message: this.$t("noneLoginPassword"), trigger: "blur" }],
        sms_code: [
          { required: true, message: this.$t("loginCodePlaceholder"), trigger: "blur" },
        ],
        first_name: [
          { required: true, message: this.$t("typeFirstName"), trigger: "blur" },
        ],
        last_name: [{ required: true, message: this.$t("typeLastName"), trigger: "blur" }],
        enterprise: [
          { required: true, message: this.$t("noneCompanyName"), trigger: "blur" },
        ],
        country: [{ required: true, message: this.$t("selectCountry"), trigger: "change" }],
      },
    };
  },
  created() {
    this.country = countryList;
    this.checkCountry = countryList;
  },
  methods: {
    //筛选国家
    dataFilter(val) {
      if (val) {
        //val存在
        this.country = this.checkCountry.filter((item) => {
          if (
            !!~item.label.indexOf(val) ||
            !!~item.label.toUpperCase().indexOf(val.toUpperCase()) ||
            !!~item.value.indexOf(val) ||
            !!~item.value.toUpperCase().indexOf(val.toUpperCase())
          ) {
            return true;
          }
        });
      } else {
        //val为空时，还原数组
        this.country = this.checkCountry;
      }
    },
    // 验证确认密码
    checkPwd() {
      if (this.formLogin.user_pwd == "") {
        return true;
      }
      if (this.check_pwd != this.formLogin.user_pwd) {
        this.check_text = this.$t("twoPwdError");
        this.show_check_text = true;
        return false;
      } else if (this.check_pwd == "") {
        this.check_text = this.$t("noneConfirmPwd");
        this.show_check_text = true;
        return false;
      } else {
        this.show_check_text = false;
        return true;
      }
    },
    //验证邮箱
    checkEmail() {
      if (this.formLogin.user_email == "") {
        this.check_email = this.$t("typeEmail");
        this.show_check_email = true;
        return false;
      }
      let myreg =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;
      if (myreg.test(this.formLogin.user_email)) {
        this.show_check_email = false;
        return true;
      } else {
        this.check_email = this.$t("emailError");
        this.show_check_email = true;
        return false;
      }
    },
    //获取验证码
    onGetCode() {
	  if(this.checkEmail()){
		  let data = {
		    user_email: this.formLogin.user_email,
		    type: 1,
		  };
		  this.isGetCode = false;
		  this.$api.getCode(data).then((res) => {
			this.$message.success(this.$t('getCodeSuccess'));
		    this.showCount = true;
			this.isGetCode = true;
		    this.countDown();
		  });
	  }
	  return true;
	  
      if (this.formLogin.user_mobile.trim() == "") {
        this.$message.error(this.$t("noneLoginMobile"));
        return;
      }
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (myreg.test(this.formLogin.user_mobile)) {
        this.formLogin.sms_code = "";
        let data = {
          user_mobile: this.formLogin.user_mobile,
          type: 1,
        };
        this.$api.getCode(data).then((res) => {
          this.showCount = true;
          this.countDown();
        });
      } else {
        this.$message.error(this.$t("phoneError"));
      }
    },
    //跳转登录
    toLogin() {
      this.$router.back();
    },

    // 倒计时
    countDown() {
      this.timer = setInterval(() => {
        if (this.sec == 1) {
          clearInterval(this.timer);
          this.showCount = false;
          this.sec = 60;
          return;
        }
        this.sec--;
      }, 1000);
    },
    // 提交注册信息
    submit() {
      let that = this;

      that.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (!this.checkPwd()) {
            return;
          }
          if (!this.checkEmail()) {
            return;
          }
          let loading = this.openLoading();
		  that.formLogin.origin = location.origin;
          that.$api.register(that.formLogin).then((res) => {
			that.$message.success(that.$t('signSuccess'));
			that.$router.replace({
			  name: "login",
			});
            loading.close();
          });
        }
      });
    },
    //    跳转用户协议
    toUserRule() {
      this.$router.push({
        name: "userAgreement",
      });
    },
    //    隐私协议
    toPrivacy() {
      this.$router.push({
        name: "privacyPolicy",
      });
    },
    //选择国家
    handleCountryChange(value) {
      this.formLogin.country = value;
    },
	//获取电话信息
	getPhoneInfo(data){
		this.formLogin.country = data.countryCode;
		this.formLogin.user_mobile = data.formatInternational;
	}
  },
};
</script>

<style scoped lang="scss">
@import "account";
</style>

import api from "@/api";
export default {
  namespaced: true,
  state: {
    currentFolder: Number.MAX_VALUE,
    share: [],
    products: [],
    loading: false,
    selectedType: 0,
    wish: {
      type: "wish",
      id: 0,
    },
    page: {
      page: 1,
      pageSize: 12,
      total: 0,
    },
  },

  mutations: {
    setCurrentFolders(state, id) {
      state.currentFolder = id;
    },
    setSelectedType(state, type) {
      state.selectedType = type;
    },
    setShare(state, data) {
      state.share.push(data);
    },
    setLoading(state, date) {
      state.loading = date;
    },

    setProducts(state, payload) {
      state.products = payload;
    },
    setPage(state, data) {
      state.page = data;
    },
    setWish(state, id) {
      state.wish = { ...id, type: "wish" };
    },
  },
  actions: {
    async queryWish({ commit, dispatch }) {
      const res = await api.queryWish();
      if (res) {
        commit("setWish", { id: res.id, type: "wish" });
      }
    },
    async queryWishList({ state, commit }) {
	  commit("setLoading", true);
      const res = await api.queryProductList({ foldId: state.wish.id });

      if (res && res.data) {
        let m = res.data.map((p) => ({
          id: p.info.id,
          price: p.info.price,
          desc: p.info.intro,
          name: p.info.name,
          like: Boolean(p.info.is_like),
          src: JSON.parse(p.info.file_info).main_file.img_url,
          type: p.info.type,
        }));

        commit("setProducts", m);
        commit("setPage", {
          page: res.current_page,
          total: res.total,
          pageSize: res.per_page,
        });
      }
      commit("setLoading", false);
    },
    async search({ state, commit }, name, { page = 1, pageSize = 12 } = {}) {
      commit("setLoading", true);
      const id = state.currentFolder;
      let productid = state.currentFolder;
      //   const baseUrl = "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com"
      if (id == Number.MAX_VALUE) {
        let data = {
          page: page,
          page_size: pageSize,
          search: {
            // name:name,
            sn: name,
            type: state.selectedType,
          },
        };
        let res = await api.getBomtable(data);

        let m = res.data.map((p) => ({
          id: p.id,
          src: p.img_url,
          name: p.name,
          type: p.type,
          label: "edit",
          like: Boolean(p.is_like),
          favicon: false,
        }));
        commit("setProducts", m);
        commit("setPage", {
          page: res.current_page,
          total: res.total,
          pageSize: res.per_page,
        });
        commit("setLoading", false);
        return;
      } else if (id == "wish") {
        productid = state.wish.id;
      }
      const res = await api.queryProductList({
        foldId: productid,
        searchname: name,
        type: state.selectedType,
        page,
        pageSize,
      });
      let data = res.data ?? [];
      let m = data.map((p) => ({
        id: p.id,
        price: p.price,
        desc: p.intro,
        name: p.name,
        like: Boolean(p.is_like),
        src: JSON.parse(p.file_info).main_file.img_url,
        type: p.type,
      }));

      commit("setProducts", m);
      commit("setPage", {
        page: res.current_page,
        total: res.total,
        pageSize: res.per_page,
      });
      commit("setLoading", false);
    },
    async searchImage({ state, commit }, image) {
      commit("setLoading", true);
      let form = new FormData();
      form.append("image", image);
      form.append("page", this.page);
      form.append("page_size", 10);
      const res = await api.getDesignImageList(form);
      let boms = res.bomInfo;
      commit("setProducts", boms);
      commit("setPage", {
        page: res.current_page,
        total: boms.length,
        pageSize: res.per_page,
      });
      commit("setLoading", false);
    },
    async queryProductList({ state, commit }, { page = 1, pageSize = 12 } = {}) {
      commit("setLoading", true);
      const id = state.currentFolder;
      let productid = state.currentFolder;

      //   const baseUrl = "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com"
      if (id == Number.MAX_VALUE) {
        let res = await api.getBomtable({ page, page_size: pageSize });

        let m = res.data.map((p) => ({
          id: p.id,
          src: p.img_url,
          name: p.name,
          type: p.type,
          label: "edit",
          like: Boolean(p.is_like),
          favicon: false,
        }));
        commit("setProducts", m);
        commit("setPage", {
          page: res.current_page,
          total: res.total,
          pageSize: res.per_page,
        });
        commit("setLoading", false);
        return;
      } else if (id == "wish") {
        productid = state.wish.id;
      }
      const res = await api.queryProductList({
        foldId: productid,
        searchname: "",
        type: state.selectedType,
        page,
        pageSize,
      });
      let data = res.data ?? [];
      let m = data.map((p) => ({
        id: p.info.id,
        price: p.info.price,
        desc: p.info.intro,
        name: p.info.name,
        like: Boolean(p.info.is_like),
        src: JSON.parse(p.info.file_info).main_file.img_url,
        type: p.info.type,
      }));

      commit("setProducts", m);
      commit("setPage", {
        page: res.current_page,
        total: res.total,
        pageSize: res.per_page,
      });
      commit("setLoading", false);
    },
  },
  getters: {
    filterProducts(state) {
      if (state.selectedType == 0) {
        return state.products;
      }
      return state.products.filter((p) => p.type == state.selectedType);
    },
  },
};

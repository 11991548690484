<template>
  <el-table
    :empty-text="$t('emptyData')"
    :data="data"
    :span-method="cellMerge"
    :header-cell-style="headerStyle"
    row-class-name="reviewTableRow"
    border
    style="width: 48%"
  >
    <el-table-column :label="$t('position')" align="center" rowspan="2">
      <el-table-column prop="name" align="center" width="30"> </el-table-column>
      <el-table-column prop="num" align="center" width="40"> </el-table-column>
    </el-table-column>
    <el-table-column
      prop="cover_pic"
      :label="$t('image')"
      align="center"
      width="40"
    >
      <template slot-scope="scope">
        <div v-if="scope.row.cover_pic != ''">
          <img
            class="texture_img"
            :src="scope.row.cover_pic + '?imageMogr2/thumbnail/!10p'"
            crossorigin="anonymous"
            alt=""
          />
        </div>
        <div
          v-if="scope.row.cover_pic == '' && scope.row.texture_remark_img != ''"
        >
          <img
            class="texture_img"
            :src="scope.row.texture_remark_img + '?imageMogr2/thumbnail/!10p'"
            crossorigin="anonymous"
            alt=""
          />
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="color"
      :label="$t('color')"
      align="center"
      width="80"
    >
      <template slot-scope="scope">
        <div v-if="scope.row.color != ''">
          <div
            class="texture_img"
            :style="{ backgroundColor: scope.row.color }"
            alt=""
          ></div>
          <div>
            {{ scope.row.pantone }}
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="texture_name"
      :label="$t('materialName')"
      align="center"
    >
      <template slot-scope="scope">
        <div>{{ scope.row.sn }}{{ scope.row.texture_name }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="technology" :label="$t('technology')" align="center">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "reviewTable",
  props: {
    data: {
      type: Array,
    },
    spanMethod: {},
  },
  created() {
    this.spanArrs = this.getSpanArr(this.data, "part_id");
  },
  methods: {
    //合并表头
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1) {
        return { display: "none" };
      }
    },
    // 计算 数据合并 索引
    getSpanArr(data, params) {
      // 接收重构数组
      let arr = [];
      // 设置索引
      let pos = 0;
      // 控制合并的数组
      let spanArr = [];
      // spanArr 处理
      // const redata = arr.map(v => v[params])
      const datas = JSON.parse(JSON.stringify(data)).sort(function (a, b) {
        if (a[params] != b[params]) {
          return a[params] - b[params];
        }
        return a.num - b.num;
      });

      let redata = datas.map((v) => v[params]);
      redata.reduce((old, cur, i) => {
        if (i === 0) {
          spanArr.push(1);
          pos = 0;
        } else {
          if (cur === old) {
            spanArr[pos] += 1;
            spanArr.push(0);
          } else {
            spanArr.push(1);
            pos = i;
          }
        }

        return cur;
      }, {});
      return spanArr;
    },

    // 合并 tableData 数据
    cellMerge({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArrs[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
};
</script>

<style lang="scss"></style>

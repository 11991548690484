<template>
  <el-card class="cbd-page">
    <cbdHeader :headerData="allHeaderData"></cbdHeader>
    <cbdPart
      v-for="(val, key) in allPartList"
      :itemData="val"
      :index="key"
      :key="key + 'a'"
      @addPart="addPart"
      @setAmount="setAmount"
    ></cbdPart>
    <cbdPart
      v-for="(val, key) in allOtherList"
      :itemData="val"
      :index="key"
      :key="key + 'b'"
      @addPart="addOtherPart"
      @setAmount="setOtherAmount"
    ></cbdPart>
    <cbdCostTable
      v-for="(val, key) in allCostList"
      :itemData="val"
      :index="key"
      :key="key + 'c'"
      @addCost="addCost"
      @setCostAmount="setCostAmount"
    ></cbdCostTable>
    <cbdTotalTable
      :allTotalTable="allTotalData"
      @setAllCost="setAllCost"
    ></cbdTotalTable>
  </el-card>
</template>

<script>
import cbdHeader from "./cbdHeader";
import cbdPart from "./cbdPart";
import cbdCostTable from "./cbdCostTable";
import cbdTotalTable from "./cbdTotalTable";
import { mapState } from "vuex";
export default {
  name: "cbdPage",
  components: {
    cbdHeader,
    cbdPart,
    cbdCostTable,
    cbdTotalTable,
  },
  props: {
    itemData: {
      type: Object,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkUpdate: this.isUpdate,
      allPartList: [],
      allCostList: [],
      allOtherList: [],
      allHeaderData: {},
      allTotalData: {},
    };
  },
  computed: {
    ...mapState("jiyang/form", [
      "partList",
      "costList",
      "otherPartList",
      "headerData",
      "totalData",
    ]),
  },
  watch: {
    itemData() {
      this.initData();
    },
    isUpdate(val) {
      this.checkUpdate = val;
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.itemData && this.isUpdate) {
        let val = this.itemData;
        this.allPartList = val.partList;
        this.allCostList = val.costList;
        this.allOtherList = val.otherPartList;
        this.allTotalData = val.totalData;
        this.allHeaderData = val.headerData;
      } else {
        this.allPartList = this.deepClone(this.partList);
        this.allCostList = this.deepClone(this.costList);
        this.allOtherList = this.deepClone(this.otherPartList);
        this.allHeaderData = this.deepClone(this.headerData);
        this.allTotalData = this.deepClone(this.totalData);
      }
    },
    //设置总数
    setAmount(data) {
      let index = data.index;
      let key = data.key;
      let amount = data.amount;
      let item = this.allPartList[index].list[key];

      if (data.amount) {
        item.cost = amount.toFixed(2);
      } else {
        item.cost = amount;
      }
      this.$set(this.allPartList[index].list, key, item);
      let total = this.allPartList[index].list.reduce((total, item) => {
        return total + Number(item.cost);
      }, 0);
      this.allPartList[index].total = Number(total).toFixed(2);
      this.setAllCost();
    },
    //设置其他总数
    setOtherAmount(data) {
      let index = data.index;
      let key = data.key;
      let amount = data.amount;
      let item = this.allOtherList[index].list[key];
      if (data.amount) {
        item.cost = amount.toFixed(2);
      } else {
        item.cost = amount;
      }
      this.$set(this.allOtherList[index].list, key, item);
      let total = this.allOtherList[index].list.reduce((total, item) => {
        return total + Number(item.cost);
      }, 0);
      this.allOtherList[index].total = Number(total).toFixed(2);
      this.setAllCost();
    },
    setCostAmount(data) {
      let index = data.index;
      let total = this.allCostList[index].list.reduce((total, item) => {
        return total + Number(item.value);
      }, 0);
      this.allCostList[index].total = total;
      this.setAllCost();
    },
    //设置所有成本
    setAllCost() {
      let material_cost = this.allPartList.reduce((total, item) => {
        return total + Number(item.total);
      }, 0);
      let other_cost = this.allOtherList.reduce((total, item) => {
        return total + Number(item.total);
      }, 0);
      let people_cost = this.allCostList[0].total;
      let create_cost = this.allCostList[1].total;
      let profit =
        (material_cost + other_cost + people_cost + create_cost) /
          (1 - this.allTotalData.cost.profit.percent / 100) -
        (material_cost + other_cost + people_cost + create_cost);
      let tax =
        (material_cost + other_cost + people_cost + create_cost + profit) *
        (this.allTotalData.cost.tax.percent / 100);
      let profit_total =
        material_cost + other_cost + people_cost + create_cost + profit;
      let tax_profit_total =
        material_cost + other_cost + people_cost + create_cost + profit + tax;
      let current_material_ratio =
        (material_cost / tax_profit_total).toFixed(2) * 100 + "%";
      let transaction_material_ratio =
        (material_cost / tax_profit_total).toFixed(2) * 100 + "%";
      this.allTotalData.cost.materials_cost.value = material_cost.toFixed(2);
      this.allTotalData.cost.other_cost.value = other_cost.toFixed(2);
      this.allTotalData.cost.people_cost.value = people_cost.toFixed(2);
      this.allTotalData.cost.create_cost.value = create_cost.toFixed(2);
      this.allTotalData.cost.profit.value = profit.toFixed(2);
      this.allTotalData.cost.tax.value = tax.toFixed(2);
      this.allTotalData.cost.current_material_ratio.value =
        current_material_ratio;
      this.allTotalData.cost.transaction_material_ratio.value =
        transaction_material_ratio;
      this.allTotalData.total.profit_total = profit_total.toFixed(2);
      this.allTotalData.total.tax_profit_total = tax_profit_total.toFixed(2);
    },
    //保存
    saveData() {
      let obj = {
        partList: this.allPartList,
        otherList: this.allOtherList,
        costList: this.allCostList,
        headerData: this.allHeaderData,
        totalData: this.allTotalData,
      };
      console.log(obj);
    },
    //添加行
    addPart(data) {
      let index = data.index;
      this.allPartList[index].list.push({
        part: "",
        texture_name: "",
        specification: "",
        net_usage: "",
        loss: "",
        price: "",
        cost: "",
        remark: "",
      });
    },
    addOtherPart(data) {
      let index = data.index;
      this.allOtherList[index].list.push({
        part: "",
        texture_name: "",
        specification: "",
        net_usage: "",
        loss: "",
        price: "",
        cost: "",
        remark: "",
      });
    },
    addCost(data) {
      let index = data.index;
      for (let i = 0; i < 5; i++) {
        this.allCostList[index].list.push({
          label: "",
          value: "",
        });
      }
    },
    deepClone(params) {
      if (typeof params === "object") {
        let obj = Array.isArray(params) ? [] : {};
        for (const i in params) {
          obj[i] = this.deepClone(params[i]);
        }
        return obj;
      } else {
        return params;
      }
    },
  },
};
</script>

<style lang="scss">
.cbd-page {
  margin: 60px auto;
  width: 1155px;
  /*min-height: 1634px;*/
  background-color: #fff;
  padding: 20px;
  position: relative;
  overflow: visible !important;
}
</style>

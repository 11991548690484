<template>
  <!-- <div class="conversation border box-shadow-light bg-gray" v-show="visible"> -->
  <div class="conversation" v-show="visible">
    <VueDragResize
      ref="resizeRef"
      :isActive="isActive"
      :minh="500"
      :minw="450"
      :w="1040"
      :h="845"
      :x="422"
      :y="100"
      :z="998"
      :sticks="['tm', 'bm', 'ml', 'mr']"
      v-on:resizing="resize"
      v-on:dragging="resize"
      :isResizable="isActive"
      style="position: fixed"
    >
      <div class="wrap flex bg-gray">
        <div class="flex flex-col conversation-list__container">
          <div class="tool flex p-12">
            <!-- <el-input placeholder="请输入内容"></el-input> -->
            <div class="search mr-16 w-100 flex-1">
              <i
                class="iconfont icon-fangdajing font-size-16 text-info-color"
              ></i>
              <a-input
                :placeholder="$t('messageSearch')"
                style="width: 200px"
                @pressEnter="onSubmitSearch"
                ref="searchRef"
                @click="$refs.searchRef.focus()"
                v-model="searchname"
                @search="onSubmitSearch"
                allowClear
                ><a-icon slot="prefix" type="search" />
              </a-input>
              <!-- <input
                class="input w-100 outline radius-4 font-size-16 focus border-gray bg-gray-1"
                @keyup.enter="onSubmitSearch"
                type="text"
                v-model="searchname"
                @focus="searchFocus(true)"
                @blur="searchFocus(false)"
                @keyup.delete="onSubmitSearchdel"
                @input="onSearchinput"
                :placeholder="$t('messageSearch')"
                ref="input"
                @click="$refs.input.focus()"
              /> -->
            </div>
            <button
              class="plus hvr-bg-color-priamry outline radius-4 border p-0"
              @click="handleAdd"
            >
              <i class="iconfont icon-ic_add font-size-14"></i>
              <!-- {{$t("meetingCreate")}} -->
            </button>
          </div>
          <conversation-list
            @click="handleOpenConvs"
            @addnewmsg="addnewmsg"
            ref="conversationListRef"
          ></conversation-list>
        </div>
        <current-conversation
          :visible.sync="showCurrentConvs"
          :current="someone"
          @changeUserList="changeUserList"
          @upMeetingStatue="upMeetingStatue"
          @newmeeting="newmeeting"
          @jumpgoods="jumpgoods"
          ref="conversation"
        ></current-conversation>
        <group-detail
          :visible="showGroupDetail"
          :btntype="isCreate"
          @newmeeting="newmeeting"
          :ownid="ownid"
          ref="creategroup"
        ></group-detail>
      </div>
    </VueDragResize>
    <contact-modal @change="handleContactChange" @ok="handleOk" />
    <!-- <div
      class="
        conv-header
        text-white
        flex
        just-space-between
        align-center
        p-h-14
        border-bottom
        primary-color-v2
      "
    >
      <div>
        <i
          class="iconfont icon-jiantou font-size-14 text-white mr-6 pointer"
          v-if="showCurrentConvs"
          @click.stop="hanldeBack"
        ></i>
        <span class="title font-size-18">{{ title }}</span>
      </div>
      <span>
        <i
          class="iconfont hvr-color-white ml-16 pointer"
          :class="icon"
          v-for="(icon, idx) in icons"
          :key="idx"
          @click="handleIconClick(idx)"
        ></i>
      </span>
    </div> -->
    <div v-for="newmsg in newMessage" :key="newmsg.meeting_id">
      <newmessage-tip
        :newmsg="newmsg"
        @click="handleOpenConvs"
        @delnewmsg="delnewmsg"
      >
      </newmessage-tip>
    </div>

    <div class="conv-sharetip" v-if="callIMmsg.msgid != 0">
      请点击群,分享商品信息
    </div>
  </div>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import NewmessageTip from "./newmessage-tip.vue";
import GroupDetail from "./group-detail.vue";
import ConversationList from "./conversation-list.vue";
import CurrentConversation from "../conversation/current-conversation.vue";
import ContactModal from "../components/modal/contact-modal.vue";
import im from "@/utils/im.js";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapMutations } = createNamespacedHelpers(
  "jiyang/project-group"
);
const {
  mapMutations: mapMutationsGroup,
  mapState: mapStateGroup,
  mapActions: mapActionsGroup,
} = createNamespacedHelpers("jiyang/im");

export default {
  mixins: [im],
  components: {
    ConversationList,
    GroupDetail,
    CurrentConversation,
    NewmessageTip,
    ContactModal,
    VueDragResize,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: true,
      IMsign: "",
      IMisready: false,
      showCurrentConvs: true,
      someone: {
        meeting_id: 0,
      },
      userlist: null,
      title: this.$t("meeting"),
      icons: ["", "icon-jiantoudown"],
      isCreate: true,
      callIMmsg: {
        msgid: 0, //发送商品或bom单ID
        name: 0, //发送商品或bom单ID
        url: 0, //发送商品或bom单ID
        type: 0, //0商品  1bom单
      },
      ownid: 0,
      searchname: "",
      searchfile: null,
      searchfocus: false,
      searchTimeout: null,
      newMessage: [],
      conversations: [],
      pageInfo: {
        page: 0,
        pageSize: 50,
      },
    };
  },
  computed: {
    ...mapStateGroup(["showGroupDetail", "position", "showConversation"]),
    sendIMmsg() {
      return this.$store.getters.sendIMmsg;
    },
    groupListFilter() {
      return this.grouplis;
    },
  },
  created() {
    let user_info = JSON.parse(this.$cookie.get("user_info"));
    this.$api.getIMsign().then((res) => {
      this.IMsign = res;
      this.loginIM(user_info, this.IMsign);
    });
    // this.getGroupList();
    this.fetchAllContact();
  },
  watch: {
    visible(val) {
      this.isActive = val;
      if (val && !this.IMisready) {
        let user_info = JSON.parse(this.$cookie.get("user_info"));
        this.loginIM(user_info, this.IMsign, 1);
      }
      // !val ? (this.showCurrentConvs = false) : "";
    },
    searchname(val) {
      this.filterGroup({ name: val });
    },
    sendIMmsg: {
      deep: true,
      handler(val) {
        if (val.msgid == 0) return;
        if (this.searchfocus) {
          this.searchfile = {
            id: val.msgid,
            name: val.name,
            type: val.type,
          };
          this.searchname = "[" + val.name + "]";
        } else if (this.showCurrentConvs) {
          let data = {
            id: val.msgid,
            name: val.name,
            url: val.url,
            type: val.type,
          };
          this.$refs.conversation.sendmsg(6, "", JSON.stringify(data));
        } else {
          this.callIMmsg.msgid = val.msgid;
          this.callIMmsg.name = val.name;
          this.callIMmsg.url = val.url;
          this.callIMmsg.type = val.type;
        }
        this.$store.commit("callIM", {
          id: 0,
          name: "",
          url: "",
          type: 0,
        });
        setTimeout(() => {
          // this.$emit("update:visible", true);
          this.setShowConversation(true);
        }, 200);
      },
    },
  },
  methods: {
    ...mapMutationsGroup([
      "setContactModalShow",
      "setCurrentConversation",
      "setShowGroupDetail",
      "resetContact",
      "setModalConfig",
      "setShowConversation",
      "filterGroup",
    ]),
    ...mapActionsGroup(["fetchAllContact"]),
    ...mapActions(["queryProductList"]),
    ...mapMutations(["setCurrentFolders", "setShare"]),
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    async handleOk() {
      this.getGroupList();
    },

    async createGroup(user) {
      //  avatar: ""
      // introduction: ""
      // memberList: [{id: 12}, {id: 13}, {id: 14}, {id: 15}, {id: 12}, {id: 13}, {id: 14}, {id: 15}]
      // name: "1111"
      // notification: ""
      if (!user.length) {
        return;
      }
      const param = {
        avatar: "",
        introduction: "",
        memberList: user,
        name:
          user
            .map((u) => u.nickname)
            .splice(0, 1)
            .join(" ") + `...`,
        notification: "",
      };
      await this.$api.creatMeeting(param);

      //  this.$api.creatMeeting(param).then((res) => {
      //   this.close();
      //   this.members = [];
      //   this.createroom = {
      //     name: "",
      //     avatar: "",
      //     introduction: "",
      //     notification: "",
      //     memberList: [],
      //   };
      //   res.meeting_img =
      //     "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
      //     res.meeting_img;
      //   this.$emit("newmeeting", res, 0);
      // });
    },
    getPageInfo() {
      let p = this.pageInfo;
      return {
        page: p.page,
        pagesize: p.pageSize,
      };
    },

    getGroupList() {
      this.$refs.conversationListRef.getGroupList();
    },
    getMeeting() {
      // let data = { page: this.meetingpage, pagesize: this.meetingpagesize };
      let p = this.pageInfo;
      let pageInfo = {
        page: p.page,
        pagesize: p.pageSize,
      };

      this.$api.getMeeting(pageInfo).then((res) => {
        this.list = res;
        let _this = this;
        this.list.forEach((msg) => {
          msg.meeting_img =
            "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
            msg.meeting_img;
          if (msg.meeting_chat_type == 4)
            msg.lastmsg = _this.$t("meetingPicmsg");
          else if (msg.meeting_chat_type == 5)
            msg.lastmsg = _this.$t("meetingPicECL");
          // _this.timeTostr(msg);
        });
      });
    },
    timeFormat(timestamp) {
      day().unix(timestamp).format("yyyy-MM-dd");
    },
    handleContactChange(contact) {
      // console.log("contact", contact);
    },
    hanldeBack() {
      this.closeShowMeeting();
    },
    onIMReadyChange(isready) {
      this.IMisready = isready;
      if (isready == false) {
        this.$emit("update:visible", isready);
        console.log("IM在其他地方登陆了!!");
      }
    },
    onIMMsg(newVal) {
      for (var i = 0; i < newVal.length; i++) {
        if (newVal[i].conversationType == this.$TIM.TYPES.CONV_SYSTEM) break;
        newVal[i].payload.data = parseInt(newVal[i].payload.data);
        if (newVal[i].payload.data == 128) {
          this.newmeeting(JSON.parse(newVal[i].payload.description), 2);
          break;
        }
        //群被删除了
        else if (newVal[i].payload.data == 129) {
          let _this = this;
          this.$alert(this.$t("meetingClosetishi2"), this.$t("messageTiShi"), {
            confirmButtonText: this.$t("yes"),
            type: "warning",
            center: true,
          }).then(() => {
            let data = { meeting_id: parseInt(newVal[i].payload.description) };
            _this.newmeeting(data, -1);
            if (this.someone && newVal[i].to == this.someone.room) {
              _this.showCurrentConvs = false;
            }
          });

          break;
        }
        this.$emit("playaudio");
        if (!this.visible) this.$emit("newmessage"); //通知上层 有新的信息
        let isnowmeeting = 0;
        if (this.someone && newVal[i].to == this.someone.room) {
          this.$refs.conversation.onIMMsg(newVal[i]);
          this.showCurrentConvs ? (isnowmeeting = 1) : "";
        }
        this.upMeetingStatue(newVal[i], isnowmeeting);
      }
    },
    addnewmsg(meeting) {
      if (this.showCurrentConvs) {
        const idx = this.newMessage.findIndex(
          (me) => me.meeting_id == meeting.meeting_id
        );
        idx >= 0 ? "" : this.newMessage.push(meeting);
      }
    },
    delnewmsg(meeting) {
      const idx = this.newMessage.findIndex(
        (me) => me.meeting_id == meeting.meeting_id
      );
      idx >= 0 ? this.newMessage.splice(idx, 1) : "";
    },
    //更新会议状态
    upMeetingStatue(msg, isnow) {
      this.$refs.conversationListRef.newmeetingmsg(msg, isnow);
    },

    jumpfile(id) {
      this.setCurrentFolders(id);
      this.queryProductList();
    },
    //跳转商品
    jumpgoods(goodsmsg) {
      if (goodsmsg.type < 4)
        this.$router.push({
          name: "productDetail",
          query: {
            id: goodsmsg.id,
            type: goodsmsg.type,
          },
        });
      else if (goodsmsg.type == 5 || goodsmsg.type == 6) {
        this.jumpfile(goodsmsg.id);
        this.$router.push({
          name: "project-group",
          query: {
            id: goodsmsg.id,
            type: goodsmsg.type,
          },
        });
      } else if (goodsmsg.type == 7) {
        this.$router.push({
          name: "workspace",
          query: {
            id: goodsmsg.id,
          },
        });
      } else if (goodsmsg.type == 8) {
        this.$router.push({
          name: "draw",
          query: {
            type: 2,
            id: goodsmsg.id,
          },
        });
      }
    },
    changeUserList(userlist) {
      this.userlist = userlist;
    },
    loginIM(user_info, sig, type = 0) {
      //这边需要用户的ID和数据
      this.createIm(user_info, sig, type);
    },
    handleIconClick(idx) {
      if (idx == 0) {
        this.showCurrentConvs ? this.handleChange() : "";
        return;
      }
      if (idx == 1) {
        this.handleMini();
        // this.showCurrentConvs = false;
        // this.closeShowMeeting();
        // this.handleMini();
        // this.showCreate
        //   ? this.closeCreate()
        //   : this.showCurrentConvs
        //   ? this.closeShowMeeting()
        //   : this.handleMini();
        return;
      }
    },
    handleOpenConvs(room) {
      this.setShowGroupDetail(false);
      this.setCurrentConversation({
        ...room,
      });
      let name = room.meeting_name || "工作群";
      this.title = name;
      if (this.callIMmsg.msgid != 0) {
        let data = {
          id: this.callIMmsg.msgid,
          name: this.callIMmsg.name,
          url: this.callIMmsg.url,
          type: this.callIMmsg.type,
        };
        // let user = JSON.parse(this.$cookie.get('user_info'));
        // let msg = {
        //   meeting_user_id: user.id,
        //   meeting_user_name: user.last_name+user.first_name,
        //   meeting_user_img: user.head_img,
        //   meeting_chat_conent: "",
        //   meeting_chat_sub: JSON.stringify(data),
        //   meeting_chat_type: 5,
        //   finish: 0,
        // };

        this.$refs.conversation.sendmsg(
          6,
          "",
          JSON.stringify(data),
          1,
          room.room,
          room.meeting_id
        );
        this.callIMmsg.msgid = 0;
      } else {
        this.icons[0] = "icon-ellipsis";
        // this.icons[1] = "icon-guanbi";
        this.someone = room;
        this.showCurrentConvs = true;
        this.ownid = room.uid;
      }
    },
    closeShowMeeting() {
      this.title = this.$t("meeting");
      this.icons[0] = "";
      // this.icons[1] = "icon-jiantoudown";
      this.showCurrentConvs = false;
      this.showCreate = false;
      this.ownid = 0;
    },
    handleMini() {
      this.$emit("update:visible", false);
      this.callIMmsg.msgid = 0;
    },
    closeCreate() {
      this.title = this.$t("meeting");
      this.showCreate = false;
      this.showCurrentConvs ? (this.title = this.someone.meeting_name) : "";
    },
    handleAdd() {
      // this.showCreate = true;
      // this.isCreate = true;
      // this.icons[1] = "icon-guanbi";
      // this.title = this.$t("meetingCreate");
      this.resetContact();
      this.setContactModalShow(true);
      this.setModalConfig({
        mode: "create",
      });
    },
    handleChange() {
      this.showCreate = true;
      this.isCreate = false;
      this.$refs.creategroup.setNowMeetingMsg(this.someone, this.userlist);
      // this.title = this.$t("meetingModify");
    },
    // handleManage() {},
    //type 0 创建通知 1 修改通知 -1 退出通知
    newmeeting(newmeeting, type) {
      this.$refs.conversationListRef.newmeeting(newmeeting, type);
      //通知有新的会议
      if (type == 0)
        this.$refs.conversation.sendSysmsgToIM(
          JSON.stringify(newmeeting),
          "",
          newmeeting.room,
          128
        );
      //通知人员改变
      if (type == 1)
        this.$refs.conversation.sendSysmsgToIM("", "", newmeeting.room, 127);
    },
    onSubmitSearch() {
      this.filterGroup({ name: this.searchname });
      // this.$refs.conversationListRef.getMeeting(
      //   this.searchname,
      //   this.searchfile
      // );
      // this.$refs.conversationListRef.searchMeetingGroup(
      //   this.searchname,
      //   this.searchfile
      // );
      // this.searchname = "";
      // this.searchfile = null;
    },
    //删除文件
    onSubmitSearchdel() {
      if (this.searchfile) {
        this.searchfile = null;
        this.searchname = "";
      }
    },
    //如果已经分享文件无法输入
    onSearchinput(e) {
      // console.log(e);
      if (this.searchfile) {
        this.searchname = "[" + this.searchfile.name + "]";
      }
    },
    searchFocus(e) {
      if (e) {
        this.searchfocus = e;
        clearTimeout(this.searchTimeout);
      } else {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        let _this = this;
        this.searchTimeout = setTimeout(() => {
          _this.searchfocus = e;
        }, 500);
      }
    },
    modifyStyle() {
      const tm = document.querySelector(".conversation .vdr-stick-tm");
      tm &&
        (tm.style =
          "height: 6px;  width: 100%; left:0;margin-left:0;top:-2px;");
      const bm = document.querySelector(".conversation .vdr-stick-bm");
      bm &&
        (bm.style =
          "height: 6px;  width: 100%; left:0;margin-left:0;bottom:-2px;");
      const ml = document.querySelector(".conversation .vdr-stick-ml");
      ml &&
        (ml.style =
          "width: 6px;  height: 100%; left:0px;top:0px;margin-left:-2;");
      const mr = document.querySelector(".conversation .vdr-stick-mr");
      mr &&
        (mr.style =
          "width: 6px;  height: 100%; right:-2px;top:0px;margin-left:0;");
    },
  },
  mounted() {
    this.modifyStyle();
  },
};
</script>

<style lang="less">
.conversation {
  // position: absolute;
  // top: 10px;
  // left: 10px;
  // height: calc(100vh - 100px);
  // z-index: 999;
  height: 100%;
  width: 100%;
  background: #f3f3f3;
  // position: relative;
  .conv-header {
    width: 100%;
    height: 42px;
  }
  .wrap {
    // overflow: auto;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .conv-sharetip {
    width: 100%;
    text-align: center;
    height: 30px;
  }
  .conversation-list__container {
    display: flex;
    flex-direction: column;
    width: 273px;
    height: 100%;
    border-right: 1px solid #b2b2b2;
    flex-shrink: 0;
    flex-grow: 0;
    > .tool {
      width: 100%;
      height: 68px;
      align-items: center;
      flex-shrink: 0;
      box-shadow: 0px 0px 10px rgba(46, 46, 46, 0.3);
      .ant-input {
        border-radius: 18px;
      }
      .search {
        // height: 26px;
        position: relative;
        .input {
          height: 100%;
          font-size: 14px;
          text-indent: 20px;
          border-radius: 13px;
        }

        > i {
          top: 50%;
          left: 5px;
          position: absolute;
          transform: translate(0, -50%);
        }
      }
      .plus {
        width: 26px;
        //width: 100%;
        height: 26px;
        color: #b2b2b2;
      }
    }
  }
  .vdr.active:before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: 1px solid #b2b2b2;
  }
  .vdr-stick {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    font-size: 1px;
    background: transparent;
    border: none;
    z-index: 998;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
</style>

<template>
  <div class="recommend-card" :class="[isChoose ? 'recommend-choose' : '']">
    <div class="recommend-img-box" @click="toAddProduct">
      <el-image
        v-if="itemData.img_url"
        class="recommend-img"
        fit="contain"
        :src="itemData.img_url"
      >
      </el-image>
      <i v-else class="iconfont icon-plus-full recommend-add"></i>
    </div>
    <component
      is="normalTable"
      :checkEdit="checkEdit"
      :table="itemData.tableData"
      index="recommend"
      @addRow="addRow"
    />
  </div>
</template>
<script>
import normalTable from "./normalTable";
export default {
  name: "recommendCard",
  components: {
    normalTable,
  },
  props: {
    checkEdit: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
    itemData: {
      type: Object,
    },
  },
  data() {
    return {
      // itemData: {
      //   img: "",
      //   tableData: {
      //     props: [
      //       { prop: "title", type: "title", width: 100 },
      //       { prop: "detail", type: "input", width: 90 },
      //     ],
      //     data: [
      //       {
      //         title: { value: "Designation", type: "normal" },
      //         detail: { value: "", type: "normal" },
      //       },
      //     ],
      //   },
      // },
      isChoose: false,
    };
  },
  created() {
    let that = this;
    // document.addEventListener("click", (e) => {
    //   if (!that.isChoose) return;
    //   if (!that.$el.contains(e.target)) {
    //     that.isChoose = false;
    //   }
    // });
  },
  methods: {
    addRow(data) {
      this.itemData.tableData.data.push({
        title: { value: "", type: "normal" },
        detail: { value: "", type: "normal" },
      });
    },
    toAddProduct(e) {
      let top = e.y;
      this.isChoose = true;
      this.$emit("showProduct", {
        top: top,
        index: this.index,
        isRecommend: true,
      });
    },
  },
};
</script>
<style lang="scss">
.recommend-card {
  width: 566px;
  margin-bottom: 20px;
  .recommend-img-box {
    background-color: #f6f6f6;
    height: 286px;
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .recommend-add {
      font-size: 40px;
      color: #959595;
    }
    .recommend-img {
      width: 100%;
      height: 100%;
    }
  }
}
.recommend-choose {
  border: 1px solid $base-config-color;
}
</style>

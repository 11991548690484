<template>
  <div class="review-header">
    <div class="header-left">
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomSn") }}：</div>
        <div class="basic-msg-text">
          {{ sn }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomDate") }}：</div>
        <div class="basic-msg-text">
          {{ svgData.delivery_date }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomStyle") }}：</div>
        <div class="basic-msg-text">
          {{ svgData.style_number }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomColor") }}：</div>
        <div class="basic-msg-text">
          {{ svgData.style_color }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomModel") }}：</div>
        <div class="basic-msg-text">
          {{ svgData.model_name }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomHeader") }}：</div>
        <div class="basic-msg-text">
          {{ svgData.shoe_last_name }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomSize") }}：</div>
        <div class="basic-msg-text">
          {{ svgData.size }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomNum") }}：</div>
        <div class="basic-msg-text">
          {{ svgData.count }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">{{ $t("bomClient") }}：</div>
        <div class="basic-msg-text">
          {{ svgData.client }}
        </div>
      </div>
      <div class="basic-msg-item">
        <div class="basic-msg-title">制单人：</div>
        <div class="basic-msg-text">
          {{ info.last_name }}{{ info.first_name }}
        </div>
      </div>
    </div>
    <div class="header-right">
      <div
        class="bom-png"
        @click.stop="doNothing"
        v-drag
        :class="clickImg ? 'click-border' : ''"
      >
        <img
          id="bomUrl"
          :src="bomUrl"
          :style="{ transform: `scale(${scale},${scale})` }"
          crossorigin="anonymous"
        />
        <!--                <div class="png-size-box">-->
        <!--                    <div class="png-size" @click="changeSize(0)">+</div>-->
        <!--                    <div class="png-size" @click="changeSize(1)">-</div>-->
        <!--                </div>-->
        <el-button-group class="png-size-box" v-if="clickImg">
          <el-button size="mini" @click="changeSize(0)">+</el-button>
          <el-button size="mini" @click="changeSize(1)">-</el-button>
        </el-button-group>
      </div>
    </div>
  </div>
</template>

<script>
let that;
import { mapState } from "vuex";
export default {
  name: "review-header",
  props: {
    svgData: {
      type: Object,
      default() {
        return {};
      },
    },
    sn: {
      type: String,
    },
    bomUrl: {
      type: String,
    },
  },
  data() {
    return {
      clickImg: true,
      scale: 1.3,
      scaleNum: 0.2,
    };
  },
  computed: {
    ...mapState("jiyang/user", ["info"]),
  },
  created() {
    that = this;
  },
  directives: {
    drag: {
      // 指令的定义
      inserted: function (el) {
        // el.drag();
        //获取元素
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        let isDown = false;
        //鼠标按下事件
        el.onmousedown = function (e) {
          //获取x坐标和y坐标
          x = e.clientX;
          y = e.clientY;

          //获取左部和顶部的偏移量
          l = el.offsetLeft;
          t = el.offsetTop;
          //开关打开
          isDown = true;
          that.clickImg = true;
          //设置样式
          el.style.cursor = "move";
        };
        //鼠标移动
        window.onmousemove = function (e) {
          if (isDown == false) {
            return;
          }
          //获取x和y
          let nx = e.clientX;
          let ny = e.clientY;
          //计算移动后的左偏移量和顶部的偏移量
          let nl = nx - (x - l);
          let nt = ny - (y - t);

          el.style.left = nl + "px";
          el.style.top = nt + "px";
        };
        //鼠标抬起事件
        el.onmouseup = function (e) {
          //开关关闭
          isDown = false;
          el.style.cursor = "default";
        };
      },
    },
  },
  methods: {
    clickPage() {
      that.clickImg = false;
    },
    doNothing() {},
    getImage(url, imgId) {
      var xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "blob";
      xhr.onload = async function () {
        if (this.status == 200) {
          document.getElementById(imgId).src = await that.main(
            URL.createObjectURL(this.response)
          );
        }
      };
      xhr.send();
    },
    //图片转base64
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      var dataURL = canvas.toDataURL("image/png"); // 可选其他值 image/jpeg
      return dataURL;
    },

    main(src) {
      return new Promise((resolve) => {
        let url = "";
        if (src) {
          let image = new Image();
          image.src = src + "?v=" + Math.random(); // 处理缓存
          image.crossOrigin = "*";
          image.onload = function () {
            url = that.getBase64Image(image);
            resolve(url);
          };
        } else {
          resolve(url);
        }
      });
    },
    startMove(e) {
      this.offsetX = e.offsetX;
      this.offsetY = e.offsetY;
      this.flag = true;
    },
    changeSize(type) {
      if (type == 0) {
        this.scale += this.scaleNum;
      } else {
        this.scale -= this.scaleNum;
      }
    },
    movePng(e) {
      if (!this.flag) return;
      this.left = e.offsetX - this.offsetX;
      this.top = e.offsetY - this.offsetY;
    },
    endMove(e) {
      this.flag = false;
    },
  },
};
</script>

<style scoped lang="scss">
$page-border: 1px solid #aaa;
.review-header {
  max-height: 220px;
  border: $page-border;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  box-sizing: border-box;
  .header-left {
    width: 30%;
    height: 100%;
    padding: 10px 6px;

    box-sizing: border-box;
    .header-title {
      width: 100%;
      text-align: center;
      margin-bottom: 6px;
      font-size: 12px;
    }
    .basic-msg-item {
      width: 100%;
      display: flex;
      color: #333;
      margin-bottom: 8px;
      .basic-msg-title {
        width: 40%;
        text-align: right;
        flex-shrink: 0;
        font-size: 12px;
      }
      .basic-msg-text {
        width: 62%;

        font-weight: 600;
        word-wrap: break-word;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
  .header-right {
    width: 68%;
    /*height: 100%;*/
    padding: 10px 6px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    /*background-color: #aaa;*/
    position: relative;
    .bom-png {
      height: 90%;
      width: 70%;
      position: absolute;
      box-sizing: border-box;
      overflow: hidden;
      img {
        // width: 100%;
        // height: auto;
		width: auto;
		height: 100%;
		display: block;
		margin: auto;
      }
      .png-size-box {
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: flex;
        align-items: center;
        .png-size {
          font-size: 20px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .click-border {
      border: 2px solid #333;
    }
  }
}
</style>

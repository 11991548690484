import api from "@/api";
import dayjs from "dayjs";

const transform = (user) => {
  return {
    ...user,
    id: user.id,
    nickname: `${user.last_name || ""}${user.first_name || ""}`,
    avatar: user.head_img || "",
    selected: false,
    disabled: false,
  };
};

const transformGroup = (list) => {
  const baseUrl = "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com";

  return list.map((group) => {
    let lastmsg = "";
    // if (group.meeting_chat_type == 4) {
    if (group.meeting_chat_type == 4) {
      // lastmsg = this.$t("meetingPicmsg");
      lastmsg = "meetingPicmsg";
    } else if (group.meeting_chat_type == 5) {
      // lastmsg = this.$t("meetingPicECL");
      lastmsg = "meetingPicECL";
    }
    let formatTime = "";
    let unixTime = Number(group.update_time);
    let time = dayjs.unix(group.update_time);
    let today = dayjs().startOf("day").unix();
    let yestday = dayjs().subtract(1, "day").startOf("day").unix();
    if (unixTime > today) {
      formatTime = time.format("HH:mm");
    }
    if (unixTime >= yestday && unixTime <= today) {
      formatTime = "昨天";
    }
    if (today - unixTime >= 86400 && unixTime < yestday) {
      formatTime = time.format("YY/MM/DD");
    }

    return {
      ...group,
      lastmsg,
      meeting_img: baseUrl + group.meeting_img,
      formatTime: formatTime,
      time: dayjs.unix(group.update_time).format("YY/MM/DD"),
    };
  });
};
export default {
  namespaced: true,
  state: {
    sdkReady: false,
    messages: [],
    title: "Message",
    showContactModal: false,
    showGroupDetail: false,
    showConversation: false,
    totalContact: [],
    _employees: [],
    _enterprise: [],
    current: {
      room: "",
      meeting_id: 0,
      page: 0,
      size: 50,
    },
    pageInfo: {
      page: 0,
      pagesize: 50,
    },
    groupMember: [],
    groupDetail: {},
    modalConfig: {
      mode: "create",
    },
    conversationList: [],
    groupList: [],
    //辣鸡搜索接口问题，本地filter实现搜索
    _tempGroupList: [],
    // messageMap: new Map(),
  },

  mutations: {
    setSdkState(state, payload) {
      state.sdkReady = payload;
    },

    setGroupList(state, list) {
      state.groupList = list;
      state._tempGroupList = list;
    },

    // setMessageMap(state, payload) {
    //   const { room, msg } = payload;
    //   const target = state.messageMap.get(room) || [];
    //   target.push(msg);
    //   state.messageMap.set(room);
    // },

    setContactModalShow(state, val) {
      state.showContactModal = val;
    },

    setShowGroupDetail(state, val) {
      state.showGroupDetail = val;
    },

    setTotalContact(state, users) {
      state.totalContact = users;
    },

    setEnterprise(state, users) {
      state._enterprise = users;
    },

    setEmployees(state, users) {
      state._employees = users;
    },
    setGroupMember(state, members) {
      state.groupMember = members;
    },

    setCancelSelect(state, contact) {
      const idx = state.totalContact.findIndex((c) => c.id == contact.id);
      idx != -1 &&
        state.totalContact.splice(idx, 1, {
          ...contact,
        });
    },
    filterGroup(state, payload) {
      const { name = "" } = payload;
      if (name) {
        state.groupList = state._tempGroupList.filter((g) =>
          g.meeting_name.includes(name)
        );
      } else {
        state.groupList = state._tempGroupList;
      }
    },
    resetContact(state) {
      state.groupMember = [];
      state._employees = state._employees.map((e) => ({
        ...e,
        selected: false,
      }));
      state._enterprise = state._enterprise.map((e) => ({
        ...e,
        selected: false,
      }));
      state.totalContact = state.totalContact.map((contact) => {
        return {
          ...contact,
          selected: false,
        };
      });
    },

    setShowConversation(state, val) {
      state.showConversation = val;
    },

    removeGroupContact(state, contact) {
      const idx = state.groupMember.findIndex((c) => c.id == contact.id);
      state.groupMember.splice(idx, 1, {
        ...contact,
        selected: false,
      });
    },

    removeGroup(state) {
      const id = state.current.meeting_id;
      state.groupList = state.groupList.filter((g) => g.meeting_id != id);
      state.showGroupDetail = false;
    },
    setCurrentConversation(state, group) {
      state.current = {
        ...group,
      };
    },

    setMessage(state, msg) {
      state.messages = msg;
    },

    setTitle(state, title) {
      state.title = title;
    },

    setModalConfig(state, config) {
      state.modalConfig = config;
    },
  },

  actions: {
    // async filterGroup({ commit }, payload) {
    //   // const res = await api.searchMeeting(payload);
    //   // console.log(res);
    //   commit("setGroupList", payload);
    // },
    async fetchGroupList({ state, commit }) {
      let data = { page: 1, pagesize: 1000 };
      let list = await api.getMeeting(data);
      commit("setGroupList", transformGroup(list));
    },
    async fetchGroupInfo({ state, commit }) {
      const current = state.current;
      if (!current.meeting_id) {
        return;
      }
      // first_name: "浩1"
      // head_img: "/shoe/material/0.75281385873422751640945102.18.png"
      // id: 4
      // last_name: "朗"
      const { user } = await api.seeMeeting({ ...current, ...state.pageInfo });
      // console.log('users=>>', user);
      const baseUrl = "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com";
      const defaultAvatar = "";
      const userList = user.map((r) => ({
        ...r,
        head_img: baseUrl + r.head_img,
        avatar: baseUrl + r.head_img,
        nickname: `${r.last_name || ""} ${r.first_name || ""}`,
      }));
      commit("setGroupMember", userList);
    },
    async fetchAllContact(
      { state, commit },
      pageInfo = {
        searchName: "",
        page: 0,
        pageSize: 50,
      }
    ) {
      // first_name: "智渊"
      // head_img: "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.75281385873422751640945102.18.png"
      // id: 11
      // is_im: 1
      // last_name: "龙"
      // const res = await api.searchMeetinguser(pageInfo);
      let enterprise = await api.getUserList({ user_type: 2 });
      let employees = await api.getUserList({ user_type: 3 });

      const total = [...enterprise, ...employees].map((user) =>
        transform(user)
      );
      enterprise = enterprise.map((u) => transform(u));
      employees = employees.map((u) => transform(u));
      // console.log("getUserList", enterprise, employees);
      commit("setTotalContact", total);
      commit("setEnterprise", enterprise);
      commit("setEmployees", employees);
    },
  },
  getters: {
    // employees(state) {
    //   if (state.modalConfig.mode == "add") {
    //     return (
    //       state.totalContact.map((c) => ({
    //         ...c,
    //         disabled: c.selected,
    //       })) || []
    //     );
    //   }
    //   if (state.modalConfig.mode == "remove") {
    //     return state.groupMember || [];
    //   }
    //   if (state.modalConfig.mode == "create") {
    //     return state.totalContact || [];
    //   }
    //   return [];
    // },
    // enterprise(state) {
    //   if (state.modalConfig.mode == "add") {
    //     return (
    //       state.totalContact.map((c) => ({
    //         ...c,
    //         disabled: c.selected,
    //       })) || []
    //     );
    //   }
    //   if (state.modalConfig.mode == "remove") {
    //     return state.groupMember || [];
    //   }
    //   if (state.modalConfig.mode == "create") {
    //     return state.totalContact || [];
    //   }
    //   return [];
    // },
    // userGroup(state) {
    //   return state.totalContact.filter((c) => c.selected) || [];
    // },
  },
};

export default ({ request }) => ({
  // 商品列表
  getProductList(params) {
    return request({
      url: "/v1/product",
      method: "get",
      params,
    });
  },
  //新增商品
  addProduct(data) {
    return request({
      url: "/v1/product",
      method: "post",
      data,
    });
  },
  //    商品详情
  getProductDetail(params) {
    return request({
      url: `/v1/product/${params.id}`,
      method: "get",
      params,
    });
  },
  //    添加心愿单
  addBaseProject(data) {
    return request({
      url: "/v1/project/addBaseProject",
      method: "post",
      data,
    });
  },
  //    移除心愿单
  delBaseProject(data) {
    return request({
      url: "/v1/project/delBaseProject",
      method: "post",
      data,
    });
  },
  //    获取相关设计
  getRelatedList(params) {
    return request({
      url: `/v1/product/related/${params.id}`,
      method: "get",
      params,
    });
  },
  //    添加到项目组
  goodsJoinProject(data) {
    return request({
      url: "/v1/project/goodsJoinProject",
      method: "post",
      data,
    });
  },
  //    获取项目组列表
  getProjectList(data) {
    // console.log(data);
    return request({
      url: "/v1/project/getSelectList",
      method: "post",
      data,
    });
  },
  //修改商品
  editProduct(data) {
    return request({
      url: `/v1/product/${data.id}`,
      method: "put",
      data,
    });
  },
  //删除商品
  deleteProduct(data) {
    return request({
      url: `/v1/product/${data.id}`,
      method: "delete",
      data,
    });
  },
  // createFolder(data) {
  //     return request({
  //         url: '/v1/project/saveProject',
  //         method: 'post',
  //         data
  //     })
  // },

  // queryFolders(name) {
  //     return request({
  //         url: '/v1/project/getList',
  //         method: 'post',
  //         data: { project_name: name }
  //     })
  // },
  // queryProductList({ foldId=null, page=1, pageSize =12}={}) {
  //     return request({
  //         url: '/v1/project/getProjectGoodsList',
  //         method: 'post',
  //         data: { page, page_size: pageSize, project_id: foldId }
  //     })
  // }
});

<template>
  <div class="cbd-cost-table">
    <div class="cbd-part-title">{{ itemData.title }}</div>
    <div class="cbd-table-box">
      <div
        class="cbd-table-column"
        v-for="(val, key) in itemData.list"
        :key="key"
      >
        <div class="cbd-table-key-box">
          <el-input
            v-model="val.label"
            type="textarea"
            :autosize="{ minRows: 1 }"
            size="mini"
          ></el-input>
        </div>
        <div class="cbd-table-key-box">
          <el-input
            v-model="val.value"
            type="textarea"
            @blur="setCostAmount"
            @keyup.native="val.value = val.value.replace(/[^0-9_\.]/g, '')"
            autosize
            size="mini"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="part-bottom">
      <div class="part-add-btn" @click="addCost">
        <i class="iconfont icon-ic_add"></i>
      </div>
      <div class="part-total">
        <div class="total-text">小计：</div>
        <div class="count">￥{{ itemData.total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cbdTotalTable",
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    addCost() {
      this.$emit("addCost", { index: this.index });
    },
    setCostAmount() {
      this.$emit("setCostAmount", { index: this.index });
    },
  },
};
</script>

<style lang="scss">
$cbd-border: #ebeef5;
.cbd-cost-table {
  width: 100%;
  .cbd-part-title {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
  }
  .el-textarea__inner {
    font-size: $base-font-size;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    resize: none;
    padding: 5px 0px !important;
  }
  .cbd-table-box {
    width: 100%;
    border-left: 1px solid $cbd-border;
    border-top: 1px solid $cbd-border;
    display: flex;
    flex-wrap: wrap;
    .cbd-table-column {
      width: 20%;
      min-height: 22px;
      flex-shrink: 0;
      display: flex;
      overflow: hidden;
      position: relative;
      .cbd-table-key-box {
        display: flex;
        align-items: center;
        width: 50%;
        min-height: 22px;
        padding: 4px;
        border-right: 1px solid $cbd-border;
        border-bottom: 1px solid $cbd-border;
      }
    }
  }
  .part-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    .part-add-btn {
      font-size: 14px;
      font-weight: 500;
      color: $base-config-color;
      cursor: pointer;
    }
    .part-total {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      .count {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
}
</style>

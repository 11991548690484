var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"wrapClassName":_vm.wrapClassName,"width":_vm.width,"title":_vm.title,"visible":_vm.visible,"getContainer":".prod","mask":_vm.mask,"destroyOnClose":true,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.handleClose}},[_c('catalog',{attrs:{"material":_vm.material,"value":_vm.value,"materialData":_vm.materialData},on:{"mat-change":_vm.emit}}),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.handleClose}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('a-button',{style:({ marginRight: '8px' }),attrs:{"type":"danger"},on:{"click":_vm.handleCreate}},[_vm._v(_vm._s(_vm.$t("addMaterial")))]),(_vm.materialData.id)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v(_vm._s(_vm.$t("updateMaterial")))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="hello">
    群列表:
    <div v-for="item in meetinglist" :key="item.meeting_id">
      <div>
        <span> 群名:{{ item.meeting_name }} </span>
        <span> 公告:{{ item.meeting_notice }} </span>
        <span> 备注:{{ item.meeting_remarks }} </span>
        <span> 最后一条:{{ item.update_time }} </span>
        <span> 创建时间:{{ item.create_time }} </span>
        <button @click="seeMeeting(item)">查看</button>
      </div>
    </div>
    列表信息:
    <div v-for="item in nowmeetinglist" :key="item.index">
      <div v-if="item.meeting_chat_type == 0">
        <span> 用户id:{{ item.meeting_user_id }} </span>
        <span> 用户名:{{ item.meeting_user_name }} </span>
        <span> 头像:{{ item.meeting_user_img }} </span>
        <span> 信息:{{ item.meeting_chat_conent }} </span>
        <span v-if="item.finish == 0"> 正在发送 </span>
        <span> 创建时间:{{ item.create_time }} </span>
      </div>
      <div v-else-if="item.meeting_chat_type == 4">
        <span> 用户id:{{ item.meeting_user_id }} </span>
        <span> 用户名:{{ item.meeting_user_name }} </span>
        <span> 头像:{{ item.meeting_user_img }} </span>
        <div v-for="item2 in item.arr" :key="item2.index">
          <span> 文件名称:{{ item2.name }} </span>
          <span> 图片路径:{{ item2.url }} </span>
        </div>
        <span v-if="item.finish == 0"> 正在发送 </span>
        <span> 创建时间:{{ item.create_time }} </span>
      </div>
      <div v-else-if="item.meeting_chat_type == 5">
        <span> 用户id:{{ item.meeting_user_id }} </span>
        <span> 用户名:{{ item.meeting_user_name }} </span>
        <span> 头像:{{ item.meeting_user_img }} </span>
        <span> 内部文件 </span>
        <div v-if="item.arr.type == 0">
          <span> 商品ID{{ item.arr.id }} </span>
          <span> 商品文件{{ item.arr.name }} </span>
          <span> 商品url{{ item.arr.url }} </span>
        </div>
        <div v-if="item.arr.type == 1">
          <span> 文件夹ID{{ item.arr.id }} </span>
          <span> 文件夹文件{{ item.arr.name }} </span>
          <span> 文件夹url{{ item.arr.url }} </span>
        </div>
        <div v-if="item.arr.type == 2">
          <span> bomID{{ item.arr.id }} </span>
          <span> bom文件{{ item.arr.name }} </span>
          <span> bomurl{{ item.arr.url }} </span>
        </div>
        <div v-if="item.arr.type == 3">
          <span> 设计ID{{ item.arr.id }} </span>
          <span> 设计文件{{ item.arr.name }} </span>
          <span> 设计url{{ item.arr.url }} </span>
        </div>
        <span v-if="item.finish == 0"> 正在发送 </span>
        <span> 创建时间:{{ item.create_time }} </span>
      </div>
    </div>
    <button @click="sendmsg(0, 'test')">发送</button>
    <button @click="createGroup()">创建群</button>
    <button @click="addpeople()">添加人</button>
    <button @click="delpeople()">删除人</button>
    <el-upload
      class="upload-demo"
      action=""
      :auto-upload="false"
      accept="image/*"
      :multiple="true"
      :show-file-list="false"
      :on-change="uploadChange"
    >
      上传文件
    </el-upload>
    待发送的文件:
    <div v-for="item in uploadfilelist" :key="item.uid">
      <span> 文件名称:{{ item.name }} </span>
      <button @click="deletefile(item.uid)">删除</button>
    </div>
    <button @click="uploadImg">发送文件</button>
    <button @click="sendinside('1', 'name', 'url', 1)">发送内部文件夹</button>
    <button @click="sendinside('1', 'name', 'url', 0)">发送商品</button>
    <button @click="sendinside('1', 'name', 'url', 2)">发送bom单</button>
    <button @click="sendinside('1', 'name', 'url', 3)">发送设计</button>
    <!-- 
    <button @click="searchMeeting('test',0)">
      通过群名称或聊天记录搜索
    </button>
    <button @click="searchMeeting('test',1)">
      通过用户搜索
    </button>     
    -->
    <button @click="searchMOther('test', 0, 0)">聊天记录</button>
    <button @click="searchMOther('image', 4, 0)">文件名称</button>
    <button @click="searchMOther('1', 5, 0)">搜索商品</button>
    <button @click="searchMOther('1', 5, 1)">文件夹</button>
    <button @click="searchMOther('1', 5, 2)">bom单</button>
    <button @click="searchMOther('11', 5, 3)">设计</button>
  </div>
</template>

<script>
import im from "@/utils/im.js";
import TIM from "tim-js-sdk";
import uploadCos from "@/utils/upload_to_cos";
export default {
  mixins: [im, TIM],
  name: "IM",
  data() {
    return {
      $im: "",
      $TIM: "",
      //创建会议数据格式
      createroom: {
        name: "test",
        avatar: "",
        introduction: "test1",
        notification: "test2",
        memberList: [],
      },
      //通过用户或群名称
      searchroom: {
        name: "test", //群名称或者用户ID
        type: 0, //1
      },
      //删除用户数据格式
      delpeoplemsg: {
        meeting_id: "2",
        room: "@TGS#32HSWAQH6",
        memberList: [
          {
            id: 310,
          },
        ],
      },
      //添加用户数据格式
      addpeoplemsg: {
        meeting_id: "2",
        room: "@TGS#32HSWAQH6",
        memberList: [
          {
            id: 310,
          },
        ],
      },
      //发布内部数据
      sendinsidemsg: {
        id: 3,
        name: "2",
        url: "2",
        type: 0, //0商品 1文件夹 2bom单 3设计图
      },
      //会议列表
      meetinglist: [],
      //当前会议
      nowmeeting: [],
      //当前成员
      nowmeetingpeople: [],
      //当前聊天记录
      nowmeetinglist: [],
      //聊天页数
      chatpage: 0,
      chatpagesize: 50,
      //聊天群页数
      meetingpage: 0,
      meetingpagesize: 50,
      //搜索结果页数
      searchpage: 0,
      searchpagesize: 50,
      //用户结果页数
      username: "",
      userpage: 0,
      userpagesize: 50,
      //搜索结果列表
      searchmeetinglist: [],
      //上传文件列表
      uploadfilelist: [],
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    let options = {
      SDKAppID: 1400581099, // 这个需要替换成 新的IM的APPID
    };
    this.$TIM = TIM;
    this.$im = TIM.create(options);
    let user_info = JSON.parse(this.$cookie.get("user_info"));
    this.$api.getIMsign().then((res) => {
      this.loginIM(user_info, res);
    });
    this.nowmeeting = {
      meeting_id: 2,
      room: "@TGS#32HSWAQH6",
      meeting_name: "test",
      meeting_remarks: "test",
      meeting_notice: "test",
      meeting_img: "test",
      update_time: "test",
      create_time: "test",
    };
    this.getMeeting();
    this.searchMeetinguser();
    //this.sendmsg(0,new Date().toLocaleString().replace(/:\d{1,2}$/,' '));
  },
  methods: {
    loginIM(user_info, sig) {
      //这边需要用户的ID和数据
      this.createIm(user_info, sig);
    },
    onMsg(newVal) {
      let receivenews = [];
      let user = JSON.parse(this.$cookie.get("user_info"));
      let grouplist = this.nowmeetingpeople;
      for (var i = 0; i < newVal.length; i++) {
        if (newVal[i].to == this.nowmeeting.room) {
          newVal[i].payload.data = parseInt(newVal[i].payload.data);
          if (user.id + "uid" == newVal[i].from) continue;
          if (newVal[i].payload.data == 2) {
            receivenews.push({
              id: newVal[i].from.split("uid")[0],
              text: newVal[i].payload.description,
              time: newVal[i].time,
              type: 2,
            });
          } else if (newVal[i].payload.data >= 3) {
            receivenews.push({
              id: newVal[i].from.split("uid")[0],
              text: newVal[i].payload.description,
              type: newVal[i].payload.data,
            });
          } else if (newVal[i].payload.data == 1) {
            receivenews.push({
              id: newVal[i].from.split("uid")[0],
              text: newVal[i].payload.description,
              type: 1,
            });
          }
          //有新的用户加入
          else if (newVal[i].payload.data == 127) {
            //this.getservice2();
          } else {
            receivenews.push({
              id: newVal[i].from.split("uid")[0],
              text: newVal[i].payload.description,
              time: newVal[i].time,
              type: 0,
            });
          }
        }
      }

      if (receivenews.length > 0) {
        let _this = this;
        this.updateMeetingUMlast();
        receivenews.forEach(function (val) {
          grouplist.forEach(function (val2) {
            if ((val.id == val2.id) == true) {
              _this.pushmsg({
                meeting_user_id: val.id,
                meeting_user_name: val2.enterprise,
                meeting_user_img: val2.head_img,
                meeting_chat_conent: val.text,
                meeting_chat_type: val.type,
              });
            }
          });
        });
      }
    },
    uploadChange(file, fileList) {
      this.uploadfilelist.push(file.raw);
    },
    deletefile(index) {
      for (let i = 0; i < this.uploadfilelist.length; i++) {
        if (this.uploadfilelist[i].uid == index) {
          this.uploadfilelist.splice(i, 1);
          break;
        }
      }
    },
    //上传图片
    uploadImg() {
      let pictureList = this.uploadfilelist;
      let filemsg = [];
      // console.log(pictureList);
      pictureList.forEach((file) => {
        filemsg.push({
          name: file.name,
          type: file.type,
        });
      });
      this.uploadfilelist = [];
      let _this = this;
      let listmsg = this.sendmsg(4, "", JSON.stringify(filemsg), 0);
      return new Promise((resolve) => {
        uploadCos(
          pictureList,
          "meeting/" + this.nowmeeting.meeting_id,
          (picture) => {
            for (let i = 0; i < filemsg.length; i++) {
              filemsg[i].url = picture[i];
            }
            listmsg.meeting_chat_sub = JSON.stringify(filemsg);
            listmsg.arr = filemsg;
            _this.sendmsgToIM(listmsg);
            resolve(picture);
          }
        );
      });
    },
    sendinside(id, name, url, type) {
      let data = { id: id, name: name, url: url, type: type };
      this.sendmsg(5, "", JSON.stringify(data));
    },
    //发送信息
    sendmsg(type, msg, sub = "", now = 1) {
      let user = JSON.parse(this.$cookie.get("user_info"));
      let user_id = user.id;

      let user_nickname = user.enterprise;
      let head_img = user.head_img;
      var c = [];
      c.push({
        meeting_user_id: user_id,
        meeting_user_name: user_nickname,
        meeting_user_img: head_img,
        meeting_chat_conent: msg,
        meeting_chat_sub: sub,
        meeting_chat_type: type,
        finish: 0,
      });
      this.pushmsg(c[0]);
      if (now == 1) this.sendmsgToIM(c[0]);
      return c[0];
    },
    //发送信息
    sendmsgToIM(msg) {
      let Txroom = this.nowmeeting.room;
      ///需要等待后台分配客服才发送
      let message = this.$im.createCustomMessage({
        to: Txroom,
        conversationType: this.$TIM.TYPES.CONV_GROUP,
        payload: {
          data: msg.meeting_chat_type + "", // 消息类型
          description: msg.meeting_chat_conent, // 信息主体
          extension: "",
        },
      });
      // 2. 发送消息
      let promise = this.$im.sendMessage(message);
      var _this = this;
      promise
        .then(function () {
          _this.sendnews(msg);
        })
        .catch(function (imError) {
          console.warn("sendMessage error:", imError);
        });
    },
    sendnews(msg) {
      if (msg.meeting_chat_type == 127) return;
      let data = {
        meeting_id: this.nowmeeting.meeting_id,
        msg_type: msg.meeting_chat_type,
        msg: msg.meeting_chat_conent,
        sub: msg.meeting_chat_sub,
      };
	 
      this.$api.sendMeetingmsg(data).then((res) => {
        msg.finish = 1;
      });
    },
    pushmsg(msg) {
      if (msg.meeting_chat_type == 127) return;
      else if (msg.meeting_chat_type == 2) {
        msg.arr = JSON.parse(msg.meeting_chat_sub);
        msg.gid = msg.arr.id;
        msg.img = msg.arr.img;
        msg.tid = msg.arr.tid;
        msg.text = msg.arr.text;
      } else if (msg.meeting_chat_type >= 3) {
        msg.arr = JSON.parse(msg.meeting_chat_sub);
      }
      if (msg.create_time == undefined) {
        msg.create_time = new Date().toLocaleString().replace(/:\d{1,2}$/, " ");
      } else {
        msg.create_time = new Date(msg.create_time * 1000)
          .toLocaleString()
          .replace(/:\d{1,2}$/, " ");
      }
      this.nowmeetinglist.push(msg);
    },
    searchMOther(name, type, type2) {
      let data = {
        name: name,
        type: type,
        type2: type2,
        page: this.searchpage,
        pagesize: this.searchpagesize,
      };
      this.$api.searchMeetingmsg(data).then((res) => {
        this.searchmeetinglist = res;
      });
    },
    getMeeting() {
      let data = { page: this.meetingpage, pagesize: this.meetingpagesize };
      this.$api.getMeeting(data).then((res) => {
        this.meetinglist = res;
      });
    },
    //更新用户最后一个信息id
    updateMeetingUMlast() {
      let data = { meeting_id: this.nowmeeting.meeting_id };
      this.$api.updateMeetingUMlast(data).then((res) => {});
    },
    searchMeetinguser() {
      let data = {
        name: this.username,
        page: this.userpage,
        pagesize: this.userpagesize,
      };
      this.$api.searchMeetinguser(data).then((res) => {
        //this.meetinglist = res;
      });
    },
    // searchMeeting(){
    //   let data = {page:this.meetingpage,pagesize:this.meetingpagesize}
    //   this.$api.getMeeting(data).then(res=>{
    //       this.meetinglist = res.msg;
    //   })
    // } ,

    createGroup() {
      this.$api.creatMeeting(this.createroom).then((res) => {
        this.meetinglist.push(res);
      });
    },
    addpeople() {
      this.$api.addmpeople(this.addpeoplemsg).then((res) => {});
    },
    delpeople() {
      this.$api.delmpeople(this.delpeoplemsg).then((res) => {});
    },
    seeMeeting(msg) {
      this.nowmeeting = msg;
      let data = {
        meeting_id: this.nowmeeting.meeting_id,
        page: this.chatpage,
        pagesize: this.chatpagesize,
      };
      let _this = this;
      this.$api.seeMeeting(data).then((res) => {
        if (this.nowmeeting.meeting_id == res.meeting_id) {
          if (res.page == 0) {
            this.nowmeetingpeople = res.user;
            this.nowmeetingpeople.forEach((user) => {
              user.head_img =
                "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com" +
                user.head_img;
            });
            this.nowmeetinglist = [];
          }
          res.msg.forEach((msg) => {
            this.nowmeetingpeople.forEach((user) => {
              if (user.id == msg.meeting_user_id) {
                msg.meeting_user_name = user.enterprise;
                msg.meeting_user_img = user.head_img;
                msg.finish = 1;
                _this.pushmsg(msg);
                return;
              }
            });
          });
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

export default ({ service, request }) => ({
  textToSpeech({ text }) {
    return request({
      url: "/v1/ns/api/SynthesizeSpeech",
      method: "post",
      data: { text },
    });
  },

  health(params) {
    return request({
      url: "/v1/ns/api/health",
      method: "get",
      params,
    });
  },
});

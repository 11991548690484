export default {
  data() {
    return {
      inqTemplate: {
        formHeader: {
          name: "formHeader",
          type: "header",
          data: { logo: "", desc: "", title: "Inquiry" },
        },
        formFooter: {
          name: "formFooter",
          type: "footer",
          data: { desc: "Thank you for business with us!" },
        },
        formPage: {
          userMsg: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [
                { prop: "title", type: "title", width: 90 },
                { prop: "detail", type: "input", width: 310 },
              ],
              data: [
                {
                  title: { value: "To", type: "normal" },
                  detail: { value: "", type: "unedit" },
                },
                {
                  title: { value: "Attention", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "Address", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "Phone", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "Email", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
              ],
            },
          },
          formSn: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [
                { prop: "title", type: "title", width: 90 },
                { prop: "detail", type: "input", width: 200 },
              ],
              data: [
                {
                  title: { value: "Maker", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "INQ No", type: "normal" },
                  detail: { value: "", type: "unedit" },
                },
                {
                  title: { value: "Date", type: "normal" },
                  detail: { value: "", type: "date" },
                },
              ],
            },
          },
          payment: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [{ prop: "title", type: "input", width: 530 }],
              data: [
                { title: { value: "Payment Method", type: "header" } },
                {
                  title: {
                    value: "Card payment: Visa, Master Card",
                    type: "input",
                  },
                },
              ],
            },
          },
          terms: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [{ prop: "title", type: "input", width: 530 }],
              data: [
                { title: { value: "Terms & Conditions", type: "header" } },
                {
                  title: {
                    value:
                      "This quote is valid for thirty(30) days. Partial payment required before rending services.",
                    type: "input",
                  },
                },
              ],
            },
          },
          product: {
            name: "formTable",
            type: "table",
            totalData: { discount: 0, subTotal: 0, grandTotal: 0 },
            tableData: [],
          },
        },
      },
      quoTemplateData: {
        formHeader: {
          name: "formHeader",
          type: "header",
          data: { logo: "", desc: "", title: "Quotation" },
        },
        formFooter: {
          name: "formFooter",
          type: "footer",
          data: { desc: "Thank you for business with us!" },
        },
        formPage: {
          userMsg: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [
                { prop: "title", type: "title", width: 90 },
                { prop: "detail", type: "input", width: 310 },
              ],
              data: [
                {
                  title: { value: "To", type: "normal" },
                  detail: { value: "", type: "unedit" },
                },
                {
                  title: { value: "Attention", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "Address", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "Phone", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "Email", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
              ],
            },
          },
          formSn: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [
                { prop: "title", type: "title", width: 90 },
                { prop: "detail", type: "input", width: 200 },
              ],
              data: [
                {
                  title: { value: "Maker", type: "normal" },
                  detail: { value: "", type: "normal" },
                },
                {
                  title: { value: "INQ No", type: "normal" },
                  detail: { value: "", type: "unedit" },
                },
                {
                  title: { value: "Date", type: "normal" },
                  detail: { value: "", type: "date" },
                },
              ],
            },
          },
          payment: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [{ prop: "title", type: "input", width: 530 }],
              data: [
                { title: { value: "Payment Method", type: "header" } },
                {
                  title: {
                    value: "Card payment: Visa, Master Card",
                    type: "input",
                  },
                },
              ],
            },
          },
          terms: {
            name: "normalTable",
            type: "table",
            tableData: {
              props: [{ prop: "title", type: "input", width: 530 }],
              data: [
                { title: { value: "Terms & Conditions", type: "header" } },
                {
                  title: {
                    value:
                      "This quote is valid for thirty(30) days. Partial payment required before rending services.",
                    type: "input",
                  },
                },
              ],
            },
          },
          product: {
            name: "formTable",
            type: "table",
            totalData: { discount: 0, subTotal: 0, grandTotal: 0 },
            tableData: [],
          },
        },
      },
    };
  },
};

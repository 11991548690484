export default ({ request }) => ({
  // 获取文件夹
  getMaterialFolderList() {
    return request({
      url: "/v1/texturecategory/getList",
      method: "post",
      data: {
        type: 1,
      },
    });
  },
  //获取材质列表
  getMaterialList({
    page = 1,
    page_size = 12,
    cat_id,
    sn,
    name,
    company_name,
    part_type = 0,
  } = {}) {
    cat_id = cat_id || undefined;
    return request({
      url: "/v1/texture/getList",
      method: "post",
      data: {
        page,
        page_size,
        cat_id,
        sn,
        name,
        company_name,
        part_type,
      },
    });
  },
  getTextureFolderList() {
    return request({
      url: "/v1/texturecategory/getList",
      method: "post",
      data: {
        type: 2,
      },
    });
  },
  getTextureList({ page = 1, page_size = 12, cat_id, name } = {}) {
    cat_id = cat_id || undefined;
    return request({
      url: "/v1/texturemap/getList",
      method: "post",
      data: {
        page,
        page_size,
        cat_id,
        name,
      },
    });
  },
  getLogoList({ page = 1, page_size = 12, cat_id } = {}) {
    cat_id = cat_id || undefined;
    return request({
      url: "/v1/texturelogo/getList",
      method: "post",
      data: {
        page,
        page_size,
        cat_id,
      },
    });
  },
  getLogoFolderList() {
    return request({
      url: "/v1/texturecategory/getList",
      method: "post",
      data: {
        type: 3,
      },
    });
  },
  getById(id) {
    return request({
      url: "/v1/texture/getTextureInfo",
      method: "post",
      data: {
        id,
      },
    });
  },
  updateMaterial(data) {
    return request({
      url: "/v1/texture/updateTexture",
      method: "post",
      data,
    });
  },
  saveMaterial(data) {
    return request({
      url: "/v1/texture/saveTexture",
      method: "post",
      data,
    });
  },
  saveTexture(data) {
    return request({
      url: "/v1/texturemap/saveMap",
      method: "post",
      data,
    });
  },
  updateTexture(data) {
    return request({
      url: "/v1/texturemap/updateMap",
      method: "post",
      data,
    });
  },
  saveLogo(data) {
    return request({
      url: "/v1/texturelogo/saveLogo",
      method: "post",
      data,
    });
  },
  updateLogo(data) {
    return request({
      url: "/v1/texturelogo/updateLogo",
      method: "post",
      data,
    });
  },
  delMaterial(id) {
    return request({
      url: "/v1/texture/deleteTexture",
      method: "post",
      data: {
        id,
      },
    });
  },
  delTexture(id) {
    return request({
      url: "/v1/texturemap/deleteMap",
      method: "post",
      data: {
        id,
      },
    });
  },
  delLogo(id) {
    return request({
      url: "/v1/texturelogo/deleteLogo",
      method: "post",
      data: {
        id,
      },
    });
  },
  filter(id) {
    return request({
      url: "/v1/texturecategory/getSelectList",
      method: "post",
      data: {
        id,
      },
    });
  },
  getTextureSelectOption(id) {
    return request({
      url: "/v1/texturecategory/getSelectList",
      method: "post",
      data: {
        id,
      },
    });
  },
  getLogoSelectOption(id) {
    return request({
      url: "/v1/texturecategory/getSelectList",
      method: "post",
      data: {
        id,
      },
    });
  },
  //AI搜贴图
  textureSearch(data) {
    return request({
      url: `/v1/texturemap/mapSearch`,
      method: "post",
      data,
    });
  },
  //AI搜素材
  materialSearch(data) {
    return request({
      url: `/v1/texture/textureSearch`,
      method: "post",
      data,
    });
  },
  //获取语音
  getAudio(data){
	  return request({
		  url: `/v1/ns/api/SynthesizeSpeech`,
		  method: 'post',
		  data
	  })
  },
});

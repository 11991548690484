import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapActions } = createNamespacedHelpers("jiyang/im");
export default {
  methods: {
    ...mapMutations(["setSdkState"]),
    ...mapActions(["fetchGroupList"]),
    updateGroupProfile(profile) {
      this.$im.updateGroupProfile(profile);
    },
    async createIm(imdata, sig, type = 0) {
      if (type == 0) this.createon();
      try {
        await this.$im.login({
          userID: imdata.id + "uid",
          userSig: sig,
        });
        // this.$im.setSelfStatus({
        //   statusType: this.$TIM.TYPES.USER_STATUS_ONLINE,
        // });
      } catch (e) {
        console.log(e);
      }
      this.$im.on(this.$TIM.EVENT.SDK_READY, () => {
        this.setSdkState(true);
      });
    },
    getUserStatus(userIDList) {
      return this.$im.getUserStatus({ userIDList: [userIDList] });
    },
    onMessage(event) {
      // console.log('onMessage=>>>', event.data[0]);
    },
    //创建监听
    createon() {
      const im = this.$im.on;
      const EVENT = this.$TIM.EVENT;
      let _this = this;
      this.$im.on(this.$TIM.EVENT.SDK_NOT_READY, (event) => {
        // this.onMessage(event)
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        // event.name - TIM.EVENT.SDK_READY
        // console.log(this.$TIM.EVENT.SDK_NOT_READY, "====>");
        _this.onIMReadyChange(true);
      });

      // 监听事件，例如：
      this.$im.on(this.$TIM.EVENT.SDK_READY, (event) => {
        // console.log("====>", this.$TIM.EVENT.SDK_READY, "====>");
        this.onMessage(event);
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        // event.name - TIM.EVENT.SDK_READY
        _this.onIMReadyChange(true);
      });

      this.$im.on(this.$TIM.EVENT.GROUP_ATTRIBUTES_UPDATED, (event) => {
        // console.log("=>>> TIM.EVENT.GROUP_ATTRIBUTES_UPDATED =>>>", event);
      });

      this.$im.on(this.$TIM.EVENT.MESSAGE_RECEIVED, (event) => {
        // console.log("this.$TIM.EVENT.MESSAGE_RECEIVED", event);
        this.onMessage(event);
        _this.onIMMsg(event.data);
        // console.log(event.data);
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        // event.name - TIM.EVENT.MESSAGE_RECEIVED
        // event.data - 存储 Message 对象的数组 - [Message]
      });
      this.$im.on(this.$TIM.EVENT.SDK_RELOAD, function (event) {
        //重新加载触发
      });
      this.$im.on(this.$TIM.EVENT.MESSAGE_REVOKED, (event) => {
        // console.log('=>>>>消息撤回=>>>>>', event);
        // console.log(event.data[0])
        // 收到消息被撤回的通知
        // event.name - TIM.EVENT.MESSAGE_REVOKED
        // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
      });
      this.$im.on(this.$TIM.EVENT.MESSAGE_READ_BY_PEER, function (event) {
        // SDK 收到对端已读消息的通知，即已读回执。使用前需要将 SDK 版本升级至 v2.7.0 或以上。仅支持单聊会话。
        // event.name - TIM.EVENT.MESSAGE_READ_BY_PEER
        // event.data - event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isPeerRead 属性值为 true
      });

      this.$im.on(this.$TIM.EVENT.CONVERSATION_LIST_UPDATED, (event) => {
        // console.log("TIM.EVENT.CONVERSATION_LIST_UPDATED", event);
        this.fetchGroupList();
        // this.
        // this.setConversationList(data)
        // 收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面
        // event.name - TIM.EVENT.CONVERSATION_LIST_UPDATED
        // event.data - 存储 Conversation 对象的数组 - [Conversation]
      });

      this.$im.on(this.$TIM.EVENT.GROUP_LIST_UPDATED, function (event) {
        // 收到群组列表更新通知，可通过遍历 event.data 获取群组列表数据并渲染到页面
        // event.name - TIM.EVENT.GROUP_LIST_UPDATED
        // event.data - 存储 Group 对象的数组 - [Group]
      });

      this.$im.on(this.$TIM.EVENT.PROFILE_UPDATED, function (event) {
        // 收到自己或好友的资料变更通知
        // event.name - TIM.EVENT.PROFILE_UPDATED
        // event.data - 存储 Profile 对象的数组 - [Profile]
      });

      this.$im.on(this.$TIM.EVENT.BLACKLIST_UPDATED, function (event) {
        // 收到黑名单列表更新通知
        // event.name - TIM.EVENT.BLACKLIST_UPDATED
        // event.data - 存储 userID 的数组 - [userID]
      });

      this.$im.on(this.$TIM.EVENT.ERROR, function (event) {
        // 收到 SDK 发生错误通知，可以获取错误码和错误信息
        // event.name - TIM.EVENT.ERROR
        // event.data.code - 错误码
        // event.data.message - 错误信息

        console.log(event);
      });

      this.$im.on(this.$TIM.EVENT.SDK_NOT_READY, function (event) {
        // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
        // event.name - TIM.EVENT.SDK_NOT_READY
        _this.onIMReadyChange(false);
      });

      this.$im.on(this.$TIM.EVENT.KICKED_OUT, function (event) {
        // 收到被踢下线通知
        // event.name - TIM.EVENT.KICKED_OUT
        // event.data.type - 被踢下线的原因，例如:
        //    - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
        //    - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
        //    - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢 （v2.4.0起支持）。
      });

      this.$im.on(this.$TIM.EVENT.NET_STATE_CHANGE, function (event) {
        //  网络状态发生改变（v2.5.0 起支持）。
        // event.name - TIM.EVENT.NET_STATE_CHANGE
        // event.data.state 当前网络状态，枚举值及说明如下：
        //     \- TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
        //     \- TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
        //    \- TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
      });
      this.$im.on(this.$TIM.EVENT.MESSAGE_REVOKED, function (event) {
        // event.name - TIM.EVENT.MESSAGE_REVOKED
        // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
      });
    },
  },
};

<template>
  <i class="iconfont icon-order order" @click="toListDetail">
    <!--    <span v-if="cartTotal > 0"-->
    <!--      ><i>{{ cartTotal }}</i></span-->
    <!--    >-->
  </i>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapState } = createNamespacedHelpers("jiyang/cart");

export default {
  data() {
    return { tweenedNumber: 0 };
  },
  computed: {
    ...mapGetters(["cartTotal"]),
    ...mapState(["nowFormId"]),
  },
  methods: {
    toListDetail() {
      this.$router.push({
        path: "/lists",
        query: {
          type: 1,
          isDetail: true,
          t: Date.now(),
        },
      });
    },
  },
};
</script>

<style lang="less">
.iconfont.order {
  margin-right: 18px;
  font-size: 30px;
  color: #626262;
  position: relative;
  cursor: pointer;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 2px;
    min-width: 18px;
    height: 18px;
    text-align: center;
    font-size: 10px;
    border-radius: 50%;
    background-color: var(--primaryColor);
    color: #fff;
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 2;
    i {
      font-style: normal;
    }
  }
}
</style>

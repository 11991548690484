let _self = this;
import VueCookies from "vue-cookie";
import axios from "axios";
import COS from 'cos-js-sdk-v5'
// 返回数组
let upload_cache_list = []
// 上传前数组
let upload_before_list=[]
//上传path
let upload_paths='model';
//新文件名数据
let new_files_name = [];
export default function upLoadFile(paths,upload_path,callback) {
	upload_cache_list=[]
	upload_before_list=[]
	upload_before_list=paths
	upload_paths=upload_path
	new_files_name = [];
	const promises = paths.map((path,key) => {
		return promisify(upload)({
			path: path,
			name: 'file',
			extra: {},
      		key:key
		})
	})
	Promise.all(promises).then(async (data) => {
		upload_cache_list.push(...data);
		callback(upload_cache_list,new_files_name)
	}).catch((res) => {
		console.log(res);
	});
}
	const promisify = (api) => {
		return (options, ...params) => {
			return new Promise((resolve, reject) => {
				api(Object.assign({}, options, {
					success: resolve,
					fail: reject
				}), ...params);
			});
		}
	}
	const upload = async (options) => {
    await upload_file_server(_self,  upload_before_list[options.key], options.success)
	}
	function getConfig(options){
		return new Promise(resolve => {
			axios.get(`${process.env.VUE_APP_API}/v1/user/getCosSignSts`).then(res=>{
				VueCookies.set('cosConfig',JSON.stringify(res.data.result))
				resolve(res.data.result)
			})
		})
	}
// 文件上传
async function upload_file_server(_self,file,success) {
	let config=VueCookies.get('cosConfig')
	let data={}
	let date=new Date().getTime()/1000
	if(config){
		let item=JSON.parse(config)
		let now_time=date+5*60
		if(now_time>item.expiredTime){
			data=await getConfig()
		}else{
			data=item
		}
	}else{
		data=await getConfig()
	}
	var credentials = data.credentials;
	let configs={
		TmpSecretId: credentials.tmpSecretId,
		TmpSecretKey: credentials.tmpSecretKey,
		SecurityToken: credentials.sessionToken,
		// 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
		StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
		ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
		ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
	}
	let cosFile = new COS({
		getAuthorization: (options, callback) => {
			callback(configs)
		}
	});
	// 腾讯云配置
	let cosConfig = {
		Bucket: 'shoe-1303249748',
		Region: 'ap-shanghai',
	}
	// let cosFile = new COS({
	// 	getAuthorization: (params, callback) => {
	// 		let authorization = COS.getAuthorization({
	// 			SecretId: cosConfig.SecretId,
	// 			SecretKey: cosConfig.SecretKey,
	// 			Method: params.Method,
	// 			Key: params.Key
	// 		});
	// 		callback(authorization);
	// 	}
	// });
	// 获取后缀
  let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
    let file_name = `${Math.random()}${date}`;
	let Key = file_name+`.${fileExtension}` // 这里指定上传的文件名
	new_files_name.push(file_name);
  let files= Key.replace(/.zip/,'')
  let files_name= file.name.replace(/.zip/,'')
  let params={
    Bucket: cosConfig.Bucket,
    Region: cosConfig.Region,
    Key: `/shoe/${upload_paths}/` + Key , //上传路径&文件名
    Body: file,
  }
  if(upload_paths=='material'){
    params={...params,...{Headers:{'Cache-Control':'no-cache'}}}
  }
  cosFile.putObject(params, (err, data) => {
		if (err == null) {
			let path = `https://${data.Location}`
			success(path)
		} else {
			console.log(`%c 腾讯云上传失败:${JSON.stringify(err)}`, 'color:#f00');
			return;
		}
	});
}

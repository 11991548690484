<template>
  <div class="design-same-card">
    <div class="card-left">
      <designView
        :itemData="itemData"
        @toShowGoodsTree="toShowGoodsTree"
      ></designView>
    </div>

    <div class="card-right">
      <div
        class="card-img-box"
        v-for="(val, key) in sameList"
        :key="key"
        @click="toDetail(val)"
      >
        <el-image
          class="design-image"
          :src="
            val.img_url +
            `${
              val.type === 3
                ? '?imageMogr2/thumbnail/!10p'
                : '?imageMogr2/thumbnail/!20p'
            }`
          "
          fit="contain"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import designView from "@/components/designView/designView";
export default {
  name: "designSameCard",
  components: {
    designView,
  },
  props: {
    current: {
      type: Number,
    },
    itemData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    sameList() {
      if (this.current === 2) {
        return this.itemData.relatedModel;
      }
      return this.itemData.relatedStyle;
    },
  },
  methods: {
    //显示树状弹窗
    toShowGoodsTree() {
      this.$emit("toShowGoodsTree", { data: this.itemData });
    },
    toDetail(val) {
      this.$router.push({
        name: "productDetail",
        query: {
          id: val.id,
          type: val.type,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.design-same-card {
  width: 640px;
  height: 310px;
  padding: 10px 10px 0 10px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 12px;
  .card-left {
    //border: 1px solid #e2dede;
    overflow: hidden;
  }
  .card-right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: auto;
    .card-img-box {
      width: 178px;
      height: 140px;
      margin: 0 0 10px 10px;
      border-radius: 2px;
      border: 1px solid #e2dede;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .design-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-icon-picture-outline {
          font-size: 32px;
          color: #999;
        }
      }
    }
  }
}
</style>

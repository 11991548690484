<template>
  <div
    class="relation-table__wrap"
    v-if="visible"
    v-click-outside="onClickOutside"
  >
    <a-input-search
      class="search"
      placeholder="Search Data/Number/Customer"
      @search="onSearch"
    />
    <a-table
      :columns="columns"
      :data-source="data"
      size="small"
      :pagination="pagination"
    ></a-table>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data() {
    return {
      pagination: {
        size: "small",
        defaultPageSize: 3,
      },
    };
  },
  methods: {
    onSearch() {},
    onClickOutside() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="scss">
.relation-table__wrap {
  width: 560px;
  padding: 18px 18px 18px 18px;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.09);
  .search {
    margin-bottom: 20px;
  }
  .ant-table-column-title {
    color: var(--primaryColor);
  }
}
</style>

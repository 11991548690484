export default ({ request }) => ({
	/* 首页提交信息 */
    postContact(data) {
        return request({
            url: '/v1/clue/contactUs',
            method: 'post',
            data
        })
    },
})

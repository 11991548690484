export default ({ service, request }) => ({
// 用户搜索素材
    searchTexture (params) {
        return request({
            url: '/v1/materials/search',
            method: 'get',
            params
        })
    },

})


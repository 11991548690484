<template>
  <a-form class="material-form">
    <a-form-item>
      <span slot="label">
        {{ $t("materialName") }}
        <!-- <a-tooltip placement="bottom" title="name">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <a-input
        v-model="material.name"
        :placeholder="$t('placeHolderMaterialName')"
        @change="handleNameChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("materialNumber") }}
        <!--  <a-tooltip placement="bottom" title="name">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-input
        v-model="material.sn"
        :placeholder="$t('placeHolderMaterialNumber')"
        @change="handleNumberChange"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("placement") }}
        <!-- <a-tooltip placement="bottom" title="材质编号">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-cascader
        :options="placementOptions"
        :placeholder="$t('pleaseSelect')"
        @change="onChangePlacement"
      />
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("hairLength") }}
        <!-- <a-tooltip placement="bottom" title="albedoTexture vScale">
	      <a-icon type="question-circle-o" />
	    </a-tooltip> -->
      </span>
      <a-slider
        :min="1"
        :max="10"
        :step="1"
        v-model="material.param.furLength"
        @change="handleInputChange"
      ></a-slider>
    </a-form-item>
    <!-- <a-row>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 15 }" :labelCol="{ span: 9 }">
          <span slot="label">
            quality
            <a-tooltip placement="bottom" title="quality">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider
            :min="0.1"
            :max="100"
            :step="0.1"
            v-model="material.param.quality"
            placeholder="type the quality"
            @change="handleInputChange"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 15 }" :labelCol="{ span: 9 }">
          <span slot="label">
            length
            <a-tooltip placement="bottom" title="fur length">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider
            :min="0.1"
            :max="100"
            :step="0.1"
            v-model="material.param.furLength"
            placeholder="type the quality"
            @change="handleInputChange"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 15 }" :labelCol="{ span: 9 }">
          <span slot="label">
            spacing
            <a-tooltip placement="bottom" title="fur spacing">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider
            :min="0.1"
            :max="100"
            :step="0.1"
            v-model="material.param.furSpacing"
            placeholder="type the quality"
            @change="handleInputChange"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 15 }" :labelCol="{ span: 9 }">
          <span slot="label">
            angle
            <a-tooltip placement="bottom" title="furAngle">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider
            :min="0.1"
            :max="100"
            :step="0.1"
            v-model="material.param.furAngle"
            placeholder="type the quality"
            @change="handleInputChange"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 15 }" :labelCol="{ span: 9 }">
          <span slot="label">
            density
            <a-tooltip placement="bottom" title="fur density">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider
            :min="0.1"
            :max="100"
            :step="0.1"
            v-model="material.param.furDensity"
            placeholder="type the quality"
            @change="handleInputChange"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :wrapperCol="{ span: 15 }" :labelCol="{ span: 9 }">
          <span slot="label">
            speed
            <a-tooltip placement="bottom" title="fur speed">
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-slider
            :min="0.1"
            :max="100"
            :step="0.1"
            v-model="material.param.furSpeed"
            placeholder="type the quality"
            @change="handleInputChange"
          />
        </a-form-item>
      </a-col>
    </a-row> -->
    <a-form-item>
      <span slot="label">
        {{ $t("textureColor") }}
        <!-- <a-tooltip placement="bottom" title="fur color">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <color-picker
        class="color-picker"
        v-model="material.param.furColor"
        @input="handleColorPicker"
      ></color-picker>
    </a-form-item>
    <a-form-item>
      <span slot="label">
        {{ $t("colorTexture") }}
        <!-- <a-tooltip placement="bottom" title="A texture map with more than two colors can make the material show rich color effects.">
          <a-icon type="question-circle-o" />
        </a-tooltip> -->
      </span>
      <picker @ok="handleImageChange"></picker>
    </a-form-item>
  </a-form>
</template>

<script>
import Picker from "@/components/ImagePicker";
import ColorPicker from "@/components/v2/color-picker/ColorPicker.vue";
import mixin from "@/components/material/mixin";
export default {
  name: "fur",
  components: {
    Picker,
    ColorPicker,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    option: {
      type: Object,
      default: () => ({
        name: "",
        sn: "",
        cat_id: 4,
        shape: "ball",
        type: "fur",
        material: "fur",
        param: {
          diffuseTexture: {
            url: "",
            type: "fur",
            material: null,
          },
          quality: 20,
          furLength: 4,
          furAngle: 0,
          furColor: { r: 1, g: 1, b: 1 },
          furSpacing: 6,
          furDensity: 10,
          furSpeed: 2000,
        },
      }),
    },
  },

  data() {
    return {
      material: Object.assign(this.option, this.value),
    };
  },
  methods: {
    handleNameChange(e) {
      this.material.name = e.target.value;
      this.emit(this.material);
    },
    handleNumberChange() {
      this.material.number = e.target.value;
      this.emit(this.material);
    },
    handleInputChange() {
      this.emit(this.material);
    },
    handleImageChange(file) {
      if (file) {
        this.material.param.diffuseTexture = {
          ...this.material.param.diffuseTexture,
          ...file,
        };
      } else {
        this.material.param.diffuseTexture = {};
      }
      this.emit(this.material);
    },
    handleColorPicker() {
      this.emit(this.material);
    },
    beforeUpload() {},
  },
  mixins: [mixin],
};
</script>
<style lang="scss">
.ant-drawer-body {
  padding: 0 16px;
}
</style>
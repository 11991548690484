<template>
  <div class="review-page" :class="[isShowSlider ? 'mt-20' : 'mt-2']">
    <el-slider
      v-model="size"
      class="svg-size-box"
      @input="changeImgSize"
      v-if="isShowSlider"
    ></el-slider>
    <img
      class="svg-png"
      :src="svgUrl"
      :style="{
        transform: `translate(-50%,-50%) rotate(90deg) scale(${scale},${scale})`,
      }"
      crossorigin="*"
    />
  </div>
</template>

<script>
export default {
  name: "reviewRemark",
  props: {
    svgUrl: {
      type: String,
    },
    isShowSlider: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scale: 1,
      size: 50,
    };
  },
  methods: {
    changeImgSize(e) {
      this.scale = 1 + (e - 50) / 100;
    },
  },
};
</script>

<style lang="scss">
.review-page {
  width: 794px;
  height: 1123px;
  background-color: #fff;

  padding: 22px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .svg-png {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1120px;
    height: auto;
  }
  .svg-size-box {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 200px;
    z-index: 101;
  }
}
.mt-20 {
  margin-top: 20px;
}
.mt-2 {
  margin-top: 1px;
}
</style>

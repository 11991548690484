// import Vue from "vue";
// import Vuex from "vuex";
// import {
//   getTextureFolderList,
//   getTextureList,
//   delMaterial,
//   delTexture,
// } from "@/api/api";

import api from "@/api";
import uploadFile from "@/utils/upload_to_cos.js";
import { materialsData } from "@/utils/babylonjs/meshData";
// Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    textureSelectOption: [],
    currentMaterialFolder: { id: 0, title: "all" },
    currentTextureFolder: { id: 0, title: "all" },
    currentLogoFolder: { id: 0, title: "all" },
    curSelectedMat: {},
    curSelectedTexture: {},
    materialFolders: [],
    textureFolders: [],
    materials: [],
    textures: [],
    logos: [],
    logoFolders: [],
    search: "",
    loading: false,
    folders: [],
    holderVisible: true,
    texturePage: {
      total: 0,
      per_page: 12,
      current_page: 1,
    },
    materialPage: {
      total: 0,
      per_page: 12,
      current_page: 1,
    },
    logoPage: {
      total: 0,
      per_page: 12,
      current_page: 1,
    },
    saveModelData: {},
    materialSeleted: null,
    pickedMaterial: null,
    textureSetType: "texture",
    searchType: "1",
    setPart: 1,
    partList: [],
    partType: 0,
  },
  mutations: {
    SET_HOLDER_VISIBLE(state, bool) {
      state.holderVisible = bool;
    },
    SET_TEXTURE_SELECTT_OPTION(state, opts) {
      state.textureSelectOption = opts;
    },
    SET_CURRENT_MATFOLDER(state, idx) {
      state.currentMaterialFolder = state.materialFolders[idx];
      console.log(state.currentMaterialFolder);
    },
    SET_CURRENT_LOGOFOLDER(state, idx) {
      state.currentLogoFolder = state.logoFolders[idx];
      console.log(state.currentLogoFolder);
    },
    SET_CURRENT_TEXTUREFOLDER(state, idx) {
      state.currentTextureFolder = state.textureFolders[idx];
    },
    SET_MATERIAL_FOLDERS(state, payload) {
      let num = payload.reduce((total, t) => total + t.num, 0) || 0;
      let all = { name: "All Material", num };
      state.materialFolders = [all, ...payload];
    },
    SET_TEXTURE_FOLDERS(state, payload) {
      let num = payload.reduce((total, t) => total + t.num, 0);
      let all = { name: "All Mapping", num };
      state.textureFolders = [all, ...payload];
    },

    SET_SEARCH(state, payload) {
      state.search = payload;
    },
    SET_FOLDERS(state, payload) {
      state.folders = payload;
    },
    SET_MATERIALS(state, payload) {
      state.materials = [...payload];
    },
    SET_TEXTURES(state, payload) {
      state.textures = [...payload];
    },
    SET_LOGOS(state, payload) {
      state.logos = [...payload];
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_TEXTURE_PAGE(state, payload) {
      state.texturePage = { ...payload };
    },
    SET_MATERIAL_PAGE(state, payload) {
      state.materialPage = { ...payload };
    },
    SET_LOGO_PAGE(state, payload) {
      state.logoPage = { ...payload };
    },
    SET_MODEL_DATA(state, payload) {
      state.saveModelData = payload;
    },
    SET_MATERIAL_SELECTED(state, payload) {
      state.pickedMaterial = payload;
    },
    SET_TEXTURE_TYPE(state, payload) {
      state.textureSetType = payload;
    },
    SET_SEARCH_TYPE(state, payload) {
      state.searchType = payload;
    },
    SET_PART(state, payload) {
      state.setPart = payload;
    },
    SET_LOGO_FOLDERS(state, payload) {
      state.logoFolders = payload;
    },
    SET_PART_LIST(state, payload) {
      state.partList = payload;
    },
    SET_PART_TYPE(state, payload) {
      state.partType = payload;
    },
  },
  actions: {
    async getTextureSelectOption({ commit }) {
      const res = await api.getTextureSelectOption();
      commit("SET_TEXTURE_SELECTT_OPTION", res);
    },
    async getMaterialFolderList({ commit }) {
      const res = await api.getMaterialFolderList();
      commit("SET_MATERIAL_FOLDERS", res);
    },
    async getMaterialList({ commit, dispatch, state }, payload) {
      return new Promise(async (resolve) => {
        const { page = 1, page_size = 12 } = payload || {};
        let cat_id = state.currentMaterialFolder.id;
        let sn = state.searchType == 1 ? state.search : "";
        let name = state.searchType == 2 ? state.search : "";
        let company_name = state.searchType == 3 ? state.search : "";
        let part_type = state.partType;
        let res = await api.getMaterialList({
          page,
          page_size,
          cat_id,
          sn,
          name,
          company_name,
          part_type,
        });
        let { per_page, total } = res;
        let size = per_page;
        commit("SET_MATERIALS", res.data);
        commit("SET_MATERIAL_PAGE", { size, page_size: per_page, total });
        resolve(res);
        //dispatch("renderMaterials")
      });
    },
    async saveMaterial({ dispatch }, data) {
		return new Promise(async (resolve) => {
			if (data.id) {
			  await api.updateMaterial(data);
			} else {
			  await api.saveMaterial(data);
			}
			dispatch("getMaterialFolderList");
			dispatch("getMaterialList");
			
			resolve();
		})
    },
    async saveTexture({ dispatch }, data) {
      if (data.id) {
        await api.updateTexture(data);
      } else {
        await api.saveTexture(data);
      }
      dispatch("getTextureList");
      dispatch("getTextureFolderList");
    },

    async saveLogo({ dispatch }, data) {
      await api.saveLogo(data);
      dispatch("getLogoList");
      dispatch("getLogoFolderList");
    },
    async updateLogo({ dispatch }, data) {
      await api.updateLogo(data);
      dispatch("getLogoList");
      dispatch("getLogoFolderList");
    },
    async getTextureFolderList({ commit }) {
      const res = await api.getTextureFolderList();
      commit("SET_TEXTURE_FOLDERS", res);
      commit("SET_TEXTURE_SELECTT_OPTION", res);
    },
    async getTextureList({ commit, state }, { page = 1, pageSize = 12 } = {}) {
      let cat_id = state.currentTextureFolder.id;
      let page_size = pageSize;
      let name = state.search;
      const res = await api.getTextureList({ page, page_size, cat_id, name });
      let { per_page, total } = res;
      let size = per_page;
      commit("SET_TEXTURE_PAGE", { size, page_size: per_page, total });
      commit("SET_TEXTURES", res.data);
    },
    async getLogoList({ commit, state }) {
      let cat_id = state.currentLogoFolder.id;
      let { page, page_size } = state.logoPage;
      const res = await api.getLogoList({ page, page_size, cat_id });
      let { per_page, total } = res;
      let size = per_page;
      commit("SET_LOGO_PAGE", { size, page_size: per_page, total });
      commit("SET_LOGOS", res.data);
    },
    async getLogoFolderList({ commit }) {
      const res = await api.getLogoFolderList();
      // commit("SET_LOGO_FOLDERS", res);
      // commit('SET_TEXTURE_SELECTT_OPTION', res)
      commit("SET_LOGO_FOLDERS", res);
    },
    async delMaterial({ dispatch }, ids) {
	  return new Promise(async (resolve) => {
		  ids = ids || [];
		  let delFun = [];
		  for (let i = 0; i < ids.length; i++) {
		    delFun.push(api.delMaterial(ids[i]));
		  }
		  await Promise.all(delFun);
		  //dispatch("getMaterialList");
		  resolve();
	  })
    },
    async delTexture({ dispatch }, payload) {
      let ids = payload || [];
      let delFun = [];
      for (let i = 0; i < ids.length; i++) {
        delFun.push(api.delTexture(ids[i]));
      }
      await Promise.all(delFun);
      dispatch("getTextureList");
    },
    async delLogo({ dispatch }, payload) {
      let ids = payload || [];
      let delFun = [];
      for (let i = 0; i < ids.length; i++) {
        delFun.push(api.delLogo(ids[i]));
      }
      await Promise.all(delFun);
      dispatch("getLogoList");
    },
    async changeMaterial({ state }, data) {
      let b = data.ModelScene;
      let material = JSON.stringify(data.pbr);
      material = JSON.parse(material);

      if (b.pickedMesh && b.pickedMesh.material)
        b.pickedMesh.material.dispose();
      if (b.pickedMesh && material.type == "pbr") {
        let arr = Object.keys(b.pickedMeshArr);
        if (arr.length > 1) {
          arr.forEach(function (item) {
            if (b.pickedMeshArr[item].material)
              b.pickedMeshArr[item].material.dispose();
            b.pickedMeshArr[item].material = b.getPBR(material.param);
          });
          return;
        }
        b.pickedMesh.material = b.getPBR(material.param);
        state.saveModelData[b.pickedMesh.name] = {
          texture_id: material.id,
          mesh_name: b.pickedMesh.name,
          param: material.param,
          logo: null,
        };
        state.textureSetType = "texture";
      } else if (b.pickedMesh && material.type == "fur") {
        b.pickedMesh.material = b.getFur(material.param);
        let shells = BABYLON.FurMaterial.FurifyMesh(
          b.pickedMesh,
          material.param.quality
        );
        state.saveModelData[b.pickedMesh.name] = {
          texture_id: material.id,
          mesh_name: b.pickedMesh.name,
          param: material.param,
          logo: null,
        };
      }
    },
    async saveModelData({ state }, data) {
      return new Promise(async (resolve) => {
        const label_attribute = [];
        const b = data.ModelScene;
        const bomtable = data.bomtable;

        b.screenShot().then((file) => {
          uploadFile([file], "work", (file) => {
            let img_url = file[0];
            bomtable.label.img = {
              img_url: img_url,
            };
            //         Object.keys(state.saveModelData).forEach(function (key) {
            //           if (b.sign[key]) {
            //             state.saveModelData[key].sign = {
            //               text: b.sign[key].text,
            //               position: b.sign[key].position,
            //               meshName: b.sign[key].meshName,
            //             };
            //           } else {
            //             state.saveModelData[key].sign = null;
            //           }
            //  let param = JSON.stringify(materialsData.pbr.param);
            //  param = JSON.parse(param);
            // state.saveModelData[key].param = b.getMaterialData2(param,key);
            //           label_attribute.push(state.saveModelData[key]);
            //         });
            console.log(state.saveModelData);
            Object.keys(b.objMeshes).forEach(function (key) {
              let param = JSON.stringify(materialsData.pbr.param);
              param = JSON.parse(param);
              if (state.saveModelData[key]) {
                state.saveModelData[key].mesh_name = key;
                state.saveModelData[key].param = b.getMaterialData2(param, key);
              } else {
                state.saveModelData[key] = {
                  mesh_name: key,
                  param: b.getMaterialData2(param, key),
                };
              }
              if (b.sign[key]) {
                state.saveModelData[key].sign = {
                  text: b.sign[key].text,
                  position: b.sign[key].position,
                  meshName: b.sign[key].meshName,
                };
              }

              if (b.logos[key]) {
                let logoArr = [];
                b.logos[key].forEach(function (logo) {
                  logoArr.push({
                    position: {
                      x: logo.position._x,
                      y: logo.position._y,
                      z: logo.position._z,
                    },
                    normal: {
                      x: logo.normal._x,
                      y: logo.normal._y,
                      z: logo.normal._z,
                    },
                    size: { x: logo.size._x, y: logo.size._y, z: logo.size._z },
                    angle: logo.angle,
                    param: logo.param,
                  });
                });
                state.saveModelData[key].logo = logoArr;
              }
              label_attribute.push(state.saveModelData[key]);
            });
            bomtable.label_attribute = label_attribute;
            bomtable.label.partArr = b.partArr;
            let result;
            if (bomtable.id) {
              result = api.updateBom(bomtable);
            } else {
              result = api.saveBom(bomtable);
            }
            resolve(result);
          });
        });
      });
    },
    async renderMaterials({ state, dispatch, commit }, data) {
      //     let canvasArr = data.MaterialScene;
      //      let materials = data.materials;
      //     //重置mesh
      //     let that = this;
      //     for (let m = 0; m < 12; m++) {
      //       let a = canvasArr[m];
      //       if (a.meshes && a.meshes[0]) {
      //     	  a.meshes[1].material.dispose();
      //         a.meshes[0].dispose();
      //         a.meshes = null;
      //       } else if (a.meshes && !a.meshes[0]) {
      //     	  a.meshes.material.dispose();
      //         a.meshes.dispose();
      //         a.meshes = null;
      //       }
      //     }
      //     //遍历材质
      //     for (let m = 0; m < materials.length; m++) {
      //       canvasArr[m].loadMaterial(materials[m]);
      // canvasArr[m].canvas.style.border = 'none';
      //       //替换材质
      //       canvasArr[m].selectMaterial(materials[m], function (pbr) {
      //     			data.pbr = pbr;
      // 		canvasArr.forEach(function(item){
      // 			item.canvas.style.border = 'none';
      // 		})
      // 		if(m == that.materialSeleted){
      // 			that.materialSeleted = null;
      // 			commit("SET_MATERIAL_SELECTED", null);
      // 			canvasArr[m].canvas.style.border = 'none';
      // 		}else{
      // 			that.materialSeleted = m;
      // 			commit("SET_MATERIAL_SELECTED", pbr);
      // 			dispatch("changeMaterial",data);
      // 			//canvasArr[m].canvas.style.border = '1px solid #999';
      // 		}
      //       });
      //     }
    },
    async textureSet({ state, dispatch }, data) {
      let scene = data.ModelScene;
      if (scene.pickedMesh && scene.pickedMesh.material) {
        if (data.type == "size") {
          if (scene.pickedMesh.material.albedoTexture) {
            scene.pickedMesh.material.albedoTexture.uScale = data.val;
            scene.pickedMesh.material.albedoTexture.vScale = data.val;
          }
          if (scene.pickedMesh.material.bumpTexture) {
            scene.pickedMesh.material.bumpTexture.uScale = data.val;
            scene.pickedMesh.material.bumpTexture.vScale = data.val;
          }
          if (
            state.saveModelData[scene.pickedMesh.name].param &&
            state.saveModelData[scene.pickedMesh.name].param.albedoTexture
          ) {
            state.saveModelData[
              scene.pickedMesh.name
            ].param.albedoTexture.uScale = data.val;
            state.saveModelData[
              scene.pickedMesh.name
            ].param.albedoTexture.vScale = data.val;
          }
          if (
            state.saveModelData[scene.pickedMesh.name].param &&
            state.saveModelData[scene.pickedMesh.name].param.bumpTexture
          ) {
            state.saveModelData[
              scene.pickedMesh.name
            ].param.bumpTexture.uScale = data.val;
            state.saveModelData[
              scene.pickedMesh.name
            ].param.bumpTexture.vScale = data.val;
          }
        } else if (data.type == "angle") {
          if (scene.pickedMesh.material.albedoTexture)
            scene.pickedMesh.material.albedoTexture.wAng = data.val;
          if (scene.pickedMesh.material.bumpTexture) {
            scene.pickedMesh.material.bumpTexture.wAng = data.val;
          }
          if (
            state.saveModelData[scene.pickedMesh.name].param &&
            state.saveModelData[scene.pickedMesh.name].param.albedoTexture
          ) {
            state.saveModelData[
              scene.pickedMesh.name
            ].param.albedoTexture.wAng = data.val;
          }
          if (
            state.saveModelData[scene.pickedMesh.name].param &&
            state.saveModelData[scene.pickedMesh.name].param.bumpTexture
          ) {
            state.saveModelData[scene.pickedMesh.name].param.bumpTexture.wAng =
              data.val;
          }
        } else if (data.type == "horizontal") {
          scene.pickedMesh.material.albedoTexture.uOffset = data.val;
          if (
            state.saveModelData[scene.pickedMesh.name].param &&
            state.saveModelData[scene.pickedMesh.name].param.albedoTexture
          )
            state.saveModelData[
              scene.pickedMesh.name
            ].param.albedoTexture.uOffset = data.val;
        } else if (data.type == "vertical") {
          scene.pickedMesh.material.albedoTexture.vOffset = data.val;
          if (
            state.saveModelData[scene.pickedMesh.name].param &&
            state.saveModelData[scene.pickedMesh.name].param.albedoTexture
          )
            state.saveModelData[
              scene.pickedMesh.name
            ].param.albedoTexture.vOffset = data.val;
        } else if (data.type == "albedoColor") {
          scene.pickedMesh.material.albedoColor = new BABYLON.Color3(
            data.val.r,
            data.val.g,
            data.val.b
          );
          //let light_intensity = 0.3*scene.pickedMesh.material.albedoColor.r+0.6*scene.pickedMesh.material.albedoColor.g+0.1*scene.pickedMesh.material.albedoColor.b;
          // scene.pickedMesh.material.directIntensity = light_intensity;
          // scene.pickedMesh.material.environmentIntensity = light_intensity;
          scene.pickedMesh.material.reflectionColor = new BABYLON.Color3(
            data.val.r,
            data.val.g,
            data.val.b
          );
          //scene.pickedMesh.material.ambientColor = new BABYLON.Color3(data.val.r,data.val.g,data.val.b);

          if (
            state.saveModelData[scene.pickedMesh.name] &&
            state.saveModelData[scene.pickedMesh.name].param
          ) {
            state.saveModelData[scene.pickedMesh.name].param.albedoColor =
              data.val;
            state.saveModelData[scene.pickedMesh.name].param.reflectionColor =
              data.val;
          } else {
            state.saveModelData[scene.pickedMesh.name] = {
              mesh_name: scene.pickedMesh.name,
              param: {
                reflectionTexture: {
                  url: "texture/environmentSpecular.env",
                  type: "env",
                  material: null,
                },
                metallic: 0,
                roughness: 1,
                albedoColor: data.val,
                reflectionColor: data.val,
              },
            };
          }
        } else if (data.type == "EnvironmentIntensity") {
          scene.pickedMesh.material.environmentIntensity = data.val;
          if (state.saveModelData[scene.pickedMesh.name].param)
            state.saveModelData[
              scene.pickedMesh.name
            ].param.environmentIntensity = data.val;
        } else if (data.type == "alpha") {
          scene.pickedMesh.material.alpha = data.val;
          if (state.saveModelData[scene.pickedMesh.name].param)
            state.saveModelData[scene.pickedMesh.name].param.alpha = data.val;
        }
      }
    },
    async createLogo({ state, dispatch }, data) {
      let scene = data.ModelScene;
      let logoData = data.logoData;
      if (
        scene.pickedMesh &&
        state.saveModelData[scene.pickedMesh.name] &&
        state.saveModelData[scene.pickedMesh.name].logo
      ) {
        scene.deleteLogo();
        state.saveModelData[scene.pickedMesh.name].logo = null;
      }
      if (
        scene.pickedMesh &&
        state.saveModelData[scene.pickedMesh.name] &&
        !state.saveModelData[scene.pickedMesh.name].logo
      ) {
        //logoData.meshName = scene.pickedMesh.name;
        let logo = scene.createLogo(logoData);
        state.saveModelData[scene.pickedMesh.name].logo = logo;
        state.textureSetType = "logo";
      } else if (
        scene.pickedMesh &&
        !state.saveModelData[scene.pickedMesh.name]
      ) {
        //logoData.meshName = scene.pickedMesh.name;
        let logo = scene.createLogo(logoData);
        state.saveModelData[scene.pickedMesh.name] = {
          mesh_name: scene.pickedMesh.name,
          logo: logo,
        };
        console.log(state.saveModelData[scene.pickedMesh.name].logo);
        state.textureSetType = "logo";
      }
    },
    async getLogo({ state, dispatch }, data) {
      let scene = data.ModelScene;
      let logoData = data.logoData;
      if (scene.logos[scene.pickedMesh.name]) {
        scene.logos[scene.pickedMesh.name].dispose();
      }
      if (
        scene.pickedMesh &&
        state.saveModelData[scene.pickedMesh.name] &&
        state.saveModelData[scene.pickedMesh.name].logo
      ) {
        let logo = scene.getLogo(scene.pickedMesh, logoData);
        state.saveModelData[scene.pickedMesh.name].logo = logoData;
      } else if (
        scene.pickedMesh &&
        !state.saveModelData[scene.pickedMesh.name]
      ) {
        let logo = scene.getLogo(scene.pickedMesh, logoData);
        state.saveModelData[scene.pickedMesh.name] = {
          mesh_name: scene.pickedMesh.name,
          logo: logoData,
        };
      }
    },
    async moveLogo({ state, dispatch }, data) {
      let scene = data.ModelScene;
      let logoData = data.logoData;
      let mesh = scene.objMeshes[scene.pickedMesh.name.split("[MESHLOGO]")[0]];
      console.log(scene.logos);
      console.log(scene.pickedMesh.name);
      if (scene.logos[scene.pickedMesh.name.split("[MESHLOGO]")[0]]) {
        scene.logos[scene.pickedMesh.name.split("[MESHLOGO]")[0]].dispose();
      }
      if (
        scene.pickedMesh &&
        state.saveModelData[scene.pickedMesh.name] &&
        state.saveModelData[scene.pickedMesh.name].logo
      ) {
        let logo = scene.moveLogo(mesh, logoData);
        state.saveModelData[scene.pickedMesh.name].logo = logoData;
      } else if (
        scene.pickedMesh &&
        !state.saveModelData[scene.pickedMesh.name]
      ) {
        let logo = scene.moveLogo(mesh, logoData);
        state.saveModelData[scene.pickedMesh.name] = {
          mesh_name: scene.pickedMesh.name,
          logo: logoData,
        };
      }
    },
    async deleteAllLogo({ state }) {
      Object.keys(state.saveModelData).forEach(function (key) {
        state.saveModelData[key].logo = null;
      });
    },
    async deleteAllMaterials({ state }) {
      Object.keys(state.saveModelData).forEach(function (key) {
        state.saveModelData[key].texture_id = null;
        state.saveModelData[key].param = null;
      });
    },
    async deleteMaterial({ state }, meshName) {
      if (state.saveModelData[meshName]) {
        state.saveModelData[meshName].texture_id = null;
        state.saveModelData[meshName].param = null;
      }
    },
    async textureSearch({ state, commit }, data) {
      var forms = new FormData();
      forms.append("image", data);
      forms.append("page", 1);
      forms.append("page_size", 12);
      const res = await api.textureSearch(forms);
      let { per_page, total } = res;
      let size = per_page;
      commit("SET_TEXTURES", res.data);
      commit("SET_TEXTURE_PAGE", { size, page_size: per_page, total });
      return res;
    },
    async materialSearch({ state, commit }, data) {
      var forms = new FormData();
      forms.append("image", data);
      forms.append("page", 1);
      forms.append("page_size", 12);
      const res = await api.materialSearch(forms);
      let { per_page, total } = res;
      let size = per_page;
      commit("SET_MATERIALS", res.data);
      commit("SET_MATERIAL_PAGE", { size, page_size: per_page, total });
      return res;
    },
  },
  getters: {
    filterMaterial(state) {
      return state.materials;
    },
    filterTexture(state) {
      return state.textures;
    },
    filterLogo(state) {
      return state.logos;
    },
    pickedMaterial(state) {
      return state.pickedMaterial;
    },
    getModelData(state) {
      return state.getModelData;
    },
    getPartList(state) {
      return state.partList;
    },
  },
};

<template>
  <el-dialog
               :visible.sync="visible"
               :before-close="closeModal"
               :modal-append-to-body="false"
               :close-on-click-modal="false"
               width="1200px">
    <div class="same-design-search">
      {{$t('searchSameType')}}：
      <div class="list-search">
        <el-input
            class="list-search-input"
            v-model="search_form.name"
            @keyup.enter.native="searchDesign"
        ></el-input>
<!--        <el-upload-->
<!--            action=""-->
<!--            accept=".jpg,.jpeg,.png,.JPG,.PNG"-->
<!--            :show-file-list="false"-->
<!--            ref="upload"-->
<!--            :http-request="toSearchImage"-->
<!--        >-->
<!--          <i class="iconfont icon-zhaoxiangji image-search-icon"></i>-->
<!--        </el-upload>-->
        <i
            class="iconfont icon-fangdajing list-search-icon"
            @click.stop="searchDesign"
        ></i>
      </div>
      <el-button size="mini" v-if="isChoose" @click="toSubmitProduct">{{$t('confirmSeleted')}}</el-button>
    </div>
    <div class="same-design-lists">
      <designView
          v-for="val in tableData"
          :key="val.id"
          :itemData="val"
          :isToChooseType="2"
          :isChoose="val.check"
          @chooseProduct="chooseProduct"
      ></designView>
    </div>
    <div v-if="tableData.length === 0">
      <emptyPage></emptyPage>
    </div>
    <div class="pagination" v-if="tableData.length > 0 && !isInAiSearch">
      <el-pagination
          background
          big
          :total="total"
          :current-page="page"
          :page-size="page_size"
          layout="prev, pager, next"
          @current-change="currentChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import designView from "@/components/designView/designView";
import emptyPage from "@/components/emptyPage";
export default {
  name: "chooseDesignDialog",
  components:{
    designView,
    emptyPage,
  },
  props:{
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      page: 1,
      page_size: 8,
      tableData:[],
      isInAiSearch:false,
      search_form: {
        name: "",
      },
      chooseData:{},
      isChoose:false,
      total:0,
    }
  },
  created() {
    this.getTableData()
  },
  watch:{
	  'visible':{
		   handler(newVal){
			   if(newVal){
				    this.getTableData()
			   }
		   }
	  }
  },
  methods:{
    //筛选
    searchDesign() {
      this.page = 1;
      this.chooseData={}
      this.isChoose=false
      this.getTableData();
    },

    //提交选中商品
    toSubmitProduct(){
      if(this.isChoose){

        this.$emit('toSubmitProduct',this.chooseData)
        this.closeModal()
      }
    },
    //ai搜索图片
    toSearchImage(file) {
      this.img_file = file;
      this.page = 1;
      if (file) {
        this.isInAiSearch = true;
      } else {
        this.isInAiSearch = false;
      }

      this.getTableData();
    },
    //获取列表
    getTableData() {
      let loading = this.openLoading();
      if (this.isInAiSearch) {
        var forms = new FormData();
        forms.append("image", this.img_file);
        forms.append("page", this.page);
        forms.append("page_size", this.page_size);
        if (this.menuType > 0) {
          forms.append("type", this.menuType);
        }
        this.$api.getDesignImageList(forms).then((res) => {
          this.tableData = res.bomInfo;
          this.total = _.get(res, "ai_info.result_num", 0);
          loading.close();
        });
      } else {
        let data = {
          page: this.page,
          page_size: this.page_size,
        };
        let search = {};
        search = this.search_form;
        data = { ...data, ...{ search } };
        this.$api.getProductList(data).then((res) => {
          let datas = res.data;
          if (datas) {
            datas.forEach((val, key) => {
              datas[key] = { ...val, ...{ check: false } };

              datas[key]["img_url"] = datas[key].file_info
                  ? datas[key].file_info.main_file.img_url
                  : "";
              datas[key]["amount"] = 0;
              datas[key]["number"] = "";
            });
          }
          this.total = res.total;
          this.tableData = datas;
          loading.close();
        });
      }
    },
    //选中商品
    chooseProduct(data) {
      this.tableData.forEach((val, key) => {
        if (val.id === data.item.id) {
          if(!data.check){
            this.chooseData=val
            this.isChoose=true
          }else{
            this.chooseData={}
            this.isChoose=false
          }
          val.check = !data.check;
          this.$set(this.tableData, key, val);
        }else{
          val.check = false;
          this.$set(this.tableData, key, val);
        }
      });

    },
    closeModal(){
      this.chooseData={}
      this.isChoose=false
      this.$emit("update:visible", false);
    },
    //设计分页
    currentChange(arg) {
      this.chooseData={}
      this.isChoose=false
      this.page = arg; // 当前页：arg
      this.getTableData();
    },
  }
}
</script>

<style lang="scss">
  .same-design-search{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

  }
  .same-design-lists{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 40px;
  }
  .list-search {
    height: 32px;
    position: relative;
    margin-right: 10px;
    .el-input__inner {
      width: 294px;
      height: 32px;
      padding-right: 34px;
      padding-left: 12px;
    }
    .list-search-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      font-size: 18px;
      z-index: 1;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .image-search-icon {
      position: absolute;
      left: 12px;
      top: 50%;
      font-size: 18px;
      transform: translateY(-50%);
    }
  }
  .list-search-btn {
    margin-right: 10px;
    border-radius: 2px;
    height: 32px;
    width: 74px;
    border: 1px solid #dcdfe6;
    color: #333;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    cursor: pointer;
    background-color: #fff;
  }
</style>
<template>
  <div class="form-left-box" v-dragSize="{ min: 200, max: 500 }" :style="{ width: navCollapse ? '62px' : '320px' }">
	  <div class="nav-collapse-icon" @click="handleClickCollapse">
		<a-icon type="right-circle" v-if="navCollapse" />
		<a-icon type="left-circle" v-else />
	  </div>
    <ul class="left-menu-list" v-show="!navCollapse">
      <li
        class="left-menu-item"
        :class="[active === key ? 'left-menu-item-active' : '']"
        v-for="(val, key) in menu"
        :key="key"
      >
        <div class="circle"></div>
        <span class="left-menu-item-text" @click="toFormList(val.type,key)">
          {{ $t(val.title) }}
          <span v-if="val.total && val.total > 0" class="red-circle">
            {{ val.total }}
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import formMenuItem from "./formMenuItem";
import { mapMutations, mapState,mapActions} from "vuex";
export default {
  name: "formLeftMenu",
  props: {
    type: {
      type: Number,
    },
  },
  components: {
    // formMenuItem,
  },
  data() {
    return {
      checkJoin: "",
      formType: this.type,
      menu: [
        {
          title: "enquiryForm",
          type: 1,
          total: 0,
        },
       /* {
          title: "quotationForm",
          type: 2,
          total: 0,
        }, */
        {
          title: "orderForm",
          type: 3,
          total: 0,
        },
        {
          title: "recommendForm",
          type: 4,
          total: 0,
        },
      ],
	  times:'',
	  active:0
    };
  },
  created() {
    // this.menu.forEach(async (val, key) => {
    //   let datas = await this.getFormList(val.type);
    //   val.data = datas;
    //   this.$set(this.menu, key, val);
    // });
	let that = this;
	that.getCount();
	this.times = setInterval(function(){
		that.getCount();
	},5000)
  },
  destroyed(){
	  clearInterval(this.times);
  },
  watch: {
    $route: {
      handler() {
        /* if (this.$route.name == "lists") {
          this.formType = Number(this.$route.query.type);
          this.$emit("getList", {
            type: Number(this.$route.query.type),
          });
        } */
      },
      deep: true,
    },
    countList(list) {
      this.menu[0].total = list.offer_count;
      this.$set(this.menu, 1, this.menu[1]);
    },
  },
  computed: {
    ...mapState("jiyang/count", ["countList"]),
	...mapState("jiyang/app", ["navCollapse"]),
  },
  methods: {
    ...mapMutations("jiyang/form", ["setFormState"]),
	...mapActions("jiyang/count", ["getCount"]),
	...mapMutations("jiyang/app", ["setCollapse"]),
    toFormList(type,key) {
	  this.active = key;
      this.formType = type;
	  this.setFormState(this.formType);
     // this.$emit("resetList");
	  if (this.$route.name == "lists") {
	    //this.formType = Number(this.$route.query.type);
	    this.$emit("getList", {
		  type: this.formType,
	    });
	  }
      /* this.$router.push({
        path: "lists",
        query: {
          type,
          t: Date.now(),
        },
      }); */
    },
    getFormList(type) {
      return new Promise((resolve) => {
        let data = {
          type,
        };
        this.$api.getFormList(data).then((res) => {
          resolve(res.data);
        });
      });
    },
    getDetail(data) {
      this.checkJoin = data.join;
      this.$emit("getDetail", {
        id: data.id,
      });
    },
    handleChange(e) {},
    checkAcitve(type) {
      if (this.menu.indexOf(str) === -1) return false;
      return true;
    },
	handleClickCollapse() {
	  this.setCollapse(!this.navCollapse);
	},
  },
};
</script>

<style lang="scss">
.form-left-box {
  width: 320px;
  height: 100%;
/*  overflow-y: auto; */
  padding: 24px 16px;
  background-color: #fbfbfb;
  border-right: 1px solid #d6d3d3;
  .el-collapse-item__header {
    position: relative;
  }
  .el-icon-arrow-right {
    display: none;
  }
  .left-menu-list {
  }
  .left-menu-item {
    margin-bottom: 58px;
    color: #000;
    display: flex;
    align-items: center;
    .left-menu-item-text {
      font-size: $base-font-size;
      cursor: pointer;
      position: relative;
    }
    .circle {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #000;
      margin-right: 6px;
    }
  }
  .left-menu-item-active {
    color: $base-config-color;
  }
  .red-circle {
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    border-radius: 8px;
    background-color: #fa4444;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -20px;
    color: #fff;
    font-size: 10px;
    z-index: 3;
  }
  .menu-list {
    min-height: 600px;
    height: calc(100% - 46px);
  }
  .memu-title {
    font-size: 18px;
    font-weight: 600;
    margin-left: 8px;
  }
  .menu-active {
    transform: rotate(90deg);
  }
  .form-left-search-box {
    padding-bottom: 12px;
    border-bottom: 2px solid #dfe0e2;
  }
  .form-left-search {
    width: 100%;
    height: 32px;
    padding: 0 12px 0 30px;
    position: relative;
    border-radius: 16px;
    background-color: #f0f2f5;
    .form-left-input {
      padding-left: 4px;
      border: none;
      height: 32px;
      width: 100%;
      border-radius: 16px;
      background-color: #f0f2f5;
    }
    .search-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form-add-box {
    width: 100%;
    height: 50px;
    margin-top: 12px;
    border: 1px solid #e2dede;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    .add-btn {
      font-size: 30px;
      color: $base-config-color;
    }
  }
  
  .nav-collapse-icon {
    cursor: pointer;
    background-color: #fff;
    top: 14px;
    right: -15px;
    position: absolute;
    font-size: 30px;
    z-index: 3;
    > i > svg {
      path:nth-child(2) {
        color: #ddd;
      }
    }
  }
}
</style>

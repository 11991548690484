<template>
  <a-upload
    :disabled="disabled"
    class="n-uploader"
    list-type="picture"
    accept="image/png,image/jpeg"
    :beforeUpload="handleBeforeUpload"
    :default-file-list="fileList"
    :customRequest="customRequest"
    :remove="remove"
    :file-list="fileList"
    @change="handleChange"
  >
    <a-button :disabled="disabled">
      <a-icon type="upload" /> {{ $t("upload") }}
    </a-button>
  </a-upload>
</template>

<script>
import uploadFile from "@/utils/upload_to_cos.js";
export default {
  name: "n-uploader",
  props: {
    fileCallBack: {
      type: Function,
      default: () => {},
    },
    beforeUpload: {
      type: Function,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      image: null,
      fileList: [],
    };
  },
  methods: {
    handleBeforeUpload(file) {
      return this.beforeUpload(file);
    },
    handleChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      this.fileList = fileList;
      if (this.fileList.length == 0) {
        this.$emit("delete");
        return;
      }
      if (this.fileList[0].status == "done") {
        this.$emit("done", this.fileList);
      }
    },
    customRequest({ file, onSuccess, onError, onProgress }) {
      let process = {
        percent: 1,
      };
      let timeId = setInterval(() => {
        if (process.percent < 100) {
          process.percent += 1;
          onProgress(process);
        } else {
          process.percent = 1;
        }
      }, 100);
      uploadFile([file], "material", (file) => {
        let process = {
          percent: 100,
        };
        onSuccess();
        onProgress(process);
        this.fileCallBack(file);
        clearInterval(timeId);
      });
    },
  },
};
</script>

<style>
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
